import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType} from "@data/enums/data.enums";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {Util} from "@data/util/util";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";

export let pageContext: {
  [key: string]: boolean | string | undefined
  // // START Survey details
  // surveyCode: string;
  sc_f: string | undefined;
  // surveyName: string;
  sn_f: string | undefined;
  // programSubject: string;
  sps_f: string | undefined;
  // period: string;
  sp_f: string | undefined;
  // marketCode: string;
  mc_f: string | undefined;
  // marketName: string;
  mn_f: string | undefined;
  // industryCode: string;
  ic_f: string | undefined;
  // industryName: string;
  in_f: string | undefined;
  // surveySource: string;
  ss_f: string | undefined;
  // retired: boolean;
  sr_f: boolean | undefined;
  // descriptionNotes: string;
  sd_f: string | undefined;
  // expectedFieldStart: Date;
  sefs_f: string | undefined;
  // expectedFieldClose: Date;
  sefc_f: string | undefined;
  // fieldStart: Date;
  sfs_f: string | undefined;
  // fieldClose: Date;
  sfc_f: string | undefined;
  // importStatus: string;
  sis_f: string | undefined;
  // approvalStatus: string;
  sas_f: string | undefined;
  // approvalDatetime: Date;
  sad_f: string | undefined;
  // responseApprovalStatus: string;
  sras_f: string | undefined;
  // responseApprovalDatetime: Date;
  srad_f: string | undefined;
  // // END Survey details
  //
  // // START Forsta Project details
  // forstaProjectCode: string;
  fpc_f: string | undefined;
  // forstaProjectName: string;
  fpn_f: string | undefined;
  // forstaProjectVersion: string;
  fpv_f: string | undefined;
  // isCurrentProject: boolean;
  fc_f: boolean | undefined;
  // forstaProjectState: string;
  fps_f: string | undefined;
  // forstaProjectCreatedDate: Date;
  fpcd_f: string | undefined;
  // forstaProjectArchivedDate: Date;
  fpad_f: string | undefined;
  // forstaProjectDeletedDate: Date;
  fpdd_f: string | undefined;
  // existsInForsta: boolean;
  fe_f: boolean | undefined;
  // // END Forsta Project details
  //
  // // START Forsta Template details
  // surveyTemplateName: string;
  tn_f: string | undefined;
  // forstaTemplateCode: string;
  ftc_f: string | undefined;
  // forstaTemplateName: string;
  ftn_f: string | undefined;
  // forstaTemplateVersion: string;
  ftv_f: string | undefined;
  // // END Forsta Template details
  //
  // // START ids
  // surveyDataId: number;
  sd_id: string | undefined;
  // surveyId: number;
  s_id: string | undefined;
  // surveyTemplateId: number;
  st_id: string | undefined;
  // // END ids
  //
  // // START calculated fields
  // projectedArchiveDate: Date;
  fpad_id: string | undefined;
  // projectedDeletionDate: Date;
  fpdd_id: string | undefined;
  // // END calculated fields
} = {
  // // START Survey details
  // surveyCode: string;
  sc_f: undefined,
  // surveyName: string;
  sn_f: undefined,
  // programSubject: string;
  sps_f: undefined,
  // period: string;
  sp_f: undefined,
  // marketCode: string;
  mc_f: undefined,
  // marketName: string;
  mn_f: undefined,
  // industryCode: string;
  ic_f: undefined,
  // industryName: string;
  in_f: undefined,
  // surveySource: string;
  ss_f: undefined,
  // retired: boolean;
  sr_f: undefined,
  // descriptionNotes: string;
  sd_f: undefined,
  // expectedFieldStart: Date;
  sefs_f: undefined,
  // expectedFieldClose: Date;
  sefc_f: undefined,
  // fieldStart: Date;
  sfs_f: undefined,
  // fieldClose: Date;
  sfc_f: undefined,
  // importStatus: string;
  sis_f: undefined,
  // approvalStatus: string;
  sas_f: undefined,
  // approvalDatetime: Date;
  sad_f: undefined,
  // responseApprovalStatus: string;
  sras_f: undefined,
  // responseApprovalDatetime: Date;
  srad_f: undefined,
  // // END Survey details
  //
  // // START Forsta Project details
  // forstaProjectCode: string;
  fpc_f: undefined,
  // forstaProjectName: string;
  fpn_f: undefined,
  // forstaProjectVersion: string;
  fpv_f: undefined,
  // isCurrentProject: boolean;
  fc_f: undefined,
  // forstaProjectState: string;
  fps_f: undefined,
  // forstaProjectCreatedDate: Date;
  fpcd_f: undefined,
  // forstaProjectArchivedDate: Date;
  fpad_f: undefined,
  // forstaProjectDeletedDate: Date;
  fpdd_f: undefined,
  // existsInForsta: boolean;
  fe_f: undefined,
  // // END Forsta Project details
  //
  // // START Forsta Template details
  // surveyTemplateName: string;
  tn_f: undefined,
  // forstaTemplateCode: string;
  ftc_f: undefined,
  // forstaTemplateName: string;
  ftn_f: undefined,
  // forstaTemplateVersion: string;
  ftv_f: undefined,
  // // END Forsta Template details
  //
  // // START ids
  // surveyDataId: number;
  sd_id: undefined,
  // surveyId: number;
  s_id: undefined,
  // surveyTemplateId: number;
  st_id: undefined,
  // // END ids
  //
  // // START calculated fields
  // projectedArchiveDate: Date;
  fpad_id: undefined,
  // projectedDeletionDate: Date;
  fpdd_id: undefined
  // // END calculated fields
};

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  // // START Survey details
  {
    headerName: 'Survey Details',
    valueFormatter: '',
    children: [
      // surveyCode: string;
      // surveyName: string;
      {
        field: 'surveyCode',
        headerName: 'Survey Code',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'sc_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params?.data?.surveyName
          };
        },
        enableRowGroup: true
      },
      {
        field: 'surveyName',
        headerName: 'Survey Name',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'sn_f',
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params?.data?.surveyCode
          };
        }
      },
      // programSubject: string;
      {
        field: 'programSubject',
        headerName: 'Survey Subject',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'sps_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: StatusColumnComponent,
        enableRowGroup: true,
      },
      // period: string;
      {
        field: 'period',
        headerName: 'Period',
        hide: true,
        filterId: 'sp_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        enableRowGroup: true,
      },
      // marketCode: string;
      // marketName: string;
      {
        field: 'marketCode',
        headerName: 'Market Code',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'mc_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params?.data?.marketName
          };
        },
        enableRowGroup: true,
      },
      {
        field: 'marketName',
        headerName: 'Market Name',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'mn_f',
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params?.data?.marketCode
          };
        },
        enableRowGroup: true,
      },
      // industryCode: string;
      // industryName: string;
      {
        field: 'industryCode',
        headerName: 'Industry Code',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'ic_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params?.data?.industryName
          };
        },
        enableRowGroup: true,
      },
      {
        field: 'industryName',
        headerName: 'Industry Name',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'in_f',
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params?.data?.industryCode
          };
        },
        enableRowGroup: true,
      },
      // surveySource: string;
      {
        field: 'surveySource',
        headerName: 'Survey Source',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'ss_f',
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent,
        enableRowGroup: true,
      },
      // retired: boolean;
      {
        field: 'retired',
        headerName: 'Retired?',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          cellRendererParams: function (params: any) {
            return {
              iconSize: 'fa-lg'
            };
          },
          buttons: ['reset']
        },
        filterId: 'sr_f',
        cellRenderer: CircleCheckColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            iconSize: 'fa-xl'
          };
        },
        enableRowGroup: true,
      },
      // descriptionNotes: string;
      {
        field: 'descriptionNotes',
        headerName: 'Description',
        maxWidth: 200,
        hide: true,
        filterId: 'sd_f',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params?.data?.descriptionNotes,
            type: 'text',
          };
        }
      },
      // expectedFieldStart: Date;
      {
        field: 'expectedFieldStart',
        headerName: 'Expected Field Start',
        hide: true,
        filterId: 'sefs_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params?.value).toString();
        }
      },
      // expectedFieldClose: Date;
      {
        field: 'expectedFieldClose',
        headerName: 'Expected Field Close',
        hide: true,
        filterId: 'sefc_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params?.value).toString();
        }
      },
      // fieldStart: Date;
      {
        field: 'fieldStart',
        headerName: 'Field Start',
        hide: true,
        filterId: 'sfs_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params?.value).toString();
        }
      },
      // fieldClose: Date;
      {
        field: 'fieldClose',
        headerName: 'Field Close',
        hide: false,
        filterId: 'sfc_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params?.value).toString();
        }
      },
      // importStatus: string;
      {
        field: 'importStatus',
        headerName: 'Import Status',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'sis_f',
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent,
        enableRowGroup: true,
      },
      // approvalStatus: string;
      {
        field: 'approvalStatus',
        headerName: 'Approval Status',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'sas_f',
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent,
        enableRowGroup: true,
      },
      // approvalDatetime: Date;
      {
        field: 'approvalDatetime',
        headerName: 'Approval Date',
        hide: true,
        filterId: 'sad_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params?.value).toString();
        }
      },
      // responseApprovalStatus: string;
      {
        field: 'responseApprovalStatus',
        headerName: 'Response Approval Status',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'aras_f',
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent
      },
      // responseApprovalDatetime: Date;
      {
        field: 'responseApprovalDatetime',
        headerName: 'Response Approval Date',
        hide: true,
        filterId: 'srad_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params?.value).toString();
        }
      }
      // // END Survey details
    ]
  },
  //
  // // START Forsta Project details
  {
    headerName: 'Forsta Details',
    valueFormatter: '',
    children: [
      // forstaProjectCode: string;
      // forstaProjectName: string;
      {
        field: 'forstaProjectCode',
        headerName: 'Project Code',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'fpc_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params?.data?.forstaProjectName
          };
        }
      },
      {
        field: 'forstaProjectName',
        headerName: 'Project Name',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'fpn_f',
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params?.data?.forstaProjectCode
          };
        }
      },
      // forstaProjectVersion: string;
      {
        field: 'forstaProjectVersion',
        headerName: 'Project Version',
        hide: true,
        filterId: 'fpv_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        enableRowGroup: true,
      },
      // isCurrentProject: boolean;
      {
        field: 'isCurrentProject',
        headerName: 'Is Current Project?',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          cellRendererParams: function (params: any) {
            return {
              iconSize: 'fa-lg'
            };
          },
          buttons: ['reset']
        },
        filterId: 'pc_f',
        cellRenderer: CircleCheckColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            iconSize: 'fa-xl'
          };
        },
        enableRowGroup: true,
      },
      // forstaProjectState: string;
      {
        field: 'forstaProjectState',
        headerName: 'Project State',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'pps_f',
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent,
        enableRowGroup: true,
      },
      // forstaProjectCreatedDate: Date;
      {
        field: 'forstaProjectCreatedDate',
        headerName: 'Project Created Date',
        hide: false,
        filterId: 'fpcd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params?.value).toString();
        }
      },
      // projectedArchiveDate: Date;
      {
        field: 'projectedArchiveDate',
        headerName: 'Planned Archive Date',
        hide: false,
        filterId: 'fpad_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params?.value).toString();
        }
      },
      // forstaProjectArchivedDate: Date;
      {
        field: 'forstaProjectArchivedDate',
        headerName: 'Project Archived Date',
        hide: false,
        filterId: 'fad_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params?.value).toString();
        }
      },
      // projectedDeletionDate: Date;
      {
        field: 'projectedDeletionDate',
        headerName: 'Planned Deletion Date',
        hide: false,
        filterId: 'fpdd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params?.value).toString();
        }
      },
      // forstaProjectDeletedDate: Date;
      {
        field: 'forstaProjectDeletedDate',
        headerName: 'Project Deleted Date',
        hide: false,
        filterId: 'fdd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params?.value).toString();
        }
      },
      // existsInForsta: boolean;
      {
        field: 'existsInForsta',
        headerName: 'Exists In Forsta?',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          cellRendererParams: function (params: any) {
            return {
              iconSize: 'fa-lg'
            };
          },
          buttons: ['reset']
        },
        filterId: 'fe_f',
        cellRenderer: CircleCheckColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            iconSize: 'fa-xl'
          };
        },
        enableRowGroup: true,
      }
      // // END Forsta Project details
    ]
  },
  //
  // // START Forsta Template details
  {
    headerName: 'Template Details',
    valueFormatter: '',
    children: [
      // surveyTemplateName: string;
      {
        field: 'surveyTemplateName',
        headerName: 'Template Name',
        hide: true,
        filterId: 'tn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        enableRowGroup: true,
      },
      // forstaTemplateCode: string;
      // forstaTemplateName: string;
      {
        field: 'forstaTemplateCode',
        headerName: 'Template Project Code',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'ftc_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params?.data?.forstaTemplateName
          };
        },
        enableRowGroup: true,
      },
      {
        field: 'forstaTemplateName',
        headerName: 'Template Project Name',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'ftn_f',
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params?.data?.forstaTemplateCode
          };
        },
        enableRowGroup: true,
      },
      // forstaTemplateVersion: string;
      {
        field: 'forstaTemplateVersion',
        headerName: 'Template Version',
        hide: true,
        filterId: 'ftv_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        enableRowGroup: true,
      },
      // // END Forsta Template details
      ]
  },
  //
  // // START ids
  {
    headerName: 'IDs',
    valueFormatter: '',
    children: [
      // surveyDataId: number;
      {
        field: 'surveyDataId',
        headerName: 'Survey Data ID',
        cellDataType: 'number',
        hide: true,
        filter: ColumnFilterType.NUMBER,
        filterId: 'sd_id',
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      },
      // surveyId: number;
      {
        field: 'surveyId',
        headerName: 'Survey ID',
        cellDataType: 'number',
        hide: true,
        filter: ColumnFilterType.NUMBER,
        filterId: 's_id',
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      },
      // surveyTemplateId: number;
      {
        field: 'surveyTemplateId',
        headerName: 'Survey Template ID',
        cellDataType: 'number',
        hide: true,
        filter: ColumnFilterType.NUMBER,
        filterId: 'st_id',
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']},
        enableRowGroup: true,
      }
      // // END ids
    ]
  }
];
