<ag-task (goBackEvent)="goBackEvent.emit($event)" [isUrlMode]="isUrlMode" [selectedFeatureMode]="selectedFeatureMode"
         [sidebarEl]="featureComponentSidebarEl" [urlModeGoBackUrl]="urlModeGoBackUrl" class="full-height-container"
         id="task-component">
    <ng-template agClassifier="{{FeatureComponentMode.UPLOAD}}">
        <verbatim-file-upload
                class="task-component-detail full-height-container"></verbatim-file-upload>
    </ng-template>
    <ng-template agClassifier="{{FeatureComponentMode.DETAIL}}">
        <verbatim-file-detail
                class="task-component-detail full-height-container"></verbatim-file-detail>
    </ng-template>
</ag-task>
