import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DownloadRequestService} from "@data/download-request/download.request.service";
import {Util} from "@data/util/util";
import {ActivatedRoute, Router} from "@angular/router";
import {BaseComponent} from "@pages/base.component";
import {FormControl, FormGroup} from "@angular/forms";
import {FeatureComponentMode} from "@data/enums/data.enums";
import {NavigationService} from "@core/api/navigation.service";
import {AutomatedReport, DownloadRequest} from "@data/interefaces/download.request.interfaces";

@Component({
  selector: 'download-request-add-legacy',
  templateUrl: './download-request-add-legacy.component.html',
  styleUrls: ['./download-request-add-legacy.component.scss'],
})
export class DownloadRequestAddLegacyComponent extends BaseComponent {

  @Input() selectedFeatureMode: FeatureComponentMode | undefined;
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  auxLinks: Map<string, string>;
  downloadParameters: string = "";
  isUrlMode: boolean = false;
  urlModeGoBackUrl: string = "/data-management/download/request";
  protected products: AutomatedReport[] = [];
  protected isSaving = false;
  protected formGroup: FormGroup<{ [K in keyof DownloadRequest]?: FormControl<any> }> = new FormGroup<any>([]);
  protected readonly util = Util;
  protected saveAndNew = false;
  protected saveAndClose = false;
  protected title: string = 'Create New Parameter Request';

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _downloadRequestService: DownloadRequestService,
    private router: Router,
    private _navigationService: NavigationService
  ) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
    this.initFormGroup();
  }

  public ngAfterViewInit(): void {
    this._downloadRequestService.getAutomatedReports().then(result => {
      this.products = result;

      //Prefill product option if URL query specified it
      this._activatedRoute.queryParams.subscribe(params => {
        const productFromURLQuery = params['product'] && this.products.map((element) => element.automatedReportCode).includes(params['product']) ? params['product'] : '';
        const parameterFromURLQuery = params['parameter'] ? decodeURI(params['parameter']) : "";
        this.formGroup.patchValue({
          downloadType: productFromURLQuery,
          downloadParameters: parameterFromURLQuery
        });
      });
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => this.displayProgressBar(false));
  }

  onSelectionChange(): void {
    this.router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {product: this.formGroup.value.downloadType},
      queryParamsHandling: 'merge', // merge with existing query params
    });
  }

  onTextAreaChange(): void {
    this.router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {parameter: encodeURI(this.formGroup.value.downloadParameters)},
      queryParamsHandling: 'merge', // merge with existing query params
    });
  }

  protected save() {
    this.isSaving = true;
    this.formGroup.value.downloadParameters = JSON.parse(this.formGroup.value.downloadParameters);
    this.formGroup.value.automatedReport = {automatedReportCode: this.formGroup.value.downloadType}
    this._downloadRequestService.create(Util.mapFormGroupValuesToObject<DownloadRequest>(this.formGroup.value)).then(result => {
      this.isSaving = false;
      this.navigateToUrl(['/data-management', 'download', 'request', result.id!.toString(), 'detail']);
    }, err => {
      console.log(err);
      this.isSaving = false;
    });
  }

  protected onSaveClick() {
    this.saveAndNew = false;
    this.saveAndClose = false;
  }

  protected onSaveAndNewClick() {
    this.saveAndNew = true;
    this.saveAndClose = false;
  }

  protected onSaveAndCloseClick() {
    this.saveAndNew = false;
    this.saveAndClose = true;
  }

  protected close() {
    this._navigationService.back(this.urlModeGoBackUrl, ["task"]);
  }

  protected resetForm() {
    this.initFormGroup();
  }

  private initFormGroup() {
    this.formGroup = new FormGroup<{ [K in keyof DownloadRequest]?: FormControl<any> }>(
      {
        downloadType: new FormControl({
          value: "",
          disabled: false
        }),
        downloadParameters: new FormControl({
          value: "",
          disabled: false
        }),
      }
    )
  }


}


