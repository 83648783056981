import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType, LinkType, ResponseType} from "@data/enums/data.enums";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {QuilliupForm} from "@data/enums/quilliup.enum";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {Util} from "@data/util/util";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {SelectHeaderComponent} from "@pages/segments/grid/columns/select-header/select-header.component";
import {ResetFilterComponent} from "@pages/segments/grid/filters/reset-filter/reset-filter.component";

export let pageContext: {
  [key: string]: boolean | string | undefined
  p_f: string | undefined;
  mc_f: string | undefined;
  mn_f: string | undefined;
  ic_f: string | undefined;
  in_f: string | undefined;
  ss_f: string | undefined;
  sti_f: string | undefined;
  sc_f: string | undefined;
  rc_f: string | undefined;
  pc_f: string | undefined;
  olc_f: string | undefined;
  rs_f: string | undefined;
  rp_f: string | undefined;
  rt_f: string | undefined;
  a_f: string | undefined;
  icc_f: string | undefined;
  icl_f: string | undefined;
  idc_f: string | undefined;
  idl_f: string | undefined;
  rcc_f: string | undefined;
  rcl_f: string | undefined;
  rl_f: string | undefined;
  rdc_f: string | undefined;
  rdl_f: string | undefined;
  lrc_f: string | undefined;
  lrl_f: string | undefined;
  mfn_f: string | undefined;
  mln_f: string | undefined;
  me_f: string | undefined;
  mjt_f: string | undefined;
  myir_f: string | undefined;
  mt_f: string | undefined;
  mcp_f: string | undefined;
  mct_r: string | undefined;
  ml_f: string | undefined;
  pyt_f: string | undefined;
  rfn_f: string | undefined;
  rlb_f: string | undefined;
  rln_f: string | undefined;
  re_f: string | undefined;
  rjt_f: string | undefined;
  ryir_f: string | undefined;
  ryr_f: string | undefined;
  rtel_f: string | undefined;
  rcp_f: string | undefined;
  rct_f: string | undefined;
  bpc_f: string | undefined;
  bpl_f: string | undefined;
  abc_f: string | undefined;
  bcc_f: string | undefined;
  bcl_f: string | undefined;
  cc_f: string | undefined;
  cl_f: string | undefined;
  ssrc_f: string | undefined;
  ssrl_f: string | undefined;
  sssc_f: string | undefined;
  sssl_f: string | undefined;
  rsrc_f: string | undefined;
  rssl_f: string | undefined;
  rssc_f: string | undefined;
  snrsc_f: string | undefined;
  snrsl_f: string | undefined;
  nbc_f: string | undefined;
  nbl_f: string | undefined;
  nc_f: string | undefined;
  nn_f: string | undefined;
  rde_f: string | undefined;
  bpe_f: string | undefined;
  ce_f: string | undefined;
  bce_f: string | undefined;
  rsd_f: string | undefined;
  red_f: string | undefined;
  rli_f: string | undefined;
  rlu_f: string | undefined;
  su_f: string | undefined;
  rri_f: string | undefined;
  en_f: string | undefined;
  mtc_f: string | undefined;
  mfc_f: string | undefined;
  md_f: string | undefined;
  mh_f: string | undefined;
  rti_f: string | undefined;
  fmd_f: string | undefined;
  lmd_f: string | undefined;
  lms_f: string | undefined;
  tm_f: string | undefined;
  ir_f: string | undefined;
  iv_f: string | undefined;
  pn_f: string | undefined;
  fus_f: string | undefined;
  ntp_f: string | undefined;
  rf_f: string | undefined;
  scc_f: string | undefined;
  rfs_f: string | undefined;
  rfc_f: string | undefined;
  rffrcd_i: undefined,
  rffrcd_f: undefined,
  rffr_i: undefined,
  rffr_f: undefined,
  rfp_f: string | undefined;
  si_f: string | undefined;
  sri_f: string | undefined;
} = {
  p_f: undefined,
  mc_f: undefined,
  mn_f: undefined,
  ic_f: undefined,
  in_f: undefined,
  ss_f: undefined,
  sti_f: undefined,
  sc_f: undefined,
  rc_f: undefined,
  pc_f: undefined,
  olc_f: undefined,
  rs_f: undefined,
  rp_f: undefined,
  rt_f: undefined,
  a_f: undefined,
  icc_f: undefined,
  icl_f: undefined,
  idc_f: undefined,
  idl_f: undefined,
  rcc_f: undefined,
  rcl_f: undefined,
  rl_f: undefined,
  rdc_f: undefined,
  rdl_f: undefined,
  lrc_f: undefined,
  lrl_f: undefined,
  mfn_f: undefined,
  mln_f: undefined,
  me_f: undefined,
  mjt_f: undefined,
  myir_f: undefined,
  mt_f: undefined,
  mcp_f: undefined,
  mct_r: undefined,
  ml_f: undefined,
  pyt_f: undefined,
  rfn_f: undefined,
  rlb_f: undefined,
  rln_f: undefined,
  re_f: undefined,
  rjt_f: undefined,
  ryir_f: undefined,
  ryr_f: undefined,
  rtel_f: undefined,
  rcp_f: undefined,
  rct_f: undefined,
  bpc_f: undefined,
  bpl_f: undefined,
  abc_f: undefined,
  bcc_f: undefined,
  bcl_f: undefined,
  cc_f: undefined,
  cl_f: undefined,
  ssrc_f: undefined,
  ssrl_f: undefined,
  sssc_f: undefined,
  sssl_f: undefined,
  rsrc_f: undefined,
  rssl_f: undefined,
  rssc_f: undefined,
  snrsc_f: undefined,
  snrsl_f: undefined,
  nbc_f: undefined,
  nbl_f: undefined,
  nc_f: undefined,
  nn_f: undefined,
  rde_f: undefined,
  bpe_f: undefined,
  ce_f: undefined,
  bce_f: undefined,
  rsd_f: undefined,
  red_f: undefined,
  rli_f: undefined,
  rlu_f: undefined,
  su_f: undefined,
  rri_f: undefined,
  en_f: undefined,
  mtc_f: undefined,
  mfc_f: undefined,
  md_f: undefined,
  mh_f: undefined,
  rti_f: undefined,
  fmd_f: undefined,
  lmd_f: undefined,
  lms_f: undefined,
  tm_f: undefined,
  ir_f: undefined,
  iv_f: undefined,
  pn_f: undefined,
  fus_f: undefined,
  ntp_f: undefined,
  rf_f: undefined,
  scc_f: undefined,
  rfs_f: undefined,
  rfc_f: undefined,
  rffrcd_i: undefined,
  rffrcd_f: undefined,
  rffr_i: undefined,
  rffr_f: undefined,
  rfp_f: undefined,
  si_f: undefined,
  sri_f: undefined,
};

export let periods: string[] = [];
export let marketCodes: string[] = [];
export let surveyCodes: string[] = [];

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    maxWidth: 75,
    field: 'select',
    headerName: '',
    hide: false,
    filter: false,
    headerComponent: SelectHeaderComponent,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    pinned: "left",
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRenderer: SelectColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        showFlag: params.data.responseType == ResponseType.INVALID,
        flagIcon: "fa-exclamation",
        flagColor: "red",
        toolTip: 'Invalid'
      }
    }
  },
  {
    headerName: 'Data Filter', valueFormatter: '', children: [
      {
        field: 'period',
        headerName: 'Period',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'p_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: periods,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            closeOnApply: true,
            suppressSorting: true,
            buttons: ['apply', 'reset']
          }
      },
      {
        field: 'marketCode',
        headerName: 'Market Code',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'mc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: marketCodes,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            closeOnApply: true,
            buttons: ['apply', 'reset']
          },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketName
          };
        }
      },
      {
        field: 'surveyCode',
        headerName: 'Survey Code',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'sc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: surveyCodes,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            closeOnApply: true,
            buttons: ['apply', 'reset']
          }
      }
    ]
  },
  {
    headerName: 'Response', valueFormatter: '', children: [
      {
        field: 'marketName',
        headerName: 'Market Name',
        hide: false,
        filter: ColumnFilterType.TEXT,
        filterId: 'mn_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketCode
          };
        }
      },
      {
        field: 'industryCode',
        headerName: 'Industry Code',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'ic_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryName
          };
        }
      },
      {
        field: 'industryName',
        headerName: 'Industry Name',
        hide: true,
        filter: ColumnFilterType.TEXT,
        filterId: 'inc_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryCode
          };
        }
      },
      {
        field: 'surveySubject',
        headerName: 'Survey Subject',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'ss_f',
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent
      },
      {field: 'remoteRespId', headerName: 'Remote Resp ID', hide: false, filterId: 'rr_f'},
      {
        field: 'responseCode',
        headerName: 'Response Code',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'rc_f',
        filterParams: {buttons: ['reset']},
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View Responses in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.RESPONSES
                },
                {
                  text: "View Ratings in Quilliup",
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.RATINGS
                }
              ]
          };
        }
      },
      {
        field: 'personCode',
        headerName: 'Person Code',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'pc_f',
        filterParams: {buttons: ['reset']},
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.email,
          };
        }
      },
      {
        field: 'openLinkCode',
        headerName: 'Open Link Code',
        hide: false,
        filterId: 'olc_f',
        filter: ColumnFilterType.DROPDOWN
      },
      {
        field: 'responseStatus',
        headerName: 'Response Status',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'rs_f',
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent
      },
      {field: 'responseProgress', headerName: 'Response Progress', hide: false, filterId: 'rp_f'},
      {
        field: 'responseProgress', headerName: 'Response Progress %', hide: false, filterId: 'rpp_f',
        filter: ColumnFilterType.TEXT,
        valueGetter: (params) => {
          return Util.getFormattedResponseProgressPercent(params.data.responseProgress);
        }
      },
      {
        field: 'responseType', headerName: 'Response Type', hide: false, filterId: 'rt_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
        cellRenderer: StatusColumnComponent
      },
      {field: 'alerts', headerName: 'Alerts', hide: false, filterId: 'a_f'},
    ]
  },
  {
    headerName: 'Invitation Demographics', valueFormatter: '', children: [
      {
        field: 'invitationCompanyCode', headerName: 'Invitation Company Code', hide: false, filterId: 'icc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.invitationCompanyLabel
          };
        }
      },
      {
        field: 'invitationCompanyLabel', headerName: 'Invitation Company Label', hide: true, filterId: 'icl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.invitationCompanyCode
          };
        }
      },
      {
        field: 'invitationDivisionCodes', headerName: 'Invitation Division Codes', hide: false, filterId: 'idc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.invitationDivisionLabels
          };
        }
      },
      {
        field: 'invitationDivisionLabels', headerName: 'Invitation Division Labels', hide: true, filterId: 'idl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.invitationDivisionCodes
          };
        }
      }
    ]
  }, {
    headerName: 'Response Demographics', valueFormatter: '', children: [
      {
        field: 'respondentCompanyCode', headerName: 'Respondent Company Code', hide: true, filterId: 'rcc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.respondentCompanyLabel
          };
        }
      },
      {
        field: 'respondentCompanyLabel', headerName: 'Respondent Company Label', hide: true, filterId: 'rcl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.respondentCompanyCode
          };
        }
      },
      {
        field: 'respondentDivisionCodes', headerName: 'Respondent Division Codes', hide: true, filterId: 'rdc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.respondentDivisionLabels
          };
        }
      },
      {
        field: 'respondentDivisionLabels', headerName: 'Respondent Division Labels', hide: true, filterId: 'rdl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.respondentDivisionCodes
          };
        }
      },
      {
        field: 'responseFirstName',
        headerName: 'Response First Name',
        hide: false,
        filterId: 'rfn_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'responseLastName',
        headerName: 'Response Last Name',
        hide: false,
        filterId: 'rln_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'responseEmail', headerName: 'Response Email', hide: false, filterId: 're_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.responseEmail,
            type: 'text',
          };
        }
      },
      {
        field: 'responseJobTitle',
        headerName: 'Response Job Title',
        hide: false,
        filterId: 'rjt_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'responseYearsInRole',
        headerName: 'Response Years In Role',
        hide: true,
        filterId: 'ryir_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'responseTelephone',
        headerName: 'Response Telephone',
        hide: false,
        filterId: 'rtel_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'responseCellPhone',
        headerName: 'Response Cell Phone',
        hide: false,
        filterId: 'rcp_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'responseCity',
        headerName: 'Response City',
        hide: true,
        filterId: 'rct_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'responseLocale', headerName: 'Response Locale', hide: false, filterId: 'rl_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
        cellRenderer: StatusColumnComponent
      }
    ]
  },
  {
    headerName: 'Response Selections', valueFormatter: '', children: [
      {
        field: 'localRegionCodes', headerName: 'Local Region Codes', hide: true, filterId: 'lrc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.localRegionLabels
          };
        }
      },
      {
        field: 'localRegionLabels', headerName: 'Local Region Labels', hide: true, filterId: 'lrl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.localRegionCodes
          };
        }
      },
      {
        field: 'seniorityCode', headerName: 'Seniority Code', hide: false, filterId: 'sc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.seniorityLabel
          };
        }
      },
      {
        field: 'seniorityLabel', headerName: 'Seniority Label', hide: true, filterId: 'sl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.seniorityCode
          };
        }
      },
      {
        field: 'roleCodes', headerName: 'Role Codes', hide: false, filterId: 'rc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.roleLabels
          };
        }
      },
      {
        field: 'roleLabels', headerName: 'Role Labels', hide: true, filterId: 'rlb_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.roleCodes
          };
        }
      },
      {
        field: 'brPlCode', headerName: 'BR/PL Code', hide: false, filterId: 'bpc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.brPlLabel
          };
        }
      },
      {
        field: 'brPlLabel', headerName: 'BR/PL Label', hide: true, filterId: 'bpl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.brPlCode
          };
        }
      },
      {
        field: 'allBuCategories', headerName: 'All BU-Categories?', hide: false, filterId: 'abc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, cellRenderer: CircleCheckColumnComponent, buttons: ['reset']},
        cellRenderer: CircleCheckColumnComponent
      },
      {
        field: 'buCategoryCodes', headerName: 'BU-Category Codes', hide: false, filterId: 'bcc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.buCategoryLabels,
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.BU_CATEGORIES
                }
              ]
          };
        }
      },
      {
        field: 'buCategoryLabels', headerName: 'BU-Category Labels', hide: true, filterId: 'bcl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.buCategoryCodes
          };
        }
      },
      {
        field: 'channelCodes', headerName: 'Channel Codes', hide: false, filterId: 'cc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.channelLabels,
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.CHANNELS
                },
              ]
          };
        }
      },
      {
        field: 'channelLabels', headerName: 'Channel Labels', hide: false, filterId: 'cl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.channelCodes
          };
        }
      },
      {
        field: 'selectedSubjectRollupCodes',
        headerName: 'Selected Subject Rollup Codes',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'ssrc_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.selectedSubjectRollupLabels
          };
        }
      },
      {
        field: 'selectedSubjectRollupLabels',
        headerName: 'Selected Subject Rollup Labels',
        hide: true,
        filterId: 'ssrl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.selectedSubjectRollupCodes
          };
        }
      },
      {
        field: 'selectedSubjectSingleCodes',
        headerName: 'Selected Subject Single Codes',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'sssc_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.selectedSubjectSingleLabels,
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.SUBJECT_COMPANIES
                }
              ]
          };
        }
      },
      {
        field: 'selectedSubjectSingleLabels',
        headerName: 'Selected Subject Single Labels',
        hide: true,
        filterId: 'sssl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.selectedSubjectSingleCodes
          };
        }
      },
      {
        field: 'ratedSubjectRollupCodes', headerName: 'Rated Subject Rollup Codes', hide: false, filterId: 'rsrc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.ratedSubjectRollupLabels
          };
        }
      },
      {
        field: 'ratedSubjectRollupLabels', headerName: 'Rated Subject Rollup Labels', hide: true, filterId: 'rsrl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.ratedSubjectRollupCodes
          };
        }
      },
      {
        field: 'ratedSubjectSingleCodes', headerName: 'Rated Subject Single Codes', hide: false, filterId: 'rssc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.ratedSubjectSingleLabels
          };
        }
      },
      {
        field: 'ratedSubjectSingleLabels', headerName: 'Rated Subject Single Labels', hide: true, filterId: 'rssl_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.ratedSubjectSingleCodes
          };
        }
      },
      {
        field: 'selectedNotRatedSingleCodes',
        headerName: 'Selected Not Rated Single Codes',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'snrsc_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.selectedNotRatedSingleLabels
          };
        }
      },
      {
        field: 'selectedNotRatedSingleLabels',
        headerName: 'Selected Not Rated Single Labels',
        hide: true,
        filterId: 'snrsl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.selectedNotRatedSingleCodes
          };
        }
      }
    ]
  },

  {
    headerName: 'Mailing Demographics', valueFormatter: '', children: [
      {
        field: 'mailingFirstName',
        headerName: 'Mailing First Name',
        hide: false,
        filterId: 'mfn_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'mailingLastName',
        headerName: 'Mailing Last Name',
        hide: false,
        filterId: 'mln_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'mailingEmail', headerName: 'Mailing Email', hide: false, filterId: 'me_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.mailingEmail,
            type: 'text',
          };
        }
      },
      {
        field: 'mailingJobTitle',
        headerName: 'Mailing Job Title',
        hide: false,
        filterId: 'mjt_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'mailingYearsInRole', headerName: 'Mailing Years In Role', hide: true, filterId: 'myir_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'mailingTelephone',
        headerName: 'Mailing Telephone',
        hide: false,
        filterId: 'mt_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'mailingCellPhone',
        headerName: 'Mailing Cell Phone',
        hide: true,
        filterId: 'mcp_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'mailingCity',
        headerName: 'Mailing City',
        hide: true,
        filterId: 'mct_r',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'mailingLocale', headerName: 'Mailing Locale', hide: false, filterId: 'ml_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'priorYearTelephone',
        headerName: 'Prior Year Telephone',
        hide: true,
        filterId: 'pyt_f',
        filterParams: {buttons: ['reset']}
      },
    ]
  },
  {
    headerName: 'Nominations', valueFormatter: '', children: [
      {
        field: 'nominatedByCodes', headerName: 'Nominated By Codes', hide: true, filterId: 'nbc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.nominatedByLabels
          };
        }
      },
      {
        field: 'nominatedByLabels', headerName: 'Nominated By Labels', hide: true, filterId: 'nbl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.nominatedByCodes
          };
        }
      },
      {
        field: 'nominatedCategories',
        headerName: 'Nominated Categories',
        hide: true,
        filterId: 'nc_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'nominationNotes',
        headerName: 'Nomination Notes',
        hide: true,
        filterId: 'nn_f',
        filterParams: {buttons: ['reset']}
      }
    ]
  },
  {
    headerName: 'Response Exclusions', valueFormatter: '', children: [
      {
        field: 'respondentDivisionExclusions',
        headerName: 'Respondent Division Exclusions',
        hide: true,
        filterId: 'rde_f',
        filterParams: {buttons: ['reset']},
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.RESPONDENT_COMPANY_EXCLUSIONS
                },
              ]
          };
        }
      },
      {
        field: 'brPlExclusions', headerName: 'BR/PL Exclusions', hide: true, filterId: 'bpe_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.BR_PL_EXCLUSIONS
                },
              ]
          };
        }
      },
      {
        field: 'channelExclusions', headerName: 'Channel Exclusions', hide: true, filterId: 'ce_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.CHANNEL_EXCLUSIONS
                },
              ]
          };
        }
      },
      {
        field: 'buCategoryExclusions', headerName: 'BU Category Exclusions', hide: true, filterId: 'bce_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.BU_CATEGORY_EXCLUSIONS
                },
              ]
          };
        }
      },
      {
        field: 'localRegionExclusions', headerName: 'Local Region Exclusions', hide: true, filterId: 'lce_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.LOCAL_REGION_EXCLUSIONS
                },
              ]
          };
        }
      },
    ]
  },
  {
    headerName: 'Response', valueFormatter: '', children: [
      {
        field: 'responseStartDatetime', headerName: 'Response Start Datetime', hide: false, filterId: 'rsd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'responseEndDatetime', headerName: 'Response End Datetime', hide: false, filterId: 'red_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'responseLastImported', headerName: 'Response Last Imported', hide: true, filterId: 'rli_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'responseLastUpdated', headerName: 'Response Last Updated', hide: true, filterId: 'rlu_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'surveyUrl', headerName: 'Survey URL', hide: false, filterId: 'su_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.surveyUrl,
            type: 'link',
          };
        }
      },
    ]
  },
  {
    headerName: 'Mailing', valueFormatter: '', children: [
      {
        field: 'endorserName',
        headerName: 'Endorser Name',
        hide: true,
        filterId: 'en_f',
        filterParams: {buttons: ['reset']},
      },
      {
        field: 'mailoutTemplateCode', headerName: 'Mailout Template Code', hide: false, filterId: 'mtc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View Code in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.MAILOUT_TEMPLATES
                },
                {
                  text: "View Label in Quilliup",
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.MAILOUT_TEMPLATE_LABELS
                }
              ]
          };
        }
      },
      {
        field: 'mailoutFieldSetCode', headerName: 'Mailout Field Set Code', hide: false, filterId: 'mfsc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View Code in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.MAILOUT_FIELD_SETS
                },
                {
                  text: "View Label in Quilliup",
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.MAILOUT_FIELD_SET_LABELS
                }
              ]
          };
        }
      },
      {
        field: 'mailoutDatetime',
        headerName: 'Mailout Datetime',
        hide: false,
        filterId: 'md_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'remoteTaskId',
        headerName: 'Remote Task ID',
        hide: false,
        filterId: 'rti_f',
        filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      },
      {
        field: 'mailoutHistory',
        headerName: 'Mailout History',
        hide: true,
        filterId: 'mh_f',
        filterParams: {buttons: ['reset']},
      },
      {
        field: 'firstMailingDate', headerName: 'First Mailing Date', hide: true, filterId: 'fmd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'lastMailingDate', headerName: 'Last Mailing Date', hide: true, filterId: 'lmd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'lastMailingStatus', headerName: 'Last Mailing Status', hide: true, filterId: 'lms_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'timesMailed',
        headerName: 'Times Mailed',
        hide: true,
        filterId: 'tm_f',
        filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']},
      },
    ]
  },
  {
    headerName: 'Invalidations', valueFormatter: '', children: [
      {
        field: 'invalidateRatings', headerName: 'Invalidate Ratings', hide: false, filterId: 'ir_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, cellRenderer: CircleCheckColumnComponent, buttons: ['reset']},
        cellRenderer: CircleCheckColumnComponent
      },
      {
        field: 'invalidateVerbatim',
        headerName: 'Invalidate Verbatim?',
        hide: false,
        filterId: 'iv_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, cellRenderer: CircleCheckColumnComponent, buttons: ['reset']},
        cellRenderer: CircleCheckColumnComponent
      }
    ]
  },
  {
    headerName: 'Tracking', valueFormatter: '', children: [

      {field: 'pmNotes', headerName: 'PM Notes', hide: false, filterId: 'pn_f'},
      {
        field: 'respondentCompanyPriority',
        headerName: 'Respondent Company Priority',
        hide: true,
        filterId: 'rcp_f',
        filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      },
      {
        field: 'notesToPM',
        headerName: 'Notes To PM',
        hide: true,
        filterId: 'ntp_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'ratingFrequency', headerName: 'Rating Frequency', hide: true, filterId: 'rf_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.RATINGS
                },
              ]
          };
        }
      },
      {
        field: 'surveyCommentCount', headerName: 'Survey Comment Count', hide: true, filterId: 'scc_f',
        filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']},
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.VERBATIM
                },
              ]
          };
        }
      },
    ]
  },
  {
    headerName: 'Response Files', valueFormatter: '', children: [
      {
        field: 'responseFilesStatus', headerName: 'Response Files Status', hide: false, filterId: 'rfs_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'responseFileForInterviewersCreatedDatetime',
        headerName: 'Response File For Interviewers Created Datetime',
        hide: true,
        filterId: 'rfficd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'responseFilesForRespondentsCreatedDatetime',
        headerName: 'Response Files For Respondents Created Datetime',
        hide: true,
        filterId: 'rffrcd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'responseFileForInterviewers',
        headerName: 'Response File For Interviewers',
        hide: false,
        filterId: 'rffi_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.responseFileForInterviewers,
            type: 'link',
          };
        }
      },
      {
        field: 'responseFileForRespondents',
        headerName: 'Response File For Respondents',
        hide: false,
        filterId: 'rffr_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.responseFileForRespondents,
            type: 'link',
          };
        }
      },
      {
        field: 'responseFilePassword',
        headerName: 'Response File Password',
        hide: true,
        filterId: 'rfp_f',
        filterParams: {buttons: ['reset']}
      },
    ]
  },
  {
    headerName: "ID's", valueFormatter: '', children: [
      {
        field: 'surveyId',
        headerName: 'Survey ID',
        hide: true,
        filterId: 'si_f',
        filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      },
      {
        field: 'surveyResponseId',
        headerName: 'Survey Response ID',
        hide: true,
        filterId: 'sri_f',
        filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      },
    ]
  },
  {
    field: "actions",
    headerName: "Actions",
    pinned: "right",
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: ResetFilterComponent,
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    cellRenderer: ActionColumnComponent,
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRendererParams:
      function (params: any) {
        return {
          actions: [
            {
              toolTip: "Edit",
              icon: "fa-pen-to-square",
              name: "Edit",
              routerLink: ['/forms', 'survey-respondents', 'survey-tracker', 'task', 'edit'],
              queryParams: {sri_f: params.data.surveyResponseId}
            }
          ]
        };
      }
  }
]
