import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {UniqueLinkInvitee} from "@data/interefaces/panel.interfaces";
import {ProcessStatus} from "@data/interefaces/extended.data.interfaces";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {ColumnFilterType} from "@data/enums/data.enums";

export interface UniqueLinkInviteeWithProcessStatus extends UniqueLinkInvitee, ProcessStatus {
}

export let displayTableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    headerName: 'Data Filter', children: [
      {
        field: 'surveyCode', headerName: 'Survey Code', pinned: "left", hide: false,
        cellRenderer: SelectColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            showFlag: params.data.processingNote != undefined,
            flagIcon: "fa-square-exclamation",
            flagColor: "red",
            toolTip: params.data.processingNote
          }
        }
      }
    ]
  },
  {
    headerName: 'Invitation', children: [
      {field: 'remoteRespId', headerName: 'Remote Resp ID'},
      {field: 'responseCode', headerName: 'Response Code', autoHeight: true},
      {field: 'personCode', headerName: 'Person Code'},
      {field: 'responseStatus', headerName: 'Response Status'},
      {
        field: 'inviteeUrl', headerName: 'Invitee Url',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.inviteeUrl,
            type: 'link',
          };
        }
      },
      {
        field: 'invalid', headerName: 'Invalid?', hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          cellRendererParams: function (params: any) {
            return {
              invertIcons: true
            };
          },
          buttons: ['reset']
        },
        filterId: 'i_f',
        cellRenderer: CircleCheckColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            invertIcons: true
          };
        },
      },
      {field: 'invitationCompanyCode', headerName: 'Invitation Company Code', autoHeight: true},
      {field: 'invitationCompanyLabel', headerName: 'Invitation Company Label'},
      {field: 'invitationDivisionCodes', headerName: 'Invitation Division Codes',},
      {field: 'invitationDivisionLabels', headerName: 'Invitation Division Labels'}
    ]
  },
  {
    headerName: 'Demographics', children: [
      {field: 'firstName', headerName: 'First Name'},
      {field: 'lastName', headerName: 'Last Name'},
      {
        field: 'email', headerName: 'Email',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.email,
            type: 'text',
          };
        }
      },
      {field: 'telephone', headerName: 'Telephone'},
      {field: 'cellPhone', headerName: 'Cell Phone'},
      {field: 'city', headerName: 'City'},
      {field: 'jobTitle', headerName: 'Job Title'},
      {field: 'yearsInRole', headerName: 'Years in Role'},
      {field: 'preFillContactInformation', headerName: 'Pre-fill Contact Information?', cellRenderer: CircleCheckColumnComponent},
      {field: 'surveyLanguage', headerName: 'Survey Language'}
    ]
  },
  {
    headerName: 'Nominations', children: [
      {field: 'nominatedByCodes', headerName: 'Nominated By Codes'},
      {field: 'nominatedByLabels', headerName: 'Nominated By Labels'},
      {field: 'categoryResponsibility', headerName: 'Category Responsibility'},
      {field: 'nominationNotes', headerName: 'Nomination Notes'}
    ]
  },
  {
    headerName: 'Pre-selections', children: [
      {field: 'preFillSurveySelections', headerName: 'Pre-fill Survey Selections?', cellRenderer: CircleCheckColumnComponent},
      {field: 'lockSurveySelections', headerName: 'Lock Survey Selections?', cellRenderer: CircleCheckColumnComponent},
      {field: 'showDivisionsCollapsed', headerName: 'Show Divisions Collapsed?', cellRenderer: CircleCheckColumnComponent},
      {field: 'startInReviewMode', headerName: 'Start in Review Mode?', cellRenderer: CircleCheckColumnComponent},
      {field: 'localRegionCodes', headerName: 'Local Region Codes'},
      {field: 'localRegionLabels', headerName: 'Local Region Labels'},
      {field: 'seniorityCode', headerName: 'Seniority Code'},
      {field: 'seniorityLabel', headerName: 'Seniority Label'},
      {field: 'roleCodes', headerName: 'Role Codes'},
      {field: 'roleLabels', headerName: 'Role Labels'},
      {field: 'allBuCategories', headerName: 'All BU-Categories?'},
      {field: 'buCategoryCodes', headerName: 'BU-Category Codes'},
      {field: 'buCategoryLabels', headerName: 'BU-Category Labels'},
      {field: 'channelCodes', headerName: 'Channel Codes'},
      {field: 'channelLabels', headerName: 'Channel Labels'},
      {field: 'brPlCode', headerName: 'BR/PL Code'},
      {field: 'brPlLabel', headerName: 'BR/PL Label'},
      {field: 'subjectRollupCodes', headerName: 'Subject Rollup Codes'},
      {field: 'subjectRollupLabels', headerName: 'Subject Rollup Labels'},
      {field: 'subjectSingleCodes', headerName: 'Subject Single Codes'},
      {field: 'subjectSingleLabels', headerName: 'Subject Single Labels'},
      {field: 'maxTotalCompaniesToRate', headerName: 'Max Total Companies to Rate'},
      {field: 'maxP1CompaniesToRate', headerName: 'Max P1 Companies to Rate', hide: true, filterId: ''},
      {field: 'maxP2CompaniesToRate', headerName: 'Max P2 Companies to Rate', hide: true, filterId: ''},
      {field: 'maxP3CompaniesToRate', headerName: 'Max P3 Companies to Rate', hide: true, filterId: ''}
    ]
  },
  {
    headerName: 'Mailouts', children: [
      {field: 'mailoutTemplateCode', headerName: 'Mailout Template Code', autoHeight: true,},
      {field: 'mailoutFieldSetCode', headerName: 'Mailout Field Set Code', autoHeight: true,},
      {field: 'mailoutDatetime', headerName: 'Mailout Datetime'},
      {field: 'remoteTaskId', headerName: 'Remote Task ID'},
      {
        field: 'mailoutHistory', headerName: 'Mailout History',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.mailoutHistory,
            type: 'text',
          };
        }
      },
      {
        field: 'firstMailingDate', headerName: 'First Mailing Date',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.firstMailingDate,
            type: 'text',
          };
        }
      },
      {
        field: 'lastMailingDate', headerName: 'Last Mailing Date',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.lastMailingDate,
            type: 'text',
          };
        }
      },
      {
        field: 'lastMailingStatus', headerName: 'Last Mailing Status',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.mailoutHistory,
            type: 'text',
          };
        }
      },
      {field: 'timesMailed', headerName: 'Times Mailed'}
    ]
  },
  {
    headerName: 'ID\'s', children: [
      {field: 'surveyId', headerName: 'Survey ID'},
      {field: 'surveyInviteeId', headerName: 'Invitation ID'}
    ]
  }
]
