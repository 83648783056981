import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {
  BatchProcessResult,
  OperationStatus,
  ProductPeriod,
  ProductPeriodReportLabel,
  ResultsetThresholds
} from "@data/interefaces/data.interfaces";
import {ProductCode} from "@data/interefaces/prodct.spec.interfaces";
import {Util} from "@data/util/util";

@Injectable({
  providedIn: 'root'
})
export class ProductSpecService {

  constructor(private _apiService: ApiService) {
  }

  public getProductPeriod(period: string, marketCode: string): Promise<ProductPeriod[]> {
    let queryParam: string[] = [];
    if (period) {
      queryParam.push(`period=${period}`);
    }
    if (marketCode) {
      queryParam.push(`marketCode=${marketCode}`);
    }
    return this._apiService.makeGetServiceCall<ProductPeriod[]>(`/v2/api/product-spec/product-period`, queryParam.join('&'));
  }

  public getProductPeriodById(period: string, productId: string): Promise<ProductPeriod> {
    let queryParams: string[] = [];
    Util.pushQueryParam("period", period, queryParams);
    Util.pushQueryParam("productId", productId, queryParams);
    return this._apiService.makeGetServiceCall<ProductPeriod>(`/v2/api/product-spec/product-period/${period}/${productId}`);
  }

  public getProductPeriodByPeriodIdList(productPeriodIdList: string): Promise<ProductPeriod[]> {
    return this._apiService.makeGetServiceCall<ProductPeriod[]>(`/v2/api/product-spec/product-period`, `productPeriodIdList=${productPeriodIdList}`);
  }

  public getProductPeriodThresholds(period?: string, marketCode?: string): Promise<ResultsetThresholds> {
    let queryParam: string[] = [];
    if (period) {
      queryParam.push(`period=${period}`);
    }
    if (marketCode) {
      queryParam.push(`marketCode=${marketCode}`);
    }

    return this._apiService.makeGetServiceCall<ResultsetThresholds>(`/v2/api/product-spec/product-period/thresholds`, queryParam.join('&'));
  }

  public bulkProcessProductPeriods(productPeriods: ProductPeriod[], mode: "update" | "insert") {
    return this._apiService.makePutServiceCall<{
      successRecordList: Array<{ productPeriodId: number, }>,
      failedRecordList: Array<{ productPeriodId: number, processing_note: string }>
    }>(mode == 'insert' ? `/v2/api/product-spec/product-period/bulk-insert` : `/v2/api/product-spec/product-period/bulk-update`, null, productPeriods);
  }

  public getProductReportLabel(productPeriodId: number, period: string, marketCode: string, industryCode: string, surveySubject: string) {
    return this._apiService.makeGetServiceCall<ProductPeriodReportLabel[]>(`/v2/api/product-spec/report-label`,
      `productPeriodId=${productPeriodId}&period=${period}&marketCode=${marketCode}&industryCode=${industryCode}&surveySubject=${surveySubject}`);
  }

  public updateProductPeriod(productPeriod: ProductPeriod) {
    return this._apiService.makePutServiceCall<OperationStatus>(`/v2/api/product-spec`, null, productPeriod);
  }

  public getProductCodes(): Promise<ProductCode[]> {
    return this._apiService.makeGetServiceCall<ProductCode[]>(`/v2/api/product-spec/product-code`, null);
  }

  public getLatestBatchProcess() {
    return this._apiService.makeGetServiceCall<BatchProcessResult>(`/v2/api/product-spec/product-period/bulk-process/latest`);
  }

  public startBatchProcess(data: ProductPeriod[], processType: string) {
    let queryParams: string[] = [];
    Util.pushQueryParam("processType", processType, queryParams);
    return this._apiService.makePutServiceCall<{ id: number }>(`/v2/api/product-spec/product-period/bulk-process/start`, queryParams.join('&'), data);
  }

}
