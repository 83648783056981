import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Util} from "@data/util/util";
import {TransferRunLog} from "@data/interefaces/data.interfaces";
import {ActivatedRoute} from "@angular/router";
import {TransferRunService} from "@data/transfer-run/transfer.run.service";
import {AgSidePanelComponent} from "@shared/ag-side-panel/ag-side-panel.component";
import {ExtendedTransferRun} from "@data/interefaces/extended.data.interfaces";
import {TableBaseComponent} from "@pages/table.base.component";
import {pageContext, tableColumns, transferDetailIdClickAction} from "@pages/transfer-run-detail/transfer-run-detail-messages/transfer-run-detail-messages.component.ds";
import {FilterChangedEvent} from "ag-grid-community";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'app-transfer-run-detail-messages',
  templateUrl: './transfer-run-detail-messages.component.html',
  styleUrls: ['./transfer-run-detail-messages.component.scss'],
  providers: [AgGridService]
})
export class TransferRunDetailMessagesComponent extends TableBaseComponent implements AfterViewInit {

  @Input() transferId: string | undefined;
  @Input() transferRun: ExtendedTransferRun | undefined;
  @Output() refreshViewRequired: EventEmitter<boolean> = new EventEmitter<boolean>();
  dataSource: TransferRunLog[] = [];
  refreshComplete: boolean = true;
  selectedTransferRunLog: TransferRunLog | undefined;
  @ViewChild("messageDetailPanel") messageDetailPanel: AgSidePanelComponent | null = null;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns;
  protected readonly util = Util;

  constructor(private _activatedRoute: ActivatedRoute, private _transferRunService: TransferRunService) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.updatePageContext();
  }

  ngAfterViewInit(): void {
    this.loadData().then(result => this.dataSource = result)
  }

  updatePageContext(updateContextUrl: boolean = true): void {
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }

  async refreshView() {
    this.refreshComplete = false;
    await this.loadData().then(result => {
      this.dataSource = result;
      this.refreshComplete = true;
      this.refreshViewRequired.emit(true);
    }).finally(() => {
      this.refreshComplete = true;
      this.displayProgressBar(false);
    })
  }


  onMessageDetailClick(transferRunLog: TransferRunLog) {
    this.selectedTransferRunLog = transferRunLog;
    this.messageDetailPanel?.toggle();
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this));
  }

  protected override initAfterGridReady() {
    this.gridApi.addEventListener(transferDetailIdClickAction, (event: any) => {
      this.onMessageDetailClick(event.detail.rowData);
    });
  }

  private async loadData(): Promise<TransferRunLog[]> {
    let returnArray: TransferRunLog[] = [];
    await this._transferRunService.getTransferLogs(this.transferId!).then(result => {
      returnArray = result;
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => {
      this.displayProgressBar(false);
    });
    return returnArray;
  }
}
