import {DetailBaseComponent} from "@pages/detail.base.component";
import {AfterViewInit, Component} from "@angular/core";
import {DownloadRequest, DownloadRequestDetailField, ViewDownloadRequest} from "@data/interefaces/download.request.interfaces";
import {Util} from "@data/util/util";
import {DownloadRequestUtil} from "@data/download-request/download.request.util";
import {DownloadRequestStatus} from "@data/enums/downloadRequest.enum";
import {FeatureComponentMode} from "@data/enums/data.enums";
import {FormControl, FormGroup} from "@angular/forms";
import {DownloadRequestService} from "@data/download-request/download.request.service";
import {OperationStatus} from "@data/interefaces/data.interfaces";

@Component({
  selector: 'download-request-detail',
  templateUrl: './download-request-detail.component.html',
  styleUrls: ['./download-request-detail.component.scss']
})
export class DownloadRequestDetailComponent extends DetailBaseComponent implements AfterViewInit {
  auxLinks: any;
  downloadRequestFields: DownloadRequestDetailField[] | undefined;
  override formGroup: FormGroup<{ [K in keyof DownloadRequest]?: FormControl<any> }> = new FormGroup<any>([]);
  override pageContext: {
    [key: string]: boolean | string | undefined;
    dri_f: string | undefined;
  } = {
    dri_f: undefined,
  };
  protected downloadRequest: DownloadRequest | undefined;
  protected viewDownloadRequest: ViewDownloadRequest | undefined;
  protected override readonly util = Util;
  protected readonly downloadRequestUtil = DownloadRequestUtil;
  protected readonly downloadStatus = DownloadRequestStatus;
  protected readonly FeatureComponentMode = FeatureComponentMode;
  protected override loading: boolean = false;
  protected isFilterActive: boolean = true;

  constructor(private _downloadRequestService: DownloadRequestService) {
    super();
  }

  ngAfterViewInit(): void {
  }

  override ngOnInit(): void {
    this.displayProgressBar(true);
    super.ngOnInit();
  }

  isDownloadRequestStatusSelected(downloadStatusEnum: DownloadRequestStatus) {
    return this.viewDownloadRequest?.processStatus == downloadStatusEnum.toString();
  }

  protected getContentDetails(): void {
    this.loadData();
  }

  protected initFormGroup(): void {
  }

  protected loadDropDownData(): void {
  }

  protected override saveOrUpdate() {
    return new Promise<OperationStatus>((resolve, reject) => {
    })
  }

  protected loadData(): void {
    // Get download request fields data
    this._downloadRequestService.getOneDownloadRequest(Number(this.pageContext.dri_f)).then(result => {
      this.downloadRequest = result;
      this.viewDownloadRequest = this.downloadRequestUtil.mapDownloadRequestToView(result);
      this.downloadRequestFields = [
        {key: 'Period', value: this.viewDownloadRequest.period!, cols: 1, rows: 1,},
        {key: 'Country', value: this.viewDownloadRequest.country!, cols: 1, rows: 1,},
        {key: 'Product Type', value: this.viewDownloadRequest.productType!, cols: 1, rows: 1,},

        {key: 'Product', value: this.viewDownloadRequest.product!, cols: 1, rows: 1,},
        {key: 'Sponsor', value: this.viewDownloadRequest.sponsor!, cols: 1, rows: 1,},
        {key: 'Language', value: this.viewDownloadRequest.language!, cols: 1, rows: 1,},

        {key: 'Requested By', value: this.viewDownloadRequest.requestedBy!, cols: 1, rows: 1,},
        {key: 'Request Date', value: new Date(this.viewDownloadRequest.requestDate!), cols: 1, rows: 1,},
        {key: 'Start', value: new Date(this.viewDownloadRequest.processStartDate!), cols: 1, rows: 1,},

        {key: 'End', value: new Date(this.viewDownloadRequest.processEndDate!), cols: 1, rows: 1,},
        {key: 'Status', value: this.viewDownloadRequest.processStatus!, cols: 1, rows: 1,},
        {key: 'SharePoint Folder', value: this.viewDownloadRequest.sharepointFolderUrl!, cols: 1, rows: 1,},

        {key: 'Parameters', value: JSON.stringify(this.viewDownloadRequest.downloadParameters, null, 4), cols: 1, rows: 3,},
        {key: 'Message', value: this.viewDownloadRequest.processStatusMessage!, cols: 1, rows: 3,},
      ];
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => this.displayProgressBar(false));
  }

  protected override getPageState(): any {
    return this.pageContext;
  }
}
