import {NgModule} from '@angular/core';
import {ApiService} from "./api/api.service";
import {SecurityService} from "@core/secutiry/security.service";
import {EventbusService} from "@core/event-bus/eventbus.service";
import {LocalStorageService} from "@core/api/local.storage.service";


@NgModule({
  providers: [ApiService, SecurityService, EventbusService, LocalStorageService]
})
export class CoreModule {
}
