import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType} from "@data/enums/data.enums";

export let pageContext: {
  [key: string]: boolean | string | undefined
  fs_f: string | undefined;
  ft_f: string | undefined;
  p_f: string | undefined;
  ps_f: string | undefined;
  tt_f: string | undefined;
  ts_f: string | undefined;
} = {
  fs_f: undefined,
  ft_f: undefined,
  p_f: undefined,
  ps_f: undefined,
  tt_f: undefined,
  ts_f: undefined
};

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    field: 'fromSchemaName',
    headerName: 'From Schema',
    hide: false,
    filter: ColumnFilterType.DROPDOWN,
    filterId: 'fs_f',
    filterParams: {
      buttons: ['reset']
    }
  },
  {
    field: 'fromTableName',
    headerName: 'From Table',
    hide: false,
    filter: ColumnFilterType.DROPDOWN,
    filterId: 'ft_f',
    filterParams: {
      buttons: ['reset']
    }
  },
  {
    field: 'path',
    headerName: 'Path',
    hide: false,
    filterId: 'p_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      buttons: ['reset']
    },
    cellDataType: false, // removes warning from console - Please either provide an object data type definition with a Value Formatter, or set "colDef.valueFormatter
    cellRenderer: (params: { value: string[]; }) => `${params.value ? params.value.join(' → ') : "-"}`,
  },
  {
    field: 'pathSteps',
    headerName: 'Number of Steps',
    hide: false,
    filter: ColumnFilterType.NUMBER,
    filterId: 'ps_f',
    filterParams: {
      buttons: ['reset']
    }
  },
  {
    field: 'schemaTableNameEnd',
    headerName: 'End Table',
    hide: false,
    filter: ColumnFilterType.DROPDOWN,
    filterId: 'tt_f',
    filterParams: {
      buttons: ['reset']
    }
  },
  {
    field: 'tableStatus',
    headerName: 'Table Status',
    hide: false,
    filter: ColumnFilterType.DROPDOWN,
    filterId: 'ts_f',
    filterParams: {
      buttons: ['reset']
    }
  }
];
