<mat-card class="confirmation-dialog">
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <i class="fa-sharp fa-light fa-xmark-large primary-color" mat-dialog-close></i>
  </mat-card-header>
  <mat-card-content mat-dialog-content>
    <p class="message">
      {{ data.message }}
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button *ngIf="data.hideCancel" class="secondary-button mg-r-s" mat-dialog-close>Cancel</button>
    <button [mat-dialog-close]="true" class="mg-r-s" color="primary" mat-raised-button>OK</button>
  </mat-card-actions>
</mat-card>
