import {Component} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {EventbusService} from "src/app/core/event-bus/eventbus.service";
import {ApplicationEvent, EventType} from "src/app/core/interfaces/data.interfaces";
import {AlertWindowComponent} from "@layout/alert/alert-window/alert-window.component";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  baseConfiguration: MatSnackBarConfig = {
    horizontalPosition: "right",
    verticalPosition: "bottom",
  };


  constructor(private _snackBar: MatSnackBar, private _eventService: EventbusService) {
    _eventService.register(EventType.NOTIFICATION, (event: ApplicationEvent) => this.displayMessage(event.data))
  }

  public displayMessage(data: string) {
    this._snackBar.openFromComponent(AlertWindowComponent, this.getAlertConfiguration(data));
  }

  getAlertConfiguration(data: any) {
    let config: MatSnackBarConfig;
    if (data.type && data.type == 'error') {
      config = this.baseConfiguration;
    } else {
      config = {...this.baseConfiguration}
      config.duration = 5000;
    }
    config.data = this.getAlertMessage(data);
    return config;
  }

  getAlertMessage(data: any) {
    if (typeof data == 'string') {
      return data;
    } else if (data.message) {
      return data.message
    }

    return JSON.stringify(data);
  }

}

