<div [showSkeleton]="this.loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <form [formGroup]="this.formGroup" class="full-height-container">
    <mat-card *ngIf="!this.loading" class="task-component-detail download-request-container backdrop" id="task-component-detail">
      <div class="header-row-info header-container-item flex-row">
        <mat-card-header class="header-title">
          <div>
            <mat-card-title class="primary-font-color">{{ this.title }}</mat-card-title>
            <mat-card-subtitle>Fill in the fields below to create new download request(s)</mat-card-subtitle>
          </div>
        </mat-card-header>
        <p hidden>There are currently 10 items in the queue</p>
      </div>
      <div class="header-row-buttons buttons-row flex-row container-item">
        <button (click)="this.onClearFiltersButtonClick()" class="secondary-button">
          <i class="fa-sharp fa-light fa-eraser"></i>
          <span>Clear Filters</span>
        </button>
      </div>
      <div class="drop-down-row container-item flex-row">
        <mat-form-field>
          <mat-label>Period</mat-label>
          <mat-select formControlName="period">
            <mat-option *ngFor="let period of this.periods"
                        [value]="period">
              {{ period.period }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Market</mat-label>
          <mat-select formControlName="market">
            <mat-option *ngFor="let market of this.markets"
                        [value]="market">
              {{ market.marketCode + ' - ' + market.marketName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Industry</mat-label>
          <mat-select formControlName="industry">
            <mat-option *ngFor="let industry of this.industries"
                        [value]="industry">
              {{ industry.industryCode + ' - ' + industry.industryName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Survey Subject</mat-label>
          <mat-select formControlName="surveySubject">
            <mat-option *ngFor="let surveySubject of this.surveySubjects"
                        [value]="surveySubject">
              {{ surveySubject.surveySubject }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Download Type</mat-label>
          <mat-select formControlName="downloadRequestType">
            <mat-option *ngFor="let downloadType of this.downloadRequestTypes"
                        [value]="downloadType">
              {{ downloadType.downloadRequestTypeDisplayValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Language</mat-label>
          <mat-select formControlName="locale">
            <mat-option *ngFor="let locale of this.locales"
                        [value]="locale">
              {{ locale.language + ' - ' + locale.languageName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="product-drop-down-row container-item flex-row">
        <ag-search-dropdown (itemsSelected)="this.onItemsSelected($event)" *ngIf="this.canShowProductSelection()" [dataSource]="this.products"
                            [disable]="this.disableProductDropdown"
                            [displayValuePropertyName]="'displayValue'" [showAllOption]=false
                            class="flex-grow" label="Search for Products"></ag-search-dropdown>
        <p *ngIf="this.noFilterDataFound()">No products found. Please adjust your search filters and try again.</p>
      </div>
      <div *ngIf="this.canShowSelectionWizard()" class="select-row select-wizard-row container-item">
        <ng-container [ngSwitch]="this.formGroup.controls.downloadRequestType?.value.downloadRequestTypeValue">
          <our-voice-report (downloadRequests)="this.onDownloadRequestsChange($event)"
                            (isValidSelectionData)="this.onIsValidSelectionData($event)"
                            (selectedProductsProcessed)="this.onSelectedProductsProcessed()"
                            *ngSwitchCase="this.OurVoiceDownloadTypeValue"
                            [currentBatchCode]="this.currentBatchCode"
                            [originalBatchCodeFromRetry]="this.originalBatchCodeFromRetry"
                            [reportTypes]="this.reportTypes"
                            [resetEvent]="this.resetEventSubject.asObservable()"
                            [retryEvent]="this.retryEventSubject.asObservable()"
                            [selectedDownloadType]="this.formGroup.controls.downloadRequestType?.value"
                            [selectedIndustry]="this.formGroup.controls.industry?.value"
                            [selectedLocale]="this.formGroup.controls.locale?.value"
                            [selectedMarket]="this.formGroup.controls.market?.value"
                            [selectedPeriod]="this.formGroup.controls.period?.value"
                            [selectedProducts]="this.selectedProducts"
                            [selectedSurveySubject]="this.formGroup.controls.surveySubject?.value"
                            [standardReportTypes]="this.standardReportTypes">
          </our-voice-report>
          <advantage-report (downloadRequests)="this.onDownloadRequestsChange($event)"
                            (isValidSelectionData)="this.onIsValidSelectionData($event)"
                            (selectedProductsProcessed)="this.onSelectedProductsProcessed()"
                            *ngSwitchDefault
                            [currentBatchCode]="this.currentBatchCode"
                            [originalBatchCodeFromRetry]="this.originalBatchCodeFromRetry"
                            [reportTypes]="this.reportTypes"
                            [resetEvent]="this.resetEventSubject.asObservable()"
                            [retryEvent]="this.retryEventSubject.asObservable()"
                            [selectedDownloadType]="this.formGroup.controls.downloadRequestType?.value"
                            [selectedIndustry]="this.formGroup.controls.industry?.value"
                            [selectedLocale]="this.formGroup.controls.locale?.value"
                            [selectedMarket]="this.formGroup.controls.market?.value"
                            [selectedPeriod]="this.formGroup.controls.period?.value"
                            [selectedProducts]="this.selectedProducts"
                            [selectedSurveySubject]="this.formGroup.controls.surveySubject?.value"
                            [standardReportTypes]="this.standardReportTypes">
          </advantage-report>
        </ng-container>
      </div>
      <div class="table-row table-container container-item">
        <ag-grid-angular (columnVisible)="this.onColumnVisible($event)"
                         (gridReady)="this.onGridReady($event)"
                         (modelUpdated)="this.onModelUpdated()"
                         (selectionChanged)="this.onRowSelectionChanged($event)"
                         *ngIf="this.canShowResultsGrid()"
                         [columnDefs]="this.tableColumns"
                         [gridOptions]="this.gridOptions"
                         [rowData]="this.rowData"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz ag-grid-flex record-display-table">
        </ag-grid-angular>
      </div>
      <div class="bottom-row info-row flex-row container-item">
        <p hidden>Estimated time to download: hh:mm</p>
      </div>

      <div class="bottom-row buttons-row flex-row container-item">
        <button (click)="this.requestClick()" [disabled]="!this.canRequestDownloads()" color="primary" mat-raised-button type="submit">
          <mat-icon class="material-symbols-outlined">save</mat-icon>
          {{ 'Request & Close' }}
        </button>
        <button (click)="this.closeClick()" mat-button type="button">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
          {{ 'Close' }}
        </button>
      </div>
    </mat-card>
  </form>
</div>
