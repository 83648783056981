<div class="data-container {{horizontalAlign ? 'flex-row' : 'flex-column'}}" id="datablock">
  <span [ngClass]="{'font-bold' : this.titleBold}" class="title {{horizontalAlign ? 'mg-r-s' : 'mg-b-s'}}">{{ title }}</span>
  <span *ngIf="!url && !isLongData && !isDate && !isJson && !isHtml" class="data">{{ data && data != ' ' ? data : '-' }}</span>

  <span *ngIf="isDate" class="data">{{ data && data != ' ' ? (data | date: 'short') : '-' }}</span>

  <div *ngIf="isHtml || isJson" class="data word-wrap-long-text">
    <span [innerHTML]="data && data != ' ' ? data  : '-'"></span>
    <ag-copy-text-button [data]="this.data"/>
    <ag-expand-dialog-button [title]="this.title!" [message]="this.data!" [isJson]="this.isJson!"/>
  </div>

  <div *ngIf="isLongData" class="long-data-container flex-row">
    <span class="long-data-text">{{ data && data != ' ' ? data : '-' }}</span>
    <ag-copy-link-button [data]="this.data"/>
  </div>

  <div *ngIf="url" class="url-container flex-row">
    <a [href]="url" class="url-link"
       target="_blank">{{ url }}</a>
    <ag-copy-link-button [data]="this.url"/>
  </div>
</div>
