import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {DataList} from "@data/interefaces/data.interfaces";

@Injectable({
  providedIn: 'root'
})
export class LovService {

  constructor(private _apiService: ApiService) {
  }

  public getApprovalStatusList(): Promise<DataList> {
    return this._apiService.makeGetServiceCall<DataList>(`/v2/api/lov/approval-status`);
  }

  public getMaskingOverrideList(): Promise<DataList> {
    return this._apiService.makeGetServiceCall<DataList>(`/v2/api/lov/masking-override`);
  }

  public getMarketList(): Promise<DataList> {
    return this._apiService.makeGetServiceCall<DataList>(`/v2/api/lov/market`);
  }

  public getPeriodList(): Promise<DataList> {
    return this._apiService.makeGetServiceCall<DataList>(`/v2/api/lov/period`);
  }

}
