<mat-grid-list [cols]="2" class="signin" rowHeight="4:3">
  <mat-grid-tile>
    <div>
      <h1>Welcome to the A2 Management Console!</h1>
      <p>Use this site to interact with the Confirmit Client Interface</p>
      <button (click)="signIn()" color="primary" mat-raised-button>Sign in</button>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <img alt="data trends" fill="" ngSrc="assets/undraw_data_trends_b0wg.svg" priority="">
  </mat-grid-tile>
</mat-grid-list>
