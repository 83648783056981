<div class="flex-row">
  <div class="search-box">
    <form>
      <mat-form-field appearance="fill" class="full-width" subscriptSizing="dynamic">
        <mat-label>Quick Search</mat-label>
        <input (click)="clearValue($event)" (keydown)="clearTypedText($event)" [formControl]="searchFormControl" [matAutocomplete]="auto" matInput type="text">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="getMenuDisplayName" autoActiveFirstOption>
          <mat-optgroup *ngFor="let searchOption of searchOptions | async" [label]="searchOption.group">
            <mat-option *ngFor="let menu of searchOption.menuItems" [value]="menu">{{ menu.displayName }}</mat-option>
          </mat-optgroup>
        </mat-autocomplete>
        <mat-icon (click)="clearValue($event)" *ngIf="hasValue()" matSuffix><i class="fa-sharp fa-regular fa-xmark"></i></mat-icon>
        <mat-icon matSuffix><i class="fa-sharp fa-thin fa-magnifying-glass"></i></mat-icon>
      </mat-form-field>
    </form>
  </div>
  <ag-sub-menu *ngFor="let menu of navigationMenu" [menuItem]="menu"/>
</div>
