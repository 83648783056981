<mat-card class="card-content full-height-container backdrop" id="survey-tracker-bulk">
  <mat-card-header class="mg-b-m">
    <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
    <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="form-container">
    <div *ngIf="updateStage != 3">
      <div *ngIf="featureComponentMode == FeatureComponentMode.UPLOAD">
        <h1 class="page-sub-header mg-l-m">
          File Processing | Step {{ updateStage }}/3
        </h1>
        <div class="mg-l-m">
      <span *ngIf="uploadInfo.uploadInitiated">
        <i class="fa-sharp fa-thin fa-xl fa-file mg-r-s"></i> {{ uploadInfo.fileName }} ({{ uploadInfo.fileSize }})
        <span *ngIf="uploadInfo.uploadDataAvailable">
          <i class="fa-sharp fa-thin fa-circle-check fa-xl mg-l-m mg-r-s primary-color"></i>uploaded
        </span>
      </span>
        </div>
        <ag-file-upload (uploadFinished)="uploadFinished($event)" (uploadReset)="resetForm()" (uploadStarted)="uploadStarted($event)"
                        [maxFileSizeSupported]="maxFileSizeSupported" [supportedFileTypes]="this.getSupportedFileTypes()"></ag-file-upload>
      </div>
      <div *ngIf="featureComponentMode != FeatureComponentMode.UPLOAD" class="full-height-container">
        <form [formGroup]="formGroup" id="bulkUpdateForm">
          <div *ngIf="featureComponentMode == FeatureComponentMode.BULK_EDIT">
            <div class="flex-row mg-b-m">
              <mat-form-field>
                <mat-label>Mailing Locale</mat-label>
                <mat-select [formControl]="getFormControl('mailingLocale')">
                  <mat-option *ngFor="let mailingLocale of mailingLocaleList"
                              [value]="mailingLocale.language">
                    {{ mailingLocale.language }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex-row toggle-block custom-container">
              <div class="custom-left-container">
                <ag-three-state-selector
                  [formCtrl]="this.getFormControl('invalidateRatings')"
                  label="Invalidate Ratings?"
                  noLabel="Validate All Ratings"
                  yesLabel="Invalidate All Ratings"
                ></ag-three-state-selector>
              </div>
              <div class="custom-left-container">
                <ag-three-state-selector
                  [formCtrl]="this.getFormControl('invalidateVerbatim')"
                  label="Invalidate Verbatim?"
                  noLabel="Validate All Verbatim"
                  yesLabel="Invalidate All Verbatim"
                ></ag-three-state-selector>
              </div>
            </div>
            <div class="flex-row custom-container mg-t-m mg-b-m">
              <div class="category-container">
                <mat-form-field>
                  <mat-label>Nominated Categories</mat-label>
                  <textarea [formControl]="getFormControl('nominatedCategories')" matInput></textarea>
                </mat-form-field>
              </div>
              <div class="custom-right-container">
                <mat-form-field>
                  <mat-label>Nomination Notes</mat-label>
                  <textarea [formControl]="getFormControl('nominationNotes')" matInput></textarea>
                </mat-form-field>
              </div>
            </div>
            <div class="flex-row custom-container mg-b-m">
              <div class="category-container">
                <mat-form-field>
                  <mat-label>PM Notes</mat-label>
                  <textarea [formControl]="getFormControl('pmNotes')" matInput></textarea>
                </mat-form-field>
              </div>
              <div class="custom-right-container">
                <mat-form-field>
                  <mat-label>Notes to PM</mat-label>
                  <textarea [formControl]="getFormControl('notesToPM')" matInput></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div *ngIf="featureComponentMode == FeatureComponentMode.BULK_MAILOUT" class="flex-row mg-b-m">
            <mat-form-field>
              <mat-label>Mailout Template</mat-label>
              <mat-select [formControl]="getFormControl('mailoutTemplateCode')">
                <mat-option *ngFor="let mailoutTemplateCode of mailoutTemplateCodeList"
                            [value]="mailoutTemplateCode.mailoutTemplateCode">
                  {{ mailoutTemplateCode.mailoutTemplateCode }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Mailout Field Set</mat-label>
              <mat-select [formControl]="getFormControl('mailoutFieldSetCode')">
                <mat-option *ngFor="let mailoutFieldsetCode of mailoutFieldsetCodeList"
                            [value]="mailoutFieldsetCode.mailoutFieldsetCode">
                  {{ mailoutFieldsetCode.mailoutFieldsetCode }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <ng-container *ngIf="getFormControl('mailoutDatetime')">
              <ag-datetimepicker [control]="getFormControl('mailoutDatetime')" [label]="'Mailout Datetime'" class="mat-mdc-form-field"></ag-datetimepicker>
            </ng-container>
          </div>
          <div *ngIf="featureComponentMode == FeatureComponentMode.BULK_REQUEST_FILES" class="flex-row mg-b-m">
            <mat-form-field>
              <mat-label>Response Files Status</mat-label>
              <mat-select [formControl]="getFormControl('responseFilesStatus')">
                <mat-option *ngFor="let responseFilesStatus of fileRequestStatusList"
                            [value]="responseFilesStatus">
                  {{ responseFilesStatus }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="updateStage == 3" class="mg-b-m">
      <div class="bulk-update-progress-panel">
        <div class="bulk-update-progress-top-icon">
          <i class="fa-sharp fa-light fa-table fa-5x"></i>
        </div>
        <div class="flex-row flex-g-m">
          <div>
            <span>Processing Rows ({{ updateStatus.numberOfProcessedRows }} of {{ updateStatus.totalNumberOfRows }})</span>
          </div>
          <div class="right-align">
            <h3>{{ processedPercentage() }}%</h3>
          </div>
        </div>
        <div class="progress-bar-container">
          <mat-progress-bar color="primary" mode="determinate" value="{{processedPercentage()}}"></mat-progress-bar>
        </div>
        <div class="flex-row flex-g-m">
          <div>
            <span>Successful Rows ({{ updateStatus.numberOfSuccessRows }} of {{ updateStatus.numberOfProcessedRows }}) </span>
          </div>
          <div class="right-align">
            <h3>{{ successPercentage() }}%</h3>
          </div>
        </div>
        <div class="progress-bar-container">
          <mat-progress-bar color="accent" mode="determinate" value="{{successPercentage()}}"></mat-progress-bar>
        </div>
        <div class="flex-row flex-g-m">
          <div>
            <span>Unsuccessful Rows ({{ updateStatus.numberOfErrorRows }} of {{ updateStatus.numberOfProcessedRows }}) </span>
          </div>
          <div class="right-align">
            <h3>{{ errorPercentage() }}%</h3>
          </div>
        </div>
        <div class="progress-bar-container">
          <mat-progress-bar color="warn" mode="determinate" value="{{errorPercentage()}}"></mat-progress-bar>
        </div>
      </div>
    </div>
    <div>
      <div class="flex-row flex-g-s action-buttons">
        <button (click)="startProcessing()" *ngIf="updateStage == 2" [disabled]="this.featureComponentMode != FeatureComponentMode.UPLOAD && !this.hasAnyEdits()"
                color="primary" form="bulkUpdateForm" mat-raised-button type="submit">
          <mat-icon class="material-symbols-outlined">save</mat-icon>
          {{ submitButtonText }}
        </button>
        <button (click)="resetForm()" *ngIf="updateStage == 2 && featureComponentMode != FeatureComponentMode.UPLOAD" [disabled]="!this.hasAnyEdits()" mat-button
                type="button">
          <mat-icon class="material-symbols-outlined">cancel</mat-icon>
          Reset
        </button>
        <button (click)="abort()" *ngIf="updateStage == 3" [disabled]="!saveInProgress" mat-button>
          <mat-icon class="material-symbols-outlined">cancel</mat-icon>
          Abort Processing
        </button>
        <button (click)="retry()" *ngIf="updateStage == 3" [disabled]="saveInProgress" mat-button>
          <mat-icon class="material-symbols-outlined">rotate_left</mat-icon>
          Retry
        </button>
        <button (click)="resetForm(); close()" *ngIf="updateStage == 2 || updateStage == 3" mat-button type="button">
          <i class="fa-sharp fa-light fa-xmark-large"></i> Close
        </button>
        <button (click)="this.downloadFailedRows('survey-tracker-failed')" *ngIf="updateStage == 3" [disabled]="saveInProgress || updateStatus.numberOfErrorRows===0"
                class="right-align failed-rows-download-button"
                color="primary"
                mat-raised-button>
          <i class="fa-sharp fa-light fa-arrow-down-to-bracket"></i>
          <span class="button-span-margin-left">Download Error File</span>
        </button>
        <div *ngIf="updateStage != 3" class="flex-row view-only-container right-align">
          <div class="view-only-block">
            <ag-datablock [horizontalAlign]="true" data="{{selectedRows.length}}"
                          title="Number of rows selected:"></ag-datablock>
          </div>
        </div>
      </div>
      <div class="flex-row full-width mg-t-m">
        <ag-grid-angular (gridReady)="onGridReady($event)"
                         [columnDefs]="tableColumns"
                         [rowData]="selectedRows"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz ag-grid-flex record-display-table"></ag-grid-angular>
      </div>
    </div>
  </mat-card-content>
</mat-card>
