<div [showSkeleton]="loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <mat-card *ngIf="!loading" class="task-component-detail backdrop" id="task-component-detail">
    <mat-card-header class="mg-b-m">
      <div class="header-title">
        <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
        <mat-card-subtitle>Ms Excel Survey Invitees</mat-card-subtitle>
      </div>

      <div *ngIf="featureComponentMode == FeatureComponentMode.EDIT_ROW" class="view-only-block flex-row mg-t-s" style="margin-left: auto">
        <ag-datablock [horizontalAlign]="true" data="{{responseApprovalStatus}}"
                      title="Response Approval Status"></ag-datablock>
      </div>
      <div class="flex-row view-only-block mg-t-s" style="margin-left: auto">
        <ag-slide-toggle (toggleUpdate)="util.setToggleValue($event.checked, this.getFormControl('invalid'))"
                         [colorType]="'error'"
                         [isChecked]="util.isToggleChecked(this.getFormControl('invalid'))"
                         [isDisabled]="!isEditMode"
                         class="slide-toggle"></ag-slide-toggle>
        <span class="mg-l-s">Invalid?</span>
      </div>
    </mat-card-header>
    <mat-card-content class="form-container">
      <form (ngSubmit)="this.submitForm(this.saveAndClose, 'Ms Excel Survey Invitees Saved.')" [formGroup]="formGroup">
        <div *ngIf="isEditMode" class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('period')}}"
                          title="Period"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('marketCode') + ' ' + getFormControlValue('marketName')}}"
                          title="Market Name"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('industryCode') + ' ' + getFormControlValue('industryName')}}"
                          title="Industry Name"></ag-datablock>
          </div>
          <div class="view-only-block">
          <div class="status-container">
            <label>Survey Subject</label>
            <mat-chip-listbox aria-label="Status" class="mat-mdc-chip-set-stacked" disabled="true">
              <div class="flex-row">
                <mat-chip-option
                  [selected]="isSurveySubjectSelected(SurveySubject.SUPPLIER)"
                  color="accent"
                  id="ss_s">Supplier
                </mat-chip-option>
                <mat-chip-option
                  [selected]="isSurveySubjectSelected(SurveySubject.RETAILER)"
                  color="accent"
                  id="ss_r">Retailer
                </mat-chip-option>
              </div>
            </mat-chip-listbox>
          </div>
          </div>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('surveyCode')}}" title="Survey Code"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('openLinkCode')}}" title="Response Code"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseCode')}}" title="Response Code"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('personCode')}}"
                          title="Person Code"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row view-only-container">
          <mat-form-field>
            <mat-label>Survey Code</mat-label>
            <mat-select [formControl]="getFormControl('surveyCode')">
              <mat-option (click)="onSurveyCodeSelected(surveyCode.surveyCode)"
                          *ngFor="let surveyCode of surveyCodeList"
                          [value]="surveyCode.surveyCode"
              >{{ surveyCode.surveyCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="view-only-block" *ngIf="this.featureComponentMode != FeatureComponentMode.ADD_ROW">
            <ag-datablock
              data="{{getFormControlValue('openLinkCode')}}"
              title="Open Link Code"></ag-datablock>
          </div>
          <mat-form-field>
            <mat-label>Invitation Name</mat-label>
            <input [formControl]="getFormControl('invitationName')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Invitation Company</mat-label>
            <mat-select [formControl]="getFormControl('invitationCompanyCode')">
              <mat-option *ngFor="let invitationCompanyCode of invitationCompanyCodeList"
                          [value]="invitationCompanyCode.respondentCompanyRollupCode"
              >
                {{ invitationCompanyCode.respondentCompanyRollupCode + " " + invitationCompanyCode.respondentCompanyRollupLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('forwardedToPersonCodes')}}"
                          title="Forwarded to Person Codes"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('invitationCompanyCode') + ' ' + getFormControlValue('invitationCompanyLabel')}}"
              title="Invitation Company"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseCode')}}" title="Response Code"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('personCode')}}"
                          title="Person Code"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('forwardedToPersonCodes')}}"
                          title="Forwarded to Person Codes"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('invitationCompanyLabel') + ' ' + getFormControlValue('invitationCompanyCode')}}"
              title="Invitation Company Label"></ag-datablock>
          </div>
        </div>
        <div class="status-container mg-b-m">
          <label>Response Status</label>
          <mat-chip-listbox aria-label="Status" class="mat-mdc-chip-set-stacked" disabled="true">
            <div class="flex-row">
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.NOT_STARTED)"
                color="accent"
                id="rs_ns">{{ ResponseStatus.NOT_STARTED }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.IN_PROGRESS)"
                color="accent"
                id="rs_ip">{{ ResponseStatus.IN_PROGRESS }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.ERROR)"
                color="accent"
                id="rs_e">{{ ResponseStatus.ERROR }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.COMPLETE)"
                color="accent"
                id="rs_c">{{ ResponseStatus.COMPLETE }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.SCREENED)"
                color="accent"
                id="rs_s">{{ ResponseStatus.SCREENED }}
              </mat-chip-option>
            </div>
          </mat-chip-listbox>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('firstName')}}"
                          title="First Name"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('lastName')}}"
                          title="Last Name"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock [isLongData]="true"
                          data="{{getFormControlValue('email')}}" title="Email"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('surveyLanguage')}}"
                          title="Survey Language"></ag-datablock>
          </div>
        </div>

        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('firstName')}}"
                          title="First Name"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('lastName')}}"
                          title="Last Name"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock [isLongData]="true"
                          data="{{getFormControlValue('email')}}" title="Email"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('surveyLanguage')}}"
                          title="Survey Language"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input [formControl]="getFormControl('firstName')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input [formControl]="getFormControl('lastName')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input [formControl]="getFormControl('email')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Survey Language</mat-label>
            <mat-select [formControl]="getFormControl('surveyLanguage')">
              <mat-option *ngFor="let surveyLanguage of surveyLanguageList"
                          [value]="surveyLanguage.language">
                {{ surveyLanguage.language }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="featureComponentMode == FeatureComponentMode.EDIT_ROW" class="flex-row">
          <mat-form-field>
            <mat-label>Telephone</mat-label>
            <input [formControl]="getFormControl('telephone')" matInput>
          </mat-form-field>
            <mat-form-field>
              <mat-label>Cellphone</mat-label>
              <input [formControl]="getFormControl('cellPhone')" matInput>
            </mat-form-field>
            <mat-form-field>
              <mat-label>City</mat-label>
              <input [formControl]="getFormControl('city')" matInput>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Job Title</mat-label>
              <input [formControl]="getFormControl('jobTitle')" matInput>
            </mat-form-field>
        </div>

        <div *ngIf="featureComponentMode == FeatureComponentMode.ADD_ROW" class="flex-row">
          <mat-form-field>
            <mat-label>Telephone</mat-label>
            <input [formControl]="getFormControl('telephone')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Cell Phone</mat-label>
            <input [formControl]="getFormControl('cellPhone')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>City</mat-label>
            <input [formControl]="getFormControl('city')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Job Title</mat-label>
            <input [formControl]="getFormControl('jobTitle')" matInput>
          </mat-form-field>
        </div>

        <div *ngIf="this.featureComponentMode === FeatureComponentMode.EDIT_ROW"
             class="flex-row view-only-container mg-t-m mg-b-m">
            <mat-form-field>
              <mat-label>Nominated By</mat-label>
              <mat-select [formControl]="getFormControl('nominatedByCodes')">
                <mat-option *ngFor="let nominatedBy of nominatedByList"
                            [value]="nominatedBy.code">
                  {{ nominatedBy.code + ' ' + nominatedBy.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Category Responsibility</mat-label>
              <input [formControl]="getFormControl('categoryResponsibility')" matInput>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Nomination Notes</mat-label>
              <input [formControl]="getFormControl('nominationNotes')" matInput>
            </mat-form-field>
        </div>

        <div *ngIf="this.featureComponentMode === FeatureComponentMode.ADD_ROW"
             class="flex-row custom-container mg-t-m">
          <div class="category-container">
            <mat-form-field>
              <mat-label>Category Responsibility</mat-label>
              <textarea [formControl]="getFormControl('categoryResponsibility')" matInput></textarea>
            </mat-form-field>
          </div>
          <div class="custom-right-container">
            <mat-form-field>
              <mat-label>Nomination Notes</mat-label>
              <textarea [formControl]="getFormControl('nominationNotes')" matInput></textarea>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="featureComponentMode == FeatureComponentMode.ADD_ROW" class="flex-row">
          <mat-form-field>
            <mat-label>Roles</mat-label>
            <mat-select [formControl]="getFormControl('roleCodes')">
              <mat-option *ngFor="let role of rolesList"
                          [value]="role.roleCode">
                {{ role.roleCode + ' ' + role.roleLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Channels</mat-label>
            <mat-select [formControl]="getFormControl('channelCodes')">
              <mat-option *ngFor="let channel of channelsList"
                          [value]="channel.channelCode">
                {{ channel.channelCode + ' ' + channel.channelLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Subject Rollups</mat-label>
            <mat-select [formControl]="getFormControl('subjectRollupCodes')">
              <mat-option *ngFor="let subjectRollup of subjectRollupsList"
                          [value]="subjectRollup.subjectCompanyRollupCode">
                {{ subjectRollup.subjectCompanyRollupCode + ' ' + subjectRollup.subjectCompanyRollupLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Subject Singles</mat-label>
            <mat-select [formControl]="getFormControl('subjectSingleCodes')">
              <mat-option *ngFor="let subjectSingle of subjectSinglesList"
                          [value]="subjectSingle.subjectCompanySingleCode">
                {{ subjectSingle.subjectCompanySingleCode + ' ' + subjectSingle.subjectCompanySingleLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="featureComponentMode == FeatureComponentMode.EDIT_ROW" class="flex-row view-only-container">
            <mat-form-field>
              <mat-label>Roles</mat-label>
              <mat-select [formControl]="getFormControl('roleCodes')">
                <mat-option *ngFor="let role of rolesList"
                            [value]="role.roleCode">
                  {{ role.roleCode + ' ' + role.roleLabel }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Channels</mat-label>
              <mat-select [formControl]="getFormControl('channelCodes')">
                <mat-option *ngFor="let channel of channelsList"
                            [value]="channel.channelCode">
                  {{ channel.channelCode + ' ' + channel.channelLabel }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Subject Rollups</mat-label>
              <mat-select [formControl]="getFormControl('subjectRollupCodes')">
                <mat-option *ngFor="let subjectRollup of subjectRollupsList"
                            [value]="subjectRollup.subjectCompanyRollupCode">
                  {{ subjectRollup.subjectCompanyRollupCode + ' ' + subjectRollup.subjectCompanyRollupLabel }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Subject Singles</mat-label>
              <mat-select [formControl]="getFormControl('subjectSingleCodes')">
                <mat-option *ngFor="let subjectSingle of subjectSinglesList"
                            [value]="subjectSingle.subjectCompanySingleCode">
                  {{ subjectSingle.subjectCompanySingleCode + ' ' + subjectSingle.subjectCompanySingleLabel }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="featureComponentMode == FeatureComponentMode.ADD_ROW" class="flex-row">
          <mat-form-field>
            <mat-label>Local Regions</mat-label>
            <mat-select [formControl]="getFormControl('localRegionCodes')">
              <mat-option *ngFor="let localRegion of localRegionsList"
                          [value]="localRegion.localRegionCode">
                {{ localRegion.localRegionCode + ' ' + localRegion.localRegionLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Bu-Categories</mat-label>
            <mat-select [formControl]="getFormControl('buCategoryCodes')">
              <mat-option *ngFor="let buCategory of buCategoriesList"
                          [value]="buCategory.buCategoryCode">
                {{ buCategory.buCategoryCode + ' ' + buCategory.buCategoryLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Nominated By</mat-label>
            <mat-select [formControl]="getFormControl('nominatedByCodes')">
              <mat-option *ngFor="let nominatedBy of nominatedByList"
                          [value]="nominatedBy.code">
                {{ nominatedBy.code + ' ' + nominatedBy.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="mg-b-m flex-row w-25">
            <ag-slide-toggle (toggleUpdate)="onAllBuCategoriesChanged($event)"
                             [isChecked]="util.isToggleChecked(this.getFormControl('allBuCategories'))"
                             [isDisabled]="!isEditMode"
            ></ag-slide-toggle>
            <span class="mg-l-s">All BU-Categories?</span>
          </div>
        </div>

        <div class="flex-row view-only-container mg-t-m mg-b-m">
            <mat-form-field>
              <mat-label>Local Regions</mat-label>
              <mat-select [formControl]="getFormControl('localRegionCodes')">
                <mat-option *ngFor="let localRegion of localRegionsList"
                            [value]="localRegion.localRegionCode">
                  {{ localRegion.localRegionCode + ' ' + localRegion.localRegionLabel }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Bu-Categories</mat-label>
              <mat-select [formControl]="getFormControl('buCategoryCodes')">
                <mat-option *ngFor="let buCategory of buCategoriesList"
                            [value]="buCategory.buCategoryCode">
                  {{ buCategory.buCategoryCode + ' ' + buCategory.buCategoryLabel }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          <div class="mg-b-m flex-row w-25">
            <ag-slide-toggle (toggleUpdate)="onAllBuCategoriesChanged($event)"
                             [isChecked]="util.isToggleChecked(this.getFormControl('allBuCategories'))"
                             [isDisabled]="!isEditMode"
            ></ag-slide-toggle>
            <span class="mg-l-s">All BU-Categories?</span>
          </div>
          <div *ngIf="!isEditMode" class="view-only-block">
            <ag-datablock data="{{getFormControlValue('seniorityCode')}}"
                          title="Seniority"></ag-datablock>
          </div>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('brPlCode') + ' ' + getFormControlValue('brPlLabel')}}"
                          title="BR/PL"></ag-datablock>
          </div>
        </div>

        <div *ngIf="isEditMode" class="flex-row">
          <mat-form-field>
            <mat-label>Seniority</mat-label>
            <mat-select [formControl]="getFormControl('seniorityCode')">
              <mat-option *ngFor="let seniorities of senioritiesList"
                          [value]="seniorities.seniorityCode">
                {{ seniorities.seniorityCode + ' ' + seniorities.seniorityLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>BR/PL</mat-label>
            <mat-select [formControl]="getFormControl('brPlCode')">
              <mat-option *ngFor="let industryFlags of industryFlagsList"
                          [value]="industryFlags.brPlCode">
                {{ industryFlags.brPlCode + ' ' + industryFlags.brPlLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>Survey File Status</mat-label>
            <mat-select [formControl]="getFormControl('surveyFileStatus')">
              <mat-option *ngFor="let surveyFileStatus of surveyFileStatusList"
                          [value]="surveyFileStatus">
                {{ surveyFileStatus }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('surveyFileCreatedDatetime')}}"
                          title="Survey File Created Datetime"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock [url]="getFormControlValue('surveyFile')"
                          data="{{getFormControlValue('surveyFile')}}"
                          title="Survey File"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('surveyFilePassword')}}"
                          title="Survey File Password"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('surveyId')}}" title="Survey Id"></ag-datablock>
          </div>
        </div>

        <div class="flex-row flex-g-s action-buttons">
          <button (click)="onSaveAndCloseClick()" [disabled]="!this.hasAnyEdits()" color="primary" mat-raised-button
                  type="submit">
            <mat-icon class="material-symbols-outlined">save</mat-icon>
            Save and Close
          </button>
          <button (click)="resetForm()" *ngIf="isEditMode" [disabled]="!this.hasAnyEdits()" mat-button type="button">
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
            Reset
          </button>
          <button (click)="resetForm(); close()" *ngIf="hasAnyEdits()" [disabled]="!this.hasAnyEdits()" mat-button
                  type="button">
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
            Cancel
          </button>
          <button (click)="resetForm(); close()" *ngIf="!hasAnyEdits()" [disabled]=" this.hasAnyEdits()"
                  class="primary-font-color" mat-button type="button">
            <i class="fa-sharp fa-light fa-xmark-large"></i> Close
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
