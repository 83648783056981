import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {SurveyTracker} from "@data/interefaces/panel.interfaces";
import {Util} from "@data/util/util";
import {BatchProcessResult, DataItem, OperationStatus, ResultsetThresholds} from "@data/interefaces/data.interfaces";

@Injectable({
  providedIn: 'root'
})
export class SurveyTrackerService {

  constructor(private _apiService: ApiService) {
  }

  public getSurveyTrackers(period: string, marketCode: string, surveyCode: string): Promise<SurveyTracker[]> {
    let queryParams: string[] = [];
    Util.pushQueryParam("period", period, queryParams);
    Util.pushQueryParam("marketCode", marketCode, queryParams);
    Util.pushQueryParam("surveyCode", surveyCode, queryParams);
    return this._apiService.makeGetServiceCall<SurveyTracker[]>(`/v2/api/panel/survey-tracker`, queryParams.join('&'));
  }

  public getSurveyTrackersById(surveyResponseId: string): Promise<SurveyTracker> {
    return this._apiService.makeGetServiceCall<SurveyTracker>(`/v2/api/panel/survey-tracker/${surveyResponseId}`);
  }

  public getSurveyTrackersThresholds(period: string, marketCode: string, surveyCode: string): Promise<ResultsetThresholds> {
    let queryParams: string[] = [];
    Util.pushQueryParam("period", period, queryParams);
    Util.pushQueryParam("marketCode", marketCode, queryParams);
    Util.pushQueryParam("surveyCode", surveyCode, queryParams);
    return this._apiService.makeGetServiceCall<ResultsetThresholds>(`/v2/api/panel/survey-tracker/thresholds`, queryParams.join('&'));
  }

  public saveSurveyTrackers(surveyTracker: any): Promise<DataItem> {
    return this._apiService.makePostServiceCall<DataItem>(`/v2/api/panel/survey-tracker`, null, surveyTracker);
  }

  public updateSurveyTrackers(surveyTracker: any) {
    return this._apiService.makePutServiceCall<OperationStatus>(`/v2/api/panel/survey-tracker`, null, surveyTracker);
  }

  public getLatestSurveyTrackersBatchProcess() {
    return this._apiService.makeGetServiceCall<BatchProcessResult>(`/v2/api/panel/survey-tracker/bulk-process/latest`);
  }

  public startSurveyTrackersBatchProcess(surveyTracker: SurveyTracker[], processType: string) {
    let queryParams: string[] = [];
    Util.pushQueryParam("processType", processType, queryParams);
    return this._apiService.makePutServiceCall<{ id: number }>(`/v2/api/panel/survey-tracker/bulk-process/start`, queryParams.join('&'), surveyTracker);
  }

}
