import {Component} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {DetailBaseComponent} from "@pages/detail.base.component";
import {VerbatimFileInfo} from "@data/interefaces/verbatim.file.interfaces";
import {VerbatimService} from "@data/verbatim/verbatim.service";
import {DataItem, OperationStatus} from "@data/interefaces/data.interfaces";
import {VerbatimFileUtil} from "@data/util/verbatim.file.util";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'verbatim-file-detail',
  templateUrl: './verbatim-file-detail.component.html',
  styleUrls: ['./verbatim-file-detail.component.scss'],
  providers: [AgGridService]
})
export class VerbatimFileDetailComponent extends DetailBaseComponent {

  selectedItem: VerbatimFileInfo = {} as VerbatimFileInfo;
  override formGroup: FormGroup<{ [K in keyof VerbatimFileInfo]?: FormControl<any> }> = new FormGroup<any>([]);
  override pageContext: {
    [key: string]: number | undefined;
    vfi_f: number | undefined;
  } = {
    vfi_f: undefined
  };
  expanded : boolean = false;

  constructor(private _verbatimService: VerbatimService) {
    super();
  }

  override ngOnInit(): void {
    this.displayProgressBar(true);
    super.ngOnInit();
  }

  protected override getContentDetails() {
    if (this.pageContext.vfi_f) {
      this._verbatimService.getVerbatimFile(this.pageContext.vfi_f).then(result => {
        this.selectedItem = result;
        this.title = "File Details";
        this.initFormGroup();
        this.displayProgressBar(false);
        this.loading = false;
      });
    } else {
      // redirect to Upload page
      throw new Error("The Verbatim File page is read-only; id is required.");
    }
  }

  protected getFormControl(controlName: (keyof VerbatimFileInfo)) {
    return this.getControl<VerbatimFileInfo>(controlName);
  }

  protected getFormControlValue(controlName: keyof VerbatimFileInfo) {
    return this.getControlValue<VerbatimFileInfo>(controlName);
  }

  protected getUploadDetails() {
    return VerbatimFileUtil.getUploadDetails(this.selectedItem);
  }

  protected initFormGroup(): void {
    Object.entries(this.selectedItem).forEach(([key, value]) => {
      this.formGroup.addControl(key, new FormControl({
        value: value,
        disabled: true
      }));
    });
  }

  protected loadDropDownData(): void {
    // nothing to do, this form has no dropdowns.
  }

  protected saveOrUpdate(): Promise<OperationStatus> | Promise<DataItem> {
    throw new Error("The Verbatim File page is read-only.");
  }

  protected changeState($event: boolean){
    this.expanded = $event;
  }
}
