import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {ICellRendererParams} from "@ag-grid-community/core";

@Component({
  selector: 'app-action-column',
  templateUrl: './action-column.component.html',
  styleUrls: ['./action-column.component.scss']
})
export class ActionColumnComponent implements ICellRendererAngularComp {
  params!: any
  actions: any[] = [];
  defaultIconStyle: string = 'fa-sharp fa-light';


  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.updateActions();
  }

  updateActions() {
    this.actions = this.params.actions;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  onClick(actionEvent?: string) {
    if (actionEvent !== undefined && actionEvent !== null && actionEvent !== '') {
      const clickEvent = new CustomEvent(actionEvent, {
        detail: {
          rowData: this.params.data
        }
      });
      this.params.api.dispatchEvent(clickEvent);
    }
  }
}
