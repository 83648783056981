import {Injectable} from "@angular/core";
import {DomSanitizer} from '@angular/platform-browser';
import {UserNotification} from "@data/interefaces/notification.interface";
import {NotificationDecoratorFactory} from "@data/notification/notification-decorator.factory";

@Injectable({
  providedIn: 'root'
})
export class NotificationDecorateService {
  constructor(private _sanitizer: DomSanitizer, private _decoratorFactory: NotificationDecoratorFactory) {
  }

  public decorateNotification(notification: UserNotification): UserNotification {
    const notificationDecorator = this._decoratorFactory.getDecorator(notification.notificationType);
    if (notificationDecorator) {
      return notificationDecorator.decorate(notification);
    } else {
      return this.decorateByDefault(notification);
    }
  }

  private decorateByDefault(notification: UserNotification): UserNotification {
    notification.htmlSubject = this._sanitizer.bypassSecurityTrustHtml(notification.subject || '');
    notification.htmlBody = this._sanitizer.bypassSecurityTrustHtml(notification.body || '');
    return notification;
  }
}
