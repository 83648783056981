import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'ag-datetimepicker',
  templateUrl: './ag-datetimepicker.component.html',
  styleUrls: ['./ag-datetimepicker.component.scss']
})
export class AgDatetimepickerComponent<T> {
  @Input() label: string = "Choose a Date";
  @Input() control!: FormControl<T>;
}
