<div class="{{showTaskComponent ? 'hidden' : 'full-height-container'}}">
  <mat-card class="full-height-container">
    <mat-card-content class="full-height-container content-panel">
      <div class="flex-row">
        <app-component-header (downloadClickEvent)="this.handleDownloadClickEvent('product-period')" (refreshClickEvent)="this.refreshView()"
                              [hideColumnsButton]="true" [quilliupGuid]="QuilliupForm.PRODUCT_PERIODS.guid" [redirectTo]="'quilliup'"
                              [startRefreshTimer]="this.refreshComplete" [subtitleSegments]="getUrlSegments()"
                              [uploadUrlParam]="{mode: 'upload'}"
                              [uploadUrl]="['task', 'upload']"
                              class="full-width"></app-component-header>
      </div>
      <div class="flex-row table-container">
        <ag-kpi-sidebar [sidebar]="kpiSidebar" class="mg-r-s"></ag-kpi-sidebar>
        <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                         (filterChanged)="onFilterChanged($event)"
                         (firstDataRendered)="onFirstDataRendered($event)"
                         (gridReady)="onGridReady($event)"
                         (selectionChanged)="onRowSelectionChanged($event)"
                         (viewportChanged)="onViewportChanged($event)"
                         [columnDefs]="tableColumns"
                         [gridOptions]="baseAgGridOptions"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         [rowData]="rowData"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz ag-grid-flex">
        </ag-grid-angular>
        <ng-template #footerActionButtonPanel>
          <button (click)="onBulkEditClicked()" [disabled]="!isBulkOperationAllowed" class="white-font-color mg-r-m" color="accent" mat-raised-button>
            <i class="fa-sharp fa-light fa-pen"></i>
            Bulk Update
          </button>
        </ng-template>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<app-product-period-task (goBackEvent)="goBack()" *ngIf="showTaskComponent" [selectedFeatureMode]="selectedFeatureMode" [selectedRows]="this.selectedRows"/>
