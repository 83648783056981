<ag-task (goBackEvent)="goBackEvent.emit($event)" [isUrlMode]="isUrlMode" [selectedFeatureMode]="selectedFeatureMode"
         [sidebarEl]="featureComponentSidebarEl" [urlModeGoBackUrl]="urlModeGoBackUrl" class="open-link-invitees-task"
         id="open-link-invitees-task">
  <ng-template agClassifier="{{FeatureComponentMode.ADD_ROW}}">
    <open-link-invitees-detail class="open-link-invitees-detail full-height-container"></open-link-invitees-detail>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.EDIT_ROW}}">
    <open-link-invitees-detail class="open-link-invitees-detail full-height-container"></open-link-invitees-detail>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.BULK_MAILOUT}}">
    <app-open-link-invitees-bulk (goBackEvent)="goBackEvent.emit($event)"
                                   [featureComponentMode]="FeatureComponentMode.BULK_MAILOUT"
                                   [selectedRows]="selectedRows"
                                 class="open-link-invitees-detail full-height-container"></app-open-link-invitees-bulk>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.BULK_EDIT}}">
    <app-open-link-invitees-bulk (goBackEvent)="goBackEvent.emit($event)"
                                   [featureComponentMode]="FeatureComponentMode.BULK_EDIT"
                                   [selectedRows]="selectedRows"
                                 class="open-link-invitees-detail full-height-container"></app-open-link-invitees-bulk>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.UPLOAD}}">
    <app-open-link-invitees-bulk [featureComponentMode]="FeatureComponentMode.UPLOAD"
                                   class="open-link-invitees-detail full-height-container"></app-open-link-invitees-bulk>
  </ng-template>
</ag-task>
