<div id="slide-toggle-wrapper">
  <ng-container *ngIf="formControlInput">
    <mat-slide-toggle
      [checked]="isChecked"
      [formControl]="formControlInput"
      class="slide-toggle-wrapper {{colorType}}">
    </mat-slide-toggle>
  </ng-container>

  <ng-container *ngIf="!formControlInput">
    <mat-slide-toggle
      (change)="triggerToggleUpdate($event)"
      [checked]="isChecked"
      [disabled]="isDisabled"
      class="slide-toggle-wrapper {{colorType}}">
    </mat-slide-toggle>
  </ng-container>
</div>
