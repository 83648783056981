<ag-task (goBackEvent)="goBackEvent.emit($event)" [isUrlMode]="isUrlMode" [selectedFeatureMode]="selectedFeatureMode"
         [sidebarEl]="featureComponentSidebarEl" [urlModeGoBackUrl]="urlModeGoBackUrl" class="full-height-container"
         id="task-component">
  <ng-template agClassifier="{{FeatureComponentMode.DETAIL}}">
    <app-response-file-detail
      class="task-component-detail full-height-container"></app-response-file-detail>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.UPLOAD}}">
    <app-response-file-upload
      class="task-component-detail full-height-container"></app-response-file-upload>
  </ng-template>

</ag-task>
