import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {OpenLinkInvitee} from "@data/interefaces/panel.interfaces";
import {ProcessStatus} from "@data/interefaces/extended.data.interfaces";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";

export interface OpenLinkInviteeWithProcessStatus extends OpenLinkInvitee, ProcessStatus {
}

export let openLinkDisplayTableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    field: 'surveyCode', headerName: 'Survey Code', pinned: "left", hide: false,
    cellRenderer: SelectColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        showFlag: params.data.processingNote != undefined,
        flagIcon: "fa-square-exclamation",
        flagColor: "red",
        toolTip: params.data.processingNote
      }
    }
  },
  {field: 'openLinkCode', headerName: 'Open Link Code', autoHeight: true},
  {field: 'responseCode', headerName: 'Response Code', autoHeight: true},
  {field: 'personCode', headerName: 'Person Code', autoHeight: true},
  {field: 'forwardedToPersonCodes', headerName: 'Forwarded to Person Codes', autoHeight: true},
  {field: 'invalid', headerName: 'Invalid?', cellRenderer: CircleCheckColumnComponent},
  {field: 'invitationCompanyCode', headerName: 'Invitation Company Code', autoHeight: true},
  {field: 'invitationDivisionCodes', headerName: 'Invitation Division Codes',},
  {
    headerName: 'Demographics', children: [
      {field: 'firstName', headerName: 'First Name'},
      {field: 'lastName', headerName: 'Last Name'},
      {
        field: 'email', headerName: 'Email',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.email,
            type: 'text',
          };
        }
      },
      {field: 'telephone', headerName: 'Telephone'},
      {field: 'cellPhone', headerName: 'Cell Phone'},
      {field: 'city', headerName: 'City'},
      {field: 'jobTitle', headerName: 'Job Title'},
      {field: 'surveyLanguage', headerName: 'Survey Language'}
    ]
  },
  {
    field: 'inviteeUrl', headerName: 'Invitee Url',
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.inviteeUrl,
        type: 'link',
      };
    }
  },
  {
    headerName: 'Nominations', children: [
      {field: 'nominatedByCodes', headerName: 'Nominated By Codes'},
      {field: 'categoryResponsibility', headerName: 'Category Responsibility'},
      {field: 'nominationNotes', headerName: 'Nomination Notes'}
    ]
  },
  {
    headerName: 'Mailouts', children: [
      {field: 'mailoutTemplateCode', headerName: 'Mailout Template Code', autoHeight: true,},
      {field: 'mailoutFieldSetCode', headerName: 'Mailout Field Set Code', autoHeight: true,},
      {field: 'mailoutDatetime', headerName: 'Mailout Datetime'}
    ]
  },
  {
    headerName: 'Labels', children: [
      {field: 'invitationCompanyLabel', headerName: 'Invitation Company Label'},
      {field: 'invitationDivisionLabels', headerName: 'Invitation Division Labels'},
      {field: 'nominatedByLabels', headerName: 'Nominated By Labels',},
    ]
  }
]
