<div class="full-height-container">
  <mat-card class="full-height-container">
    <mat-card-content class="content-panel full-height-container">
      <div class="flex-row">
        <app-component-header (refreshClickEvent)="this.refreshView()"
                              [hideColumnsButton]="true"
                              [startRefreshTimer]="this.refreshComplete"
                              [subtitleSegments]="getUrlSegments()"
                              class="full-width"></app-component-header>
      </div>
      <div class="flex-row table-container">
        <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                         (filterChanged)="onFilterChanged($event)"
                         (firstDataRendered)="onFirstDataRendered($event)"
                         (gridReady)="onGridReady($event)"
                         (viewportChanged)="onViewportChanged($event)"
                         [columnDefs]="tableColumns"
                         [gridOptions]="baseAgGridOptions"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         [pagination]="true"
                         [rowData]="dataSource"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz ag-grid-flex">
        </ag-grid-angular>

      </div>
    </mat-card-content>
  </mat-card>

</div>


<ag-side-panel #messageDetailPanel>
  <app-transfer-run-detail-messages-details [referenceCode]="this.transferRun?.transferId"
                                            [transferDetailId]="this.selectedTransferRunLog?.transferDetailId"></app-transfer-run-detail-messages-details>
</ag-side-panel>
