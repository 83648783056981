<mat-card class="card-content full-height-container backdrop" id="task-component-bulk">
  <mat-card-header class="mg-b-m">
    <div class="header-title">
      <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
      <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
    </div>
  </mat-card-header>
  <mat-card-content class="form-container">
    <div *ngIf="updateStage != 3">
      <div *ngIf="featureComponentMode == FeatureComponentMode.UPLOAD" id="file-processing">
        <h1 class="page-sub-header mg-l-m">
          File Processing | Step {{ updateStage }}/3
        </h1>
        <div class="mg-l-m">
      <span *ngIf="uploadInfo.uploadInitiated">
        <i class="fa-sharp fa-thin fa-xl fa-file mg-r-s"></i> {{ uploadInfo.fileName }} ({{ uploadInfo.fileSize }})
        <span *ngIf="uploadInfo.uploadDataAvailable">
          <i class="fa-sharp fa-thin fa-circle-check fa-xl mg-l-m mg-r-s primary-color"></i>uploaded
        </span>
      </span>
        </div>
        <ag-file-upload (uploadFinished)="uploadFinished($event)"
                        (uploadReset)="resetForm()"
                        (uploadStarted)="uploadStarted($event)"
                        [maxFileSizeSupported]="maxFileSizeSupported"
                        [supportedFileTypes]="this.getSupportedFileTypes()"></ag-file-upload>
      </div>
      <div *ngIf="featureComponentMode == FeatureComponentMode.BULK_INCLUDE" class="full-height-container">
        <form [formGroup]="formGroup" id="bulkIncludeForm">
          <div class="flex-row mg-b-m">
            <div class="mg-l-s flex-row w-100">
              <ag-three-state-selector
                [formCtrl]="getFormControl('include')"
                [showUndeterminedValue]="showIncludeOptionPleaseSelect()"
                [showYesValue]="showIncludeOptionIncludeAll()"
                [showNoValue]="showIncludeOptionExcludeAll()"
              ></ag-three-state-selector>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="updateStage == 3" class="mg-b-m">
      <div class="bulk-update-progress-panel">
        <div class="bulk-update-progress-top-icon">
          <i class="fa-sharp fa-light fa-table fa-5x"></i>
        </div>
        <div class="flex-row flex-g-m">
          <div>
            <span>Processing Rows ({{ updateStatus.numberOfProcessedRows }} of {{ updateStatus.totalNumberOfRows }})</span>
          </div>
          <div class="right-align">
            <h3>{{ processedPercentage() }}%</h3>
          </div>
        </div>
        <div class="progress-bar-container">
          <mat-progress-bar color="primary" mode="determinate" value="{{processedPercentage()}}"></mat-progress-bar>
        </div>
        <div class="flex-row flex-g-m">
          <div>
            <span>Successful Rows ({{ updateStatus.numberOfSuccessRows }} of {{ updateStatus.numberOfProcessedRows }}) </span>
          </div>
          <div class="right-align">
            <h3>{{ successPercentage() }}%</h3>
          </div>
        </div>
        <div class="progress-bar-container">
          <mat-progress-bar color="accent" mode="determinate" value="{{successPercentage()}}"></mat-progress-bar>
        </div>
        <div *ngIf="updateStatus.markUnchangedRecord" class="flex-row flex-g-m">
          <div>
            <span>Unchanged Rows ({{ updateStatus.numberOfUnchangedRows }} of {{ updateStatus.numberOfProcessedRows }}) </span>
          </div>
          <div class="right-align">
            <h3>{{ unchangedPercentage() }}%</h3>
          </div>
        </div>
        <div *ngIf="updateStatus.markUnchangedRecord" class="progress-bar-container">
          <mat-progress-bar color="accent" mode="determinate" value="{{unchangedPercentage()}}"></mat-progress-bar>
        </div>
        <div class="flex-row flex-g-m">
          <div>
            <span>Unsuccessful Rows ({{ updateStatus.numberOfErrorRows }} of {{ updateStatus.numberOfProcessedRows }}) </span>
          </div>
          <div class="right-align">
            <h3>{{ errorPercentage() }}%</h3>
          </div>
        </div>
        <div class="progress-bar-container">
          <mat-progress-bar color="warn" mode="determinate" value="{{errorPercentage()}}"></mat-progress-bar>
        </div>
      </div>
    </div>
    <div>
      <div class="flex-row flex-g-s action-buttons">
        <button (click)="startProcessing()" *ngIf="updateStage == 2" [disabled]="this.featureComponentMode != FeatureComponentMode.UPLOAD && !this.hasAnyEdits()"
                color="primary" form="bulkIncludeForm" mat-raised-button type="submit">
          <mat-icon class="material-symbols-outlined">save</mat-icon>
          {{ submitButtonText }}
        </button>
        <button (click)="resetForm()" *ngIf="updateStage == 2 && featureComponentMode != FeatureComponentMode.UPLOAD" [disabled]="!this.hasAnyEdits()" mat-button
                type="button">
          <mat-icon class="material-symbols-outlined">cancel</mat-icon>
          Reset
        </button>
        <button (click)="abort()" *ngIf="updateStage == 3" [disabled]="!saveInProgress" mat-button>
          <mat-icon class="material-symbols-outlined">cancel</mat-icon>
          Abort
        </button>
        <button (click)="retry()" *ngIf="updateStage == 3" [disabled]="saveInProgress" mat-button>
          <mat-icon class="material-symbols-outlined">rotate_left</mat-icon>
          Retry
        </button>
        <button (click)="resetForm(); close()" *ngIf="updateStage == 2 || updateStage == 3" mat-button type="button">
          <i class="fa-sharp fa-light fa-xmark-large"></i> Close
        </button>
        <button (click)="this.downloadFailedRows('product-sponsor-verbatim-failed')" *ngIf="updateStage == 3" [disabled]="saveInProgress"
                class="right-align failed-rows-download-button" color="primary"
                mat-raised-button>
          <i class="fa-sharp fa-light fa-arrow-down-to-bracket"></i>
          <span class="button-span-margin-left">Download</span>
        </button>
      </div>
      <div *ngIf="updateStage != 1" class="flex-row full-width mg-t-m">
        <ag-grid-angular (gridReady)="onGridReady($event)"
                         [columnDefs]="tableColumns"
                         [rowData]="selectedRows"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz record-display-table"></ag-grid-angular>
      </div>
    </div>
  </mat-card-content>
</mat-card>
