<div [showSkeleton]="loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <mat-card *ngIf="!loading" class="task-component-detail backdrop" id="task-component-detail">
    <div class="header-row-info header-container-item">
      <mat-card-header class="header-title">
        <div>
          <mat-card-title class="primary-font-color">{{ "Download Request: " + this.viewDownloadRequest?.id }}</mat-card-title>
        </div>
      </mat-card-header>
    </div>
    <mat-card-content>
      <div class="flex-row view-only-container mg-t-s mg-b-m">
        <div class="view-only-block">
          <h2 class="mat-mdc-card-subtitle sub-header-section">Request</h2>
        </div>
      </div>
      <div class="flex-row view-only-container">
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.batchCode}}" title="Batch Code"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.period}}" title="Period"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.country}}" title="Market"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestParameters?.industryCode}}" title="Industry Code"></ag-datablock>
        </div>
      </div>
      <div class="flex-row view-only-container mg-t-m mg-b-m">
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestParameters?.survey_subject}}" title="Survey Subject"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadType?.downloadRequestTypeDisplayValue}}" title="Download Type"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.productType}}" title="Download Type Version"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.product}}" title="Product"></ag-datablock>
        </div>
      </div>
      <div class="flex-row view-only-container mg-t-m mg-b-m">
        <div class="view-only-block">
          <ag-datablock
            data="{{this.viewDownloadRequest?.sponsor ? this.viewDownloadRequest?.sponsor + (this.viewDownloadRequest?.sponsorName ? ('-' + this.viewDownloadRequest?.sponsorName) : '') : '-'}}"
            title="Sponsor"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.respondentLabels?.join(', ')}}" title="Respondent Companies"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.language}}" title="Language"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{(this.viewDownloadRequest?.isLatestRequest ? 'Y' : '-')}}" title="Latest Request"></ag-datablock>
        </div>

      </div>

      <div class="flex-row view-only-container mg-t-m mg-b-m">
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.requestedBy}}" title="Requested By"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock [isDate]="true" data="{{this.viewDownloadRequest?.requestDate}}" title="Request Date"></ag-datablock>
        </div>
        <div class="view-only-block flex-stretch">
          <ag-datablock [isJson]="true" data="{{this.viewDownloadRequest?.downloadParameters}}" title="Parameter"></ag-datablock>
        </div>
      </div>

      <div class="flex-row view-only-container mg-t-s mg-b-m">
        <div class="view-only-block">
          <h1 class="mat-mdc-card-subtitle sub-header-section">Result</h1>
        </div>
      </div>
      <div class="flex-row view-only-container">
        <div class="view-only-block">
          <span class="status-title">Status</span>
          <mat-chip-listbox aria-label="Status" class="mat-mdc-chip-set-stacked" disabled="true">
            <div class="flex-row">
              <mat-chip-option *ngIf="isDownloadRequestStatusSelected(downloadStatus.PENDING)"
                               [selected]="isDownloadRequestStatusSelected(downloadStatus.PENDING)"
                               color="accent"
                               id="rs_ns">{{ downloadStatus.PENDING }}
              </mat-chip-option>
              <mat-chip-option *ngIf="isDownloadRequestStatusSelected(downloadStatus.IN_PROGRESS)"
                               [selected]="isDownloadRequestStatusSelected(downloadStatus.IN_PROGRESS)"
                               color="accent"
                               id="rs_e">{{ downloadStatus.IN_PROGRESS }}
              </mat-chip-option>
              <mat-chip-option *ngIf="isDownloadRequestStatusSelected(downloadStatus.AVAILABLE)"
                               [selected]="isDownloadRequestStatusSelected(downloadStatus.AVAILABLE)"
                               color="accent"
                               id="rs_c">{{ downloadStatus.AVAILABLE }}
              </mat-chip-option>
              <mat-chip-option *ngIf="isDownloadRequestStatusSelected(downloadStatus.FAILED)"
                               [selected]="isDownloadRequestStatusSelected(downloadStatus.FAILED)"
                               color="accent"
                               id="rs_ip">{{ downloadStatus.FAILED }}
              </mat-chip-option>
              <mat-chip-option *ngIf="isDownloadRequestStatusSelected(downloadStatus.CANCELLED)"
                               [selected]="isDownloadRequestStatusSelected(downloadStatus.CANCELLED)"
                               color="accent"
                               id="rs_ca">{{ downloadStatus.CANCELLED }}
              </mat-chip-option>
              <mat-chip-option *ngIf="isDownloadRequestStatusSelected(downloadStatus.ABORTED)"
                               [selected]="isDownloadRequestStatusSelected(downloadStatus.ABORTED)"
                               color="accent"
                               id="rs_a">{{ downloadStatus.ABORTED }}
              </mat-chip-option>
            </div>
          </mat-chip-listbox>
        </div>
        <div class="view-only-block">
          <ag-datablock [isDate]="true" data="{{this.viewDownloadRequest?.processStartDate}}" title="Start Date"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock [isDate]="true" data="{{this.viewDownloadRequest?.processEndDate}}" title="End Date"></ag-datablock>
        </div>
      </div>
      <div class="flex-row custom-container">
        <div class="category-container">
          <div class="view-only-block">
            <ag-datablock data="{{this.viewDownloadRequest?.sharepointFolderUrl}}" title="SharePoint Folder URL"
                          url="{{this.viewDownloadRequest?.sharepointFolderUrl}}"></ag-datablock>
          </div>
        </div>
        <div class="custom-right-container">
          <div class="view-only-block">
            <ag-datablock [isHtml]="true" data="{{this.viewDownloadRequest?.processStatusMessage}}" title="Details"></ag-datablock>
          </div>
        </div>
      </div>
      <div class="flex-row flex-g-s action-buttons mg-t-m mg-b-m">
        <button (click)="this.onCancelClick()" [disabled]="!this.canCancel()" color="warn" mat-raised-button>
          <mat-icon class="material-symbols-outlined">stop</mat-icon>
          Cancel Request
        </button>
        <button (click)="this.onExpediteClick()" [disabled]="this.disableExpediteRequestButton()" color="primary" mat-raised-button>
          <mat-icon class="material-symbols-outlined">more_time</mat-icon>
          Expedite Request
        </button>
        <button (click)="this.onRetryClick()" [disabled]="!this.canRetry()" class="secondary-button" mat-button>
          <mat-icon class="material-symbols-outlined">rotate_left</mat-icon>
          Retry
        </button>
        <button (click)="this.close()" mat-button type="button">
          <mat-icon class="material-symbols-outlined">cancel</mat-icon>
          Close
        </button>
      </div>
      <app-download-request-detail-file [sharepointFolderUrl]="downloadRequest?.sharepointFolderUrl"/>
      <div class="flex-row view-only-container mg-t-s mg-b-m">
        <div class="view-only-block">
          <h1 class="mat-mdc-card-subtitle sub-header-section">Metrics</h1>
        </div>
      </div>

      <div class="flex-row view-only-container mg-t-m mg-b-m">
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.estimatedFileCount}}" title="Estimated file count"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.estimatedSlideCount}}" title="Estimated slide count"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.currentNumberInQueue}}" title="Current number in queue"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.estimatedTimeInQueueInSeconds}}" title="Estimated time in queue"></ag-datablock>
        </div>

      </div>

      <div class="flex-row view-only-container mg-t-m mg-b-m">
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.estimatedProcessingTimeInSeconds}}" title="Estimated processing time"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.estimatedCompletionDatetime}}" title="Estimated completion datetime"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.timeInQueueInSeconds}}" title="Time in queue"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.timeToProcessInSeconds}}" title="Time to process"></ag-datablock>
        </div>
      </div>

      <div class="flex-row view-only-container mg-t-m mg-b-m">
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.totalCompletionTimeInSeconds}}" title="Total completion time"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.fileCount}}" title="File count"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.fileSize}}" title="File size"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.slideCount}}" title="Slide count"></ag-datablock>
        </div>
      </div>

      <div class="flex-row view-only-container mg-t-m mg-b-m">
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.staticSlideCount}}" title="Static slide count"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.templateSlideCount}}" title="Template slide count"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.dataSlideCount}}" title="Data slide count"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.downloadRequestMetrics?.orphanedSlideCount}}" title="Orphaned slide count"></ag-datablock>
        </div>
      </div>
    </mat-card-content>
  </mat-card>


</div>
