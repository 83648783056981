<div [showSkeleton]="loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <mat-card *ngIf="!loading" class="task-component-detail backdrop" id="task-component-detail">
    <mat-card-header class="mg-b-m">
      <div class="header-title">
        <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
        <mat-card-subtitle>Survey Tracker</mat-card-subtitle>
      </div>
      <div *ngIf="getFormControl('surveyUrl') && getFormControlValue('surveyUrl')" class="view-only-block flex-row mg-t-s">
        <ag-datablock [horizontalAlign]="true" title="Survey URL"
                      url="{{getFormControlValue('surveyUrl')}}"></ag-datablock>
      </div>
      <div class="view-only-block flex-row mg-t-s">
        <ag-datablock [horizontalAlign]="true" data="{{responseApprovalStatus}}"
                      title="Response Approval Status"></ag-datablock>
      </div>
    </mat-card-header>
    <mat-card-content>
      <form (ngSubmit)="this.submitForm(this.saveAndClose, 'Survey Tracker Saved.')" [formGroup]="formGroup">
        <div class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('period')}}" title="Period"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('marketCode') + ' ' + getFormControlValue('marketName')}}" title="Market"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('industryCode') + ' ' + getFormControlValue('industryName')}}" title="Industry"></ag-datablock>
          </div>
          <div class="view-only-block">
            <label>Survey Subject</label>
            <mat-chip-listbox aria-label="Status" class="mat-mdc-chip-set-stacked" disabled="true">
              <div class="flex-row">
                <mat-chip-option
                  [selected]="isSurveySubjectSelected(SurveySubject.SUPPLIER)"
                  color="accent"
                  id="ss_s">{{ SurveySubject.SUPPLIER }}
                </mat-chip-option>
                <mat-chip-option
                  [selected]="isSurveySubjectSelected(SurveySubject.RETAILER)"
                  color="accent"
                  id="ss_r">{{ SurveySubject.RETAILER }}
                </mat-chip-option>
              </div>
            </mat-chip-listbox>
          </div>
        </div>
        <div class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('surveyCode')}}" title="Survey Code"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('remoteRespId')}}" title="Remote Resp Id"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseCode')}}" title="Response Code"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('openLinkCode')}}" title="Response Code"></ag-datablock>
          </div>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('personCode')}}" title="Person Code"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseType')}}" title="Response Type"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row view-only-container">
          <mat-form-field>
            <mat-label>Person Code</mat-label>
            <mat-select formControlName="personCode">
              <mat-option
                *ngFor="let personCode of personCodeList"
                [value]="personCode.personCode"
              >{{ personCode.personCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Response Type</mat-label>
            <mat-select formControlName="responseType">
              <mat-option *ngFor="let responseType of responseTypeList"
                          [value]="responseType.responseType"
              >
                {{ responseType.responseType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="status-container mg-b-m">
          <label>Response Status</label>
          <mat-chip-listbox aria-label="Status" class="mat-mdc-chip-set-stacked" disabled="true">
            <div class="flex-row">
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.NOT_STARTED)"
                color="accent"
                id="rs_ns">{{ ResponseStatus.NOT_STARTED }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.IN_PROGRESS)"
                color="accent"
                id="rs_ip">{{ ResponseStatus.IN_PROGRESS }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.ERROR)"
                color="accent"
                id="rs_e">{{ ResponseStatus.ERROR }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.COMPLETE)"
                color="accent"
                id="rs_c">{{ ResponseStatus.COMPLETE }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.SCREENED)"
                color="accent"
                id="rs_s">{{ ResponseStatus.SCREENED }}
              </mat-chip-option>
            </div>
          </mat-chip-listbox>
        </div>
        <div class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseProgress')}}" title="Response Progress"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{util.getFormattedResponseProgressPercent(getFormControlValue('responseProgress'))}}"
                          title="Response Progress %"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('alerts')}}" title="Alerts"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseStartDatetime')}}"
                          title="Response Start Datetime"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseEndDatetime')}}"
                          title="Response End Datetime"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseLastImported')}}"
                          title="Response Last Imported"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseLastUpdated')}}"
                          title="Response Last Updated"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('invitationCompanyCode') + ' ' + getFormControlValue('invitationCompanyLabel')}}"
              title="Invitation Company"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('invitationDivisionCodes') + ' ' + getFormControlValue('invitationDivisionLabels')}}"
              title="Invitation Divisions"></ag-datablock>
          </div>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('mailingFirstName')}}"
                          title="Mailing First Name"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('mailingLastName')}}"
                          title="Mailing Last Name"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock [isLongData]="true"
                          data="{{getFormControlValue('mailingEmail')}}" title="Mailing Email"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('mailingJobTitle')}}"
                          title="Mailing Job Title"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row">
          <mat-form-field>
            <mat-label>Mailing First Name</mat-label>
            <input [formControl]="getFormControl('mailingFirstName')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Mailing Last Name</mat-label>
            <input [formControl]="getFormControl('mailingLastName')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Mailing Email</mat-label>
            <input [formControl]="getFormControl('mailingEmail')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Mailing Job Title</mat-label>
            <input [formControl]="getFormControl('mailingJobTitle')" matInput>
          </mat-form-field>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('mailingYearsInRole')}}"
                          title="Mailing Years In Role"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('mailingTelephone')}}"
                          title="Mailing Telephone"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('priorYearTelephone')}}"
                          title="Prior Year Telephone"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('mailingCellPhone')}}"
                          title="Mailing Cell Phone"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row">
          <mat-form-field>
            <mat-label>Mailing Years In Role</mat-label>
            <mat-select formControlName="mailingYearsInRole">
              <mat-option *ngFor="let mailingYearsInRole of mailingYearsInRoleList"
                          [value]="mailingYearsInRole.yearsInRole">
                {{ mailingYearsInRole.yearsInRole }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Mailing Telephone</mat-label>
            <input [formControl]="getFormControl('mailingTelephone')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Prior Year Telephone</mat-label>
            <input [formControl]="getFormControl('priorYearTelephone')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Mailing Cell Phone</mat-label>
            <input [formControl]="getFormControl('mailingCellPhone')" matInput>
          </mat-form-field>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('mailingCity')}}"
                          title="Mailing City"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('mailingLocale')}}"
                          title="Mailing Locale"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row">
          <mat-form-field>
            <mat-label>Mailing City</mat-label>
            <input [formControl]="getFormControl('mailingCity')" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Mailing Locale</mat-label>
            <mat-select [formControl]="getFormControl('mailingLocale')">
              <mat-option *ngFor="let mailingLocale of mailingLocales"
                          [value]="mailingLocale.language">
                {{ mailingLocale.language }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('respondentCompanyCode') + ' ' + getFormControlValue('respondentCompanyLabel')}}"
              title="Respondent Company"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('respondentDivisionCodes') + ' ' + getFormControlValue('respondentDivisionLabels')}}"
              title="Respondent Divisions"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseFirstName')}}"
                          title="Response First Name"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseLastName')}}" title="Response Last Name"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock [isLongData]="true" data="{{getFormControlValue('responseEmail')}}"
                          title="Response Email"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseJobTitle')}}" title="Response Job Title"></ag-datablock>
          </div>
          <div class="view-only-block">  <!-- should be a pill -->
            <ag-datablock data="{{getFormControlValue('responseYearsInRole')}}"
                          title="Response Years In Role"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseTelephone')}}"
                          title="Response Telephone"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseCellPhone')}}"
                          title="Response Cell Phone"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseCity')}}" title="Response City"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseLocale')}}" title="Response Locale"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('seniorityCode')}}"
                          title="Seniority"></ag-datablock>
          </div>
          <div class="mg-b-m flex-row w-25">
            <ag-slide-toggle (toggleUpdate)="onAllBuCategoriesChanged($event, 'allBuCategories')"
                             [isChecked]="util.isToggleChecked(this.getFormControl('allBuCategories'))"
                             [isDisabled]="!isEditMode"
            ></ag-slide-toggle>
            <span class="mg-l-s">All BU-Categories?</span>
          </div>
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('buCategoryCodes') + ' ' + getFormControlValue('buCategoryLabels')}}"
              title="Bu-Categories"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('localRegionCodes') + ' ' + getFormControlValue('localRegionLabels')}}"
              title="Local Regions"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('roleCodes') + ' ' + getFormControlValue('roleLabels')}}"
                          title="Roles"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('channelCodes') + ' ' + getFormControlValue('channelLabels')}}"
                          title="Channels"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('brPlCode') + ' ' + getFormControlValue('brPlLabel')}}"
                          title="BR/PL"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('selectedSubjectRollupCodes') + ' ' + getFormControlValue('selectedSubjectRollupLabels')}}"
              title="Selected Subject Rollups"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('selectedSubjectSingleCodes') + ' ' + getFormControlValue('selectedSubjectSingleLabels')}}"
              title="Selected Subject Singles"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('ratedSubjectRollupCodes') + ' ' + getFormControlValue('ratedSubjectRollupLabels')}}"
              title="Rated Subject Rollups"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('ratedSubjectSingleCodes') + ' ' + getFormControlValue('ratedSubjectSingleLabels')}}"
              title="Rated Subject Singles"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('ratingFrequency')}}" title="Rating Frequency"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('surveyCommentCount')}}"
                          title="Survey Comment Count"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('respondentDivisionExclusions')}}"
                          title="Respondent Division Exclusions"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('localRegionExclusions')}}"
                          title="Local Region Exclusions"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('brPlExclusions')}}" title="BR/PL Exclusions"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('channelExclusions')}}"
                          title="Channel Exclusions"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('buCategoryExclusions')}}"
                          title="BU-Category Exclusions"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('localRegionExclusions')}}"
                          title="Local Region Exclusions"></ag-datablock>
          </div>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('nominatedByCodes') + ' ' + getFormControlValue('nominatedByLabels')}}"
              title="Nominated By"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('nominatedCategories')}}"
                          title="Nominated Categories"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('nominationNotes')}}" title="Nomination Notes"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('nominatedByCodes') + ' ' + getFormControlValue('nominatedByLabels')}}"
              title="Nominated By"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row custom-container">
          <div class="category-container">
            <mat-form-field>
              <mat-label>Nominated Categories</mat-label>
              <textarea [formControl]="getFormControl('nominatedCategories')" matInput></textarea>
            </mat-form-field>
          </div>
          <div class="custom-right-container">
            <mat-form-field>
              <mat-label>Nomination Notes</mat-label>
              <textarea [formControl]="getFormControl('nominationNotes')" matInput></textarea>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('mailoutTemplateCode')}}"
                          title="Mailout Template"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('mailoutFieldSetCode')}}"
                          title="Mailout Field Set"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('mailoutDatetime')}}"
                          title="Mailout Datetime"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row">
          <mat-form-field>
            <mat-label>Mailout Template</mat-label>
            <mat-select [formControl]="getFormControl('mailoutTemplateCode')">
              <mat-option *ngFor="let mailoutTemplateCode of mailoutTemplateCodeList"
                          [value]="mailoutTemplateCode.mailoutTemplateCode">
                {{ mailoutTemplateCode.mailoutTemplateCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Mailout Field Set</mat-label>
            <mat-select [formControl]="getFormControl('mailoutFieldSetCode')">
              <mat-option *ngFor="let mailoutFieldsetCode of mailoutFieldsetCodeList"
                          [value]="mailoutFieldsetCode.mailoutFieldsetCode">
                {{ mailoutFieldsetCode.mailoutFieldsetCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container>
            <ag-datetimepicker [control]="getFormControl('mailoutDatetime')" [label]="'Mailout Datetime'" class="mat-mdc-form-field"></ag-datetimepicker>
          </ng-container>
        </div>

        <div class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('remoteTaskId')}}" title="Remote Task IDs"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('mailoutHistory')}}" title="Mailout History"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('firstMailingDate')}}"
                          title="First Mailing Datetime"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('lastMailingDate')}}"
                          title="Last Mailing Datetime"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block"> <!-- should be a pill -->
            <ag-datablock data="{{getFormControlValue('lastMailingStatus')}}"
                          title="Last Mailing Status"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('timesMailed')}}" title="Times Mailed"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('endorserName')}}" title="Endorser Name"></ag-datablock>
          </div>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('inviteForAdditionalRatings')}}"
                          title="Invite For Additional Ratings"></ag-datablock>
          </div>
          <div class="mg-b-m flex-row w-25">
            <ag-slide-toggle (toggleUpdate)="onInvalidateRatingsChanged($event)"
                             [isChecked]="util.isToggleChecked(this.getFormControl('invalidateRatings'))"
                             [isDisabled]="true"
            ></ag-slide-toggle>
            <span class="mg-l-s">Invalidate Ratings?</span>
          </div>
          <div class="mg-b-m flex-row">
            <ag-slide-toggle (toggleUpdate)="onInvalidateVerbatimSelected($event)"
                             [isChecked]="util.isToggleChecked(this.getFormControl('invalidateVerbatim'))"
                             [isDisabled]="true"
            ></ag-slide-toggle>
            <span class="mg-l-s">Invalidate Verbatim?</span>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row">
          <mat-form-field>
            <mat-label>Invite For Additional Ratings</mat-label>
            <input [formControl]="getFormControl('inviteForAdditionalRatings')" matInput>
          </mat-form-field>
          <div class="mg-b-m flex-row w-25">
            <ag-slide-toggle (toggleUpdate)="onInvalidateRatingsChanged($event)"
                             [isChecked]="util.isToggleChecked(this.getFormControl('invalidateRatings'))"
            ></ag-slide-toggle>
            <span class="mg-l-s">Invalidate Ratings?</span>
          </div>
          <div class="mg-b-m flex-row">
            <ag-slide-toggle (toggleUpdate)="onInvalidateVerbatimSelected($event)"
                             [isChecked]="util.isToggleChecked(this.getFormControl('invalidateVerbatim'))"
            ></ag-slide-toggle>
            <span class="mg-l-s">Invalidate Verbatim?</span>
          </div>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('pmNotes')}}" title="PM Notes"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('notesToPM')}}" title="Notest to PM"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('respondentCompanyPriority')}}"
                          title="Respondent Company Priority"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row custom-container">
          <div class="category-container">
            <mat-form-field>
              <mat-label>PM Notes</mat-label>
              <textarea [formControl]="getFormControl('pmNotes')" matInput></textarea>
            </mat-form-field>
          </div>
          <div class="custom-right-container">
            <mat-form-field>
              <mat-label>Notes to PM</mat-label>
              <textarea [formControl]="getFormControl('notesToPM')" matInput></textarea>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('respondentCompanyPriority')}}"
                          title="Respondent Company Priority"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseFileForInterviewersCreatedDatetime')}}"
                          title="Response Files For Interviewers Created Datetime"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseFilesForRespondentsCreatedDatetime')}}"
                          title="Response Files For Respondents Created Datetime"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('responseFilesForRespondentsCreatedDatetime')}}"
                          title="Response Files For Respondents Created Datetime"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock [url]="getFormControlValue('responseFileForInterviewers')"
                          data="{{getFormControlValue('responseFileForInterviewers')}}"
                          title="Response File For Inverviewers"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock [url]="getFormControlValue('responseFileForRespondents')"
                          data="{{getFormControlValue('responseFileForRespondents')}}"
                          title="Response File For Inverviewers"></ag-datablock>
          </div>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>Response Files Status</mat-label>
            <mat-select [formControl]="getFormControl('responseFilesStatus')">
              <mat-option *ngFor="let responseFilesStatus of fileRequestStatusList"
                          [value]="responseFilesStatus">
                {{ responseFilesStatus }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('surveyId')}}" title="Survey Id"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('surveyResponseId')}}" title="Survey Response Id"></ag-datablock>
          </div>
        </div>

        <div class="flex-row flex-g-s action-buttons">
          <button (click)="onSaveAndCloseClick()" [disabled]="!this.hasAnyEdits()" color="primary" mat-raised-button
                  type="submit">
            <mat-icon class="material-symbols-outlined">save</mat-icon>
            Save and Close
          </button>
          <button (click)="resetForm()" *ngIf="isEditMode" [disabled]="!this.hasAnyEdits()" mat-button type="button">
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
            Reset
          </button>
          <button (click)="resetForm(); close()" *ngIf="hasAnyEdits()" [disabled]="!this.hasAnyEdits()" mat-button
                  type="button">
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
            Cancel
          </button>
          <button (click)="resetForm(); close()" *ngIf="!hasAnyEdits()" [disabled]=" this.hasAnyEdits()"
                  class="primary-font-color" mat-button type="button">
            <i class="fa-sharp fa-light fa-xmark-large"></i> Close
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
