<div [showSkeleton]="loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <mat-card *ngIf="!loading" class="task-component-detail backdrop" id="task-component-detail">

    <mat-card-header class="mg-b-m">
      <div class="header-title">
        <mat-card-subtitle>Download Request</mat-card-subtitle>
      </div>

    </mat-card-header>
    <mat-card-content>
      <div class="flex-row view-only-container">
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.period}}" title="Period"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.country}}" title="Country"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.batchCode}}" title="Batch Code"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.language}}" title="Language"></ag-datablock>
        </div>
      </div>
      <div class="flex-row view-only-container mg-t-m mg-b-m">
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.product}}" title="Product"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.productType}}" title="Product Type"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.sponsor}}" title="Sponsor"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.sharepointFolderUrl}}" title="SharePoint Folder URL"
                        url="{{this.viewDownloadRequest?.sharepointFolderUrl}}"></ag-datablock>
        </div>
      </div>
      <div class="flex-row view-only-container mg-t-m mg-b-m">
        <div class="view-only-block">
          <ag-datablock data="{{this.viewDownloadRequest?.requestedBy}}" title="Requested By"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock [isDate]="true" data="{{this.viewDownloadRequest?.requestDate}}" title="Request Date"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock [isDate]="true" data="{{this.viewDownloadRequest?.processStartDate}}" title="Start Date"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock [isDate]="true" data="{{this.viewDownloadRequest?.processEndDate}}" title="End Date"></ag-datablock>
        </div>
      </div>
      <div class="status-container mg-b-m">
        <span>Status</span>
        <mat-chip-listbox aria-label="Status" class="mat-mdc-chip-set-stacked" disabled="true">
          <div class="flex-row">
            <mat-chip-option
              [selected]="isDownloadRequestStatusSelected(downloadStatus.PENDING)"
              color="accent"
              id="rs_ns">{{ downloadStatus.PENDING }}
            </mat-chip-option>
            <mat-chip-option
              [selected]="isDownloadRequestStatusSelected(downloadStatus.IN_PROGRESS)"
              color="accent"
              id="rs_e">{{ downloadStatus.IN_PROGRESS }}
            </mat-chip-option>
            <mat-chip-option
              [selected]="isDownloadRequestStatusSelected(downloadStatus.READY_FOR_DOWNLOAD)"
              color="accent"
              id="rs_c">{{ downloadStatus.READY_FOR_DOWNLOAD }}
            </mat-chip-option>
            <mat-chip-option
              [selected]="isDownloadRequestStatusSelected(downloadStatus.FAILED)"
              color="accent"
              id="rs_ip">{{ downloadStatus.FAILED }}
            </mat-chip-option>
            <mat-chip-option
              [selected]="isDownloadRequestStatusSelected(downloadStatus.CANCELLED)"
              color="accent"
              id="rs_ca">{{ downloadStatus.CANCELLED }}
            </mat-chip-option>
            <mat-chip-option
              [selected]="isDownloadRequestStatusSelected(downloadStatus.ABORTED)"
              color="accent"
              id="rs_a">{{ downloadStatus.ABORTED }}
            </mat-chip-option>
          </div>
        </mat-chip-listbox>
      </div>
      <div class="flex-row custom-container">
        <div class="category-container">
          <div class="view-only-block">
            <ag-datablock [isJson]="true" data="{{this.viewDownloadRequest?.downloadParameters}}" title="Parameter"></ag-datablock>
          </div>
        </div>
        <div class="custom-right-container">
          <div class="view-only-block">
            <ag-datablock [isHtml]="true" data="{{this.viewDownloadRequest?.processStatusMessage}}" title="Message"></ag-datablock>
          </div>
        </div>
      </div>
      <div class="flex-row flex-g-s action-buttons mg-t-m">
        <button (click)="this.onCancelClick()" [disabled]="!this.canCancel()" color="warn" mat-raised-button>
          <mat-icon class="material-symbols-outlined">stop</mat-icon>
          Cancel Request
        </button>
        <button (click)="this.close()" mat-button type="button">
          <mat-icon class="material-symbols-outlined">cancel</mat-icon>
          Close
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <app-download-request-detail-file [sharepointFolderUrl]="downloadRequest?.sharepointFolderUrl"/>

</div>
