import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TransferRunService} from "@data/transfer-run/transfer.run.service";
import {BaseComponent} from "@pages/base.component";
import {TransferRunLog} from "@data/interefaces/data.interfaces";
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-transfer-run-detail-messages-details',
  templateUrl: './transfer-run-detail-messages-details.component.html',
  styleUrls: ['./transfer-run-detail-messages-details.component.scss']
})
export class TransferRunDetailMessagesDetailsComponent extends BaseComponent implements OnChanges {

  @Input() referenceCode: string | undefined;
  @Input() transferDetailId: string | undefined;

  transferRunLog: TransferRunLog | undefined;
  showSkeleton: boolean = true;

  constructor(private _transferRunService: TransferRunService, private _clipboard: Clipboard) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['transferDetailId']?.currentValue) {
      this.showSkeleton = true;
      this._transferRunService.getTransferLog(this.referenceCode, this.transferDetailId).then(result => {
        this.transferRunLog = result;
        this.showSkeleton = false;
      });
    }
  }

  onCopyClipboardClick(textToCopy: string | undefined) {
    if (textToCopy !== undefined) {
      this._clipboard.copy(textToCopy);
    }

  }
}
