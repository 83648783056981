<ng-container *ngIf="!this.refreshComplete">
  <p>Loading</p>
</ng-container>
<ng-container *ngIf="this.refreshComplete">
  <p *ngIf="rowData.length == 0">No Rows Found</p>

  <div *ngIf="rowData.length != 0" class="full-height-container">
    <app-component-header (downloadClickEvent)="this.handleDownloadClickEvent('verbatim-file-rows')"
                          (refreshClickEvent)="this.loadPageData()"
                          [checkAutoRefreshByDefault]="true"
                          [hideColumnsButton]="true"
                          [hideUploadButton]="true"
                          [redirectTo]="'verbatimFileRows'"
                          [startRefreshTimer]="this.refreshComplete"
                          [subtitleSegments]="['File Rows']"
                          class="full-width"></app-component-header>
    <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                     (firstDataRendered)="onFirstDataRendered($event)"
                     (gridReady)="onGridReady($event)"
                     (viewportChanged)="onViewportChanged($event)"
                     [columnDefs]="tableColumns"
                     [gridOptions]="baseAgGridOptions"
                     [overlayNoRowsTemplate]="noRowsTemplate"
                     [paginationPageSize]="50"
                     [pagination]="true"
                     [rowData]="rowData"
                     [suppressPropertyNamesCheck]="true"
                     class="ag-theme-quartz ag-grid-flex"
                     style="height: 100%;">
    </ag-grid-angular>
  </div>
</ng-container>
