<div [formGroup]="formGroup" class="dynamic-panel">
  <ng-container *ngFor="let gridLine of getRowList()">
    <div class="flex-row flex-g-s mg-b-l full-width">
      <ng-container *ngFor="let field of getRowFields(gridLine); index as i">
        <div *ngIf="['string', 'number'].includes(field.config.type)">
          <mat-form-field class="full-width">
            <mat-label>{{ field.config.displayLabel }}</mat-label>
            <input [formControl]="field.formControl" matInput placeholder="{{field.config.placeHolderLabel}}">
            <mat-error *ngIf="field.formControl.hasError('pattern')">
              {{ field.config.validationErrorMsg }}
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="['select'].includes(field.config.type)">
          <mat-form-field class="full-width">
            <mat-label>{{ field.config.displayLabel }}</mat-label>
            <mat-select [formControl]="field.formControl" multiple="{{field.config.mode == 'multiple'}}" placeholder="{{field.config.placeHolderLabel}}">
              <mat-option *ngFor="let entry of getSplitArray(field.config.valueList); index as i" value="{{entry.trim()}}">
                {{ getSplitArray(field.config.labelList)[i] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
