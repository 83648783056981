import {Injectable} from "@angular/core";
import {ApiService} from "@core/api/api.service";
import {ViewMailoutFieldsets, ViewMailoutTemplates, ViewPersonCode} from "@data/interefaces/panel.interfaces";
import {Util} from "@data/util/util";

@Injectable({
  providedIn: 'root'
})
export class PanelService {
  constructor(private _apiService: ApiService) {
  }

  public getMailoutTemplates(surveyCode: string): Promise<ViewMailoutTemplates[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewMailoutTemplates[]>(`/v2/api/panel/mailout-templates`, queryParam.join('&'));
  }

  public getMailoutFieldsets(surveyCode: string): Promise<ViewMailoutFieldsets[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewMailoutFieldsets[]>(`/v2/api/panel/mailout-fieldsets`, queryParam.join('&'));
  }

  public getPersonCodes(surveyCode: string): Promise<ViewPersonCode[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewPersonCode[]>(`/v2/api/panel/person-codes`, queryParam.join('&'));
  }

}
