import {Component} from '@angular/core';
import {BaseComponent} from "../base.component";
import {SecurityService} from "src/app/core/secutiry/security.service";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent extends BaseComponent {

  constructor(private _securityService: SecurityService) {
    super();
  }

  override ngOnInit() {
    this._securityService.isAuthenticated().then(authenticated => {
        if (authenticated) {
          this._router.navigate(['/home/welcome']).catch(error => this._router.navigate(['/error']));
        }
      }
    )
  }

  public signIn() {
    this._securityService.signIn()
  }

}
