import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {EventProcessor, EventType} from "@data/interefaces/event.type.interface";
import {Util} from "@data/util/util";

@Injectable({
  providedIn: 'root'
})
export class EventTypeService {

  constructor(private _apiService: ApiService) {
  }

  /**
   * Retrieve all event type information
   */
  public getEventTypes(): Promise<EventType[]> {
    return this._apiService.makeGetServiceCall<EventType[]>(`/v2/api/event/event-types`);
  }

  /**
   * Get Event Type by code
   *
   * @param eventTypeCode the event type code
   */
  public getEventTypeByCode(eventTypeCode: string): Promise<EventType> {
    let queryParams: string[] = [];
    Util.pushQueryParam("eventTypeCode", eventTypeCode, queryParams);
    return this._apiService.makeGetServiceCall<EventType>(`/v2/api/event/event-type`, queryParams.join('&'));
  }

  /**
   * Save event type
   *
   * @param eventType
   */
  public saveEventType(eventType: EventType): Promise<EventType> {
    return this._apiService.makePutServiceCall<EventType>(`/v2/api/event/event-type`, null, eventType);
  }

  /**
   * Get all event processors
   */
  public getAllEventProcessors(): Promise<EventProcessor[]> {
    return this._apiService.makeGetServiceCall<EventProcessor[]>(`/v2/api/event/event-processors`);
  }

  /**
   * Get event processors by event type
   *
   * @param eventType the event type
   */
  public getEventProcessorsByEventType(eventType: string): Promise<EventProcessor[]> {
    let queryParams: string[] = [];
    Util.pushQueryParam("eventType", eventType, queryParams);
    return this._apiService.makeGetServiceCall<EventProcessor[]>(`/v2/api/event/event-processors/by-type`, queryParams.join('&'));
  }

  /**
   * Get Event Processor by processor code
   *
   * @param processorCode the processor code
   */
  public getEventProcessorsByProcessorCode(processorCode: string): Promise<EventProcessor> {
    let queryParams: string[] = [];
    Util.pushQueryParam("processorCode", processorCode, queryParams);
    return this._apiService.makeGetServiceCall<EventProcessor>(`/v2/api/event/event-processor`, queryParams.join('&'));
  }

  /**
   * Save Event Processor
   *
   * @param eventProcessor the event processor
   */
  public saveEventProcessor(eventProcessor: EventProcessor): Promise<EventProcessor> {
    return this._apiService.makePutServiceCall<EventProcessor>(`/v2/api/event/event-processor`, null, eventProcessor);
  }

  public startEventProcessor(processorCode: string): Promise<EventProcessor> {
    let queryParams: string[] = [];
    Util.pushQueryParam("processorCode", processorCode, queryParams);
    return this._apiService.makePutServiceCall<EventProcessor>(`/v2/api/event/event-processor/start`, queryParams.join('&'), null);
  }

  public stopEventProcessor(processorCode: string): Promise<EventProcessor> {
    let queryParams: string[] = [];
    Util.pushQueryParam("processorCode", processorCode, queryParams);
    return this._apiService.makePutServiceCall<EventProcessor>(`/v2/api/event/event-processor/stop`, queryParams.join('&'), null);
  }

}
