import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {DataItem, OperationStatus, ResultsetThresholds, BatchProcessResult} from "@data/interefaces/data.interfaces";
import {OpenLinkInvitee} from "@data/interefaces/panel.interfaces";
import {Util} from "@data/util/util";

@Injectable({
  providedIn: 'root'
})
export class OpenLinkInviteesService {

  constructor(private _apiService: ApiService) {
  }

  public getOpenLinkInvitees(period: string, marketCode: string, surveyCode: string): Promise<OpenLinkInvitee[]> {
    let queryParams: string[] = [];
    Util.pushQueryParam("period", period, queryParams);
    Util.pushQueryParam("marketCode", marketCode, queryParams);
    Util.pushQueryParam("surveyCode", surveyCode, queryParams);
    return this._apiService.makeGetServiceCall<OpenLinkInvitee[]>(`/v2/api/panel/open-link-invitees`, queryParams.join('&'));
  }

  public getOpenLinkInviteesById(surveyInviteeId: string): Promise<OpenLinkInvitee> {
    return this._apiService.makeGetServiceCall<OpenLinkInvitee>(`/v2/api/panel/open-link-invitees/${surveyInviteeId}`);
  }

  public getOpenLinkInviteesThresholds(period: string, marketCode: string, surveyCode: string): Promise<ResultsetThresholds> {
    let queryParams: string[] = [];
    Util.pushQueryParam("period", period, queryParams);
    Util.pushQueryParam("marketCode", marketCode, queryParams);
    Util.pushQueryParam("surveyCode", surveyCode, queryParams);
    return this._apiService.makeGetServiceCall<ResultsetThresholds>(`/v2/api/panel/open-link-invitees/thresholds`, queryParams.join('&'));
  }

  public saveOpenLinkInvitees(openLinkInvitee: any): Promise<DataItem> {
    return this._apiService.makePostServiceCall<DataItem>(`/v2/api/panel/open-link-invitees`, null, openLinkInvitee);
  }

  public updateOpenLinkInvitees(openLinkInvitee: any) {
    return this._apiService.makePutServiceCall<OperationStatus>(`/v2/api/panel/open-link-invitees`, null, openLinkInvitee);
  }

  public getLatestOpenLinkInviteesBatchProcess() {
    return this._apiService.makeGetServiceCall<BatchProcessResult>(`/v2/api/panel/open-link-invitees/bulk-process/latest`);
  }

  public startOpenLinkInviteesBatchProcess(openLinkInvitee: OpenLinkInvitee[], processType: string) {
    let queryParams: string[] = [];
    Util.pushQueryParam("processType", processType, queryParams);
    return this._apiService.makePutServiceCall<{ id: number }>(`/v2/api/panel/open-link-invitees/bulk-process/start`, queryParams.join('&'), openLinkInvitee);
  }

}

