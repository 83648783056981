import {Component, Input} from '@angular/core';
import {MenuItem} from "@data/interefaces/data.interfaces";

@Component({
  selector: 'ag-sub-menu',
  templateUrl: './ag-sub-menu.component.html',
  styleUrls: ['./ag-sub-menu.component.scss']
})
export class AgSubMenuComponent {

  @Input()
  menuItem!: MenuItem
  @Input()
  isRootMenu: boolean = true;

}
