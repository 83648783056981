import {Component} from '@angular/core';
import {TableBaseComponent} from "@pages/table.base.component";
import {pageContext, tableColumns} from "@pages/event-processor/event-processor.component.ds";
import {EventProcessor} from "@data/interefaces/event.type.interface";
import {FilterChangedEvent, SelectionColumnDef} from "@ag-grid-community/core";
import {ActivatedRoute} from "@angular/router";
import {EventTypeService} from "@data/event/event-type.service";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'app-event-processor',
  templateUrl: './event-processor.component.html',
  styleUrls: ['./event-processor.component.scss'],
  providers: [AgGridService]
})
export class EventProcessorComponent extends TableBaseComponent {
  refreshComplete: boolean = true;
  rowData: EventProcessor[] = [];
  eventType: string | null | undefined;
  protected override tableColumns = tableColumns;
  protected override pageContext = pageContext;

  constructor(private _activatedRoute: ActivatedRoute, private _eventTypeService: EventTypeService) {
    super();
    this._activatedRoute.queryParamMap.subscribe(params => {
      this.eventType = params.get('event_type');
    });
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.refreshView();
  }

  refreshView() {
    this.refreshComplete = false;
    this.displayProgressBar(true);
    this.eventType = this.pageContext.etc_f;
    let promise: Promise<EventProcessor[]> = this.eventType ? this._eventTypeService.getEventProcessorsByEventType(this.eventType)
      : this._eventTypeService.getAllEventProcessors();
    promise.then(result => this.rowData = result)
      .catch(error => this.displayNotificationMessage('error', JSON.stringify(error)))
      .finally(() => {
        this.refreshComplete = true;
        this.displayProgressBar(false)
      });
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    const updateLiveDataFilter = this.isUpdateLiveDataFilterRequired(event.columns);
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this)).then(() => {
      if (updateLiveDataFilter) {
        this.onLiveDataFilterChange(true);
      }
    });
  }

  onLiveDataFilterChange(forceReload: boolean = false): void {
    if (forceReload) {
      this.refreshView();
    }
    this.updateToPageContextUrl();
  }

  protected override updatePageContext(updateContextUrl: boolean): void {
  }

  protected override getSelectionColumnDef(): SelectionColumnDef {
    return {};
  }
}
