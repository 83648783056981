import {NgModule} from '@angular/core';

// Material
import {MatTreeModule} from "@angular/material/tree";
import {MatDatepickerModule} from "@angular/material/datepicker";

// Common components
// Shared
import {SharedModule} from "@shared/shared.module";
import {AgHideShowColumnComponent} from "@shared/ag-hide-show-column/ag-hide-show-column.component";
// Headers
import {ComponentHeaderComponent} from '@pages/segments/headers/component-header/component-header.component';
import {SelectHeaderComponent} from '@pages/segments/grid/columns/select-header/select-header.component';
// Columns
import {ActionColumnComponent} from '@pages/segments/grid/columns/action-column/action-column.component';
import {CircleCheckColumnComponent} from '@pages/segments/grid/columns/circle-check-column/circle-check-column.component';
import {DurationColumnComponent} from '@pages/segments/grid/columns/duration-column/duration-column.component';
import {LongDataColumnComponent} from '@pages/segments/grid/columns/long-data-column/long-data-column.component';
import {MultiLineLinkColumnComponent} from '@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component';
import {SelectColumnComponent} from '@pages/segments/grid/columns/select-column/select-column.component';
import {StatusColumnComponent} from '@pages/segments/grid/columns/status-column/status-column.component';
// Filter
import {ResetFilterComponent} from '@pages/segments/grid/filters/reset-filter/reset-filter.component';

// Home Pages
import {HomeComponent} from "@pages/home/home.component";
import {SigninComponent} from "@pages/signin/signin.component";

// Transfers
import {TransferConfigComponent} from '@pages/transfer-config/transfer-config.component';
import {TransferConfigDetailComponent} from '@pages/transfer-config-detail/transfer-config-detail.component';
import {TransferManualRunComponent} from '@pages/transfer-manual-run/transfer-manual-run.component';
import {TransferRunComponent} from '@pages/transfer-run/transfer-run.component';
import {TransferRunDetailComponent} from '@pages/transfer-run-detail/transfer-run-detail.component';
import {TransferRunDetailTasksComponent} from '@pages/transfer-run-detail/transfer-run-detail-tasks/transfer-run-detail-tasks.component';
import {TransferRunDetailMessagesComponent} from '@pages/transfer-run-detail/transfer-run-detail-messages/transfer-run-detail-messages.component';
import {
  TransferRunDetailTasksDetailsComponent
} from '@pages/transfer-run-detail/transfer-run-detail-tasks/transfer-run-detail-tasks-details/transfer-run-detail-tasks-details.component';
import {
  TransferRunDetailMessagesDetailsComponent
} from '@pages/transfer-run-detail/transfer-run-detail-messages/transfer-run-detail-messages-details/transfer-run-detail-messages-details.component';

// Verbatim
import {VerbatimFilesComponent} from '@pages/verbatim-files/verbatim-files.component';
import {VerbatimFileTaskComponent} from "@pages/verbatim-files/verbatim-file-task/verbatim-file-task.component";
import {VerbatimFileDetailComponent} from "@pages/verbatim-files/verbatim-file-detail/verbatim-file-detail.component";
import {VerbatimFileDetailRowsComponent} from "@pages/verbatim-files/verbatim-file-detail/verbatim-file-detail-rows/verbatim-file-detail-rows.component";
//
import {VerbatimFileRowsComponent} from "@pages/verbatim-file-rows/verbatim-file-rows.component";

// Offline Surveys
import {OfflineSurveyComponent} from '@pages/offline-survey/offline-survey.component';

// Product Period
import {ProductPeriodComponent} from '@pages/product-spec/product-period/product-period.component';
import {ProductPeriodTaskComponent} from '@pages/product-spec/product-period/product-period-task/product-period-task.component';
import {ProductPeriodDetailComponent} from '@pages/product-spec/product-period/product-period-detail/product-period-detail.component';
import {ProductPeriodBulkComponent} from '@pages/product-spec/product-period/product-period-bulk/product-period-bulk.component';
import {ProductAssignmentComponent} from "@pages/product-spec/product-assignment/product-assignment.component";

// Product Respondent Company
import {RespondentCompanyComponent} from '@pages/product-spec/product-assignment/respondent-company/respondent-company.component';
import {RespondentCompanyTaskComponent} from '@pages/product-spec/product-assignment/respondent-company/respondent-company-task/respondent-company-task.component';
import {RespondentCompanyDetailComponent} from '@pages/product-spec/product-assignment/respondent-company/respondent-company-detail/respondent-company-detail.component';
import {RespondentCompanyBulkComponent} from '@pages/product-spec/product-assignment/respondent-company/respondent-company-bulk/respondent-company-bulk.component';

// Product Sponsor Verbatim
import {ProductSponsorVerbatimComponent} from '@pages/product-spec/product-sponsor-verbatim/product-sponsor-verbatim.component';
import {ProductSponsorVerbatimTaskComponent} from "@pages/product-spec/product-sponsor-verbatim/product-sponsor-verbatim-task/product-sponsor-verbatim-task.component";
import {ProductSponsorVerbatimBulkComponent} from "@pages/product-spec/product-sponsor-verbatim/product-sponsor-verbatim-bulk/product-sponsor-verbatim-bulk.component";

// Unique Link Invitations
import {UniqueLinkInviteesComponent} from '@pages/panel/unique-link-invitees/unique-link-invitees.component';
import {UniqueLinkInviteesTaskComponent} from "@pages/panel/unique-link-invitees/unique-link-invitees-task/unique-link-invitees-task.component";
import {UniqueLinkInviteesDetailComponent} from "@pages/panel/unique-link-invitees/unique-link-invitees-detail/unique-link-invitees-detail.component";
import {UniqueLinkInviteesBulkComponent} from '@pages/panel/unique-link-invitees/unique-link-invitees-bulk/unique-link-invitees-bulk.component';

// Open Link Invitations
import {OpenLinkInviteesComponent} from '@pages/panel/open-link-invitees/open-link-invitees.component';
import {OpenLinkInviteesTaskComponent} from '@pages/panel/open-link-invitees/open-link-invitees-task/open-link-invitees-task.component';
import {OpenLinkInviteesDetailComponent} from '@pages/panel/open-link-invitees/open-link-invitees-detail/open-link-invitees-detail.component';
import {OpenLinkInviteesBulkComponent} from '@pages/panel/open-link-invitees/open-link-invitees-bulk/open-link-invitees-bulk.component';

// MS Excel Invitations
import {MsExcelSurveyInviteesComponent} from '@pages/panel/ms-excel-survey-invitees/ms-excel-survey-invitees.component';
import {MsExcelSurveyInviteesTaskComponent} from '@pages/panel/ms-excel-survey-invitees/ms-excel-survey-invitees-task/ms-excel-survey-invitees-task.component';
import {MsExcelSurveyInviteesDetailComponent} from '@pages/panel/ms-excel-survey-invitees/ms-excel-survey-invitees-detail/ms-excel-survey-invitees-detail.component';
import {MsExcelSurveyInviteesBulkComponent} from '@pages/panel/ms-excel-survey-invitees/ms-excel-survey-invitees-bulk/ms-excel-survey-invitees-bulk.component';

// Survey Tracker
import {SurveyTrackerComponent} from '@pages/panel/survey-tracker/survey-tracker.component';
import {SurveyTrackerTaskComponent} from '@pages/panel/survey-tracker/survey-tracker-task/survey-tracker-task.component';
import {SurveyTrackerDetailComponent} from '@pages/panel/survey-tracker/survey-tracker-detail/survey-tracker-detail.component';
import {SurveyTrackerBulkComponent} from '@pages/panel/survey-tracker/survey-tracker-bulk/survey-tracker-bulk.component';

// PPTX Download Requests
import {DownloadRequestListComponent} from "@pages/download-request/download-request-list.component";
import {DownloadRequestTaskComponent} from '@pages/download-request/download-request-task/download-request-task.component';
import {DownloadRequestAddComponent} from "@pages/download-request/download-request-add/download-request-add.component";
import {DownloadRequestAddLegacyComponent} from "@pages/download-request/download-request-add-legacy/download-request-add-legacy.component";
import {DownloadRequestDetailComponent} from "@pages/download-request/download-request-detail/download-request-detail.component";
import {DownloadRequestDetailFileComponent} from "@pages/download-request/download-request-detail/download-request-detail-file/download-request-detail-file.component";

// MS Excel Survey Response Files
import {SurveyResponseFileComponent} from '@pages/survey-response-file/survey-response-file.component';
import {ResponseFileTaskComponent} from '@pages/survey-response-file/response-file-task/response-file-task.component';
import {ResponseFileDetailComponent} from '@pages/survey-response-file/response-file-detail/response-file-detail.component';
import {ResponseFileUploadComponent} from '@pages/survey-response-file/response-file-upload/response-file-upload.component';

// Events
import { EventTypeComponent } from '@pages/event-type/event-type.component';
import { EventTypeTaskComponent } from '@pages/event-type/event-type-task/event-type-task.component';
import { EventTypeDetailComponent } from '@pages/event-type/event-type-detail/event-type-detail.component';
import { EventProcessorComponent } from '@pages/event-processor/event-processor.component';
import { EventProcessorTaskComponent } from '@pages/event-processor/event-processor-task/event-processor-task.component';
import { EventProcessorDetailComponent } from '@pages/event-processor/event-processor-detail/event-processor-detail.component';
import {EventLogComponent} from "@pages/event-log/event-log.component";

// Admin
import {ArmConfigurationComponent} from '@pages/arm-configuration/arm-configuration.component';
import {VerbatimFileUploadComponent} from "@pages/verbatim-files/varbatim-file-upload/verbatim-file-upload.component";

/**
 * We define all Angular page component used here.
 * Everytime we add a new page component, please add it here.
 */
@NgModule({
  declarations: [
    // Shared
    AgHideShowColumnComponent,

    // Common components
    ComponentHeaderComponent,
    SelectHeaderComponent,
    //
    ActionColumnComponent,
    CircleCheckColumnComponent,
    DurationColumnComponent,
    LongDataColumnComponent,
    MultiLineLinkColumnComponent,
    SelectColumnComponent,
    StatusColumnComponent,
    //
    ResetFilterComponent,

    // Home Pages
    SigninComponent,
    HomeComponent,

    // Transfers
    TransferRunComponent,
    TransferConfigComponent,
    TransferConfigDetailComponent,
    TransferRunDetailComponent,
    TransferRunDetailTasksComponent,
    TransferRunDetailMessagesComponent,
    TransferRunDetailTasksDetailsComponent,
    TransferRunDetailMessagesDetailsComponent,
    TransferManualRunComponent,

    // Verbatim
    VerbatimFilesComponent,
    VerbatimFileTaskComponent,
    VerbatimFileDetailComponent,
    VerbatimFileDetailRowsComponent,
    VerbatimFileUploadComponent,

    //
    VerbatimFileRowsComponent,

    // Offline Surveys
    OfflineSurveyComponent,

    // Product Period
    ProductPeriodComponent,
    ProductPeriodTaskComponent,
    ProductPeriodDetailComponent,
    ProductPeriodBulkComponent,
    ProductAssignmentComponent,

    // Product Respondent Company
    RespondentCompanyComponent,
    RespondentCompanyTaskComponent,
    RespondentCompanyDetailComponent,
    RespondentCompanyBulkComponent,

    // Product Sponsor Verbatim
    ProductSponsorVerbatimComponent,
    ProductSponsorVerbatimTaskComponent,
    ProductSponsorVerbatimBulkComponent,

    // Unique Link Invitations
    UniqueLinkInviteesComponent,
    UniqueLinkInviteesTaskComponent,
    UniqueLinkInviteesDetailComponent,
    UniqueLinkInviteesBulkComponent,

    // Open Link Invitations
    OpenLinkInviteesComponent,
    OpenLinkInviteesTaskComponent,
    OpenLinkInviteesDetailComponent,
    OpenLinkInviteesBulkComponent,

    // MS Excel Invitations
    MsExcelSurveyInviteesComponent,
    MsExcelSurveyInviteesTaskComponent,
    MsExcelSurveyInviteesDetailComponent,
    MsExcelSurveyInviteesBulkComponent,

    // Survey Tracker
    SurveyTrackerComponent,
    SurveyTrackerTaskComponent,
    SurveyTrackerDetailComponent,
    SurveyTrackerBulkComponent,

    // PPTX Download Requests
    DownloadRequestListComponent,
    DownloadRequestTaskComponent,
    DownloadRequestAddComponent,
    DownloadRequestAddLegacyComponent,
    DownloadRequestDetailComponent,
    DownloadRequestDetailFileComponent,

    // MS Excel Survey Response Files
    SurveyResponseFileComponent,
    ResponseFileTaskComponent,
    ResponseFileDetailComponent,
    ResponseFileUploadComponent,

    // Event
    EventTypeComponent,
    EventTypeDetailComponent,
    EventLogComponent,

    // Admin
    ArmConfigurationComponent,
      EventTypeTaskComponent,
      EventProcessorComponent,
      EventProcessorTaskComponent,
      EventProcessorDetailComponent,
    VerbatimFileUploadComponent
  ],
  imports: [
    SharedModule,
    MatDatepickerModule,
    MatTreeModule
  ]
})
export class PagesModule {
}
