import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {CompanyWeightSet} from "@data/interefaces/data.interfaces";

@Injectable({
  providedIn: 'root'
})
export class CompanyWeightSetService {

  constructor(private _apiService: ApiService) {
  }

  public getCompanyWeightSet(period: string, marketCode: string, industryCode: string, surveySubject: string): Promise<CompanyWeightSet[]> {
    return this._apiService.makeGetServiceCall<CompanyWeightSet[]>(`/v2/api/product-spec/res-comp-weight-set`,
      `period=${period}&marketCode=${marketCode}&industryCode=${industryCode}&surveySubject=${surveySubject}`);
  }

}
