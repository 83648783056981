import {Component, OnChanges, OnInit} from '@angular/core';
import {DownloadRequestType} from "@data/interefaces/lookup.interfaces";
import {ReportType, RespondentSegmentLabel, WeightedProductLabel} from "@data/interefaces/product.interfaces";
import {DownloadRequestData, ViewDownloadRequest} from "@data/interefaces/download.request.interfaces";
import {SelectionChangeType} from "@data/enums/data.enums";
import {TreeSelectionChanged} from "@shared/ag-checkbox-tree/ag-checkbox-tree.component.ds";
import {AdvantageReportBaseComponent} from "@pages/download-request/download-request-add/components/advantage-report.base.component";
import {AgCheckboxTreeService} from "@shared/ag-checkbox-tree/services/ag-checkbox-tree.service";

@Component({
  selector: 'our-voice-report',
  templateUrl: './our-voice-report.component.html',
  styleUrls: ['./our-voice-report.component.scss'],
  providers: [AgCheckboxTreeService]
})
export class OurVoiceReportComponent extends AdvantageReportBaseComponent implements OnChanges, OnInit {
  productsToRespondentsMap: Map<WeightedProductLabel, RespondentSegmentLabel[]> = new Map();
  selectedProductToSelectedRespondentsMap: Map<WeightedProductLabel, RespondentSegmentLabel[]> = new Map();

  constructor() {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  async onCheckBoxTreeSelectionChanged(event: TreeSelectionChanged) {
    if (event.changeType === SelectionChangeType.ADD) {
      // we only want to focus on the events in this event, and not from the entire list from selectedSponsors
      event.childrenItems.forEach(item => {
        const existingProduct = this.findExistingProductForSelectedProductToSelectedRespondentsMap(event.parentItem);
        if (existingProduct) {
          // exist product is already selected, let's update the map to include the selected respondent
          const currentSelectedRespondents = this.selectedProductToSelectedRespondentsMap.get(existingProduct);
          if (currentSelectedRespondents && !currentSelectedRespondents?.includes(item)) {
            currentSelectedRespondents.push(item);
            this.sortRespondentsList(currentSelectedRespondents);
          }
        } else {
          // no existing product, so add it to the map
          this.selectedProductToSelectedRespondentsMap.set(event.parentItem, [item]);
        }
      });
    } else {
      // Remove from the selectedRespondents list
      event.childrenItems.forEach(item => {
        this.removeRespondentFromSelectedRespondents(event.parentItem, item);
      });
    }
    this.emitIsValidSelectionData();
    this.addOrUpdateDownloadRequest(event.changeType);
  }

  public setDownloadRequestDashboardVersion(downloadRequest: DownloadRequestData) {
    downloadRequest.dashboardPrefix = '';
  }

  protected override resetAllData() {
    this.productsToRespondentsMap.clear();
    this.selectedProductToSelectedRespondentsMap.clear();
    this.isValidSelectionData.emit(false); // emit false on reset
    this.createAgCheckBoxTreeWithSelectedValues(this.productsToRespondentsMap, SelectionChangeType.DELETE);
  }

  protected override async handleSelectedProductsChanged(value: WeightedProductLabel[]) {
    if (value && Array.isArray(value) && value.length > 0) {
      const promises = value.map(async (product) => {
        if (!this.productsToRespondentsMap.has(product)) {
          // product not found in the list, add it
          this.displayProgressBar(true);
          const itemsToAdd: Map<WeightedProductLabel, RespondentSegmentLabel[]> = new Map();
          await this._pptDownloadService.getRespondentSegmentLabelsByProductPeriodId(product.productPeriodId.toString(), product.productLocale, product.isNegativeProductViewId).then(result => {
            itemsToAdd.set(product, result);
            this.productsToRespondentsMap.set(product, result);
            this.createAgCheckBoxTreeWithSelectedValues(itemsToAdd, SelectionChangeType.ADD);
          }).catch(error => {
            this.displayNotificationMessage('error', JSON.stringify(error));
          }).finally(() => this.displayProgressBar(false));
        } else {
          // value returns a list of all items selected, so since we are removing items.. we need to remove everything from selectedProductsToRespondentsMap.key
          // where it's not in that list.
          const productPeriodsToKeep = new Set(value.map(v => v.productPeriodId));
          const itemsToRemove: Map<WeightedProductLabel, RespondentSegmentLabel[]> = new Map();
          Array.from(this.productsToRespondentsMap.entries()).forEach(([key, value]) => {
            if (!productPeriodsToKeep.has(key.productPeriodId)) {
              this.productsToRespondentsMap.delete(key);
              itemsToRemove.set(key, value);
              // remove the respondent as well
              value.forEach(respondent => {
                this.removeRespondentFromSelectedRespondents(key, respondent);
              });
              this.createAgCheckBoxTreeWithSelectedValues(itemsToRemove, SelectionChangeType.DELETE);
              // since we are deleting this item, lets make sure it's not in the grid, if it is, we need to remove it there as well.
              this.addOrUpdateDownloadRequest(SelectionChangeType.DELETE);
            }
          });
        }
      });
      await Promise.all(promises);
      this.selectedProductsProcessed.emit();
    }
    // value is 0, which means we may have unselected all items, so lets make sure there are items still in the selectedProductsAndSponsors.
    if (value?.length === 0 && this.productsToRespondentsMap.size > 0) {
      // clear and update the tree if items are all unselected
      this.resetAllData();
      this.selectedProductsProcessed.emit();
    }
  }

  protected override addOrUpdateDownloadRequest(changeType: SelectionChangeType) {
    const downloadRequests = this.createNewDownloadRequestParams();
    // I have the parameters now I need to map them to the grids dataSource
    this.pendingDownloadRequests.next({items: downloadRequests, changeType: changeType});
  }

  protected override async onRetryEvent(downloadRequestsToRetry: ViewDownloadRequest[]) {
    await this.updateProductAndRespondentsTreeOnRetry(downloadRequestsToRetry);
  }

  private removeRespondentFromSelectedRespondents(product: WeightedProductLabel, respondentToRemove: RespondentSegmentLabel) {
    const existingSelectedProduct = this.findExistingProductForSelectedProductToSelectedRespondentsMap(product);
    if (existingSelectedProduct) {
      let selectedRespondents = this.selectedProductToSelectedRespondentsMap.get(existingSelectedProduct);
      if (selectedRespondents) {
        const index = selectedRespondents?.findIndex(respondent =>
          respondent.respondentSegmentId === respondentToRemove.respondentSegmentId);
        if (index !== -1) {
          selectedRespondents.splice(index, 1);
        }
        if (selectedRespondents.length === 0) {
          // no respondents, remove it
          this.selectedProductToSelectedRespondentsMap.delete(product);
        }
        if (selectedRespondents.length > 0) {
          // only sort if there are items in the list
          this.sortRespondentsList(selectedRespondents);
        }
      }
    }
  }

  private sortRespondentsList(respondents: RespondentSegmentLabel[]) {
    respondents.sort((a, b) => a.respondentSegmentId - b.respondentSegmentId);
  }

  private findExistingProductForSelectedProductToSelectedRespondentsMap(product: WeightedProductLabel) {
    return [...this.selectedProductToSelectedRespondentsMap.keys()].find(key => key.productPeriodId === product.productPeriodId
      && key.isNegativeProductViewId === product.isNegativeProductViewId);
  }

  private createAgCheckBoxTreeWithSelectedValues(items: Map<WeightedProductLabel, RespondentSegmentLabel[]>, changeType: SelectionChangeType) {
    this._checkboxTreeService.updateAgCheckBoxTreeFromMap(items, 'displayValue', 'respondentSegmentLabel', changeType);
  }

  private createNewDownloadRequestParams(): DownloadRequestData[] {
    const downloadRequests: DownloadRequestData[] = [];
    for (const [key, value] of this.selectedProductToSelectedRespondentsMap.entries()) {
      const selectedProduct: WeightedProductLabel = key;
      // Create the default download request for the product.
      const downloadRequest: DownloadRequestData = this._advantageReportService.createDownloadRequestWithDefaultValues(this.currentBatchCode, this.selectedPeriod, this.selectedMarket, this.selectedIndustry,
        this.selectedSurveySubject, this.selectedDownloadType, this.selectedLocale, this.originalBatchCodeFromRetry);
      if (selectedProduct) {
        this._advantageReportService.setDownloadRequestProduct(downloadRequest, selectedProduct);
        this.setDownloadRequestDashboardVersion(downloadRequest);
        this.setDownloadRequestReportTypeValues(downloadRequest, this.selectedDownloadType);
        this._advantageReportService.setDownloadRequestTableauProject(downloadRequest);
        // For the same product, iterate through each respondent, and create a unique download request for that respondent
        for (const respondent of value) {
          const clonedDownloadRequest = {...downloadRequest};
          this._advantageReportService.setDownloadRequestRespondents(clonedDownloadRequest, [respondent]);
          downloadRequests.push(clonedDownloadRequest);
        }
      }
    }
    return downloadRequests;
  }

  private emitIsValidSelectionData() {
    this.isValidSelectionData.emit(this.selectedProductToSelectedRespondentsMap.size > 0);
  }

  private setDownloadRequestReportTypeValues(downloadRequest: DownloadRequestData, selectedDownloadType: DownloadRequestType | undefined) {
    // Do not add to product.standardreporttypes as this table is also used for client facing dashboards and data.  So we will manually pick the automated report
    // from the report types list.
    let matchingReportType: ReportType | undefined;
    if (downloadRequest && selectedDownloadType) {
      const matchingReportTypes = this.reportTypes.filter(reportType => reportType.automatedReportCode.includes('OurVoice'));
      if (matchingReportTypes.length > 1) {
        matchingReportType = matchingReportTypes.find(reportType => reportType.versions.includes(Number(downloadRequest.period)));
      } else {
        matchingReportType = matchingReportTypes[0];
      }
    }
    if (!matchingReportType) {
      throw new Error(`Unable to find a matching report type for OurVoice`);
    }

    downloadRequest.automatedReportId = matchingReportType.automatedReportId.toString();
    downloadRequest.automatedReportName = matchingReportType.automatedReportName;
    downloadRequest.automatedReportCode = matchingReportType.automatedReportCode;
  }

  private async updateProductAndRespondentsTreeOnRetry(downloadRequestsToRetry: ViewDownloadRequest[]) {
    let itemsToSelectMap: TreeSelectionChanged[] = [];
    for (let downloadRequest of downloadRequestsToRetry) {
      const requestParams = JSON.parse(downloadRequest.downloadParameters ?? '');
      const productPeriodId = Number(requestParams.productperiodid);
      const weightedProductLabel = requestParams.product;
      for (const [product, respondent] of this.productsToRespondentsMap) {
        if (product.productPeriodId === productPeriodId && product.displayValue === weightedProductLabel) {
          let treeSelectionChangedEvent = itemsToSelectMap.find(item => {
            return item.parentItem.productPeriodId === productPeriodId && item.parentItem.displayValue === weightedProductLabel
          });
          // nothing found, add a new one
          if (!treeSelectionChangedEvent) {
            treeSelectionChangedEvent = {
              parentItem: undefined,
              changeType: SelectionChangeType.ADD,
              childrenItems: []
            }
            itemsToSelectMap.push(treeSelectionChangedEvent);
          }
          treeSelectionChangedEvent.parentItem = product;
          const respondentsArray = Array.from(requestParams.respondentsegments);
          respondentsArray.forEach(id => {
            const matchingRespondent = respondent.find(r => r.respondentSegmentId === Number(id));
            if (matchingRespondent) {
              treeSelectionChangedEvent?.childrenItems.push(matchingRespondent);
            } else {
              throw new Error(`Respondent with ID: ${id} not found in the list of respondents`);
            }
          });
        }
      }
    }

    for (const item of itemsToSelectMap) {
      this._checkboxTreeService.setSelectedItems(item.parentItem, item.childrenItems, 'displayValue', 'respondentSegmentId', false);
      // Manually trigger the selection change, so we can await this functionality.  This allow us to ensure that the respondents list does not
      // get updated before this is process is done.  Without this, the UI will be rendering as we try to pre-select the respondents.
      await this.onCheckBoxTreeSelectionChanged(item);
    }
  }
}
