<div [showSkeleton]="loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <mat-card *ngIf="!loading" class="task-component-detail backdrop" id="task-component-detail">
    <mat-card-header class="mg-b-m">
      <div class="header-title">
        <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
        <mat-card-subtitle>Unique Link Invitees</mat-card-subtitle>
      </div>
      <div *ngIf="isEditMode && inviteeUrl && inviteeUrl.value" class="url-container flex-row mg-t-s">
        <label class="url-label mg-r-s">Invitee URL</label>
        <div>
          <a [href]="inviteeUrl.value" class="url-link" target="_blank">{{ inviteeUrl.value }}</a>
        </div>
        <i [cdkCopyToClipboard]="inviteeUrl.value" class="fa-sharp fa-link-simple url-link-icon mg-l-s"></i>
      </div>
      <div *ngIf="isEditMode" class="flex-row mg-t-s mg-r-m invalid-toggle">
        <ag-slide-toggle (toggleUpdate)="util.setToggleValue($event.checked, this.getFormControl('invalid'))"
                         [colorType]="'error'"
                         [isChecked]="util.isToggleChecked(this.getFormControl('invalid'))"
                         [isDisabled]="!getSurveyCodeEditRule()"
                         class="slide-toggle"></ag-slide-toggle>
        <span class="mg-l-s">Invalid?</span>
      </div>
    </mat-card-header>
    <mat-card-content class="form-container">
      <form (ngSubmit)="this.submitForm(this.saveAndClose, 'Unique Link Invitees Saved.')" [formGroup]="formGroup">
        <div *ngIf="this.isEditMode" class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('period')}}" title="Period"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('marketCode') + ' ' + getFormControlValue('marketName')}}" title="Market"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('industryCode') + ' ' + getFormControlValue('industryName')}}" title="Industry"></ag-datablock>
          </div>
          <div class="view-only-block">
            <label>Survey Subject</label>
            <mat-chip-listbox aria-label="Status" class="mat-mdc-chip-set-stacked" disabled="true">
              <div class="flex-row">
                <mat-chip-option
                  [selected]="isSurveySubjectSelected(SurveySubject.SUPPLIER)"
                  color="accent"
                  id="ss_s">{{ SurveySubject.SUPPLIER }}
                </mat-chip-option>
                <mat-chip-option
                  [selected]="isSurveySubjectSelected(SurveySubject.RETAILER)"
                  color="accent"
                  id="ss_r">{{ SurveySubject.RETAILER }}
                </mat-chip-option>
              </div>
            </mat-chip-listbox>
          </div>
        </div>
        <div [ngClass]="{'mg-b-m': isEditMode}" class="flex-row view-only-container">
          <mat-form-field *ngIf="!isEditMode">
            <mat-label>Survey Code</mat-label>
            <mat-select formControlName="surveyCode">
              <mat-option (click)="onSurveyCodeSelected()"
                          *ngFor="let surveyCode of surveyCodeList"
                          [value]="surveyCode.surveyCode"
              >{{ surveyCode.surveyCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="isEditMode" class="view-only-block">
            <ag-datablock data="{{formGroup.get('surveyCode')?.value}}" title="Survey Code"></ag-datablock>
          </div>
          <div *ngIf="isEditMode" class="view-only-block">
            <ag-datablock data="{{formGroup.get('remoteRespId')?.value}}" title="Remote Resp Id"></ag-datablock>
          </div>
          <div *ngIf="isEditMode" class="view-only-block">
            <ag-datablock data="{{formGroup.get('personCode')?.value}}" title="Person Code"></ag-datablock>
          </div>
          <div *ngIf="isEditMode" class="view-only-block">
            <ag-datablock data="{{formGroup.get('personCode')?.value}}" title="Response Code"></ag-datablock>
          </div>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>Invitation Company</mat-label>
            <mat-select formControlName="invitationCompanyCode">
              <mat-option *ngFor="let invitationCompanyCode of invitationCompanyCodeList"
                          [value]="invitationCompanyCode.respondentCompanyRollupCode"
              >
                {{ invitationCompanyCode.respondentCompanyRollupCode + " " + invitationCompanyCode.respondentCompanyRollupLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Invitation Divisions</mat-label>
            <mat-select [multiple]="true" formControlName="invitationDivisionCodes">
              <mat-option *ngFor="let invitationDivisionCodes of invitationDivisionCodesList"
                          [value]="invitationDivisionCodes.respondentCompanyRollupCode">
                {{ invitationDivisionCodes.respondentCompanyRollupCode + ' ' + invitationDivisionCodes.respondentCompanyDivisionCode + ' ' + invitationDivisionCodes.respondentCompanyDivisionLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="isEditMode" class="status-container mg-b-m">
          <label>Status</label>
          <mat-chip-listbox aria-label="Status" class="mat-mdc-chip-set-stacked" disabled="true">
            <div class="flex-row">
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.NOT_STARTED)"
                color="accent"
                id="rs_ns">{{ ResponseStatus.NOT_STARTED }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.IN_PROGRESS)"
                color="accent"
                id="rs_ip">{{ ResponseStatus.IN_PROGRESS }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.ERROR)"
                color="accent"
                id="rs_e">{{ ResponseStatus.ERROR }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.COMPLETE)"
                color="accent"
                id="rs_c">{{ ResponseStatus.COMPLETE }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.SCREENED)"
                color="accent"
                id="rs_s">{{ ResponseStatus.SCREENED }}
              </mat-chip-option>
            </div>
          </mat-chip-listbox>
        </div>
        <div class="flex-row toggle-block">
          <div class="mg-b-m flex-row">
            <ag-slide-toggle (toggleUpdate)="util.setToggleValue($event.checked, this.getFormControl('preFillContactInformation'))"
                             [isChecked]="util.isToggleChecked(this.getFormControl('preFillContactInformation'))"
                             [isDisabled]="!getSurveyCodeEditRule()"
            ></ag-slide-toggle>
            <span class="mg-l-s">Pre-fill Contact Information?</span>
          </div>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input formControlName="firstName" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input formControlName="lastName" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Telephone</mat-label>
            <input formControlName="telephone" matInput>
          </mat-form-field>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>Cell Phone</mat-label>
            <input formControlName="cellPhone" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>City</mat-label>
            <input formControlName="city" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Job Title</mat-label>
            <input formControlName="jobTitle" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Years In Role</mat-label>
            <mat-select formControlName="yearsInRole">
              <mat-option *ngFor="let yearsInRole of yearsInRoleList"
                          [value]="yearsInRole.yearsInRole">
                {{ yearsInRole.yearsInRole }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex-row toggle-block">
          <div class="mg-b-m flex-row">
            <ag-slide-toggle (toggleUpdate)="onPrefillSurveySelectionsChanged($event, 'preFillSurveySelections')"
                             [isChecked]="util.isToggleChecked(this.getFormControl('preFillSurveySelections'))"
                             [isDisabled]="!getSurveyCodeEditRule()"
            ></ag-slide-toggle>
            <span class="mg-l-s">Pre-fill Survey Selections?</span>
          </div>
          <div class="mg-b-m flex-row">
            <ag-slide-toggle (toggleUpdate)="util.setToggleValue($event.checked, this.getFormControl('lockSurveySelections'))"
                             [isChecked]="util.isToggleChecked(this.getFormControl('lockSurveySelections'))"
                             [isDisabled]="!getPrefillEditRule() || !getSurveyCodeEditRule()"
            ></ag-slide-toggle>
            <span class="mg-l-s">Lock Survey Selections?</span>
          </div>
          <div class="mg-b-m flex-row">
            <ag-slide-toggle (toggleUpdate)="util.setToggleValue($event.checked, this.getFormControl('showDivisionsCollapsed'))"
                             [isChecked]="util.isToggleChecked(this.getFormControl('showDivisionsCollapsed'))"
                             [isDisabled]="!getSurveyCodeEditRule()"
            ></ag-slide-toggle>
            <span class="mg-l-s">Show Divisions Collapsed?</span>
          </div>
          <div class="mg-b-m flex-row">
            <ag-slide-toggle (toggleUpdate)="util.setToggleValue($event.checked, this.getFormControl('startInReviewMode'))"
                             [isChecked]="util.isToggleChecked(this.getFormControl('startInReviewMode'))"
                             [isDisabled]="!getSurveyCodeEditRule()"
            ></ag-slide-toggle>
            <span class="mg-l-s">Start in Review Mode?</span>
          </div>
        </div>
        <div class="flex-row custom-container">
          <div class="category-container">
            <mat-form-field class="full-width">
              <mat-label>Category Responsibility</mat-label>
              <textarea formControlName="categoryResponsibility" matInput></textarea>
            </mat-form-field>

            <div class="left-bottom-block flex-row">
              <div class="seniority-container">
                <mat-form-field>
                  <mat-label>Seniority</mat-label>
                  <mat-select formControlName="seniorityCode">
                    <mat-option *ngFor="let seniorities of senioritiesList"
                                [value]="seniorities.seniorityCode">
                      {{ seniorities.seniorityCode + ' ' + seniorities.seniorityLabel }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="mg-b-m flex-row w-50">
                <ag-slide-toggle (toggleUpdate)="onAllBuCategoriesChanged($event, 'allBuCategories')"
                                 [isChecked]="util.isToggleChecked(this.getFormControl('allBuCategories'))"
                                 [isDisabled]="!getSurveyCodeEditRule() || getBuCategoriesEditRule()"
                ></ag-slide-toggle>
                <span class="mg-l-s">All BU-Categories?</span>
              </div>
            </div>
          </div>
          <div class="custom-right-container">
            <mat-form-field>
              <mat-label>Nomination Notes</mat-label>
              <textarea formControlName="nominationNotes" matInput></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>Local Regions</mat-label>
            <mat-select [multiple]="true" formControlName="localRegionCodes">
              <mat-option *ngFor="let localRegions of localRegionsList"
                          [value]="localRegions.localRegionCode">
                {{ localRegions.localRegionCode + ' ' + localRegions.localRegionLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Survey Language</mat-label>
            <mat-select formControlName="surveyLanguage">
              <mat-option *ngFor="let surveyLanguage of surveyLanguageList"
                          [value]="surveyLanguage.language">
                {{ surveyLanguage.language }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Nominated By</mat-label>
            <mat-select [multiple]="true" formControlName="nominatedByCodes">
              <mat-option *ngFor="let buCategories of buCategoriesList"
                          [value]="buCategories.buCategoryCode">
                {{ buCategories.buCategoryCode + ' ' + buCategories.buCategoryLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Roles</mat-label>
            <mat-select [multiple]="true" formControlName="roleCodes">
              <mat-option *ngFor="let roles of rolesList"
                          [value]="roles.roleCode">
                {{ roles.roleCode + ' ' + roles.roleLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>BU-Categories</mat-label>
            <mat-select [multiple]="true" formControlName="buCategoryCodes">
              <mat-option *ngFor="let buCategories of buCategoriesList"
                          [value]="buCategories.buCategoryCode">
                {{ buCategories.buCategoryCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Channels</mat-label>
            <mat-select [multiple]="true" formControlName="channelCodes">
              <mat-option *ngFor="let channels of channelsList"
                          [value]="channels.channelCode">
                {{ channels.channelCode + ' ' + channels.channelLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>BR/PL</mat-label>
            <mat-select formControlName="brPlCode">
              <mat-option *ngFor="let industryFlags of industryFlagsList"
                          [value]="industryFlags.brPlCode">
                {{ industryFlags.brPlCode + ' ' + industryFlags.brPlLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Subject Rollups</mat-label>
            <mat-select [multiple]="true" formControlName="subjectRollupCodes">
              <mat-option *ngFor="let subjectRollups of subjectRollupsList"
                          [value]="subjectRollups.subjectCompanyRollupCode">
                {{ subjectRollups.subjectCompanyRollupCode + ' ' + subjectRollups.subjectCompanyRollupLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>Subject Singles</mat-label>
            <mat-select [multiple]="true" formControlName="subjectSingleCodes">
              <mat-option *ngFor="let subjectSingles of subjectSinglesList"
                          [value]="subjectSingles.subjectCompanySingleCode">
                {{ subjectSingles.subjectCompanySingleCode + ' ' + subjectSingles.subjectCompanySingleLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Max Total Companies to Rate</mat-label>
            <input formControlName="maxTotalCompaniesToRate" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Max P1 Companies to Rate</mat-label>
            <input formControlName="maxP1CompaniesToRate" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Max P2 Companies to Rate</mat-label>
            <input formControlName="maxP2CompaniesToRate" matInput>
          </mat-form-field>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>Max P3 Companies to Rate</mat-label>
            <input formControlName="maxP3CompaniesToRate" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Mailout Template</mat-label>
            <mat-select formControlName="mailoutTemplateCode">
              <mat-option *ngFor="let mailoutTemplateCode of mailoutTemplateCodeList"
                          [value]="mailoutTemplateCode.mailoutTemplateCode">
                {{ mailoutTemplateCode.mailoutTemplateCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="getFormControl('mailoutDatetime')">
            <ag-datetimepicker [control]="getFormControl('mailoutDatetime')" [label]="'Mailout Datetime'" class="mat-mdc-form-field"></ag-datetimepicker>
          </ng-container>
          <mat-form-field>
            <mat-label>Mailout Field Set</mat-label>
            <mat-select formControlName="mailoutFieldSetCode">
              <mat-option *ngFor="let mailoutFieldsetCode of mailoutFieldsetCodeList"
                          [value]="mailoutFieldsetCode.mailoutFieldsetCode">
                {{ mailoutFieldsetCode.mailoutFieldsetCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="isEditMode" class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('remoteTaskId')?.value}}" title="Remote Task IDs"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('mailoutHistory')?.value}}" title="Mailout History"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('firstMailingDate')?.value}}" title="First Mailing Date"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('lastMailingDate')?.value}}" title="Last Mailing Date"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('timesMailed')?.value}}" title="Times Mailed"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('surveyId')?.value}}" title="Survey Id"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('surveyInviteeId')?.value}}" title="Invitation Id"></ag-datablock>
          </div>
        </div>
        <div class="flex-row flex-g-s action-buttons">
          <button (click)="onSaveAndCloseClick()" [disabled]="!this.hasAnyEdits()" color="primary" mat-raised-button type="submit">
            <mat-icon class="material-symbols-outlined">save</mat-icon>
            Save and Close
          </button>
          <button (click)="resetForm()" *ngIf="isEditMode" [disabled]="!this.hasAnyEdits()" mat-button type="button">
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
            Reset
          </button>
          <button (click)="resetForm(); close()" *ngIf="hasAnyEdits()" [disabled]="!this.hasAnyEdits()" mat-button type="button">
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
            Cancel
          </button>
          <button (click)="resetForm(); close()" *ngIf="!hasAnyEdits()" [disabled]=" this.hasAnyEdits()" class="primary-font-color" mat-button type="button">
            <i class="fa-sharp fa-light fa-xmark-large"></i> Close
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
