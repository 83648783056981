<div class="ag-checkbox-list-component">
  <div class="flex-row">
    <div class="header-title">
      <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
    </div>
    <div class="mg-l-l search-box">
      <form>
        <mat-form-field class="full-width"> <!-- when using flex-column these need to be applied to fix the spacing issues -- appearance="fill" subscriptSizing="dynamic"-->
          <mat-label>{{ this.searchBarLabel }}</mat-label>
          <input
            [formControl]="this.searchFormControl"
            [matAutocomplete]="auto" matInput/>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="autoCompleteDisplayValue" class="ag-checkbox-list-autocomplete">
            <mat-option *ngFor="let checkboxItem of this.filteredCheckboxItems$ | async" [value]="checkboxItem">
                  <span (click)="this.onAutoCompleteMatOption($event, checkboxItem)" class="mat-option-span">
                    <div class="checkbox-wrapper">
                    <mat-checkbox (change)="this.onAutoCompleteSelectionChanged(checkboxItem)"
                                  (click)="this.onAutoCompleteCheckboxClicked($event)"
                                  [checked]="this.isSelected(checkboxItem)"
                                  class="checklist-leaf-node"
                                  color="primary">
                      {{ checkboxItem.label }}
                    </mat-checkbox>
                    </div>
                  </span>
            </mat-option>
          </mat-autocomplete>
          <mat-icon (click)="this.clearValue($event)" *ngIf="hasValue()" matSuffix><i class="fa-sharp fa-regular fa-xmark"></i></mat-icon>
          <mat-icon matSuffix><i class="fa-sharp fa-thin fa-magnifying-glass"></i></mat-icon>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div #checkboxContainer class="checkbox-container">
    <mat-checkbox (change)="this.toggleSelectAll()" *ngIf="this.hasItems()" [checked]="this.allSelected()" [indeterminate]="this.partiallySelected()"
                  class="checkbox-item"
                  color="primary">
      Select All
    </mat-checkbox>
    <ul *ngFor="let item of checkboxItems" class="list-container">
      <li>
        <mat-checkbox (change)="this.selectedItemToggle(item)" [checked]="this.checkboxSelection.isSelected(item)" [id]="item.id" class="checkbox-item" color="primary">
          {{ item.label }}
        </mat-checkbox>
      </li>
    </ul>
  </div>
</div>
