<div id="transfer-run-detail" class="full-height-container">
  <div>
    <ag-context-link [auxLinks]="auxLinks"></ag-context-link>
  </div>
  <div class="mg-b-s">
    <ag-expansion-panel [isOpen]="false" [supportReset]="false" headerIcon="fa-square-info" title="Transfer Details">
      <div class="flex-row flex-g-l mg-b-s full-width" expansion-content>
        <ag-datablock data="{{transferRun?.transferId}}" title="Run Code"></ag-datablock>
        <ag-datablock data="{{transferRun?.transferState}}" title="Status"></ag-datablock>
        <ag-datablock data="{{transferRun?.startDatetime | date : 'short'}}" title="Start Time"></ag-datablock>
        <ag-datablock data="{{transferRun?.endDatetime | date : 'short'}}" title="End Time"></ag-datablock>
        <ag-datablock data="{{transferRun?.transferMode}}" title="Run Mode"></ag-datablock>
        <ag-datablock data="{{transferRun?.transferTypeCode}}" title="Transfer Code"></ag-datablock>
        <ag-datablock data="{{transferRun?.transferTypeName}}" title="Transfer Name"></ag-datablock>
      </div>
    </ag-expansion-panel>
  </div>
  <div class="flex-row flex-g-s mg-b-s">
    <ag-status-chip status="{{transferRun?.transferState}}"></ag-status-chip>
    <button (click)="onStopTransferClick(transferRun)" [disabled]="transferRun?.transferState !== 'in progress'" mat-button>
      <i Class='fa-sharp fa-solid fa-stop fa-lg accent-color mg-r-s' [class.disable_icon]="transferRun?.transferState !== transferStatus.IN_PROGRESS"></i>
      Stop Transfer
    </button>
    <button (click)="onDisplayRunConfigurationClick(transferRun?.transferTypeCode, transferRun?.runConfiguration)"
            [class.disable_icon]="transferRun?.runConfiguration == undefined" mat-button>
      <i Class='fa-sharp fa-solid fa-list fa-lg accent-color mg-r-s'></i>
      View Run Configuration
    </button>
  </div>
  <div class="full-height-container">
    <mat-tab-group (selectedIndexChange)="displayRequestedTab($event)" [selectedIndex]="selectedTabId" class="full-height-container">
      <mat-tab [disabled]="!this.isA3Transfer(transferRun?.transferTypeCode)">
        <ng-template mat-tab-label>
          <i Class='fa-sharp fa-light fa-diagram-subtask fa-lg mg-r-s'></i>Tasks
        </ng-template>
        <ng-template *ngTemplateOutlet="run_detail_tasks" matTabContent></ng-template>
      </mat-tab>
      <mat-tab >
        <ng-template mat-tab-label>
          <i Class='fa-sharp fa-light fa-memo-circle-info fa-lg mg-r-s'></i>Messages
        </ng-template>
        <ng-template *ngTemplateOutlet="run_detail_messages" matTabContent></ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>

  <ag-side-panel #runParametersSidePanel size="medium" subTitle="Custom run parameters passed in." title="Run Parameters">
    <div [showSkeleton]="showSkeleton" agSkeleton skeletonType="grid-2x3">
      <ag-dynamic-panel [fieldPerRow]="3" [isReadOnly]="true" [panelConfig]="runParameters"></ag-dynamic-panel>
    </div>
  </ag-side-panel>

  <ng-template #run_detail_messages>
    <div class="hide-overflow-x full-height-container">
      <app-transfer-run-detail-messages (refreshViewRequired)="this.refreshView()" [transferId]="this.transferId"
                                        [transferRun]="this.transferRun"></app-transfer-run-detail-messages>
    </div>
  </ng-template>

  <ng-template #run_detail_tasks>
    <div class="hide-overflow-x full-height-container">
      <app-transfer-run-detail-tasks (refreshViewRequired)="this.refreshView()"
                                     [transferId]="this.transferId"
                                     [transferRun]="this.transferRun"></app-transfer-run-detail-tasks>
    </div>
  </ng-template>
</div>

