import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType, LinkType, YesNoValue} from "@data/enums/data.enums";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {QuilliupForm} from "@data/enums/quilliup.enum";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {Util} from "@data/util/util";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";
import {ResetFilterComponent} from "@pages/segments/grid/filters/reset-filter/reset-filter.component";
import {SelectHeaderComponent} from "@pages/segments/grid/columns/select-header/select-header.component";

export let pageContext: {
  [key: string]: boolean | string | undefined
  p_f: string | undefined;
  mc_f: string | undefined;
  mn_f: string | undefined;
  ic_f: string | undefined;
  in_f: string | undefined;
  ss_f: string | undefined;
  sc_f: string | undefined;
  olc_f: string | undefined;
  rc_f: string | undefined;
  pc_f: string | undefined;
  ftpc_f: string | undefined;
  rs_f: string | undefined;
  i_f: string | undefined;
  icc_f: string | undefined;
  icl_f: string | undefined;
  idc_f: string | undefined;
  idl_f: string | undefined;
  e_f: string | undefined;
  fn_f: string | undefined;
  ln_f: string | undefined;
  t_f: string | undefined;
  cp_f: string | undefined;
  c_f: string | undefined;
  jt_f: string | undefined;
  sl_f: string | undefined;
  iu_f: string | undefined;
  nbc_f: string | undefined;
  nbl_f: string | undefined;
  nn_f: string | undefined;
  mtctr_f: string | undefined;
  mp1ctr_f: string | undefined;
  mp2ctr_f: string | undefined;
  mp3ctr_f: string | undefined;
  mtc_f: string | undefined;
  mfc_f: string | undefined;
  mdt_f: string | undefined;
  rti_f: string | undefined;
  mh_f: string | undefined;
  fmd_f: string | undefined;
  lmd_f: string | undefined;
  lms_f: string | undefined;
  tm_f: string | undefined;
  rri_f: string | undefined;
  si_f: string | undefined;
} = {
  p_f: undefined,
  mc_f: undefined,
  mn_f: undefined,
  ic_f: undefined,
  in_f: undefined,
  ss_f: undefined,
  sc_f: undefined,
  olc_f: undefined,
  rc_f: undefined,
  pc_f: undefined,
  ftpc_f: undefined,
  rs_f: undefined,
  i_f: undefined,
  icc_f: undefined,
  icl_f: undefined,
  idc_f: undefined,
  idl_f: undefined,
  e_f: undefined,
  fn_f: undefined,
  ln_f: undefined,
  t_f: undefined,
  cp_f: undefined,
  c_f: undefined,
  jt_f: undefined,
  sl_f: undefined,
  iu_f: undefined,
  nbc_f: undefined,
  nbl_f: undefined,
  nn_f: undefined,
  mtctr_f: undefined,
  mp1ctr_f: undefined,
  mp2ctr_f: undefined,
  mp3ctr_f: undefined,
  mtc_f: undefined,
  mfc_f: undefined,
  mdt_f: undefined,
  rti_f: undefined,
  mh_f: undefined,
  fmd_f: undefined,
  lmd_f: undefined,
  lms_f: undefined,
  tm_f: undefined,
  rri_f: undefined,
  si_f: undefined,
};

export let periods: string[] = [];
export let marketCodes: string[] = [];
export let surveyCodes: string[] = [];

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    maxWidth: 75,
    field: 'select',
    headerName: '',
    hide: false,
    filter: false,
    headerComponent: SelectHeaderComponent,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    pinned: "left",
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRenderer: SelectColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        showFlag: params.data.invalid == YesNoValue.YES_VALUE,
        flagIcon: "fa-exclamation",
        flagColor: "red",
        toolTip: 'Invalid'
      }
    }
  },
  {
    headerName: 'Data Filter', valueFormatter: '', children: [
      {
        field: 'period',
        headerName: 'Period',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'p_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: periods,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            closeOnApply: true,
            suppressSorting: true,
            buttons: ['apply', 'reset']
          }
      },
      {
        field: 'marketCode',
        headerName: 'Market Code',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'mc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: marketCodes,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            closeOnApply: true,
            buttons: ['apply', 'reset']
          },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketName
          };
        }
      },
      {
        field: 'surveyCode', headerName: 'Survey Code', pinned: "left", hide: false, dbFilter: true, filterId: 'sc_f', filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: surveyCodes,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            buttons: ['apply', 'reset'],
            closeOnApply: true
          }
      }
    ]
  },
  {
    headerName: "Invitation", valueFormatter: '', children: [
      {
        field: 'marketName',
        headerName: 'Market Name',
        hide: false,
        filter: ColumnFilterType.TEXT,
        filterId: 'mn_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketCode
          };
        }
      },
      {
        field: 'industryCode',
        headerName: 'Industry Code',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'ic_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryName
          };
        }
      },
      {
        field: 'industryName',
        headerName: 'Industry Name',
        hide: true,
        filter: ColumnFilterType.TEXT,
        filterId: 'in_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryCode
          };
        }
      },
      {
        field: 'surveySubject',
        headerName: 'Survey Subject',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'ss_f',
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent
      },
      {field: 'remoteRespId', headerName: 'Remote Resp ID', hide: false, filterId: 'rr_f'},
      {
        field: 'responseCode', headerName: 'Response Code', hide: false, filterId: 'rc_f',
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.email,
            value: params.data.responseCode,
            filterId: 'rc_f',
            links:
              [
                {
                  routerLink: '/forms/survey-respondents/survey-tracker',
                  text: 'Survey Tracker',
                  target: '_blank',
                  linkType: LinkType.INTERNAL
                },
              ]
          };
        }
      },
      {
        field: 'openLinkCode',
        headerName: 'Open Link Code',
        hide: false,
        filterId: 'olc_f',
        sort: 'asc',
        initialSortIndex: 1
      },
      {field: 'personCode', headerName: 'Person Code', hide: false, filterId: 'pc_f'},
      {field: 'forwardedToPersonCodes', headerName: 'Forwarded to Person Codes', hide: false, filterId: 'ftpc_f'},
      {
        field: 'responseStatus',
        headerName: 'Response Status',
        hide: false,
        filterId: 'rs_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
        cellRenderer: StatusColumnComponent
      },
      {field: 'inviteeUrl', headerName: 'Invitee Url', hide: false},
      {
        field: 'invalid', headerName: 'Invalid?', hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, cellRenderer: CircleCheckColumnComponent,
          cellRendererParams: function (params: any) {
            return {
              invertIcons: true
            }
          }},
        filterId: 'i_f',
        cellRenderer: CircleCheckColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            invertIcons: true
          }
        }
      },
      {
        field: 'invitationCompanyCode', headerName: 'Invitation Company Code', hide: false, filterId: 'icc_f',
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.invitationCompanyLabel,
            value: params.data.invitationCompanyCode,
            filterId: 'icc_f',
            links:
              [
                {
                  routerLink: '/forms/survey-respondents/survey-tracker',
                  text: 'Survey Tracker',
                  target: '_blank',
                  linkType: LinkType.INTERNAL
                },
              ]
          };
        }
      },
      {field: 'invitationDivisionCodes', headerName: 'Invitation Division Codes', hide: false, filterId: 'idc_f'},
    ]
  },
  {
    headerName: 'Demographics', valueFormatter: '', children: [
      {field: 'firstName', headerName: 'First Name', hide: false, filterId: 'fn_f'},
      {field: 'lastName', headerName: 'Last Name', hide: false, filterId: 'ln_f'},
      {field: 'email', headerName: 'Email', hide: false, filterId: 'e_f'},
      {field: 'telephone', headerName: 'Telephone', hide: false, filterId: 'tp_f'},
      {field: 'cellPhone', headerName: 'Cell Phone', hide: false, filterId: 'cp_f'},
      {field: 'city', headerName: 'City', hide: false, filterId: 'c_f'},
      {field: 'jobTitle', headerName: 'Job Title', hide: false, filterId: 'jt_f'},
      {field: 'surveyLanguage', headerName: 'Survey Language', hide: false, filter: ColumnFilterType.DROPDOWN, filterId: 'sl_f'}
    ]
  },
  {
    headerName: 'Nominations', children: [
      {
        field: 'nominatedByCodes', headerName: 'Nominated By Codes', hide: true, filterId: 'nc_f',
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.nominatedByLabels
          };
        }
      },
      {field: 'nominationNotes', headerName: 'Nomination Notes', hide: true, filterId: 'nn_f'}
    ]
  },
  {
    headerName: 'Mailouts', children: [
      {
        field: 'mailoutTemplateCode', headerName: 'Mailout Template Code', hide: false, filterId: 'mtc_f',
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {text: 'View Code in Quilliup', target: '_blank', linkType: LinkType.EXTERNAL, quilliupForm: QuilliupForm.MAILOUT_TEMPLATES},
                {text: "View Label in Quilliup", target: '_blank', linkType: LinkType.EXTERNAL, quilliupForm: QuilliupForm.MAILOUT_TEMPLATE_LABELS}
              ]
          };
        }
      },
      {
        field: 'mailoutFieldSetCode', headerName: 'Mailout Field Set Code', hide: false, filterId: 'mfsc_f',
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {text: 'View Code in Quilliup', target: '_blank', linkType: LinkType.EXTERNAL, quilliupForm: QuilliupForm.MAILOUT_TEMPLATES},
                {text: "View Label in Quilliup", target: '_blank', linkType: LinkType.EXTERNAL, quilliupForm: QuilliupForm.MAILOUT_TEMPLATE_LABELS}
              ]
          };
        }
      },
      {
        field: 'mailoutDatetime',
        headerName: 'Mailout Datetime',
        hide: false,
        filterId: 'md_r',
        filter: ColumnFilterType.DATE,
        filterParams: {defaultOption: 'inRange', inRangeInclusive: true, maxNumConditions: 1, comparator: Util.dateComparator},
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {field: 'remoteTaskId', headerName: 'Remote Task ID', hide: false, filterId: 'rti_f'},
      {field: 'mailoutHistory', headerName: 'Mailout History', hide: false, filterId: 'moh_f'},
      {
        field: 'firstMailingDate',
        headerName: 'First Mailing Date',
        hide: false,
        filterId: 'fmd_r',
        filter: ColumnFilterType.DATE,
        filterParams: {defaultOption: 'inRange', inRangeInclusive: true, maxNumConditions: 1, comparator: Util.dateComparator},
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'lastMailingDate',
        headerName: 'Last Mailing Date',
        hide: false,
        filterId: 'lmd_r',
        filter: ColumnFilterType.DATE,
        filterParams: {defaultOption: 'inRange', inRangeInclusive: true, maxNumConditions: 1, comparator: Util.dateComparator},
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {field: 'lastMailingStatus', headerName: 'Last Mailing Status', hide: false, filterId: 'lms_f'},
      {
        field: 'timesMailed', headerName: 'Times Mailed', hide: false, filterId: 'tm_r',
        filter: ColumnFilterType.NUMBER,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          buttons: ['reset']
        }
      }
    ]
  },
  {
    headerName: 'Labels', children: [
      {
        field: 'invitationCompanyLabel', headerName: 'Invitation Company Label', hide: true, filterId: 'icl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          }
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.invitationCompanyCode,
            value: params.data.invitationCompanyLabel,
            links:
              [
                {
                  routerLink: '/forms/survey-respondents/survey-tracker',
                  text: 'Survey Tracker',
                  target: '_blank',
                  linkType: LinkType.INTERNAL
                },
              ]
          };
        }
      },
      {field: 'invitationDivisionLabels', headerName: 'Invitation Division Labels', hide: true, filterId: 'idl_f'},
      {
        field: 'nominatedByLabels', headerName: 'Nominated By Labels', hide: true, filterId: 'nl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          }
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.nominatedByCodes
          };
        }
      }
    ]
  },
  {
    headerName: 'ID\'s', valueFormatter: '', children: [
      {field: 'surveyId', headerName: 'Survey ID', cellDataType: 'number', hide: true, filter: ColumnFilterType.NUMBER, filterId: 'si_f'},
      {
        field: 'surveyInviteeId',
        headerName: 'Invitation ID',
        cellDataType: 'number',
        hide: true,
        filter: ColumnFilterType.NUMBER,
        filterId: 'sii_f',
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      }
    ]
  },
  {
    field: "actions",
    headerName: "Actions",
    pinned: "right",
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: ResetFilterComponent,
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    cellRenderer: ActionColumnComponent,
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRendererParams: function (params: any) {
      return {
        actions: [
          {
            toolTip: "Edit",
            icon: "fa-pen-to-square",
            name: "Edit",
            routerLink: ['/forms', 'survey-respondents', 'open-link-invitees', 'task', 'edit'],
            queryParams: {
              sc_f: params.data.surveyCode,
              olc_f: params.data.openLinkCode
            }
          }
        ]
      };
    }
  }
]
