/// <reference types="@angular/localize" />

import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from '@environments/environment';
import {enableProdMode} from '@angular/core';
import {LicenseManager} from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(environment.agGridLicense);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
