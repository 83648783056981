import {AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {Util} from "@data/util/util";
import {OperationStatus, Task} from "@data/interefaces/data.interfaces";
import {ActivatedRoute} from "@angular/router";
import {ExtendedTask, ExtendedTransferRun} from "@data/interefaces/extended.data.interfaces";
import {TransferRunService} from "@data/transfer-run/transfer.run.service";
import {TaskStatus, TransferStatus} from "@data/enums/data.enums";
import {AgConfirmationDialogComponent} from "@shared/ag-confirmation-dialog/ag-confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {TableBaseComponent} from "@pages/table.base.component";
import {FilterChangedEvent} from "ag-grid-community";
import {pageContext, stopClickAction, tableColumns, taskIdClickAction} from "@pages/transfer-run-detail/transfer-run-detail-tasks/transfer-run-detail-tasks.component.ds";
import {AgSidePanelComponent} from "@shared/ag-side-panel/ag-side-panel.component";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'app-transfer-run-detail-tasks',
  templateUrl: './transfer-run-detail-tasks.component.html',
  styleUrls: ['./transfer-run-detail-tasks.component.scss'],
  providers: [AgGridService]
})
export class TransferRunDetailTasksComponent extends TableBaseComponent implements AfterViewInit {

  @Input() transferId: string | undefined;
  @Input() transferRun: ExtendedTransferRun | undefined;
  @Output() refreshViewRequired: EventEmitter<boolean> = new EventEmitter<boolean>();
  isFilterActive: boolean = false;
  refreshComplete: boolean = true;
  selectedTask: ExtendedTask | undefined = undefined;
  @ViewChild("hideShowColumnsPanel") hideShowColumnsPanel: AgSidePanelComponent | null = null;
  @ViewChild("taskDetailsPanel") taskDetailsPanel: AgSidePanelComponent | null = null;
  @ViewChild('footerActionButtonPanel') footerActionButtonPanel: TemplateRef<any> | undefined;
  dataSource: ExtendedTask[] = [];
  protected readonly Util = Util;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns;
  protected readonly taskStatus = TaskStatus;
  protected readonly util = Util;

  constructor(private _activatedRoute: ActivatedRoute, private _transferRunService: TransferRunService, public _dialog: MatDialog) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.updatePageContext();
    this.displayProgressBar(true);
  }

  ngAfterViewInit(): void {
    this.loadData().then(result => this.addTasksToDataSource(result as ExtendedTask[]))
  }

  async refreshView() {
    this.refreshComplete = false;
    await this.loadData().then(result => {
      this.addTasksToDataSource(result as ExtendedTask[]);
      this.refreshComplete = true;
      this.refreshViewRequired.emit(true);
    }).finally(() => {
      this.refreshComplete = true;
      this.displayProgressBar(false);
    })
  }


  addTasksToDataSource(results: ExtendedTask[]) {
    this.dataSource = results.map(item => {
      item.duration = Util.calculateDurationInSeconds(item.startDatetime, item.endDatetime);
      return item;
    });
  }


  updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }


  getDialogRef(message: string) {
    return this._dialog.open(AgConfirmationDialogComponent, {
      data: {
        title: "Confirm", message: message
      }
    });
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this))
  }

  onTaskClick(task: ExtendedTask) {
    this.selectedTask = task;
    this.taskDetailsPanel?.toggle();
  }

  onStopTaskClick(task: any) {
    if (task !== undefined) {
      const dialogRef = this.getDialogRef(`Are you sure you want to stop the task ${task.taskIdentifier}?`);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.displayProgressBar(true);
          this._transferRunService.stopTask(task.transferId, task.id).then(result => {
            if (result.status) {
              // immediately set status to stop to not allow accidental double clicks.
              task.taskStatus = TaskStatus.CANCELLED.toString();
              this.displayNotificationMessage('success', `Task ${task.taskIdentifier} ${TaskStatus.CANCELLED}.`);
              // let refresh get proper status.
              this.refreshView();
            }
            this.displayProgressBar(false);
          }).catch(error => {
              this.displayNotificationMessage('error', JSON.stringify(error));
              this.displayProgressBar(false);
            }
          );
        }
      })
    }
  }

  enableStopButton() {
    let selectedRecords = this.gridApi.getSelectedRows();
    if (selectedRecords.length == 0) {
      return false;
    }
    let enableStopButton = true;
    selectedRecords.forEach(record => {
      enableStopButton = record.taskStatus == TransferStatus.IN_PROGRESS && enableStopButton;
    });

    return enableStopButton;
  }

  onStopTasksButtonClick() {
    const dialogRef = this.getDialogRef(`Are you sure you want to stop the selected tasks?`);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let promises: Promise<OperationStatus>[] = [];
        // Util.getSelectedRecords(this.dataSource, this.taskSelectedRows).forEach(record => {
        this.gridApi.getSelectedRows().forEach(record => {
          promises.push(this._transferRunService.stopTask(record.transferId, record.id));
        });
        Promise.all(promises).then(r => {
            this.refreshView();
            this.displayNotificationMessage('success', 'tasks stopped.');
          }
        ).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
      }
    });
  }

  protected override initAfterGridReady() {
    this.gridApi.addEventListener(stopClickAction, (event: any) => {
      this.onStopTaskClick(event.detail.rowData);
    });
    this.gridApi.addEventListener(taskIdClickAction, (event: any) => {
      this.onTaskClick(event.detail.rowData);
    });

  }

  protected override getTableFooterActionButtonPanel(): TemplateRef<any> | undefined {
    return this.footerActionButtonPanel;
  }

  private async loadData(): Promise<Task[]> {
    let returnArray: Task[] = [];
    await this._transferRunService.getTasks(this.transferId!).then(result => {
      returnArray = result;
    }).catch(error => this.displayNotificationMessage('error', error)
    ).finally(() => {
      this.displayProgressBar(false);
    });
    return returnArray;
  }
}
