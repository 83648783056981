import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from 'ag-grid-enterprise';

@Component({
  selector: 'app-status-column',
  templateUrl: './status-column.component.html',
  styleUrls: ['./status-column.component.scss']
})
export class StatusColumnComponent implements ICellRendererAngularComp {
  params!: any;
  status?: any;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.status = this.params.value;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

}
