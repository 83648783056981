import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function maxCompaniesToRateValidator(...maxCompaniesToRate: (AbstractControl | undefined)[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // validate to ensure all controls have a value.
    const anyHasValue = maxCompaniesToRate.some(control => control && control.value);
    let allHaveValue = false;
    if (anyHasValue) {
      allHaveValue = maxCompaniesToRate.every(control => control && control.value);
    }

    const total = Number(control.value.maxTotalCompaniesToRate);
    const sum = Number(control.value.maxP1CompaniesToRate) + Number(control.value.maxP2CompaniesToRate) + Number(control.value.maxP3CompaniesToRate);
    // There are some various states for the maxTotal textbox.  At first, it will start as undefined.  When I type text, and delete it, it will either be null or an empty string.
    // So I put all checks in there to account for all instances of when it can be "empty".
    const isValid = (allHaveValue && sum === total) ||
      ((control.value.maxTotalCompaniesToRate === undefined || control.value.maxTotalCompaniesToRate === null || control.value.maxTotalCompaniesToRate === '')
        && !anyHasValue);
    if (isValid) {
      maxCompaniesToRate.forEach(control => {
        if (control) {
          control.setErrors(null);
        }
      });
    } else {
      maxCompaniesToRate.forEach(control => {
        if (control) {
          control.setErrors({maxTotalCompaniesValid: false});
        }
      });
    }
    return isValid ? null : {maxTotalCompaniesValid: false};
  }
}
