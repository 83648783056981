import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UniqueLinkInviteesService} from "@data/panel/unique.link.invitees.service";
import {SurveySpecService} from "@data/survey-spec/survey.spec.service";
import {UniqueLinkInvitee} from "@data/interefaces/panel.interfaces";
import {Util} from "@data/util/util";
import {ViewSurvey} from "@data/interefaces/survey.spec.interfaces";
import {marketCodes, pageContext, periods, surveyCodes, tableColumns} from "@pages/panel/unique-link-invitees/unique-link-invitees.component.ds";
import {TableBaseComponent} from "@pages/table.base.component";
import {FilterChangedEvent, FirstDataRenderedEvent, SelectionChangedEvent, ViewportChangedEvent} from 'ag-grid-community';
import {KpiSidebarItem} from "@shared/ag-kpi-sidebar/ag-kpi-sidebar.component.ds";
import {QuilliupForm} from "@data/enums/quilliup.enum";
import {FeatureComponentMode, ResponseStatus, YesNoValue} from "@data/enums/data.enums";
import {ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {ReferenceService} from "@data/reference/reference.service";
import {LookupService} from "@data/lookup/lookup.service";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'app-unique-link-invitees',
  templateUrl: './unique-link-invitees.component.html',
  styleUrls: ['./unique-link-invitees.component.scss'],
  providers: [AgGridService]
})

export class UniqueLinkInviteesComponent extends TableBaseComponent implements AfterViewInit {

  rowData: UniqueLinkInvitee[] = [];
  selectedRows: UniqueLinkInvitee[] = [];
  selectedFeatureMode!: FeatureComponentMode;
  auxLinks: Map<string, string>;
  surveyCodeList: ViewSurvey[] | undefined;
  showSkeleton: boolean = true;
  kpiSidebar: KpiSidebarItem[] = [];
  refreshComplete: boolean = true;
  isFilterActive: boolean = false;
  @ViewChild('footerActionButtonPanel') footerActionButtonPanel: TemplateRef<any> | undefined;

  isBulkOperationAllowed: boolean = false;
  showTaskComponent: boolean = false;

  protected readonly util = Util;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns
  protected readonly responseStatus = ResponseStatus;
  protected readonly QuilliupForm = QuilliupForm;

  constructor(private _activatedRoute: ActivatedRoute, private _uniqueLinkInviteService: UniqueLinkInviteesService, private _surveySpecService: SurveySpecService,
              private _referenceService: ReferenceService, private _lookupService: LookupService) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    this.loadFilterData();
    this.updatePageContext();
  }

  ngAfterViewInit() {
  }

  onLiveDataFilterChange(forceReload: boolean = false): void {
    if (forceReload) {
      this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    }
    this.updateToPageContextUrl();
  }

  updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
    this.populateKpiMetrics();
  }

  async loadPageData() {
    const periods = this.pageContext.p_f || '';
    const marketCodes = this.pageContext.mc_f || '';
    const surveyCodes = this.pageContext.sc_f || '';
    let thresholds: ResultsetThresholds | undefined;
    this.refreshComplete = false;
    this.displayProgressBar(true);
    await this._uniqueLinkInviteService.getUniqueLinkInviteesThresholds(periods, marketCodes, surveyCodes).then(result => {
      thresholds = result;
      this.setRowTemplateMessage(thresholds);
    }).catch(error => {
        this.displayNotificationMessage('error', JSON.stringify(error));
        this.displayProgressBar(false);
        this.refreshComplete = true;
      }
    );

    if (thresholds && !thresholds.hasTooMany) {
      await this._uniqueLinkInviteService.getUniqueLinkInvitees(periods, marketCodes, surveyCodes).then(result => {
        this.rowData = result;
      }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
      ).finally(() => {
          this.displayProgressBar(false);
          this.refreshComplete = true;
        }
      );
    } else {
      this.refreshComplete = true;
      this.rowData = [];
      this.displayProgressBar(false);
    }
  }

  loadFilterData() {
    this._surveySpecService.getSurveys().then(result => {
      result.forEach(item => surveyCodes.push(item.surveyCode));
    });
    this._referenceService.getAllMarkets().then(result => {
      result.forEach(item => marketCodes.push(item.marketCode));
    });
    this._lookupService.getPeriods().then(result => {
      result.forEach(item => periods.push(item.period));
    });
  }

  onRowSelectionChanged(event: SelectionChangedEvent) {
    this.refreshGridHeaderOnSelectionChanged(event);
    this.selectedRows = this.getSelectedRows();
    this.isBulkOperationAllowed = this.selectedRows.length != 0 && this.selectedRows.every(item => item.surveyCode === this.selectedRows[0].surveyCode);
  }

  async refreshView() {
    await this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
  }

  onBulkMailoutClicked() {
    this.selectedFeatureMode = FeatureComponentMode.BULK_MAILOUT;
    this.showTaskComponent = true;
  }

  onBulkEditClicked() {
    this.selectedFeatureMode = FeatureComponentMode.BULK_EDIT;
    this.showTaskComponent = true;
  }

  goBack() {
    this.showTaskComponent = false;
    this.refreshView();
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    const updateLiveDataFilter = this.isUpdateLiveDataFilterRequired(event.columns);
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this)).then(() => {
      if (updateLiveDataFilter) {
        this.onLiveDataFilterChange(true);
      }
    });
  }

  protected override onViewportChanged(event: ViewportChangedEvent<any>) {
    this.populateKpiMetrics();
  }

  protected override getTableFooterActionButtonPanel(): TemplateRef<any> | undefined {
    return this.footerActionButtonPanel
  }

  protected override onFirstDataRendered(event: FirstDataRenderedEvent<any>) {
    super.onFirstDataRendered(event);
    event.api.autoSizeAllColumns();
  }

  private populateKpiMetrics() {
    let filteredRows = this.getRowsAfterFilter();
    let {validCompletes, respondentCompanies, respondents} = this.calculateKpiMetrics(filteredRows);
    this.kpiSidebar = [
      {
        title: "Valid Completes",
        value: validCompletes
      },
      {
        title: "Respondent Companies",
        value: respondentCompanies
      },
      {
        title: "Respondents",
        value: respondents
      }
    ];
  }

  private calculateKpiMetrics(rows: UniqueLinkInvitee[]) {
    let validCompletes = rows.filter(element => element.responseStatus == ResponseStatus.COMPLETE && element.invalid != YesNoValue.YES_VALUE).length;
    let respondentCompanies = new Set(rows.map(element => element.invitationCompanyCode)).size;
    let respondents = new Set(rows.map(element => element.personCode)).size;
    return {validCompletes, respondentCompanies, respondents};
  }
}
