<div class="full-height-container">
  <mat-card class="full-height-container">
    <mat-card-content class="content-panel full-height-container">
      <div class="flex-row">
        <app-component-header (refreshClickEvent)="this.refreshView()"
                              [hideColumnsButton]="true"
                              [hideUploadButton]="true"
                              [startRefreshTimer]="this.refreshComplete"
                              [subtitleSegments]="getUrlSegments()"
                              class="full-width"></app-component-header>
      </div>
      <div class="flex-row table-container">
        <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                         (filterChanged)="onFilterChanged($event)"
                         (firstDataRendered)="onFirstDataRendered($event)"
                         (gridReady)="onGridReady($event)"
                         (selectionChanged)="onRowSelectionChanged($event)"
                         (viewportChanged)="onViewportChanged($event)"
                         [columnDefs]="tableColumns"
                         [gridOptions]="baseAgGridOptions"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         [pagination]="true"
                         [rowData]="dataSource"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz ag-grid-flex">
        </ag-grid-angular>
        <ng-template #footerActionButtonPanel>
          <button [routerLink]="['task', 'upload']" class="white-font-color mg-r-m" color="primary" mat-raised-button>
            <i class="fa-sharp fa-light fa-add"></i>
            Upload New File
          </button>
        </ng-template>
      </div>
    </mat-card-content>
  </mat-card>
</div>
