import {Component, TemplateRef, ViewChild} from '@angular/core';
import {TableBaseComponent} from "@pages/table.base.component";
import {ViewDownloadRequest,} from "@data/interefaces/download.request.interfaces";
import {DownloadRequestService} from "@data/download-request/download.request.service";
import {Util} from "src/app/data/util/util";
import {DownloadRequestUtil} from "@data/download-request/download.request.util";
import {pageContext, tableColumns} from "@pages/download-request/download-request-list.component.ds";
import {FilterChangedEvent, RowSelectedEvent} from "ag-grid-community";
import {ActivatedRoute} from "@angular/router";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'app-download-request',
  templateUrl: './download-request-list.component.html',
  styleUrls: ['./download-request-list.component.scss'],
  providers: [AgGridService]
})
export class DownloadRequestListComponent extends TableBaseComponent {

  auxLinks: Map<string, string>;
  rowData: ViewDownloadRequest[] = [];
  refreshComplete: boolean = true;
  isFilterActive: boolean = false;
  selectedRows: ViewDownloadRequest[] = [];
  @ViewChild('footerActionButtonPanel') footerActionButtonPanel: TemplateRef<any> | undefined;
  protected downloadRequestUtil = DownloadRequestUtil;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns;
  protected readonly util = Util;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _downloadRequestService: DownloadRequestService,) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.updatePageContext();

    this.refreshView();
    // we cannot call refreshView() in ngAfterViewInit(), we will get
    // NG0100: Expression has changed after it was checked error

    this.displayProgressBar(true);
  }

  onRowSelected(event: RowSelectedEvent) {
    if (event.source == "checkboxSelected") {
      this.gridApi.refreshHeader();
    }
    this.selectedRows = this.getSelectedRows();
  }

  onLiveDataFilterChange(forceReload: boolean = false): void {
    if (forceReload) {
      this.refreshView();
    }
    this.updateToPageContextUrl();
  }

  refreshView() {
    this.refreshComplete = false;
    this.displayProgressBar(true);
    this._downloadRequestService.getAllDownloadRequests().then(result => {
      result.sort((a, b) => new Date(b.requestDate!).getTime() - new Date(a.requestDate!).getTime());
      this.rowData = result.map((element) => this.downloadRequestUtil.mapDownloadRequestToView(element));
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => {
      this.refreshComplete = true;
      this.displayProgressBar(false)
    });
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    const updateLiveDataFilter = this.isUpdateLiveDataFilterRequired(event.columns);
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this)).then(() => {
      if (updateLiveDataFilter) {
        this.onLiveDataFilterChange(true);
      }
    });
  }

  protected override getTableFooterActionButtonPanel(): TemplateRef<any> | undefined {
    return this.footerActionButtonPanel;
  }

  protected updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }
}


