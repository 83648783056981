import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType} from "@data/enums/data.enums";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {Util} from "@data/util/util";
import {ResetFilterComponent} from "@pages/segments/grid/filters/reset-filter/reset-filter.component";

export let pageContext: {
  [key: string]: boolean | string | undefined
  etc_f: string | undefined;
  pc_f: string | undefined;
  n_f: string | undefined;
  d_f: string | undefined;
} = {
  etc_f: undefined,
  pc_f: undefined,
  n_f: undefined,
  d_f: undefined,
}

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    field: 'applicationStatus',
    headerName: 'Alert',
    maxWidth: 75,
    filter: false,
    pinned: "left",
    cellRenderer: (params: any) => {
      if (params.value === 'Offline') {
        return `<span><i class="fa-sharp fa-circle-exclamation error-color"></i></span>`
      }
      return '';
    }
  },
  {
    field: 'eventType',
    headerName: 'Event Type Code',
    filterId: 'etc_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'eventProcessorCode',
    headerName: 'Processor Code',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'processorStatus',
    headerName: 'Processing Status',
    maxWidth: 120,
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'applicationStatus',
    headerName: 'Application Status',
    maxWidth: 120,
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'eventProcessorName',
    headerName: 'Name',
  },
  {
    field: 'description',
    headerName: 'Description',
    maxWidth: 200,
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.description,
        type: 'text',
      };
    }
  },
  {
    field: 'acceptingNewEvent',
    headerName: 'Accepting Events',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'eventsInQueue',
    headerName: 'In Queue',
  },
  {
    field: 'queueSize',
    headerName: 'Queue Size',
  },
  {
    field: 'eventsInProgress',
    headerName: 'In Progress',
  },
  {
    field: 'threadPoolSize',
    headerName: 'Thread pool size',
  },
  {
    field: 'startDatetime',
    headerName: 'Started Time',
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },
  {
    field: 'Parameters',
    headerName: 'parameters',
    maxWidth: 250,
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.parameters,
        type: 'text',
      };
    }
  },
  {
    field: "actions",
    headerName: "Actions",
    pinned: "right",
    suppressFiltersToolPanel: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: ResetFilterComponent,
    suppressFloatingFilterButton: true,
    suppressHeaderFilterButton: true,
    cellRenderer: ActionColumnComponent,
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRendererParams: function (params: any) {
      return {
        actions: [
          {
            toolTip: "Edit",
            icon: "fa-pen-to-square",
            name: "Edit",
            routerLink: ['task', 'edit'],
            queryParams: {pc_f: params.data.eventProcessorCode}
          },
          {
            toolTip: "Event Logs",
            icon: "fa-rectangle-vertical-history",
            name: "Event Logs",
            routerLink: ['/system', 'events', 'event-logs'],
            queryParams: {pb_f: params.data.eventProcessorCode}
          },
        ]
      };
    }
  }
];


