import {AfterViewInit, Component} from '@angular/core';
import {FormControl} from "@angular/forms";
import {BaseComponent} from "@pages/base.component";
import {TransferConfig} from "@data/interefaces/data.interfaces";
import {TransferConfigService} from "@data/transfer-config/transfer.config.service";
import {ActivatedRoute} from "@angular/router";
import {TransferRunService} from "@data/transfer-run/transfer.run.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
  selector: 'app-transfer-manual-run',
  templateUrl: './transfer-manual-run.component.html',
  styleUrls: ['./transfer-manual-run.component.scss']
})
export class TransferManualRunComponent extends BaseComponent implements AfterViewInit {

  transferConfigArray: TransferConfig[] = [];
  selectedTransferConfig: TransferConfig | undefined;
  formControl = new FormControl();
  auxLinks: Map<string, string>;

  runParameters: any = {};
  runParametersStatus: boolean = false;
  includeRunParameters: boolean = false;

  constructor(private _activatedRoute: ActivatedRoute, private _transferConfigService: TransferConfigService, private _transferRunService: TransferRunService) {
    super();
    this.formControl.valueChanges.subscribe(value => this.onConfigurationChange());
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    this.displayProgressBar(true);
  }

  ngAfterViewInit() {
    this._transferConfigService.getTransferConfigurations().then(result => {
      this.transferConfigArray = result;
      let referenceCode = this._activatedRoute.snapshot.params['referenceCode'];
      if (referenceCode != undefined) {
        this.formControl.setValue(referenceCode);
        this.selectedTransferConfig = this.findTransferConfig(referenceCode);
      }
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => this.displayProgressBar(false));
  }

  onConfigurationChange() {
    this.selectedTransferConfig = this.findTransferConfig(this.formControl.value);
  }

  findTransferConfig(transferTypeCode: any) {
    return this.transferConfigArray.find(element => element.code.toLowerCase() == transferTypeCode);
  }

  resetRunParameters() {
  }

  onRunParameterChange(event: { field: string; value: any }) {
    if (Array.isArray(event.value)) {
      this.runParameters[event.field] = event.value.join(',');
    } else {
      this.runParameters[event.field] = event.value;
    }
  }

  onRunButtonClick() {
    this.displayProgressBar(true);
    if (this.includeRunParameters) {
      this._transferRunService.runTransfer(this.selectedTransferConfig?.code, this.runParameters).then(result => {
        if (result.status) {
          this.displayNotificationMessage('success', `Transfer ${this.selectedTransferConfig?.code} started.`);
        }
      }).catch(error => {
          this.displayNotificationMessage('error', JSON.stringify(error));
        }
      ).finally(() => this.displayProgressBar(false));
    } else {
      this._transferRunService.runTransfer(this.selectedTransferConfig?.code).then(result => {
        if (result.status) {
          this.displayNotificationMessage('success', `Transfer ${this.selectedTransferConfig?.code} started.`);
        }
      }).catch(error => {
          this.displayNotificationMessage('error', JSON.stringify(error));
        }
      ).finally(() => this.displayProgressBar(false));
    }
  }

  onValidParameterPanel(event: boolean) {
    this.runParametersStatus = event;
  }

  runButtonStatus() {
    return this.selectedTransferConfig && (!this.includeRunParameters || (this.includeRunParameters && this.runParametersStatus))
  }

  onEnableCustomParameters(event: MatSlideToggleChange) {
    this.includeRunParameters = event.checked;
  }
}
