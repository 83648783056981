import {Component} from '@angular/core';
import {ICellRendererParams} from "ag-grid-enterprise";

@Component({
  selector: 'app-select-column',
  templateUrl: './select-column.component.html',
  styleUrls: ['./select-column.component.scss']
})
export class SelectColumnComponent {
  params!: any;
  showFlag!: any;
  flagIcon!: any;
  flagColor!: any;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.showFlag = this.params.showFlag;
    this.flagIcon = this.params.flagIcon;
    this.flagColor = this.params.flagColor;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

}
