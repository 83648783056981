<mat-card class="full-height-container">
  <mat-card-content class="full-height-container content-panel">
    <div class="flex-row">
      <app-component-header [hideColumnsButton]="true"
                            [startRefreshTimer]="this.refreshComplete"
                            (refreshClickEvent)="this.refreshView()"
                            [hideUploadButton]="true"
                            [hideDownloadButton]="true"
                            [subtitleSegments]="getUrlSegments()"
                            [checkAutoRefreshByDefault]="true"
                            class="full-width">
      </app-component-header>
    </div>
    <div class="flex-row table-container">
      <ag-grid-angular
        (columnVisible)="onColumnVisible($event)"
        (filterChanged)="onFilterChanged($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (gridReady)="onGridReady($event)"
        (viewportChanged)="onViewportChanged($event)"
        [columnDefs]="tableColumns"
        [gridOptions]="baseAgGridOptions"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [rowData]="rowData"
        [suppressPropertyNamesCheck]="true"
        class="ag-theme-quartz full-width">
      </ag-grid-angular>
    </div>
  </mat-card-content>
</mat-card>
