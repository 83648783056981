import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType, LinkType, YesNoValue} from "@data/enums/data.enums";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {QuilliupForm} from "@data/enums/quilliup.enum";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {Util} from "@data/util/util";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";
import {SelectHeaderComponent} from "@pages/segments/grid/columns/select-header/select-header.component";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {ResetFilterComponent} from "@pages/segments/grid/filters/reset-filter/reset-filter.component";

export let pageContext: {
  [key: string]: boolean | string | undefined
  p_f: string | undefined;
  mc_f: string | undefined;
  mn_f: string | undefined;
  ic_f: string | undefined;
  in_f: string | undefined;
  ss_f: string | undefined;
  sc_f: string | undefined;
  rr_f: string | undefined;
  rc_f: string | undefined;
  rs_f: string | undefined;
  pc_f: string | undefined;
  i_f: string | undefined;
  icc_f: string | undefined;
  idc_f: string | undefined;
  icl_f: string | undefined;
  n_f: string | undefined;
  fn_f: string | undefined;
  ln_f: string | undefined;
  e_f: string | undefined;
  tp_f: string | undefined;
  cp_f: string | undefined;
  c_f: string | undefined;
  jt_f: string | undefined;
  yr_f: string | undefined;
  pfci_f: boolean | undefined;
  iu_f: string | undefined;
  sl_f: string | undefined;
  mtc_f: string | undefined;
  mfsc_f: string | undefined;
  md_r: string | undefined;
  fmd_r: string | undefined;
  lmd_r: string | undefined;
  rs_ns: boolean | undefined;
  rs_ip: boolean | undefined;
  rs_e: boolean | undefined;
  rs_c: boolean | undefined;
  rs_s: boolean | undefined;
  tmn_r: string | undefined;
  tmx_r: string | undefined;
  nc_f: string | undefined;
  cr_f: string | undefined;
  nn_f: string | undefined;
  pfss_f: boolean | undefined;
  lss_f: boolean | undefined;
  sdc_f: boolean | undefined;
  srm_f: boolean | undefined;
  lrc_f: string | undefined;
  scd_f: string | undefined;
  rcd_f: string | undefined;
  abc_f: boolean | undefined;
  bcc_f: string | undefined;
  cc_f: string | undefined;
  bpc_f: string | undefined;
  src_f: string | undefined;
  ssc_f: string | undefined;
  tcr_f: string | undefined;
  ctr1_f: string | undefined;
  ctr2_f: string | undefined;
  ctr3_f: string | undefined;
  rti_f: string | undefined;
  moh_f: string | undefined;
  lms_f: string | undefined;
  idl_f: string | undefined;
  nl_f: string | undefined;
  lrl_f: string | undefined;
  slb_f: string | undefined;
  rl_f: string | undefined;
  bcl_f: string | undefined;
  cl_f: string | undefined;
  bpl_f: string | undefined;
  srl_f: string | undefined;
  ssl_f: string | undefined;
  si_f: string | undefined;
  sii_f: string | undefined;
} = {
  p_f: undefined,
  mc_f: undefined,
  mn_f: undefined,
  ic_f: undefined,
  in_f: undefined,
  ss_f: undefined,
  sc_f: undefined,
  rr_f: undefined,
  rc_f: undefined,
  rs_f: undefined,
  pc_f: undefined,
  i_f: undefined,
  icc_f: undefined,
  idc_f: undefined,
  icl_f: undefined,
  n_f: undefined,
  fn_f: undefined,
  ln_f: undefined,
  e_f: undefined,
  tp_f: undefined,
  cp_f: undefined,
  c_f: undefined,
  jt_f: undefined,
  yr_f: undefined,
  pfci_f: undefined,
  iu_f: undefined,
  sl_f: undefined,
  mtc_f: undefined,
  mfsc_f: undefined,
  md_r: undefined,
  fmd_r: undefined,
  lmd_r: undefined,
  rs_ns: false,
  rs_ip: false,
  rs_e: false,
  rs_c: false,
  rs_s: false,
  tmn_r: undefined,
  tmx_r: undefined,
  nc_f: undefined,
  cr_f: undefined,
  nn_f: undefined,
  pfss_f: undefined,
  lss_f: undefined,
  sdc_f: undefined,
  srm_f: undefined,
  lrc_f: undefined,
  scd_f: undefined,
  rcd_f: undefined,
  abc_f: undefined,
  bcc_f: undefined,
  cc_f: undefined,
  bpc_f: undefined,
  src_f: undefined,
  ssc_f: undefined,
  tcr_f: undefined,
  ctr1_f: undefined,
  ctr2_f: undefined,
  ctr3_f: undefined,
  rti_f: undefined,
  moh_f: undefined,
  lms_f: undefined,
  idl_f: undefined,
  nl_f: undefined,
  lrl_f: undefined,
  slb_f: undefined,
  rl_f: undefined,
  bcl_f: undefined,
  cl_f: undefined,
  bpl_f: undefined,
  srl_f: undefined,
  ssl_f: undefined,
  si_f: undefined,
  sii_f: undefined
};

export let periods: string[] = [];
export let marketCodes: string[] = [];
export let surveyCodes: string[] = [];

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    maxWidth: 75,
    field: 'select',
    headerName: '',
    hide: false,
    filter: false,
    headerComponent: SelectHeaderComponent,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    pinned: "left",
    suppressColumnsToolPanel: true,
    downloadable: false,
    cellRenderer: SelectColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        showFlag: params.data.invalid == YesNoValue.YES_VALUE,
        flagIcon: "fa-exclamation",
        flagColor: "red",
        toolTip: 'Invalid'
      }
    }
  },
  {
    headerName: 'Data Filter', valueFormatter: '', children: [
      {
        field: 'period',
        headerName: 'Period',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'p_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: periods,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            closeOnApply: true,
            suppressSorting: true,
            buttons: ['apply', 'reset']
          }
      },
      {
        field: 'marketCode',
        headerName: 'Market Code',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'mc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: marketCodes,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            closeOnApply: true,
            buttons: ['apply', 'reset']
          },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketName
          };
        }
      },
      {
        field: 'surveyCode', headerName: 'Survey Code', pinned: "left", hide: false, dbFilter: true, filterId: 'sc_f', filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: surveyCodes,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            buttons: ['apply', 'reset'],
            closeOnApply: true
          }
      }
    ]
  },
  {
    headerName: 'Invitation', valueFormatter: '', children: [
      {
        field: 'marketName',
        headerName: 'Market Name',
        hide: false,
        filter: ColumnFilterType.TEXT,
        filterId: 'mn_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketCode
          };
        }
      },
      {
        field: 'industryCode',
        headerName: 'Industry Code',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'ic_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryName
          };
        }
      },
      {
        field: 'industryName',
        headerName: 'Industry Name',
        hide: true,
        filter: ColumnFilterType.TEXT,
        filterId: 'in_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryCode
          };
        }
      },
      {
        field: 'surveySubject',
        headerName: 'Survey Subject',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'ss_f',
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent
      },
      {field: 'remoteRespId', headerName: 'Remote Resp ID', hide: false, filterId: 'rr_f', filterParams: {buttons: ['reset']}},
      {
        field: 'responseCode', headerName: 'Response Code', hide: false, filterId: 'rc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.email,
            links:
              [
                {routerLink: '/forms/survey-respondents/survey-tracker', text: 'Survey Tracker', target: '_blank', linkType: LinkType.INTERNAL},
              ]
          };
        }
      },
      {
        field: 'personCode', headerName: 'Person Code', hide: false, filterId: 'pc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
      },
      {
        field: 'responseStatus',
        headerName: 'Response Status',
        hide: false,
        filterId: 'rs_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'inviteeUrl', headerName: 'Invitee Url', hide: false, filterId: 'iu_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.inviteeUrl,
            type: 'link',
          };
        }
      },
      {
        field: 'invalid', headerName: 'Invalid?', hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          cellRendererParams: function (params: any) {
            return {
              invertIcons: true
            };
          },
          buttons: ['reset']
        },
        filterId: 'i_f',
        cellRenderer: CircleCheckColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            invertIcons: true
          };
        },
      },
      {
        field: 'invitationCompanyCode', headerName: 'Invitation Company Code', hide: false, filterId: 'icc_f',
        autoHeight: true,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.invitationCompanyLabel,
            links:
              [
                {routerLink: '/forms/survey-respondents/survey-tracker', text: 'Survey Tracker', target: '_blank', linkType: LinkType.INTERNAL},
              ]
          };
        }
      },
      {
        field: 'invitationCompanyLabel', headerName: 'Invitation Company Label', hide: true, filterId: 'icl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.invitationCompanyCode
          };
        }
      },
      {
        field: 'invitationDivisionCodes', headerName: 'Invitation Division Codes', hide: false, filterId: 'idc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
      },
      {
        field: 'invitationDivisionLabels', headerName: 'Invitation Division Labels', hide: true, filterId: 'idl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.invitationDivisionCodes
          };
        }
      }
    ]
  },
  {
    headerName: 'Demographics', valueFormatter: '', children: [
      {
        field: 'firstName',
        headerName: 'First Name',
        hide: false,
        filterId: 'fn_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        hide: false,
        filterId: 'ln_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'email', headerName: 'Email', hide: false, filterId: 'e_f',
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.email,
            type: 'text',
          };
        }
      },
      {
        field: 'telephone',
        headerName: 'Telephone',
        hide: false,
        filterId: 'tp_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'cellPhone',
        headerName: 'Cell Phone',
        hide: false,
        filterId: 'cp_f',
        filterParams: {buttons: ['reset']}
      },
      {field: 'city', headerName: 'City', hide: false, filterId: 'c_f', filterParams: {buttons: ['reset']}},
      {
        field: 'jobTitle',
        headerName: 'Job Title',
        hide: false,
        filterId: 'jt_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'yearsInRole',
        headerName: 'Years in Role',
        hide: false,
        filterId: 'yr_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'preFillContactInformation',
        headerName: 'Pre-fill Contact Information?',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
        filterId: 'pfci_f',
        cellRenderer: CircleCheckColumnComponent
      },
      {
        field: 'surveyLanguage',
        headerName: 'Survey Language',
        hide: false,
        filterId: 'sl_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']}
      }]
  },
  {
    headerName: 'Nominations', children: [
      {
        field: 'nominatedByCodes', headerName: 'Nominated By Codes', hide: true, filterId: 'nc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.nominatedByLabels
          };
        }
      },
      {
        field: 'nominatedByLabels', headerName: 'Nominated By Labels', hide: true, filterId: 'nl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.nominatedByCodes
          };
        }
      },
      {
        field: 'categoryResponsibility',
        headerName: 'Category Responsibility',
        hide: true,
        filterId: 'cr_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'nominationNotes',
        headerName: 'Nomination Notes',
        hide: true,
        filterId: 'nn_f',
        filterParams: {buttons: ['reset']}
      }
    ]
  },
  {
    headerName: 'Pre-selections', children: [
      {
        field: 'preFillSurveySelections',
        headerName: 'Pre-fill Survey Selections?',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
        filterId: 'pfss_f',
        cellRenderer: CircleCheckColumnComponent
      },
      {
        field: 'lockSurveySelections',
        headerName: 'Lock Survey Selections?',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
        filterId: 'lss_f',
        cellRenderer: CircleCheckColumnComponent
      },
      {
        field: 'showDivisionsCollapsed',
        headerName: 'Show Divisions Collapsed?',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
        filterId: 'sdc_f',
        cellRenderer: CircleCheckColumnComponent
      },
      {
        field: 'startInReviewMode', headerName: 'Start in Review Mode?', hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
        filterId: 'srm_f', cellRenderer: CircleCheckColumnComponent
      },
      {
        field: 'localRegionCodes', headerName: 'Local Region Codes', hide: true, filterId: 'lrc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
      },
      {
        field: 'localRegionLabels', headerName: 'Local Region Labels', hide: true, filterId: 'lrl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.localRegionCode
          };
        }
      },
      {
        field: 'seniorityCode', headerName: 'Seniority Code', hide: true, filterId: 'scd_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
      },
      {
        field: 'seniorityLabel', headerName: 'Seniority Label', hide: true, filterId: 'slb_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.seniorityCode
          };
        }
      },
      {
        field: 'roleCodes', headerName: 'Role Codes', hide: true, filterId: 'rcd_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.roleLabels
          };
        }
      },
      {
        field: 'roleLabels', headerName: 'Role Labels', hide: true, filterId: 'rl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.roleCodes
          };
        }
      },
      {
        field: 'allBuCategories',
        headerName: 'All BU-Categories?',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
        filterId: 'abc_f',
        cellRenderer: CircleCheckColumnComponent
      },
      {
        field: 'buCategoryCodes', headerName: 'BU-Category Codes', hide: true, filterId: 'bcc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.buCategoryLabels
          };
        }
      },
      {
        field: 'buCategoryLabels', headerName: 'BU-Category Labels', hide: true, filterId: 'bcl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.buCategoryCodes
          };
        }
      },
      {
        field: 'channelCodes', headerName: 'Channel Codes', hide: true, filterId: 'cc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.channelLabels
          };
        }
      },
      {
        field: 'channelLabels', headerName: 'Channel Labels', hide: true, filterId: 'cl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.channelCodes
          };
        }
      },
      {
        field: 'brPlCode', headerName: 'BR/PL Code', hide: true, filterId: 'bpc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
      },
      {
        field: 'brPlLabel', headerName: 'BR/PL Label', hide: true, filterId: 'bpl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.brPlCode
          };
        }
      },
      {
        field: 'subjectRollupCodes', headerName: 'Subject Rollup Codes', hide: true, filterId: 'src_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
      },
      {
        field: 'subjectRollupLabels', headerName: 'Subject Rollup Labels', hide: true, filterId: 'srl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.subjectRollupCodes
          };
        }
      },
      {
        field: 'subjectSingleCodes', headerName: 'Subject Single Codes', hide: true, filterId: 'ssc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
      },
      {
        field: 'subjectSingleLabels', headerName: 'Subject Single Labels', hide: true, filterId: 'ssl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.subjectSingleCodes
          };
        }
      },
      {
        field: 'maxTotalCompaniesToRate',
        headerName: 'Max Total Companies to Rate',
        hide: true,
        filterId: 'tcr_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'maxP1CompaniesToRate',
        headerName: 'Max P1 Companies to Rate',
        hide: true,
        filterId: 'ctr1_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'maxP2CompaniesToRate',
        headerName: 'Max P2 Companies to Rate',
        hide: true,
        filterId: 'ctr2_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'maxP3CompaniesToRate',
        headerName: 'Max P3 Companies to Rate',
        hide: true,
        filterId: 'ctr3_f',
        filterParams: {buttons: ['reset']}
      }
    ]
  },
  {
    headerName: 'Mailouts', children: [

      {
        field: 'mailoutTemplateCode', headerName: 'Mailout Template Code', hide: false, filterId: 'mtc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {text: 'View Code in Quilliup', target: '_blank', linkType: LinkType.EXTERNAL, quilliupForm: QuilliupForm.MAILOUT_TEMPLATES},
                {text: "View Label in Quilliup", target: '_blank', linkType: LinkType.EXTERNAL, quilliupForm: QuilliupForm.MAILOUT_TEMPLATE_LABELS}
              ]
          };
        }
      },
      {
        field: 'mailoutFieldSetCode', headerName: 'Mailout Field Set Code', hide: false, filterId: 'mfsc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {text: 'View Code in Quilliup', target: '_blank', linkType: LinkType.EXTERNAL, quilliupForm: QuilliupForm.MAILOUT_FIELD_SETS},
                {text: "View Label in Quilliup", target: '_blank', linkType: LinkType.EXTERNAL, quilliupForm: QuilliupForm.MAILOUT_FIELD_SET_LABELS}
              ]
          };
        }
      },
      {
        field: 'mailoutDatetime',
        headerName: 'Mailout Datetime',
        hide: false,
        filterId: 'md_r',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1, comparator:
          Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value).toString();
        }
      },
      {
        field: 'remoteTaskId',
        headerName: 'Remote Task ID',
        hide: false,
        filterId: 'rti_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'mailoutHistory', headerName: 'Mailout History', hide: false, filterId: 'moh_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.mailoutHistory,
            type: 'text',
          };
        }
      },
      {
        field: 'firstMailingDate',
        headerName: 'First Mailing Date',
        hide: false,
        filterId: 'fmd_r',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value).toString();
        }
      },
      {
        field: 'lastMailingDate',
        headerName: 'Last Mailing Date',
        hide: false,
        filterId: 'lmd_r',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1, comparator:
          Util.dateComparator,
          buttons: ['reset']
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value).toString();
        }
      },
      {
        field: 'lastMailingStatus', headerName: 'Last Mailing Status', hide: false, filterId: 'lms_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.lastMailingStatus,
            type: 'text',
          };
        }
      },
      {
        field: 'timesMailed',
        headerName: 'Times Mailed',
        hide: false,
        filterId: 'tm_r',
        filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      }
    ]
  },
  {
    headerName: 'ID\'s', valueFormatter: '', children: [
      {
        field: 'surveyId',
        headerName: 'Survey ID',
        cellDataType: 'number',
        hide: true,
        filter: ColumnFilterType.NUMBER,
        filterId: 'si_f',
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      },
      {
        field: 'surveyInviteeId',
        headerName: 'Invitation ID',
        cellDataType: 'number',
        hide: true,
        filter: ColumnFilterType.NUMBER,
        filterId: 'sii_f',
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      }
    ]
  },
  {
    field: "actions",
    headerName: "Actions",
    pinned: "right",
    suppressFiltersToolPanel: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: ResetFilterComponent,
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    cellRenderer: ActionColumnComponent,
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRendererParams: function (params: any) {
      return {
        actions: [
          {
            toolTip: "Edit",
            icon: "fa-pen-to-square",
            name: "Edit",
            routerLink: ['task', 'edit'],
            queryParams: {sc_f: params.data.surveyCode, rc_f: params.data.responseCode}
          }
        ]
      };
    }
  }
]
