import {NgModule} from "@angular/core";
import {SharedModule} from "@shared/shared.module";
import {HeaderComponent} from "@layout/header/header.component";
import {FooterComponent} from "@layout/footer/footer.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatMenuModule} from "@angular/material/menu";
import {RouterModule} from "@angular/router";
import {AlertComponent} from '@layout/alert/alert.component';
import {ProgressBarComponent} from '@layout/progress-bar/progress-bar.component';
import {SidenavSupportComponent} from '@layout/sidenav-support/sidenav-support.component';
import { AlertWindowComponent } from './alert/alert-window/alert-window.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, AlertComponent, ProgressBarComponent, SidenavSupportComponent, AlertWindowComponent],
  imports: [
    RouterModule,
    SharedModule,
    MatToolbarModule,
    MatMenuModule,
  ],
  exports: [HeaderComponent, FooterComponent, AlertComponent, ProgressBarComponent, SidenavSupportComponent]
})
export class LayoutModule {
}
