import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private readonly localStore: Storage = localStorage;

  constructor() {
  }

  public read(storeKey: string): string | null {
    let storedValue: string | null = this.localStore.getItem(storeKey);
    return storedValue == null ? null : window.atob(storedValue);
  }

  public remove(storeKey: string): void {
    this.localStore.removeItem(storeKey)
  }

  public write(storeKey: string, value: any): void {
    if (typeof value == "object") {
      this.localStore.setItem(storeKey, window.btoa(JSON.stringify(value)));
    } else {
      this.localStore.setItem(storeKey, window.btoa(value));
    }
  }

}
