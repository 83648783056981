import {Component} from '@angular/core';

@Component({
  selector: 'app-offline-survey',
  templateUrl: './offline-survey.component.html',
  styleUrls: ['./offline-survey.component.scss']
})
export class OfflineSurveyComponent {

}
