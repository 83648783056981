import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {FeatureComponentMode} from "@data/enums/data.enums";

export class ProductSponsorVerbatimComponentUtil {
  public static setColumns(tableColumns: (GridColumn | GridColumnGroupDef)[], featureComponentMode: FeatureComponentMode) {
    tableColumns.forEach(column => {
      if((column as GridColumnGroupDef).children) {
        this.setColumnGroup(column as GridColumnGroupDef, featureComponentMode);
      } else {
        this.setColumn(column as GridColumn, featureComponentMode);
      }
    });
  }

  protected static setColumnGroup(columnGroup: GridColumnGroupDef, featureComponentMode: FeatureComponentMode) {
    switch (columnGroup.groupId) {
      case '_data_filter':
        columnGroup.headerName = featureComponentMode === FeatureComponentMode.DEFAULT ? 'Data Filter' : '-';
        break;
      default:
        // leave as is
        break;
    }
    // recurse into columns
    if(featureComponentMode !== FeatureComponentMode.DEFAULT && columnGroup.headerName === 'Data Filter') {
      columnGroup.headerName = '-';
    } else if(featureComponentMode === FeatureComponentMode.DEFAULT && columnGroup.headerName === '-') {
      columnGroup.headerName = 'Data Filter';
    }
    columnGroup.children.forEach(column => {
      if((column as GridColumnGroupDef).children) {
        this.setColumnGroup(column as GridColumnGroupDef, featureComponentMode);
      } else {
        this.setColumn(column as GridColumn, featureComponentMode);
      }
    });
  }

  protected static setColumn(column: GridColumn, featureComponentMode: FeatureComponentMode) {
    switch (column.field) {
      case 'select':
      case 'actions':
        column.hide = (featureComponentMode !== FeatureComponentMode.DEFAULT);
        break;
      case 'processing_status':
        column.hide = true;
        break;
      case 'processing_note':
        column.hide = (featureComponentMode === FeatureComponentMode.DEFAULT);
        column.downloadable = (featureComponentMode !== FeatureComponentMode.DEFAULT);
        break;
      default:
        // leave as is
        break;
    }
  }
}
