import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from "@pages/base.component";
import {TaskSidebarItem} from "@shared/ag-task/ag-task.component.ds";
import {FeatureComponentMode} from "@data/enums/data.enums";
import {ActivatedRoute} from "@angular/router";
import {UniqueLinkInvitee} from "@data/interefaces/panel.interfaces";
import {displayTableColumns} from "@pages/panel/unique-link-invitees/unique-link-invitees-bulk/unique-link-invitees-bulk.component.ds";

@Component({
  selector: 'download-request-task',
  templateUrl: './download-request-task.component.html',
  styleUrls: ['./download-request-task.component.scss']
})
export class DownloadRequestTaskComponent extends BaseComponent implements AfterViewInit {
  featureComponentSidebarEl: TaskSidebarItem[] = [
    {
      title: "Create New Download Request",
      icon: "fa-plus",
      selected: true,
      urlSupport: true,
      disabled: false,
      mode: FeatureComponentMode.ADD_ROW
    },
    {
      title: "Create New Parameter Request",
      icon: "fa-plus",
      selected: true,
      urlSupport: true,
      disabled: false,
      mode: FeatureComponentMode.ADD_LEGACY
    },
    {
      title: "Details",
      icon: "fa-pen-to-square",
      selected: false,
      urlSupport: true,
      disabled: true,
      mode: FeatureComponentMode.DETAIL
    }
  ];

  @Input() selectedFeatureMode: FeatureComponentMode | undefined;
  @Input() selectedRows!: UniqueLinkInvitee[];
  @Input() preSelectedFeatureMode!: FeatureComponentMode;
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  isUrlMode: boolean = false;
  urlModeGoBackUrl: string = '/';
  protected readonly FeatureComponentMode = FeatureComponentMode;
  protected readonly tableColumns = displayTableColumns;

  constructor(protected _activatedRoute: ActivatedRoute) {
    super();
    let paramMode = this._activatedRoute.snapshot.params['mode'];
    if (paramMode) {
      this.isUrlMode = true;
      this.selectedFeatureMode = paramMode;
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    this.urlModeGoBackUrl += this.createGoBackUrl();
  }

  ngAfterViewInit(): void {
  }
}
