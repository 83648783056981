<div class="full-height-container">
  <mat-card class="full-height-container">
    <mat-card-content class="content-panel full-height-container">
      <div class="flex-row">
        <app-component-header (refreshClickEvent)="this.refreshView()"
                              [hideColumnsButton]="true"
                              [startRefreshTimer]="this.refreshComplete"
                              [subtitleSegments]="getUrlSegments()"
                              class="full-width"></app-component-header>
      </div>
      <div class="flex-row table-container">
        <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                         (filterChanged)="onFilterChanged($event)"
                         (firstDataRendered)="onFirstDataRendered($event)"
                         (gridReady)="onGridReady($event)"
                         (viewportChanged)="onViewportChanged($event)"
                         [columnDefs]="tableColumns"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         [paginationPageSize]="50"
                         [pagination]="true"
                         [rowData]="dataSource"
                         [suppressPaginationPanel]="true"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz full-width"
                         rowSelection="multiple">
        </ag-grid-angular>

        <ng-template #footerActionButtonPanel>
          <button (click)="onStopTasksButtonClick()" [disabled]="!enableStopButton()" class="mg-r-s" color="primary" mat-raised-button>
            <mat-icon class="material-symbols-outlined">stop_circle</mat-icon>
            Stop Task(s)
          </button>

        </ng-template>

      </div>
    </mat-card-content>
  </mat-card>

</div>

<ag-side-panel #taskDetailsPanel>
  <app-transfer-run-detail-tasks-details [referenceCode]="this.selectedTask?.transferId" [taskId]="this.selectedTask?.id"
                                         [transferRun]="this.transferRun"></app-transfer-run-detail-tasks-details>
</ag-side-panel>
