import {Component, inject, Input} from "@angular/core";

@Component({
  selector: 'ag-copy-text-button',
  templateUrl: './ag-copy-text-button.component.html',
  styleUrls: ['./ag-copy-text-button.component.scss']
})
export class AgCopyTextButtonComponent {
  @Input() data?: string;
}
