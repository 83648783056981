<mat-drawer-container [autosize]="true" class="drawer">
  <mat-drawer [opened]="true" mode="side">
    <mat-card class="mg-r-s">
      <mat-card-title>
        <div class="flex-row card-title">
          <div (click)="onOpenHideClick()" class="mg-l-s point-cursor">
            <i *ngIf="hide" [ngClass]="titleIcon"></i>
            <span [class]="hide? 'hide-content' : ''">{{ title }}</span>
          </div>
          <div class="right-align mg-r-m point-cursor">
            <i (click)="onOpenHideClick()" *ngIf="!hide" class="fa-sharp fa-light fa-arrow-left"></i>
            <i (click)="onOpenHideClick()" *ngIf="hide" class="fa-sharp fa-light fa-arrow-right"></i>
          </div>
        </div>
      </mat-card-title>
      <mat-card-content #leftCardContent class="left-card-content">
        <ng-content select="[left]"></ng-content>
      </mat-card-content>
    </mat-card>
  </mat-drawer>
  <mat-drawer-content class="right-card-content">
    <!--    <mat-card>-->
    <!--      <mat-card-content>-->
    <ng-content select="[right]"></ng-content>
    <!--      </mat-card-content>-->
    <!--    </mat-card>-->
  </mat-drawer-content>
</mat-drawer-container>
