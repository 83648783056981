<fieldset class="section">
  <legend><b>Files</b></legend>
  <ng-container *ngIf="!this.refreshComplete">
    <p>Loading</p>
  </ng-container>
  <ng-container *ngIf="this.refreshComplete">
    <p *ngIf="rowData.length == 0">No files generated</p>
    <div *ngIf="rowData.length != 0" style="height: 50vh;">
      <ag-grid-angular
        style=" height: 100%;"
        (columnVisible)="onColumnVisible($event)"
        (filterChanged)="onFilterChanged($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (gridReady)="onGridReady($event)"
        (rowSelected)="onRowSelected($event)"
        (viewportChanged)="onViewportChanged($event)"
        [columnDefs]="tableColumns"
        [gridOptions]="baseAgGridOptions"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [rowData]="rowData"
        [suppressPropertyNamesCheck]="true"
        class="ag-theme-quartz ">
      </ag-grid-angular>
    </div>
  </ng-container>

</fieldset>
