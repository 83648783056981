import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {SurveyTracker} from "@data/interefaces/panel.interfaces";
import {ProcessStatus} from "@data/interefaces/extended.data.interfaces";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {Util} from "@data/util/util";

export interface SurveyTrackerWithProcessStatus extends SurveyTracker, ProcessStatus {
}

export let surveyTrackerDisplayTableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    headerName: 'Data Filter', children: [
      {
        field: 'surveyCode', headerName: 'Survey Code', pinned: "left", hide: false,
        cellRenderer: SelectColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            showFlag: params.data.processingNote != undefined,
            flagIcon: "fa-square-exclamation",
            flagColor: "red",
            toolTip: params.data.processingNote
          }
        }
      },
    ]
  },
  {
    headerName: 'Response', children: [
      {field: 'remoteRespId', headerName: 'Remote Resp ID'},
      {field: 'responseCode', headerName: 'Response Code', sort: 'asc', initialSortIndex: 3},
      {field: 'personCode', headerName: 'Person Code', sort: 'asc', initialSortIndex: 2,},
      {field: 'openLinkCode', headerName: 'Open Link Code'},
      {field: 'responseStatus', headerName: 'Response Status', cellRenderer: StatusColumnComponent},
      {field: 'responseProgress', headerName: 'Response Progress'},
      {
        field: 'responseProgress', headerName: 'Response Progress %',
        valueFormatter: (params) => {
          return Util.getFormattedResponseProgressPercent(params.value);
        }
      },
      {field: 'responseType', headerName: 'Response Type', cellRenderer: StatusColumnComponent},
      {field: 'alerts', headerName: 'Alerts'},
    ]
  },
  {
    headerName: 'Invitation Demographics', children: [
      {field: 'invitationCompanyCode', headerName: 'Invitation Company Code'},
      {field: 'invitationCompanyLabel', headerName: 'Invitation Company Label'},
      {field: 'invitationDivisionCodes', headerName: 'Invitation Division Codes'},
      {field: 'invitationDivisionLabels', headerName: 'Invitation Division Labels'}
    ]
  }, {
    headerName: 'Response Demographics', children: [
      {field: 'respondentCompanyCode', headerName: 'Respondent Company Code'},
      {field: 'respondentCompanyLabel', headerName: 'Respondent Company Label'},
      {field: 'respondentDivisionCodes', headerName: 'Respondent Division Codes'},
      {field: 'respondentDivisionLabels', headerName: 'Respondent Division Labels'},
      {field: 'responseFirstName', headerName: 'Response First Name'},
      {field: 'responseLastName', headerName: 'Response Last Name'},
      {
        field: 'responseEmail', headerName: 'Response Email',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.responseEmail,
            type: 'text',
          };
        }
      },
      {field: 'responseJobTitle', headerName: 'Response Job Title'},
      {field: 'responseYearsInRole', headerName: 'Response Years In Role',},
      {field: 'responseTelephone', headerName: 'Response Telephone'},
      {field: 'responseCellPhone', headerName: 'Response Cell Phone',},
      {field: 'responseCity', headerName: 'Response City'},
      {field: 'responseLocale', headerName: 'Response Locale', cellRenderer: StatusColumnComponent}
    ]
  },
  {
    headerName: 'Response Selections', children: [
      {field: 'localRegionCodes', headerName: 'Local Region Codes'},
      {field: 'localRegionLabels', headerName: 'Local Region Labels'},
      {field: 'seniorityCode', headerName: 'Seniority Code'},
      {field: 'seniorityLabel', headerName: 'Seniority Label'},
      {field: 'roleCodes', headerName: 'Role Codes'},
      {field: 'roleLabels', headerName: 'Role Labels'},
      {field: 'brPlCode', headerName: 'BR/PL Code'},
      {field: 'brPlLabel', headerName: 'BR/PL Label'},
      {
        field: 'allBuCategories', headerName: 'All BU-Categories?',
        filterParams: {defaultToNothingSelected: true, cellRenderer: CircleCheckColumnComponent},
        cellRenderer: CircleCheckColumnComponent
      },
      {field: 'buCategoryCodes', headerName: 'BU-Category Codes'},
      {field: 'buCategoryLabels', headerName: 'BU-Category Labels'},
      {field: 'channelCodes', headerName: 'Channel Codes'},
      {field: 'channelLabels', headerName: 'Channel Labels'},
      {field: 'selectedSubjectRollupCodes', headerName: 'Selected Subject Rollup Codes',},
      {field: 'selectedSubjectRollupLabels', headerName: 'Selected Subject Rollup Labels',},
      {field: 'selectedSubjectSingleCodes', headerName: 'Selected Subject Single Codes',},
      {field: 'selectedSubjectSingleLabels', headerName: 'Selected Subject Single Labels',},
      {field: 'ratedSubjectRollupCodes', headerName: 'Rated Subject Rollup Codes'},
      {field: 'ratedSubjectRollupLabels', headerName: 'Rated Subject Rollup Labels'},
      {field: 'ratedSubjectSingleCodes', headerName: 'Rated Subject Single Codes'},
      {field: 'ratedSubjectSingleLabels', headerName: 'Rated Subject Single Labels'},
      {field: 'selectedNotRatedSingleCodes', headerName: 'Selected Not Rated Single Codes',},
      {field: 'selectedNotRatedSingleLabels', headerName: 'Selected Not Rated Single Labels',}
    ]
  },

  {
    headerName: 'Mailing Demographics', children: [
      {field: 'mailingFirstName', headerName: 'Mailing First Name',},
      {field: 'mailingLastName', headerName: 'Mailing Last Name'},
      {
        field: 'mailingEmail', headerName: 'Mailing Email',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.responseEmail,
            type: 'text',
          };
        }
      },
      {field: 'mailingJobTitle', headerName: 'Mailing Job Title',},
      {field: 'mailingYearsInRole', headerName: 'Mailing Years In Role', cellRenderer: StatusColumnComponent},
      {field: 'mailingTelephone', headerName: 'Mailing Telephone',},
      {field: 'mailingCellPhone', headerName: 'Mailing Cell Phone'},
      {field: 'mailingCity', headerName: 'Mailing City'},
      {field: 'mailingLocale', headerName: 'Mailing Locale', cellRenderer: StatusColumnComponent},
      {field: 'priorYearTelephone', headerName: 'Prior Year Telephone'}
    ]
  },
  {
    headerName: 'Nominations', children: [
      {field: 'nominatedByCodes', headerName: 'Nominated By Codes'},
      {field: 'nominatedByLabels', headerName: 'Nominated By Labels'},
      {field: 'nominatedCategories', headerName: 'Nominated Categories'},
      {field: 'nominationNotes', headerName: 'Nomination Notes'}
    ]
  },
  {
    headerName: 'Response Exclusions', children: [
      {field: 'respondentDivisionExclusions', headerName: 'Respondent Division Exclusions'},
      {field: 'brPlExclusions', headerName: 'BR/PL Exclusions'},
      {field: 'channelExclusions', headerName: 'Channel Exclusions'},
      {field: 'buCategoryExclusions', headerName: 'BU Category Exclusions'},
      {field: 'localRegionExclusions', headerName: 'Local Region Exclusions'},
    ]
  },
  {
    headerName: 'Response', children: [
      {
        field: 'responseStartDatetime', headerName: 'Response Start Datetime',
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'responseEndDatetime', headerName: 'Response End Datetime',
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'responseLastImported', headerName: 'Response Last Imported',
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'responseLastUpdated', headerName: 'Response Last Updated',
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'surveyUrl', headerName: 'Survey URL',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.surveyUrl,
            type: 'link',
          };
        }
      },
    ]
  },
  {
    headerName: 'Mailing', children: [
      {field: 'endorserName', headerName: 'Endorser Name'},
      {field: 'mailoutTemplateCode', headerName: 'Mailout Template Code'},
      {field: 'mailoutFieldSetCode', headerName: 'Mailout Field Set Code'},
      {
        field: 'mailoutDatetime',
        headerName: 'Mailout Datetime',
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {field: 'remoteTaskId', headerName: 'Remote Task ID'},
      {field: 'mailoutHistory', headerName: 'Mailout History'},
      {
        field: 'firstMailingDate', headerName: 'First Mailing Date',
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'lastMailingDate', headerName: 'Last Mailing Date',
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {field: 'lastMailingStatus', headerName: 'Last Mailing Status', cellRenderer: StatusColumnComponent},
      {field: 'timesMailed', headerName: 'Times Mailed'},
    ]
  },
  {
    headerName: 'Invalidations', children: [
      {field: 'invalidateRatings', headerName: 'Invalidate Ratings', cellRenderer: CircleCheckColumnComponent},
      {field: 'invalidateVerbatim', headerName: 'Invalidate Verbatim?', cellRenderer: CircleCheckColumnComponent}
    ]
  },
  {
    headerName: 'Tracking', children: [

      {field: 'pmNotes', headerName: 'PM Notes', hide: false, filterId: 'pn_f'},
      {field: 'respondentCompanyPriority', headerName: 'Respondent Company Priority'},
      {field: 'notesToPM', headerName: 'Notes To PM'},
      {field: 'ratingFrequency', headerName: 'Rating Frequency'},
      {field: 'surveyCommentCount', headerName: 'Survey Comment Count'},
    ]
  },
  {
    headerName: 'Response Files', children: [
      {field: 'responseFilesStatus', headerName: 'Response Files Status', cellRenderer: StatusColumnComponent},
      {
        field: 'responseFileForInterviewersCreatedDatetime',
        headerName: 'Response File For Interviewers Created Datetime',
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'responseFilesForRespondentsCreatedDatetime',
        headerName: 'Response Files For Respondents Created Datetime',
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'responseFileForInterviewers',
        headerName: 'Response File For Interviewers',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.responseFileForInterviewers,
            type: 'link',
          };
        }
      },
      {
        field: 'responseFileForRespondents',
        headerName: 'Response File For Respondents',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.responseFileForRespondents,
            type: 'link',
          };
        }
      },
      {field: 'responseFilePassword', headerName: 'Response File Password'},
    ]
  },
  {
    headerName: "ID's", children: [
      {field: 'surveyId', headerName: 'Survey ID'},
      {field: 'surveyResponseId', headerName: 'Survey Response ID'}
    ]
  }
]
