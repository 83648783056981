import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {TransferRunService} from "@data/transfer-run/transfer.run.service";
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from "@angular/material/paginator";
import {AgSidePanelComponent} from "@shared/ag-side-panel/ag-side-panel.component";
import {Util} from "@data/util/util";
import {ActivatedRoute} from "@angular/router";
import {ExtendedTransferRun} from "@data/interefaces/extended.data.interfaces";
import {TransferRunStatus} from "@data/enums/data.enums";
import {AgConfirmationDialogComponent} from "@shared/ag-confirmation-dialog/ag-confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {TableBaseComponent} from "@pages/table.base.component";
import {pageContext, stopClickAction, tableColumns, transferCodeClickAction} from "@pages/transfer-run/transfer-run.component.ds";
import {FilterChangedEvent, SelectionColumnDef} from "@ag-grid-community/core";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'app-transfer-run',
  templateUrl: './transfer-run.component.html',
  styleUrls: ['./transfer-run.component.scss'],
  providers: [AgGridService]
})
export class TransferRunComponent extends TableBaseComponent implements AfterViewInit {

  dataSource: ExtendedTransferRun[] = [];
  auxLinks: Map<string, string>;
  isFilterActive: boolean = false;
  refreshComplete: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild("configDetailsSidePanel") configDetailsSidePanel: AgSidePanelComponent | null = null;
  @ViewChild("filterSidePanel") filterSidePanel: AgSidePanelComponent | null = null;
  @ViewChild("hideShowColumnsPanel") hideShowColumnsPanel: AgSidePanelComponent | null = null;
  @ViewChild(MatSort, {static: false}) sort!: MatSort;
  durationMessage: string = "Format of: 'HH:mm:ss'";
  selectedTransferCode: string | undefined;
  protected readonly util = Util;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns

  constructor(private _activatedRoute: ActivatedRoute, private _transferService: TransferRunService,
              public _dialog: MatDialog) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.updatePageContext();
    this.displayProgressBar(true);
  }

  ngAfterViewInit() {
    this._transferService.getTransferList().then(result => {
      this.addTransferListToDataSource(result as ExtendedTransferRun[]);
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => this.displayProgressBar(false));
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this));
  }

  onTransferCodeSelect(transferCode: string) {
    this.selectedTransferCode = transferCode;
    this.configDetailsSidePanel?.toggle()
  }

  async refreshView() {
    this.refreshComplete = false;
    this.displayProgressBar(true);
    await this._transferService.getTransferList().then(result => {
      this.addTransferListToDataSource(result as ExtendedTransferRun[]);
    }).catch(error => {
      this.displayNotificationMessage('error', JSON.stringify(error))
    }).finally(() => {
      this.refreshComplete = true;
      this.displayProgressBar(false);
    });
  }

  toggleHideShowColumns() {
    this.hideShowColumnsPanel?.toggle();
  }

  populateIsLatest(transfers: ExtendedTransferRun[]) {
    const transferNamesMap = new Map<string, Date>();

    // Iterate through transferRuns and find the latest startDatetime for each transferName
    for (const transfer of transfers) {
      const currentStartDatetime = transfer.startDatetime;
      const currentTransferCode = transfer.transferTypeCode;

      if (!transferNamesMap.has(currentTransferCode)) {
        // If the transferName is not in the map, add it
        transferNamesMap.set(currentTransferCode, currentStartDatetime);
      } else {
        // If the transferName is in the map, check if the current startDatetime is later
        const latestStartDatetime = transferNamesMap.get(currentTransferCode);
        if (latestStartDatetime !== undefined) {
          if (currentStartDatetime > latestStartDatetime) {
            // If the current startDatetime is later, update the map
            transferNamesMap.set(currentTransferCode, currentStartDatetime);
          }
        }
      }
    }

    // et the isLatest property based on the map
    for (const run of transfers) {
      const currentStartDatetime = run.startDatetime;
      const currentTransferCode = run.transferTypeCode;

      run.isLatest = currentStartDatetime === transferNamesMap.get(currentTransferCode);
    }
  }

  onStopTransferClick(transferRun: ExtendedTransferRun) {
    const dialogRef = this._dialog.open(AgConfirmationDialogComponent, {
      data: {
        title: "Confirm", message: "Are you sure you want to stop" +
          " the transfer '" + transferRun.transferId + "' ?"
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.displayProgressBar(true);
        this._transferService.stopTransfer(transferRun.transferId).then(result => {
          if (result.status) {
            // immediately set status to stop to not allow accidental double clicks.
            transferRun.transferState = TransferRunStatus.STOPPED;
            this.displayNotificationMessage('success', `Transfer ${transferRun.transferId} ${TransferRunStatus.STOPPED}.`)
            // let refresh get proper status.
            this.refreshView();
          }
          this.displayProgressBar(false);
        }).catch(error => {
            this.displayNotificationMessage('error', JSON.stringify(error));
            this.displayProgressBar(false);
          }
        );
      }
    })
  }

  protected override updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }

  protected override getSelectionColumnDef(): SelectionColumnDef {
    return {};
  }

  protected override initAfterGridReady() {
    this.gridApi.addGlobalListener((eventType: any, event: any) => {
      switch (eventType) {
        case stopClickAction: {
          this.onStopTransferClick(event.detail.rowData);
          break;
        }
        case transferCodeClickAction: {
          this.onTransferCodeSelect(event.detail.rowData.transferTypeCode);
          break;
        }
      }
    });
  }

  private addTransferListToDataSource(results: ExtendedTransferRun[]) {
    this.populateIsLatest(results);
    this.dataSource = results.map(item => {
      item.duration = Util.calculateDurationInSeconds(item.startDatetime, item.endDatetime);
      return item;
    });
  }

}
