<div [showSkeleton]="showSkeleton" agSkeleton class="transfer-run-detail-messages-details" skeletonType="grid-4x1">
  <div class="message-details-container flex-row flex-g-s">
    <ag-datablock data="{{this.transferRunLog?.transferDetailId}}" title="Message Code"></ag-datablock>
    <ag-datablock data="{{this.transferRunLog?.detailLevel}}" title="Log Level"></ag-datablock>
    <ag-datablock data="{{this.transferRunLog?.detailMessage}}" title="Message"></ag-datablock>
    <button (click)="onCopyClipboardClick(this.transferRunLog?.detailMessage)" class="copy-clipboard-button" color="primary" mat-raised-button>
      <mat-icon class="mat-mdc-tooltip-trigger fa-sharp fa-light fa-copy"></mat-icon>
      Copy to clipboard
    </button>
    <ag-datablock data="-" title="Stack Trace"></ag-datablock>
    <button (click)="onCopyClipboardClick(this.transferRunLog?.detailMessage)" [disabled]='true' class="copy-clipboard-button" color="primary" mat-raised-button>
      <mat-icon class="mat-mdc-tooltip-trigger fa-sharp fa-light fa-copy"></mat-icon>
      Copy to clipboard
    </button>
  </div>
</div>
