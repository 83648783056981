import { Component } from '@angular/core';
import {
  IFloatingFilter, IFloatingFilterParams, NumberFilterModel
} from "ag-grid-enterprise";
import {AgFrameworkComponent} from "ag-grid-angular";

@Component({
  selector: 'app-reset-filter',
  templateUrl: './reset-filter.component.html',
  styleUrls: ['./reset-filter.component.scss']
})
export class ResetFilterComponent implements IFloatingFilter, AgFrameworkComponent<IFloatingFilterParams> {

  protected params!: IFloatingFilterParams;
  protected buttonText = "Clear All Filters";

  agInit(params: IFloatingFilterParams): void {
    this.params = params;
  }

  protected clearFilters() {
    this.params.api.setFilterModel(null);
  }

  protected isAnyFilterActive() {
    return this.params.api.getFilterModel() !== null
      && this.params.api.getFilterModel() !== undefined
      && Object.keys(this.params.api.getFilterModel()).length !== 0;
  }

  onParentModelChanged(parentModel: NumberFilterModel): void {
  }

}
