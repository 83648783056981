import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {AutomatedReport, DownloadRequest, DownloadRequestFile} from "@data/interefaces/download.request.interfaces";

@Injectable({
  providedIn: 'root'
})
export class DownloadRequestService {

  constructor(private _apiService: ApiService) {
  }

  public async getAllDownloadRequests(): Promise<DownloadRequest[]> {
    return await this._apiService.makeGetServiceCall<DownloadRequest[]>('/v2/api/download-request');
  }

  public async getAutomatedReports(): Promise<AutomatedReport[]> {
    return await this._apiService.makeGetServiceCall<AutomatedReport[]>('/v2/api/download-request/automated');
  }

  public async getOneDownloadRequest(downloadRequestId: number): Promise<DownloadRequest> {
    return await this._apiService.makeGetServiceCall<DownloadRequest>(`/v2/api/download-request/${downloadRequestId}`);
  }

  public async create(downloadrequest: any): Promise<DownloadRequest> {
    return this._apiService.makePostServiceCall<DownloadRequest>('/v2/api/download-request', null, downloadrequest);
  }

  public async getAllDownloadRequestFilesOfOneDownloadRequest(downloadRequestId: number): Promise<DownloadRequestFile[]> {
    return await this._apiService.makeGetServiceCall<DownloadRequestFile[]>(`/v2/api/download-request/files/${downloadRequestId}`);
  }

  public updateDownloadRequestForPriority(downloadRequestIdList: any): void {
    return;
  }
}
