import {Component, Input} from '@angular/core';
import {FeatureComponentMode, YesNoValue} from "@data/enums/data.enums";
import {FormControl, FormGroup} from "@angular/forms";
import {BatchProcessResult, ProductRespondentCompany} from "@data/interefaces/data.interfaces";
import {IDatasource} from "ag-grid-enterprise";
import {RespondentCompanyService} from "@data/product-spec/respondent.company.service";
import {
  displayTableColumns,
  ProductRespondentCompanyWithProcessStatus
} from "@pages/product-spec/product-assignment/respondent-company/respondent-company-bulk/respondent-company-bulk.component.ds";
import {MatSelectChange} from "@angular/material/select";
import {BulkBaseComponent} from "@pages/bulk.base.component";

@Component({
  selector: 'app-respondent-company-bulk',
  templateUrl: './respondent-company-bulk.component.html',
  styleUrls: ['./respondent-company-bulk.component.scss']
})
export class RespondentCompanyBulkComponent extends BulkBaseComponent {

  @Input() selectedRows: ProductRespondentCompany[] = [];
  formGroup!: FormGroup<{ [K in keyof ProductRespondentCompany]?: FormControl<any> }>;
  data: ProductRespondentCompanyWithProcessStatus[] = [];
  dataSource: IDatasource = {
    getRows: (params) => {
      const startRow = params.startRow;
      const endRow = params.endRow;

      // Simulate a call to get data from the passed array
      const data = this.getLocalData(startRow, endRow);
      params.successCallback(data.rows, data.totalCount);
    }
    // getRows: function (params: any) {
    //   console.log('ds -> get rows');
    //   params.rows = [];
    // }
  }
  protected readonly tableColumns = displayTableColumns;

  constructor(private _respondentCompanyService: RespondentCompanyService) {
    super();
  }

  returnRows() {
    return this.selectedRows;
  }

  getLocalData(startRow: number, endRow: number) {
    const rowsThisPage = this.data.slice(startRow, endRow);
    const totalCount = this.data.length;
    return {
      rows: rowsThisPage,
      totalCount: totalCount
    };
  }

  override ngOnInit() {
    super.ngOnInit();
    this.displayProgressBar(true);
    this.initFormGroup();
    this.initFieldsByFeatureComponentMode();
    this.displayProgressBar(false);
  }

  initFormGroup() {
    this.formGroup = new FormGroup<{ [K in keyof ProductRespondentCompany]?: FormControl<any> }>(
      {
        include: new FormControl({
          value: ' ',
          disabled: false
        }),
        respondentCompanyReportLabelDefaultLocale: new FormControl({
          value: null,
          disabled: true
        })
      });
  }

  uploadFinished(event: { status: number; message: string; file?: File }) {
    this.processUploadedFile<ProductRespondentCompanyWithProcessStatus>(event);
  }

  getFormControl(controlName: (keyof ProductRespondentCompany)) {
    return this.formGroup.get(controlName) as FormControl<any>;
  }

  protected override startBatchProcess(selectedRows: ProductRespondentCompany[]): Promise<any> {
    return this._respondentCompanyService.startRespCompanyBatchProcess(selectedRows);
  }


  protected override initFieldsByFeatureComponentMode() {
    switch (this.featureComponentMode) {
      case FeatureComponentMode.BULK_INCLUDE:
        this.title = "Product Respondent Company Bulk Include / Exclude";
        this.subtitle = "Product Respondent Company Bulk Include / Exclude Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        break;
      case FeatureComponentMode.UPLOAD:
        this.title = "Product Respondent Company Bulk Upload";
        this.subtitle = "Product Respondent Company Bulk Upload Configuration";
        this.submitButtonText = "Start Upload";
        this.updateStage = 1;
        break;
      default:
        break;
    }
  }

  protected override validateRowData<T>(rowData: T[]) {
    let returnList: T[] = [];
    rowData.map(element => {
      returnList.push(element);
      // if ((element.period && !element.surveyInviteeId) || (!element.responseCode && element.surveyInviteeId)) {
      //   element.processingNote = 'To add, response code and survey code both should be null, for update, both should be provided';
      //   returnList.push(element);
      // }
    });

    return returnList;
  }

  protected onIncludeChanged(event: MatSelectChange) {
    this.applyEditRules();
  }

  private applyEditRules() {
    this.toggleFormControl(this.getFormControl('respondentCompanyReportLabelDefaultLocale'), this.getIncludeEditRule());
  }

  toggleFormControl(formControl: FormControl, enable: boolean) {
    return enable ? formControl.enable() : formControl.disable();
  }

  protected getIncludeEditRule() {
    let includeValue = this.getFormControl('include').value;
    return includeValue != undefined && includeValue === YesNoValue.YES_VALUE;
  }

  protected override getBatchProcessResultByType(): Promise<BatchProcessResult> {
    return this._respondentCompanyService.getLatestRespondentCompanyBatchProcess();
  }

}
