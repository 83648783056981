import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {map, Observable, startWith} from "rxjs";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'ag-search-dropdown',
  templateUrl: './ag-search-dropdown.component.html',
  styleUrls: ['./ag-search-dropdown.component.scss']
})
export class AgSearchDropdownComponent implements OnInit, OnChanges {

  @Input() label: string = '';
  @Input() displayValuePropertyName: string = '';
  @Input() dataSource: any[] = [];
  @Input() showAllOption: boolean = true;
  @Output() itemsSelected = new EventEmitter<any[]>();
  searchFormControl: FormControl<any> = new FormControl<any>('');
  filteredItems$: Observable<any>;
  selectedItems = new SelectionModel<any>(true);

  constructor() {
    this.filteredItems$ = this.searchFormControl.valueChanges.pipe(startWith(''), map(value => this.filterSearchOptions(value || '')));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataSource']) {
      this.dataSource = changes['dataSource'].currentValue;
      // if there are no items in the dataSource, disable the control, otherwise enable
      if (this.dataSource.length === 0) {
        this.selectedItems.clear();
        this.searchFormControl.disable({onlySelf: true});
      } else {
        this.searchFormControl.enable({onlySelf: true});
      }
      this.triggerSearchFromControlValueChangeEvent(this.dataSource);
    }
  }

  autoCompleteDisplayValue(item: any): string {
    // If the selected value is an object, display the 'item' property
    if (typeof item === 'string') {
      return item;
    }
    return item ? item[this.displayValuePropertyName] : '';
  }

  onAutoCompleteMatOption(event: MouseEvent, item: any) {
    // this method, with the mat-option-span class allows the dropdown to stay open when there is text in the autocompleted input
    event.stopPropagation();
    this.handleOnClickChange(item);
  }

  onAutoCompleteSelectionChanged(item: any) {
    this.handleOnClickChange(item);
  }

  onAutoCompleteCheckboxClicked(event: MouseEvent) {
    // When there is no text in the the matInput then this method is called.
    // This means that we need to stopPropagation here in order for the dropdown window stays open
    event.stopPropagation();
  }

  clearInputValue(event: Event) {
    this.searchFormControl.setValue('');
  }

  hasValue() {
    return !!this.searchFormControl.getRawValue()
  }

  isSelected(item: any) {
    return this.selectedItems.hasValue() && this.selectedItems.isSelected(item);
  }

  selectItemToggle(item: any) {
    this.selectedItems.toggle(item);
  }

  partiallySelected() {
    return this.dataSource.some(item => this.selectedItems.isSelected(item));
  }

  allSelected() {
    return this.dataSource.every(item => this.selectedItems.isSelected(item));
  }

  toggleSelectAll() {
    if (this.allSelected()) {
      this.dataSource.forEach(item => this.selectedItems.deselect(item));
    } else {
      this.dataSource.forEach(item => {
        if (!this.selectedItems.isSelected(item)) {
          this.selectedItems.select(item);
        }
      });
    }
    this.itemsSelected.emit(this.selectedItems.selected);
  }

  hasItems() {
    return this.dataSource && this.dataSource.length > 0;
  }

  private filterSearchOptions(value: any) {
    if (typeof value === 'string') {
      let filteredItems: any[];
      filteredItems = this.dataSource.filter(item => item[this.displayValuePropertyName].toString()?.toLowerCase().includes(value.toLowerCase()));
      return filteredItems;
    } else {
      console.warn('ag-search-dropdown - unexpected type - ' + (typeof value));
    }

    return this.dataSource;
  }

  private handleOnClickChange(item: any) {
    this.selectedItems.toggle(item);
    this.itemsSelected.emit(this.selectedItems.selected);
  }

  private triggerSearchFromControlValueChangeEvent(data: any[]) {
    // Manually trigger valueChanges by setting the current value to itself -- This will ensure the
    // search form control has the latest data source.
    // if data.length is 0, it means everything is unselected, to reset the control.
    this.searchFormControl.setValue(data.length === 0 ? '' : this.searchFormControl.value || '', {emitEvent: true});
  }

}
