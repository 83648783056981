import {Component} from '@angular/core';
import {GridApi, ICellRendererParams} from "ag-grid-enterprise";

@Component({
  selector: 'app-select-header',
  templateUrl: './select-header.component.html',
  styleUrls: ['./select-header.component.scss']
})
export class SelectHeaderComponent {
  params!: any;
  gridApi!: GridApi;
  isChecked = false;
  allSelected: boolean = false;
  isIndeterminate: boolean = false;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.gridApi = params.api;
    this.updateCheckboxState();
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.updateCheckboxState();
    return false;
  }

  someSelected(): boolean {
    return this.gridApi.getSelectedRows().length > 0 && !this.allSelected;
  }

  checkForAllSelected() {
    return this.gridApi.getSelectedRows().length > 0 && this.gridApi.getSelectedNodes().length === this.gridApi.getDisplayedRowCount();
  }

  checkForAlLSelectedOnCurrentPage() {

    return this.gridApi.getSelectedRows().length > 0 && this.getCountOfCurrentlySelectedItemsOnCurrentPage() === this.gridApi.getDisplayedRowCount();
  }

  selectAll() {
    this.calculateHeaderCheckBoxState();
    this.updateCheckboxState();
  }

  private calculateHeaderCheckBoxState() {
    // if all are selected, deselect all
    if (this.checkForAllSelected()) {
      this.deselectAllRows();
      return;
    }

    // if all on the current page are selected, selected all records
    if (this.checkForAlLSelectedOnCurrentPage()) {
      this.selectAllRowsOnCurrentPage();
      return;
    }

    // if the current selected items is less then the number of items of the paginated page size then select the current page only
    if (this.getCountOfCurrentlySelectedItemsOnCurrentPage() < this.gridApi.paginationGetPageSize()) {
      this.selectCurrentPageRows();
      return;
    }

    // default to selectAll
    this.selectAllFilteredRows();
  }

  private getCountOfCurrentlySelectedItemsOnCurrentPage() {
    const currentPageStart = this.gridApi.paginationGetPageSize() * this.gridApi.paginationGetCurrentPage();
    const currentPageEnd = currentPageStart + this.gridApi.paginationGetPageSize();
    return this.gridApi
      .getSelectedNodes()
      .filter((node) => node.rowIndex !== undefined && node.rowIndex !== null && node.rowIndex >= currentPageStart && node.rowIndex < currentPageEnd)
      .length;
  }

  private deselectAllRows() {
    this.allSelected = false;
    this.gridApi.deselectAllFiltered();
  }

  private selectCurrentPageRows() {
    this.allSelected = false;
    this.gridApi.selectAllOnCurrentPage();
  }

  private selectAllFilteredRows() {
    this.allSelected = true;
    this.gridApi.selectAllFiltered();
  }

  private selectAllRowsOnCurrentPage() {
    this.gridApi.selectAllOnCurrentPage();
  }

  private updateCheckboxState() {
    if (this.checkForAllSelected()) {
      this.isIndeterminate = false;
      this.allSelected = true;
      this.isChecked = this.allSelected;
    } else if (this.someSelected()) {
      this.isIndeterminate = true;
      this.allSelected = false;
      this.isChecked = false;
    } else {
      this.isChecked = this.allSelected;
      this.isIndeterminate = false;
    }
  }

}
