import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function mailoutValidator(...mailoutControls: (AbstractControl | undefined)[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const anyHasValue = mailoutControls.some(control => control && control.value);
    if (anyHasValue) {
      const allHaveValue = mailoutControls.every(control => control && control.value);
      if (allHaveValue) {
        mailoutControls.forEach(control => {
          if (control) {
            control.setErrors(null);
          }
        });
      } else {
        mailoutControls.forEach(control => {
          if (control) {
            control.setErrors({'mailoutValid': false});
          }
        });
      }
      return allHaveValue ? null : {mailoutValid: false}
    } else {
      return null;
    }
  }
}
