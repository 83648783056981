<div [showSkeleton]="loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <mat-card *ngIf="!loading" class="task-component-detail backdrop" id="event-processor-detail">
    <mat-card-header class="mg-b-m">
      <div class="header-title">
        <mat-card-title class="primary-font-color">Event Processors</mat-card-title>
        <mat-card-subtitle>Manage Event Processor</mat-card-subtitle>
      </div>
    </mat-card-header>
    <mat-card-content>
      <form id="eventTypeForm" class="form-field" (ngSubmit)="this.save()" [formGroup]="formGroup">
        <div class="flex-row view-only-container mg-b-m">
          <mat-form-field>
            <mat-label>Processor Code</mat-label>
            <input formControlName="eventProcessorCode" matInput value="{{eventProcessor?.eventProcessorCode}}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Event Type Code</mat-label>
            <input formControlName="eventType" matInput value="{{eventProcessor?.eventType}}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Application Status</mat-label>
            <input formControlName="applicationStatus" matInput value="{{eventProcessor?.applicationStatus}}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Processing Status</mat-label>
            <input formControlName="processorStatus" matInput value="{{eventProcessor?.processorStatus}}">
          </mat-form-field>
        </div>
        <div class="flex-row view-only-container mg-b-m">
          <mat-form-field>
            <mat-label>Events In Queue</mat-label>
            <input formControlName="eventsInQueue" matInput value="{{eventProcessor?.eventsInQueue}}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Queue Size</mat-label>
            <input formControlName="queueSize" matInput value="{{eventProcessor?.queueSize}}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Events In Progress</mat-label>
            <input formControlName="eventsInProgress" matInput value="{{eventProcessor?.eventsInProgress}}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Thread Pool Size</mat-label>
            <input formControlName="threadPoolSize" matInput value="{{eventProcessor?.threadPoolSize}}">
          </mat-form-field>
        </div>
        <div class="flex-row view-only-container mg-b-m">
          <mat-form-field>
            <mat-label>Event Processor Name</mat-label>
            <input formControlName="eventProcessorName" matInput value="{{eventProcessor?.eventProcessorName}}">
          </mat-form-field>
        </div>
        <div class="flex-row custom-container mg-b-m">
          <div class="category-container">
            <mat-form-field class="mg-b-m">
              <mat-label>Description</mat-label>
              <textarea formControlName="description" matInput rows="5"></textarea>
            </mat-form-field>
          </div>
          <div class="category-container">
            <mat-form-field class="mg-b-m">
              <mat-label>Parameters</mat-label>
              <textarea formControlName="parameters" matInput rows="5"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-row flex-g-s action-buttons mg-b-m">
          <button color="primary" mat-raised-button type="submit">
            <mat-icon class="material-symbols-outlined">save</mat-icon>
            Save
          </button>
          <button *ngIf="displayStartButton()" (click)="start()" color="accent" mat-raised-button type="button">
            <mat-icon class="material-symbols-outlined">play_arrow</mat-icon>
            Start Processing
          </button>
          <button *ngIf="displayStopButton()" (click)="stop()" color="warn" mat-raised-button type="button">
            <mat-icon class="material-symbols-outlined">stop</mat-icon>
            Stop Processing
          </button>
          <button (click)="this.close()" mat-button type="button">
            <mat-icon class="material-symbols-outlined">close</mat-icon>
            Close
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
