import {Component, OnChanges, OnInit} from '@angular/core';
import {TreeSelectionChanged} from "@shared/ag-checkbox-tree/ag-checkbox-tree.component.ds";
import {SelectionChangeType} from "@data/enums/data.enums";
import {ReportType, RespondentSegmentLabel, SubjectLabel, WeightedProductLabel} from "@data/interefaces/product.interfaces";
import {AgCheckboxTreeService} from "@shared/ag-checkbox-tree/services/ag-checkbox-tree.service";
import {AgCheckboxListService} from "@shared/ag-checkbox-list/services/ag-checkbox-list.service";
import {DownloadRequestData, ViewDownloadRequest} from "@data/interefaces/download.request.interfaces";
import {CheckboxListSelectionChanged} from "@shared/ag-checkbox-list/ag-checkbox-list.component.ds";
import {AdvantageReportBaseComponent} from "@pages/download-request/download-request-add/components/advantage-report.base.component";

@Component({
  selector: 'advantage-report',
  templateUrl: './advantage-report.component.html',
  styleUrls: ['./advantage-report.component.scss'],
  providers: [AgCheckboxTreeService, AgCheckboxListService]
})
export class AdvantageReportComponent extends AdvantageReportBaseComponent implements OnChanges, OnInit {

  selectedProductsToSponsorsMap: Map<WeightedProductLabel, SubjectLabel[]> = new Map();
  selectedRespondents: RespondentSegmentLabel[] = [];
  respondentsList: RespondentSegmentLabel[] = [];
  sponsorsToRespondentsMap: Map<SubjectLabel, RespondentSegmentLabel[]> = new Map(); // master list of the sponsors to all of their respondents
  selectedSponsorsToSelectedRespondentsMap: Map<SubjectLabel, RespondentSegmentLabel[]> = new Map(); // the list of sponsor to only the respondents they've selected

  constructor(private _checkboxListService: AgCheckboxListService) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  async onCheckBoxTreeSelectionChanged(event: TreeSelectionChanged) {
    if (event.changeType === SelectionChangeType.ADD) {
      // Adding records and make the service call.
      const selectedSponsors: SubjectLabel[] = [];
      // we only want to focus on the events in this event, and not from the entire list from selectedSponsors
      event.childrenItems.forEach(item => {
        // Add the subject id to the array so we can use it in the service call.
        selectedSponsors.push(item);
        // Check to make sure the selected item doesn't already exist.  If it does, add it.
        if (!this.checkForMatchingSelectedSponsorInMap(this.sponsorsToRespondentsMap, item)) {
          this.sponsorsToRespondentsMap.set(item, []);
        }
      });

      if (this.selectedDownloadType?.requiresRespondents) {
        this.displayProgressBar(true);
        const subjectIdsToRetrieveRespondentsFor = selectedSponsors.map(item => item.subjectId);
        await this._pptDownloadService.getRespondentSegmentLabels(event.parentItem.productPeriodId, event.parentItem.productLocale, subjectIdsToRetrieveRespondentsFor.join(',')).then(
          result => {
            // Add to the respondents list the results.  We want to track what's already in there by it's label and code.  If we don't find it,
            // add it directly to the list.  If we do find it, we want to ensure the subjectId's are updated to reflect the new list coming in
            result.forEach(item => {
              this.processSelectedSponsorsToIncomingRespondent(selectedSponsors, item);
              this.processSelectedSponsorsToCurrentlySelectedRespondents(selectedSponsors, item);
            });

            // create respondents list here
            this.createRespondentsList();
            this.createAgCheckBoxListWithSelectedValues();
            this.displayProgressBar(false);
          }).catch(error => {
          this.displayProgressBar(false);
          this.displayNotificationMessage('error', JSON.stringify(error));
        });
      }
    } else {
      // Remove from the selectedSponsors list
      event.childrenItems.forEach(item => {
        this.removeSponsorFromSelectedSponsors(item);
        this.removeSponsorFromRespondentsList(item);
      });

      // redraw the tree.
      this.createRespondentsList();
      this.createAgCheckBoxListWithSelectedValues();
    }
    this.emitIsValidSelectionData();
    this.addOrUpdateDownloadRequest(event.changeType);
  }

  onCheckBoxListSelectionChanged(event: CheckboxListSelectionChanged) {
    if (event.changeType === SelectionChangeType.ADD) {
      event.items.forEach(item => {
        this.processAndAddOrUpdateSelectedRespondent(item.tag);
      });
      this.selectedRespondents.sort((a, b) => a.respondentSegmentId - b.respondentSegmentId);
    } else if (event.changeType === SelectionChangeType.DELETE) {
      event.items.forEach(item => {
        this.processAndDeleteSelectedRespondent(item.tag);
      });
    } else if (event.changeType === SelectionChangeType.REFRESH) {
      // do nothing for now
    }
    this.emitIsValidSelectionData();
    this.addOrUpdateDownloadRequest(event.changeType);
  }

  canShowSponsorSelection() {
    return this.selectedProducts.length > 0
  }

  canShowRespondentSelection() {
    return this.sponsorsToRespondentsMap.size > 0 && this.selectedDownloadType?.requiresRespondents;
  }

  public setDownloadRequestDashboardVersion(downloadRequest: DownloadRequestData, standardReportTypeId: number) {
    const standardReportType = this.getStandardReportTypeById(standardReportTypeId);
    const periodString = standardReportType.period.toString();
    downloadRequest.dashboardPrefix = `${periodString.substring(periodString.length - 2)}-`;
  }

  public setDownloadRequestHighlightSegment(downloadRequest: DownloadRequestData, standardReportTypeId: number) {
    const standardReportType = this.getStandardReportTypeById(standardReportTypeId);
    if (standardReportType.period === 2022 || standardReportType.period === 2023) {
      downloadRequest.highlighted = '0';
    } else {
      downloadRequest.highlighted = '1';
    }
  }

  protected override resetAllData() {
    this.selectedProductsToSponsorsMap.clear();
    this.sponsorsToRespondentsMap.clear();
    this.selectedSponsorsToSelectedRespondentsMap.clear();
    this.respondentsList = [];
    this.selectedRespondents = [];
    this.isValidSelectionData.emit(false); // emit false on reset
    this.createAgCheckBoxTreeWithSelectedValues(this.selectedProductsToSponsorsMap, SelectionChangeType.DELETE);
    this.createAgCheckBoxListWithSelectedValues();
  }

  protected override addOrUpdateDownloadRequest(changeType: SelectionChangeType) {
    const downloadRequests = this.createNewDownloadRequestParams();
    // I have the parameters now I need to map them to the grids dataSource
    this.pendingDownloadRequests.next({items: downloadRequests, changeType: changeType});
  }

  protected override async handleSelectedProductsChanged(value: WeightedProductLabel[]) {
    if (value && Array.isArray(value) && value.length > 0) {
      const promises = value.map(async (product) => {
        if (!this.selectedProductsToSponsorsMap.has(product)) {
          // product not found in the list, add it
          this.displayProgressBar(true);
          const itemsToAdd: Map<WeightedProductLabel, SubjectLabel[]> = new Map();
          await this._pptDownloadService.getSubjectLabels(product.productPeriodId.toString(), product.productLocale, product.isNegativeProductViewId).then(result => {
            const orderedSubjectsByLabel = result.sort((a, b) => a.subjectLabel?.toLowerCase().localeCompare(b.subjectLabel?.toLowerCase()));
            itemsToAdd.set(product, orderedSubjectsByLabel);
            this.selectedProductsToSponsorsMap.set(product, result);
            this.createAgCheckBoxTreeWithSelectedValues(itemsToAdd, SelectionChangeType.ADD);
          }).catch(error => {
            this.displayNotificationMessage('error', JSON.stringify(error));
          }).finally(() => this.displayProgressBar(false));
        } else {
          // value returns a list of all items selected, so since we are removing items.. we need to remove everything from selectedProductsAndSponsors.key
          // where it's not in that list.
          const productPeriodsToKeep = new Set(value.map(v => v.productPeriodId));
          const itemsToRemove: Map<WeightedProductLabel, SubjectLabel[]> = new Map();
          Array.from(this.selectedProductsToSponsorsMap.entries()).forEach(([key, value]) => {
            if (!productPeriodsToKeep.has(key.productPeriodId)) {
              this.selectedProductsToSponsorsMap.delete(key);
              itemsToRemove.set(key, value);
              // remove the sponsors as well
              value.forEach(sponsor => {
                this.removeSponsorFromSelectedSponsors(sponsor);
                // I may also have to remove the respondents here as well.
                this.removeSponsorFromRespondentsList(sponsor);
              });
              this.createAgCheckBoxTreeWithSelectedValues(itemsToRemove, SelectionChangeType.DELETE);
              // since we are deleting this item, lets make sure it's not in the grid, if it is, we need to remove it there as well.
              this.addOrUpdateDownloadRequest(SelectionChangeType.DELETE);
            }
          });
        }
      });
      await Promise.all(promises);
      this.selectedProductsProcessed.emit();
    }
    // value is 0, which means we may have unselected all items, so lets make sure there are items still in the selectedProductsAndSponsors.
    if (value?.length === 0 && this.selectedProductsToSponsorsMap.size > 0) {
      // clear and update the tree if items are all unselected
      this.resetAllData();
      this.selectedProductsProcessed.emit();
    }
  }

  protected override async onRetryEvent(downloadRequestsToRetry: ViewDownloadRequest[]) {
    // Pre-select the product and sponsors.  Again, we await this so that we can properly select the respondents from the list
    await this.updateProductAndSponsorsTreeOnRetry(downloadRequestsToRetry);
    // Pre-select the respondents, we don't need to await as it's the last part of the chain
    this.updateRespondentsCheckListOnRetry(downloadRequestsToRetry);
  }

  /**
   * This method will check the map for the matching subject label.  This will not use the .has method, as the SubjectLabel
   * may not match because of the TransferData created datetime timestamp
   * @param map
   * @param sponsorToCheckFor
   * @private
   */
  private checkForMatchingSelectedSponsorInMap(map: Map<SubjectLabel, RespondentSegmentLabel[]>, sponsorToCheckFor: SubjectLabel) {
    for (const key of map.keys()) {
      if (this.compareSponsorForEquality(key, sponsorToCheckFor)) {
        return true;
      }
    }
    return false;
  }

  /**
   * This method will add the selected respondent to the selected sponsors to selected respondents map
   * @param selectedRespondent
   * @private
   */
  private processAndAddOrUpdateSelectedRespondent(selectedRespondent: RespondentSegmentLabel) {
    this.sponsorsToRespondentsMap.forEach((value, key) => {
      const actualRespondentToAddFromSelectedSponsorMap = value.find(r => this.compareRespondentForEquality(selectedRespondent, r));
      if (actualRespondentToAddFromSelectedSponsorMap) {
        if (this.checkForMatchingSelectedSponsorInMap(this.selectedSponsorsToSelectedRespondentsMap, key)) {
          const currentlySelectedRespondentsForSponsor = this.selectedSponsorsToSelectedRespondentsMap.get(key);
          if (currentlySelectedRespondentsForSponsor) {
            if (!currentlySelectedRespondentsForSponsor.some(cr => this.compareRespondentForEquality(selectedRespondent, cr))) {
              currentlySelectedRespondentsForSponsor.push(actualRespondentToAddFromSelectedSponsorMap);
            }
          }
        } else {
          this.selectedSponsorsToSelectedRespondentsMap.set(key, [actualRespondentToAddFromSelectedSponsorMap]);
        }
      }
    });
  }

  /**
   * This method will remove the respondent from the selected sponsors
   * @param selectedRespondent
   * @private
   */
  private processAndDeleteSelectedRespondent(selectedRespondent: RespondentSegmentLabel) {
    this.selectedSponsorsToSelectedRespondentsMap.forEach((value, key) => {
      const index = value.findIndex(r => this.compareRespondentForEquality(selectedRespondent, r));
      if (index !== -1) {
        value.splice(index, 1);
      }
    });
  }

  /**
   * This method will process the selected sponsors to ensure that the respondent is associated with it
   * @param selectedSponsors
   * @param respondent
   * @private
   */
  private processSelectedSponsorsToIncomingRespondent(selectedSponsors: SubjectLabel[], respondent: RespondentSegmentLabel) {
    // Search for any sponsors that matches any of the subject ids returned from the RespondentSegmentLabel result.
    const matchingSelectedSponsorsBySubjectId = selectedSponsors.filter(selectedSponsor =>
      respondent.subjectIds.some(subjectId => subjectId === selectedSponsor.subjectId));
    if (matchingSelectedSponsorsBySubjectId) {
      // There could be multiple matching sponsors, because a sponsor with a different productViewId could have the same subjectId.  So we will
      // iterate through this list to apply it to each matching one
      matchingSelectedSponsorsBySubjectId.forEach(matchingSelectingSponsor => {
        if (this.checkForMatchingSelectedSponsorInMap(this.sponsorsToRespondentsMap, matchingSelectingSponsor)) {
          // verify that the respondent is in the list.  If it's not in the list, add it
          let existingRespondents = this.sponsorsToRespondentsMap.get(matchingSelectingSponsor);
          if (existingRespondents) {
            if (!existingRespondents.some(existingRespondent => this.compareRespondentForEquality(existingRespondent, respondent))) {
              existingRespondents.push(respondent);
            }
          }
        } else {
          this.sponsorsToRespondentsMap.set(matchingSelectingSponsor, [respondent]);
        }
      });
    }
  }

  /**
   * This method will return true or false if any of the sponsors have this respondent selected
   * @param respondent
   * @private
   */
  private isRespondentCurrentlySelected(respondent: RespondentSegmentLabel) {
    for (const respondents of this.selectedSponsorsToSelectedRespondentsMap.values()) {
      if (respondents.some(r => this.compareRespondentForEquality(respondent, r))) {
        return true;
      }
    }
    return false;
  }

  /**
   * When a new sponsor is selected, this method will check to see if the respondent is selected.
   * If it is, it will associate this respondent with this selected sponsor
   * @param selectedSponsors
   * @param item
   * @private
   */
  private processSelectedSponsorsToCurrentlySelectedRespondents(selectedSponsors: SubjectLabel[], item: RespondentSegmentLabel) {
    if (this.isRespondentCurrentlySelected(item)) {
      // this respondent is selected, so we need to add this
      selectedSponsors.forEach(selectedSponsor => {
        const existingSelectedRespondents = this.selectedSponsorsToSelectedRespondentsMap.get(selectedSponsor) || [];
        if (!existingSelectedRespondents.some(r => this.compareRespondentForEquality(r, item))) {
          existingSelectedRespondents.push(item);
        }
        this.selectedSponsorsToSelectedRespondentsMap.set(selectedSponsor, existingSelectedRespondents);
      });
    }
  }

  private emitIsValidSelectionData() {
    this.isValidSelectionData.emit((this.sponsorsToRespondentsMap.size > 0 || this.selectedRespondents.length > 0));
  }

  private removeSponsorFromSelectedSponsors(sponsorToRemove: SubjectLabel) {
    this.sponsorsToRespondentsMap.delete(sponsorToRemove);
    // also remove from the existing selection map
    this.selectedSponsorsToSelectedRespondentsMap.delete(sponsorToRemove);
  }

  private compareSponsorForEquality(sponsor: SubjectLabel, sponsorToCompare: SubjectLabel) {
    return sponsor.subjectId === sponsorToCompare.subjectId
      && sponsor.productPeriodId === sponsorToCompare.productPeriodId
      && sponsor.productViewId === sponsorToCompare.productViewId;
  }

  private createAgCheckBoxTreeWithSelectedValues(items: Map<WeightedProductLabel, SubjectLabel[]>, changeType: SelectionChangeType) {
    this._checkboxTreeService.updateAgCheckBoxTreeFromMap(items, 'displayValue', 'subjectLabel', changeType);
  }

  private createAgCheckBoxListWithSelectedValues() {
    this._checkboxListService.createAgCheckboxList(this.respondentsList, 'respondentSegmentLabel');
  }

  private compareRespondentForEquality(originalRespondent: RespondentSegmentLabel, respondentToCompare: RespondentSegmentLabel) {
    return originalRespondent.respondentSegmentCode.toLowerCase() === respondentToCompare.respondentSegmentCode.toLowerCase()
      && originalRespondent.respondentSegmentLabel.toLowerCase() === respondentToCompare.respondentSegmentLabel.toLowerCase();
  }

  private createRespondentsList() {
    if (this.sponsorsToRespondentsMap) {
      const allCompaniesRespondents: RespondentSegmentLabel[] = []
      const remainingRespondents: RespondentSegmentLabel[] = []
      this.sponsorsToRespondentsMap.forEach(respondents => {
        respondents.forEach(respondent => {
          if (respondent.respondentSegmentId < 0) {
            if (!allCompaniesRespondents.some(r => this.compareRespondentForEquality(r, respondent))) {
              allCompaniesRespondents.push(respondent);
            }
          } else {
            if (!remainingRespondents.some(r => this.compareRespondentForEquality(r, respondent))) {
              remainingRespondents.push(respondent);
            }
          }
        });
      });
      let sortedAllCompanies: RespondentSegmentLabel[] = [];
      let sortedRespondents: RespondentSegmentLabel[] = [];
      if (allCompaniesRespondents) {
        sortedAllCompanies = allCompaniesRespondents.sort((a, b) =>
          a.respondentSegmentLabel.toLowerCase().localeCompare(b.respondentSegmentLabel.toLowerCase()));
      }
      if (remainingRespondents) {
        sortedRespondents = remainingRespondents.sort((a, b) =>
          a.respondentSegmentLabel.toLowerCase().localeCompare(b.respondentSegmentLabel.toLowerCase()));
      }
      this.respondentsList = [
        ...sortedAllCompanies,
        ...sortedRespondents
      ]
    }
  }

  private createNewDownloadRequestParams(): DownloadRequestData[] {
    // the Download request is all about the selected sponsors.  Each selected sponsors will be a new download request.
    const downloadRequests: DownloadRequestData[] = [];
    this.selectedSponsorsToSelectedRespondentsMap.forEach((respondents, selectedSponsor) => {
      // check the selectedSponsors to see if any respondents were selected.
      const selectedRespondents = respondents;
      // If there are some selected, we can proceed with creating the item.  If not, we don't want to add it yet as not all the
      // information is available to create a request.
      let selectedProduct: WeightedProductLabel | undefined;
      // const downloadRequest: DownloadRequestData = this.createDownloadRequestWithDefaultValues();
      const downloadRequest: DownloadRequestData = this._advantageReportService.createDownloadRequestWithDefaultValues(this.currentBatchCode, this.selectedPeriod, this.selectedMarket, this.selectedIndustry,
        this.selectedSurveySubject, this.selectedDownloadType, this.selectedLocale, this.originalBatchCodeFromRetry);
      for (const [key, value] of this.selectedProductsToSponsorsMap.entries()) {
        if (value.includes(selectedSponsor)) {
          selectedProduct = key;
          break;
        }
      }
      if (selectedProduct) {
        this._advantageReportService.setDownloadRequestProduct(downloadRequest, selectedProduct);
        this._advantageReportService.setDownloadRequestSponsor(downloadRequest, selectedSponsor);
        this.setDownloadRequestDashboardVersion(downloadRequest, selectedSponsor.standardReportTypeId);
        this.setDownloadRequestHighlightSegment(downloadRequest, selectedSponsor.standardReportTypeId);
        this.setDownloadRequestReportTypeValues(downloadRequest, selectedSponsor);
        this._advantageReportService.setDownloadRequestTableauProject(downloadRequest);
        this._advantageReportService.setDownloadRequestRespondents(downloadRequest, selectedRespondents);
        if ((selectedRespondents && selectedRespondents.length > 0) || !this.selectedDownloadType?.requiresRespondents) {
          downloadRequests.push(downloadRequest);
        }
      }
    });
    return downloadRequests;
  }

  private setDownloadRequestReportTypeValues(downloadRequest: DownloadRequestData, selectedSponsor: SubjectLabel) {
    const standardReportTypeId = selectedSponsor.standardReportTypeId;
    const matchingReportTypes = this.reportTypes.filter(reportType => reportType.standardReportTypeIds?.includes(standardReportTypeId));
    let matchingReportType: ReportType | undefined;

    // Multiple values returned, possible Full or Presentation selected
    if (matchingReportTypes.length > 1) {
      matchingReportType = matchingReportTypes.find(reportType =>
        reportType.automatedReportName.toLowerCase().includes(this.selectedDownloadType!.downloadRequestTypeValue.toLowerCase())
      );
    } else {
      matchingReportType = matchingReportTypes[0];
    }
    if (!matchingReportType) {
      // Couldn't find a matching automated report, lets try to find one based on my period and standard report type
      const matchingReportTypesByName = this.reportTypes.filter(reportType =>
        reportType.automatedReportName.toLowerCase().includes(this.selectedDownloadType!.downloadRequestTypeValue.toString().toLowerCase()));
      if (matchingReportTypesByName.length > 1) {
        // more then one matching type returned, lets filter by it's period
        const standardReportType = this.getStandardReportTypeById(standardReportTypeId);
        matchingReportType = matchingReportTypesByName.find(reportType =>
          reportType.versions.includes(standardReportType.period));
      } else {
        matchingReportType = matchingReportTypesByName[0];
      }
    }

    if (!matchingReportType) {
      throw new Error(`Unable to find a matching report type for download request: ${JSON.stringify(downloadRequest)} and the selected sponsor: ${JSON.stringify(selectedSponsor)}`);
    }
    downloadRequest.automatedReportId = matchingReportType.automatedReportId.toString();
    downloadRequest.automatedReportName = matchingReportType.automatedReportName;
    downloadRequest.automatedReportCode = matchingReportType.automatedReportCode;
  }

  private getStandardReportTypeById(standardReportTypeId: number) {
    const standardReportType = this.standardReportTypes.find(reportType => reportType.standardReportTypeId === standardReportTypeId);
    if (!standardReportType) {
      // Should only be a dev exception
      throw new Error(`Standard report type with ID: ${standardReportTypeId} not found.`);
    }
    return standardReportType;
  }

  private async updateProductAndSponsorsTreeOnRetry(downloadRequestsToRetry: ViewDownloadRequest[]) {
    // used the TreeSelectChange object to create the mapping.  This can be replaced with a proper map, I just thought it would be easier this way
    let itemsToSelectMap: TreeSelectionChanged[] = [];
    for (let downloadRequest of downloadRequestsToRetry) {
      const requestParams = JSON.parse(downloadRequest.downloadParameters ?? '');
      const productPeriodId = Number(requestParams.productperiodid);
      const productViewId = Number(requestParams.productviewid);
      const isNegativeProductViewId = productViewId < 0;
      const sponsorCode = downloadRequest.sponsor;
      for (const [product, subject] of this.selectedProductsToSponsorsMap) {
        if (product.productPeriodId === productPeriodId && product.isNegativeProductViewId === isNegativeProductViewId) {
          let treeSelectionChangedEvent = itemsToSelectMap.find(item => item.parentItem.productPeriodId === productPeriodId && item.parentItem.productViewId === productViewId);
          if (!treeSelectionChangedEvent) {
            treeSelectionChangedEvent = {
              parentItem: undefined,
              changeType: SelectionChangeType.ADD,
              childrenItems: []
            }
            itemsToSelectMap.push(treeSelectionChangedEvent);
          }
          treeSelectionChangedEvent.parentItem = product;
          const matchingSubject = subject.find(s => s.subjectCode === sponsorCode);
          if (matchingSubject) {
            treeSelectionChangedEvent.childrenItems.push(matchingSubject);
          }
        }
      }
    }
    for (const item of itemsToSelectMap) {
      this._checkboxTreeService.setSelectedItems(item.parentItem, item.childrenItems, 'displayValue', 'subjectCode', false);
      // Manually trigger the selection change, so we can await this functionality.  This allow us to ensure that the respondents list does not
      // get updated before this is process is done.  Without this, the UI will be rendering as we try to pre-select the respondents.
      await this.onCheckBoxTreeSelectionChanged(item);
    }
  }

  private updateRespondentsCheckListOnRetry(downloadRequestsToRetry: ViewDownloadRequest[]) {
    // Gets a list of all the respondents Ids from the download requests.
    let respondentsToSelect = [];
    for (let downloadRequest of downloadRequestsToRetry) {
      const requestParams = JSON.parse(downloadRequest.downloadParameters ?? '');
      const respondents = requestParams.respondentsegments;
      respondentsToSelect.push(...respondents);
    }
    // Create a distinct list of respondent Ids that are returned and convert it to a number
    let distinctRespondents = Array.from(new Set(respondentsToSelect.map(r => Number(r))));
    const respondentLabels = this.respondentsList.filter(r => distinctRespondents.some(id => r.respondentSegmentId === id));
    this._checkboxListService.setSelectedItems(respondentLabels, 'respondentSegmentId', true);
  }

  private removeSponsorFromRespondentsList(sponsor: SubjectLabel) {
    this.respondentsList.forEach(respondent => {
      const index = respondent.subjectIds.indexOf(sponsor.subjectId);
      if (index !== -1) {
        respondent.subjectIds.splice(index, 1);
        respondent.subjectIds.sort((a, b) => a - b);
      }
    });
  }
}
