// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  debounceTime: 500,
  agGridLicense: 'Using_this_{AG_Grid}_Enterprise_key_{AG-059635}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Advanatage_Group}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{ARM_Console}_only_for_{8}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{ARM_Console}_need_to_be_licensed___{ARM_Console}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{7_June_2025}____[v3]_[01]_MTc0OTI1MDgwMDAwMA==664499e59b5ca007bb427e4a8e144b1d'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.runJob`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
