import {Injectable} from '@angular/core';
import {ApiService} from "@core//api/api.service";
import {RespondentGroupPeriod} from "@data/interefaces/data.interfaces";

@Injectable({
  providedIn: 'root'
})
export class RespondentGroupPeriodService {

  constructor(private _apiService: ApiService) {
  }

  public getRespondentGroup(period: string, marketCode: string, industryCode: string, surveySubject: string): Promise<RespondentGroupPeriod[]> {
    return this._apiService.makeGetServiceCall<RespondentGroupPeriod[]>(`/v2/api/product-spec/res-group-period`,
      `period=${period}&marketCode=${marketCode}&industryCode=${industryCode}&surveySubject=${surveySubject}`);
  }

}
