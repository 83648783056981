<div [showSkeleton]="showSkeleton" agSkeleton class="transfer-config-detail" skeletonType="grid-5x3">
  <div [formGroup]="formGroup" class="details-container flex-row flex-g-s">
    <ag-datablock class="transfer-code mg-b-m" data="{{scheduleDetail?.code}}" title="Transfer Code"></ag-datablock>
    <ag-datablock class="transfer-name mg-b-m" data="{{scheduleDetail?.jobName}}" title="Transfer Name"></ag-datablock>
    <ag-datablock class="description mg-b-m" data="{{scheduleDetail?.description}}" title="Description"></ag-datablock>
    <ag-datablock class="mg-b-m" data="{{scheduleDetail?.status}}" title="Status"></ag-datablock>
    <ag-datablock class="mg-b-m" data="{{scheduleDetail?.source}}" title="Source"></ag-datablock>
    <ag-datablock class="mg-b-m" data="{{scheduleDetail?.lastRunDate | date:'short'}}" title="Last Run"></ag-datablock>
    <ag-datablock class="mg-b-m" data="{{scheduleDetail?.category}}" title="Transfer Category"></ag-datablock>
    <ag-datablock class="mg-b-m" data="{{scheduleDetail?.target}}" title="Target"></ag-datablock>
    <ag-datablock class="mg-b-m" data="{{scheduleDetail?.nextRunDate | date:'short'}}" title="Next Run"></ag-datablock>

    <div class="toggle flex-row">
      <ag-slide-toggle (toggleUpdate)="onToggleChanged($event)" [isChecked]="scheduleDetail?.scheduled == true" [isDisabled]="readonly"></ag-slide-toggle>
      <span class="mg-l-s">Is Scheduled</span>
    </div>
    <mat-form-field *ngIf="!readonly" class="schedule">
      <mat-label>Schedule</mat-label>
      <input [formControl]="scheduleFormControl" matInput value="{{scheduleDetail?.schedule}}">
      <mat-error *ngIf="scheduleFormControl.hasError('validationError')">
        {{ scheduleFormControl.getError('validationError') }}
      </mat-error>
    </mat-form-field>
    <ag-datablock *ngIf="readonly" class="schedule" data="{{scheduleDetail?.schedule}}" title="Schedule"></ag-datablock>
  </div>
  <div class="param-container">
    <ag-dynamic-panel (valueChange)="onDefaultRunConfigurationValueChange($event)" [fieldPerRow]="3"
                      [panelConfig]="scheduleDetail?.defaultRunConfiguration"></ag-dynamic-panel>
  </div>
</div>
