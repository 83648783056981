import {EventEmitter, Injectable} from '@angular/core';
import {ApplicationEvent, EventType} from "../interfaces/data.interfaces";

@Injectable({
  providedIn: 'root'
})
export class EventbusService {

  private emitterMap: Map<EventType, EventEmitter<ApplicationEvent>> = new Map<EventType, EventEmitter<ApplicationEvent>>()

  public register(eventType: EventType, next: (event: ApplicationEvent) => void) {
    let emitter = this.emitterMap.get(eventType);
    if (emitter == undefined) {
      emitter = new EventEmitter<ApplicationEvent>();
      this.emitterMap.set(eventType, emitter);
    }
    emitter.subscribe(next)
  }

  public fire(event: ApplicationEvent) {
    let emitter = this.emitterMap.get(event.type);
    if (emitter) {
      emitter.emit(event);
    }
  }

}
