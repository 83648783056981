import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BaseComponent} from "@pages/base.component";
import {TransferRunService} from "@data/transfer-run/transfer.run.service";
import {ExtendedTask, ExtendedTransferRun} from "@data/interefaces/extended.data.interfaces";
import {Util} from "@data/util/util";
import {TaskStatus} from "@data/enums/data.enums";
import {MatDialog} from "@angular/material/dialog";
import {AgConfirmationDialogComponent} from "@shared/ag-confirmation-dialog/ag-confirmation-dialog.component";

@Component({
  selector: 'app-transfer-run-detail-tasks-details',
  templateUrl: './transfer-run-detail-tasks-details.component.html',
  styleUrls: ['./transfer-run-detail-tasks-details.component.scss']
})
export class TransferRunDetailTasksDetailsComponent extends BaseComponent implements OnChanges {

  @Input() referenceCode: string | undefined;
  @Input() taskId: string | undefined;
  @Input() transferRun: ExtendedTransferRun | undefined;
  showSkeleton: boolean = true;
  taskDetail: ExtendedTask | undefined;

  constructor(private _transferRunService: TransferRunService, public _dialog: MatDialog) {
    super();
    this.taskDetail = undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['taskId']?.currentValue) {
      this.showSkeleton = true;
      this._transferRunService.getTask(this.referenceCode, this.taskId).then(result => {
        this.taskDetail = result as ExtendedTask;
        this.taskDetail.duration = Util.calculateDurationInSeconds(this.taskDetail.startDatetime, this.taskDetail.endDatetime);
        this.showSkeleton = false;
      });
    }
  }

  onStopTaskClick(referenceCode: string | undefined, taskId: string | undefined) {
    if (referenceCode === undefined) {
      throw new Error("Reference code can not be undefined.");
    }
    if (taskId === undefined) {
      throw new Error("Task Id can not be undefined.");
    }
    if (this.taskDetail !== undefined) {
      const dialogRef = this._dialog.open(AgConfirmationDialogComponent, {
        data: {
          title: "Confirm", message: `Are you sure you want to stop the task ${taskId}?`
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.displayProgressBar(true);
          this._transferRunService.stopTask(referenceCode, taskId).then(result => {
            if (result.status) {
              // immediately set status to stop to not allow accidental double clicks.
              this.taskDetail!.taskStatus = TaskStatus.CANCELLED.toString();
              this.displayNotificationMessage('success', `Task ${taskId} ${TaskStatus.CANCELLED}.`);
              // let refresh get proper status.
            }
            this.displayProgressBar(false);
          }).catch(error => {
              this.displayNotificationMessage('error', JSON.stringify(error));
              this.displayProgressBar(false);
            }
          );
        }
      })
    }
  }

  protected readonly Util = Util;
}
