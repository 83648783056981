<div *ngIf="text" class="long-data-column flex-row" id="long-data-column">
  <span
    *ngIf="type === 'text' || type === 'json'"
    (click)="$event.stopPropagation()"
    class="long-data-column-text"
    matTooltip="{{text}}">{{text}}</span>
  <span
    *ngIf="type === 'html'"
    (click)="$event.stopPropagation()"
    class="long-data-column-text"
    matTooltip="{{text}}"
    [innerHTML]="text"></span>
  <a *ngIf="type === 'link'"
     (click)="$event.stopPropagation()"
     [href]="text" class="long-data-column-text primary-link-color"
     matTooltip="{{text}}"
     target="_blank">{{text}}</a>
  <ag-copy-text-button *ngIf="type !== 'link'" [data]="this.text" />
  <ag-copy-link-button *ngIf="type === 'link'" [data]="this.text" />
  <ag-expand-dialog-button *ngIf="type === 'json' || type === 'html'" [title]="this.title!" [message]="this.text!" [isJson]="this.type! == 'json'" />

</div>
