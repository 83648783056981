<app-progressbar></app-progressbar>
<app-sidenav-support>
  <div class="main-container">
    <div class="header-container">
      <app-header></app-header>
    </div>
    <div class="content-container">
      <router-outlet></router-outlet>
    </div>
    <app-alert></app-alert>
    <div class="footer-container">
      <app-footer></app-footer>
    </div>
  </div>
</app-sidenav-support>
