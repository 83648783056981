import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {
  AutomatedReport,
  DownloadRequest,
  DownloadRequestFile,
  DownloadRequestMetrics
} from "@data/interefaces/download.request.interfaces";
import {DataItem, DataList, OperationStatus, ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {Util} from "@data/util/util";

@Injectable({
  providedIn: 'root'
})
export class DownloadRequestService {

  constructor(private _apiService: ApiService) {
  }

  public getAllDownloadRequests(): Promise<DownloadRequest[]> {
    return this._apiService.makeGetServiceCall<DownloadRequest[]>('/v2/api/download-request');
  }

  public getAutomatedReports(): Promise<AutomatedReport[]> {
    return this._apiService.makeGetServiceCall<AutomatedReport[]>('/v2/api/download-request/automated');
  }

  public getOneDownloadRequest(downloadRequestId: number): Promise<DownloadRequest> {
    return this._apiService.makeGetServiceCall<DownloadRequest>(`/v2/api/download-request/${downloadRequestId}`);
  }

  public create(downloadrequest: any): Promise<DownloadRequest> {
    return this._apiService.makePostServiceCall<DownloadRequest>('/v2/api/download-request', null, downloadrequest);
  }

  public getAllDownloadRequestFilesOfOneDownloadRequest(downloadRequestId: number): Promise<DownloadRequestFile[]> {
    return this._apiService.makeGetServiceCall<DownloadRequestFile[]>(`/v2/api/download-request/files/${downloadRequestId}`);
  }

  public getDownloadRequestThresholds(): Promise<ResultsetThresholds> {
    return this._apiService.makeGetServiceCall<ResultsetThresholds>(`/v2/api/download-request/thresholds`);
  }

  public getLastExpeditedByMarketCodes(): Promise<DataList> {
    return this._apiService.makeGetServiceCall<DataList>(`/v2/api/download-request/market-by-expedited`);
  }

  public getLastExpeditedByMarketCode(marketCode: string): Promise<DataItem> {
    let queryParams: string[] = [];
    Util.pushQueryParam("marketCode", marketCode.toString(), queryParams);
    return this._apiService.makeGetServiceCall<DataItem>(`/v2/api/download-request/last-expedited`, queryParams.join('&'));
  }

  public expediteDownloadRequest(downloadRequestId: number): Promise<OperationStatus> {
    let queryParams: string[] = [];
    Util.pushQueryParam("downloadRequestId", downloadRequestId.toString(), queryParams);
    return this._apiService.makePutServiceCall<OperationStatus>(`/v2/api/download-request/expedite`, queryParams.join('&'));
  }

  public downgradeDownloadRequest(downloadRequestId: number): Promise<OperationStatus> {
    let queryParams: string[] = [];
    Util.pushQueryParam("downloadRequestId", downloadRequestId.toString(), queryParams);
    return this._apiService.makePutServiceCall<OperationStatus>(`/v2/api/download-request/downgrade`, queryParams.join('&'));
  }

  public cancelDownloadRequests(downloadRequestIds: (number | undefined)[]) {
    return this._apiService.makePutServiceCall<OperationStatus>(`/v2/api/download-request/cancel`, null, downloadRequestIds);
  }

  getDownloadMetrics(downloadRequestIds: (number | undefined)[]): Promise<DownloadRequestMetrics[]> {
    return this._apiService.makePostServiceCall<DownloadRequestMetrics[]>(`/v2/api/download-request/metrics`, null, downloadRequestIds);
  }

  public getNextBatchCode(surveySubject: string, marketCode: string): Promise<DataItem> {
    let queryParams: string[] = [];
    Util.pushQueryParam("surveySubject", surveySubject, queryParams);
    Util.pushQueryParam("marketCode", marketCode, queryParams);
    return this._apiService.makeGetServiceCall<DataItem>(`/v2/api/download-request/batch-code`, queryParams.join('&'));
  }
}
