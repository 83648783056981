import {Injectable} from "@angular/core";
import {ReportType, RespondentSegmentLabel, SubjectLabel, WeightedProductLabel} from "@data/interefaces/product.interfaces";
import {Util} from "@data/util/util";
import {ApiService} from "@core/api/api.service";
import {DownloadRequest, DownloadRequestData} from "@data/interefaces/download.request.interfaces";
import {DataItem, OperationStatus, ResultsetThresholds} from "@data/interefaces/data.interfaces";

@Injectable({
  providedIn: 'root'
})
export class PptDownloadsService {

  constructor(private _apiService: ApiService) {
  }

  public getWeightedProductLabels(templateVersion: string, period: string, marketId: string, industryId: string, surveySubject: string, locale: string, isOurVoiceReport: boolean): Promise<WeightedProductLabel[]> {
    let queryParams: string[] = [];
    Util.pushQueryParam("templateVersion", templateVersion, queryParams);
    Util.pushQueryParam("period", period, queryParams);
    Util.pushQueryParam("marketId", marketId, queryParams);
    Util.pushQueryParam("industryId", industryId, queryParams);
    Util.pushQueryParam("surveySubject", surveySubject, queryParams);
    Util.pushQueryParam("locale", locale, queryParams);
    Util.pushQueryParam("isOurVoiceReport", isOurVoiceReport.toString(), queryParams);
    return this._apiService.makeGetServiceCall<WeightedProductLabel[]>(`/v2/api/ppt-downloads/weighted-products`, queryParams.join('&'));
  }

  public getSubjectLabels(productPeriodId: string, locale: string, isNegativeProductViewId: boolean): Promise<SubjectLabel[]> {
    let queryParams: string[] = [];
    Util.pushQueryParam("productPeriodId", productPeriodId, queryParams);
    Util.pushQueryParam("locale", locale, queryParams);
    Util.pushQueryParam("isNegativeProductViewId", isNegativeProductViewId.toString(), queryParams)
    return this._apiService.makeGetServiceCall<SubjectLabel[]>(`/v2/api/ppt-downloads/subject-labels`, queryParams.join('&'));
  }

  public getRespondentSegmentLabels(productPeriodId: string, locale: string, subjectIds: string) {
    let queryParams: string[] = [];
    Util.pushQueryParam("productPeriodId", productPeriodId, queryParams);
    Util.pushQueryParam("locale", locale, queryParams);
    Util.pushQueryParam("subjectIds", subjectIds, queryParams);
    return this._apiService.makeGetServiceCall<RespondentSegmentLabel[]>(`/v2/api/ppt-downloads/respondent-labels`, queryParams.join('&'));
  }

  public getRespondentSegmentLabelsByProductPeriodId(productPeriodId: string, locale: string, isNegativeProductViewId: boolean) {
    let queryParams: string[] = [];
    Util.pushQueryParam("productPeriodId", productPeriodId, queryParams);
    Util.pushQueryParam("locale", locale, queryParams);
    Util.pushQueryParam("isNegativeProductViewId", isNegativeProductViewId.toString(), queryParams);
    return this._apiService.makeGetServiceCall<RespondentSegmentLabel[]>(`/v2/api/ppt-downloads/respondent-labels`, queryParams.join('&'));
  }

  public getReportType() {
    return this._apiService.makeGetServiceCall<ReportType[]>(`/v2/api/ppt-downloads/report-type`);
  }

  public saveDownloadRequests(downloadRequests: DownloadRequestData[]) {
    return this._apiService.makePostServiceCall<OperationStatus>(`/v2/api/ppt-downloads/save`, null, downloadRequests);
  }

  public getNewDownloadRequestThresholds(): Promise<ResultsetThresholds> {
    return this._apiService.makeGetServiceCall<ResultsetThresholds>(`/v2/api/ppt-downloads/thresholds`);
  }

  public getMatchingDownloadRequests(downloadRequests: DownloadRequestData[]): Promise<DownloadRequest[]> {
    return this._apiService.makePostServiceCall(`/v2/api/ppt-downloads/matching-requests`, null, downloadRequests);
  }

  public getIndustryFromProductPeriodIds(productPeriodIds: number[]): Promise<DataItem> {
    return this._apiService.makePostServiceCall(`/v2/api/ppt-downloads/industry`, null, productPeriodIds);
  }

}
