import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {TableBaseComponent} from "@pages/table.base.component";
import {SurveyTracker} from "@data/interefaces/panel.interfaces";
import {ResponseStatus, ResponseType} from "@data/enums/data.enums";
import {ViewSurvey} from "@data/interefaces/survey.spec.interfaces";
import {KpiSidebarItem} from "@shared/ag-kpi-sidebar/ag-kpi-sidebar.component.ds";
import {Util} from "@data/util/util";
import {ActivatedRoute} from "@angular/router";
import {SurveySpecService} from "@data/survey-spec/survey.spec.service";
import {ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {marketCodes, pageContext, periods, surveyCodes, tableColumns} from "@pages/panel/survey-tracker/survey-tracker.component.ds";
import {FilterChangedEvent, FirstDataRenderedEvent, SelectionChangedEvent, SelectionColumnDef, ViewportChangedEvent} from "@ag-grid-community/core";
import {QuilliupForm} from "@data/enums/quilliup.enum";
import {SurveyTrackerService} from "@data/panel/survey.tracker.service";
import {ReferenceService} from "@data/reference/reference.service";
import {LookupService} from "@data/lookup/lookup.service";
import {AgGridService} from "@shared/services/ag-grid.service";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-survey-tracker',
  templateUrl: './survey-tracker.component.html',
  styleUrls: ['./survey-tracker.component.scss'],
  providers: [AgGridService]
})
export class SurveyTrackerComponent extends TableBaseComponent implements AfterViewInit {
  rowData: SurveyTracker[] = [];
  selectedRows: SurveyTracker[] = [];
  selectedFeatureMode!: FeatureComponentMode;
  auxLinks: Map<string, string>;
  surveyCodeList: ViewSurvey[] | undefined;
  showSkeleton: boolean = true;
  kpiSidebar: KpiSidebarItem[] = [];
  refreshComplete: boolean = true;
  isFilterActive: boolean = false;
  @ViewChild('footerActionButtonPanel') footerActionButtonPanel: TemplateRef<any> | undefined;

  isBulkOperationAllowed: boolean = false;
  isBulkRequestResponseFilesAllowed: boolean = false;
  showTaskComponent: boolean = false;

  protected readonly util = Util;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns
  protected readonly responseStatus = ResponseStatus;
  protected readonly QuilliupForm = QuilliupForm;

  constructor(private _activatedRoute: ActivatedRoute, private _surveyTrackerService: SurveyTrackerService, private _surveySpecService: SurveySpecService,
              private _referenceService: ReferenceService, private _lookupService: LookupService) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    this.loadFilterData();
    this.updatePageContext();
  }

  ngAfterViewInit() {
  }

  onLiveDataFilterChange(forceReload: boolean = false): void {
    if (forceReload) {
      this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    }
    this.updateToPageContextUrl();
  }

  async loadPageData() {
    const periods = this.pageContext.p_f || '';
    const marketCodes = this.pageContext.mc_f || '';
    const surveyCodes = this.pageContext.sc_f || '';
    let thresholds: ResultsetThresholds | undefined;
    this.refreshComplete = false;
    this.displayProgressBar(true);
    await this._surveyTrackerService.getSurveyTrackersThresholds(periods, marketCodes, surveyCodes).then(result => {
      thresholds = result;
      this.setRowTemplateMessage(thresholds);
    }).catch(error => {
        this.displayNotificationMessage('error', JSON.stringify(error))
        this.displayProgressBar(false);
        this.refreshComplete = true;
      }
    );

    if (thresholds && !thresholds.hasTooMany) {
      await this._surveyTrackerService.getSurveyTrackers(periods, marketCodes, surveyCodes).then(result => {
        this.rowData = result;
      }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
      ).finally(() => {
          this.displayProgressBar(false);
          this.refreshComplete = true;
        }
      );
    } else {
      this.refreshComplete = true;
      this.rowData = [];
      this.displayProgressBar(false);
    }
  }

  loadFilterData() {
    this._surveySpecService.getSurveys().then(result => {
      result.forEach(item => surveyCodes.push(item.surveyCode));
    });
    this._referenceService.getAllMarkets().then(result => {
      result.forEach(item => marketCodes.push(item.marketCode));
    });
    this._lookupService.getPeriods().then(result => {
      result.forEach(item => periods.push(item.period));
    });
  }

  onRowSelectionChanged(event: SelectionChangedEvent) {
    this.refreshGridHeaderOnSelectionChanged(event);
    this.selectedRows = this.getSelectedRows();
    this.isBulkOperationAllowed = this.selectedRowsInSameSurvey();
    this.isBulkRequestResponseFilesAllowed = this.isBulkOperationAllowed && this.selectedRowsHaveSameResponseFilesStatusTransition();
  }

  async refreshView() {
    await this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
  }

  onBulkRequestResponseFilesClicked() {
    this.selectedFeatureMode = FeatureComponentMode.BULK_REQUEST_FILES;
    this.showTaskComponent = true;
  }

  onBulkMailoutClicked() {
    this.selectedFeatureMode = FeatureComponentMode.BULK_MAILOUT;
    this.showTaskComponent = true;
  }

  onBulkEditClicked() {
    this.selectedFeatureMode = FeatureComponentMode.BULK_EDIT;
    this.showTaskComponent = true;
  }

  goBack() {
    this.showTaskComponent = false;
    this.refreshView();
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    const updateLiveDataFilter = this.isUpdateLiveDataFilterRequired(event.columns);
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this)).then(() => {
      if (updateLiveDataFilter) {
        this.onLiveDataFilterChange(true);
      }
    });
  }

  protected override updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
    this.populateKpiMetrics();
  }

  protected override getSelectionColumnDef(): SelectionColumnDef {
    return this.getSelectColumnDef((params: any) => {
      return {
        showFlag: params.data.responseType == ResponseType.INVALID,
        flagIcon: "fa-exclamation",
        flagColor: "red",
        toolTip: 'Invalid'
      }
    });
  }

  protected override onViewportChanged(event: ViewportChangedEvent<any>) {
    this.populateKpiMetrics();
  }

  protected override getTableFooterActionButtonPanel() {
    return this.footerActionButtonPanel;
  }

  protected override onFirstDataRendered(event: FirstDataRenderedEvent<any>) {
    super.onFirstDataRendered(event);
    event.api.autoSizeAllColumns();
  }

  private selectedRowsInSameSurvey() {
    return this.selectedRows.length != 0 && this.selectedRows.every(item => item.surveyCode === this.selectedRows[0].surveyCode);
  }

  private selectedRowsHaveSameResponseFilesStatusTransition() {
    return this.selectedRows.length != 0 && this.selectedRows.every(item => Util.getFileRequestStatusTransition(item.responseFilesStatus) === Util.getFileRequestStatusTransition(this.selectedRows[0].responseFilesStatus));
  }

  private populateKpiMetrics() {
    let filteredRows = this.getRowsAfterFilter();
    let {
      validCompletes,
      validRespondentCompanies,
      respondentCompanies,
      respondents
    } = this.calculateKpiMetrics(filteredRows);
    this.kpiSidebar = [
      {
        title: "Valid Completes",
        value: validCompletes
      },
      {
        title: "Valid Respondent Completes",
        value: validRespondentCompanies
      },
      {
        title: "Respondent Companies",
        value: respondentCompanies
      },
      {
        title: "Respondents",
        value: respondents
      }
    ];
  }

  private calculateKpiMetrics(rows: SurveyTracker[]) {
    let validCompletes = rows.filter(element => element.responseStatus == ResponseStatus.COMPLETE && element.responseType != ResponseType.INVALID).length;
    let validRespondentCompanies = new Set(rows.filter(element => element.responseStatus == ResponseStatus.COMPLETE && element.responseType != ResponseType.INVALID).map(element => element.invitationCompanyCode)).size;
    let respondentCompanies = new Set(rows.map(element => element.invitationCompanyCode).filter(code => !!code)).size;
    let respondents = new Set(rows.map(element => element.personCode).filter(code => !!code)).size;
    return {validCompletes, validRespondentCompanies, respondentCompanies, respondents};
  }
}

