import {Injectable} from '@angular/core';
import {AuthenticationContext, EnvironmentInfo} from "@data/interefaces/data.interfaces";
import {ApiService} from "@core/api/api.service";

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private apiService: ApiService) {
  }

  public signIn() {
    let port = (location.port ? ':' + location.port : '');
    if (port === ':8081') {
      port = ':8080';
    }
    location.href = '//' + location.hostname + port + '/oauth2/login';
  }

  public signOut() {
    let port = (location.port ? ':' + location.port : '');
    if (port === ':8081') {
      port = ':8080';
    }
    location.href = '//' + location.hostname + port + '/oauth2/logout';
  }

  public async getAuthenticationContext(): Promise<AuthenticationContext> {
    return await this.apiService.makeGetServiceCall<AuthenticationContext>('/v2/api/security/auth-context');
  }

  public async getEnvironmentInfo(): Promise<EnvironmentInfo> {
    return await this.apiService.makeGetServiceCall<EnvironmentInfo>('/v2/api/security/env');
  }

  public async isAuthenticated() {
    return await this.getAuthenticationContext().then(() => true).catch((error) => {
      return false
    });
  }

}
