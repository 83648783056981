<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <pre [innerHTML]="data.message"></pre>
</mat-dialog-content>
<mat-dialog-actions>
  <button (click)="onCopyClipboardClick(data.message)" class="copy-clipboard-button" color="primary" mat-raised-button>
    <mat-icon class="mat-mdc-tooltip-trigger material-symbols-outlined">content_copy</mat-icon>
    Copy to clipboard
  </button>
  <button mat-button mat-dialog-close class="mg-r-s" color="primary" mat-raised-button>
    <mat-icon class="material-symbols-outlined">check</mat-icon>
    Done
  </button>
</mat-dialog-actions>
