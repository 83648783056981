import {Injectable} from '@angular/core';
import {VerbatimFileRowInfo} from "../interefaces/verbatim.file.interfaces";
import {ApiService} from "@core/api/api.service";
import {ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {Util} from "@data/util/util";

@Injectable({
  providedIn: 'root'
})
export class VerbatimRowService {

  constructor(private _apiService: ApiService) {
  }

  public getVerbatimFileRowsByFile(verbatimFileId: number) {
    return this._apiService.makeGetServiceCall<VerbatimFileRowInfo[]>(`/v2/api/verbatim/${verbatimFileId}/verbatim-row`);
  }

  public getVerbatimFileRowsByFilter(fileNames: string, minDate: string, surveyCodes: string) {
    let queryParams: string[] = [];
    Util.pushQueryParam("verbatimFileName", fileNames, queryParams);
    Util.pushQueryParam("verbatimFileUploadDateMin", minDate, queryParams);
    Util.pushQueryParam("surveyCode", surveyCodes, queryParams);
    return this._apiService.makeGetServiceCall<VerbatimFileRowInfo[]>(`/v2/api/verbatim-row`, queryParams.join('&'));
  }

  public getVerbatimFileRowThresholds(fileNames: string, minDate: string, surveyCodes: string): Promise<ResultsetThresholds> {
    let queryParams: string[] = [];
    Util.pushQueryParam("verbatimFileName", fileNames, queryParams);
    Util.pushQueryParam("verbatimFileUploadDateMin", minDate, queryParams);
    Util.pushQueryParam("surveyCode", surveyCodes, queryParams);
    return this._apiService.makeGetServiceCall<ResultsetThresholds>(`/v2/api/verbatim-row/thresholds`, queryParams.join('&'));
  }

  getVerbatimFileRow(verbatimFileRowId: number) {
    return this._apiService.makeGetServiceCall<VerbatimFileRowInfo>(`/v2/api/verbatim-row/${verbatimFileRowId}`);
  }
}
