import {Directive, EventEmitter, inject, Input, OnDestroy, Output} from "@angular/core";
import {BaseComponent} from "@pages/base.component";
import {FormControl, FormGroup} from "@angular/forms";
import {GridApi, GridReadyEvent} from "@ag-grid-community/core";
import {NavigationService} from "@core/api/navigation.service";
import {Util} from "@data/util/util";
import {BatchProcessResult} from "@data/interefaces/data.interfaces";
import {BatchService} from "@data/panel/batch.service";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Directive()
export abstract class BulkBaseComponent extends BaseComponent implements OnDestroy {

  @Input() abstract selectedRows: any[];
  @Input() featureComponentMode: FeatureComponentMode = FeatureComponentMode.DEFAULT;
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  protected abstract formGroup: FormGroup;
  protected abstract readonly tableColumns: any;
  protected _navigationService: NavigationService
  protected title: string = 'Default Title';
  protected subtitle: string = 'Default Sub Title';
  protected modifiedRecords: any[] = [];
  protected submitButtonText: string = 'Default Submit Button Text'
  protected updateStatus: {
    totalNumberOfRows: number,
    numberOfProcessedRows: number,
    numberOfSuccessRows: number,
    numberOfErrorRows: number,
    numberOfUnchangedRows: number,
    markUnchangedRecord: boolean,
    failedRows: any[]
  } = {
    totalNumberOfRows: 0,
    numberOfProcessedRows: 0,
    numberOfSuccessRows: 0,
    numberOfErrorRows: 0,
    numberOfUnchangedRows: 0,
    markUnchangedRecord: false,
    failedRows: []
  };
  protected uploadInfo: {
    fileName: string;
    fileSize: number;
    uploadDataAvailable: boolean;
    uploadInitiated: boolean;
  } = {
    fileName: "",
    fileSize: 0,
    uploadDataAvailable: false,
    uploadInitiated: false
  };
  protected readonly maxFileSizeSupported = 1024 * 1024 * 25;
  protected updateStage: number = -1;
  protected saveInProgress: boolean = false;
  protected intervalId: any;
  protected batchProcessId: number = -1;
  protected gridApi!: GridApi;
  protected goBackUrl: string = '/';
  protected readonly util = Util;
  protected readonly FeatureComponentMode = FeatureComponentMode;
  protected defaultSupportedFileTypes = ['xlsx', 'csv'];
  private _batchService: BatchService;

  protected constructor() {
    super();
    this._navigationService = inject(NavigationService);
    this._batchService = inject(BatchService);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initFormGroup();
    this.initGoBackUrl();
    this.initFieldsByFeatureComponentMode();
    this.getBatchProcessStatus();
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  /**
   * Initializes the formGroup
   * @protected
   */
  protected abstract initFormGroup(): void;

  /**
   * Initializes the feature set by the Feature Component Mode
   * @protected
   */
  protected abstract initFieldsByFeatureComponentMode(): void;

  protected abstract getBatchProcessResultByType(): Promise<BatchProcessResult>;

  /**
   * Makes the batch process call.  Each page will need to implement its own call.
   * @param selectedRows
   * @protected
   */
  protected abstract startBatchProcess(selectedRows: any[]): Promise<any>;

  /**
   * Makes any validation to the rows that are required by the business logic.
   * If there are no validations, create a blank method.
   * @param rowData
   * @protected
   */
  protected abstract validateRowData<T>(rowData: T[]): T[];

  /**
   * Gets the initial batch process status
   * @protected
   */
  protected getBatchProcessStatus() {
    this.getBatchProcessResultByType()
      .then(result => {
        if (result != null) {
          // If -1 is returned, no batch processes are in process.
          if (result.id !== -1) {
            if (this.isInProgress(result)) {
              this.batchProcessId = result.id;
              this.displayBatchProcessResult(result);
              this.startProcessing();
            } else if (!result.doneIndicator) {
              this.displayBatchProcessResult(result);
              this.updateStage = 3;
            }
          }
        }
      }).catch(error => {
      this.displayNotificationMessage('error', JSON.stringify(error));
    });
  }

  protected retry() {
    if (this.selectedRows.length <= 0)
      this.selectedRows = this.updateStatus.failedRows;
    this.gridApi.setGridOption('rowData', this.modifiedRecords);
    this.initFieldsByFeatureComponentMode();
    this.initFormGroup();
  }

  protected getControl<T>(controlName: (keyof T)) {
    return this.formGroup.get(controlName as string) as FormControl<T>;
  }

  protected onGridReady(gridReadyEvent: GridReadyEvent<any>) {
    this.gridApi = gridReadyEvent.api;
  }

  protected uploadStarted(event: { fileName: string; fileSize: number }) {
    this.uploadInfo.uploadDataAvailable = false;
    this.uploadInfo.uploadInitiated = true;
    this.uploadInfo.fileName = event.fileName;
    this.uploadInfo.fileSize = event.fileSize;
  }

  protected processUploadedFile<T>(event: { status: number; message: string; file?: File }) {
    if (event.status == 0) {
      this.updateStage++;
      Util.readXlsSheetAsJson(event.file!, 0, this.tableColumns).then(rowData => {
        let validationResult = this.validateRowData<T>(rowData as T[]);
        if (validationResult.length > 0) {
          this.gridApi.setGridOption('rowData', validationResult);
        }
        this.uploadInfo.uploadDataAvailable = true;
        this.selectedRows = rowData;
        this.gridApi.setGridOption('rowData', this.selectedRows);
      }).catch(error => {
        this.displayNotificationMessage("error", JSON.stringify(error));
      });
    }
  }

  protected async startProcessing() {
    if (this.featureComponentMode == FeatureComponentMode.UPLOAD || this.formGroup.valid) {
      this.updateStage = 3;
      this.saveInProgress = true;
      if (this.batchProcessId === -1) {
        this.gridApi.setGridOption('rowData', this.updateStatus.failedRows);
        this.modifiedRecords = this.getUpdatedRowsFromFormValues(this.selectedRows, this.formGroup.getRawValue());
        await this.startBatchProcess(this.modifiedRecords)
          .then(result => {
            this.batchProcessId = result.id;
            this.updateStatus.markUnchangedRecord = result.markUnchangedRecord ?? false;
          })
          .catch(error => {
            this.displayNotificationMessage("error", JSON.stringify(error));
            this.saveInProgress = false;
          });
      }
      if (this.saveInProgress) {
        this.intervalId = setInterval(async () => {
          await this.getBatchProcessResult();
        }, 5000);
      }
    }
  }

  protected async getBatchProcessResult() {
    if (this.batchProcessId > 0) {
      await this._batchService.getBatchProcessById(this.batchProcessId)
        .then(result => this.displayBatchProcessResult(result))
        .catch(error => {
          this.displayNotificationMessage("error", JSON.stringify(error));
          this.saveInProgress = false;
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }
        });
    }
  }

  protected displayBatchProcessResult(result: BatchProcessResult) {
    let batchStatus: string = result.batchStatus;
    let processType: string = result.processType;
    this.featureComponentMode = Util.getEnumKeyByValue(FeatureComponentMode, processType);
    this.updateStatus.totalNumberOfRows = result.totalRecords;
    this.updateStatus.numberOfSuccessRows = result.successRecords ?? 0;
    this.updateStatus.numberOfErrorRows = result.failedRecords ?? 0;
    this.updateStatus.markUnchangedRecord = result.markUnchangedRecord ?? false;
    if (this.updateStatus.markUnchangedRecord) {
      this.updateStatus.numberOfUnchangedRows = result.unchangedRecords ?? 0;
    } else {
      this.updateStatus.numberOfUnchangedRows = 0;
    }
    this.updateStatus.numberOfProcessedRows = this.updateStatus.numberOfSuccessRows + this.updateStatus.numberOfErrorRows + this.updateStatus.numberOfUnchangedRows;
    if (batchStatus != "in progress") {
      this.saveInProgress = false;
      this.batchProcessId = -1;
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      if (this.updateStatus.numberOfErrorRows > 0) {
        if (result.failedRecordList != null && Array.isArray(result.failedRecordList)) {
          this.updateStatus.failedRows = [...result.failedRecordList];
          this.updateStatus.failedRows.forEach(row => row.processingNote = row.processing_note);
        }
        this.gridApi.setGridOption('rowData', this.updateStatus.failedRows);
      }
      if (batchStatus === "completed") {
        if (this.updateStatus.numberOfErrorRows > 0) {
          this.displayNotificationMessage("error", `Bulk Operation Completed With Errors.`);
        } else {
          this.displayNotificationMessage("info", `Bulk Operation Completed.`);
        }
      } else {
        this.displayNotificationMessage("info", `Bulk Operation Aborted.`);
      }
      this._batchService.markBatchProcessDone(result.id);
    }
  }

  protected isInProgress(result: BatchProcessResult): boolean {
    return result.batchStatus === "in progress";
  }

  protected processedPercentage() {
    return Util.calculatePercentage(this.updateStatus.numberOfProcessedRows, this.updateStatus.totalNumberOfRows);
  }

  protected successPercentage() {
    return Util.calculatePercentage(this.updateStatus.numberOfSuccessRows, this.updateStatus.numberOfProcessedRows)
  }

  protected errorPercentage() {
    return Util.calculatePercentage(this.updateStatus.numberOfErrorRows, this.updateStatus.numberOfProcessedRows);
  }

  protected unchangedPercentage() {
    return Util.calculatePercentage(this.updateStatus.numberOfUnchangedRows, this.updateStatus.numberOfProcessedRows);
  }

  protected downloadFailedRows(fileName: string) {
    this.gridApi.exportDataAsExcel({
      fileName: `${fileName}-${Util.getFormattedDateTimestamp(new Date())}`,
      columnKeys: Util.getDownloadableColumnNames(this.tableColumns),
      skipColumnGroupHeaders: true
    });
  }

  protected close() {
    if (this.featureComponentMode == FeatureComponentMode.UPLOAD) {
      this._navigationService.back(this.goBackUrl, ["task"]);
    } else {
      this.goBackEvent.emit();
    }
  }

  protected abort() {
    if (this.batchProcessId > 0) {
      if (confirm("There are still pending changes on processing.  By clicking OK all changes will be lost")) {
        this._batchService.stopBatchProcess(this.batchProcessId);
      }
    }
  }

  protected hasAnyEdits() {
    return this.formGroup.dirty;
  }

  protected resetForm() {
    this.formGroup.reset();
    this.updateStatus = {
      totalNumberOfRows: 0,
      numberOfProcessedRows: 0,
      numberOfSuccessRows: 0,
      numberOfErrorRows: 0,
      numberOfUnchangedRows: 0,
      markUnchangedRecord: false,
      failedRows: []
    };
    this.uploadInfo = {
      fileName: "",
      fileSize: 0,
      uploadDataAvailable: false,
      uploadInitiated: false
    }
    this.gridApi.setGridOption('rowData', this.selectedRows);
    this.initFieldsByFeatureComponentMode();
  }

  /**
   * This method returns the list of support files types for the File Upload component.
   * Overwrite this method to change the supported types.
   * @protected
   */
  protected getSupportedFileTypes() {
    return this.defaultSupportedFileTypes;
  }

  private initGoBackUrl() {
    this.goBackUrl = this.goBackUrl += this.createGoBackUrl()
  }

  /**
   *
   * Return an array, such that it copied from oldRows, but latest value from the form.
   * @param oldRows an array of table rows having all value unchanged
   * @param formValues a formGroup
   */
  private getUpdatedRowsFromFormValues(oldRows: any[], formValues: any): any[] {

    return oldRows.map(row => {
      const newRow = {...row};

      for (let key in formValues) {
        if (formValues[key] !== null && formValues[key] !== undefined) {
          newRow[key] = formValues[key];
        }
      }

      return newRow;
    });
  }
}
