import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType} from "@data/enums/data.enums";
import {Util} from "@data/util/util";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {ResetFilterComponent} from "@pages/segments/grid/filters/reset-filter/reset-filter.component";

export let pageContext: {
  [key: string]: boolean | string | undefined
  fn_f: string | undefined;
  desc_f: string | undefined;
  sc_f: string | undefined;
  re_f: string | undefined;
  pd_f: string | undefined;
  ps_f: string | undefined;
  ud_f: string | undefined;
  fs_f: string | undefined;
  su_f: string | undefined;
} = {
  fn_f: undefined,
  desc_f: undefined,
  sc_f: undefined,
  re_f: undefined,
  pd_f: undefined,
  ps_f: undefined,
  ud_f: undefined,
  fs_f: undefined,
  su_f: undefined,
}

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    field: 'fileName',
    headerName: 'File Name',
    hide: false,
    minWidth: 300,
    maxWidth: 500,
    filterId: 'fn_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    hide: false,
    minWidth: 200,
    filterId: 'desc_f',
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.description,
        type: 'text',
      };
    }
  },
  {
    field: 'survey?.surveyCode',
    headerName: 'Survey Code',
    hide: false,
    minWidth: 150,
    filterId: 'sc_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'respondentEmail',
    headerName: 'Respondent Email',
    hide: false,
    minWidth: 200,
    filterId: 're_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'processedDatetime',
    headerName: 'Uploaded Date',
    hide: false,
    minWidth: 150,
    filterId: 'pd_f',
    filter: ColumnFilterType.DATE,
    filterParams: {
      defaultOption: 'inRange',
      maxNumConditions: 1,
      comparator: Util.dateComparator
    },
    useValueFormatterForExport: false,
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },
  {
    field: 'processingStatus',
    headerName: 'Upload Status',
    hide: false,
    minWidth: 100,
    filterId: 'ps_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    cellRenderer: StatusColumnComponent
  },
  {
    field: 'processingDetails',
    headerName: 'Upload Details',
    hide: false,
    maxWidth: 250,
    filterId: 'ud_f',
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.processingDetails,
        type: 'json',
      };
    }
  },
  {
    field: 'overwritten',
    headerName: 'File Status',
    hide: false,
    filterId: 'fs_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'sharepointUrl',
    headerName: 'Sharepoint Url',
    hide: false,
    filterId: 'su_f',
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.sharepointUrl,
        type: 'link',
      };
    }
  },
  {
    field: 'action',
    headerName: 'Action',
    hide: false,
    isMandatory: true,
    downloadable: false,
    pinned: "right",
    suppressFiltersToolPanel: true,
    filter: true,
    floatingFilterComponent: ResetFilterComponent,
    suppressFloatingFilterButton: true,
    suppressHeaderFilterButton: true,
    cellRenderer: ActionColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        actions: [
          {
            toolTip: "File Detail",
            icon: "fa-file",
            name: "FileDetail",
            routerLink: ['task', 'detail'],
            queryParams: {id: params.data.id}
          }
        ]
      };
    }
  }
]
