import {Component, Input} from '@angular/core';
import {Params} from "@angular/router";
import {NavigationService} from "@core/api/navigation.service";

@Component({
  selector: 'ag-context-link',
  templateUrl: './ag-context-link.component.html',
  styleUrls: ['./ag-context-link.component.scss']
})
export class AgContextLinkComponent {

  @Input() enableBackLink: boolean = true;
  @Input() qParams: Params = {};

  /**
   * Defiled map with key as the relative url and the value as the text to be displayed as the link.
   */
  @Input() auxLinks: Map<string, string> = new Map<string, string>();

  constructor(private _navigation: NavigationService) {
  }

  goBack() {
    this._navigation.back("/");
  }

}
