import {Component, OnInit} from '@angular/core';
import packageJson from '../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version: string = packageJson.version;
  timezoneOffset: any
  currentYear = 0;

  ngOnInit() {
    this.updateTimezone();
    this.currentYear = new Date().getFullYear();
  }

  updateTimezone() {
    const offset = new Date().getTimezoneOffset();
    const hours = Math.abs(Math.floor(offset / 60));
    const minutes = Math.abs(offset % 60);
    const sign = offset > 0 ? '-' : '+';
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timezoneOffset = `${sign}${this.pad(hours)}:${this.pad(minutes)} ${timezone}`;
  }

  pad(number: number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }
}
