<aside [@shrinkAnimation]="shrinkState" class="flex-column pd-s shrinkable-div" id="kpi-sidebar">
  <div
    [ngClass]="
      {'flex-row': shrinkState === 'expanded',
       'flex-column shrunken-state': shrinkState === 'shrunken'
      }"
    class="icons-container"
  >
    <i class="fa-sharp fa-light fa-chart-line primary-color"></i>
    <button (click)="shrinkDiv()" color="primary" mat-icon-button>
      <i
        [ngClass]="
        {'fa-arrow-left': shrinkState === 'expanded',
         'fa-arrow-right': shrinkState === 'shrunken'
        }"
        class="fa-sharp fa-light"
      ></i>
    </button>
  </div>
  <section *ngIf="shrinkState === 'expanded'" class="sidebar-container flex-column">
    <ng-content></ng-content>
    <div
      *ngFor="let item of sidebar"
      class="sidebar-item flex-column flex-centered pd-l-m pd-r-m"
    >
      <span class="sidebar-item-value">{{item.value === 0 ? '-' : item.value}}</span>
      <span class="sidebar-item-title">{{item.title}}</span>
    </div>
    <ng-content></ng-content>
  </section>
</aside>

