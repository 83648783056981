import {TableBaseComponent} from "@pages/table.base.component";
import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {ViewDownloadRequestFile} from "@data/interefaces/download.request.interfaces";
import {pageContext, tableColumns} from "@pages/download-request/download-request-detail/download-request-detail-file/download-request-detail-file.component.ds";
import {ActivatedRoute} from "@angular/router";
import {DownloadRequestService} from "@data/download-request/download.request.service";
import {DownloadRequestUtil} from "@data/download-request/download.request.util";
import {FilterChangedEvent, RowSelectedEvent} from "ag-grid-community";
import {Util} from "@data/util/util";
import {AgGridService} from "@shared/services/ag-grid.service";


@Component({
  selector: 'app-download-request-detail-file',
  templateUrl: './download-request-detail-file.component.html',
  styleUrls: ['./download-request-detail-file.component.scss'],
  providers: [AgGridService]
})
export class DownloadRequestDetailFileComponent extends TableBaseComponent implements OnChanges {

  @Input() sharepointFolderUrl: string | undefined;

  rowData: ViewDownloadRequestFile[] = [];
  refreshComplete: boolean = false;
  selectedRows: ViewDownloadRequestFile[] = [];
  isFilterActive: boolean = false;
  auxLinks: Map<string, string>;
  protected readonly downloadRequestUtil = DownloadRequestUtil;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns;
  protected readonly util = Util;


  constructor(private _activatedRoute: ActivatedRoute, private _downloadRequestService: DownloadRequestService) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    // this.refreshView();
    this.updatePageContext();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["sharepointFolderUrl"] && changes['sharepointFolderUrl'].currentValue) {
      this.refreshComplete = true;
      this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    }
  }

  async loadPageData() {
    this.refreshComplete = false;
    this._downloadRequestService.getAllDownloadRequestFilesOfOneDownloadRequest(Number(this.pageContext.dri_f!)).then(result => {
      this.rowData = result.map((element) =>
        this.downloadRequestUtil.mapDownloadRequestFileToView(this.sharepointFolderUrl!, element)
      );
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => {
      this.displayProgressBar(false)
      this.refreshComplete = true;
    });
  }

  onRowSelected(event: RowSelectedEvent) {
    if (event.source == "checkboxSelected") {
      this.gridApi.refreshHeader();
    }
    this.selectedRows = this.getSelectedRows();
  }

  onLiveDataFilterChange(forceReload: boolean = false): void {
    if (forceReload) {
      this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    }
    this.updateToPageContextUrl();
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    const updateLiveDataFilter = this.isUpdateLiveDataFilterRequired(event.columns);
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this)).then(() => {
      if (updateLiveDataFilter) {
        this.onLiveDataFilterChange(true);
      }
    });
  }

  updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }
}
