import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ReferenceService} from "@data/reference/reference.service";
import {StatusService} from "@data/system/status/status.service";
import {Util} from "@data/util/util";
import {pageContext, tableColumns} from "@pages/system/survey/project/survey-project.component.ds";
import {TableBaseComponent} from "@pages/table.base.component";
import {AgGridService} from "@shared/services/ag-grid.service";
import {SurveyProject} from "@data/interefaces/reference.interfaces";
import {FilterChangedEvent, FirstDataRenderedEvent, SelectionColumnDef} from "@ag-grid-community/core";
import {KpiSidebarItem} from "@shared/ag-kpi-sidebar/ag-kpi-sidebar.component.ds";
import {SurveyCleanupApplicationSettings} from "@data/interefaces/data.interfaces";

@Component({
  selector: 'app-survey-project',
  templateUrl: './survey-project.component.html',
  styleUrls: ['./survey-project.component.scss'],
  providers: [AgGridService]
})

export class SurveyProjectComponent extends TableBaseComponent implements AfterViewInit {

  rowData: SurveyProject[] = [];
  kpiSidebar: KpiSidebarItem[] = [];
  auxLinks: Map<string, string>;
  showSkeleton: boolean = true;
  refreshComplete: boolean = true;
  isFilterActive: boolean = false;
  @ViewChild('footerActionButtonPanel') footerActionButtonPanel: TemplateRef<any> | undefined;

  protected readonly util = Util;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns;

  constructor(private _activatedRoute: ActivatedRoute, private _referenceService: ReferenceService, private _statusService: StatusService) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    this.updatePageContext();
  }

  ngAfterViewInit() {
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this)).then(() => {
    });
  }

  async loadPageData() {
    this.refreshComplete = false;
    this.displayProgressBar(true);
    await this._referenceService.getAllSurveyProjects().then(result => {
      this.rowData = result;
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => {
        this.displayProgressBar(false);
        this.refreshComplete = true;
      }
    );
    await this._statusService.getSurveyCleanupApplicationSettings().then(result => {
      this.populateKpiMetrics(result);
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => {
      }
    );
  }

  async refreshView() {
    await this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
  }

  protected override getSelectionColumnDef(): SelectionColumnDef {
    return {};
  }

  protected override updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }

  protected override onFirstDataRendered(event: FirstDataRenderedEvent<any>) {
    super.onFirstDataRendered(event);
    event.api.autoSizeAllColumns();
  }

  private populateKpiMetrics(settings: SurveyCleanupApplicationSettings) {
    if (settings) {
      this.kpiSidebar = [
        {
          id: "field-close-period",
          title: "",
          value: "Current projects are archived " + settings.minimumDaysAfterFieldClosedDate + " days after closing field"
        },
        {
          id: "create-period",
          title: "",
          value: "Non-current projects are archived " + settings.minimumDaysAfterCreatedDate + " days after project creation"
        },
        {
          id: "archive-period",
          title: "",
          value: "Archived projects are deleted " + settings.minimumDaysAfterArchivedDate + " days after archiving"
        },
        {
          id: "survey-name-regex",
          class: "survey-name-regex",
          title: "",
          value: "Project names that don't match the pattern '" + settings.surveyNameEnvironmentRegex + "' are ignored for safety."
        }
      ];
    } else {
      this.kpiSidebar = [];
    }
  }
}
