import {BaseComponent} from "./base.component";
import {GridColumn, ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {Directive, inject, TemplateRef} from "@angular/core";
import {Util} from "@data/util/util";
import {ColDef, ColumnVisibleEvent, FirstDataRenderedEvent, GridReadyEvent, SelectionChangedEvent, SideBarDef, ViewportChangedEvent} from "ag-grid-community";
import {NotificationType} from "@data/enums/data.enums";
import {Column, GridApi, GridOptions, StatusPanelDef} from "ag-grid-enterprise";
import {AgTableFooterComponent} from "@shared/ag-table-footer/ag-table-footer.component";
import {AgGridService} from "@shared/services/ag-grid.service";

@Directive({
  providers: [AgGridService]
})
export abstract class TableBaseComponent extends BaseComponent {

  protected abstract tableColumns: any;
  protected abstract pageContext: any;
  protected noRowsTemplate: string = `<span>Loading...</span>`;
  protected excelDownloadThresholdValue: number = 20000;
  protected gridApi!: GridApi;
  protected _agGridService: AgGridService;
  protected baseAgGridOptions: GridOptions;
  private defaultColDef: ColDef;
  private sideBar: SideBarDef;

  protected constructor() {
    super();
    // const injector = inject(Injector);
    // this._agGridService = injector.get(AgGridService);
    this._agGridService = inject(AgGridService);
    this.baseAgGridOptions = this._agGridService.getDefaultAgiGridOptions();
    this.baseAgGridOptions.onGridSizeChanged = this.onGridSizeChanged.bind(this);
    this.sideBar = this._agGridService.getDefaultSideBar();
    this.defaultColDef = this._agGridService.getDefaultColumnDefinition();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.checkWidth();
    this._agGridService.updateColumnsFromLocalStorage(this.pageName, this._agGridService.getGridColumns(this.tableColumns));
  }

  onGridSizeChanged() {
    if (!this.gridApi.isDestroyed()) {
      this.checkWidth();
    }
  }

  override getPageState(): any {
    return this.pageContext;
  }

  protected abstract updatePageContext(updateContextUrl: boolean): void;

  protected onGridReady(event: GridReadyEvent<any>) {
    this.gridApi = event.api;
    let statusPanelDef: StatusPanelDef = {
      statusPanel: AgTableFooterComponent
    }
    if (this.getTableFooterActionButtonPanel() != undefined) {
      statusPanelDef.statusPanelParams = {
        actionPanelDef: this.getTableFooterActionButtonPanel()
      }
    }
    this.baseAgGridOptions.statusBar = {
      statusPanels: [statusPanelDef]
    }
    this.gridApi.updateGridOptions(this.baseAgGridOptions);
    this.initAfterGridReady();
  }

  protected setRowTemplateMessage(thresholds: ResultsetThresholds) {
    const recordStr = thresholds.resultsetLabel ? thresholds.resultsetLabel : "records";
    if (thresholds.hasTooMany) {
      let thresholdStr = '';
      thresholds.thresholds.forEach(threshold => {
        if (thresholdStr.length) {
          thresholdStr += ` or `;
        }
        thresholdStr += `${threshold.threshold} ${threshold.thresholdLabel}`;
        if (threshold.count) {
          thresholdStr += ` (current value: ${threshold.count})`;
        }
      });
      this.noRowsTemplate = `<span class="warning-color">Your filters return too many ${recordStr}. Please use filters to narrow down to ${thresholdStr}.</span>`;
    } else if (thresholds.count === 0) {
      this.noRowsTemplate = `<span class="warning-color">No ${recordStr} found. Please adjust your filters.</span>`;
    }
  }

  protected refreshGridHeaderOnSelectionChanged(event: SelectionChangedEvent) {
    // Refresh the header on checkboxSelect.
    // In rare circumstances when click the checkbox too quickly in the grid, the event.source type becomes 'api'
    // instead of 'checkboxSelected'.  So we also need to include api calls in our checks.
    if (event.source == "checkboxSelected" || event.source == 'api') {
      this.gridApi.refreshHeader();
    }
  }

  protected initAfterGridReady() {
  }

  protected getTableFooterActionButtonPanel(): TemplateRef<any> | undefined {
    return undefined;
  }

  protected onViewportChanged(event: ViewportChangedEvent<any>) {
  }

  protected getSelectedRows() {
    return this.gridApi.getSelectedRows();
  }

  protected getRowsAfterFilter() {
    const filteredRows: any[] = [];
    if (this.gridApi !== undefined && !this.gridApi.isDestroyed()) {
      this.gridApi.forEachNodeAfterFilter((node) => {
        filteredRows.push(node.data);
      });
    }
    return filteredRows;
  }

  protected updateAgGridFilters(pageContext: any, api: GridApi) {
    this._agGridService.updateAgGridFiltersHandler(pageContext, api);
  }

  protected onFirstDataRendered(event: FirstDataRenderedEvent<any>) {
    this.updateAgGridFilters(this.getPageState(), event.api);
    //event.api.autoSizeAllColumns();
  }

  protected onColumnVisible(event: ColumnVisibleEvent<any>) {
    this._agGridService.onColumnVisibleHandler(event, this.pageName);
  }

  protected async handleDownloadClickEvent(fileName: string) {
    this.displayProgressBar(true);

    const params = {
      fileName: `${fileName}-${Util.getFormattedDateTimestamp(new Date())}`,
      columnKeys: Util.getDownloadableColumnNames(this.tableColumns),
      skipColumnGroupHeaders: true,
      onlySelected: false
    };

    const selectedRowCount = this.getSelectedRows().length;
    if (selectedRowCount && selectedRowCount > 0) {
      params.onlySelected = true;
    }
    const rowCount = this.gridApi.getDisplayedRowCount();
    if (rowCount > this.excelDownloadThresholdValue && !params.onlySelected) {
      // if the row count is higher than the downloadThresholdValue, we must force the users to select what rows they want to download
      // otherwise we could potentially run out of memory.
      // const message = `Can not download with  records.  Only a maximum of [${this.downloadThresholdValue}] records can be downloaded.  Please use filters to narrow down the records or select the records to download.`;
      const message = `The current row count of [${rowCount}] is too large to download as excel format.  By clicking 'Ok' a CSV file will be downloaded.  Click 'Cancel' to reduce the number of records by using the filters.`
      const dialogResult = await this.displayDialogMessage(NotificationType.WARNING, message, undefined, false);
      //
      if (dialogResult) {
        this.gridApi.exportDataAsCsv(params);
      }
    } else {
      this.gridApi.exportDataAsExcel(params);
    }
    this.displayProgressBar(false);
  }

  protected isUpdateLiveDataFilterRequired(columns: Column<any>[]): boolean {
    return columns.find(col => {
      const gridCol = col.getColDef() as GridColumn;
      if (gridCol !== null) {
        if (gridCol.dbFilter) {
          return true;
        }
      }
      return false;
    }) !== undefined;
  }

  protected rescaleTableColumns(minColDefWidth: number, columnWidth: number, minColumnWidth: number) {
    const defaultColDef = {...this.defaultColDef};
    defaultColDef.minWidth = minColDefWidth;
    this.tableColumns.forEach((column: any) => {
      if (column.field === 'code') {
        column.width = columnWidth;
        column.minWidth = minColumnWidth;
      }
    })
    this.baseAgGridOptions = {
      ...this.baseAgGridOptions,
      defaultColDef,
    }
  }

  private checkWidth(): void {
    if (window.innerWidth <= 1600) {
      this.rescaleTableColumns(100, 200, 200);
    } else if (window.innerWidth > 1600) {
      this.rescaleTableColumns(150, 300, 300);
    }

    this.gridApi?.updateGridOptions(this.baseAgGridOptions);
    const allColumnIds: string[] = [];
    this.gridApi?.getColumns()?.forEach((column) => {
      allColumnIds.push(column.getColId());
    });
    this.gridApi?.autoSizeColumns(allColumnIds);
  }

  private getCountThreshold(thresholds: ResultsetThresholds) {
    return thresholds.thresholds.find(t => t.thresholdCode === 'count');
  }
}
