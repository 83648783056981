import {Injectable} from "@angular/core";
import {ApiService} from "@core/api/api.service";
import {DownloadRequestType, LookupLocale, Period, ResponseType, StandardReportType, SurveySubject, YearsInRole} from "@data/interefaces/lookup.interfaces";

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  constructor(private _apiService: ApiService) {
  }

  public getLookupLocales(): Promise<LookupLocale[]> {
    return this._apiService.makeGetServiceCall<LookupLocale[]>(`/v2/api/lookup/locale`);
  }

  public getYearsInRole(): Promise<YearsInRole[]> {
    return this._apiService.makeGetServiceCall<YearsInRole[]>(`/v2/api/lookup/years-in-role`);
  }

  public getResponseTypes(): Promise<ResponseType[]> {
    return this._apiService.makeGetServiceCall<ResponseType[]>(`/v2/api/lookup/response-type`);
  }

  public getPeriods(): Promise<Period[]> {
    return this._apiService.makeGetServiceCall<Period[]>(`/v2/api/lookup/period`);
  }

  public getSurveySubjects(): Promise<SurveySubject[]> {
    return this._apiService.makeGetServiceCall<SurveySubject[]>(`/v2/api/lookup/survey-subject`);
  }

  public getDownloadRequestTypes(): Promise<DownloadRequestType[]> {
    return this._apiService.makeGetServiceCall<DownloadRequestType[]>(`/v2/api/lookup/download-request-type`);
  }

  public getStandardReportTypes(): Promise<StandardReportType[]> {
    return this._apiService.makeGetServiceCall<StandardReportType[]>(`/v2/api/lookup/standard-report-type`);
  }

}
