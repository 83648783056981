import {Component} from '@angular/core';
import {GridApi, ICellRendererParams} from "@ag-grid-community/core";

@Component({
  selector: 'app-select-header',
  templateUrl: './select-header.component.html',
  styleUrls: ['./select-header.component.scss']
})
export class SelectHeaderComponent {
  params!: any;
  gridApi!: GridApi;
  isChecked = false;
  allSelected: boolean = false;
  isIndeterminate: boolean = false;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.gridApi = params.api;
    this.updateCheckboxState();
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.updateCheckboxState();
    return false;
  }

  someSelected(): boolean {
    return this.gridApi.getSelectedRows().length > 0 && !this.allSelected;
  }

  checkForAllSelected() {
    let isAllSelected = false;
    if (this.isGroupedGrid()) {
      // if it's a grouped grid, getSelectedRows does NOT return a list of rows include the group header rows.
      // Therefore, we must iterate through each node manually to determine if it's selected.
      this.gridApi.forEachNode((node) => {
        if (!node.isSelected()) {
          isAllSelected = false;
          return;
        } else {
          isAllSelected = true;
        }
      });
    } else {
      isAllSelected = this.gridApi.getSelectedRows().length > 0 && this.gridApi.getSelectedNodes().length === this.gridApi.getDisplayedRowCount();
    }
    return isAllSelected

  }

  checkForAlLSelectedOnCurrentPage() {
    return this.gridApi.getSelectedRows().length > 0 && this.getCountOfCurrentlySelectedItemsOnCurrentPage() === this.gridApi.getDisplayedRowCount();
  }

  selectAll() {
    this.calculateHeaderCheckBoxState();
    this.updateCheckboxState();
  }

  private calculateHeaderCheckBoxState() {
    // if all are selected, deselect all
    if (this.checkForAllSelected()) {
      this.deselectAllRows();
      return;
    }

    // if all on the current page are selected, selected all records
    if (this.checkForAlLSelectedOnCurrentPage()) {
      this.selectAllRowsOnCurrentPage();
      return;
    }

    // if the current selected items is less then the number of items of the paginated page size then select the current page only
    if (this.getCountOfCurrentlySelectedItemsOnCurrentPage() < this.getCountOfItemsOnPage()) {
      this.selectCurrentPageRows();
      return;
    }

    // default to selectAll
    this.selectAllFilteredRows();
  }

  /**
   * Gets the count of items on the page.  If it's the last page, return the count of rows on this page.
   * @private
   */
  private getCountOfItemsOnPage() {
    const pageSize = this.gridApi.paginationGetPageSize();
    if (this.isLastPage()) {
      const totalRows = this.gridApi.getDisplayedRowCount();
      // If totalRows is a perfect multiple of pageSize, last page has pageSize rows
      return totalRows % pageSize || pageSize;
    } else {
      return pageSize;
    }
  }

  private getCountOfCurrentlySelectedItemsOnCurrentPage() {
    const currentPageStart = this.gridApi.paginationGetPageSize() * this.gridApi.paginationGetCurrentPage();
    const currentPageEnd = currentPageStart + this.gridApi.paginationGetPageSize();
    let selectedCount = 0;

    // We will use the indexing calculated from the currentPageStart and currentPageEnd to retrieve all of the nodes in that range.
    // By calling getDisplayedRowAtIndex, we will ensure that the grouped rows are also included in this calculation.
    for (let i = currentPageStart; i < currentPageEnd; i++) {
      const node = this.gridApi.getDisplayedRowAtIndex(i);
      if (node && node.isSelected()) {
        selectedCount++;
      }
    }

    return selectedCount;
  }

  private deselectAllRows() {
    this.allSelected = false;
    this.gridApi.deselectAllFiltered();
  }

  private selectCurrentPageRows() {
    this.allSelected = false;
    this.gridApi.selectAllOnCurrentPage();
  }

  private selectAllFilteredRows() {
    this.allSelected = true;
    this.gridApi.selectAllFiltered();
  }

  private selectAllRowsOnCurrentPage() {
    this.gridApi.selectAllOnCurrentPage();
  }

  private updateCheckboxState() {
    if (this.checkForAllSelected()) {
      this.isIndeterminate = false;
      this.allSelected = true;
      this.isChecked = this.allSelected;
    } else if (this.someSelected()) {
      this.isIndeterminate = true;
      this.allSelected = false;
      this.isChecked = false;
    } else {
      this.isChecked = this.allSelected;
      this.isIndeterminate = false;
    }
  }

  private isLastPage() {
    const currentPage = this.gridApi.paginationGetCurrentPage();
    const totalPages = this.gridApi.paginationGetTotalPages();
    return currentPage === totalPages - 1;

  }

  private isGroupedGrid() {
    return this.gridApi.getRowGroupColumns().length > 0;
  }

}
