<mat-card class="card-content full-height-container backdrop" id="response-file-bulk">
  <mat-card-header class="mg-b-m">
    <div class="header-title">
      <mat-card-title class="primary-font-color">Survey Response File</mat-card-title>
      <mat-card-subtitle>Upload Files</mat-card-subtitle>
    </div>
  </mat-card-header>
  <mat-card-content class="form-container">
    <ag-file-upload (uploadFinished)="uploadFinished($event)"
                    [maxFileSizeSupported]="maxFileSizeSupported" [resetAfterUpload]="true"
                    [supportedFileTypes]="['xlsx', 'xls']">
    </ag-file-upload>
    <form (ngSubmit)="save()" class="form-field" id="uploadForm">
      <div class="flex-row custom-container mg-t-m">
        <div class="category-container">
          <mat-form-field class="mg-b-m">
            <mat-label>Description</mat-label>
            <textarea [formControl]="this.descriptionFormControl" matInput></textarea>
          </mat-form-field>
        </div>
        <div class="custom-right-container">
          <ag-slide-toggle
            (toggleUpdate)="util.setToggleValue($event.checked, this.forceOverwritingFormControl, false)"
            [isChecked]="util.isToggleChecked(this.forceOverwritingFormControl)"
            class="slide-toggle"></ag-slide-toggle>
          <span>Force Overwriting?</span>
        </div>
      </div>
      <div class="flex-row flex-g-s action-buttons mg-b-m">
        <div *ngIf="this.selectedFiles.length > 0">
          <button color="primary" mat-raised-button type="submit">
            <mat-icon class="material-symbols-outlined">upload</mat-icon>
            Upload
          </button>
          <button (click)="clearFiles()" mat-button type="button">
            <mat-icon class="material-symbols-outlined">undo</mat-icon>
            Clear
          </button>
        </div>
        <button (click)="close()" mat-button type="button">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
          Close
        </button>
      </div>
    </form>

    <ng-container *ngIf="selectedFiles.length != 0">
      <div *ngIf="selectedFiles.length > 0" style="height: 50vh;">
        <ag-grid-angular
          (columnVisible)="onColumnVisible($event)"
          (firstDataRendered)="onFirstDataRendered($event)"
          (gridReady)="onGridReady($event)"
          (viewportChanged)="onViewportChanged($event)"
          [columnDefs]="tableColumns"
          [rowData]="selectedFiles"
          [suppressPropertyNamesCheck]="true"
          class="ag-theme-quartz ag-grid-flex"
          style=" height: 100%;">
        </ag-grid-angular>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
