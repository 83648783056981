import {Injectable} from '@angular/core';
import {NotificationDecorator} from "@data/interefaces/notification.interface";
import {NotificationMessageType} from "@data/enums/data.enums";
import {DomSanitizer} from "@angular/platform-browser";
import {DownloadRequestNotificationDecorator} from "@data/notification/downloadrequest.notification-decorator";

// Import other decorators if needed

@Injectable({
  providedIn: 'root',
})
export class NotificationDecoratorFactory {
  private decorators = new Map<string, NotificationDecorator>();

  constructor(private _sanitizer: DomSanitizer) {
    const downloadRequestDecorator = new DownloadRequestNotificationDecorator(this._sanitizer);
    this.decorators.set(NotificationMessageType.DOWNLOAD_REQUEST, downloadRequestDecorator);
  }

  getDecorator(notificationType: string): NotificationDecorator | undefined {
    return this.decorators.get(notificationType);
  }
}
