import {Component} from '@angular/core';
import {FilterChangedEvent, IRowNode, SelectionColumnDef, ViewportChangedEvent} from "@ag-grid-community/core";
import {TableBaseComponent} from "@pages/table.base.component";
import {abortClickAction, cancelClickAction, pageContext, tableColumns} from "@pages/event-log/event-log.component.ds"
import {KpiSidebarItem} from "@shared/ag-kpi-sidebar/ag-kpi-sidebar.component.ds";
import {ActivatedRoute} from "@angular/router";
import {EventLog, EventLogLineChartData, EventMetadata} from "@data/interefaces/eventlog.interfaces";
import {EventLogService} from "@data/event/event.log.service";
import {Util} from "@data/util/util";
import {EventAction, NotificationType, TimeUnit} from "@data/enums/data.enums"
import {AgGridService} from "@shared/services/ag-grid.service";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss'],
  providers: [AgGridService]
})

export class EventLogComponent extends TableBaseComponent {
  currentMode: string = FeatureComponentMode.DATA;
  scale: string = TimeUnit.HOUR;
  selectedDataType: string = 'newEvents';
  eventType: string | null | undefined;
  processedBy: string | null | undefined;

  dataTypeOptions: { value: string, label: string, chartTitle: string }[] = [
    {value: 'newEvents', label: 'New Events', chartTitle: 'New Events'},
    {value: 'completedEvents', label: 'Completed Events', chartTitle: 'Completed Events'},
    {value: 'queueSize', label: 'Queue Size', chartTitle: 'Queue Size'},
    {value: 'avgQueueTime', label: 'Average Queue Time', chartTitle: 'Avg Time in Queue (min)'},
    {value: 'avgProcessingTime', label: 'Average Processing Time', chartTitle: 'Avg Processing Time (min)'}
  ];

  chartTitle = `Event Processing Charts`;
  refreshComplete: boolean = true;
  isFilterActive: boolean = false;
  rowData: EventLog[] = [];
  kpiSidebar: KpiSidebarItem[] = [];
  chartOptions: any;

  protected override readonly tableColumns = tableColumns;
  protected readonly pageContext = pageContext;
  private readonly chartDataAmount = 10;

  constructor(private _activatedRoute: ActivatedRoute, private _eventLogService: EventLogService) {
    super();
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.updatePageContext();
    this.refreshView();
  }

  switchMode(mode: string): void {
    this.currentMode = mode;
    if (!this.isDataMode()) {
      this.loadChartData();
    }
  }

  isDataMode(): boolean {
    return this.currentMode.toLowerCase() === 'data';
  }

  refreshView() {
    this.refreshComplete = false;
    this.displayProgressBar(true);
    this.eventType = this.pageContext.etc_f;
    this.processedBy = this.pageContext.pb_f;
    this._eventLogService.searchEventLog(this.eventType, null, this.processedBy, null, null)
      .then(result => {
        this.rowData = this.convertResults(result);
      })
      .catch(error => this.displayNotificationMessage('error', JSON.stringify(error)))
      .finally(() => {
        this.refreshComplete = true;
        this.displayProgressBar(false)
      });
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    const updateLiveDataFilter = this.isUpdateLiveDataFilterRequired(event.columns);
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this)).then(() => {
      if (updateLiveDataFilter) {
        this.onLiveDataFilterChange(true);
      }
    });
  }

  onLiveDataFilterChange(forceReload: boolean = false): void {
    this.updateToPageContextUrl();
  }

  onScaleChanged(selectedValue: string): void {
    this.scale = selectedValue;
    this.loadComponentsData();
  }

  onDataTypeChange(selectedValue: string): void {
    this.selectedDataType = selectedValue;
    this.loadChartData()
  }

  isGridGrouped(): boolean {
    const rowGroupColumns = this.gridApi?.getRowGroupColumns();
    return rowGroupColumns?.length > 0;
  }

  public getChartTitleByDataType(optionType: string): string {
    const option = this.dataTypeOptions.find(opt => opt.value === optionType);
    return option ? option.chartTitle : '';
  }

  protected override initAfterGridReady() {
    this.gridApi.addGlobalListener((eventType: any, event: any) => {
      switch (eventType) {
        case cancelClickAction: {
          this.executeEventAction(event.detail.rowData, EventAction.CANCEL);
          break;
        }
        case abortClickAction: {
          this.executeEventAction(event.detail.rowData, EventAction.ABORT);
          break;
        }
      }
    });
  }

  protected override updatePageContext(updateContextUrl: boolean = true): void {
    this.loadComponentsData();
  }

  protected override getSelectionColumnDef(): SelectionColumnDef {
    return {};
  }

  protected override onViewportChanged(event: ViewportChangedEvent<any>) {
    this.loadComponentsData();
  }

  private convertResults(eventLogs: EventLog[]): EventLog[] {
    const myEventLogs = Util.parseDateFields(eventLogs, ['createdDateTime', 'receivedDateTime', 'startDatetime', 'endDatetime']);
    return myEventLogs.map(log => {
      log.statusDisplay = log.processResult ?? log.eventStatus;
      log.businessIdentifiersDisplay = this.convertMetaData(log.businessIdentifiers);
      log.internalIdentifiersDisplay = this.convertMetaData(log.internalIdentifiers);
      log.tagsDisplay = this.convertMetaData(log.tags);
      if (log.metricsList && log.metricsList.length > 0) {
        log.metricsDisplay = JSON.stringify(log.metricsList);
      }
      return log;
    });
  }

  private convertMetaData(medaDataList: EventMetadata[] | undefined): string {
    if (medaDataList && medaDataList.length > 0) {
      return medaDataList.map(identifier => `${identifier.key}: ${identifier.value}`)
        .join('<br>');
    } else {
      return '';
    }
  }

  private loadComponentsData() {
    this.loadKpiMetrics();
    if (!this.isDataMode()) {
      this.loadChartData();
    }
  }

  private loadKpiMetrics() {
    this.kpiSidebar = this._eventLogService.getEventLogKpi(this.getFilteredData(), this.scale);
  }

  private loadChartData() {
    if (this.isGridGrouped()) {
      this.loadChartDataWithGroup();
    } else {
      this.loadChartDataWithoutGroup();
    }
  }

  private loadChartDataWithGroup() {
    const groupedChartData = this._eventLogService.getGroupedEventLogLineChartData(this.getGroupFilteredData(), this.scale, this.chartDataAmount);
    const mergedGroupedData = this.mergeGroupedChartDataByDataType(groupedChartData);
    const title = this.getChartTitleByDataType(this.selectedDataType);
    const count = ['newEvents', 'completedEvents', 'queueSize'].includes(this.selectedDataType);
    const yAxisLabel = count ? 'Count' : 'Minutes';
    const series = Object.keys(groupedChartData).map(groupName => ({
      type: count ? 'area' : 'line',
      xKey: 'time',
      yKey: groupName,
      title: groupName,
      stacked: count,
      marker: {enabled: false},
    }));

    const axes = [
      this.createBottomAxisConfig(this.scale),
      {
        type: 'number',
        position: 'left',
        title: {text: yAxisLabel},
      },
      {
        type: 'number',
        position: 'right',
        title: {text: yAxisLabel},
      }
    ];
    this
      .chartOptions = {
      title: {
        text: title,
      },
      data: mergedGroupedData,
      series: series,
      axes: axes,
      legend: {
        position: 'bottom'
      }
    }
  }

  private getGroupFilteredData(): Map<string, EventLog[]> {
    const groupMap: Map<string, EventLog[]> = new Map();
    if (this.gridApi && !this.gridApi.isDestroyed()) {
      this.gridApi.forEachNodeAfterFilter((node: any) => {
        if (node.group && node.level === 0 && node.key) {
          const groupValue = node.key;
          groupMap.set(groupValue, []);
          this.collectRowsUnderGroup(node, groupMap.get(groupValue)!);
        }
      });
    }
    return groupMap;
  }

  private collectRowsUnderGroup(groupNode: IRowNode, rows: EventLog[]) {
    groupNode.childrenAfterFilter?.forEach(childNode => {
      if (childNode.group) {
        this.collectRowsUnderGroup(childNode, rows);
      } else {
        rows.push(childNode.data);
      }
    });
  }

  private mergeGroupedChartDataByDataType(groupedChartData: { [key: string]: EventLogLineChartData[] }) {
    const mergedData: { [key: number]: any } = {};

    Object.keys(groupedChartData).forEach(group => {
      groupedChartData[group].forEach(chartData => {
        const time = chartData.time;
        if (!mergedData[time]) {
          mergedData[time] = {time};
        }

        mergedData[time][group] = chartData[this.selectedDataType as keyof EventLogLineChartData];
      });
    });
    return Object.values(mergedData);
  }

  private loadChartDataWithoutGroup() {
    const chartData: EventLogLineChartData[] = this._eventLogService.getEventLogLineChartData(this.getFilteredData(), this.scale, this.chartDataAmount);
    const series = this.getSeriesConfig();
    const axes = this.getAxesConfig(this.scale);
    this.chartOptions = {
      title: {
        text: this.chartTitle,
      },
      data: chartData,
      series: series,
      axes: axes,
      legend: {
        position: 'bottom'
      }
    };
  }

  private getFilteredData(): EventLog[] {
    return this.getRowsAfterFilter().filter(row => !!row);
  }

  private getSeriesConfig(): any[] {
    return [
      {
        type: 'line',
        xKey: 'time',
        yKey: 'newEvents',
        yName: this.getChartTitleByDataType('newEvents'),
        stroke: 'blue',
        marker: {enabled: false},
      },
      {
        type: 'line',
        xKey: 'time',
        yKey: 'completedEvents',
        yName: this.getChartTitleByDataType('completedEvents'),
        stroke: 'green',
        marker: {enabled: false},
      },
      {
        type: 'line',
        xKey: 'time',
        yKey: 'queueSize',
        yName: this.getChartTitleByDataType('queueSize'),
        stroke: 'red',
        marker: {enabled: false},
      },
      {
        type: 'line',
        xKey: 'time',
        yKey: 'avgQueueTime',
        yName: this.getChartTitleByDataType('avgQueueTime'),
        stroke: 'orange',
        marker: {enabled: false},
      },
      {
        type: 'line',
        xKey: 'time',
        yKey: 'avgProcessingTime',
        yName: this.getChartTitleByDataType('avgProcessingTime'),
        stroke: 'purple',
        marker: {enabled: false},
      }
    ];
  }

  private getAxesConfig(timeUnit: string): any[] {
    return [
      this.createBottomAxisConfig(timeUnit),
      {
        type: 'number',
        position: 'left',
        title: {text: 'Count'},
        id: 'countAxis',
        keys: ['newEvents', 'completedEvents', 'queueSize'],
      },
      {
        type: 'number',
        position: 'right',
        title: {text: 'Minutes'},
        id: 'minutesAxis',
        keys: ['avgQueueTime', 'avgProcessingTime']
      }
    ];
  }

  private createBottomAxisConfig(timeUnit: string): any {
    return {
      type: 'number',
      position: 'bottom',
      title: {text: 'Time'},
      min: 0,
      max: 10,
      label: {
        formatter: (params: any) => Util.formatChartTimeLabel(params.value, timeUnit),
      },
      reverse: true,
      interval: {step: 1},
    };
  }

  private executeEventAction(eventLog: EventLog, action: string) {
    if (eventLog?.id) {
      const message = action === EventAction.CANCEL ? "Are you sure you want to cancel this event?"
        : "Are you sure you want to abort this event?";
      const actionAPi = action === EventAction.CANCEL ? this._eventLogService.cancelEvent(eventLog.id)
        : this._eventLogService.abortEvent(eventLog.id);
      this.displayDialogMessage(NotificationType.WARNING, message, undefined, false)
        .then(result => {
          if (result) {
            actionAPi.then(apiResult => {
              eventLog.eventStatus = apiResult.eventStatus;
              eventLog.statusDisplay = eventLog.eventStatus;
              this.gridApi.setGridOption('rowData', this.rowData);
            })
          }
        })
        .catch(error => this.displayNotificationMessage('error', JSON.stringify(error)))
        .finally(() => {
          this.refreshComplete = true;
          this.displayProgressBar(false)
        });
    }
  }
}
