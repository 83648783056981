<ag-task (goBackEvent)="goBackEvent.emit($event)" [isUrlMode]="isUrlMode" [selectedFeatureMode]="selectedFeatureMode"
         [sidebarEl]="featureComponentSidebarEl" [urlModeGoBackUrl]="urlModeGoBackUrl" class="full-height-container"
         id="task-component">
  <ng-template agClassifier="{{FeatureComponentMode.ADD_ROW}}">
    <download-request-add
      class="task-component-detail full-height-container"></download-request-add>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.ADD_LEGACY}}">
    <download-request-add-legacy
      class="task-component-detail full-height-container"></download-request-add-legacy>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.DETAIL}}">
    <download-request-detail
      class="task-component-detail full-height-container"></download-request-detail>
  </ng-template>
</ag-task>
