import {Component} from '@angular/core';
import {FileRequestStatus, SurveySubject, YesNoValue} from "@data/enums/data.enums";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Util} from "@data/util/util";
import {ProductSpecService} from "@data/product-spec/product.spec.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {CompanyWeightSet, DataItem, ProductPeriod, RespondentGroupPeriod} from "@data/interefaces/data.interfaces";
import {RespondentGroupPeriodService} from "@data/product-spec/respondent.group.period.service";
import {CompanyWeightSetService} from "@data/product-spec/company.weight.set.service";
import {LovService} from "@data/common/lov.service";
import {DetailBaseComponent} from "@pages/detail.base.component";

@Component({
  selector: 'app-product-period-detail',
  templateUrl: './product-period-detail.component.html',
  styleUrls: ['./product-period-detail.component.scss']
})
export class ProductPeriodDetailComponent extends DetailBaseComponent {

  selectedItem: ProductPeriod = {} as ProductPeriod;
  approvalStatus: string = "";
  respondentGroupPeriodList: RespondentGroupPeriod[] | undefined;
  companyWeightSet: CompanyWeightSet[] | undefined;
  approvalStatusList: DataItem[] | undefined;
  maskingOverrideList: DataItem[] | undefined;
  productSummaryFileStatusList: FileRequestStatus[] | undefined;
  languagesList: string[] | undefined;

  formGroup: FormGroup<{ [K in keyof ProductPeriod]?: FormControl<any> }> = new FormGroup<any>([]);
  pageContext: {
    [key: string]: boolean | string | undefined;
    p_f: string | undefined;
    pi_f: string | undefined;
  } = {
    p_f: undefined,
    pi_f: undefined
  };
  protected readonly SurveySubject = SurveySubject;

  constructor(private _productSpecService: ProductSpecService, private _resGroupPeriodService: RespondentGroupPeriodService,
              private _companyWeightSetService: CompanyWeightSetService, private _lovService: LovService) {
    super();
  }

  override ngOnInit(): void {
    this.displayProgressBar(true);
    super.ngOnInit();
  }

  onAllRespondentCompaniesChanged(event: MatSlideToggleChange) {
    Util.setToggleValue(event.checked, this.getFormControl("allRespondentCompanies"), false);
  }

  isSurveySubjectSelected(surveySubjectEnum: SurveySubject) {
    return this.getFormControlValue("surveySubject") == surveySubjectEnum.toString();
  }

  getFormControl(controlName: (keyof ProductPeriod)) {
    return this.formGroup.get(controlName) as FormControl<any>;
  }

  getFormControlValue(controlName: (keyof ProductPeriod)) {
    if (!this.getFormControl(controlName) || !this.getFormControl(controlName).value) {
      return "";
    }
    return this.getFormControl(controlName).value;
  }

  protected override loadDropDownData() {
  }

  protected override getPageState(): any {
    return this.pageContext;
  }

  protected onIncludeChanged(checked: boolean) {
    let includeFormControl = this.getFormControl('include');
    includeFormControl.setValue(checked ? YesNoValue.YES_VALUE : YesNoValue.NO_VALUE);
    includeFormControl.markAsDirty({onlySelf: false});
    this.toggleAllControls(checked, [this.getFormControl('include')]);
  }

  protected override getContentDetails() {
    if (this.pageContext.p_f && this.pageContext.pi_f) {
      this._productSpecService.getProductPeriodById(this.pageContext.p_f, this.pageContext.pi_f).then(result => {
        this.selectedItem = result;
        if (this.selectedItem.approvalStatus !== 'Approved') {
          this.isEditMode = true;
        }
        this.loadData();
        this.loading = false;
        this.displayProgressBar(false);
      });
    }
  }

  protected override saveOrUpdate() {
    return this._productSpecService.updateProductPeriod(Util.mapFormGroupValuesToObject<ProductPeriod>(this.formGroup.getRawValue()));
  }

  protected override initFormGroup() {
    this.formGroup = new FormGroup<{ [K in keyof ProductPeriod]?: FormControl<any> }>(
      {
        period: new FormControl({
          value: this.selectedItem.period,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        marketCode: new FormControl({
          value: this.selectedItem.marketCode,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        marketName: new FormControl({
          value: this.selectedItem.marketName,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        industryCode: new FormControl({
          value: this.selectedItem.industryCode,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        industryName: new FormControl({
          value: this.selectedItem.industryName,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        surveySubject: new FormControl({
          value: this.selectedItem.surveySubject,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        productCode: new FormControl({
          value: this.selectedItem.productCode,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        productName: new FormControl({
          value: this.selectedItem.productName,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        include: new FormControl({
          value: this.selectedItem.include,
          disabled: !this.isEditMode
        }),
        respondentGroupCode: new FormControl({
          value: this.selectedItem.respondentGroupCode,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }, Validators.required),
        languages: new FormControl({
          value: this.selectedItem.languages,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }, Validators.required),
        allRespondentCompanies: new FormControl({
          value: this.selectedItem.allRespondentCompanies,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        standardRespondentWeightSetCode: new FormControl({
          value: this.selectedItem.standardRespondentWeightSetCode,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }, Validators.required),
        productPeriodTrendAlignments: new FormControl({
          value: this.selectedItem.productPeriodTrendAlignments,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        maskedByDefault: new FormControl({
          value: this.selectedItem.maskedByDefault,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        maskingOverride: new FormControl({
          value: this.selectedItem.maskingOverride,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }, Validators.required),
        approvalStatus: new FormControl({
          value: this.selectedItem.approvalStatus,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }, Validators.required),
        approvalDatetime: new FormControl({
          value: this.selectedItem.approvalDatetime,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        approvedChecksumDatetime: new FormControl({
          value: this.selectedItem.approvedChecksumDatetime,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        pendingChecksumDatetime: new FormControl({
          value: this.selectedItem.pendingChecksumDatetime,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        productSummaryFileStatus: new FormControl({
          value: this.selectedItem.productSummaryFileStatus,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        productSummaryFileCreatedDatetime: new FormControl({
          value: this.selectedItem.productSummaryFileCreatedDatetime,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        productSummaryFileUrl: new FormControl({
          value: this.selectedItem.productSummaryFileUrl,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        productSummaryFilePassword: new FormControl({
          value: this.selectedItem.productSummaryFilePassword,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        productId: new FormControl({
          value: this.selectedItem.productId,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
        productPeriodId: new FormControl({
          value: this.selectedItem.productPeriodId,
          disabled: !this.isEditMode || this.selectedItem.include != YesNoValue.YES_VALUE
        }),
      });
  }

  private loadData() {
    this.loadDropDownsByFilters();
    this.initFormGroup();
    this.loadProductSummaryFileStatusDropdown();
  }

  private loadDropDownsByFilters() {
    let period = this.selectedItem.period;
    let marketCode = this.selectedItem.marketCode;
    let industryCode = this.selectedItem.industryCode;
    let surveySubject = this.selectedItem.surveySubject;
    this._resGroupPeriodService.getRespondentGroup(period, marketCode, industryCode, surveySubject).then(result => {
      this.respondentGroupPeriodList = result;
    });
    this._companyWeightSetService.getCompanyWeightSet(period, marketCode, industryCode, surveySubject).then(result => {
      this.companyWeightSet = result;
    });
    this._lovService.getApprovalStatusList().then(result => {
      this.approvalStatusList = result.listElements;
    });
    this._lovService.getMaskingOverrideList().then(result => {
      this.maskingOverrideList = result.listElements;
    });
    this._productSpecService.getProductReportLabel(this.selectedItem.productPeriodId, this.selectedItem.period, this.selectedItem.marketCode, this.selectedItem.industryCode,
      this.selectedItem.surveySubject).then(result => {
      this.languagesList = result.map<string>(element => {
        return element.language;
      });
    });
  }

  private loadProductSummaryFileStatusDropdown() {
    let fileStatus = this.getFormControl('productSummaryFileStatus').value;
    this.productSummaryFileStatusList = [fileStatus, Util.getFileRequestStatusTransition(fileStatus)];
  }

}
