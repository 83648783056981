import {Injectable} from '@angular/core';
import {DownloadRequestData} from "@data/interefaces/download.request.interfaces";
import {DownloadRequestType, LookupLocale, Period, SurveySubject} from "@data/interefaces/lookup.interfaces";
import {ViewIndustry, ViewMarket} from "@data/interefaces/reference.interfaces";
import {RespondentSegmentLabel, SubjectLabel, WeightedProductLabel} from "@data/interefaces/product.interfaces";

@Injectable({
  providedIn: 'root'
})
export class AdvantageReportService {
  private readonly DEFAULT_PRIORITY: number = 5;

  constructor() {
  }

  /**
   * Creates the default download request parameters to be used for the download requests
   * @param currentBatchCode
   * @param selectedPeriod
   * @param selectedMarket
   * @param selectedIndustry
   * @param selectedSurveySubject
   * @param selectedDownloadType
   * @param selectedLocale
   * @param originalBatchCodeFromRetry
   */
  public createDownloadRequestWithDefaultValues(currentBatchCode: string, selectedPeriod?: Period, selectedMarket?: ViewMarket, selectedIndustry?: ViewIndustry,
                                                selectedSurveySubject?: SurveySubject, selectedDownloadType?: DownloadRequestType, selectedLocale?: LookupLocale,
                                                originalBatchCodeFromRetry?: string) {
    const downloadRequest: DownloadRequestData = {
      period: selectedPeriod?.period,
      marketId: selectedMarket?.marketId?.toString(),
      marketCode: selectedMarket?.marketCode,
      marketLabel: selectedMarket?.marketName,
      industryId: selectedIndustry?.industryId?.toString(),
      industryCode: selectedIndustry?.industryCode,
      industryLabel: selectedIndustry?.industryName,
      surveySubject: selectedSurveySubject?.surveySubject,
      downloadRequestTypeId: selectedDownloadType?.downloadRequestTypeId?.toString(),
      downloadRequestTypeValue: selectedDownloadType?.downloadRequestTypeValue,
      downloadRequestTypeLabel: selectedDownloadType?.downloadRequestTypeDisplayValue,
      language: selectedLocale?.language,
      priority: this.DEFAULT_PRIORITY,
      batchCode: currentBatchCode,
      originalBatchCode: originalBatchCodeFromRetry ? originalBatchCodeFromRetry : undefined
    };
    return downloadRequest;
  }

  /**
   * Sets all the required download request parameters for the selected product.
   * @param downloadRequest
   * @param selectedProduct
   */
  public setDownloadRequestProduct(downloadRequest: DownloadRequestData, selectedProduct?: WeightedProductLabel) {
    if (downloadRequest && selectedProduct) {
      downloadRequest.productCode = selectedProduct.productCode;
      downloadRequest.weightedProductLabel = selectedProduct.displayValue;
      downloadRequest.productPeriodId = selectedProduct.productPeriodId.toString();
      downloadRequest.sponsorProductSaleAccessId = selectedProduct.sponsorProductSaleAccessId.toString();
    }
  }

  /**
   * Sets all the required download requested parameters for the selected sponsor
   * @param downloadRequest
   * @param selectedSponsor
   */
  public setDownloadRequestSponsor(downloadRequest: DownloadRequestData, selectedSponsor: SubjectLabel) {
    downloadRequest.sponsorId = selectedSponsor.subjectId.toString();
    downloadRequest.sponsorCode = selectedSponsor.subjectCode;
    downloadRequest.sponsorLabel = selectedSponsor.subjectLabel;
    downloadRequest.standardReportTypeId = selectedSponsor.standardReportTypeId.toString();
    downloadRequest.approvalStatus = selectedSponsor.approvalStatus;
    downloadRequest.productViewId = selectedSponsor.productViewId.toString();
    let modifiedProductViewIdList = selectedSponsor.productViewIdList;
    if (modifiedProductViewIdList && !modifiedProductViewIdList.startsWith('_')) {
      modifiedProductViewIdList = '_' + modifiedProductViewIdList;
    }
    downloadRequest.productViewIdList = modifiedProductViewIdList;
  }

  /**
   * Sets all the required download request parameters for the selected respondents
   * @param downloadRequest
   * @param selectedRespondents
   */
  public setDownloadRequestRespondents(downloadRequest: DownloadRequestData, selectedRespondents: RespondentSegmentLabel[]) {
    downloadRequest.respondentSegments = selectedRespondents.map(selectedRespondent => selectedRespondent.respondentSegmentId);
    downloadRequest.respondentCompanyLabel = selectedRespondents.map(selectedRespondent => selectedRespondent.respondentSegmentLabel).join(';');
    downloadRequest.respondentCompanyCode = selectedRespondents.map(selectedRespondent => selectedRespondent.respondentSegmentCode).join(';');
  }

  /**
   * Sets all the required download request parameters for the tableau project
   * @param downloadRequest
   */
  public setDownloadRequestTableauProject(downloadRequest: DownloadRequestData) {
    downloadRequest.tableauProject = "";
  }
}
