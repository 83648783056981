import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {ProductRespondentRollup, ProductRespondentRollupReportLabel} from "@data/interefaces/data.interfaces";

@Injectable({
  providedIn: 'root'
})
export class RespondentRollupService {

  constructor(private _apiService: ApiService) {
  }

  public getRespondentCompanyRollupList(productPeriodIdList: string): Promise<ProductRespondentRollup[]> {
    return this._apiService.makeGetServiceCall<ProductRespondentRollup[]>(`/v2/api/product-spec/res-rollup`, `productPeriodIdList=${productPeriodIdList}`);
  }

  public getRespondentRollupReportLabelList(productPeriodId: number, code: string) {
    return this._apiService.makeGetServiceCall<ProductRespondentRollupReportLabel[]>(`/v2/api/product-spec/rollup-report-label`,
      `productPeriodId=${productPeriodId}&code=${code}`);
  }

}
