<div (fileDropped)="fileDropped($event)" agDnd class="file-upload" draggable="true" id="file-upload">
  <div class="flex-column mg-t-l">
    <div>
      <input #file (change)="handleFileSelection($event)" [multiple]="false" class="hideInputButton" onClick="this.value=null;" type="file"/>
      <i class="fa-sharp fa-solid fa-folder-arrow-up fa-5x"></i>
    </div>
    <div *ngIf="!fileSelected" class="mg-t-l">
      <div>
        Drag and drop files here
      </div>
      <div class="mg-t-s">
        - OR -
      </div>
      <div>
        <button (click)="file.click()" class="mg-t-m" color="primary" type="button" mat-raised-button>
          Click To Open
        </button>
      </div>
    </div>
    <div *ngIf="fileSelected">
      <h3><span>{{ fileName }} </span> ({{ getFileSizeDisplayString(fileSize) }}) </h3>
      <div class="flex-row flex-g-m">
        <div>
          <span>{{ getFileSizeDisplayString(uploadedSize) }} of {{ getFileSizeDisplayString(fileSize) }} Uploaded</span>
        </div>
        <div class="right-align">
          <h3>{{ progress }}%</h3>
        </div>
      </div>
      <div class="progressBarContainer">
        <mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>
      </div>
      <div *ngIf="messageText" class="flex-row flex-g-m mg-t-m">
        <i *ngIf="messageType == 'ERROR'" class="fa-sharp fa-thin fa-circle-exclamation fa-2x error-color"></i>
        <i *ngIf="messageType == 'INFO'" class="fa-sharp fa-thin fa-check fa-2x primary-color"></i>
        <span>{{ messageText }}
          <button (click)="reset();file.click()" color="primary" mat-button>...select a different file</button></span>
      </div>
    </div>
  </div>
</div>
