import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'ag-slide-toggle',
  templateUrl: './ag-slide-toggle.component.html',
  styleUrls: ['./ag-slide-toggle.component.scss']
})
export class AgSlideToggleComponent implements OnChanges {
  @Input() isChecked: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() formControlInput: FormControl | null = null;
  @Input() colorType: 'error' | '' = '';
  @Output() toggleUpdate: EventEmitter<MatSlideToggleChange> = new EventEmitter();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isDisabled'] && changes['isDisabled'].currentValue && this.formControlInput) {
      this.formControlInput.disable();
    } else if (changes['isDisabled'] && !changes['isDisabled'].currentValue && this.formControlInput) {
      this.formControlInput.enable();
    }
  }

  triggerToggleUpdate(event: MatSlideToggleChange) {
    this.toggleUpdate.emit(event);
  }
}
