
<div class="full-height-container">
  <mat-card class="full-height-container">
    <mat-card-content class="content-panel full-height-container">
      <div class="flex-row">
        <app-component-header (refreshClickEvent)="this.refreshView()"
                              [hideColumnsButton]="true"
                              [startRefreshTimer]="this.refreshComplete"
                              [subtitleSegments]="getUrlSegments()"
                              class="full-width"></app-component-header>
      </div>
      <div class="flex-row table-container">
        <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                         (filterChanged)="onFilterChanged($event)"
                         (firstDataRendered)="onFirstDataRendered($event)"
                         (gridReady)="onGridReady($event)"
                         (viewportChanged)="onViewportChanged($event)"
                         [columnDefs]="tableColumns"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         [paginationPageSize]="50"
                         [pagination]="true"
                         [rowData]="dataSource"
                         [suppressPaginationPanel]="true"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz full-width"
                         rowSelection="multiple">
        </ag-grid-angular>

      </div>
    </mat-card-content>
  </mat-card>

</div>

<ag-side-panel #configDetailsSidePanel size="xlarge" subTitle="Apply the default run parameters" title="Default Run Parameters">
  <app-transfer-config-detail [readonly]="true" [transferCode]="selectedTransferCode"></app-transfer-config-detail>
</ag-side-panel>
