import {DetailBaseComponent} from "../../detail.base.component";
import {Component, ElementRef, ViewChild} from "@angular/core";
import {ViewSurvey} from "@data/interefaces/survey.spec.interfaces";
import {SurveySpecService} from "@data/survey-spec/survey.spec.service";
import {VerbatimFileInfo} from "@data/interefaces/verbatim.file.interfaces";
import {FormControl, FormGroup} from "@angular/forms";
import {DataItem, OperationStatus} from "@data/interefaces/data.interfaces";
import {VerbatimService} from "@data/verbatim/verbatim.service";
import {FeatureComponentMode} from "@data/enums/data.enums";
import {Util} from "@data/util/util";
import {Location} from "@angular/common";


@Component({
    selector: 'verbatim-file-upload',
    templateUrl: './verbatim-file-upload.component.html',
    styleUrls: ['./verbatim-file-upload.component.scss']
})
export class VerbatimFileUploadComponent extends DetailBaseComponent {
    override featureComponentMode = FeatureComponentMode.UPLOAD;
    surveyCodeList: ViewSurvey[] | undefined;
    override formGroup: FormGroup<{ [K in keyof VerbatimFileInfo]?: FormControl<any> }> = new FormGroup<any>([]);
    override loading = false;
    selectedFile: File | null = null;
    maxFileSizeSupported = 1024 * 1024 * 100; //100MB
    override saveAndClose = true;
    @ViewChild('fileUpload') fileUpload: ElementRef | undefined;
    override pageContext: {
        [key: string]: number | undefined;
        vfi_f: number | undefined;
    } = {
        vfi_f: undefined
    };


    constructor(private _surveySpecService: SurveySpecService, private _verbatimService: VerbatimService, private _location : Location) {
        super();
        this.title = 'Upload New';
    }

    override ngOnInit(): void {
        // call displayProgressBar before calling super class.
        this.displayProgressBar(false);
        super.ngOnInit();
    }

    protected override loadDropDownData() {
        this._surveySpecService.getSurveys().then(result => {
            this.surveyCodeList = result;
        });
    }

    protected override initFormGroup(): void {
        this.formGroup = new FormGroup<{ [K in keyof VerbatimFileInfo]?: FormControl<any> }>(
            {
                addSubjectCompany: new FormControl({
                    value: 'N',
                    disabled: false
                }),
                surveyCode: new FormControl({
                    value: null,
                    disabled: false
                }),
                description: new FormControl({
                    value: null,
                    disabled: false
                })
            })
    }


    protected getFormControl(controlName: (keyof VerbatimFileInfo)) {
        return this.getControl<VerbatimFileInfo>(controlName);
    }

    protected getContentDetails(): void {
        this.initFormGroup();
    }

    protected saveOrUpdate(): Promise<OperationStatus> | Promise<DataItem> {
        this.displayProgressBar(true);
        return this._verbatimService.uploadFile(this.selectedFile, Util.mapFormGroupValuesToObject<VerbatimFileInfo>(this.formGroup.value));

    }
    
    uploadFinished(event: { status: number; message: string; file?: File }) {
        if (event.status == 0 && event.file) {
            this.selectedFile = event.file;
        }
    }


}
