import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {ICellRendererParams} from "@ag-grid-community/core";
import {StoreService} from "@core/api/store.service";
import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {Util} from "@data/util/util";
import {LinkType} from "@data/enums/data.enums";

@Component({
  selector: 'app-multi-line-link-column',
  templateUrl: './multi-line-link-column.component.html',
  styleUrls: ['./multi-line-link-column.component.scss']
})
export class MultiLineLinkColumnComponent implements ICellRendererAngularComp {
  params!: any
  links: any[] = [];
  value?: string;
  subText?: string;
  pageContext?: any;
  routerLink?: string;
  queryParams?: any;
  actionEvent?: string;
  filterId?: any;
  toolTip?: string;

  constructor(private _store: StoreService) {
  }

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.value = this.params.value;
    this.subText = this.params.subText;
    this.links = this.params.links;
    this.actionEvent = this.params.actionEvent;
    this.pageContext = this.params.currentPageContext;
    this.routerLink = this.params.routerLink;
    this.filterId = this.params.filterId;
    this.toolTip = this.params.toolTip;
    this.createUrl(this.links);
  }

  createUrl(links: any) {
    if (links) {
      for (const link of links) {
        if (link.linkType === LinkType.EXTERNAL) {
          link.url = this.createExternalLinkUrl(link);
        } else if (link.linkType === LinkType.INTERNAL) {
          link.queryParams = this.createInternalLinkParams(this.pageContext);
        }
      }
    }
    return undefined;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  onLinkClick(linkEvent?: string) {
    if (linkEvent !== undefined && linkEvent !== null && linkEvent !== '') {
      const clickEvent = new CustomEvent(linkEvent, {
        detail: {
          rowData: this.params.data
        }
      });
      this.params.api.dispatchEvent(clickEvent);
    }
  }

  private createExternalLinkUrl(link: any) {
    let params = '';
    for (const param of link.quilliupForm.params) {
      const colDef = this.findColDefByHeaderName(this.params.api.getColumnDefs(), param) as GridColumn;
      if (colDef !== undefined && colDef.field) {
        params += `${colDef.headerName}=${this.params.data[colDef.field]}&`
      }
    }
    return `${Util.getQuilliupGlobalPageLink(this._store.env, link.quilliupForm.guid)}?${params}${this.params.column.colDef.headerName}=${this.params.value}`;
  }

  /**
   * Generate a JSON object (link params), such that each key represent a filter id, each value represent a value of that filter.
   *
   * Steps:
   * 1. Check if we already have that params
   * 2. If the params not exists yet, formulate a params like `icl_f=Acqua%26Sapone%20Cesar&p_f=2023&` (encoded)
   *
   * @param pageContext
   * @private
   */
  private createInternalLinkParams(pageContext: any) {
    let params = '';
    Object.keys(pageContext).some((filterId) => {
      if (pageContext[filterId] !== undefined && pageContext[filterId] !== null) {
        params = this.getPageContextInternalLinkParams(pageContext, filterId, params);
      } else if (filterId == this.filterId) {
        params += `${filterId}=${encodeURIComponent(this.value!)}&`; //encode value to avoid wrong string replacement below
      }
    });

    return Util.getDecodedJSONObjectFromEncodedParamString(params);
  }

  private getPageContextInternalLinkParams(pageContext: any, filterId: string, params: string) {
    if (typeof pageContext[filterId] === 'boolean') {
      if (pageContext[filterId]) {
        params += `${filterId}=${pageContext[filterId]}&`;
      }
    } else {
      params += `${filterId}=${pageContext[filterId]}&`;
    }
    return params;
  }

  private findColDefByHeaderName(columns: (GridColumn | GridColumnGroupDef)[] | undefined, headerName: string): GridColumn | GridColumnGroupDef | undefined {
    if (columns !== undefined) {
      for (const col of columns) {
        const gridColumn = col as GridColumn;
        if (gridColumn !== undefined) {
          if (gridColumn.headerName === headerName) {
            return col;
          }
        }
        const gridColumnGroupDef = col as GridColumnGroupDef;
        if (gridColumnGroupDef !== undefined) {
          if (gridColumnGroupDef.children) {
            const column = this.findColDefByHeaderName(gridColumnGroupDef.children, headerName);
            if (column !== undefined) {
              return column;
            }
          }
        }
      }
    }
    return undefined;
  }
}
