import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {BatchProcessResult, DataItem, DataList, ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {ProductSponsorVerbatim} from "@data/interefaces/product.sponsor.verbatim.interfaces";
import {Util} from "@data/util/util";

@Injectable({
  providedIn: 'root'
})
export class ProductSponsorVerbatimService {

  constructor(private _apiService: ApiService) {
  }

  public getProductSponsorVerbatimByFilter(
    periods: string,
    marketCodes: string,
    industryCodes: string,
    surveySubjects: string,
    productCodes: string
  ): Promise<ProductSponsorVerbatim[]> {
    let queryParams: string[] = [];
    Util.pushQueryParam("period", periods, queryParams);
    Util.pushQueryParam("marketCode", marketCodes, queryParams);
    Util.pushQueryParam("industryCode", industryCodes, queryParams);
    Util.pushQueryParam("surveySubject", surveySubjects, queryParams);
    Util.pushQueryParam("productCode", productCodes, queryParams);

    return this._apiService.makeGetServiceCall<ProductSponsorVerbatim[]>(`/v2/api/product-spec/product-sponsor-verbatim`,
      queryParams.join('&'));
  }

  public getProductSponsorVerbatimThresholds(
    periods: string,
    marketCodes: string,
    industryCodes: string,
    surveySubjects: string,
    productCodes: string
  ): Promise<ResultsetThresholds> {
    let queryParams: string[] = [];
    Util.pushQueryParam("period", periods, queryParams);
    Util.pushQueryParam("marketCode", marketCodes, queryParams);
    Util.pushQueryParam("industryCode", industryCodes, queryParams);
    Util.pushQueryParam("surveySubject", surveySubjects, queryParams);
    Util.pushQueryParam("productCode", productCodes, queryParams);

    return this._apiService.makeGetServiceCall<ResultsetThresholds>(`/v2/api/product-spec/product-sponsor-verbatim/thresholds`,
      queryParams.join('&'));
  }

  public getLatestProductSponsorVerbatimBatchProcess() {
    return this._apiService.makeGetServiceCall<BatchProcessResult>(`/v2/api/product-spec/product-sponsor-verbatim/bulk-process/latest`);
  }

  public startProductSponsorVerbatimBatchProcess(productSponsorVerbatims: ProductSponsorVerbatim[], processType: string) {
    let queryParams: string[] = [];
    Util.pushQueryParam("processType", processType, queryParams);
    return this._apiService.makePutServiceCall<{ id: number }>(`/v2/api/product-spec/product-sponsor-verbatim/bulk-process/start`, queryParams.join('&'), productSponsorVerbatims);
  }

  public includeProductSponsorVerbatim(productSaleId: number, verbatimId: number, include: boolean) {
    let queryParams: string[] = [];
    Util.pushQueryParam("include", "" + include, queryParams);
    return this._apiService.makePostServiceCall<DataItem>(
      `/v2/api/product-spec/product-sponsor-verbatim/${productSaleId}/${verbatimId}/include`,
      queryParams.join('&')
    );
  }
}
