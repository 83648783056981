import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Util} from "@data/util/util";
import {GridColumn} from "@data/interefaces/data.interfaces";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'ag-hide-show-column',
  templateUrl: './ag-hide-show-column.component.html',
  styleUrls: ['./ag-hide-show-column.component.scss'],
  providers: [AgGridService]
})
export class AgHideShowColumnComponent implements OnInit {
  @Input({required: true}) tableColumns: any;
  @Output() columnToggled: EventEmitter<GridColumn> = new EventEmitter<GridColumn>();
  protected checkboxTasks: CheckboxTask[] | undefined;

  constructor(private _agGridService: AgGridService) {
  }

  ngOnInit(): void {
    this.checkboxTasks = this.getCheckboxTasks();
    for (const checkboxTask of this.checkboxTasks) {
      this.reloadAllChecked(checkboxTask);
    }
  }

  getRestSubtasks(task: CheckboxTask): GridColumn[] {
    return task.name === "Rest" ? task.subtasks : [];
  }

  reloadAllChecked(task: CheckboxTask) {
    task.allChecked = task.subtasks != null && task.subtasks.every(t => t.display);
  }

  updateAllChecked(task: CheckboxTask) {
    this.reloadAllChecked(task);
    if (task.subtasks == null) {
      return;
    }
    task.subtasks.forEach(t => this.toggleCheckEvent(t));
  }

  someChecked(task: CheckboxTask): boolean {
    if (task.subtasks == null) {
      return false;
    }
    return task.subtasks.filter(t => t.display).length > 0 && !task.allChecked;
  }

  someDisabled(task: CheckboxTask): boolean {
    if (task.subtasks == null) {
      return false;
    }
    return task.subtasks.filter(t => t.isMandatory).length > 0;
  }

  setAll(checked: boolean, task: CheckboxTask) {
    task.allChecked = checked;
    if (task.subtasks == null) {
      return;
    }
    task.subtasks.forEach(t => {
      t.display = checked;
      this.toggleCheckEvent(t);
    });
  }

  toggleCheckEvent(subtask: any) {
    this.columnToggled.emit(subtask);
  }

  protected getCheckboxTasks(): CheckboxTask[] {
    const tasks: CheckboxTask[] = [];
    const gridColumns = this._agGridService.getGridColumns(this.tableColumns);

    Object.values(Util.getGridColumnsWithUniqueGroupBy(gridColumns))
      .forEach(gc => tasks.push({
        name: gc.groupBy,
        allChecked: false,
        subtasks: Util.getGridColumnsByGroupBy(gc.groupBy, gridColumns)
      }));

    tasks.push({name: "Rest", allChecked: false, subtasks: gridColumns.filter(gc => !gc.groupBy)});
    return tasks;
  }
}

interface CheckboxTask {
  name: string | undefined;
  allChecked: boolean;
  subtasks: GridColumn[];
}
