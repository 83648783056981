<ng-template #sidenavTemplate id="side-panel">
  <div class="mg-l-m mg-r-m side-panel-header">
    <i (click)="toggle()" class="fa-sharp fa-light fa-xmark-large primary-color"></i>
  </div>
  <div *ngIf="showProgress">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <mat-card [class.backdrop]="showProgress" class="card-content backdrop">
    <mat-card-header *ngIf="title || subTitle" class="mg-b-m">
      <mat-card-title>{{ title }}</mat-card-title>
      <mat-card-subtitle>{{ subTitle }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ng-content></ng-content>
    </mat-card-content>
  </mat-card>

</ng-template>
