export enum EventType {
  NOTIFICATION, PROGRESS, SIDE_NAVIGATION
}

export interface ApplicationEvent {
  type: EventType;
  data: any;
}

export interface UserCache {
  principleName: string;
  pages: PagesCache[];
}

export interface PagesCache {
  pageName: string;
  columns: ColumnCache[];
}

export interface ColumnCache {
  columnDef: string;
  hide: boolean;
}
