import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ag-datepicker',
  templateUrl: './ag-datepicker.component.html',
  styleUrls: ['./ag-datepicker.component.scss']
})
export class AgDatepickerComponent {

  @Input() label: string = "Choose a Date";
  @Output() onDateSelected: EventEmitter<Date> = new EventEmitter();

  onDateChanged(date: Date) {
    this.onDateSelected.emit(date);
  }

}
