<mat-grid-list [cols]="2" class="home" rowHeight="4:3">
  <mat-grid-tile class="">
    <div class="home-info pd-l-m">
      <h1>Welcome to the Advantage Research Management (ARM) Console!</h1>
      <p>Use the available views to interface with our research data and report deliverables.</p>
      <p>You are logged in as {{ this._store.principleName }}</p>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <img alt="data trends" fill="" ngSrc="assets/undraw_data_trends_b0wg.svg" priority="">
  </mat-grid-tile>
</mat-grid-list>
