import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SurveyResponseFileService} from "@data/survey-response-file/survey.response.file.service";
import {ExcelSurveyFileUpload, SurveyResponseError} from "@data/interefaces/survey.response.file.interfaces";
import {TableBaseComponent} from "@pages/table.base.component";
import {tableColumns} from "@pages/survey-response-file/response-file-detail/response-file-detail.component.ds"
import {AgGridService} from "@shared/services/ag-grid.service";
import {SelectionColumnDef} from "@ag-grid-community/core";


@Component({
  selector: 'app-response-file-detail',
  templateUrl: './response-file-detail.component.html',
  styleUrls: ['./response-file-detail.component.scss'],
  providers: [AgGridService]
})
export class ResponseFileDetailComponent extends TableBaseComponent {
  rowData: SurveyResponseError[] = [];
  responseFileId: Number | undefined;
  loading: boolean = true;
  protected override tableColumns = tableColumns;
  protected override pageContext = {};
  protected excelSurveyFileUpload: ExcelSurveyFileUpload | undefined;

  constructor(private _activatedRoute: ActivatedRoute, private _surveyResponseFileService: SurveyResponseFileService,
  ) {
    super();
    this._activatedRoute.queryParamMap.subscribe(params => {
      this.responseFileId = Number(params.get('id'));
    });
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.refreshView();
  }

  refreshView(): void {
    this.loading = true;
    this.displayProgressBar(true);
    this._surveyResponseFileService.getSurveyResponseFileById(this.responseFileId!)
      .then(result => {
        if (result.replacementId == null) {
          result.overwritten = "Not Overwritten";
        } else {
          result.overwritten = "Overwritten";
        }
        this.excelSurveyFileUpload = result;
        if (result.processingDetails != null) {
          this.rowData = JSON.parse(result.processingDetails);
        }
      })
      .catch(error => {
          this.displayNotificationMessage('error', JSON.stringify(error));
        }
      ).finally(() => {
      this.displayProgressBar(false);
      this.loading = false;
    });
  }

  protected override updatePageContext(updateContextUrl: boolean): void {
    throw new Error('Method not implemented.');
  }

  protected override getSelectionColumnDef(): SelectionColumnDef {
    return {};
  }
}
