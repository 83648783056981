import {Component} from '@angular/core';
import {EventbusService} from "src/app/core/event-bus/eventbus.service";
import {ApplicationEvent, EventType} from "src/app/core/interfaces/data.interfaces";

@Component({
  selector: 'app-progressbar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

  showProgress = false;

  constructor(_eventBus: EventbusService) {
    _eventBus.register(EventType.PROGRESS, (event: ApplicationEvent) => this.showProgress = event.data);
  }

}
