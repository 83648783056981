import {FilterContext, GridColumn, GridColumnGroupDef, TransferData} from "@data/interefaces/data.interfaces";
import {SelectionModel} from "@angular/cdk/collections";
import {MatTableDataSource} from "@angular/material/table";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MatChipSelectionChange} from "@angular/material/chips";
import {FormControl} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {DateRange} from "@angular/material/datepicker";
import {QuilliupEnvironment} from "@data/enums/quilliup.enum";
import {LegacyDownloadRequestEnvironment} from "@data/enums/downloadRequest.enum";
import XLSX from "xlsx";
import {FileRequestStatus, TimeUnit, YesNoValue} from "@data/enums/data.enums";
import {LegacyVerbatimFileRowsEnvironment, LegacyVerbatimFilesEnvironment} from "@data/enums/verbatim.enum";

export class Util {

  /**
   * This method is used to compare the date time values for the ag-grid cells.
   *
   * @param filterValue The value of the filter text box.  This will default to the date value at midnight (0 hh, 0 mm, 0 ss)
   * @param cellValue The value of the data in the cell.
   */
  public static dateComparator(filterValue: Date, cellValue: string): number {
    // The field is a Date object, which means that ag-grid date filter should be naturally handling
    // the filtering, however, it is not.  So we created this simple dateComparator to get the filtering
    // to work.

    if (cellValue == null) return 0;
    let cellDate = new Date(cellValue);
    cellDate.setHours(0, 0, 0, 0); // remove the timestamp so that it will only compare the day / month / year.

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterValue) {
      return -1; // -1 will hide the row because the data values are before the specified filter date
    } else if (cellDate > filterValue) {
      return 1; // 1 will also hide the row because their date values are after the specified filter data
    }
    return 0; // 0 is shown because their value matches the filter date.
  }

  public static labelCodeComparator(filterOption: string, value: string, filterText: string, altText?: string) {

    if (filterText == null) {
      return false;
    }
    switch (filterOption) {
      case 'contains':
        return value.includes(filterText) || (altText !== undefined && altText !== null && altText.includes(filterText));
      case 'notContains':
        return !value.includes(filterText) || (altText !== undefined && altText !== null && altText.includes(filterText));
      case 'equals':
        return value === filterText || (altText !== undefined && altText !== null && altText === filterText);
      case 'notEqual':
        return value !== filterText || (altText !== undefined && altText !== null && altText !== filterText);
      case 'startsWith':
        return value.startsWith(filterText) || (altText !== undefined && altText !== null && altText.startsWith(filterText));
      case 'endsWith':
        return value.endsWith(filterText) || (altText !== undefined && altText !== null && altText.endsWith(filterText));
      default:
        return false;
    }
  }

  public static filterPredicate(record: any, filter: string): boolean {
    let filterJson = JSON.parse(filter);
    let recColumnToContextMap: Map<string, any[]> = new Map<string, any[]>();
    Object.keys(filterJson).filter(key => filterJson[key] != undefined && (filterJson[key] as FilterContext).filterValue != undefined)
      .forEach((key, index) => {
        let fContext: FilterContext = filterJson[key] as FilterContext
        let mapKey = fContext.recColumnName + "|" + (fContext.operation == undefined ? "=" : fContext.operation) + "|"
          + (fContext.joinRecColumnWith == "AND" ? index : '');
        let mappedContext = recColumnToContextMap.get(mapKey);
        if (mappedContext == undefined) {
          recColumnToContextMap.set(mapKey, [fContext.filterValue]);
        } else {
          mappedContext.push(fContext.filterValue);
        }
      });

    if (recColumnToContextMap.size == 0) {
      return true;
    }

    let resultStatus: boolean | undefined = undefined;
    recColumnToContextMap.forEach((value, key) => {
      let thisFilterStatus: boolean = false;
      let recColumnDataSplit: string[] = key.split('|');
      let columnList: string[] = recColumnDataSplit[0].split(',');
      columnList.forEach(recColumn => {
        let recColumnValue = record[recColumn.trim()];
        if (recColumnValue !== undefined && recColumnValue !== null) {
          let operation: string = recColumnDataSplit[1];
          if (operation == "LIKE") {
            value.forEach(filterValue => {
              if (Array.isArray(filterValue) && filterValue.length > 0) {
                filterValue.forEach(fv => {
                  thisFilterStatus = thisFilterStatus || (recColumnValue.toString().indexOf(fv) != -1);
                })
              } else {
                thisFilterStatus = thisFilterStatus || (recColumnValue.toString().indexOf(filterValue) != -1);
              }
            });
          } else if (operation == "BTN") {
            if (recColumn.endsWith("Date") || recColumn.endsWith("Datetime")) {
              let recordDate = new Date(recColumnValue);
              value.forEach(filterValue => {
                let dateRange = filterValue.split('|');
                thisFilterStatus = thisFilterStatus || (recordDate >= new Date(dateRange[0]) && recordDate <= new Date(dateRange[1]));
              });
            } else if (typeof recColumnValue == 'number') {
              value.forEach(filterValue => {
                let numberRange = filterValue.split('|');
                thisFilterStatus = thisFilterStatus || (recColumnValue >= numberRange[0] && recColumnValue <= numberRange[1]);
              });
            }
          } else if (operation == "=") {
            value.forEach(filterValue => {
              thisFilterStatus = thisFilterStatus || (typeof recColumnValue == "boolean" ? recColumnValue == Boolean(filterValue) : recColumnValue == filterValue);
            });
          }
        }
      });
      resultStatus = resultStatus == undefined ? thisFilterStatus : resultStatus && thisFilterStatus;
    });

    return resultStatus == undefined ? false : resultStatus;
  }

  /**
   * this would return the number 0, 1, or 2 correspondingly, if no records are selected, all the records are selected or some records are selected.
   * @param dataSource datasource for the table
   * @param selection the selection model.
   */
  public static getSelectionStatus(dataSource: MatTableDataSource<any>, selection: SelectionModel<any>) {
    let selectedRecords = Util.getSelectedRecords(dataSource, selection).length;
    return selectedRecords == 0 ? 0 : dataSource._pageData(dataSource.data).length == selectedRecords ? 1 : 2;
  }

  public static selectAllRows(dataSource: MatTableDataSource<any>, selection: SelectionModel<any>) {
    selection.select(...dataSource._pageData(dataSource.data));
  }

  public static deselectAllSelected(dataSource: MatTableDataSource<any>, selection: SelectionModel<any>) {
    selection.deselect(...dataSource._pageData(dataSource.data));
  }

  public static getSelectedRecords(dataSource: MatTableDataSource<any>, selection: SelectionModel<any>): any[] {
    if (selection.hasValue()) {
      let pageData = dataSource._pageData(dataSource.data);
      return pageData.filter(element => selection.isSelected(element));
    }

    return [];
  }

  public static checkFilterActive(pageContext: any): boolean {
    return Object.values(pageContext).some(value => {
      if (typeof value === 'boolean') {
        return value;
      } else if (value !== undefined && typeof value === 'string' && value !== '') {
        return true;
      }
      return false;
    });
  }

  public static checkLiveDataFilterActive(filterContext: any): boolean {
    return Object.values(filterContext).some(element => {
      return (element as FilterContext).filterValue != undefined && (element as FilterContext).liveDataFilter;
    });
  }

  public static toggleAllRows(event: MatCheckboxChange, dataSource: MatTableDataSource<any>, selectedRows: SelectionModel<any>) {
    let selectionStatus = this.getSelectionStatus(dataSource, selectedRows);
    if (selectionStatus == 1 || selectionStatus == 2) {
      Util.deselectAllSelected(dataSource, selectedRows)
      event.source.toggle();
    } else {
      Util.selectAllRows(dataSource, selectedRows);
    }
  }

  public static isToggleChecked(formControl: FormControl<any>) {
    return formControl.value?.toUpperCase() === YesNoValue.YES_VALUE;
  }

  public static setToggleValue(set: boolean, formControl: FormControl<any>, onlySelf?: boolean) {
    // Set to YesNoValue.YES_VALUE for now.  This may need to be changed to handle different values in the future.
    formControl.setValue(set ? YesNoValue.YES_VALUE : YesNoValue.NO_VALUE);
    // mark as dirty to trigger field as edited
    formControl.markAsDirty({onlySelf: onlySelf === undefined ? true : onlySelf});
  }

  public static enableBulkAction(dataSource: MatTableDataSource<any>, selectedRows: SelectionModel<any>) {
    let selectionStatus = this.getSelectionStatus(dataSource, selectedRows);
    return selectionStatus == 1 || selectionStatus == 2
  }

  public static calculateTimeDifferent(start: Date, end: Date) {
    let time = end.getTime() - start.getTime();
    return this.formatDuration(Math.round(time/1000));
  }

  public static calculateDuration(startDatetime: Date, endDatetime: Date): string | Date {
    if (!startDatetime) {
      return new Date();
    }
    if (!endDatetime) {
      endDatetime = new Date();
    }

    const duration = this.calculateTimeDifferent(new Date(startDatetime), new Date(endDatetime));
    return duration;
  }

  public static calculateDurationInSeconds(startDatetime: Date, endDatetime: Date): number {
    if (!startDatetime) {
      return 0;
    }
    if (!endDatetime) {
      endDatetime = new Date();
    }
    let milliseconds = new Date(endDatetime).getTime() - new Date(startDatetime).getTime();

    const totalSeconds = Math.floor(milliseconds / 1000);
    return totalSeconds;
  }


  /**
   * Validates the duration as HH:mm:ss
   * @param time as HH:mm:ss
   */
  public static validateDurationTime(time: string) {
    return true;
  }

  static updateFilterContext(filterContext: any, keyValue: string, filterValue: any) {
    let filterContextElement = filterContext[keyValue] as FilterContext;
    filterContextElement.filterValue = filterValue;
  }

  public static onBooleanFilterChange(event: MatChipSelectionChange, pageContext: any, filterContext: any, updatePageContextCallBack: () => void, filterValue?: any) {
    pageContext[event.source.id] = event.selected;
    event.selected ? this.updateFilterContext(filterContext, event.source.id, filterValue) : this.updateFilterContext(filterContext, event.source.id, undefined);
    updatePageContextCallBack();
  }

  public static onDateRangeFilterChange(isoDateRange: string | undefined, pageContext: any, pageContextKey: string, filterContext: any, filterContextKey: string, updatePageContextCallBack: () => void) {
    filterContext[filterContextKey].filterValue = isoDateRange;
    pageContext[pageContextKey] = isoDateRange;
    updatePageContextCallBack();
  }

  public static onInputFilterChange(pageContext: any, filterContext: any, contextKey: string, updatePageContextCallBack: (liveDataFilter?: boolean) => void) {
    pageContext[contextKey] == '' ? filterContext[contextKey].filterValue = undefined : filterContext[contextKey].filterValue = pageContext[contextKey];
    if (pageContext[contextKey] === '') {
      pageContext[contextKey] = undefined;
    }

    updatePageContextCallBack(filterContext[contextKey].liveDataFilter);
  }

  public static onRangeChange(pageContext: any, pageContextKey: string, filterContext: any, filterContextKey: string, range: string, rangeValidationMessage: string, startRangeKey: string, endRangeKey: string, startDurationControl: FormControl<any>, endDurationControl: FormControl<any>, updatePageContextCallBack: () => void) {
    if (range === undefined) {
      // Used for initial startWith rxjs, ensure everything is set to undefined so nothing unexpected happens.
      pageContext[pageContextKey] = undefined;
      filterContext[filterContextKey].filterValue = undefined;
      updatePageContextCallBack();
      return;
    }
    let currentForm = this.getCurrentForm(pageContextKey, startRangeKey, startDurationControl, pageContext, range, endRangeKey, endDurationControl, filterContext, filterContextKey);
    if (this.isNumberRange(pageContext, startRangeKey, endRangeKey)) {
      this.setNumberRange(pageContext, startRangeKey, endRangeKey, filterContext, filterContextKey);
    } else {
      this.validateAndSetDateDuration(range, pageContext, pageContextKey, currentForm, rangeValidationMessage, startRangeKey, endRangeKey, filterContext, filterContextKey);
    }
    updatePageContextCallBack();
  }

  public static getFormattedDate(date: Date | undefined) {
    if (date) {
      const formattedDate = new DatePipe('en-US').transform(date, 'short');
      if (formattedDate === null) {
        return "-";
      }
      return formattedDate;
    }
    return "-";
  }

  public static getFormattedDateTimestamp(date: Date) {
    return new DatePipe('en-Us').transform(date, 'dd-MM-yyyy HH:mm:ss');
  }

  public static getFormattedResponseProgressPercent(responseProgress: string | undefined): string {
    if (!responseProgress) {
      return "";
    }

    // Regular expression to extract numbers in the format "X of Y"
    const match = responseProgress.match(/(\d+)\s+of\s+(\d+)/);
    if (!match) {
      return "";
    }

    // Extract rated and total from the match
    const rated = parseInt(match[1], 10);
    const total = parseInt(match[2], 10);

    // Validate parsed numbers
    if (isNaN(rated) || isNaN(total) || total === 0) {
      return "";
    }

    // Calculate and return the percentage
    const percentage = (rated / total) * 100;
    return `${Math.round(percentage)}%`;
  }

  public static getDownloadableColumnNames(gridColumns: GridColumn[] | GridColumnGroupDef[]): string[] {
    let returnArray: string[] = [];
    gridColumns.forEach(data => {
      let gridColumnDef = data as GridColumnGroupDef;
      if (gridColumnDef.children) {
        returnArray.push(...Util.getDownloadableColumnNames(gridColumnDef.children));
      } else {
        let gridColumn = data as GridColumn;
        if (gridColumn.downloadable != false) {
          returnArray.push(gridColumn.field as string);
        }
      }
    });

    return returnArray;
  }

  public static getGridColumnsByGroupBy(groupBy: string | undefined, gridColumns: GridColumn[]): GridColumn[] {
    return Object.values(gridColumns).filter((gridColumn) => gridColumn.groupBy == groupBy);
  }

  public static getGridColumnsWithUniqueGroupBy(gridColumns: GridColumn[]) {
    return Object.values(gridColumns).filter((gc, i, arr) => gc.groupBy && arr.findIndex(g => g.groupBy === gc.groupBy) === i);
  }

  public static getQuilliupGlobalPageLink(environment: string | undefined, quilliupForm: string | undefined) {
    if (environment) {
      let quilliupEnvironment = QuilliupEnvironment[environment.toUpperCase() as keyof typeof QuilliupEnvironment];
      if (quilliupEnvironment && quilliupForm) {
        return `${quilliupEnvironment}editor/${quilliupForm}`;
      }
    }
    return QuilliupEnvironment.DEV;
  }

  public static getLegacyDownloadRequestPageLink(environment: string | undefined) {
    if (environment) {
      let downloadRequestEnvironment = LegacyDownloadRequestEnvironment[environment.toUpperCase() as keyof typeof LegacyDownloadRequestEnvironment];
      if (downloadRequestEnvironment) {
        return downloadRequestEnvironment;
      }
    }
    return LegacyDownloadRequestEnvironment.DEV;
  }

  public static getLegacyVerbatimFilesPageLink(environment: string | undefined) {
    if (environment) {
      let verbatimFilesEnvironment = LegacyVerbatimFilesEnvironment[environment.toUpperCase() as keyof typeof LegacyVerbatimFilesEnvironment];
      if (verbatimFilesEnvironment) {
        return verbatimFilesEnvironment;
      }
    }
    return LegacyVerbatimFilesEnvironment.DEV;
  }

  public static getLegacyVerbatimFileRowsPageLink(environment: string | undefined) {
    if (environment) {
      let verbatimFileRowsEnvironment = LegacyVerbatimFileRowsEnvironment[environment.toUpperCase() as keyof typeof LegacyVerbatimFileRowsEnvironment];
      if (verbatimFileRowsEnvironment) {
        return verbatimFileRowsEnvironment;
      }
    }
    return LegacyVerbatimFilesEnvironment.DEV;
  }

  public static getLegacySurveyResponseFilePageLink(environment: string | undefined) {
    let url: string;
    if (environment) {
      url = Util.getLegacyUrl(environment);
    } else {
      url = Util.getLegacyUrl('dev');
    }
    return url + 'survey-files';
  }

  public static getLegacyUrl(environment: string | undefined) {
    return "https://a2console-" + environment + ".advantagegroup.com/"
  }

  public static pushQueryParam(paramKey: string, paramValue: string, queryParams: string[]) {
    if (paramValue) {
      queryParams.push(`${encodeURIComponent(paramKey)}=${encodeURIComponent(paramValue)}`);
    }
    return queryParams;
  }

  public static getFiltersParams(params: string) {
    return params.length > 0 ? params.slice(0, -1) : "";
  }

  public static sliceArray(array: any[], chunkSize: number) {
    let returnArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      returnArray.push(chunk);
    }

    return returnArray;
  }

  public static calculatePercentage(numerator: number, denominator: number) {
    if (denominator == 0) {
      return 0;
    }
    return Math.round(numerator / denominator * 100);
  }

  public static async readXlsSheetAsJson(file: File, sheetNumber: 0, columDef: (GridColumn | GridColumnGroupDef)[]) {
    let sheet: XLSX.WorkSheet;
    await file.arrayBuffer().then(buffer => {
      let workBook = XLSX.read(buffer);
      sheet = workBook.Sheets[workBook.SheetNames[sheetNumber]];
    });
    return Util.populateRowDataFromJson(XLSX.utils.sheet_to_json(sheet!), columDef);
  }

  public static updateSelectedRows<T extends { [key: string]: any }>(selectedRows: T[], formGroupValue: { [K in keyof T]?: any }): T[] {
    let updatedRows: T[] = selectedRows.map(row => ({...row}));
    const formGroupObject: T = this.mapFormGroupValuesToObject<T>(formGroupValue);
    // Only update it if the formGroupsValue is not null.  If it's null it means nothing has been selected
    // So we will not override the existing value.
    const propertiesToUpdate = Object.keys(formGroupObject).filter(propertyName => (formGroupObject[propertyName] !== undefined && formGroupObject[propertyName] !== null) &&
      updatedRows.some(row => propertyName in row));

    // Iterate through all the rows and update with only the properties that have values.
    for (let row of updatedRows) {
      propertiesToUpdate.forEach(propertyName => {
        (row as any)[propertyName] = formGroupObject[propertyName]
      });
    }

    return updatedRows;
  }

  public static convertDelimitedStringToArray(delimitedString?: string) {
    if (delimitedString !== undefined && delimitedString !== null) {
      return delimitedString.split(';').map(item => item.trim());
    }
    return [];
  }

  public static convertArrayToDelimitedString(array: any[]) {
    return array.join('; ');
  }

  public static mapFormGroupValuesToObject<T>(formGroupValues: any) {
    const mappedObject: T = Object.keys(formGroupValues).reduce((accumulator, propertyName) => {
      const value = (accumulator as any)[propertyName] = (formGroupValues as any)[propertyName];
      (accumulator as any)[propertyName] = value;
      if (Array.isArray(value)) {
        if (value.length > 1) {
          (accumulator as any)[propertyName] = this.convertArrayToDelimitedString(value);
        } else {
          (accumulator as any)[propertyName] = value?.toString();
        }
      }
      return accumulator;
    }, {} as T);

    return mappedObject;
  }

  public static formatDashSeperatedStringToDisplayLabel(text: string): string {
    let words = text.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(' ');
  }

  public static getUrlSegments(urlPath: string): string[] {
    let urlSegments: string[] = [];
    urlPath?.split('/').forEach(element => {
      if (element.length > 0) {
        let split = element.split('?');
        urlSegments.push(split[0]);
      }
    });

    return urlSegments;
  }

  public static formatDuration(totalSeconds: number) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  public static parseDuration(filterValue: string): number {
    let durationValues = filterValue.split(':');
    return parseInt(durationValues[0]) * 3600 + parseInt(durationValues[1]) * 60 + parseInt(durationValues[2]);
  }

  public static getFileRequestStatusTransition(fileRequestStatus: string) {
    switch (fileRequestStatus) {
      case FileRequestStatus.NONE:
        return FileRequestStatus.REQUESTED;
      case FileRequestStatus.REQUESTED:
        return FileRequestStatus.NONE;
      case FileRequestStatus.IN_PROGRESS:
        return FileRequestStatus.IN_PROGRESS;
      case FileRequestStatus.COMPLETED:
        return FileRequestStatus.REQUESTED;
      case FileRequestStatus.COMPLETED_WITH_ERRORS:
        return FileRequestStatus.REQUESTED;
      default:
        return FileRequestStatus.NONE;
    }
  }

  /**
   * Given an encoded param string, transform it to a decoded JSON object.
   *
   * 1. Formulate a json string from the param, like `{"icl_f": "Acqua%26Sapone%20Cesar","p_f": "2023"}`
   * 2. Formulate a json object from the json string, with value decoded , like `{"icl_f": "Acqua&Sapone Cesar","p_f": "2023"}`
   * @param params a string representing a set of encoded parameters (e.g. filter parameters), params are like `icl_f=Acqua%26Sapone%20Cesar&p_f=2023&`
   (   */
  public static getDecodedJSONObjectFromEncodedParamString(params: string): Object {

    const cleanedParams = this.getFiltersParams(params)
      .replace(/"/g, '\\"') // Escape double quotes
      .replace(/&/g, '","') // Replace & with '","' to create valid key-value pairs
      .replace(/=/g, '":"'); // Replace = with '":"'
    const jsonString = '{"' + cleanedParams + '"}';

    try {
      // Parse the JSON string
      let jsonObject = JSON.parse(jsonString);

      // Decode each value in the object
      Object.keys(jsonObject).forEach((key) => {
        jsonObject[key] = decodeURIComponent(jsonObject[key]);
      });
      return jsonObject;
    } catch (error) {
      console.error("Error parsing JSON object from JSON string, the string is: ", jsonString);
      return {}; // Return an empty object in case of error
    }
  }

  public static parseDateFields<T>(data: T[], dateFields: (keyof T)[]): T[] {
    return data.map(item => {
      dateFields.forEach(field => {
        if (item[field] && typeof item[field] === 'string') {
          item[field] = new Date(item[field] as unknown as string) as unknown as T[keyof T];
        }
      });
      return item;
    });
  }

  public static getTimeUnitMultiplier(timeUnit: string): number {
    const millisecond = 1000;
    const secondsInHour = 3600;
    switch (timeUnit.toLowerCase()) {
      case TimeUnit.SECOND.toLowerCase():
        return millisecond;
      case TimeUnit.MINUTE.toLowerCase():
        return 60 * millisecond;
      case TimeUnit.DAY.toLowerCase():
        return 24 * secondsInHour * millisecond;
      case TimeUnit.WEEK.toLowerCase():
        return 7 * 24 * secondsInHour * millisecond;
      default: // Hour
        return secondsInHour * millisecond;
    }
  }

  public static formatDateToLocalString(value: any): string {
    return value ? new Date(value).toLocaleDateString() : '';
  }

  public static formatToHourMinuteString(value: any): string {
    return value ? new Date(value).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false}) : '';
  }

  public static formatChartTimeLabel(value: number, timeUnit: string): string {
    const currentDate = new Date();
    const timeUnitMultiplier = Util.getTimeUnitMultiplier(timeUnit);
    const beginTime = new Date(currentDate.getTime() - value * timeUnitMultiplier);
    if (timeUnit.toLowerCase() === TimeUnit.MINUTE.toLowerCase() || timeUnit.toLowerCase().toLowerCase() === TimeUnit.HOUR.toLowerCase()) {
      return Util.formatToHourMinuteString(beginTime);
    } else if (timeUnit.toLowerCase() === TimeUnit.DAY.toLowerCase() || timeUnit.toLowerCase() === TimeUnit.WEEK.toLowerCase()) {
      return Util.formatDateToLocalString(beginTime);
    }
    return `${value} ${timeUnit} ago`;
  }

  /**
   * This method will do a deep compare of two objects.
   * @param obj1
   * @param obj2
   * @param propertiesToIgnore an array of properties of the object that you can choose to ignore in the deep comparison
   */
  public static deepCompare(obj1: any, obj2: any, propertiesToIgnore: string[] = []) {
    // If both are strictly equal, we can return true;
    if (obj1 === obj2) {
      return true;
    }

    // If either is null, return false, since we can't do any comparison
    if (obj1 == null || obj2 == null) {
      return false;
    }

    // We only want to compare object types, so if either of them is not an object, return false;
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return false;
    }

    // We don't want to use any item in the TransferData interface in our comparison, because data off the pipe
    // will always have a different createdDateTime.

    const keysForObj1 = Object.keys(obj1);
    const keysForObj2 = Object.keys(obj2);

    const filteredKeysForObj1 = keysForObj1.filter((key: any) => !propertiesToIgnore.includes(key as keyof TransferData));
    const filteredKeysForObj2 = keysForObj2.filter((key: any) => !propertiesToIgnore.includes(key as keyof TransferData));

    // If the key counts don't match up, the object can't be equal, so return false
    if (filteredKeysForObj1.length !== filteredKeysForObj2.length) {
      return false;
    }

    // finally iterate over each key and compare the value 1 by 1.
    for (let key of filteredKeysForObj1) {
      // check if the key exists, if not return false.
      if (!filteredKeysForObj2.includes(key)) {
        return false;
      }

      // recursively call itself to check value.  Should return true of obj1 === obj2 because of the value strict comparison
      // it also shouldn't hit the is typeof object check.
      if (!this.deepCompareTransferDataObjects(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }

  /**
   * This method will do a deep object compare of two objects that inherit from the TransferData interface.
   * It will ignore any properties from TransferData in the comparison, as things like createdDateTime will always be different.
   * @param obj1
   * @param obj2
   */
  public static deepCompareTransferDataObjects(obj1: any, obj2: any): boolean {
    const transferDataInterfacePropertiesToIgnore = this.getTransferDataProperties();
    return this.deepCompare(obj1, obj2, transferDataInterfacePropertiesToIgnore);
  }

  /**
   * This method will properly reset an individual form control and reset any validators associated with it.
   * The default value it resets to is null.
   * @param formControl
   * @param resetValue
   */
  public static resetFormControl(formControl?: FormControl, resetValue: any = null) {
    formControl?.reset(resetValue, {emitEvent: false});
    formControl?.clearValidators();
    formControl?.updateValueAndValidity({emitEvent: false});
  }

  private static getTransferDataProperties(): (keyof TransferData)[] {
    return ['objId', 'createdDateTime', 'lastUpdateDatetime', 'VersionIndex'];
  }

  private static isNumberRange(pageContext: any, startRangeKey: string, endRangeKey: string) {
    return !isNaN(Number(pageContext[startRangeKey])) || !isNaN(Number(pageContext[endRangeKey]));
  }

  private static setNumberRange(pageContext: any, startRangeKey: string, endRangeKey: string, filterContext: any, filterContextKey: string) {
    const start = pageContext[startRangeKey] ? pageContext[startRangeKey] : 0;
    const end = pageContext[endRangeKey] ? pageContext[endRangeKey] : Number.MAX_VALUE;
    filterContext[filterContextKey].filterValue = start + '|' + end;
  }

  private static validateAndSetDateDuration(duration: string, pageContext: any, pageContextKey: string, currentForm: FormControl<any>, durationValidationMessage: string, startDurationKey: string, endDurationKey: string, filterContext: any, filterContextKey: string) {
    const isValidDuration = Util.validateDurationTime(duration)
    if (isValidDuration) {
      pageContext[pageContextKey] = duration;
    } else {
      // set validation failure
      if (pageContext[pageContextKey] === undefined) {
        // reset error if undefined
        currentForm.setErrors(null);
        currentForm.markAllAsTouched();
      } else {
        currentForm.setErrors({validationError: durationValidationMessage});
        currentForm.markAllAsTouched();
      }
    }

    if ((pageContext[startDurationKey] !== undefined && Util.validateDurationTime(pageContext[startDurationKey])) &&
      (pageContext[endDurationKey] !== undefined && Util.validateDurationTime(pageContext[endDurationKey]))) {
      const startDate = new Date(`01/01/1970 ${pageContext[startDurationKey]}`);
      const endDate = new Date(`01/01/1970 ${pageContext[endDurationKey]}`);
      const dateRange = new DateRange(startDate, endDate);
      filterContext[filterContextKey].filterValue = dateRange.start?.toISOString() + '|' + dateRange.end?.toISOString();
    }
  }

  private static getCurrentForm(pageContextKey: string, startDurationKey: string, startRangeControl: FormControl<any>, pageContext: any, range: string, endDurationKey: string, endRangeControl: FormControl<any>, filterContext: any, filterContextKey: string) {
    let currentForm: FormControl<any> | undefined = undefined;
    // Sets the value of the control if it is not initially set.
    switch (pageContextKey) {
      case startDurationKey:
        currentForm = startRangeControl;
        if (pageContext[startDurationKey] !== undefined && (startRangeControl.value === undefined || startRangeControl.value === null)) {
          startRangeControl.setValue(range);
        }
        break;
      case endDurationKey:
        currentForm = endRangeControl;
        if (pageContext[endDurationKey] !== undefined && (endRangeControl.value === undefined || endRangeControl.value === null)) {
          endRangeControl.setValue(range);
        }
        break;
    }
    if (currentForm === undefined) {
      throw new Error("currentForm is undefined.");
    }
    pageContext[pageContextKey] = range;
    if (pageContext[pageContextKey] === undefined || pageContext[pageContextKey] == '') {
      pageContext[pageContextKey] = undefined;
      filterContext[filterContextKey].filterValue = undefined;
    }
    return currentForm;
  }

  private static padZero(num: number) {
    return num.toString().padStart(2, '0');
  }

  private static buildColumnHeaderToPropertyMap(gridColumns: GridColumn[] | GridColumnGroupDef[]) {
    let returnMap: Map<string, string> = new Map<string, string>();
    gridColumns.forEach(data => {
      let gridColumnDef = data as GridColumnGroupDef;
      if (gridColumnDef.children) {
        Util.buildColumnHeaderToPropertyMap(gridColumnDef.children).forEach((value, key) => {
            returnMap.set(key, value);
          }
        )
      } else {
        let gridColumn = data as GridColumn;
        if (gridColumn.downloadable != false) {
          returnMap.set(gridColumn.headerName as string, gridColumn.field as string);
        }
      }
    })

    return returnMap;
  }

  private static populateRowDataFromJson(jsonDataArray: any[], columDef: (GridColumn | GridColumnGroupDef)[]) {
    let columnHeaderToPropertyMap = Util.buildColumnHeaderToPropertyMap(columDef);
    let rowDataArray: any[] = [];
    jsonDataArray.forEach(jsonData => {
      let keys = Object.keys(jsonData);
      let rowData: { [key: string]: string } = {};
      keys.forEach(key => {
        let filedName: string = columnHeaderToPropertyMap.get(key) as string;
        rowData[filedName] = jsonData[key]
      })
      rowDataArray.push(rowData);
    })

    return rowDataArray;
  }

  public static getEnumKeyByValue<T extends {}>(enumType: T, value: string) {
    let enumKey = Object.keys(enumType).find(key => enumType[key as keyof typeof enumType] === value);
    if (!enumKey) {
      throw new Error(`Could not find string value: ${value} for enum: ${enumType}`);
    }
    return enumType[enumKey as keyof typeof enumType];
  }

}
