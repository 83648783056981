<div class="notification-container">
  <button (menuClosed)="onNotificationPanelClosed()" (menuOpened)="onNotificationPanelOpened()" [matMenuTriggerFor]="notificationMenu"
          mat-icon-button>
    <mat-icon><i class="fa-sharp fa-thin fa-bell"></i></mat-icon>
    <div *ngIf="hasUnreadNotifications()" class="red-dot"></div>
  </button>
</div>

<mat-menu #notificationMenu="matMenu" [overlapTrigger]="false">
  <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <div class="notification-popup-content">
      <div class="notification-header">
        <span>Notifications <ng-container *ngIf="unreadCount > 0">({{ unreadCount }})</ng-container></span>
        <span #headerMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="headerMenu" class="clickable-icon">
          <mat-icon>more_horiz</mat-icon>
        </span>
      </div>
      <mat-divider></mat-divider>
      <div *ngFor="let notification of filteredNotifications" class="notification-item">
        <div (click)="markAsRead(notification)" [innerHTML]="notification.htmlSubject" [ngClass]="{'bold-text': notification.notificationStatus === 'pending'}"
             class="notification-subject"></div>
        <div class="notification-meta">
          <span (click)="markAsRead(notification)" *ngIf="notification.notificationStatus === 'pending'" class="blue-dot" matTooltip="Mark as read">
          </span>
        </div>
        <div *ngIf="notification.htmlBody || notification.body" [innerHTML]="notification.htmlBody" class="notification-body">
        </div>
        <div *ngIf="notification.createdTimestamp" class="notification-date">
          {{ getRelativeTime(notification.createdTimestamp) }}
        </div>
      </div>
    </div>
  </div>
</mat-menu>

<mat-menu #headerMenu="matMenu">
  <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <button (click)="markAllAsRead(); closeHeaderMenu();" mat-menu-item>Mark all as Read</button>
    <mat-divider></mat-divider>
    <button mat-menu-item>
      <mat-slide-toggle (change)="toggleUnreadOnly($event.checked)" [(ngModel)]="unreadOnly">
        Unread only
      </mat-slide-toggle>
    </button>
  </div>
</mat-menu>
