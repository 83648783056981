import {ChangeDetectorRef, Component, Input} from "@angular/core";
import {DownloadRequest, DownloadRequestData, DownloadRequestTemplate, ViewDownloadRequest} from "@data/interefaces/download.request.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DetailBaseComponent} from "@pages/detail.base.component";
import {DataItem, OperationStatus, ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {ReferenceService} from "@data/reference/reference.service";
import {LookupService} from "@data/lookup/lookup.service";
import {ViewIndustry, ViewMarket} from "@data/interefaces/reference.interfaces";
import {DownloadRequestType, LookupLocale, Period, StandardReportType, SurveySubject} from "@data/interefaces/lookup.interfaces";
import {
  expediteClickAction,
  setCurrentMarketExpediteCount,
  setIsUserAdmin,
  setMaxDownloadRequestExpediteCount,
  setThresholdCount,
  tableColumns
} from "@pages/download-request/download-request-add/download-request-add.component.ds";
import {AgGridService} from "@shared/services/ag-grid.service";
import {AgCheckboxTreeService} from "@shared/ag-checkbox-tree/services/ag-checkbox-tree.service";
import {ColumnVisibleEvent, GridApi, GridOptions, GridReadyEvent, IRowNode, RowDataUpdatedEvent, SelectionChangedEvent} from "@ag-grid-community/core";
import {ReportType, WeightedProductLabel} from "@data/interefaces/product.interfaces";
import {AgCheckboxListService} from "@shared/ag-checkbox-list/services/ag-checkbox-list.service";
import {Subject, Subscription} from "rxjs";
import {PptDownloadsService} from "@data/ppt-downloads/ppt-downloads.service";
import {NotificationType, SelectionChangeType, YesNoValue} from "@data/enums/data.enums";
import {Util} from "@data/util/util";
import {DownloadRequestService} from "@data/download-request/download.request.service";

@Component({
  selector: 'download-request-add',
  templateUrl: './download-request-add.component.html',
  styleUrls: ['./download-request-add.component.scss'],
  providers: [AgGridService, AgCheckboxTreeService, AgCheckboxListService]
})
export class DownloadRequestAddComponent extends DetailBaseComponent {
  @Input() downloadRequestsToRetry?: ViewDownloadRequest[];
  periods: Period[] = [];
  markets: ViewMarket[] = [];
  industries: ViewIndustry[] = [];
  surveySubjects: SurveySubject[] = [];
  downloadRequestTypes: DownloadRequestType[] = [];
  locales: LookupLocale[] = [];
  products: WeightedProductLabel[] = [];
  downloadParameters: string = "";
  rowData: DownloadRequestData[] = [];
  gridApi: GridApi | null = null;
  standardReportTypes: StandardReportType[] = [];
  reportTypes: ReportType[] = [];
  selectedRows: DownloadRequestData[] = [];
  requestsToCheckForDuplicates: DownloadRequestData[] = [];
  disableProductDropdown: boolean = false;
  currentBatchCode: string = '';
  originalBatchCodeFromRetry?: string = '';
  resetEventSubject: Subject<void> = new Subject<void>();
  retryEventSubject: Subject<ViewDownloadRequest[]> = new Subject<ViewDownloadRequest[]>();
  selectedProducts: WeightedProductLabel[] = [];
  public readonly OurVoiceDownloadTypeValue: string = 'OurVoice';
  protected isSaving = false;
  protected override formGroup: FormGroup<{ [K in keyof DownloadRequestTemplate]?: FormControl<any> }> = new FormGroup<any>([]);
  protected override pageContext: {
    p_f: string | undefined,
    m_f: string | undefined,
    i_f: string | undefined,
    ss_f: string | undefined,
    dt_f: string | undefined,
    l_f: string | undefined,
    pr_f: string | undefined,
  } = {
    p_f: undefined,
    m_f: undefined,
    i_f: undefined,
    ss_f: undefined,
    dt_f: undefined,
    l_f: undefined,
    pr_f: undefined,
  }
  protected readonly tableColumns = tableColumns;
  protected gridOptions?: GridOptions;
  private cachedFormValues?: Partial<DownloadRequestTemplate>;
  private formGroupValueChangeSubscription?: Subscription;
  private productsSubscription?: Subscription;
  private maxNumberOfRequestsThreshold: number = 0;
  private maxNumberOfExpeditedRequestsPerMarketCode: number = 0;
  private currentExpeditedCountByMarket: number = 0;
  private readonly MAX_EXPEDITE_THRESHOLD_CODE: string = 'max-expedite-count';
  private readonly DEFAULT_PRIORITY: number = 5;
  private readonly EXPEDITED_PRIORITY: number = 1;
  private isRetryMode: boolean = false;
  private isValidChildComponentSelectionData: boolean = false;
  private isProductsFromFiltersFound: boolean = true;

  constructor(private _agGridService: AgGridService, private _referenceService: ReferenceService, private _lookupService: LookupService,
              private _pptDownloadService: PptDownloadsService, private _downloadRequestService: DownloadRequestService, private _changeDetector: ChangeDetectorRef) {
    super();
    this.title = "Create New Download Request(s)"
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.initFormGroup();
    await this.loadDropDownDataAsync();
    this.loading = false;
    this._changeDetector.detectChanges(); // force the skeleton loader to finish
    this.initSubscriptions();
    this.initThresholds();
    setIsUserAdmin(this._store.isAdmin);
    await this.initRetryMode();
  }

  ngOnDestroy() {
    this.formGroupValueChangeSubscription?.unsubscribe();
    this.productsSubscription?.unsubscribe();
    this.gridApi = null;
  }

  onColumnVisible(event: ColumnVisibleEvent<any>) {
    this._agGridService.onColumnVisibleHandler(event, this.pageName);
  }

  requestClick() {
    if (this.canRequestDownloads()) {
      this.displayProgressBar(true);
      this._pptDownloadService.saveDownloadRequests(this.selectedRows).then(result => {
          this.displayNotificationMessage('info', 'Successfully sent download requests for processing');
          this.close();
        }
      ).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
      ).finally(() => this.displayProgressBar(false));
    } else {
      this.close();
    }
  }

  async onClearFiltersButtonClick() {
    let resetFilters = true;
    if (this.isDownloadRequestSelectionInProgress()) {
      const dialogMessage = 'Clearing the filters will reset all of your current progress.  Are you sure you want to continue?';
      resetFilters = await this.displayClearSelectionWarningMessage(dialogMessage);
    }
    if (resetFilters) {
      this.resetDropDownFilterValues();
      this.resetSelectedDownloadRequestValues();
    }
  }

  onRowSelectionChanged(event: SelectionChangedEvent) {
    // when setting via the api using setSelectedNodes these api and rowDataChanged sources are fired.  We want to ignore them.
    if (this.gridApi && event.source !== 'api' && event.source !== 'rowDataChanged') {
      this.selectedRows = this.gridApi?.getSelectedRows();
    }
    // need to move refreshHeader() call to outside of the initial if statement, because in rare circumstances when clicking
    // check boxes too quickly in the grid, the event.source type becomes 'api' instead of 'checkboxSelected'.
    this.gridApi?.refreshHeader();
  }

  canRequestDownloads() {
    return this.selectedRows.length > 0;
  }

  onItemsSelected(event: any[]) {
    this.onProductSelected(event);
  }

  canShowProductSelection() {
    return this.products.length > 0;
  }

  canShowSelectionWizard() {
    return this.canShowProductSelection() && this.selectedProducts.length > 0;
  }

  canShowResultsGrid() {
    // show the grid once all other criteria is met
    this.initGridOptions();
    return this.canShowSelectionWizard();
  }

  async closeClick() {
    let closeScreen = true;
    if (this.isDownloadRequestSelectionInProgress()) {
      const dialogMessage = 'Are you sure you want to close the screen?  All progress will be lost.';
      closeScreen = await this.displayClearSelectionWarningMessage(dialogMessage);
    }
    if (closeScreen) {
      this.close();
    }
  }

  async onDownloadRequestsChange(event: { items: DownloadRequestData[]; changeType: SelectionChangeType }) {
    if (event) {
      await this.handleDownloadRequestDataChanged(event.items, event.changeType);
    }
  }

  onIsValidSelectionData(event: boolean) {
    this.isValidChildComponentSelectionData = event;
  }

  /**
   * Used to handle the retry mechanism for child components.  This event will get a signal
   * From the child component that it is done processing the current set of selectedProducts
   */
  onSelectedProductsProcessed() {
    if (this.isRetryMode && this.downloadRequestsToRetry) {
      this.retryEventSubject.next(this.downloadRequestsToRetry);
    }
  }

  noFilterDataFound() {
    return !this.isProductsFromFiltersFound;
  }

  protected override close() {
    const urlSegments = this.goBackUrl.split('/');
    this._router.navigate(urlSegments);
  }

  protected override loadDropDownData(): void {

  }

  protected async loadDropDownDataAsync(): Promise<void> {
    let promises: Promise<any>[] = [];
    promises.push(this._referenceService.getAllMarkets().then(result => {
      result.forEach(item => this.markets.push(item));
    }));
    promises.push(this._referenceService.getAllIndustries().then(result => {
      result.forEach(item => this.industries.push(item));
    }));
    promises.push(this._lookupService.getSurveySubjects().then(result => {
      result.forEach(item => this.surveySubjects.push(item));
    }));
    promises.push(this._lookupService.getPeriods().then(result => {
      result.forEach(item => this.periods.push(item));
    }));
    promises.push(this._lookupService.getLookupLocales().then(result => {
      result.forEach(item => this.locales.push(item));
    }));
    promises.push(this._lookupService.getDownloadRequestTypes().then(result => {
      result.forEach(item => this.downloadRequestTypes.push(item));
    }));
    promises.push(this._lookupService.getStandardReportTypes().then(result => {
      result.forEach(item => this.standardReportTypes.push(item));
    }));
    promises.push(this._pptDownloadService.getReportType().then(result => {
      result.forEach(item => this.reportTypes.push(item));
    }));
    await Promise.all(promises);
  }

  protected override getContentDetails(): void {
  }

  protected override saveOrUpdate(): Promise<OperationStatus> | Promise<DataItem> {
    throw new Error("Method not implemented.");
  }

  protected getFormControl(controlName: (keyof DownloadRequestTemplate)) {
    return this.formGroup.get(controlName) as FormControl<any>;
  }

  protected override initFormGroup() {
    this.formGroup = new FormGroup<{ [K in keyof DownloadRequestTemplate]?: FormControl<any> }>(
      {
        downloadRequestType: new FormControl({
          value: "",
          disabled: false
        }, [Validators.required]),
        period: new FormControl({
          value: "",
          disabled: false
        }, [Validators.required]),
        market: new FormControl({
          value: "",
          disabled: false
        }, [Validators.required]),
        industry: new FormControl({
          value: "",
          disabled: false
        }, [Validators.required]),
        surveySubject: new FormControl({
          value: "",
          disabled: false
        }, [Validators.required]),
        locale: new FormControl({
          value: "",
          disabled: false
        }, [Validators.required])
      }
    );
  }

  protected onGridReady(event: GridReadyEvent<any>) {
    this.gridApi = event.api;
    this.gridApi.addGlobalListener((eventType: any, event: any) => {
      switch (eventType) {
        case expediteClickAction: {
          this.onExpediteClick(event.detail.rowData);
          break;
        }
      }
    });
  }

  protected onModelUpdated() {
    this.gridApi?.refreshHeader();
  }

  private onExpediteClick(downloadRequest: ViewDownloadRequest) {
    const isExpedite = downloadRequest.priority != this.EXPEDITED_PRIORITY;
    // update current value internally
    isExpedite ? this.currentExpeditedCountByMarket++ : this.currentExpeditedCountByMarket--;
    setCurrentMarketExpediteCount(this.currentExpeditedCountByMarket);
    downloadRequest.priority = isExpedite ? this.EXPEDITED_PRIORITY : this.DEFAULT_PRIORITY;
    this.gridApi?.refreshCells({
      force: true,
      columns: ["priority"]
    });
  }

  /**
   * Checks if the download request has any pending changes
   */
  private isDownloadRequestSelectionInProgress() {
    // if any sponsors or respondents are selected, return true;
    return this.isValidChildComponentSelectionData;
  }

  private async displayClearSelectionWarningMessage(message: string) {
    return await this.displayDialogMessage(NotificationType.WARNING, message);
  }

  private initSubscriptions() {
    this.formGroupValueChangeSubscription = this.formGroup.valueChanges.subscribe(async (value) => await this.handleValueChanged(value));
  }

  private initGridOptions() {
    // ensure the columns are set properly from local storage
    this._agGridService.updateColumnsFromLocalStorage(this.pageName, this._agGridService.getGridColumns(this.tableColumns));

    // Setup the sidebar to only have hide / show columns.  There is no filtering here.
    const defaultColDefs = this._agGridService.getDefaultColumnDefinition();
    // remove the filters
    defaultColDefs.filter = false;
    defaultColDefs.floatingFilter = false;
    // setup select column
    const selectColumn = this._agGridService.getDefaultSelectColumn((params: any) => {
      return {
        showFlag: params.data.invalid == YesNoValue.YES_VALUE,
        flagIcon: "fa-exclamation",
        flagColor: "red",
        toolTip: 'Invalid'
      }
    });
    const options = this._agGridService.getDefaultAgiGridOptions();
    options.defaultColDef = defaultColDefs;
    options.selectionColumnDef = selectColumn;
    options.rowSelection = this._agGridService.getMultiRowSelectionOptions();
    const sideBar = this._agGridService.getDefaultSideBar();
    sideBar.toolPanels = [this._agGridService.getDefaultColumnsSideBarPanel()];
    options.sideBar = sideBar;
    options.onGridPreDestroyed = () => {
      this.onAgGridPreDestroy()
    };
    this.gridOptions = options;
  }

  /**
   * With the new wizard in place, the grid is constantly being hidden / shown which causes it to be destroyed multiple times
   * This method tied into the gridOptions.onGridPreDestroyed allows us to properly clean up the gridApi.
   * @private
   */
  private onAgGridPreDestroy() {
    this.gridApi = null;
  }

  private initThresholds() {
    this._pptDownloadService.getNewDownloadRequestThresholds().then(result => {
      this.maxNumberOfRequestsThreshold = result.count;
      setThresholdCount(this.maxNumberOfRequestsThreshold);
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    this._downloadRequestService.getDownloadRequestThresholds().then(result => {
      this.maxNumberOfExpeditedRequestsPerMarketCode = this.getMaxExpediteDownloadRequestCount(result);
      setMaxDownloadRequestExpediteCount(this.maxNumberOfExpeditedRequestsPerMarketCode);
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
  }

  private async initRetryMode() {
    if (this.downloadRequestsToRetry) {
      // download requests to try were passed into this form, which means we must prepopulate fields.
      if (this.validateDownloadRequestsToRetry(this.downloadRequestsToRetry)) {
        // Start populating data
        this.isRetryMode = true;
        await this.processDownloadRequestsToRetry(this.downloadRequestsToRetry);
      } else {
        this.displayNotificationMessage('error', 'Invalid download requests were sent to the page.  Cannot retry the selected requests.');
      }
    }
  }

  private async handleValueChanged(value: Partial<DownloadRequestTemplate>) {
    // check if market code has changed and if so, get the updated threshold limit
    this.handleMarketCodeChange(value);
    // determine if we need to get a new batch code
    await this.handleBatchCodeChange(value);
    // check if all the values from the dropdowns are selected.  If so, enable the control
    let enableProductDropdown = this.handleEnableProductDropdown(value);
    // once it's enabled, check to see if any of the values of the dropdowns have change (or are new) and make the request to get the products
    if (enableProductDropdown) {
      // only make webservice call if the original values have not changed
      if (this.validateIfRequiredProductDropdownValuesChanged(this.formGroup.value)) {
        if (this.isDownloadRequestSelectionInProgress()) {
          const dialogMessage = 'Changing the selection will cause your current progress to reset.  Are you sure you want to continue?';
          if (await this.displayClearSelectionWarningMessage(dialogMessage)) {
            // ok was clicked
            this.resetControlsAndGetWeightedProductLabels();
          } else {
            // clicked no make sure the dropdowns are reset to the originals
            this.resetDropDownsToCachedValues();
          }
        } else {
          this.resetControlsAndGetWeightedProductLabels();
        }
      }
    } else {
      // Only fire the reset products if it's not an empty list.
      // Otherwise, this will keep firing the valueChanges subscription for products.
      if (Array.isArray(this.products) && this.products.length > 0) {
        this.resetProducts();
      }
    }

    this.cachedFormValues = this.formGroup.value;
  }

  private resetControlsAndGetWeightedProductLabels() {
    this.resetSelectedDownloadRequestValues();
    this.getWeightedProductLabels();
  }

  private async getWeightedProductLabels() {
    this.displayProgressBar(true);
    try {
      const isOurVoiceReport = this.formGroup.controls.downloadRequestType?.value.downloadRequestTypeValue == this.OurVoiceDownloadTypeValue;
      const result = await this._pptDownloadService.getWeightedProductLabels(
        this.formGroup.controls.templateVersion?.value,
        this.formGroup.controls.period?.value.period,
        this.formGroup.controls.market?.value.marketId,
        this.formGroup.controls.industry?.value.industryId,
        this.formGroup.controls.surveySubject?.value.surveySubject,
        this.formGroup.controls.locale?.value.language,
        isOurVoiceReport);
      // new products coming in, clear the current list
      this.resetProducts();
      result.forEach(item => {
        // set the display value as it comes off the pipe.
        item.displayValue = item.productCode + '-' + item.productLabel + '-' + item.weightedProductLabel;
        this.products.push(item);
      });
      this.isProductsFromFiltersFound = this.products.length > 0;
    } catch (error: any) {
      const serializedError = JSON.stringify(error);
      let errorMessage = serializedError;
      if (serializedError && serializedError === "{}") {
        errorMessage = error.toString();
      }
      this.displayNotificationMessage('error', errorMessage);
    } finally {
      this.displayProgressBar(false);
    }
  }

  private handleEnableProductDropdown(value: Partial<DownloadRequestTemplate>) {
    const requiredControls = [
      this.formGroup.controls.downloadRequestType,
      this.formGroup.controls.period,
      this.formGroup.controls.market,
      this.formGroup.controls.industry,
      this.formGroup.controls.surveySubject,
      this.formGroup.controls.locale
    ];
    return requiredControls.every(c => c?.value);
  }

  private handleMarketCodeChange(value: Partial<DownloadRequestTemplate>) {
    if (this.hasMarketCodeValueChanged(value)) {
      this._downloadRequestService.getLastExpeditedByMarketCode(value.market.marketCode).then(result => {
        this.currentExpeditedCountByMarket = result.value;
        setCurrentMarketExpediteCount(this.currentExpeditedCountByMarket);
      }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    }
  }

  private async handleBatchCodeChange(value: Partial<DownloadRequestTemplate>) {
    if (this.hasMarketCodeValueChanged(value) && value?.surveySubject?.surveySubject) {
      // the marketCode has changed and surveySubject is not null or empty we need to get a new batch code
      this.currentBatchCode = await this.getBatchCode(value);
      return;
    }

    if (this.hasSurveySubjectValueChanged(value) && value?.market?.marketCode) {
      // the survey subject has changed and the market code is not null or empty we need to get a new batch code
      this.currentBatchCode = await this.getBatchCode(value);
      return;
    }
  }

  private hasMarketCodeValueChanged(value: Partial<DownloadRequestTemplate>) {
    return value?.market?.marketCode && value?.market?.marketCode !== this.cachedFormValues?.market?.marketCode;
  }

  private hasSurveySubjectValueChanged(value: Partial<DownloadRequestTemplate>) {
    return value?.surveySubject?.surveySubject && value?.surveySubject?.surveySubject !== this.cachedFormValues?.surveySubject?.surveySubject;
  }

  private validateIfRequiredProductDropdownValuesChanged(value: Partial<DownloadRequestTemplate>) {
    return !(this.cachedFormValues?.downloadRequestType === value.downloadRequestType &&
      this.cachedFormValues?.period?.period === value.period.period &&
      this.cachedFormValues?.market?.marketId === value.market.marketId &&
      this.cachedFormValues?.industry?.industryId === value.industry.industryId &&
      this.cachedFormValues?.surveySubject?.surveySubject === value.surveySubject.surveySubject &&
      this.cachedFormValues?.locale?.language === value.locale.language);
  }

  private async handleDownloadRequestDataChanged(params: DownloadRequestData[], changeType: SelectionChangeType) {
    if (this.rowData.length === 0) {
      // No data yet, so take whatever params is and set it.
      if (params && params.length > 0) {
        /**
         * with the ag-grid v32 update, calling the execution in this order:
         *
         * this.rowData = params ? params : []
         * this.gridApi?.setGridOption('rowData', this.rowData);
         * this.gridApi?.selectAllFiltered();
         *
         * no longer works.  I suspect there is a race condition on when this.rowData renders its data from params, and when
         * setGridOption('rowData') is rendered.  I suspect that setGridOption('rowData') finishes before this.rowData, and therefore
         * the selectAllFiltered appears to no longer work.
         *
         * In order to resolve this, I on the fly, subscribe to the onRowDataUpdate event to do my processing.  I then revert it back to the original handler.
         * This happens inside the AgGridService executeWithRowDataUpdated
         */
        this._agGridService.executeWithRowDataUpdated(this.gridApi, async (event: RowDataUpdatedEvent) => {
          event.api.selectAllFiltered();
          const messageColumnData = await this.getMessageColumnData(params);
          this.processMessageColumnResults(messageColumnData, params);
        });
      }
      this.rowData = params ? params : [];
      return;
    }

    if (params && params.length === 0) {
      // nothing is here, so lets reset the grid
      this.gridApi?.deselectAll();
      this.rowData = [];
      return;
    }


    this.checkAndProcessMatchingDownloadRequests(params, changeType);
    const messageColumnData = await this.getMessageColumnData(this.requestsToCheckForDuplicates);
    this.processMessageColumnResults(messageColumnData, this.requestsToCheckForDuplicates);
    this.requestsToCheckForDuplicates = []; // clear list after we're done processing
  }

  private checkAndProcessMatchingDownloadRequests(downloadRequests: DownloadRequestData[], changeType: SelectionChangeType) {
    if (changeType === SelectionChangeType.ADD || changeType === SelectionChangeType.REFRESH) {
      this.addMatchingDownloadRequestToGrid(downloadRequests)
    } else if (changeType === SelectionChangeType.DELETE) {
      this.removeMatchingDownloadRequestFromGrid(downloadRequests);
    }
  }

  private addMatchingDownloadRequestToGrid(downloadRequests: DownloadRequestData[]) {
    const newItemsAdded: DownloadRequestData[] = [];
    let downloadRequestsToAdd: DownloadRequestData[] = [];
    if (this.isOurVoiceReport()) {
      downloadRequestsToAdd = downloadRequests.filter(newIncomingRequest => {
        const doesExistInDataSource = this.rowData.some(existingRequestInGrid => {
          return this.compareDownloadRequestsForEquality(newIncomingRequest, existingRequestInGrid);
        });
        return !doesExistInDataSource;
      });
    } else {
      for (const request of downloadRequests) {
        if (!this.updatedRespondentListAndRefreshCells(request)) {
          downloadRequestsToAdd.push(request);
        }
      }
    }

    // if there are download requests to add, we need to add them to the grid
    // and check for duplicates
    if (downloadRequestsToAdd) {
      for (const request of downloadRequestsToAdd) {
        this.rowData.push(request);
        newItemsAdded.push(request);
        this.addDownloadRequestToUpdateList(request);
      }
    }

    if (newItemsAdded && newItemsAdded.length > 0) {
      const transactionResult = this.gridApi?.applyTransaction({add: newItemsAdded});
      if (transactionResult && transactionResult.add) {
        transactionResult.add.forEach(addedNode => {
          addedNode.setSelected(true);
        });
        // manually fire as my sourceType so the handler only processes it once
        this.onRowSelectionChanged({source: 'checkboxSelected', type: 'selectionChanged', api: this.gridApi} as SelectionChangedEvent);
      }
    }
  }

  private removeMatchingDownloadRequestFromGrid(downloadRequests: DownloadRequestData[]) {
    // find the items that do not exist in rowData
    const missingItems = this.rowData.filter(
      rowItem => !downloadRequests.some(request => this.compareDownloadRequestsForEquality(rowItem, request))
    );

    if (this.gridApi && missingItems && missingItems.length > 0) {
      this._agGridService.executeWithRowDataUpdated(this.gridApi, (event: RowDataUpdatedEvent) => {
        /** Legacy Comment for historical reasoning ***
         // I have to use setGridOptions when removing the items.  When using applyTransaction (like in the add method)
         // The grid re-renders too slowly for me to reselect.  By using setGridOptions the grid is re-rendered to a state where I can select the nodes
         *******/
        event.api.setGridOption('rowData', this.rowData);
        const nodesToReselect: IRowNode<any>[] = [];
        event.api.forEachNode(node => {
          if (currentSelection.find(item => this.compareDownloadRequestsForEquality(node.data, item))) {
            nodesToReselect.push(node);
          }
        });
        event.api.setNodesSelected({nodes: nodesToReselect, newValue: true});
        // manually fire as my sourceType so the handler only processes it once
        this.onRowSelectionChanged({source: 'checkboxSelected', type: 'selectionChanged', api: event.api} as SelectionChangedEvent);
      });
      const currentSelection = this.gridApi?.getSelectedNodes().map(node => node.data);
      this.rowData = this.rowData.filter(rowItem => !missingItems.includes(rowItem));
    }

    // everything is removed, so lets update the respondents list
    for (const request of downloadRequests) {
      this.updatedRespondentListAndRefreshCells(request);
    }
  }

  private updatedRespondentListAndRefreshCells(request: DownloadRequestData) {
    const matchingDownloadRequestRecord = this.getMatchingDownloadRequestRecordFromDataSource(request);
    if (matchingDownloadRequestRecord) {
      // record found, lets check to see if we need to update the respondents list.
      if (!this.areRespondentSegmentsEqual(matchingDownloadRequestRecord.respondentSegments, request.respondentSegments)) {
        if ((this.formGroup.controls.downloadRequestType?.value.downloadRequestTypeValue === this.OurVoiceDownloadTypeValue)) {
          this.addDownloadRequestToUpdateList(request);
          return false;
        } else {
          matchingDownloadRequestRecord.respondentSegments = request.respondentSegments;
          matchingDownloadRequestRecord.respondentCompanyCode = request.respondentCompanyCode;
          matchingDownloadRequestRecord.respondentCompanyLabel = request.respondentCompanyLabel;
          this.gridApi?.refreshCells();
          // add item to refresh list
          this.addDownloadRequestToUpdateList(matchingDownloadRequestRecord);
        }
      }
      return true;
    }
    // no record was found, so return false so we can add it to the grid.
    return false;
  }

  private areRespondentSegmentsEqual(originalRespondentsList: any[], updateRespondentsList: any[]) {
    if (originalRespondentsList.length !== updateRespondentsList.length) {
      // lengths don't match, so they can't be equal
      return false;
    }
    const sortedArr1 = [...originalRespondentsList].sort((a, b) => a - b); // Clone and sort
    const sortedArr2 = [...updateRespondentsList].sort((a, b) => a - b); // Clone and sort
    return sortedArr1.every((value, index) => value == sortedArr2[index]);
  }

  private resetDropDownsToCachedValues() {
    // Compares all the current dropdowns to what their cached values are.
    // If they do not match, reset it's value to whatever is in the cache.
    if (this.formGroup.controls.period?.getRawValue() !== this.cachedFormValues?.period) {
      this.formGroup.controls.period?.setValue(this.cachedFormValues?.period);
    }
    if (this.formGroup.controls.downloadRequestType?.getRawValue() !== this.cachedFormValues?.downloadRequestType) {
      this.formGroup.controls.downloadRequestType?.setValue(this.cachedFormValues?.downloadRequestType);
    }
    if (this.formGroup.controls.market?.getRawValue() !== this.cachedFormValues?.market) {
      this.formGroup.controls.market?.setValue(this.cachedFormValues?.market);
    }
    if (this.formGroup.controls.industry?.getRawValue() !== this.cachedFormValues?.industry) {
      this.formGroup.controls.industry?.setValue(this.cachedFormValues?.industry);
    }
    if (this.formGroup.controls.surveySubject?.getRawValue() !== this.cachedFormValues?.surveySubject) {
      this.formGroup.controls.surveySubject?.setValue(this.cachedFormValues?.surveySubject);
    }
    if (this.formGroup.controls.locale?.getRawValue() !== this.cachedFormValues?.locale) {
      this.formGroup.controls.locale?.setValue(this.cachedFormValues?.locale);
    }
  }

  /**
   * Resets only the product, sponsor and respondent selection
   * @private
   */
  private resetSelectedDownloadRequestValues() {
    this.resetProducts();
    this.rowData = []; // reset the grid
    this.resetEventSubject.next();
  }

  private resetDropDownFilterValues() {
    // I have to call clearValidators and updateValueAndValidity otherwise the Validation.Required will fail
    // when I reset the value to null.
    Util.resetFormControl(this.formGroup.controls.period);
    Util.resetFormControl(this.formGroup.controls.downloadRequestType);
    Util.resetFormControl(this.formGroup.controls.market);
    Util.resetFormControl(this.formGroup.controls.industry);
    Util.resetFormControl(this.formGroup.controls.surveySubject);
    Util.resetFormControl(this.formGroup.controls.locale);

    // Also reset the expedite count and batch code
    this.currentExpeditedCountByMarket = 0;
    this.currentBatchCode = '';
    this.isProductsFromFiltersFound = true; // reset to true to remove the label
  }

  private getMatchingDownloadRequestRecordFromDataSource(request: DownloadRequestData) {
    return this.rowData.find(
      selection => {
        if (this.isOurVoiceReport()) {
          return this.compareDownloadRequestsForEqualityWithRespondents(selection, request);
        } else {
          return this.compareDownloadRequestsForEquality(selection, request);
        }
      }
    );
  }

  private compareDownloadRequestsForEquality(request1: DownloadRequestData, request2: DownloadRequestData): boolean {
    if (this.isOurVoiceReport()) {
      return this.compareDownloadRequestsForEqualityWithRespondents(request1, request2);
    } else {
      return this.compareDownloadRequestsForEqualityWithOutRespondents(request1, request2);
    }
  }

  private compareDownloadRequestsForEqualityWithOutRespondents(request1: DownloadRequestData, request2: DownloadRequestData) {
    return (
      request1.period === request2.period &&
      request1.marketId === request2.marketId &&
      request1.industryId === request2.industryId &&
      request1.surveySubject === request2.surveySubject &&
      request1.downloadRequestTypeId === request2.downloadRequestTypeId &&
      request1.language === request2.language &&
      request1.productPeriodId === request2.productPeriodId &&
      request1.productViewId === request2.productViewId &&
      request1.sponsorId === request2.sponsorId
    )
  }

  private compareDownloadRequestsForEqualityWithRespondents(request1: DownloadRequestData, request2: DownloadRequestData) {
    // Since there is no sponsor, we are not keeping track of the negativeProductViewId (Syndicated vs Custom), so because of this
    // we will add a check on the weighted product label.  Consider adding the negativeProductViewId as to not do string comparisons.
    return this.compareDownloadRequestsForEqualityWithOutRespondents(request1, request2)
      && request1.weightedProductLabel === request2.weightedProductLabel
      && this.areRespondentSegmentsEqual(request1.respondentSegments, request2.respondentSegments);
  }

  private getMaxExpediteDownloadRequestCount(resultSetThresholds: ResultsetThresholds) {
    const maxExpediateThreshold = resultSetThresholds.thresholds.find(result => result.thresholdCode === this.MAX_EXPEDITE_THRESHOLD_CODE);
    if (maxExpediateThreshold) {
      return maxExpediateThreshold.threshold;
    }
    return 0;
  }

  private addDownloadRequestToUpdateList(request: DownloadRequestData) {
    const matchingItem = this.requestsToCheckForDuplicates.find(item => this.compareDownloadRequestsForEquality(item, request));
    if (!matchingItem) {
      this.requestsToCheckForDuplicates.push(request);
    }
  }

  private async getMessageColumnData(params: DownloadRequestData[]) {
    let results: DownloadRequest[] = [];
    if (params && params.length > 0) {
      try {
        results = await this._pptDownloadService.getMatchingDownloadRequests(params);
      } catch (error) {
        this.displayNotificationMessage('error', JSON.stringify(error));
      }
    }
    return results;
  }

  private processMessageColumnResults(results: DownloadRequest[], updatedRequests: DownloadRequestData[]) {
    // Reset properties of the updated requests
    updatedRequests?.forEach(item => {
      const rowItem = this.rowData.find(row => this.compareDownloadRequestsForEquality(row, item));
      if (rowItem) {
        rowItem.message = undefined;
        rowItem.status = undefined;
        rowItem.link = undefined;
      }
    });
    for (const result of results) {
      let rowItem = this.rowData?.find(item => {
        // normalize productViewId since one can be undefined and the other an empty string.
        return (item.productViewId ?? '') === (result.downloadParameters.productviewid ?? '') &&
          item.productPeriodId === result.downloadParameters.productperiodid &&
          item.weightedProductLabel === result.downloadParameters.product &&
          item.sponsorCode == result.downloadParameters.sponsor &&
          this.areRespondentSegmentsEqual(item.respondentSegments, result.downloadParameters.respondentsegments);
      });
      if (rowItem) {
        // found a matching rowItem, lets update the status lets check to see if the respondents list is equal
        rowItem.message = result.processStatusMessage;
        rowItem.status = result.processStatus;
        rowItem.link = result.sharepointFolderUrl;
      }
    }
    this.gridApi?.refreshCells({
      force: true,
      columns: ["status", "message", "link"]
    });
  }

  private async getBatchCode(value: Partial<DownloadRequestTemplate>) {
    const result = await this._downloadRequestService.getNextBatchCode(value?.surveySubject?.surveySubject ?? '',
      value?.market?.marketCode ?? '');
    return result.value;
  }

  /**
   * Validates the download request passed in.  All values (period, market, etc) must match otherwise it's invalid
   * @param downloadRequestsToRetry
   * @private
   */
  private validateDownloadRequestsToRetry(downloadRequestsToRetry: ViewDownloadRequest[]) {
    const mainDownloadRequestParameters = JSON.parse(downloadRequestsToRetry[0].downloadParameters ?? '');
    return downloadRequestsToRetry.every(request => {
        const downloadRequestParameter = JSON.parse(request.downloadParameters ?? '');
        return request.country === downloadRequestsToRetry[0].country
          && request.period === downloadRequestsToRetry[0].period
          && downloadRequestParameter.dashboardprefix === mainDownloadRequestParameters.dashboardprefix
          && downloadRequestParameter.survey_subject === mainDownloadRequestParameters.survey_subject
          && downloadRequestParameter.report_type === mainDownloadRequestParameters.report_type
          && downloadRequestParameter.language === mainDownloadRequestParameters.language
      }
    );
  }

  private async processDownloadRequestsToRetry(downloadRequestsToRetry: ViewDownloadRequest[] | undefined) {
    if (downloadRequestsToRetry) {
      // Populate the drop downs, and build the product and sponsors tree.  We await this, to ensure that the processing is finalized
      // before moving on to trying to pre-select the items
      await this.populateAndDisableFilterDropdownsOnRetry(downloadRequestsToRetry);
    }
  }

  private async populateAndDisableFilterDropdownsOnRetry(downloadRequestsToRetry: ViewDownloadRequest[]) {
    // Manually set the form controls and drop downs.  It does not currently trigger handleValueChanges event, so we need to do some processing
    // from that method manually.
    const request = downloadRequestsToRetry[0];
    // Set the original batch code.
    this.originalBatchCodeFromRetry = request.batchCode;
    const downloadRequestParameters = JSON.parse(request.downloadParameters ?? '');
    const period = this.periods.find(p => p.period === request.period);
    this.setFormControlValueAndDisable(this.formGroup.controls.period, period);
    const market = this.markets.find(m => m.marketCode === request.country);
    this.setFormControlValueAndDisable(this.formGroup.controls.market, market);
    const surveySubject = this.surveySubjects.find(ss => ss.surveySubject === downloadRequestParameters.survey_subject);
    this.setFormControlValueAndDisable(this.formGroup.controls.surveySubject, surveySubject);
    const downloadRequestType = this.downloadRequestTypes.find(t => t.downloadRequestTypeValue === downloadRequestParameters.report_type);
    this.setFormControlValueAndDisable(this.formGroup.controls.downloadRequestType, downloadRequestType);
    const locale = this.locales.find(l => l.language === downloadRequestParameters.language);
    this.setFormControlValueAndDisable(this.formGroup.controls.locale, locale);
    // get batch code here, since the events for handling value changes do not fire
    this.currentBatchCode = await this.getBatchCode(this.formGroup.getRawValue());

    // Get the list of products we need to select
    let productPeriodIdList: { productPeriodId: number; product: string }[] = [];
    for (let downloadRequest of downloadRequestsToRetry) {
      const requestParams = JSON.parse(downloadRequest.downloadParameters ?? '');
      productPeriodIdList.push({
        productPeriodId: Number(requestParams.productperiodid),
        product: requestParams.product
      });
    }
    const productPeriodIds = productPeriodIdList.map(item => item.productPeriodId);
    // make the web service to attempt to determine which industry is being used.  This currently acts as a 'best guess'
    // and may not be 100% accurate in all scenarios.
    await this._pptDownloadService.getIndustryFromProductPeriodIds(productPeriodIds).then(result => {
      const industry = this.industries.find(i => i.industryId === result.value);
      this.setFormControlValueAndDisable(this.formGroup.controls.industry, industry);
    });

    // Manually get the products.
    await this.getWeightedProductLabels();
    // with selecting the product based on the productPeriodId there is the potential that it will select both the custom and syndicate products
    // lets do another round of filter to ensure that we only get ones that contain what's in the parameters
    let productLabels: WeightedProductLabel[] = this.products.filter(p => productPeriodIdList.some(item =>
      item.productPeriodId === p.productPeriodId && item.product.includes(p.weightedProductLabel)));
    // manually call handleProductChange with our list of pre-selected items;
    this.onProductSelected(productLabels);
    this.disableProductDropdown = true;
  }

  private setFormControlValueAndDisable(control: FormControl<any> | undefined, value: any, sendEvent: boolean = false) {
    if (control) {
      control.setValue(value, {emitEvent: sendEvent});
      control.disable({onlySelf: true});
    }
  }

  private onProductSelected(selectProducts: WeightedProductLabel[]) {
    this.selectedProducts = selectProducts
  }

  private resetProducts() {
    this.products = [];
    this.selectedProducts = [];
  }

  private isOurVoiceReport() {
    return this.formGroup.controls.downloadRequestType?.value.downloadRequestTypeValue == this.OurVoiceDownloadTypeValue;
  }

}
