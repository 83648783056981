<div [showSkeleton]="showSkeleton" agSkeleton class="transfer-run-detail-tasks-details" skeletonType="grid-9x1">
  <div class="task-details-container flex-row flex-g-s">
    <ag-datablock data="{{this.taskDetail?.id}}" title="Task Code"></ag-datablock>
    <ag-datablock data="{{this.transferRun?.transferId}}" title="Run Code"></ag-datablock>
    <ag-datablock data="{{this.transferRun?.transferTypeCode}}" title="Transfer Code"></ag-datablock>
    <ag-datablock data="{{this.transferRun?.transferTypeName}}" title="Transfer Name"></ag-datablock>
    <ag-datablock data="{{this.taskDetail?.taskIdentifier}}" title="Task Identifier"></ag-datablock>
    <ag-datablock data="{{this.taskDetail?.taskStatus}}" title="Status"></ag-datablock>
    <ag-datablock data="{{this.taskDetail?.startDatetime | date:'short'}}" title="Start"></ag-datablock>
    <ag-datablock data="{{this.taskDetail?.endDatetime | date:'short'}}" title="End"></ag-datablock>
    <ag-datablock data="{{this.taskDetail?.duration | date:'HH:mm:ss'}}" title="Duration"></ag-datablock>
  </div>
</div>
