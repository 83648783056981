import {DownloadRequest, DownloadRequestFile, DownloadRequestParameters, ViewDownloadRequest, ViewDownloadRequestFile} from "@data/interefaces/download.request.interfaces";


export class DownloadRequestUtil {

  public static mapDownloadRequestFileToView(sharePointFolderUrl: string, downloadRequestFile: DownloadRequestFile): ViewDownloadRequestFile {
    let res: ViewDownloadRequestFile = {};
    res.fileName = downloadRequestFile.fileName + "." + downloadRequestFile.contentType?.toLowerCase();
    res.locale = downloadRequestFile.locale;
    res.sharepointFileUrl = sharePointFolderUrl + "/" + res.fileName + "?web=1";
    return res;
  }

  public static mapDownloadRequestToView(downloadRequest: DownloadRequest): ViewDownloadRequest {
    let res: ViewDownloadRequest = {};
    const parameter: DownloadRequestParameters = downloadRequest.downloadParameters;
    res.id = downloadRequest.id;
    res.period = parameter.period;
    res.country = parameter.country;
    res.productType = downloadRequest.automatedReport?.automatedReportName;
    res.product = parameter.product;
    res.sponsor = parameter.sponsor;
    res.language = parameter.language;
    res.requestedBy = downloadRequest.requestedBy;
    res.requestDate = downloadRequest.requestDate;
    res.processStartDate = downloadRequest.processStartDate;
    res.processEndDate = downloadRequest.processEndDate;
    res.priority = downloadRequest.priority;
    res.downloadParameters = JSON.stringify(downloadRequest.downloadParameters);
    res.processStatus = downloadRequest.processStatus;
    res.processStatusMessage = downloadRequest.processStatusMessage;
    res.sharepointFolderUrl = downloadRequest.sharepointFolderUrl;
    return res;
  }

  public static handleExpeditedRequests(): void {

  }

  public static isUserAdmin(): boolean {
    return false
  }
}
