import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {ICellRendererParams} from "@ag-grid-community/core";
import {Util} from "@data/util/util";

@Component({
  selector: 'app-duration-column',
  templateUrl: './duration-column.component.html',
  styleUrls: ['./duration-column.component.scss']
})
export class DurationColumnComponent implements ICellRendererAngularComp {
  params!: any
  value?: number;

  constructor() {
  }

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.value = this.params.value;
  }


  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  getFormattedValue() {
    return this.value == null ? null : Util.formatDuration(this.value);
  }
}
