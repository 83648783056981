import {Component, Input} from '@angular/core';
import {MsExcelSurveyInvitees, ViewMailoutFieldsets, ViewMailoutTemplates} from "@data/interefaces/panel.interfaces";
import {FormControl, FormGroup} from "@angular/forms";
import {ViewSurveyLocales,} from "@data/interefaces/survey.spec.interfaces";
import {Util} from "@data/util/util";
import {PanelService} from "@data/panel/panel.service";
import {SurveySpecService} from "@data/survey-spec/survey.spec.service";
import {FeatureComponentMode, FileRequestStatus} from "@data/enums/data.enums";
import {
  msExcelSurveyInviteesDisplayTableColumns,
  MsExcelSurveyInviteesWithProcessStatus
} from "@pages/panel/ms-excel-survey-invitees/ms-excel-survey-invitees-bulk/ms-excel-survey-invitees-bulk.component.ds";
import {MsExcelSurveyInviteesService} from "@data/panel/ms.excel.survey.invitees.service";
import {BulkBaseComponent} from "@pages/bulk.base.component";
import {BatchProcessResult} from "@data/interefaces/data.interfaces";

@Component({
  selector: 'app-ms-excel-survey-invitees-bulk',
  templateUrl: './ms-excel-survey-invitees-bulk.component.html',
  styleUrls: ['./ms-excel-survey-invitees-bulk.component.scss']
})
export class MsExcelSurveyInviteesBulkComponent extends BulkBaseComponent {
  mailingLocaleList: ViewSurveyLocales[] | undefined = [];
  mailoutTemplateCodeList: ViewMailoutTemplates[] | undefined = [];
  mailoutFieldsetCodeList: ViewMailoutFieldsets[] | undefined = [];
  fileRequestStatusList: FileRequestStatus[] | undefined = [];
  @Input() override selectedRows: MsExcelSurveyInvitees[] = [];
  protected override formGroup: FormGroup<{ [K in keyof MsExcelSurveyInvitees]?: FormControl<any> }> = new FormGroup<any>([]);
  protected override readonly tableColumns = msExcelSurveyInviteesDisplayTableColumns;

  constructor(private _msExcelSurveyInviteesService: MsExcelSurveyInviteesService, private _panelService: PanelService, private _surveySpecService: SurveySpecService) {
    super();
  }

  override ngOnInit() {
    this.displayProgressBar(true);
    super.ngOnInit();
    this.displayProgressBar(false);
  }

  uploadFinished(event: { status: number; message: string; file?: File }) {
    this.processUploadedFile<MsExcelSurveyInviteesWithProcessStatus>(event);
  }

  getFormControl(controlName: (keyof MsExcelSurveyInvitees)) {
    return this.getControl<MsExcelSurveyInvitees>(controlName);
  }

  protected override initFormGroup() {
    let isBulkRequestSurveyFilesMode = this.featureComponentMode == FeatureComponentMode.BULK_REQUEST_FILES;
    this.formGroup = new FormGroup<{ [K in keyof MsExcelSurveyInvitees]?: FormControl<any> }>(
      {
        surveyFileStatus: new FormControl({
          value: isBulkRequestSurveyFilesMode ? this.selectedRows[0].surveyFileStatus : null,
          disabled: false
        }),
      });
  }

  protected override initFieldsByFeatureComponentMode() {
    switch (this.featureComponentMode) {
      case FeatureComponentMode.BULK_REQUEST_FILES:
        this.title = "Request Response Files in Bulk";
        this.subtitle = "Request Response Files in Bulk Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        this.loadBulkRequestResponseFilesDropdowns();
        break;
      case FeatureComponentMode.BULK_MAILOUT:
        this.title = "MS Excel Invitation Bulk Mailout";
        this.subtitle = "MS Excel Invitation Bulk Mailout Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        this.loadBulkMailoutDropdowns();
        break;
      case FeatureComponentMode.BULK_EDIT:
        this.title = "MS Excel Invitation Bulk Edit";
        this.subtitle = "MS Excel Invitation Bulk Edit Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        this.loadBulkUpdateDropdowns();
        break;
      case FeatureComponentMode.UPLOAD:
        this.title = "MS Excel Invitation Bulk Upload";
        this.subtitle = "MS Excel Invitation Bulk Upload Configuration";
        this.submitButtonText = "Upload and Process";
        this.updateStage = 1;
        break;
      default:
        break;
    }
  }

  protected override startBatchProcess(selectedRows: MsExcelSurveyInvitees[]): Promise<any> {
    return this._msExcelSurveyInviteesService.startBatchProcess(selectedRows, this.featureComponentMode.toString());
  }

  protected override validateRowData<T>(rowData: T[]): T[] {
    return [];
  }

  protected override getBatchProcessResultByType(): Promise<BatchProcessResult> {
    return this._msExcelSurveyInviteesService.getLatestBatchProcess();
  }

  private loadBulkRequestResponseFilesDropdowns() {
    this.fileRequestStatusList = [Util.getFileRequestStatusTransition(this.selectedRows[0].surveyFileStatus)];
  }

  private loadBulkMailoutDropdowns() {
    let surveyCode = this.selectedRows[0].surveyCode;
    this._panelService.getMailoutTemplates(surveyCode).then(result => {
      this.mailoutTemplateCodeList = result;
    });
    this._panelService.getMailoutFieldsets(surveyCode).then(result => {
      this.mailoutFieldsetCodeList = result;
    });
  }

  private loadBulkUpdateDropdowns() {
    let surveyCode = this.selectedRows[0].surveyCode;
    this._surveySpecService.getSurveyLocales(surveyCode).then(result => {
      this.mailingLocaleList = result;
    });
  }

}
