<div class="flex-row" id="component-header">
  <ag-subtitle [subTitleSegments]="getSubtitleSegments()" class="subtitle"></ag-subtitle>
  <ng-content></ng-content>
  <div class="flex-row flex-g-s right-align pd-r-m">
    <a *ngIf="redirectTo === 'quilliup'" [href]="util.getQuilliupGlobalPageLink(_store.env, quilliupGuid)" class="primary-link-color quilliup-link" target="_blank">
      Go to Table in Quilliup
      <i class="fa-sharp fa-light fa-arrow-right-long"></i>
    </a>
    <ag-auto-refresh (timeout)="this.refreshButtonClick()" *ngIf="!this.hideAutoRefresh" [checkedByDefault]="checkAutoRefreshByDefault" [runCounter]="this.startRefreshTimer"
                     intervals="10,20,30"></ag-auto-refresh>
    <button (click)="this.refreshButtonClick()" *ngIf="!this.hideRefreshButton" class="secondary-button">
      <i class="fa-sharp fa-light fa-arrows-rotate"></i>
      <span>Refresh</span>
    </button>
    <button (click)="this.hideShowColumnsButtonClick()" *ngIf="!this.hideColumnsButton" class="secondary-button">
      <i class="fa-sharp fa-light fa-eye-slash"></i>
      <span>Hide / Show Columns</span>
    </button>
    <button (click)="this.downloadButtonClick()" *ngIf="!this.hideDownloadButton" class="secondary-button">
      <i class="fa-sharp fa-light fa-arrow-down-to-bracket"></i>
      <span>Download</span>
    </button>
    <button *ngIf="!this.hideUploadButton" [queryParams]="uploadUrlParam" [routerLink]="uploadUrl" class="secondary-button">
      <i class="fa-sharp fa-light fa-arrow-up-from-bracket"></i>
      <span>Upload</span>
    </button>
  </div>
</div>
