import {Component} from '@angular/core';
import {FileRequestStatus, ResponseStatus, SurveySubject} from "src/app/data/enums/data.enums";
import {SurveyTracker, ViewMailoutFieldsets, ViewMailoutTemplates, ViewPersonCode} from "src/app/data/interefaces/panel.interfaces";
import {ViewSurveyLocales,} from "src/app/data/interefaces/survey.spec.interfaces";
import {ResponseType, YearsInRole} from "src/app/data/interefaces/lookup.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Util} from "src/app/data/util/util";
import {SurveySpecService} from "src/app/data/survey-spec/survey.spec.service";
import {LookupService} from "src/app/data/lookup/lookup.service";
import {PanelService} from "src/app/data/panel/panel.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {SurveyTrackerService} from "src/app/data/panel/survey.tracker.service";
import {ReferenceService} from "@data/reference/reference.service";
import {DetailBaseComponent} from "@pages/detail.base.component";

@Component({
  selector: 'app-survey-tracker-detail',
  templateUrl: './survey-tracker-detail.component.html',
  styleUrls: ['./survey-tracker-detail.component.scss']
})
export class SurveyTrackerDetailComponent extends DetailBaseComponent {

  selectedItem: SurveyTracker = {} as SurveyTracker;
  responseApprovalStatus: string = "";
  personCodeList: ViewPersonCode[] | undefined;
  mailingYearsInRoleList: YearsInRole[] | undefined;
  mailingLocales: ViewSurveyLocales[] | undefined;
  responseTypeList: ResponseType[] | undefined;
  mailoutTemplateCodeList: ViewMailoutTemplates[] | undefined;
  mailoutFieldsetCodeList: ViewMailoutFieldsets[] | undefined;
  fileRequestStatusList: FileRequestStatus[] | undefined;
  override formGroup: FormGroup<{ [K in keyof SurveyTracker]?: FormControl<any> }> = new FormGroup<any>([]);
  override pageContext: {
    [key: string]: boolean | string | undefined;
    sri_f: string | undefined;
  } = {
    sri_f: undefined,
  };
  protected readonly ResponseStatus = ResponseStatus;
  protected readonly SurveySubject = SurveySubject;

  constructor(private _surveySpecService: SurveySpecService, private _lookupService: LookupService,
              private _panelService: PanelService, private _surveyTrackerService: SurveyTrackerService, private _referenceService: ReferenceService) {
    super();
  }

  get responseFileForInterviewers() {
    return this.getFormControl('responseFileForInterviewers');
  }

  get responseFileForRespondents() {
    return this.getFormControl('responseFileForRespondents');
  }

  override ngOnInit(): void {
    this.displayProgressBar(true);
    super.ngOnInit();
  }

  loadDropdownsBySurveyCode(surveyCode: string) {
    this._panelService.getMailoutTemplates(surveyCode).then(result => {
      this.mailoutTemplateCodeList = result;
    });
    this._panelService.getMailoutFieldsets(surveyCode).then(result => {
      this.mailoutFieldsetCodeList = result;
    });
    this._panelService.getPersonCodes(surveyCode).then(result => {
      this.personCodeList = result;
    });
    this._surveySpecService.getSurveyLocales(surveyCode).then(result => {
      this.mailingLocales = result;
    });
  }

  onInvalidateRatingsChanged(event: MatSlideToggleChange) {
    Util.setToggleValue(event.checked, this.getFormControl('invalidateRatings'), false);
  }

  onAllBuCategoriesChanged(event: MatSlideToggleChange, formControlName: (keyof SurveyTracker)) {
    Util.setToggleValue(event.checked, this.getFormControl(formControlName), false);
  }

  onInvalidateVerbatimSelected(event: MatSlideToggleChange) {
    Util.setToggleValue(event.checked, this.getFormControl('invalidateVerbatim'), false);
  }

  isResponseStatusSelected(responseStatusEnum: ResponseStatus) {
    return this.formGroup.controls.responseStatus?.value == responseStatusEnum.toString();
  }

  isSurveySubjectSelected(surveySubjectEnum: SurveySubject) {
    return this.formGroup.controls.surveySubject?.value == surveySubjectEnum.toString();
  }

  getFormControl(controlName: (keyof SurveyTracker)) {
    return this.getControl<SurveyTracker>(controlName);
  }

  getFormControlValue(controlName: (keyof SurveyTracker)) {
    return this.getControlValue<SurveyTracker>(controlName);
  }

  protected override loadDropDownData() {
    this._lookupService.getYearsInRole().then(result => {
      this.mailingYearsInRoleList = result;
    });
    this._lookupService.getResponseTypes().then(result => {
      this.responseTypeList = result;
    });
  }

  protected override getContentDetails() {
    if (this.pageContext.sri_f) {
      this._surveyTrackerService.getSurveyTrackersById(this.pageContext.sri_f).then(result => {
        this.selectedItem = result;
        this.loadData();
        this.loading = false;
        this.displayProgressBar(false);
      });
    }
  }

  protected override saveOrUpdate() {
    return this._surveyTrackerService.updateSurveyTrackers(Util.mapFormGroupValuesToObject<SurveyTracker>(this.formGroup.getRawValue()));
  }

  protected override initFormGroup() {
    this.formGroup = new FormGroup<{ [K in keyof SurveyTracker]?: FormControl<any> }>(
      {
        period: new FormControl({
          value: this.selectedItem.period,
          disabled: true
        }),
        marketCode: new FormControl({
          value: this.selectedItem.marketCode,
          disabled: true
        }),
        marketName: new FormControl({
          value: this.selectedItem.marketName,
          disabled: true
        }),
        industryCode: new FormControl({
          value: this.selectedItem.industryCode,
          disabled: true
        }),
        industryName: new FormControl({
          value: this.selectedItem.industryName,
          disabled: true
        }),
        surveySubject: new FormControl({
          value: this.selectedItem.surveySubject,
          disabled: true
        }),
        surveyCode: new FormControl({
          value: this.selectedItem.surveyCode,
          disabled: true
        }),
        remoteRespId: new FormControl({
          value: this.selectedItem.remoteRespId,
          disabled: true
        }),
        responseCode: new FormControl({
          value: this.selectedItem.responseCode,
          disabled: true
        }),
        personCode: new FormControl({
          value: this.selectedItem.personCode,
          disabled: !this.isEditMode
        }, Validators.required),
        openLinkCode: new FormControl({
          value: this.selectedItem.openLinkCode,
          disabled: true
        }),
        responseStatus: new FormControl({
          value: this.selectedItem.responseStatus,
          disabled: true
        }),
        responseProgress: new FormControl({
          value: this.selectedItem.responseProgress,
          disabled: true
        }),
        responseType: new FormControl({
          value: this.selectedItem.responseType,
          disabled: !this.isEditMode
        }),
        alerts: new FormControl({
          value: this.selectedItem.alerts,
          disabled: true
        }),
        responseStartDatetime: new FormControl({
          value: this.selectedItem.responseStartDatetime,
          disabled: true
        }),
        responseEndDatetime: new FormControl({
          value: this.selectedItem.responseEndDatetime,
          disabled: true
        }),
        responseLastImported: new FormControl({
          value: this.selectedItem.responseLastImported,
          disabled: true
        }),
        responseLastUpdated: new FormControl({
          value: this.selectedItem.responseLastUpdated,
          disabled: true
        }),
        surveyUrl: new FormControl({
          value: this.selectedItem.surveyUrl,
          disabled: true
        }),
        invitationCompanyCode: new FormControl({
          value: this.selectedItem.invitationCompanyCode,
          disabled: true
        }),
        invitationCompanyLabel: new FormControl({
          value: this.selectedItem.invitationCompanyLabel,
          disabled: true
        }),
        invitationDivisionCodes: new FormControl({
          value: this.selectedItem.invitationDivisionCodes,
          disabled: true
        }),
        invitationDivisionLabels: new FormControl({
          value: this.selectedItem.invitationDivisionLabels,
          disabled: true
        }),
        mailingFirstName: new FormControl({
          value: this.selectedItem.mailingFirstName,
          disabled: !this.isEditMode
        }, Validators.required),
        mailingLastName: new FormControl({
          value: this.selectedItem.mailingLastName,
          disabled: !this.isEditMode
        }, Validators.required),
        mailingEmail: new FormControl({
          value: this.selectedItem.mailingEmail,
          disabled: !this.isEditMode
        }, Validators.required),
        mailingJobTitle: new FormControl({
          value: this.selectedItem.mailingJobTitle,
          disabled: !this.isEditMode
        }),
        mailingYearsInRole: new FormControl({
          value: this.selectedItem.mailingYearsInRole,
          disabled: !this.isEditMode
        }),
        mailingTelephone: new FormControl({
          value: this.selectedItem.mailingTelephone,
          disabled: !this.isEditMode
        }),
        priorYearTelephone: new FormControl({
          value: this.selectedItem.priorYearTelephone,
          disabled: !this.isEditMode
        }),
        mailingCellPhone: new FormControl({
          value: this.selectedItem.mailingCellPhone,
          disabled: !this.isEditMode
        }),
        mailingCity: new FormControl({
          value: this.selectedItem.mailingCity,
          disabled: !this.isEditMode
        }),
        mailingLocale: new FormControl({
          value: this.selectedItem.mailingLocale,
          disabled: !this.isEditMode
        }, Validators.required),
        respondentCompanyCode: new FormControl({
          value: this.selectedItem.respondentCompanyCode,
          disabled: true
        }),
        respondentCompanyLabel: new FormControl({
          value: this.selectedItem.respondentCompanyLabel,
          disabled: true
        }),
        respondentDivisionCodes: new FormControl({
          value: this.selectedItem.respondentDivisionCodes,
          disabled: true
        }),
        respondentDivisionLabels: new FormControl({
          value: this.selectedItem.respondentDivisionLabels,
          disabled: true
        }),
        responseFirstName: new FormControl({
          value: this.selectedItem.responseFirstName,
          disabled: true
        }),
        responseLastName: new FormControl({
          value: this.selectedItem.responseLastName,
          disabled: true
        }),
        responseEmail: new FormControl({
          value: this.selectedItem.responseEmail,
          disabled: true
        }),
        responseJobTitle: new FormControl({
          value: this.selectedItem.responseJobTitle,
          disabled: true
        }),
        responseYearsInRole: new FormControl({
          value: this.selectedItem.responseYearsInRole,
          disabled: true
        }),
        responseTelephone: new FormControl({
          value: this.selectedItem.responseTelephone,
          disabled: true
        }),
        responseCellPhone: new FormControl({
          value: this.selectedItem.responseCellPhone,
          disabled: true
        }),
        responseCity: new FormControl({
          value: this.selectedItem.responseCity,
          disabled: true
        }),
        responseLocale: new FormControl({
          value: this.selectedItem.responseLocale,
          disabled: true
        }),
        localRegionCodes: new FormControl({
          value: this.selectedItem.localRegionCodes,
          disabled: true
        }),
        localRegionLabels: new FormControl({
          value: this.selectedItem.localRegionLabels,
          disabled: true
        }),
        seniorityCode: new FormControl({
          value: this.selectedItem.seniorityCode,
          disabled: true
        }),
        seniorityLabel: new FormControl({
          value: this.selectedItem.seniorityLabel,
          disabled: true
        }),
        roleCodes: new FormControl({
          value: this.selectedItem.roleCodes,
          disabled: true
        }),
        roleLabels: new FormControl({
          value: this.selectedItem.roleLabels,
          disabled: true
        }),
        brPlCode: new FormControl({
          value: this.selectedItem.brPlCode,
          disabled: true
        }),
        brPlLabel: new FormControl({
          value: this.selectedItem.brPlLabel,
          disabled: true
        }),
        allBuCategories: new FormControl({
          value: this.selectedItem.allBuCategories,
          disabled: true
        }),
        buCategoryCodes: new FormControl({
          value: this.selectedItem.buCategoryCodes,
          disabled: true
        }),
        buCategoryLabels: new FormControl({
          value: this.selectedItem.buCategoryLabels,
          disabled: true
        }),
        channelCodes: new FormControl({
          value: this.selectedItem.channelCodes,
          disabled: true
        }),
        channelLabels: new FormControl({
          value: this.selectedItem.channelLabels,
          disabled: true
        }),
        selectedSubjectRollupCodes: new FormControl({
          value: this.selectedItem.selectedSubjectRollupCodes,
          disabled: true
        }),
        selectedSubjectRollupLabels: new FormControl({
          value: this.selectedItem.selectedSubjectRollupLabels,
          disabled: true
        }),
        selectedSubjectSingleCodes: new FormControl({
          value: this.selectedItem.selectedSubjectSingleCodes,
          disabled: true
        }),
        selectedSubjectSingleLabels: new FormControl({
          value: this.selectedItem.selectedSubjectSingleLabels,
          disabled: true
        }),
        ratedSubjectRollupCodes: new FormControl({
          value: this.selectedItem.ratedSubjectRollupCodes,
          disabled: true
        }),
        ratedSubjectRollupLabels: new FormControl({
          value: this.selectedItem.ratedSubjectRollupLabels,
          disabled: true
        }),
        ratedSubjectSingleCodes: new FormControl({
          value: this.selectedItem.ratedSubjectSingleCodes,
          disabled: true
        }),
        ratedSubjectSingleLabels: new FormControl({
          value: this.selectedItem.ratedSubjectSingleLabels,
          disabled: true
        }),
        ratingFrequency: new FormControl({
          value: this.selectedItem.ratingFrequency,
          disabled: true
        }),
        surveyCommentCount: new FormControl({
          value: this.selectedItem.surveyCommentCount,
          disabled: true
        }),
        respondentDivisionExclusions: new FormControl({
          value: this.selectedItem.respondentDivisionExclusions,
          disabled: true
        }),
        localRegionExclusions: new FormControl({
          value: this.selectedItem.localRegionExclusions,
          disabled: true
        }),
        brPlExclusions: new FormControl({
          value: this.selectedItem.brPlExclusions,
          disabled: true
        }),
        channelExclusions: new FormControl({
          value: this.selectedItem.channelExclusions,
          disabled: true
        }),
        buCategoryExclusions: new FormControl({
          value: this.selectedItem.buCategoryExclusions,
          disabled: true
        }),
        nominatedByCodes: new FormControl({
          value: this.selectedItem.nominatedByCodes,
          disabled: true
        }),
        nominatedCategories: new FormControl({
          value: this.selectedItem.nominatedCategories,
          disabled: !this.isEditMode
        }),
        nominationNotes: new FormControl({
          value: this.selectedItem.nominationNotes,
          disabled: !this.isEditMode
        }),
        endorserName: new FormControl({
          value: this.selectedItem.endorserName,
          disabled: true
        }),
        mailoutTemplateCode: new FormControl({
          value: this.selectedItem.mailoutTemplateCode,
          disabled: !this.isEditMode
        }),
        mailoutFieldSetCode: new FormControl({
          value: this.selectedItem.mailoutFieldSetCode,
          disabled: !this.isEditMode
        }),
        mailoutDatetime: new FormControl({
          value: this.selectedItem.mailoutDatetime,
          disabled: !this.isEditMode
        }),
        remoteTaskId: new FormControl({
          value: this.selectedItem.remoteTaskId,
          disabled: true
        }),
        mailoutHistory: new FormControl({
          value: this.selectedItem.mailoutHistory,
          disabled: true
        }),
        firstMailingDate: new FormControl({
          value: this.selectedItem.firstMailingDate,
          disabled: true
        }),
        lastMailingDate: new FormControl({
          value: this.selectedItem.lastMailingDate,
          disabled: true
        }),
        lastMailingStatus: new FormControl({
          value: this.selectedItem.lastMailingStatus,
          disabled: true
        }),
        timesMailed: new FormControl({
          value: this.selectedItem.timesMailed,
          disabled: true
        }),
        inviteForAdditionalRatings: new FormControl({
          value: this.selectedItem.inviteForAdditionalRatings,
          disabled: !this.isEditMode
        }),
        invalidateRatings: new FormControl({
          value: this.selectedItem.invalidateRatings,
          disabled: !this.isEditMode
        }),
        invalidateVerbatim: new FormControl({
          value: this.selectedItem.invalidateRatings,
          disabled: !this.isEditMode
        }),
        pmNotes: new FormControl({
          value: this.selectedItem.pmNotes,
          disabled: !this.isEditMode
        }),
        respondentCompanyPriority: new FormControl({
          value: this.selectedItem.respondentCompanyPriority,
          disabled: true
        }),
        notesToPM: new FormControl({
          value: this.selectedItem.notesToPM,
          disabled: !this.isEditMode
        }),
        responseFilesStatus: new FormControl({
          value: this.selectedItem.responseFilesStatus,
          disabled: false
        }),
        responseFileForInterviewersCreatedDatetime: new FormControl({
          value: this.selectedItem.responseFileForInterviewersCreatedDatetime,
          disabled: true
        }),
        responseFilesForRespondentsCreatedDatetime: new FormControl({
          value: this.selectedItem.responseFilesForRespondentsCreatedDatetime,
          disabled: true
        }),
        responseFileForInterviewers: new FormControl({
          value: this.selectedItem.responseFileForInterviewers,
          disabled: true
        }),
        responseFileForRespondents: new FormControl({
          value: this.selectedItem.responseFileForRespondents,
          disabled: true
        }),
        responseFilePassword: new FormControl({
          value: this.selectedItem.responseFilePassword,
          disabled: true
        }),
        surveyId: new FormControl({
          value: this.selectedItem.surveyId,
          disabled: true
        }),
        surveyResponseId: new FormControl({
          value: this.selectedItem.surveyResponseId,
          disabled: true
        }),
      });
  }

  private loadData() {
    this.loadResponseApprovalStatus();
    this.loadDropdownsBySurveyCode(this.selectedItem.surveyCode);
    this.initFormGroup();
    this.loadResponseFilesStatusDropdown();
  }

  private loadResponseFilesStatusDropdown() {
    let responseFilesStatus = this.getFormControl('responseFilesStatus').value;
    this.fileRequestStatusList = [responseFilesStatus, Util.getFileRequestStatusTransition(responseFilesStatus)];
  }

  private loadResponseApprovalStatus() {
    this._referenceService.getSurveyById(this.selectedItem.surveyId).then(result => {
      this.responseApprovalStatus = result.responseApprovalStatus;
      if (this.responseApprovalStatus !== 'Approved') {
        this.isEditMode = true;
        this.initFormGroup();
      }
    });
  }
}
