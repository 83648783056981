import {Component} from '@angular/core';
import {BaseComponent} from "@pages/base.component";
import {ActivatedRoute} from "@angular/router";
import {EventType} from "@data/interefaces/event.type.interface";
import {EventTypeService} from "@data/event/event-type.service";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Util} from "@data/util/util";
import {DetailBaseComponent} from "@pages/detail.base.component";
import { OperationStatus, DataItem } from '@data/interefaces/data.interfaces';

@Component({
  selector: 'app-event-type-detail',
  templateUrl: './event-type-detail.component.html',
  styleUrls: ['./event-type-detail.component.scss']
})
export class EventTypeDetailComponent extends DetailBaseComponent {
  override pageContext: {
    [key: string]: boolean | string | undefined;
    etc_f: string | undefined;
  } = {
    etc_f: undefined,
  };
  eventTypeCode: string | null | undefined;
  protected eventType: EventType | undefined;
  formGroup: FormGroup<{ [K in keyof EventType]?: FormControl<any> }> = new FormGroup<any>([]);

  constructor(private _eventTypeService: EventTypeService) {
    super();
  }

  save() {
    this.displayProgressBar(true);
    this._eventTypeService.saveEventType(Util.mapFormGroupValuesToObject<EventType>(this.formGroup.getRawValue()))
      .then(result => this.close())
      .catch(error => this.displayNotificationMessage('error', JSON.stringify(error)))
      .finally(() => this.displayProgressBar(false));
  }

  override close() {
    this.navigateToUrl(['/system', 'events', 'event-types']);
  }

  protected override getContentDetails(): void {
    this.loading = true;
    this.displayProgressBar(true);
    this.eventTypeCode = this.pageContext.etc_f;
    this._eventTypeService.getEventTypeByCode(this.eventTypeCode!)
      .then(result => {
        this.eventType = result;
        this.initFormGroup();
      })
      .catch(error => {
          this.displayNotificationMessage('error', JSON.stringify(error));
        }
      ).finally(() => {
      this.displayProgressBar(false);
      this.loading = false;
    });
  }

  override initFormGroup() {
    this.formGroup = new FormGroup<{ [K in keyof EventType]?: FormControl<any> }>(
      {
        eventTypeCode: new FormControl({
          value: this.eventType?.eventTypeCode,
          disabled: true
        }),
        category: new FormControl({
          value: this.eventType?.category,
          disabled: false,
        }, Validators.required),
        eventTypeName: new FormControl({
          value: this.eventType?.eventTypeName,
          disabled: false
        }, Validators.required),
        description: new FormControl({
          value: this.eventType?.description,
          disabled: false
        }),
        parameters: new FormControl({
          value: this.eventType?.parameters,
          disabled: false
        }),
      },
    );
  }

  protected override loadDropDownData(): void {
  }

  protected override saveOrUpdate(): Promise<OperationStatus> | Promise<DataItem> {
    throw new Error('Method not implemented.');
  }
}
