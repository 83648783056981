import {Injectable} from '@angular/core';
import {DataItem, DataList} from "@data/interefaces/data.interfaces";
import {ViewSurvey} from "@data/interefaces/survey.spec.interfaces";
import {VerbatimFileInfo} from "@data/interefaces/verbatim.file.interfaces";
import {ApiService} from "@core/api/api.service";

@Injectable({
  providedIn: 'root'
})
export class VerbatimService {

  constructor(private _apiService: ApiService) {
  }

  getFileNames() {
    return this._apiService.makeGetServiceCall<DataList>('/v2/api/verbatim/filename');
  }

  getSurveys() {
    return this._apiService.makeGetServiceCall<ViewSurvey[]>('/v2/api/verbatim/survey');
  }

  getVerbatimFiles() {
    return this._apiService.makeGetServiceCall<VerbatimFileInfo[]>('/v2/api/verbatim');
  }

  getVerbatimFile(verbatimFileId: number) {
    return this._apiService.makeGetServiceCall<VerbatimFileInfo>(`/v2/api/verbatim/${verbatimFileId}`);
  }

  uploadFile(file: File | null, info: VerbatimFileInfo) {
    const formData = new FormData();
    if(file){
      formData.append('file', file, file.name);
      formData.append('body', new Blob([JSON
          .stringify(info)], {
        type: 'application/json'
      }));
    }
    return this._apiService.makeMultipartPostServiceCall<DataItem>(`/v2/api/verbatim/upload`, null, formData);
  }
}
