import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'ag-confirmation-dialog',
  templateUrl: './ag-confirmation-dialog.component.html',
  styleUrls: ['./ag-confirmation-dialog.component.scss']
})
export class AgConfirmationDialogComponent {

  constructor(
    public _dialogRef: MatDialogRef<AgConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string, hideCancel: boolean },
  ) {
  }

}
