import {DomSanitizer} from "@angular/platform-browser";
import {DownloadRequestNotification, NotificationDecorator, UserNotification} from "@data/interefaces/notification.interface";

export class DownloadRequestNotificationDecorator implements NotificationDecorator {
  constructor(private _sanitizer: DomSanitizer) {
  }

  decorate(notification: UserNotification): UserNotification {
    if (notification.body) {
      const downloadRequestNotification = JSON.parse(notification.body) as DownloadRequestNotification;
      const subject = `Download Request(s) <a href="/data-management/download/download-request-queue?bc_f=${downloadRequestNotification.batchCode}">${downloadRequestNotification.batchCode}</a> is completed`;
      notification.htmlSubject = this._sanitizer.bypassSecurityTrustHtml(subject);
      const body = `<span class="available">${downloadRequestNotification.available} Available</span> <span class="failed">${downloadRequestNotification.failed} Failed</span>`
      notification.htmlBody = this._sanitizer.bypassSecurityTrustHtml(body);
    } else {
      notification.htmlSubject = this._sanitizer.bypassSecurityTrustHtml('');
      notification.htmlBody = this._sanitizer.bypassSecurityTrustHtml('');
    }
    return notification;
  }
}
