import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType} from "@data/enums/data.enums";
import {SelectColumnComponent} from "../segments/grid/columns/select-column/select-column.component";
import {Util} from "@data/util/util";
import {CircleCheckColumnComponent} from "../segments/grid/columns/circle-check-column/circle-check-column.component";
import {MultiLineLinkColumnComponent} from "../segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";

export let pageContext: {
  [key: string]: boolean | string | undefined
  sc_f: string | undefined;
  vf_f: string | undefined;
  fu_r: string | undefined;
  us_f: string | undefined;
  lm_f: string | undefined;
  cid_f: string | undefined
  rss_f: string | undefined
  cc_f: string | undefined;
  cn_f: string | undefined;
  rc_f: string | undefined;
  rn_f: string | undefined;
  rcc_f: string | undefined;
  rcn_f: string | undefined;
  bcn_f: string | undefined;
  rid_f: string | undefined;
  fc_f: string | undefined;
  fn_f: string | undefined;
  scc_f: string | undefined;
  scn_f: string | undefined;
  c_f: string | undefined;
  l_f: string | undefined;
  dc_f: string | undefined;
  dn_f: string | undefined;
  pc_f: string | undefined;
  pn_f: string | undefined;
  bic_f: string | undefined;
  cqc_f: string | undefined;
  cq_f: string | undefined;
  smc_f: string | undefined;
  smn_f: string | undefined;
  p_f: string | undefined;
  mc_f: string | undefined;
  mn_f: string | undefined;
  iqc_f: string | undefined;
  iq_f: string | undefined;
  sid_f: string | undefined;
  d_f: string | undefined;
  dd_r: string | undefined;
  as_f: string | undefined;
  id_f: string | undefined;
} =
  {
  sc_f: undefined,
  vf_f: undefined,
  fu_r: undefined,
  us_f: undefined,
  lm_f: undefined,
  cid_f: undefined,
  rss_f: undefined,
  cc_f: undefined,
  cn_f: undefined,
  rc_f: undefined,
  rn_f: undefined,
  rcc_f: undefined,
  rcn_f: undefined,
  bcn_f: undefined,
  rid_f: undefined,
  fc_f: undefined,
  fn_f: undefined,
  scc_f: undefined,
  scn_f: undefined,
  c_f: undefined,
  l_f: undefined,
  dc_f: undefined,
  dn_f: undefined,
  pc_f: undefined,
  pn_f: undefined,
  bic_f: undefined,
  cqc_f: undefined,
  cq_f: undefined,
  smc_f: undefined,
  smn_f: undefined,
  p_f: undefined,
  mc_f: undefined,
  mn_f: undefined,
  iqc_f: undefined,
  iq_f: undefined,
  sid_f: undefined,
  d_f: undefined,
  dd_r: undefined,
  as_f: undefined,
  id_f: undefined,
};

export let surveyCodes: string[] = [];
export let fileNames: string[] = [];

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    maxWidth: 40,
    hide: false,
    filter: false,
    pinned: "left",
    suppressColumnsToolPanel: true,
    downloadable: false,
    cellRenderer: SelectColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        showFlag: params.data.loadError,
        flagIcon: "fa-exclamation",
        flagColor: "red",
        toolTip: 'Error'
      }
    }
  },
  {
    headerName: 'Data Filter',
    valueFormatter: '',
    children: [
      {
        field: 'surveyCode',
        headerName: 'Survey Code',
        pinned: "left",
        hide: false,
        dbFilter: true,
        isMandatory: false,
        filterId: 'sc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          values: surveyCodes,
          refreshValuesOnOpen: true,
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          closeOnApply: true
        }
      },
      {
        field: 'verbatimFileName',
        headerName: 'Verbatim File Name',
        pinned: "left",
        hide: false,
        dbFilter: true,
        isMandatory: false,
        minWidth: 200,
        filterId: 'vf_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          values: fileNames,
          refreshValuesOnOpen: true,
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          closeOnApply: true
        }
      },
      {
        field: 'verbatimFileUploadDate',
        headerName: 'File Upload Date',
        pinned: "left",
        hide: false,
        dbFilter: true,
        isMandatory: false,
        filterId: 'fu_r',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'greaterThan',
          filterOptions: ['greaterThan'],
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['apply', 'reset'],
        },
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      }
    ]
  },
  {
    headerName: 'Upload Status',
    valueFormatter: '',
    children: [
      {
        field: 'loadError',
        headerName: 'Success',
        hide: false,
        cellRenderer: CircleCheckColumnComponent,
        filterId: 'us_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
        // we need to flip error --> success
        valueGetter: function (params: any) {
          return !params.data.loadError;
        }
      },
      {
        field: 'loadMessage',
        headerName: 'Load Message',
        hide: false,
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.loadMessage,
            type: 'text'
          };
        },
        filterId: 'lm_f'
      },
      {
        field: 'commentId',
        headerName: 'Comment ID',
        hide: true,
        filterId: 'cid_f',
        filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'range', maxNumConditions: 1, buttons: ['reset']}
      },
      {
        field: 'remoteSourceSystem',
        headerName: 'Remote Source System',
        hide: true,
        filterId: 'rss_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
      }
    ]
  },
  {
    headerName: 'Demographics',
    valueFormatter: '',
    children: [
      {
        field: 'channelCode', headerName: 'Channel Code',
        hide: false,
        filterId: 'cc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.channelName, value: params.data.channelCode,
            filterId: 'cc_f'
          };
        }
      },
      {
        field: 'channelName',
        headerName: 'Channel Name',
        hide: true,
        filterId: 'cn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.channelCode,
            value: params.data.channelName,
            filterId: 'cn_f'
          };
        }
      },
      {
        field: 'regionCode', headerName: 'Region Code',
        hide: false,
        filterId: 'rc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.regionName, value: params.data.regionCode,
            filterId: 'rc_f'
          };
        }
      },
      {
        field: 'regionName',
        headerName: 'Region',
        hide: true,
        filterId: 'rn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.regionCode,
            value: params.data.regionName,
            filterId: 'rn_f'
          };
        }
      },
      {
        field: 'respondentCompanyCode', headerName: 'LocCode',
        hide: false,
        filterId: 'rcc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.respondentCompanyName, value: params.data.respondentCompanyCode,
            filterId: 'rcc_f'
          };
        }
      },
      {
        field: 'respondentCompanyName',
        headerName: 'Account Name',
        hide: true,
        filterId: 'rcn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.respondentCompanyCode,
            value: params.data.respondentCompanyName,
            filterId: 'rcn_f'
          };
        }
      },
      {
        field: 'categoryName',
        headerName: 'Business Unit',
        hide: false,
        filterId: 'bcn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
      },
      {
        field: 'remoteRespId',
        headerName: 'Per #',
        hide: true,
        filterId: 'rid_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
      },
      {
        field: 'functionCode', headerName: 'Function Code',
        hide: false,
        filterId: 'fc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.functionName, value: params.data.functionCode,
            filterId: 'fc_f'
          };
        }
      },
      {
        field: 'functionName',
        headerName: 'Function',
        hide: true,
        filterId: 'fn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.functionCode,
            value: params.data.functionName,
            filterId: 'fn_f'
          };
        }
      },
      {
        field: 'subjectCompanyCode', headerName: 'CoCode',
        hide: false,
        filterId: 'scc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.subjectCompanyName, value: params.data.subjectCompanyCode,
            filterId: 'scc_f'
          };
        }
      },
      {
        field: 'subjectCompanyName',
        headerName: 'Company Name',
        hide: true,
        filterId: 'scn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.subjectCompanyCode,
            value: params.data.subjectCompanyName,
            filterId: 'scn_f'
          };
        }
      }
    ]
  },
  {
    headerName: 'Comment Details',
    valueFormatter: '',
    children: [
      {
        field: 'verbatim',
        headerName: 'Comment',
        hide: false,
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.verbatim,
            type: 'text'
          };
        },
        filterId: 'c_f',
        filter: ColumnFilterType.TEXT
      },
      {
        field: 'locale',
        headerName: 'Language Code',
        hide: false,
        filterId: 'l_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
      },
      {
        field: 'apdCode', headerName: 'Performance Area #',
        hide: false,
        filterId: 'dc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.apdName, value: params.data.apdCode,
            filterId: 'dc_f'
          };
        }
      },
      {
        field: 'apdName',
        headerName: 'Performance Area Wording',
        hide: true,
        filterId: 'dn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.apdCode,
            value: params.data.apdName,
            filterId: 'dn_f'
          };
        }
      },
      {
        field: 'competencyCode', headerName: 'Practice #',
        hide: false,
        filterId: 'pc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.competencyName, value: params.data.competencyCode,
            filterId: 'pc_f'
          };
        }
      },
      {
        field: 'competencyName',
        headerName: 'Practice Wording',
        hide: true,
        filterId: 'pn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.competencyCode,
            value: params.data.competencyName,
            filterId: 'pn_f'
          };
        }
      },
      {
        field: 'verbatimFlags',
        headerName: 'Best In Class',
        hide: false,
        cellRenderer: CircleCheckColumnComponent,
        cellRendererParams: function () {
          return {
            failIcon: ' '
          };
        },
        filterId: 'bic_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
        // any non-blank string is "Best In Class"
        valueGetter: function (params: any) {
          return params.data.verbatimFlags && params.data.verbatimFlags !== '';
        }
      },
      {
        field: 'customQuestionCode',
        headerName: 'Custom Q #',
        hide: true,
        filterId: 'cqc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        }
      },
      {
        field: 'customQuestion',
        headerName: 'Custom Q Wording',
        hide: false,
        filterId: 'cq_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.customQuestion,
            type: 'text'
          };
        }
      },
      {
        field: 'sentimentCode', headerName: 'Sentiment Code',
        hide: false,
        filterId: 'smc_f',
        filter: ColumnFilterType.DROPDOWN, filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.sentimentName, value: params.data.sentimentCode,
            filterId: 'smc_f'
          };
        }
      },
      {
        field: 'sentimentName',
        headerName: 'Sentiment Name',
        hide: true,
        filterId: 'smn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.sentimentCode,
            value: params.data.sentimentName,
            filterId: 'smn_f'
          };
        }
      },
      {
        field: 'period',
        headerName: 'Year',
        hide: false,
        filterId: 'p_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        }
      },
      {
        field: 'countryCode',
        headerName: 'Country Code',
        hide: false,
        filterId: 'mc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.countryName, value: params.data.countryCode,
            filterId: 'mc_f'
          };
        }
      },
      {
        field: 'countryName',
        headerName: 'Country',
        hide: true,
        filterId: 'mn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.countryCode,
            value: params.data.countryName,
            filterId: 'mn_f'
          };
        }
      },
      {
        field: 'industryQuestionCode',
        headerName: 'Industry Q #',
        hide: true,
        filterId: 'iqc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        }
      },
      {
        field: 'industryQuestion',
        headerName: 'Industry Q Wording',
        hide: false,
        filterId: 'iq_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.industryQuestion,
            type: 'text'
          };
        }
      },
      {
        field: 'remoteSourceId',
        headerName: 'Unique ID',
        hide: false,
        filterId: 'sid_f',
        filter: ColumnFilterType.TEXT,
        filterParams: {
          buttons: ['reset']
        },
      },
      {
        field: 'done',
        headerName: 'Done',
        hide: false,
        cellRenderer: CircleCheckColumnComponent,
        cellRendererParams: function () {
          return {
            failIcon: ' '
          };
        },
        filterId: 'd_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
        // any non-blank string is "Done"
        valueGetter: function (params: any) {
          return params.data.done && params.data.done !== '';
        }
      },
      {
        field: 'dateDone',
        headerName: 'Date Done',
        hide: false,
        filterId: 'dd_r',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        },
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'approvalStatus',
        headerName: 'Approval Status',
        hide: false,
        filterId: 'as_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
      }
    ]
  },
  {
    headerName: 'ID\'s', valueFormatter: '', children: [
      {
        field: 'id',
        headerName: 'ID',
        cellDataType: 'number',
        hide: true,
        filterId: 'id_f',
        filter: ColumnFilterType.NUMBER,
        filterParams: {
          defaultOption: 'equals',
          maxNumConditions: 1,
          buttons: ['reset']
        }
      }
    ]
  }
]
