import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ag-expansion-panel',
  templateUrl: './ag-expansion-panel.component.html',
  styleUrls: ['./ag-expansion-panel.component.scss']
})
export class AgExpansionPanelComponent {

  @Input() title: string = '';
  @Input() isPanelActive: boolean = false;
  @Input() headerIcon: string = 'fa-thin fa-filter';
  @Input() activeHeaderIcon: string | undefined;
  @Input() isOpen: boolean = false;
  @Input() supportReset: boolean = false;
  @Output() reset = new EventEmitter<boolean>()
  @Output() expanded = new EventEmitter<boolean>()
  @Input() isDisabled: boolean = false;

  resetFilters() {
    this.reset.emit(true);
  }

  getPanelHeaderIcon(): string {
    if (this.isPanelActive) {
      return 'fa-sharp fa-solid fa-lg primary-color mg-r-s ' + (this.activeHeaderIcon == undefined ? this.headerIcon : this.activeHeaderIcon);
    }
    return 'fa-sharp fa-light fa-lg primary-color mg-r-s ' + this.headerIcon;
  }

  closeArea() {
    this.isOpen = !this.isOpen;
  }

  changeState() {
    this.expanded.emit(this.isOpen);
  }
}
