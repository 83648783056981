import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {VerbatimFileInfo} from "@data/interefaces/verbatim.file.interfaces";
import {Util} from "@data/util/util";
import {MatPaginator} from "@angular/material/paginator";
import {VerbatimService} from "@data/verbatim/verbatim.service";
import {AgSidePanelComponent} from "@shared/ag-side-panel/ag-side-panel.component";
import {TableBaseComponent} from "@pages/table.base.component";
import {pageContext, tableColumns} from "@pages/verbatim-files/verbatim-files.component.ds";
import {FilterChangedEvent} from "ag-grid-community";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'app-verbatim-files',
  templateUrl: './verbatim-files.component.html',
  styleUrls: ['./verbatim-files.component.scss'],
  providers: [AgGridService]
})
export class VerbatimFilesComponent extends TableBaseComponent implements AfterViewInit {

  auxLinks: Map<string, string>;
  isFilterActive: boolean | undefined;
  dataSource: VerbatimFileInfo[] = [];

  refreshComplete: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild("hideShowColumnsPanel") hideShowColumnsPanel: AgSidePanelComponent | null = null;
  protected override readonly tableColumns = tableColumns
  protected readonly pageContext = pageContext;
  protected readonly util = Util;
  @ViewChild('footerActionButtonPanel') footerActionButtonPanel: TemplateRef<any> | undefined;

  constructor(private _verbatimService: VerbatimService) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.displayProgressBar(true);
  }

  ngAfterViewInit() {
    this._verbatimService.getVerbatimFiles().then(result => {
      this.dataSource = result;
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => this.displayProgressBar(false));
  }


  async refreshView() {
    this.refreshComplete = false;
    this.displayProgressBar(true);
    await this._verbatimService.getVerbatimFiles().then(result => {
      this.dataSource = result;
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))).finally(() => {
        this.refreshComplete = true;
        this.displayProgressBar(false);
      }
    );
  }

  updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this))
  }

  protected override getTableFooterActionButtonPanel(): TemplateRef<any> | undefined {
    return this.footerActionButtonPanel;
  }

}
