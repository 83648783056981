import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {ResetFilterComponent} from "@pages/segments/grid/filters/reset-filter/reset-filter.component";

export const deleteClickAction: string = 'Delete';
export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    field: 'detail',
    headerName: 'File',
    hide: false,
    flex: 1,
  },
  {
    field: "actions",
    headerName: "Actions",
    pinned: "right",
    suppressFiltersToolPanel: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: ResetFilterComponent,
    suppressFloatingFilterButton: true,
    suppressHeaderFilterButton: true,
    cellRenderer: ActionColumnComponent,
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRendererParams: function (params: any) {
      return {
        actions: [
          {
            toolTip: "Delete",
            icon: "fa-trash",
            name: "Delete",
            actionEvent: deleteClickAction
          },
        ]
      };
    }
  }
]
