import {Injectable} from '@angular/core';
import {OperationStatus, Task, TransferRun, TransferRunLog} from "@data/interefaces/data.interfaces";
import {ApiService} from "@core/api/api.service";

@Injectable({
  providedIn: 'root'
})
export class TransferRunService {

  constructor(private _apiService: ApiService) {
  }

  getTransferList() {
    return this._apiService.makeGetServiceCall<TransferRun[]>(`/v2/api/transfer-run`);
  }

  getTransfer(transferRefCode: string) {
    return this._apiService.makeGetServiceCall<TransferRun>(`/v2/api/transfer-run/${transferRefCode}`);
  }

  getTransferLogs(transferRefCode: string | undefined) {
    return this._apiService.makeGetServiceCall<TransferRunLog[]>(`/v2/api/transfer-run/${transferRefCode}/log`);
  }

  getTransferLog(transferRefCode: string | undefined, transferDetailId: string | undefined) {
    return this._apiService.makeGetServiceCall<TransferRunLog>(`/v2/api/transfer-run/${transferRefCode}/log/${transferDetailId}`);
  }

  getTasks(transferRefCode: string | undefined) {
    return this._apiService.makeGetServiceCall<Task[]>(`/v2/api/transfer-run/${transferRefCode}/tasks`);
  }

  getTask(transferRefCode: string | undefined, taskId: string | undefined) {
    return this._apiService.makeGetServiceCall<Task>(`/v2/api/transfer-run/${transferRefCode}/task/${taskId}`);
  }

  runTransfer(transferRefCode: string | undefined, runParameters?: any) {
    if (runParameters) {
      return this._apiService.makePostServiceCall<OperationStatus>(`/v2/api/transfer-run/${transferRefCode}/run`, null, runParameters);
    }

    return this._apiService.makePostServiceCall<OperationStatus>(`/v2/api/transfer-run/${transferRefCode}/run`);
  }

  stopTransfer(transferRefCode: string | undefined) {
    return this._apiService.makePostServiceCall<OperationStatus>(`/v2/api/transfer-run/${transferRefCode}/stop`);
  }

  stopTask(transferRefCode: string | undefined, taskId: string | undefined) {
    return this._apiService.makePostServiceCall<OperationStatus>(`/v2/api/transfer-run/${transferRefCode}/${taskId}/cancel`);
  }

}
