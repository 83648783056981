import {Injectable} from '@angular/core';
import {GeneralServiceError} from "src/app/data/interefaces/data.interfaces";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor() {
  }

  makeGetServiceCall<T>(url: string, urlParam?: string | null, init?: RequestInit): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      let constantInitParam = {
        method: "GET",
        headers: {
          'Content-Type': 'application/vnd.api+json'
        }
      };
      this.makeHttpCall(url + (urlParam ? "?" + urlParam : ""), {...constantInitParam, ...init}, resolve, reject);
    });
  }

  makePostServiceCall<T>(url: string, urlParam?: string | null, body?: any, init?: RequestInit): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      let constantInitParam = {
        method: "POST",
        headers: {
          'Content-Type': 'application/vnd.api+json'
        },
        body: JSON.stringify(body || null)
      };

      this.makeHttpCall(url + (urlParam ? "?" + urlParam : ""), {...constantInitParam, ...init}, resolve, reject);
    });
  }

  makePutServiceCall<T>(url: string, urlParam: string | null, body?: any, init?: RequestInit): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      let constantInitParam = {
        method: "PUT",
        headers: {
          'Content-Type': 'application/vnd.api+json'
        },
        body: JSON.stringify(body || null)
      };

      this.makeHttpCall(url + (urlParam ? "?" + urlParam : ""), {...constantInitParam, ...init}, resolve, reject);
    });
  }

  makeDeleteServiceCall(url: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let constantInitParam = {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/vnd.api+json'
        }
      };
      fetch(url, constantInitParam).then(() => resolve(true)).catch(error => {
        reject({code: "GEN: 000", message: "Service Error - " + error});
      });
    });
  }

  makeMultipartPostServiceCall<T>(url: string, urlParam: string | null, body: FormData, init?: RequestInit): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      let constantInitParam = {
        method: "POST",
        body: body
      };
      this.makeHttpCall(url + (urlParam ? "?" + urlParam : ""), {...constantInitParam, ...init}, resolve, reject);
    });
  }

  makeHttpCall(url: string, initParams: RequestInit, resolve: Function, reject: Function) {
    fetch(url, initParams).then(response => {
      if (response.ok) {
        response.json().then(body => {
          if (body.data) {
            resolve(body.data.attributes);
          } else {
            reject(body.error);
          }
        }).catch(error => {
          reject({code: "GEN: 001", message: "Data reading error - " + JSON.stringify(error)});
        })
      } else {
        response.json().then(body => {
          if (body.error) {
            reject(body.error);
          } else {
            let resultedError = body as GeneralServiceError;
            reject({code: "GEN: 002", message: "Data processing error - response status: " + response.status + " (" + resultedError.error + ") - " + resultedError.message});
          }
        }).catch(error => {
          reject({code: "GEN: 003", message: "Data processing error - " + JSON.stringify(error)});
        });
      }
    }).catch(error => {
        reject({code: "GEN: 000", message: "Service Error - " + error});
      }
    );
  }
}
