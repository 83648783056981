import {Component, TemplateRef, ViewChild} from '@angular/core';
import {TableBaseComponent} from "@pages/table.base.component";
import {pageContext, tableColumns} from "@pages/survey-response-file/survey-response-file.component.ds";
import {ExcelSurveyFileUpload} from "@data/interefaces/survey.response.file.interfaces";
import {KpiSidebarItem} from "@shared/ag-kpi-sidebar/ag-kpi-sidebar.component.ds";
import {ActivatedRoute} from "@angular/router";
import {SurveyResponseFileService} from "@data/survey-response-file/survey.response.file.service";
import {FilterChangedEvent, SelectionColumnDef, ViewportChangedEvent} from "@ag-grid-community/core";
import {Util} from "@data/util/util";
import {SurveyFileUploadStatus} from "@data/enums/data.enums";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'app-survey-response-file',
  templateUrl: './survey-response-file.component.html',
  styleUrls: ['./survey-response-file.component.scss'],
  providers: [AgGridService]
})
export class SurveyResponseFileComponent extends TableBaseComponent {
  rowData: ExcelSurveyFileUpload[] = [];
  showTaskComponent: boolean = false;
  refreshComplete: boolean = true;
  isFilterActive: boolean = false;
  kpiSidebar: KpiSidebarItem[] = [];
  @ViewChild('footerActionButtonPanel') footerActionButtonPanel: TemplateRef<any> | undefined;
  protected override readonly tableColumns = tableColumns;
  protected readonly pageContext = pageContext;

  constructor(private _activatedRoute: ActivatedRoute, private _surveyResponseFileService: SurveyResponseFileService) {
    super();
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.updatePageContext();
    this.refreshView();
  }

  refreshView() {
    this.refreshComplete = false;
    this.displayProgressBar(true);
    this._surveyResponseFileService.getAllSurveyResponseFiles().then(result => {
      this.rowData = this.convertSurveyResponseFiles(result);
    })
      .catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
      ).finally(() => {
      this.refreshComplete = true;
      this.displayProgressBar(false)
    });
  }

  convertSurveyResponseFiles(results: ExcelSurveyFileUpload[]): ExcelSurveyFileUpload[] {
    return results.map(result => {
      let updatedResult = {...result};
      if (updatedResult.processingDetails === '[]') {
        updatedResult.processingDetails = '';
      }
      if (updatedResult.replacementId == null) {
        updatedResult.overwritten = "Not Overwritten";
      } else {
        updatedResult.overwritten = "Overwritten";
      }
      return updatedResult;
    });
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    const updateLiveDataFilter = this.isUpdateLiveDataFilterRequired(event.columns);
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this)).then(() => {
      if (updateLiveDataFilter) {
        this.onLiveDataFilterChange(true);
      }
    });
  }

  onLiveDataFilterChange(forceReload: boolean = false): void {
    if (forceReload) {
      this.refreshView();
    }
    this.updateToPageContextUrl();
  }

  protected override updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
    this.populateKpiMetrics();
  }

  protected override getSelectionColumnDef(): SelectionColumnDef {
    return {};
  }

  protected override onViewportChanged(event: ViewportChangedEvent<any>) {
    this.populateKpiMetrics();
  }

  private populateKpiMetrics() {
    let filteredRows = this.getRowsAfterFilter();
    let {pendingNum, inProgressNum, successfulNumber, processingErrorNumber, dataErrorNumber} = this.calculateKpiMetrics(filteredRows);
    this.kpiSidebar = [
      {
        title: "Pending",
        value: pendingNum
      },
      {
        title: "In progress",
        value: inProgressNum
      },
      {
        title: "Completed successfully",
        value: successfulNumber
      },
      {
        title: "Completed with data errors",
        value: dataErrorNumber
      },
      {
        title: "Completed with processing errors",
        value: processingErrorNumber
      }
    ];

  }

  private calculateKpiMetrics(rows: ExcelSurveyFileUpload[]) {
    let pendingNum = rows.filter(element => element.processingStatus == SurveyFileUploadStatus.PENDING).length;
    let inProgressNum = rows.filter(element => element.processingStatus == SurveyFileUploadStatus.IN_PROGRESS).length;
    let successfulNumber = rows.filter(element => element.processingStatus == SurveyFileUploadStatus.COMPLETED_SUCCESSFULLY).length;
    let processingErrorNumber = rows.filter(element => element.processingStatus == SurveyFileUploadStatus.COMPLETED_WITH_PROCESSING_ERRORS).length;
    let dataErrorNumber = rows.filter(element => element.processingStatus == SurveyFileUploadStatus.COMPLETED_WITH_DATA_ERRORS).length;

    return {pendingNum, inProgressNum, successfulNumber, processingErrorNumber, dataErrorNumber};
  }

}
