<div>
  <div class="header-row-info header-container-item mg-b-m">
    <div class="header-title">
      <h1 class="mat-mdc-card-subtitle sub-header-section">Files</h1>
    </div>
  </div>
  <ng-container *ngIf="!this.refreshComplete">
    <p>Loading</p>
  </ng-container>
  <ng-container *ngIf="this.refreshComplete">
    <p *ngIf="rowData.length == 0">No files generated</p>
    <div *ngIf="rowData.length != 0" style="height: 50vh;">
      <ag-grid-angular
        (columnVisible)="onColumnVisible($event)"
        (filterChanged)="onFilterChanged($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (gridReady)="onGridReady($event)"
        (rowSelected)="onRowSelected($event)"
        (viewportChanged)="onViewportChanged($event)"
        [columnDefs]="tableColumns"
        [gridOptions]="baseAgGridOptions"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [rowData]="rowData"
        [suppressPropertyNamesCheck]="true"
        class="ag-theme-quartz ag-grid-flex"
        style=" height: 100%;">
      </ag-grid-angular>
    </div>
  </ng-container>
</div>
