import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Util} from "@data/util/util";
import {FormControl} from "@angular/forms";
import {NotificationType, YesNoValue} from "@data/enums/data.enums";
import {SurveyResponseFileService} from "@data/survey-response-file/survey.response.file.service";
import {NavigationService} from "@core/api/navigation.service";
import {TableBaseComponent} from "@pages/table.base.component";
import {deleteClickAction, tableColumns} from "@pages/survey-response-file/response-file-upload/response-file-upload.component.ds";
import {GridReadyEvent, SelectionColumnDef} from "@ag-grid-community/core";
import {AgGridService} from "@shared/services/ag-grid.service";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-response-file-upload',
  templateUrl: './response-file-upload.component.html',
  styleUrls: ['./response-file-upload.component.scss'],
  providers: [AgGridService]
})
export class ResponseFileUploadComponent extends TableBaseComponent {
  maxFileSizeSupported = 1024 * 1024 * 200;
  descriptionFormControl: FormControl = new FormControl('');
  forceOverwritingFormControl: FormControl = new FormControl("false");
  selectedFiles: { name: string, detail: string, file: File }[] = [];
  protected override tableColumns = tableColumns;
  protected override pageContext = {};
  protected readonly util = Util;
  protected readonly FeatureComponentMode = FeatureComponentMode;

  constructor(private route: ActivatedRoute, private _surveyResponseFileService: SurveyResponseFileService,
              private _navigationService: NavigationService) {
    super();
  }

  uploadFinished(event: { status: number; message: string; file?: File }) {
    if (event.status == 0 && event.file) {
      let size = (event.file.size / 1024).toFixed(2) + ' KB';
      let detail = event.file.name + ' (' + size + ')';
      const fileDetail = {
        name: event.file.name,
        detail: detail,
        file: event.file
      };
      const existingFileIndex = this.selectedFiles.findIndex(f => f.name === fileDetail.name);
      if (existingFileIndex !== -1) {
        this.selectedFiles[existingFileIndex] = fileDetail;
      } else {
        this.selectedFiles.push(fileDetail);
      }
      this.gridApi.setGridOption('rowData', this.selectedFiles);
    }
  }

  deleteFileByName(name: string) {
    this.selectedFiles = this.selectedFiles.filter(file => file.name !== name);
  }

  clearFiles(): void {
    this.selectedFiles = [];
  }

  save(): void {
    if (this.selectedFiles.length > 0) {
      if (this.forceOverwritingFormControl.value === YesNoValue.YES_VALUE) {
        this.submitFiles();
      } else {
        this.submitFilesWithCheckExisting();
      }
    }
  }

  close(): void {
    this._navigationService.back("/data-management/survey/response-file");
  }

  protected override updatePageContext(updateContextUrl: boolean): void {
  }

  protected override getSelectionColumnDef(): SelectionColumnDef {
    return {};
  }

  protected override onGridReady(gridReadyEvent: GridReadyEvent<any>) {
    this.gridApi = gridReadyEvent.api;
    this.initAfterGridReady();
  }

  protected override initAfterGridReady() {
    this.gridApi.addGlobalListener((eventType: any, event: any) => {
      switch (eventType) {
        case deleteClickAction: {
          this.deleteFileByName(event.detail.rowData.name);
          break;
        }
      }
    });
  }

  private submitFilesWithCheckExisting(): void {
    const fileNames: string[] = this.selectedFiles.map(fileObj => fileObj.name);
    this._surveyResponseFileService.checkExistFiles(fileNames).then(results => {
      if ((results.existingFileNames ?? []).length > 0) {
        this.displayDialogMessage(NotificationType.WARNING, this.getConfirmationMessage(fileNames)).then(result => {
          if (result) {
            this.submitFiles();
          }
        })
      } else {
        this.submitFiles();
      }
    });
  }

  private getConfirmationMessage(existFilenames: string[]): string {
    const length = existFilenames.length;
    const filenames = existFilenames.join(', ');
    return `${this.pluralize(length, 'File')} with this ${this.pluralize(length, 'name')} already exist: ${filenames}
        Do you want to overwrite these ${this.pluralize(length, 'file')}?`;
  }

  private submitFiles(): void {
    const files: File[] = this.selectedFiles.map(fileObj => fileObj.file);
    this.displayProgressBar(true);
    this._surveyResponseFileService.uploadFiles(files, this.descriptionFormControl.value).then(result => {
      this.displayNotificationMessage('success', `Survey response ${this.pluralize(files.length, "file")} uploaded.`);
      this.close();
    }).catch(error => {
      this.displayNotificationMessage('error', JSON.stringify(error));
      this.displayProgressBar(false)
    })
  }

  private pluralize(val: number, word: string) {
    let plural = word + 's';
    return val != 1 ? plural : word;
  }

}
