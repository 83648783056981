import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {SurveyTracker} from "@data/interefaces/panel.interfaces";
import {ProcessStatus} from "@data/interefaces/extended.data.interfaces";
import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";

export interface MsExcelSurveyInviteesWithProcessStatus extends SurveyTracker, ProcessStatus {
}

export let msExcelSurveyInviteesDisplayTableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    headerName: 'Data Filter', children: [
      {
        field: 'surveyCode', headerName: 'Survey Code', pinned: "left", hide: false,
        cellRenderer: SelectColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            showFlag: params.data.processingNote != undefined,
            flagIcon: "fa-square-exclamation",
            flagColor: "red",
            toolTip: params.data.processingNote
          }
        }
      },
      {
        field: 'period',
        headerName: 'Period',
      },
      {
        field: 'marketCode',
        headerName: 'Market Code',
      }
    ]
  },
  {
    headerName: 'Invitation', children: [
      {
        field: 'period',
        headerName: 'Period',
      },
      {
        field: 'marketCode',
        headerName: 'Market Code',
      },
      {
        field: 'surveyCode', headerName: 'Survey Code', pinned: "left", hide: false,
        cellRenderer: SelectColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            showFlag: params.data.processingNote != undefined,
            flagIcon: "fa-square-exclamation",
            flagColor: "red",
            toolTip: params.data.processingNote
          }
        }
      },
      {field: 'marketName', headerName: 'Market Name'},
      {field: 'industryCode', headerName: 'Industry Code'},
      {field: 'industryName', headerName: 'Industry Name'},
      {field: 'surveySubject', headerName: 'Survey Subject'},
      {
        field: 'responseCode',
        headerName: 'Response Code',
      },
      {
        field: 'personCode',
        headerName: 'Person Code',
      },
      {
        field: 'openLinkCode',
        headerName: 'Open Link Code',
      },
      {field: 'forwardedToPersonCodes', headerName: 'Forwarded to Person Codes'},
      {
        field: 'responseStatus',
        headerName: 'Response Status',
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'invitationName',
        headerName: 'Invitation Name',

      },
      {
        field: 'invalid', headerName: 'Invalid?',
        cellRenderer: CircleCheckColumnComponent
      },
      {
        field: 'invitationCompanyCode', headerName: 'Invitation Company Code'
      },
      {
        field: 'invitationCompanyLabel', headerName: 'Invitation Company Label'
      },
    ]
  },
  {
    headerName: 'Demographics', children: [
      {field: 'firstName', headerName: 'First Name'},
      {field: 'lastName', headerName: 'Last Name'},
      {field: 'email', headerName: 'Email',},
      {field: 'telephone', headerName: 'Telephone'},
      {field: 'cellPhone', headerName: 'Cell Phone'},
      {field: 'city', headerName: 'City'},
      {field: 'jobTitle', headerName: 'Job Title'},
      {field: 'surveyLanguage', headerName: 'Survey Language'}
    ]
  },
  {
    headerName: 'Nominations', children: [
      {field: 'nominatedByCodes', headerName: 'Nominated By Codes'},
      {field: 'nominatedByLabels', headerName: 'Nominated By Labels'},
      {field: 'categoryResponsibility', headerName: 'Category Responsibility'}
    ]
  },
  {
    headerName: 'Pre-selections', children: [
      {
        field: 'localRegionCodes', headerName: 'Local Region Codes'
      },
      {
        field: 'localRegionLabels', headerName: 'Local Region Labels'
      },
      {
        field: 'seniorityCode', headerName: 'Seniority Code'
      },
      {
        field: 'seniorityLabel', headerName: 'Seniority Label'
      },
      {
        field: 'roleCodes', headerName: 'Role Codes'
      },
      {
        field: 'roleLabels', headerName: 'Role Labels'
      },
      {
        field: 'brPlCode', headerName: 'BR/PL Code'
      },
      {
        field: 'brPlLabel', headerName: 'BR/PL Label'
      },
      {field: 'allBuCategories', headerName: 'All BU-Categories?', cellRenderer: CircleCheckColumnComponent},
      {
        field: 'buCategoryCodes', headerName: 'BU-Category Codes'
      },
      {
        field: 'buCategoryLabels', headerName: 'BU-Category Labels'
      },
      {
        field: 'channelCodes', headerName: 'Channel Codes'
      },
      {
        field: 'channelLabels', headerName: 'Channel Labels'
      },
      {
        field: 'subjectRollupCodes', headerName: 'Subject Rollup Codes'
      },
      {
        field: 'subjectRollupLabels', headerName: 'Subject Rollup Labels'
      },
      {
        field: 'subjectSingleCodes', headerName: 'Subject Single Codes'
      },
      {
        field: 'subjectSingleLabels', headerName: 'Subject Single Labels'
      },
    ]
  },
  {
    headerName: 'Survey File', children: [
      {
        field: 'surveyFileStatus', headerName: 'Survey File Status',
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'surveyFileCreatedDatetime',
        headerName: 'Survey File Created Datetime'
      },
      {
        field: 'surveyFile', headerName: 'Survey File',
        cellRenderer: LongDataColumnComponent,
      },
      {
        field: 'surveyFilePassword',
        headerName: 'Survey File Password',
      },
    ]
  },
  {
    headerName: "ID's", children: [
      {
        field: 'surveyId',
        headerName: 'Survey ID'
      }
    ]
  }
]
