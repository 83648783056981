import {CheckboxNode} from "../ag-checkbox-tree.component.ds";
import {BehaviorSubject} from "rxjs";
import {Injectable} from "@angular/core";
import {v4 as uuidv4} from 'uuid';

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class AgCheckboxTreeService {
  dataChange = new BehaviorSubject<CheckboxNode[]>([]);

  constructor() {
  }

  get data(): CheckboxNode[] {
    return this.dataChange.value;
  }

  /**
   * Creates the tree structure based on the map data provided.
   * @param mapData - The map containing parent-child relationships.
   * @param parentKeyPath - The key path to extract parent node labels.
   * @param childKeyPath - The key path to extract child node labels.
   */
  createAgCheckboxTreeFromMap(
    mapData: Map<any, any[]>,
    parentKeyPath: string,
    childKeyPath: string
  ) {
    // Convert the map to a plain object to use with buildFileTree
    const jsonData = this.convertMapToTreeData(mapData, parentKeyPath, childKeyPath);
    // Use the existing logic to build the tree
    this.createAgCheckboxTree(jsonData);
  }

  /**
   * Creates the tree structure based on the json data provided.
   * @param jsonData
   */
  private createAgCheckboxTree(jsonData: { [key: string]: any }) {
    const data = this.buildFileTree(jsonData, 0);
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   * @param obj
   * @param level
   * @param parentId
   * @private
   */
  private buildFileTree(obj: { [key: string]: any }, level: number, parentId?: string): CheckboxNode[] {
    const result = Object.keys(obj).reduce<CheckboxNode[]>((accumulator, key) => {
      const value = obj[key]
      const node = new CheckboxNode();
      node.id = uuidv4();
      node.item = key;

      // Store the original parent object
      if (value?.originalObject) {
        node.tag = value.originalObject;
      }

      // If there are children, build them recursively
      if (Array.isArray(value?.children)) {
        node.children = value.children.map((child: any) => {
          const childNode = new CheckboxNode();
          childNode.id = uuidv4();
          childNode.item = child.label; // Use label for the item name
          childNode.tag = child.tag; // Include original child object
          childNode.parentId = node.id;
          return childNode;
        });
      }

      if (parentId !== undefined) {
        node.parentId = parentId;
      }

      return accumulator.concat(node);
    }, []);

    return result;
  }

  /**
   * Convert the map to a nested object structure based on the provided key paths.
   * @param mapData
   * @param parentKeyPath
   * @param childKeyPath
   * @private
   */
  private convertMapToTreeData(
    mapData: Map<any, any[]>,
    parentKeyPath: string,
    childKeyPath: string
  ): { [key: string]: any } {
    const treeData: { [key: string]: any } = {};

    // Iterate over the map entries to build the tree structure
    mapData.forEach((children, parent) => {
      // Extract the parent label using the provided key path
      const parentLabel = this.extractPropertyByPath(parent, parentKeyPath);
      // Map children labels using the provided key path
      const childNodes = children.map(child => ({
        label: this.extractPropertyByPath(child, childKeyPath),
        tag: child
      }));
      treeData[parentLabel] = {
        originalObject: parent,
        children: childNodes
      }
    });

    return treeData;
  }

  /**
   * Extract a nested property value from an object using a dot-separated key path.
   * @param obj - The object to extract the property from.
   * @param keyPath - The dot-separated path to the property.
   * @private
   */
  private extractPropertyByPath(obj: any, keyPath: string): any {
    return keyPath.split('.').reduce((acc, key) => acc && acc[key], obj);
  }
}
