<div *ngIf="this.value" class="multi-line-link-column">
  <!--  <div class="multi-line-table-label">-->
  <div class="mg-t-s">
    <span *ngIf="this.actionEvent === undefined && this.toolTip === undefined && routerLink===undefined">{{ this.value }}</span>
    <span [matTooltip]="toolTip!" *ngIf="this.actionEvent === undefined && this.toolTip !== undefined">{{ this.value }}</span>
    <a (click)="onLinkClick(this.actionEvent)" *ngIf="this.actionEvent !== undefined" class="primary-link-color point-cursor">
      {{ this.value }}
    </a>
    <a [routerLink]="routerLink" *ngIf="this.routerLink!=undefined" class="primary-link-color point-cursor">{{ this.value }}</a>
  </div>
  <sub *ngIf="this.subText">{{ this.subText }}</sub>
  <div *ngIf="this.links">
    <div *ngFor="let link of this.links" class="multi-line-table-link">
      <!--  <div *ngFor="let link of this.links">-->
      <a *ngIf="link.linkType === 'external'"
         [href]="link.url"
         [target]="link.target"
         class="primary-link-color"
      >
        {{ link.text }}
        <i class="fa-sharp fa-light fa-arrow-right-long"></i>
      </a>
      <!--      [queryParams]="util.getInternalInTableLinkParams(tableColumns.responseCode.columnDef, element, filterContext)"-->
      <a *ngIf="link.linkType === 'internal'"
         [queryParams]="link.queryParams"
         [routerLink]="link.routerLink"
         [target]="link.target"
         class="primary-link-color"
      >
        {{ link.text }}
        <i class="fa-sharp fa-light fa-arrow-right-long"></i>
      </a>

    </div>
  </div>
</div>
