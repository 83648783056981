import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {FeatureComponentMode} from "@data/enums/data.enums";
import {ActivatedRoute} from "@angular/router";
import {TaskSidebarItem} from "@shared/ag-task/ag-task.component.ds";
import {displayTableColumns} from "@pages/panel/unique-link-invitees/unique-link-invitees-bulk/unique-link-invitees-bulk.component.ds";
import {BaseComponent} from "@pages/base.component";
import {UniqueLinkInvitee} from "@data/interefaces/panel.interfaces";

@Component({
  selector: 'app-unique-link-invitees-task',
  templateUrl: './unique-link-invitees-task.component.html',
  styleUrls: ['./unique-link-invitees-task.component.scss']
})
export class UniqueLinkInviteesTaskComponent extends BaseComponent implements AfterViewInit {
  featureComponentSidebarEl: TaskSidebarItem[] = [
    {
      title: "Add Row",
      icon: "fa-plus",
      selected: true,
      urlSupport: true,
      disabled: false,
      mode: FeatureComponentMode.ADD_ROW
    },
    {
      title: "Edit Row",
      icon: "fa-pen-to-square",
      selected: false,
      urlSupport: true,
      disabled: true,
      mode: FeatureComponentMode.EDIT_ROW
    },
    {
      title: "Bulk Mailout",
      icon: "fa-envelopes-bulk",
      selected: false,
      urlSupport: false,
      disabled: false,
      mode: FeatureComponentMode.BULK_MAILOUT
    },
    {
      title: "Bulk Edit",
      icon: "fa-pen",
      selected: false,
      urlSupport: false,
      disabled: false,
      mode: FeatureComponentMode.BULK_EDIT
    },
    {
      title: "Upload",
      icon: "fa-upload",
      selected: false,
      urlSupport: true,
      disabled: false,
      mode: FeatureComponentMode.UPLOAD
    }
  ];
  @Input() selectedFeatureMode: FeatureComponentMode | undefined;
  @Input() selectedRows!: UniqueLinkInvitee[];
  @Input() preSelectedFeatureMode!: FeatureComponentMode;
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  isUrlMode: boolean = false;
  urlModeGoBackUrl: string = "/forms/survey-respondents/unique-link-invitees";
  protected readonly FeatureComponentMode = FeatureComponentMode;
  protected readonly tableColumns = displayTableColumns;

  constructor(protected _activatedRoute: ActivatedRoute) {
    super();
    let paramMode = this._activatedRoute.snapshot.params['mode'];
    if (paramMode) {
      this.isUrlMode = true;
      this.selectedFeatureMode = paramMode;
    }
  }

  ngAfterViewInit(): void {
  }
}
