import { Component } from '@angular/core';
import {DetailBaseComponent} from "@pages/detail.base.component";
import {SurveySubject, YesNoValue} from "@data/enums/data.enums";
import {FormControl, FormGroup} from "@angular/forms";
import {Util} from "@data/util/util";
import {
  ProductRespondentCompany,
} from "@data/interefaces/data.interfaces";
import {RespondentCompanyService} from "@data/product-spec/respondent.company.service";

@Component({
  selector: 'app-respondent-company-detail',
  templateUrl: './respondent-company-detail.component.html',
  styleUrls: ['./respondent-company-detail.component.scss']
})
export class RespondentCompanyDetailComponent extends DetailBaseComponent {

  selectedItem: ProductRespondentCompany = {} as ProductRespondentCompany;

  formGroup: FormGroup<{ [K in keyof ProductRespondentCompany]?: FormControl<any> }> = new FormGroup<any>([]);
  pageContext: {
    [key: string]: boolean | string | undefined;
    rcc_f: string | undefined;
    ppi_f: string | undefined;
  } = {
    rcc_f: undefined,
    ppi_f: undefined
  };
  protected readonly SurveySubject = SurveySubject;

  constructor(private _respondentCompanyService: RespondentCompanyService) {
    super();
  }

  override ngOnInit(): void {
    this.displayProgressBar(true);
    super.ngOnInit();
  }

  isSurveySubjectSelected(surveySubjectEnum: SurveySubject) {
    return this.getFormControlValue("surveySubject") == surveySubjectEnum.toString();
  }

  getFormControl(controlName: (keyof ProductRespondentCompany)) {
    return this.formGroup.get(controlName) as FormControl<any>;
  }

  getFormControlValue(controlName: (keyof ProductRespondentCompany)) {
    if (!this.getFormControl(controlName) || !this.getFormControl(controlName).value) {
      return "";
    }
    return this.getFormControl(controlName).value;
  }

  protected override loadDropDownData() {
  }

  protected override getPageState(): any {
    return this.pageContext;
  }

  protected onIncludeChanged(checked: boolean) {
    let includeFormControl = this.getFormControl('include');
    includeFormControl.setValue(checked ? YesNoValue.YES_VALUE : YesNoValue.NO_VALUE);
    includeFormControl.markAsDirty({onlySelf: false});
    this.isEditMode = checked;
    this.toggleAllControls(checked, [this.getFormControl('include'), this.getFormControl('respondentCompanyReportLabelDefaultLocale')]);
  }

  protected override getContentDetails() {
    if (this.pageContext.rcc_f && this.pageContext.ppi_f) {
      this._respondentCompanyService.getRespondentCompanyById(this.pageContext.rcc_f, this.pageContext.ppi_f).then(result => {
        this.selectedItem = result;
        if (this.selectedItem.include == YesNoValue.YES_VALUE) {
          this.isEditMode = true;
        }
        this.initFormGroup();
        this.loading = false;
        this.displayProgressBar(false);
      });
    }
  }

  protected override saveOrUpdate() {
    return this._respondentCompanyService.updateRespondentCompany(Util.mapFormGroupValuesToObject<ProductRespondentCompany>(this.formGroup.getRawValue()));
  }

  protected override initFormGroup() {
    this.formGroup = new FormGroup<{ [K in keyof ProductRespondentCompany]?: FormControl<any> }>(
      {
        period: new FormControl({
          value: this.selectedItem.period,
          disabled: !this.isEditMode
        }),
        marketCode: new FormControl({
          value: this.selectedItem.marketCode,
          disabled: !this.isEditMode
        }),
        marketName: new FormControl({
          value: this.selectedItem.marketName,
          disabled: !this.isEditMode
        }),
        industryCode: new FormControl({
          value: this.selectedItem.industryCode,
          disabled: !this.isEditMode
        }),
        industryName: new FormControl({
          value: this.selectedItem.industryName,
          disabled: !this.isEditMode
        }),
        surveySubject: new FormControl({
          value: this.selectedItem.surveySubject,
          disabled: !this.isEditMode
        }),
        productCode: new FormControl({
          value: this.selectedItem.productCode,
          disabled: !this.isEditMode
        }),
        productName: new FormControl({
          value: this.selectedItem.productName,
          disabled: !this.isEditMode
        }),
        respondentGroupCode: new FormControl({
          value: this.selectedItem.respondentGroupCode,
          disabled: !this.isEditMode
        }),
        respondentGroupName: new FormControl({
          value: this.selectedItem.respondentGroupCode,
          disabled: !this.isEditMode
        }),
        ratingCount: new FormControl({
          value: this.selectedItem.ratingCount,
          disabled: !this.isEditMode
        }),
        personCount: new FormControl({
          value: this.selectedItem.personCount,
          disabled: !this.isEditMode
        }),
        subjectCompanyCount: new FormControl({
          value: this.selectedItem.subjectCompanyCount,
          disabled: !this.isEditMode
        }),
        include: new FormControl({
          value: this.selectedItem.include,
          disabled: false
        }),
        respondentCompanyCode: new FormControl({
          value: this.selectedItem.respondentCompanyCode,
          disabled: !this.isEditMode
        }),
        respondentCompanySurveyLabelDefaultLocale: new FormControl({
          value: this.selectedItem.respondentCompanySurveyLabelDefaultLocale,
          disabled: !this.isEditMode
        }),
        respondentCompanyReportLabelDefaultLocale: new FormControl({
          value: this.selectedItem.respondentCompanyReportLabelDefaultLocale,
          disabled: this.isEditMode
        }),
        // TO Add Product ID control
        productPeriodId: new FormControl({
          value: this.selectedItem.productPeriodId,
          disabled: !this.isEditMode
        }),
        productRespondentCompanyId: new FormControl({
          value: this.selectedItem.productRespondentCompanyId,
          disabled: !this.isEditMode
        }),
      });
  }

}
