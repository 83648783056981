<div class="{{showTaskComponent ? 'hidden' : 'full-height-container'}}">
  <mat-card class="full-height-container">
    <mat-card-content class="full-height-container content-panel">
      <div class="flex-row">
        <app-component-header (downloadClickEvent)="this.handleDownloadClickEvent('survey-tracker')" (refreshClickEvent)="this.refreshView()"
                              [hideColumnsButton]="true" [quilliupGuid]="QuilliupForm.SURVEY_TRACKER.guid" [startRefreshTimer]="this.refreshComplete"
                              [subtitleSegments]="getUrlSegments()" [uploadUrlParam]="{mode: 'upload'}" [uploadUrl]="['task', 'upload']"
                              class="full-width">
        </app-component-header>
      </div>
      <div class="flex-row table-container">
        <ag-kpi-sidebar [sidebar]="kpiSidebar" class="mg-r-s"></ag-kpi-sidebar>
        <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                         (filterChanged)="onFilterChanged($event)"
                         (firstDataRendered)="onFirstDataRendered($event)"
                         (gridReady)="onGridReady($event)"
                         (selectionChanged)="onRowSelectionChanged($event)"
                         (viewportChanged)="onViewportChanged($event)"
                         [columnDefs]="tableColumns"
                         [gridOptions]="baseAgGridOptions"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         [rowData]="rowData"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz ag-grid-flex full-height-container">
        </ag-grid-angular>
        <ng-template #footerActionButtonPanel>
          <button (click)="onBulkRequestResponseFilesClicked()" [disabled]="!isBulkRequestResponseFilesAllowed" class="white-font-color mg-r-m" color="accent"
                  mat-raised-button>
            <i class="fa-sharp fa-light fa-files"></i>
            Request Response Files in Bulk
          </button>
          <button (click)="onBulkMailoutClicked()" [disabled]="!isBulkOperationAllowed" class="white-font-color mg-r-m" color="accent" mat-raised-button>
            <i class="fa-sharp fa-light fa-envelopes-bulk"></i>
            Bulk Mailout
          </button>
          <button (click)="onBulkEditClicked()" [disabled]="!isBulkOperationAllowed" class="white-font-color mg-r-m" color="accent" mat-raised-button>
            <i class="fa-sharp fa-light fa-pen"></i>
            Bulk Edit
          </button>
        </ng-template>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div class="{{showTaskComponent ? 'full-height-container': 'hidden' }}">
  <app-survey-tracker-task (goBackEvent)="goBack()" *ngIf="showTaskComponent" [selectedFeatureMode]="selectedFeatureMode" [selectedRows]="this.selectedRows"/>
</div>
