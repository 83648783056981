import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType, YesNoValue} from "@data/enums/data.enums";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {Util} from "@data/util/util";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";
import {SelectHeaderComponent} from "@pages/segments/grid/columns/select-header/select-header.component";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {ProcessingStatus} from "@core/interfaces/data.interfaces";

export const includeClickAction: string = 'include';
export const excludeClickAction: string = 'exclude';

export let pageContext:
  {
  [key: string]: Date | number | string | undefined
  'p_f': string | undefined;
  'mc_f': string | undefined;
  'ic_f': string | undefined;
  'ss_f': string | undefined;
  'pc_f': string | undefined;
  'spc_f': string | undefined;
  'mn_f': string | undefined;
  'in_f': string | undefined;
  'pn_f': string | undefined;
  'spn_f': string | undefined;
  'sc_f': string | undefined;
  'sn_f': string | undefined;
  'psn_f': string | undefined;
  'rsc_f': string | undefined;
  'rc_f': string | undefined;
  'rn_f': string | undefined;
  'rs_f': string | undefined;
  'fn_f': string | undefined;
  'ln_f': string | undefined;
  'prc_f': string | undefined;
  'prn_f': string | undefined;
  'c_f': string | undefined;
  'stn_f': string | undefined;
  'vt_f': string | undefined;
  'i_f': string | undefined;
  'e_f': string | undefined;
  'vs_f': string | undefined;
  'va_f': string | undefined;
  'fd_r': Date | undefined;
  'ld_r': Date | undefined;
  'd_f': string | undefined;
  'ps_id': number | undefined;
  'v_id': number | undefined;
  'pp_id': number | undefined;
  'psv_id': number | undefined;
} =
  {
  'p_f': undefined,
  'mc_f': undefined,
  'ic_f': undefined,
  'ss_f': undefined,
  'pc_f': undefined,
  'spc_f': undefined,
  'mn_f': undefined,
  'in_f': undefined,
  'pn_f': undefined,
  'spn_f': undefined,
  'sc_f': undefined,
  'sn_f': undefined,
  'psn_f': undefined,
  'rsc_f': undefined,
  'rc_f': undefined,
  'rn_f': undefined,
  'rs_f': undefined,
  'fn_f': undefined,
  'ln_f': undefined,
  'prc_f': undefined,
  'prn_f': undefined,
  'c_f': undefined,
  'stn_f': undefined,
  'vt_f': undefined,
  'i_f': undefined,
  'e_f': undefined,
  'vs_f': undefined,
  'va_f': undefined,
  'fd_r': undefined,
  'ld_r': undefined,
  'd_f': undefined,
  'ps_id': undefined,
  'v_id': undefined,
  'pp_id': undefined,
  'psv_id': undefined
};

export let periods: string[] = [];
export let marketCodes: string[] = [];
export let industryCodes: string[] = [];
export let surveySubjects: string[] = [];
export let productCodes: string[] = [];

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    maxWidth: 90,
    field: 'select',
    headerName: '',
    hide: false,
    filter: false,
    headerComponent: SelectHeaderComponent,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    pinned: "left",
    suppressColumnsToolPanel: true,
    downloadable: false,
    cellRenderer: SelectColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        showFlag: (params.data.processing_status && params.data.processing_status !== ProcessingStatus.NONE),
        flagIcon: (
          params.data.processing_status === ProcessingStatus.PROGRESS ?
            "fa-floppy-disk-circle-arrow-right" :
            (params.data.processing_status === ProcessingStatus.COMPLETE_SUCCESS ?
              "fa-floppy-disk primary-color" :
              "fa-floppy-disk-circle-xmark error-color"
            )
        ),
        flagColor: "",
        toolTip: params.data.processing_status
      }
    }
  },
  {
    groupId: '_data_filter',
    headerName: 'Data Filter',
    valueFormatter: '',
    children: [
      {
        field: 'period',
        headerName: 'Period',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'p_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          values: periods,
          refreshValuesOnOpen: true,
          defaultToNothingSelected: true,
          closeOnApply: true,
          suppressSorting: true,
          buttons: ['apply', 'reset']
        }
      },
      {
        field: 'marketCode',
        headerName: 'Market Code',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'mc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          values: marketCodes,
          refreshValuesOnOpen: true,
          defaultToNothingSelected: true,
          closeOnApply: true,
          buttons: ['apply', 'reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketName
          };
        }
      },
      {
        field: 'industryCode',
        headerName: 'Industry Code',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'ic_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          values: industryCodes,
          refreshValuesOnOpen: true,
          defaultToNothingSelected: true,
          closeOnApply: true,
          buttons: ['apply', 'reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryName
          };
        }
      },
      {
        field: 'surveySubject',
        headerName: 'Survey Subject',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'ss_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          values: surveySubjects,
          refreshValuesOnOpen: true,
          defaultToNothingSelected: true,
          cellRenderer: StatusColumnComponent,
          closeOnApply: true,
          buttons: ['apply', 'reset']
        },
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'productCode',
        headerName: 'Product Code',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'pc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          values: productCodes,
          refreshValuesOnOpen: true,
          defaultToNothingSelected: true,
          closeOnApply: true,
          buttons: ['apply', 'reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.productName
          };
        }
      }
    ]
  },
  {
    groupId: '_sponsor',
    headerName: 'Sponsor',
    valueFormatter: '',
    children: [
      {
        field: 'marketName',
        headerName: 'Market Name',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'mn_f',
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketCode
          };
        }
      },
      {
        field: 'industryName',
        headerName: 'Industry Name',
        hide: true,
        filterId: 'in_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryCode
          };
        }
      },
      {
        field: 'productName',
        headerName: 'Product Name',
        hide: true,
        filterId: 'pn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.productCode
          };
        }
      },
      {
        field: 'sponsorCompanyCode',
        headerName: 'Sponsor Company Code',
        hide: false,
        filterId: 'spc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.sponsorCompanyLabelDefaultLocale
          };
        }
      },
      {
        field: 'sponsorCompanyLabelDefaultLocale',
        headerName: 'Sponsor Company Label (Default Locale)',
        hide: true,
        filterId: 'spn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.sponsorCompanyCode
          };
        }
      }
    ]
  },
  {
    groupId: '_response',
    headerName: 'Response',
    valueFormatter: '',
    children: [
      {
        field: 'verbatimSubmittedForCode',
        headerName: 'Verbatim Submitted For Code',
        hide: false,
        filterId: 'sc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.verbatimSubmittedForLabelDefaultLocale
          };
        }
      },
      {
        field: 'verbatimSubmittedForLabelDefaultLocale',
        headerName: 'Verbatim Submitted For Label (Default Locale)',
        hide: true,
        filterId: 'sn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.verbatimSubmittedForCode
          };
        }
      },
      {
        field: 'personCode',
        headerName: 'Person Code',
        hide: false,
        filterId: 'psn_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
        },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.respondentFirstName + ' ' + params.data.respondentLastName
          };
        }
      },
      {
        field: 'responseCode',
        headerName: 'Response Code',
        hide: false,
        filterId: 'rsc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        }
      },
      {
        field: 'respondentCompanyCode',
        headerName: 'Respondent Company Code',
        hide: false,
        filterId: 'rc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.respondentCompanyLabel
          };
        }
      },
      {
        field: 'respondentCompanyLabel',
        headerName: 'Respondent Company Label',
        hide: true,
        filterId: 'rn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.respondentCompanyCode
          };
        }
      },
      {
        field: 'respondentCompanyStatus',
        headerName: 'Respondent Company Status',
        hide: false,
        filterId: 'rs_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: StatusColumnComponent,
          buttons: ['reset']
        },
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'respondentFirstName',
        headerName: 'Respondent First Name',
        hide: true,
        filterId: 'fn_f',
        filterParams: {
          buttons: ['reset']
        }
      },
      {
        field: 'respondentLastName',
        headerName: 'Respondent Last Name',
        hide: true,
        filterId: 'ln_f',
        filterParams: {
          buttons: ['reset']
        }
      }
    ]
  },
  {
    groupId: '_verbatim',
    headerName: 'Verbatim',
    valueFormatter: '',
    children: [
      {
        field: 'practiceCode',
        headerName: 'Practice Code',
        hide: false,
        filterId: 'prc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.practiceLabel
          };
        }
      },
      {
        field: 'practiceLabel',
        headerName: 'Practice Label',
        hide: true,
        filterId: 'prn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.practiceCode
          };
        }
      },
      {
        field: 'verbatim',
        headerName: 'Verbatim',
        hide: false,
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.verbatim,
            type: 'text'
          };
        },
        filterId: 'c_f',
        filter: ColumnFilterType.TEXT
      },
      {
        field: 'sentimentName',
        headerName: 'Sentiment Name',
        hide: false,
        filterId: 'stn_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        }
      },
      {
        field: 'verbatimType',
        headerName: 'Verbatim Type',
        hide: false,
        filterId: 'vt_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: StatusColumnComponent,
          buttons: ['reset']
        },
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'include',
        headerName: 'Include?',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
        filterId: 'i_f',
        cellRenderer: CircleCheckColumnComponent,
        valueGetter: (params) => {
          return (params.data.include && params.data.include === YesNoValue.YES_VALUE) ?
            YesNoValue.YES_VALUE :
            YesNoValue.NO_VALUE;
        }
      },
      {
        field: 'eligibleByDefault',
        headerName: 'EligibleByDefault?',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: StatusColumnComponent,
          buttons: ['reset']
        },
        filterId: 'e_f',
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'verbatimSource',
        headerName: 'Verbatim Source',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: StatusColumnComponent,
          buttons: ['reset']
        },
        filterId: 'vs_f',
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'verbatimApprovalStatus',
        headerName: 'Verbatim Approval Status',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: StatusColumnComponent,
          buttons: ['reset']
        },
        filterId: 'va_f',
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'firstVerbatimPublishedDatetime',
        headerName: 'First Verbatim Published Datetime',
        hide: false,
        isMandatory: false,
        filterId: 'fd_r',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'lastVerbatimPublishedDatetime',
        headerName: 'Last Verbatim Published Datetime',
        hide: false,
        isMandatory: false,
        filterId: 'ld_r',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'duplicated',
        headerName: 'Duplicated?',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: StatusColumnComponent,
          buttons: ['reset']
        },
        filterId: 'd_f',
        cellRenderer: StatusColumnComponent
      }
    ]
  },
  {
    groupId: '_ids',
    headerName: 'ID\'s',
    valueFormatter: '',
    children: [
      {
        field: 'productSaleId',
        headerName: 'Product Sale ID',
        cellDataType: 'number',
        hide: true,
        filter: ColumnFilterType.NUMBER,
        filterId: 'ps_id',
        filterParams: {
          defaultOption: 'equals',
          maxNumConditions: 1,
          buttons: ['reset']
        }
      },
      {
        field: 'verbatimId',
        headerName: 'Verbatim ID',
        cellDataType: 'number',
        hide: true,
        filter: ColumnFilterType.NUMBER,
        filterId: 'v_id',
        filterParams: {
          defaultOption: 'equals',
          maxNumConditions: 1,
          buttons: ['reset']
        }
      },
      {
        field: 'reportRespondentCompanyUseId',
        headerName: 'Report Respondent Company Use ID',
        cellDataType: 'number',
        hide: true,
        filter: ColumnFilterType.NUMBER,
        filterId: 'rcu_id',
        filterParams: {
          defaultOption: 'equals',
          maxNumConditions: 1,
          buttons: ['reset']
        }
      },
      {
        field: 'productPeriodId',
        headerName: 'Product Period ID',
        cellDataType: 'number',
        hide: true,
        filter: ColumnFilterType.NUMBER,
        filterId: 'pp_id',
        filterParams: {
          defaultOption: 'equals',
          maxNumConditions: 1,
          buttons: ['reset']
        }
      },
      {
        field: 'productSaleVerbatimId',
        headerName: 'Product Sale Verbatim ID',
        cellDataType: 'number',
        hide: true,
        filter: ColumnFilterType.NUMBER,
        filterId: 'psv_id',
        filterParams: {
          defaultOption: 'equals',
          maxNumConditions: 1,
          buttons: ['reset']
        }
      }
    ]
  },
  {
    groupId: '_actions',
    headerName: 'Actions',
    valueFormatter: '',
    children: [
      {
        field: 'processing_status',
        headerName: 'Processing Status',
        hide: true,
        pinned: "right",
        downloadable: false,
        suppressFiltersToolPanel: true,
        filter: false,
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'processing_note',
        headerName: 'Processing Notes',
        hide: true,
        pinned: "right",
        suppressFiltersToolPanel: true,
        filter: false,
        downloadable: false,
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.processing_note,
            type: 'json'
          };
        },
        filterId: 'pn_f'
      },
      {
        field: "actions",
        headerName: "",
        pinned: "right",
        maxWidth: 75,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        filter: false,
        cellRenderer: ActionColumnComponent,
        downloadable: false,
        cellRendererParams: function (params: any) {
          return {
            actions: [
              {
                name: "Include/Exclude",
                toolTip: (params.data.include === YesNoValue.YES_VALUE ? "Exclude": "Include"),
                icon: (params.data.include === YesNoValue.YES_VALUE ?
                  "fa-arrow-left-to-arc":
                  "fa-arrow-right-to-arc"),
                disabled:
                  params.data.verbatimApprovalStatus === 'Approved' ||
                  params.data.verbatimApprovalStatus === 'Ready for Approval' ||
                  params.data.processing_status === ProcessingStatus.PROGRESS,
                actionClass: (params.data.include === YesNoValue.YES_VALUE ?
                  "error-color":
                  "primary-color"),
                actionEvent: (params.data.include === YesNoValue.YES_VALUE ?
                  excludeClickAction:
                  includeClickAction)
              }
            ]
          };
        }
      }
    ]
  }
]
