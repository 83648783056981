import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {ColumnFilterType} from "@data/enums/data.enums";
import {Util} from "@data/util/util";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {deleteClickAction} from "@pages/survey-response-file/response-file-upload/response-file-upload.component.ds";

export const cancelClickAction: string = 'cancel_button_link_click';
export const abortClickAction: string = 'abort_button_link_click';

export let pageContext: {
  [key: string]: boolean | string | undefined
  etc_f: string | undefined;
  pb_f: string | undefined;
  rd_f: string | undefined;
  rt_f: string | undefined;
  s_f: string | undefined;
  psd_f: string | undefined;
  ped_f: string | undefined;
  tiq_f: string | undefined;
  tp_f: string | undefined;
  bi_f: string | undefined;
  ii_f: string | undefined;
  tag_f: string | undefined;
  pm_f: string | undefined;
} = {
  etc_f: undefined,
  pb_f: undefined,
  rd_f: undefined,
  rt_f: undefined,
  s_f: undefined,
  psd_f: undefined,
  ped_f: undefined,
  tiq_f: undefined,
  tp_f: undefined,
  bi_f: undefined,
  ii_f: undefined,
  tag_f: undefined,
  pm_f: undefined
}

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    field: 'eventType',
    headerName: 'Event Type',
    filterId: 'etc_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    enableRowGroup: true,
  },
  {
    field: 'processedBy',
    headerName: 'Processed By',
    filterId: 'pb_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    enableRowGroup: true,
  },
  {
    field: 'createdDateTime',
    headerName: 'Request Date',
    filterId: 'rd_f',
    filter: ColumnFilterType.DATE,
    filterParams: {
      defaultOption: 'inRange',
      maxNumConditions: 1,
      comparator: Util.dateComparator
    },
    useValueFormatterForExport: false,
    valueFormatter: (params) => {
      return Util.formatDateToLocalString(params.value);
    },
    enableRowGroup: true,
  },
  {
    field: 'createdDateTime',
    headerName: 'Request Time',
    filterId: 'rt_f',
    valueFormatter: (params) => {
      return Util.formatToHourMinuteString(params.value);
    }
  },
  {
    field: 'statusDisplay',
    headerName: 'Status',
    filterId: 's_f',
    minWidth: 180,
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    cellRenderer: StatusColumnComponent,
    enableRowGroup: true,
  },
  {
    field: 'startDatetime',
    headerName: 'Processing Start Date',
    filterId: 'psd_f',
    filter: ColumnFilterType.DATE,
    filterParams: {
      defaultOption: 'inRange',
      maxNumConditions: 1,
      comparator: Util.dateComparator
    },
    useValueFormatterForExport: false,
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },
  {
    field: 'endDatetime',
    headerName: 'Processing End Date',
    filterId: 'ped_f',
    filter: ColumnFilterType.DATE,
    filterParams: {
      defaultOption: 'inRange',
      maxNumConditions: 1,
      comparator: Util.dateComparator
    },
    useValueFormatterForExport: false,
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },
  {
    field: 'timeInQueueInSeconds',
    headerName: 'Time in Queue',
  },
  {
    field: 'timeProcessingInSeconds',
    headerName: 'Time Processing',
  },
  {
    field: 'processMessage',
    headerName: 'Details',
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.value,
        type: 'text',
      };
    },
  },
  {
    field: 'businessIdentifiersDisplay',
    headerName: 'Business Identifiers',
    cellRenderer: (params: any) => {
      return params.value ? params.value : '';
    },
    flex: 1,
    autoHeight: true,
    cellClass: 'grid-cell-wrapper',
  },
  {
    field: 'internalIdentifiersDisplay',
    headerName: 'Internal Identifiers',
    cellRenderer: (params: any) => {
      return params.value ? params.value : '';
    },
    flex: 1,
    autoHeight: true,
    cellClass: 'grid-cell-wrapper',
  },
  {
    field: 'metricsDisplay',
    headerName: 'Metrics',
    hide: true,
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data?.metricsDisplay || '',
        type: params.data?.metricsDisplay ? 'json' : 'text'
      };
    },
    flex: 1,
    autoHeight: true,
  },
  {
    field: 'id',
    headerName: 'Event Log ID',
  },
  {
    field: "actions",
    headerName: "Actions",
    pinned: "right",
    maxWidth: 100,
    filter: false,
    cellRenderer: ActionColumnComponent,
    downloadable: false,
    cellRendererParams: function (params: any) {
      const actions = [];

      // Add the first button if eventStatus is "pending"
      if (params.data.eventStatus === "pending") {
        actions.push({
          toolTip: "Cancel",
          icon: "fa-undo-alt",
          name: "Cancel",
          actionEvent: cancelClickAction,
        });
      }
      // Add the second button if eventStatus is "in progress"
      if (params.data.eventStatus === "in progress") {
        actions.push({
          toolTip: "Abort",
          icon: "fa-stop-circle",
          name: "Abort",
          actionEvent: abortClickAction,
        });
      }
      return { actions };
    }
  }
];
