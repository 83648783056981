import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType, DownloadRequestProcessStatus} from "@data/enums/data.enums";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {Util} from "@data/util/util";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {SelectHeaderComponent} from "@pages/segments/grid/columns/select-header/select-header.component";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";

let maxDownloadRequestThreshold: number = 0;
let isUserAdmin: boolean = false;
let marketCodesToLastExpeditedCount: Map<String, number> = new Map();
let currentUser: string = '';

export const expediteClickAction = 'expedite_link_click';
export const cancelClickAction = 'cancel_link_click';

export function setDownloadRequestThreshold(count: number) {
  maxDownloadRequestThreshold = count;
}

export function setIsUserAdmin(isAdmin: boolean) {
  isUserAdmin = isAdmin;
}

export function setMarketCodesToLastExpeditedMap(map: Map<String, number>) {
  marketCodesToLastExpeditedCount = map;
}

export function setCurrentUser(user?: string) {
  currentUser = user ?? '';
}

export let pageContext: {
  [key: string]: boolean | string | undefined
  per_f: string | undefined;
  m_f: string | undefined;
  pt_f: string | undefined;
  p_f: string | undefined;
  s_f: string | undefined;
  l_f: string | undefined;
  rb_f: string | undefined;
  rd_f: string | undefined;
  sd_f: string | undefined;
  ed_f: string | undefined;
  para_f: string | undefined;
  ps_f: string | undefined;
  psm_f: string | undefined;
  sfu_f: string | undefined;
  efc_f: string | undefined;
  bc_f: string | undefined;
} = {
  per_f: undefined,
  m_f: undefined,
  pt_f: undefined,
  p_f: undefined,
  s_f: undefined,
  l_f: undefined,
  rb_f: undefined,
  rd_f: undefined,
  sd_f: undefined,
  ed_f: undefined,
  para_f: undefined,
  ps_f: undefined,
  psm_f: undefined,
  sfu_f: undefined,
  efc_f: undefined,
  bc_f: undefined
}

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    maxWidth: 75,
    field: 'select',
    headerName: '',
    hide: false,
    filter: false,
    headerComponent: SelectHeaderComponent,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    pinned: "left",
    suppressColumnsToolPanel: true,
    downloadable: false,
    cellRenderer: SelectColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        showFlag: false,
        flagIcon: "fa-exclamation",
        flagColor: "red",
        toolTip: 'Invalid'
      }
    }
  },
  {
    headerName: 'Details', valueFormatter: '', children: [
      {
        field: 'period',
        headerName: 'Period',
        hide: false,
        filterId: 'per_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        maxWidth: 100
      },
      {
        field: 'country',
        headerName: 'Market',
        hide: false,
        filterId: 'm_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        maxWidth: 100
      },
      {
        field: 'batchCode',
        headerName: 'Batch Code',
        rowGroup: true,
        hide: false,
        filter: ColumnFilterType.TEXT,
        filterId: 'bc_f',
        filterParams: {
          buttons: ['reset']
        }
      },
      {
        field: 'productType',
        headerName: 'Product Type',
        hide: false,
        minWidth: 300,
        filterId: 'pt_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
      },
      {
        field: 'product',
        headerName: 'Product',
        hide: false,
        minWidth: 400,
        filterId: 'p_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
      },
      {
        field: 'sponsor',
        headerName: 'Sponsor',
        hide: false,
        maxWidth: 120,
        filterId: 's_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
      },
      {
        field: 'language',
        headerName: 'Language',
        hide: false,
        filterId: 'l_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        maxWidth: 120
      },
      {
        field: 'requestedBy',
        headerName: 'Requested By',
        hide: false,
        filterId: 'rb_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
      },
      {
        field: 'requestDate',
        headerName: 'Request Date',
        hide: false,
        filterId: 'rd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          maxNumConditions: 1,
          comparator: Util.dateComparator
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'priority',
        headerName: 'Is Expedited?',
        hide: false,
        filterId: 'e_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          buttons: ['reset']
        },
        cellRenderer: CircleCheckColumnComponent,
        valueGetter: params => {
          // change value to boolean for CircleCheckColumnComponent
          // return params.data.priority === 1
        }
      },
      {
        field: 'processStartDate',
        headerName: 'Start Date',
        hide: true,
        filterId: 'sd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          maxNumConditions: 1,
          comparator: Util.dateComparator
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'processEndDate',
        headerName: 'End Date',
        hide: true,
        filterId: 'ed_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          maxNumConditions: 1,
          comparator: Util.dateComparator
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'downloadParameters',
        headerName: 'Parameters',
        hide: false,
        filterId: 'para_f',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          if (params.node.group) {
            return;
          }
          return {
            text: params.data.downloadParameters ?? '',
            type: 'json',
            title: 'Parameters'
          };
        }
      },
      {
        field: 'processStatus',
        headerName: 'Status',
        hide: false,
        minWidth: 180,
        filterId: 'ps_f',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'processStatusMessage',
        headerName: 'Message',
        hide: false,
        filterId: 'psm_f',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          if (params.node.group) {
            return;
          }
          return {
            text: params.data.processStatusMessage,
            type: 'text',
            title: 'Message'
          };
        }
      },
      {
        field: 'sharepointFolderUrl',
        headerName: 'Sharepoint Folder Url',
        hide: false,
        filterId: 'sfu_f',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          if (params.node.group) {
            return;
          }
          return {

            text: params.data.sharepointFolderUrl,
            type: 'link',
          };
        }
      }
    ]
  },
  {
    headerName: 'Metrics', valueFormatter: '', children: [
      {
        field: 'metrics.estimatedFileCount',
        headerName: 'Estimated File Count',
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.estimatedSlideCount',
        headerName: 'Estimated Slide Count',
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.currentNumberInQueue',
        headerName: 'Current number in queue',
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.estimatedTimeInQueueInSeconds',
        headerName: 'Estimated time in queue',
        valueFormatter: (params) => Util.formatSecondsToHHMMSS(params.value),
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.estimatedProcessingTimeInSeconds',
        headerName: 'Estimated processing time',
        valueFormatter: (params) => Util.formatSecondsToHHMMSS(params.value),
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.estimatedCompletionDatetime',
        headerName: 'Estimated completion datetime',
        valueFormatter: (params) => Util.getFormattedDate(params.value),
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.timeInQueueInSeconds',
        headerName: 'Time in queue',
        valueFormatter: (params) => Util.formatSecondsToHHMMSS(params.value),
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.timeToProcessInSeconds',
        headerName: 'Time to process',
        valueFormatter: (params) => Util.formatSecondsToHHMMSS(params.value),
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.totalCompletionTimeInSeconds',
        headerName: 'Total completion time',
        valueFormatter: (params) => Util.formatSecondsToHHMMSS(params.value),
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.fileCount',
        headerName: 'File count',
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.fileSize',
        headerName: 'File size',
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.slideCount',
        headerName: 'Slide count',
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.staticSlideCount',
        headerName: '"Static" slide count',
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.templateSlideCount',
        headerName: '"Template" slide count',
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.dataSlideCount',
        headerName: '"Data" slide count',
        hide: true,
        filter: false,
        maxWidth: 120
      },
      {
        field: 'metrics.orphanedSlideCount',
        headerName: '"Orphaned" slide count',
        hide: true,
        filter: false,
        maxWidth: 120
      }
    ]
  },
  {
    field: 'action',
    headerName: 'Action',
    hide: false,
    isMandatory: true,
    downloadable: false,
    filter: false,
    pinned: "right",
    maxWidth: 150,
    cellRenderer: ActionColumnComponent,
    cellRendererParams: function (params: any) {
      if (params.node.group) {
        return;
      }
      const rowMarketCode = params.data.country;
      const currentMarketExpediteCount = marketCodesToLastExpeditedCount.get(rowMarketCode) ?? 0;
      const isNotPending = params.data.processStatus != DownloadRequestProcessStatus.PENDING
      const isRequestedByOtherAndNotAdmin = params.data.requestedBy !== currentUser && !isUserAdmin;
      const disableIcon = isNotPending ||
        (params.data?.priority === 5 && currentMarketExpediteCount >= maxDownloadRequestThreshold && !isUserAdmin) ||
        (params.data?.priority === 1 && isRequestedByOtherAndNotAdmin);
      const disableCancelIcon = isNotPending || isRequestedByOtherAndNotAdmin;
      return {
        actions: [
          {
            toolTip: "Request Detail",
            icon: "fa-pen-to-square",
            name: "Request Detail",
            routerLink: ['task', 'detail'],
            queryParams: {dri_f: params.data.id}
          },
          {
            toolTip: (params.data?.priority === 5 && currentMarketExpediteCount >= maxDownloadRequestThreshold && !isUserAdmin) ?
              `Current expedite limit of ${maxDownloadRequestThreshold} for ${rowMarketCode} reached (${currentMarketExpediteCount}).`
              : params.data?.priority === 1 ?
                (params.data?.priority === 1 && isRequestedByOtherAndNotAdmin) ? 'Cannot downgrade requests you do not own' : 'Downgrade Request'
                : 'Expedite Request',
            icon: params.data?.priority === 1 ? 'fa-gauge-circle-minus' : 'fa-gauge-circle-plus',
            name: 'Expedite Request',
            disabled: disableIcon,
            actionEvent: expediteClickAction
          },
          {
            toolTip: "Cancel Request",
            icon: "fa-ban",
            name: "Cancel Request",
            disabled: disableCancelIcon,
            actionEvent: cancelClickAction
          }
        ]
      };
    }
  }
]
