import {AfterViewInit, Component, Input} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {tableColumns} from "@pages/product-spec/product-sponsor-verbatim/product-sponsor-verbatim.component.ds";
import {FeatureComponentMode, YesNoUndeterminedValue, YesNoValue} from "@data/enums/data.enums";
import {BulkBaseComponent} from "@pages/bulk.base.component";
import {ProductSponsorVerbatim} from "@data/interefaces/product.sponsor.verbatim.interfaces";
import {ProductSponsorVerbatimService} from "@data/product-spec/product.sponsor.verbatim.service";
import {
  ProductSponsorVerbatimWithProcessStatus
} from "@pages/product-spec/product-sponsor-verbatim/product-sponsor-verbatim-bulk/product-sponsor-verbatim-bulk.component.ds";
import {
  BatchProcessResult,
  ProductRespondentCompany
} from "@data/interefaces/data.interfaces";
import {
  ProductSponsorVerbatimComponentUtil
} from "@pages/product-spec/product-sponsor-verbatim/product-sponsor-verbatim.component.util";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-product-sponsor-verbatim-bulk',
  templateUrl: './product-sponsor-verbatim-bulk.component.html',
  styleUrls: ['./product-sponsor-verbatim-bulk.component.scss']
})
export class ProductSponsorVerbatimBulkComponent extends BulkBaseComponent implements AfterViewInit {
  @Input() override selectedRows: ProductSponsorVerbatim[] = [];
  protected override formGroup: FormGroup<{ [K in keyof ProductSponsorVerbatim]?: FormControl<any> }> = new FormGroup<any>([]);
  protected override readonly tableColumns = tableColumns;
  protected readonly YesNoUndeterminedValue = YesNoUndeterminedValue;

  protected defaultIncludeValue: YesNoUndeterminedValue = YesNoUndeterminedValue.UNDETERMINED_VALUE;

  constructor(
    private _productSponsorVerbatimService: ProductSponsorVerbatimService
  ) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
  }

  uploadFinished(event: { status: number; message: string; file?: File }) {
    this.processUploadedFile<ProductSponsorVerbatimWithProcessStatus>(event);
  }

  getFormControl(controlName: (keyof ProductSponsorVerbatim)) {
    return this.getControl<ProductSponsorVerbatim>(controlName) as FormControl<any>;
  }

  protected override initFormGroup() {
    const selectedRowsAllIncluded = this.selectedRows.every(item => item.include === YesNoValue.YES_VALUE);
    const selectedRowsAllExcluded = this.selectedRows.every(item => item.include !== YesNoValue.YES_VALUE);
    if(selectedRowsAllIncluded) {
      this.defaultIncludeValue = YesNoUndeterminedValue.NO_VALUE;
    } else if(selectedRowsAllExcluded) {
      this.defaultIncludeValue = YesNoUndeterminedValue.YES_VALUE;
    } else {
      this.defaultIncludeValue = YesNoUndeterminedValue.UNDETERMINED_VALUE;
    }

    this.formGroup = new FormGroup<{ [K in keyof ProductRespondentCompany]?: FormControl<any> }>(
      {
        include: new FormControl({
          value: this.defaultIncludeValue,
          disabled: false
        })
      });
  }
  showIncludeOptionPleaseSelect() {
    return this.defaultIncludeValue === YesNoUndeterminedValue.UNDETERMINED_VALUE;
  }
  showIncludeOptionIncludeAll() {
    return this.defaultIncludeValue === YesNoUndeterminedValue.UNDETERMINED_VALUE ||
      this.defaultIncludeValue === YesNoUndeterminedValue.YES_VALUE
  }
  showIncludeOptionExcludeAll() {
    return this.defaultIncludeValue === YesNoUndeterminedValue.UNDETERMINED_VALUE ||
      this.defaultIncludeValue === YesNoUndeterminedValue.NO_VALUE
  }

  protected override getBatchProcessResultByType(): Promise<BatchProcessResult> {
    return this._productSponsorVerbatimService.getLatestProductSponsorVerbatimBatchProcess();
  }

  protected override initFieldsByFeatureComponentMode() {
    ProductSponsorVerbatimComponentUtil.setColumns(this.tableColumns, this.featureComponentMode);
    switch (this.featureComponentMode) {
      case FeatureComponentMode.BULK_INCLUDE:
        this.title = "Product Sponsor Verbatim Bulk Include / Exclude";
        this.subtitle = "Product Sponsor Verbatim Bulk Include / Exclude Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        break;
      case FeatureComponentMode.UPLOAD:
        this.title = "Product Sponsor Verbatim Bulk Upload";
        this.subtitle = "Product Sponsor Verbatim Bulk Upload Configuration";
        this.submitButtonText = "Start Upload";
        this.updateStage = 1;
        break;
      default:
        break;
    }
  }

  protected override hasAnyEdits() {
    let include = this.getFormControl('include').value;
    return include === YesNoUndeterminedValue.YES_VALUE || include === YesNoUndeterminedValue.NO_VALUE;
  }

  protected override startBatchProcess(selectedRows: ProductSponsorVerbatim[]): Promise<any> {
    return this._productSponsorVerbatimService.startProductSponsorVerbatimBatchProcess(selectedRows, this.featureComponentMode.toString());
  }

  protected override validateRowData<T>(rowData: T[]) {
    let returnList: T[] = [];
    rowData.map(item => {
      const element = item as ProductSponsorVerbatim;
      if (!element) {
        throw new Error('Element is not the proper type.');
      }
    });

    return returnList;
  }
}
