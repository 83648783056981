import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {BatchProcessResult} from "@data/interefaces/data.interfaces";
import {MsExcelSurveyInvitees} from "@data/interefaces/panel.interfaces";
import {Util} from "@data/util/util";
import {DataItem, OperationStatus, ResultsetThresholds} from "@data/interefaces/data.interfaces";

@Injectable({
  providedIn: 'root'
})
export class MsExcelSurveyInviteesService {

  constructor(private _apiService: ApiService) {
  }

  public getMsExcelSurveyInvitees(period: string, marketCode: string, surveyCode: string): Promise<MsExcelSurveyInvitees[]> {
    let queryParams: string[] = [];
    Util.pushQueryParam("period", period, queryParams);
    Util.pushQueryParam("marketCode", marketCode, queryParams);
    Util.pushQueryParam("surveyCode", surveyCode, queryParams);
    return this._apiService.makeGetServiceCall<MsExcelSurveyInvitees[]>(`/v2/api/panel/ms-excel-survey-invitees`, queryParams.join('&'));
  }

  public getMsExcelSurveyInviteesById(surveyCode: string, responseCode: string): Promise<MsExcelSurveyInvitees> {
    return this._apiService.makeGetServiceCall<MsExcelSurveyInvitees>(`/v2/api/panel/ms-excel-survey-invitees/${surveyCode}/${responseCode}`);
  }

  public getMsExcelSurveyInviteesThresholds(period: string, marketCode: string, surveyCode: string): Promise<ResultsetThresholds> {
    let queryParams: string[] = [];
    Util.pushQueryParam("period", period, queryParams);
    Util.pushQueryParam("marketCode", marketCode, queryParams);
    Util.pushQueryParam("surveyCode", surveyCode, queryParams);
    return this._apiService.makeGetServiceCall<ResultsetThresholds>(`/v2/api/panel/ms-excel-survey-invitees/thresholds`, queryParams.join('&'));
  }

  public saveMsExcelSurveyInvitees(msExcelSurveyInvitees: any): Promise<DataItem> {
    return this._apiService.makePostServiceCall<DataItem>(`/v2/api/panel/ms-excel-survey-invitees`, null, msExcelSurveyInvitees);
  }

  public updateMsExcelSurveyInvitees(msExcelSurveyInvitees: any) {
    return this._apiService.makePutServiceCall<OperationStatus>(`/v2/api/panel/ms-excel-survey-invitees`, null, msExcelSurveyInvitees);
  }

  public getLatestBatchProcess() {
    return this._apiService.makeGetServiceCall<BatchProcessResult>(`/v2/api/panel/ms-excel-survey-invitees/bulk-process/latest`);
  }

  public startBatchProcess(msExcelSurveyInvitees: MsExcelSurveyInvitees[], processType: string) {
    let queryParams: string[] = [];
    Util.pushQueryParam("processType", processType, queryParams);
    return this._apiService.makePutServiceCall<{ id: number }>(`/v2/api/panel/ms-excel-survey-invitees/bulk-process/start`, queryParams.join('&'), msExcelSurveyInvitees);
  }
}
