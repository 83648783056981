<div id="three-state-selector-wrapper">
  <ng-container>
    <mat-form-field class='three-state-selector-wrapper'>
      <mat-label>{{label}}</mat-label>
      <mat-select
        [placeholder]="undeterminedLabel"
        [formControl]="formCtrl"
        (selectionChange)="onSelectionChange($event)">
        <mat-option *ngIf="showUndeterminedValue" [value]='undeterminedValue'>
          <i *ngIf="undeterminedIcon" [ngClass]="'fa-sharp fa-light ' + undeterminedIcon"></i>
          {{undeterminedLabel}}
        </mat-option>
        <mat-option *ngIf="showYesValue" [value]='yesValue'>
          <i *ngIf="yesIcon" [ngClass]="'fa-sharp fa-light ' + yesIcon"></i>
          {{yesLabel}}
        </mat-option>
        <mat-option *ngIf="showNoValue" [value]='noValue'>
          <i *ngIf="noIcon" [ngClass]="'fa-sharp fa-light ' + noIcon"></i>
          {{noLabel}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</div>
