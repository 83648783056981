import {GridColumn, GridColumnGroupDef, ProductPeriod} from "@data/interefaces/data.interfaces";
import {ProcessStatus} from "@data/interefaces/extended.data.interfaces";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";

export interface ProductPeriodWithProcessStatus extends ProductPeriod, ProcessStatus {
}

export let productPeriodDisplayTableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    headerName: 'Product Period', children: [
      {
        field: 'period', headerName: 'Period', pinned: "left", hide: false,
        cellRenderer: SelectColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            showFlag: params.data.processingNote != undefined,
            flagIcon: "fa-square-exclamation",
            flagColor: "red",
            toolTip: params.data.processingNote
          }
        }
      },
      {field: 'marketCode', headerName: 'Market Code'},
      {field: 'marketName', headerName: 'Market Name'},

      {field: 'industryCode', headerName: 'Industry Code'},
      {field: 'industryName', headerName: 'Industry Name'},
      {field: 'surveySubject', headerName: 'Survey Subject', cellRenderer: StatusColumnComponent},

      {field: 'productCode', headerName: 'Product Code'},
      {field: 'productName', headerName: 'Product Name'},
    ]
  },
  {
    headerName: 'Product Configuration', children: [
      {field: 'include', headerName: 'Included?', cellRenderer: CircleCheckColumnComponent},
      {field: 'respondentGroupCode', headerName: 'Respondent Group Code'},
      {field: 'respondentGroupName', headerName: 'Respondent Group Name'},
      {field: 'languages', headerName: 'Languages'},
      {field: 'allRespondentCompanies', headerName: 'All Respondent Companies?', cellRenderer: CircleCheckColumnComponent},
      {field: 'standardRespondentWeightSetCode', headerName: 'Standard Respondent Weight Set Code'},
      {field: 'standardRespondentWeightSetName', headerName: 'Standard Weight Set Name'},
      {field: 'productPeriodTrendAlignments', headerName: 'Product Period Trend Alignments'},
      {field: 'maskedByDefault', headerName: 'Masked By Default', cellRenderer: CircleCheckColumnComponent},
      {field: 'maskingOverride', headerName: 'Masking Override', cellRenderer: StatusColumnComponent}
    ]
  }, {
    headerName: 'Release Management', children: [
      {field: 'approvalStatus', headerName: 'Approval Status', cellRenderer: StatusColumnComponent},
      {field: 'approvalDatetime', headerName: 'Approval Datetime'},
      {field: 'approvalChecksumDatetime', headerName: 'Approved Checksum Datetime'},
      {field: 'pendingChecksumDatetime', headerName: 'Pending Checksum Datetime'},
    ]
  },
  {
    headerName: 'Product Summary File', children: [
      {field: 'productSummaryFileStatus', headerName: 'Product Summary File Status'},
      {field: 'productSummaryFileCreatedDatetime', headerName: 'Product Summary File Created Datetime'},
      {field: 'productSummaryFileURL', headerName: 'Product Summary File URL'}
    ]
  },
  {
    headerName: "ID's", children: [
      {field: 'productId', headerName: 'Product Id'},
      {field: 'productPeriodId', headerName: 'Product Period Id'}
    ]
  }
]
