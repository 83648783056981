import {ComponentRef, Directive, HostBinding, Input, OnChanges, SimpleChanges, ViewContainerRef} from '@angular/core';
import {AgSkeletonComponent} from "@shared/ag-skeleton/ag-skeleton.component";

@Directive({
  selector: '[agSkeleton]'
})
export class AgSkeletonDirective implements OnChanges {

  @Input('showSkeleton')
  showSkeleton: boolean = true;
  @Input('skeletonType')
  skeletonType: string | undefined;
  agSkeletonComp: ComponentRef<AgSkeletonComponent> | undefined;

  @HostBinding('class')
  cssClass = 'hide-content';

  constructor(private viewContainerRef: ViewContainerRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showSkeleton'].currentValue == true) {
      this.cssClass = 'hide-content'
      this.agSkeletonComp = this.viewContainerRef.createComponent(AgSkeletonComponent);
      this.agSkeletonComp.setInput("uiType", this.skeletonType);
    } else if (changes['showSkeleton'].currentValue == false) {
      this.cssClass = ''
      if (this.agSkeletonComp !== undefined) {
        this.agSkeletonComp.hostView.destroy();
      }
    }
  }

}
