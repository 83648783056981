<div [showSkeleton]="loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <mat-card *ngIf="!loading" class="task-component-detail backdrop" id="task-component-detail">
    <mat-card-header class="mg-b-m">
      <div class="header-title">
        <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
        <mat-card-subtitle>Product Period</mat-card-subtitle>
      </div>
      <div class="flex-row view-only-block mg-t-s" style="margin-left: auto">
        <ag-slide-toggle (toggleUpdate)="onIncludeChanged($event.checked)"
                         [colorType]="'error'"
                         [isChecked]="util.isToggleChecked(this.getFormControl('include'))"
                         [isDisabled]="!isEditMode"
                         class="slide-toggle"></ag-slide-toggle>
        <span class="mg-l-s">Include?</span>
      </div>
    </mat-card-header>
    <mat-card-content class="form-container">
      <form (ngSubmit)="this.submitForm(this.saveAndClose, 'Product Period Saved.')" [formGroup]="formGroup">
        <div class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('period')}}"
                          title="Period"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('marketCode') + ' ' + getFormControlValue('marketName')}}"
                          title="Market"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('industryCode') + ' ' + getFormControlValue('industryName')}}"
                          title="Industry"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('productCode') + ' ' + getFormControlValue('productName')}}"
                          title="Product"></ag-datablock>
          </div>
          <div class="view-only-block">
            <label>Survey Subject</label>
            <mat-chip-listbox aria-label="Status" class="mat-mdc-chip-set-stacked" disabled="true">
              <div class="flex-row">
                <mat-chip-option
                  [selected]="isSurveySubjectSelected(SurveySubject.SUPPLIER)"
                  color="accent"
                  id="ss_s">{{ SurveySubject.SUPPLIER }}
                </mat-chip-option>
                <mat-chip-option
                  [selected]="isSurveySubjectSelected(SurveySubject.RETAILER)"
                  color="accent"
                  id="ss_r">{{ SurveySubject.RETAILER }}
                </mat-chip-option>
              </div>
            </mat-chip-listbox>
          </div>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-b-m mg-t-m">
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('respondentGroupCode') + ' ' + getFormControlValue('respondentGroupName')}}"
              title="Respondent Group"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('languages')}}"
                          title="Languages"></ag-datablock>
          </div>
          <div class="mg-b-m flex-row w-25">
            <ag-slide-toggle (toggleUpdate)="onAllRespondentCompaniesChanged($event)"
                             [isChecked]="util.isToggleChecked(this.getFormControl('allRespondentCompanies'))"
                             [isDisabled]="!isEditMode"
            ></ag-slide-toggle>
            <span class="mg-l-s">All Respondent Companies?</span>
          </div>
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('standardRespondentWeightSetCode') + ' ' + getFormControl('standardRespondentWeightSetName')}}"
              title="Standard Respondent Weight Set"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row mg-b-m mg-t-m">
          <mat-form-field>
            <mat-label>Respondent Group</mat-label>
            <mat-select [formControl]="getFormControl('respondentGroupCode')">
              <mat-option
                *ngFor="let respondentGroup of respondentGroupPeriodList"
                [value]="respondentGroup.respondentGroupCode"
              >{{ respondentGroup.respondentGroupCode + ' ' + respondentGroup.respondentGroupName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Languages</mat-label>
            <mat-select [formControl]="getFormControl('languages')">
              <mat-option
                *ngFor="let language of languagesList"
                [value]="language"
              >{{ language }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="mg-b-m flex-row w-25">
            <ag-slide-toggle (toggleUpdate)="onAllRespondentCompaniesChanged($event)"
                             [isChecked]="util.isToggleChecked(this.getFormControl('allRespondentCompanies'))"
                             [isDisabled]="!isEditMode"
            ></ag-slide-toggle>
            <span class="mg-l-s">All Respondent Companies?</span>
          </div>
          <mat-form-field>
            <mat-label>Standard Respondent Weight Set</mat-label>
            <mat-select [formControl]="getFormControl('standardRespondentWeightSetCode')">
              <mat-option
                *ngFor="let companyWeightSet of companyWeightSet"
                [value]="companyWeightSet.weightSetCode"
              >{{ companyWeightSet.weightSetCode + ' ' + companyWeightSet.weightSetName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock
              data="{{getFormControlValue('maskingOverride')}}"
              title="Masking Override"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('approvalStatus')}}"
                          title="Approval Status"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row">
          <mat-form-field>
            <mat-label>Masking Override</mat-label>
            <mat-select [formControl]="getFormControl('maskingOverride')">
              <mat-option
                *ngFor="let maskingOverride of maskingOverrideList"
                [value]="maskingOverride.value"
              >{{ maskingOverride.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Approval Status</mat-label>
            <mat-select [formControl]="getFormControl('approvalStatus')">
              <mat-option
                *ngFor="let approvalStatus of approvalStatusList"
                [value]="approvalStatus.value"
              >{{ approvalStatus.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex-row view-only-container mg-b-m mg-t-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('productPeriodTrendAlignments')}}"
                          title="Product Period Trend Alignments"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('maskedByDefault')}}"
                          title="Masked By Default?"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('approvalDatetime')}}"
                          title="Approval Datetime"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('approvedChecksumDatetime')}}"
                          title="Approved Checksum Datetime"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('pendingChecksumDatetime')}}"
                          title="Pending Checksum Datetime"></ag-datablock>
          </div>
        </div>
        <div class="flex-row mg-t-m">
          <mat-form-field>
            <mat-label>Product Summary File Status</mat-label>
            <mat-select [formControl]="getFormControl('productSummaryFileStatus')">
              <mat-option
                *ngFor="let productSummaryFileStatus of productSummaryFileStatusList"
                [value]="productSummaryFileStatus"
              >{{ productSummaryFileStatus }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('productSummaryFileCreatedDatetime')}}"
                          title="Product Summary File Created Datetime"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock [url]="getFormControlValue('productSummaryFileUrl')"
                          data="{{getFormControlValue('productSummaryFileUrl')}}"
                          title="Product Summary File Url"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container mg-b-m mg-t-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('productId')}}"
                          title="Product ID"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('productPeriodId')}}"
                          title="Product Period ID"></ag-datablock>
          </div>
        </div>
        <div class="flex-row flex-g-s action-buttons">
          <button (click)="onSaveAndCloseClick()" [disabled]="!this.hasAnyEdits()" color="primary" mat-raised-button
                  type="submit">
            <mat-icon class="material-symbols-outlined">save</mat-icon>
            Save and Close
          </button>
          <button (click)="resetForm()" *ngIf="isEditMode" [disabled]="!this.hasAnyEdits()" mat-button type="button">
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
            Reset
          </button>
          <button (click)="resetForm(); close()" *ngIf="hasAnyEdits()" [disabled]="!this.hasAnyEdits()" mat-button
                  type="button">
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
            Cancel
          </button>
          <button (click)="resetForm(); close()" *ngIf="!hasAnyEdits()" [disabled]=" this.hasAnyEdits()"
                  class="primary-font-color" mat-button type="button">
            <i class="fa-sharp fa-light fa-xmark-large"></i> Close
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
