import {Component} from '@angular/core';
import {MsExcelSurveyInvitees, ViewPersonCode} from "@data/interefaces/panel.interfaces";
import {
  ViewSurvey,
  ViewSurveyBuCategories,
  ViewSurveyChannels,
  ViewSurveyIndustryFlags,
  ViewSurveyLocales,
  ViewSurveyLocalRegions,
  ViewSurveyRespondentCompanies,
  ViewSurveyRoles,
  ViewSurveySeniorities,
  ViewSurveySubjectRollups,
  ViewSurveySubjectSingles
} from "@data/interefaces/survey.spec.interfaces";
import {FileRequestStatus, ResponseStatus, SurveySubject} from "@data/enums/data.enums";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Util} from "@data/util/util";
import {SurveySpecService} from "@data/survey-spec/survey.spec.service";
import {PanelService} from "@data/panel/panel.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {MsExcelSurveyInviteesService} from "@data/panel/ms.excel.survey.invitees.service";
import {ReferenceService} from "@data/reference/reference.service";
import {NominatedBy} from "@pages/panel/ms-excel-survey-invitees/ms-excel-survey-invitees.component.ds";
import {DetailBaseComponent} from "@pages/detail.base.component";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-ms-excel-survey-invitees-detail',
  templateUrl: './ms-excel-survey-invitees-detail.component.html',
  styleUrls: ['./ms-excel-survey-invitees-detail.component.scss']
})
export class MsExcelSurveyInviteesDetailComponent extends DetailBaseComponent {

  selectedItem: MsExcelSurveyInvitees = {} as MsExcelSurveyInvitees;
  responseApprovalStatus: string = "";
  surveyCodeList: ViewSurvey[] | undefined;
  personCodeList: ViewPersonCode[] | undefined;
  invitationCompanyCodeList: ViewSurveyRespondentCompanies[] | undefined;
  surveyLanguageList: ViewSurveyLocales[] | undefined;
  buCategoriesList: ViewSurveyBuCategories[] | undefined;
  localRegionsList: ViewSurveyLocalRegions[] | undefined;
  senioritiesList: ViewSurveySeniorities[] | undefined;
  rolesList: ViewSurveyRoles[] | undefined;
  channelsList: ViewSurveyChannels[] | undefined;
  industryFlagsList: ViewSurveyIndustryFlags[] | undefined;
  subjectRollupsList: ViewSurveySubjectRollups[] | undefined;
  subjectSinglesList: ViewSurveySubjectSingles[] | undefined;
  nominatedByList: NominatedBy[] | undefined;
  surveyFileStatusList: FileRequestStatus[] | undefined;
  approvedOrReady: boolean = this.responseApprovalStatus == 'Approved' || this.responseApprovalStatus == 'Ready for Approval';

  override formGroup: FormGroup<{ [K in keyof MsExcelSurveyInvitees]?: FormControl<any> }> = new FormGroup<any>([]);
  override pageContext: {
    [key: string]: boolean | string | undefined;
    sc_f: string | undefined;
    rc_f: string | undefined;
  } = {
    sc_f: undefined,
    rc_f: undefined,
  };
  alwaysEnabledControls = [this.formGroup.controls.surveyCode];
  protected readonly ResponseStatus = ResponseStatus;
  protected readonly FeatureComponentMode = FeatureComponentMode;

  constructor(private _surveySpecService: SurveySpecService, private _panelService: PanelService,
              private _msExcelSurveyInviteesService: MsExcelSurveyInviteesService, private _referenceService: ReferenceService) {
    super();
  }

  override ngOnInit(): void {
    this.displayProgressBar(true);
    super.ngOnInit();
  }

  loadDropdownsBySurveyCode(surveyCode: string) {
    this._panelService.getPersonCodes(surveyCode).then(result => {
      this.personCodeList = result;
    });
    this._surveySpecService.getSurveyRespondentCompanies(surveyCode).then(result => {
      this.invitationCompanyCodeList = result;
    });
    this._surveySpecService.getSurveyLocales(surveyCode).then(result => {
      this.surveyLanguageList = result;
    });
    this._surveySpecService.getSurveyBuCategories(surveyCode).then(result => {
      this.buCategoriesList = result;
    });
    this._surveySpecService.getSurveyLocalRegions(surveyCode).then(result => {
      this.localRegionsList = result;
    });
    this._surveySpecService.getSurveySeniorities(surveyCode).then(result => {
      this.senioritiesList = result;
    });
    this._surveySpecService.getSurveyRoles(surveyCode).then(result => {
      this.rolesList = result;
    });
    this._surveySpecService.getSurveyChannels(surveyCode).then(result => {
      this.channelsList = result;
    });
    this._surveySpecService.getSurveyIndustryFlags(surveyCode).then(result => {
      this.industryFlagsList = result;
    });
    this._surveySpecService.getSurveySubjectRollups(surveyCode).then(result => {
      this.subjectRollupsList = result;
    });
    this._surveySpecService.getSurveySubjectSingles(surveyCode).then(result => {
      this.subjectSinglesList = result.filter(s => s != null);
    });
    this._surveySpecService.getSurveySeniorities(surveyCode).then(result => {
      this.senioritiesList = result;
    });
    this._surveySpecService.getSurveyIndustryFlags(surveyCode).then(result => {
      this.industryFlagsList = result;
    });
    this.nominatedByList = [
      ...(this.subjectSinglesList || []).map(this.mapToNominatedBy),
      ...(this.subjectRollupsList || []).map(this.mapToNominatedBy)
    ];
  }

  private mapToNominatedBy(singleOrRollup: ViewSurveySubjectSingles | ViewSurveySubjectRollups): NominatedBy {
    if ('subjectCompanySingleCode' in singleOrRollup) {
      return {
        code: singleOrRollup.subjectCompanySingleCode,
        label: singleOrRollup.subjectCompanySingleLabel,
        surveyIds: singleOrRollup.surveyIds,
        surveyCodes: singleOrRollup.surveyCodes
      };
    } else {
      return {
        code: singleOrRollup.subjectCompanyRollupCode,
        label: singleOrRollup.subjectCompanyRollupLabel,
        surveyIds: singleOrRollup.surveyIds,
        surveyCodes: singleOrRollup.surveyCodes
      };
    }
  }

  private loadSurveyCodesDropdown() {
    this._surveySpecService.getSurveys().then(result => {
      this.surveyCodeList = result;
    });
  }

  onAllBuCategoriesChanged(event: MatSlideToggleChange) {
    Util.setToggleValue(event.checked, this.getFormControl("allBuCategories"), false);
  }

  isResponseStatusSelected(responseStatusEnum: ResponseStatus) {
    return this.formGroup.controls.responseStatus?.value == responseStatusEnum.toString();
  }

  protected getFormControl(controlName: (keyof MsExcelSurveyInvitees)) {
    return this.getControl<MsExcelSurveyInvitees>(controlName);
  }

  protected getFormControlValue(controlName: keyof MsExcelSurveyInvitees) {
    return this.getControlValue<MsExcelSurveyInvitees>(controlName);
  }

  protected override getPageState(): any {
    return this.pageContext;
  }

  protected override loadDropDownData() {
  }

  protected override getContentDetails() {
    if (this.pageContext.sc_f && this.pageContext.rc_f) {
      this._msExcelSurveyInviteesService.getMsExcelSurveyInviteesById(this.pageContext.sc_f, this.pageContext.rc_f).then(result => {
        this.selectedItem = result;
        this.initEditMode();
      });
    } else {
      this.initAddMode();
    }
  }

  private initEditMode() {
    this.isEditMode = this.selectedItem.surveyCode !== undefined;
    if (this.featureComponentMode == FeatureComponentMode.EDIT_ROW) {
      this.title = "Edit Row";
      this.loadDropdownsBySurveyCode(this.selectedItem.surveyCode);
    }
    this.loadResponseApprovalStatus();
  }

  private initAddMode() {
    this.title = "Add Row";
    this.initFormGroup();
    this.loadSurveyCodesDropdown();
    this.loadResponseFilesStatusDropdown();
    this.displayProgressBar(false);
    this.loading = false;
    this.isEditMode = true;
  }

  onSurveyCodeSelected(surveyCode: string) {
    this.loadDropdownsBySurveyCode(surveyCode);
    if (this.featureComponentMode == FeatureComponentMode.ADD_ROW) {
      this.toggleAllControls(true, this.alwaysEnabledControls);
      this.refreshFormGroup();
    }

  }

  isSurveySubjectSelected(surveySubjectEnum: SurveySubject) {
    return this.formGroup.controls.surveySubject?.value == surveySubjectEnum.toString();
  }

  protected override initFormGroup() {
    let isAddMode = this.featureComponentMode == FeatureComponentMode.ADD_ROW;
    this.formGroup = new FormGroup<{ [K in keyof MsExcelSurveyInvitees]?: FormControl<any> }>(
      {
        period: new FormControl({
          value: this.selectedItem.period,
          disabled: true
        }, Validators.required),
        marketName: new FormControl({
          value: this.selectedItem.marketName,
          disabled: true
        }, Validators.required),
        marketCode: new FormControl({
          value: this.selectedItem.marketCode,
          disabled: true
        }, Validators.required),
        industryName: new FormControl({
          value: this.selectedItem.industryName,
          disabled: true
        }, Validators.required),
        industryCode: new FormControl({
          value: this.selectedItem.industryCode,
          disabled: true
        }, Validators.required),
        surveySubject: new FormControl({
          value: this.selectedItem.surveySubject,
          disabled: true
        }, Validators.required),
        surveyCode: new FormControl({
          value: this.selectedItem.surveyCode,
          disabled: false
        }, Validators.required),
        responseCode: new FormControl({
          value: this.selectedItem.responseCode,
          disabled: true
        }),
        personCode: new FormControl({
          value: this.selectedItem.personCode,
          disabled: isAddMode
        }),
        openLinkCode: new FormControl({
          value: this.selectedItem.openLinkCode,
          disabled: isAddMode
        }),
        responseStatus: new FormControl({
          value: isAddMode ? ResponseStatus.NOT_STARTED : this.selectedItem.responseStatus,
          disabled: true
        }),
        invitationCompanyCode: new FormControl({
          value: this.selectedItem.invitationCompanyCode,
          disabled: isAddMode
        }, Validators.required),
        invitationCompanyLabel: new FormControl({
          value: this.selectedItem.invitationCompanyLabel,
          disabled: true
        }),
        localRegionCodes: new FormControl({
          value: this.selectedItem.localRegionCodes,
          disabled: this.approvedOrReady
        }),
        localRegionLabels: new FormControl({
          value: this.selectedItem.localRegionLabels,
          disabled: this.approvedOrReady
        }),
        seniorityCode: new FormControl({
          value: this.selectedItem.seniorityCode,
          disabled: isAddMode
        }, Validators.required),
        seniorityLabel: new FormControl({
          value: this.selectedItem.seniorityLabel,
          disabled: true
        }),
        roleCodes: new FormControl({
          value: this.selectedItem.roleCodes,
          disabled: this.approvedOrReady
        }),
        roleLabels: new FormControl({
          value: this.selectedItem.roleLabels,
          disabled: this.approvedOrReady
        }),
        brPlCode: new FormControl({
          value: this.selectedItem.brPlCode,
          disabled: isAddMode
        }, Validators.required),
        brPlLabel: new FormControl({
          value: this.selectedItem.brPlLabel,
          disabled: true
        }),
        allBuCategories: new FormControl({
          value: this.selectedItem.allBuCategories,
          disabled: isAddMode
        }),
        buCategoryCodes: new FormControl({
          value: this.selectedItem.buCategoryCodes,
          disabled: this.approvedOrReady
        }),
        buCategoryLabels: new FormControl({
          value: this.selectedItem.buCategoryLabels,
          disabled: this.approvedOrReady
        }),
        channelCodes: new FormControl({
          value: this.selectedItem.channelCodes,
          disabled: this.approvedOrReady
        }),
        channelLabels: new FormControl({
          value: this.selectedItem.channelLabels,
          disabled: this.approvedOrReady
        }),
        nominatedByCodes: new FormControl({
          value: this.selectedItem.nominatedByCodes,
          disabled: this.approvedOrReady
        }),
        nominationNotes: new FormControl({
          value: this.selectedItem.nominationNotes,
          disabled: this.approvedOrReady
        }),
        surveyId: new FormControl({
          value: this.selectedItem.surveyId,
          disabled: true
        }),
        invitationName: new FormControl({
          value: this.selectedItem.invitationName,
          disabled: isAddMode
        }, Validators.required),
        invalid: new FormControl({
          value: this.selectedItem.invalid,
          disabled: true
        }),
        firstName: new FormControl({
          value: this.selectedItem.firstName,
          disabled: this.approvedOrReady
        }, Validators.required),
        lastName: new FormControl({
          value: this.selectedItem.lastName,
          disabled: this.approvedOrReady
        }, Validators.required),
        email: new FormControl({
          value: this.selectedItem.email,
          disabled: this.approvedOrReady
        }, Validators.required),
        telephone: new FormControl({
          value: this.selectedItem.telephone,
          disabled: this.approvedOrReady
        }),
        cellPhone: new FormControl({
          value: this.selectedItem.cellPhone,
          disabled: this.approvedOrReady
        }),
        city: new FormControl({
          value: this.selectedItem.city,
          disabled: this.approvedOrReady
        }),
        jobTitle: new FormControl({
          value: this.selectedItem.jobTitle,
          disabled: this.approvedOrReady
        }),
        surveyLanguage: new FormControl({
          value: this.selectedItem.surveyLanguage,
          disabled: this.approvedOrReady
        }, Validators.required),
        nominatedByLabels: new FormControl({
          value: this.selectedItem.nominatedByLabels,
          disabled: this.approvedOrReady
        }),
        categoryResponsibility: new FormControl({
          value: this.selectedItem.categoryResponsibility,
          disabled: this.approvedOrReady
        }),
        subjectRollupCodes: new FormControl({
          value: this.selectedItem.subjectRollupCodes,
          disabled: this.approvedOrReady
        }),
        subjectRollupLabels: new FormControl({
          value: this.selectedItem.subjectRollupLabels,
          disabled: this.approvedOrReady
        }),
        subjectSingleCodes: new FormControl({
          value: this.selectedItem.subjectSingleCodes,
          disabled: this.approvedOrReady
        }),
        subjectSingleLabels: new FormControl({
          value: this.selectedItem.subjectSingleLabels,
          disabled: this.approvedOrReady
        }),
        surveyFileStatus: new FormControl({
          value: this.selectedItem.surveyFileStatus,
          disabled: this.approvedOrReady
        }, Validators.required),
        surveyFileCreatedDatetime: new FormControl({
          value: this.selectedItem.surveyFileCreatedDatetime,
          disabled: true
        }),
        surveyFile: new FormControl({
          value: this.selectedItem.surveyFile,
          disabled: true
        }),
        surveyFilePassword: new FormControl({
          value: this.selectedItem.surveyFilePassword,
          disabled: true
        })
      });
  }

  override saveOrUpdate() {
    if (this.featureComponentMode == FeatureComponentMode.ADD_ROW) {
      return this._msExcelSurveyInviteesService.saveMsExcelSurveyInvitees(Util.mapFormGroupValuesToObject<MsExcelSurveyInvitees>(this.formGroup.getRawValue()));
    } else {
      return this._msExcelSurveyInviteesService.updateMsExcelSurveyInvitees(Util.mapFormGroupValuesToObject<MsExcelSurveyInvitees>(this.formGroup.getRawValue()));
    }
  }

  private loadResponseFilesStatusDropdown() {
    let surveyFileStatus = this.getFormControl('surveyFileStatus').value;
    this.surveyFileStatusList = surveyFileStatus ? [surveyFileStatus, Util.getFileRequestStatusTransition(surveyFileStatus)] : [FileRequestStatus.NONE];
  }

  private loadResponseApprovalStatus() {
    this._referenceService.getSurveyById(this.selectedItem.surveyId).then(result => {
      this.responseApprovalStatus = result.responseApprovalStatus;
      this.approvedOrReady = this.responseApprovalStatus == 'Approved' || this.responseApprovalStatus == 'Ready for Approval';
      this.initFormGroup();
      this.loadSurveyCodesDropdown();
      this.loadResponseFilesStatusDropdown();
      this.loadDropdownsBySurveyCode(this.selectedItem.surveyCode);
      this.displayProgressBar(false);
      this.loading = false;
      if (this.responseApprovalStatus === 'Under Construction') {
        this.isEditMode = true;
      }
    });
  }

  protected readonly SurveySubject = SurveySubject;
}
