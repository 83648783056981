<mat-accordion class="expansion-panel-wrapper" id="expansion-panel-wrapper">
  <mat-expansion-panel [(expanded)]="isOpen" [disabled]="isDisabled" class="expansion-panel">
    <mat-expansion-panel-header (click)="changeState()">
      <mat-panel-title >
        <i [class.disable_icon]="isDisabled" [ngClass]="getPanelHeaderIcon()"></i>
        {{ title }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-content select="[expansion-content]"></ng-content>
    <div class="flex-row flex-g-s">
      <button (click)="resetFilters()" *ngIf="supportReset" color="primary" mat-stroked-button>
        <i class='fa-sharp fa-light fa-clock-rotate-left fa-lg mg-r-s'></i>
        Reset
      </button>
    </div>
  </mat-expansion-panel>
  <div (click)="closeArea()" *ngIf="isOpen" class="backdrop"></div>
</mat-accordion>
