<mat-grid-list [cols]="2" class="signin" rowHeight="4:3">
  <mat-grid-tile>
    <div class="pd-l-m">
      <h1>Welcome to the Advantage Research Management (ARM) Console!</h1>
      <p>Please sign in to start using the available views to interface with our research data and report deliverables.</p>
      <button (click)="signIn()" color="primary" mat-raised-button>Sign in</button>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <img alt="data trends" fill="" ngSrc="assets/undraw_data_trends_b0wg.svg" priority="">
  </mat-grid-tile>
</mat-grid-list>
