<mat-card class="task-component-detail full-height-container" id="task-component-add">

  <mat-card-header class="mg-b-m">
    <div class="header-title">
      <mat-card-title class="primary-font-color">{{ this.title }}</mat-card-title>
      <mat-card-subtitle>Create a new parameter download request</mat-card-subtitle>
    </div>
  </mat-card-header>
  <mat-card-content>
    <form (ngSubmit)="save()" [formGroup]="formGroup" class="form-field">
      <mat-form-field class="form-field">
        <mat-label>Product</mat-label>
        <mat-select (selectionChange)="onSelectionChange()" formControlName="downloadType">
          <mat-option *ngFor="let product of products" [value]="product.automatedReportCode">{{ product.automatedReportName }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="form-field">
        <mat-label>Parameters</mat-label>
        <textarea (change)="onTextAreaChange()" formControlName="downloadParameters" matInput rows="10"></textarea>
      </mat-form-field>

      <div class="flex-row flex-g-s action-buttons">
        <button (click)="onSaveClick()" color="primary" mat-raised-button
                type="submit">
          <mat-icon class="material-symbols-outlined">save</mat-icon>
          Save
        </button>
        <button (click)="onSaveAndNewClick()" color="primary"
                mat-raised-button type="submit">
          <mat-icon class="material-symbols-outlined">save</mat-icon>
          Save and New
        </button>
        <button (click)="onSaveAndCloseClick()" color="primary" mat-raised-button
                type="submit">
          <mat-icon class="material-symbols-outlined">save</mat-icon>
          Save and Close
        </button>
        <button (click)="resetForm()" mat-button type="button">
          <mat-icon class="material-symbols-outlined">cancel</mat-icon>
          Reset
        </button>
        <button (click)="resetForm(); close()"
                class="primary-font-color" mat-button type="button">
          <i class="fa-sharp fa-light fa-xmark-large"></i> Close
        </button>
      </div>

    </form>
  </mat-card-content>

</mat-card>
