import {Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {KpiSidebarItem} from "@shared/ag-kpi-sidebar/ag-kpi-sidebar.component.ds";


@Component({
  selector: 'ag-kpi-sidebar',
  templateUrl: './ag-kpi-sidebar.component.html',
  styleUrls: ['./ag-kpi-sidebar.component.scss'],
  animations: [
    trigger('shrinkAnimation', [
      state('expanded', style({
        width: '100px',
      })),
      state('shrunken', style({
        width: '30px',
        height: '60px'
      })),
      transition('expanded <=> shrunken', [
        animate('0.5s ease-out')
      ]),
    ]),
  ]
})
export class AgKpiSidebarComponent {
  @Input() sidebar: KpiSidebarItem[] = [];
  shrinkState: string = 'expanded';

  shrinkDiv() {
    this.shrinkState = (this.shrinkState === 'expanded') ? 'shrunken' : 'expanded';
  }
}
