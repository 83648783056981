<div>
  <ng-container *ngIf="menuItem.childMenu != undefined">
    <ng-container *ngIf="isRootMenu">
      <button [matMenuTriggerFor]="subMenu" mat-button>
        {{ menuItem.displayName }}
      </button>
    </ng-container>
    <ng-container *ngIf="!isRootMenu">
      <a [matMenuTriggerFor]="subMenu" mat-menu-item>
        {{ menuItem.displayName }}
      </a>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="menuItem.childMenu == undefined">
    <ng-container *ngIf="isRootMenu">
      <button [routerLink]="menuItem.fullPath" mat-button>
        {{ menuItem.displayName }}
      </button>
    </ng-container>
    <ng-container *ngIf="!isRootMenu">
      <a [routerLink]="menuItem.fullPath" mat-menu-item>
        {{ menuItem.displayName }}
      </a>
    </ng-container>
  </ng-container>
  <mat-menu #subMenu class="mat-menu-holder">
    <a mat-menu-item class="menu-button" *ngFor="let item of menuItem.childMenu">
      <ag-sub-menu *ngIf="item.childMenu != undefined; else linkMenu" [isRootMenu]="false" [menuItem]="item"/>
        <ng-template #linkMenu>
          <a [routerLink]="item.fullPath" mat-menu-item>{{ item.displayName }}</a>
        </ng-template>
    </a>
  </mat-menu>
</div>
