import {Component} from '@angular/core';
import {ResponseStatus, SurveySubject, YesNoValue} from "@data/enums/data.enums";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  ViewSurvey,
  ViewSurveyBuCategories,
  ViewSurveyChannels,
  ViewSurveyIndustryFlags,
  ViewSurveyLocales,
  ViewSurveyLocalRegions,
  ViewSurveyRespondentCompanies,
  ViewSurveyRespondentDivisions,
  ViewSurveyRoles,
  ViewSurveySeniorities,
  ViewSurveySubjectRollups,
  ViewSurveySubjectSingles
} from "@data/interefaces/survey.spec.interfaces";
import {YearsInRole} from "@data/interefaces/lookup.interfaces";
import {UniqueLinkInvitee, ViewMailoutFieldsets, ViewMailoutTemplates} from "@data/interefaces/panel.interfaces";
import {SurveySpecService} from "@data/survey-spec/survey.spec.service";
import {LookupService} from "@data/lookup/lookup.service";
import {PanelService} from "@data/panel/panel.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {Util} from "@data/util/util";
import {UniqueLinkInviteesService} from "@data/panel/unique.link.invitees.service";
import {maxCompaniesToRateValidator} from "@pages/panel/unique-link-invitees/unique-link-invitees.component.validator";
import {DetailBaseComponent} from "@pages/detail.base.component";

@Component({
  selector: 'unique-link-invitees-detail',
  templateUrl: './unique-link-invitees-detail.component.html',
  styleUrls: ['./unique-link-invitees-detail.component.scss']
})
export class UniqueLinkInviteesDetailComponent extends DetailBaseComponent {

  selectedItem: UniqueLinkInvitee = {} as UniqueLinkInvitee;
  surveyCodeList: ViewSurvey[] | undefined;
  invitationCompanyCodeList: ViewSurveyRespondentCompanies[] | undefined;
  invitationDivisionCodesList: ViewSurveyRespondentDivisions[] | undefined;
  buCategoriesList: ViewSurveyBuCategories[] | undefined;
  localRegionsList: ViewSurveyLocalRegions[] | undefined;
  senioritiesList: ViewSurveySeniorities[] | undefined;
  rolesList: ViewSurveyRoles[] | undefined;
  channelsList: ViewSurveyChannels[] | undefined;
  industryFlagsList: ViewSurveyIndustryFlags[] | undefined;
  subjectRollupsList: ViewSurveySubjectRollups[] | undefined;
  subjectSinglesList: ViewSurveySubjectSingles[] | undefined;
  surveyLanguageList: ViewSurveyLocales[] | undefined;
  yearsInRoleList: YearsInRole[] | undefined;
  mailoutTemplateCodeList: ViewMailoutTemplates[] | undefined;
  mailoutFieldsetCodeList: ViewMailoutFieldsets[] | undefined;
  override formGroup: FormGroup<{ [K in keyof UniqueLinkInvitee]?: FormControl<any> }> = new FormGroup<any>([]);
  override pageContext: {
    [key: string]: boolean | string | undefined;
    sc_f: string | undefined;
    rc_f: string | undefined;
  } = {
    sc_f: undefined,
    rc_f: undefined
  };
  preFillSurveySelectionsDependentFields: (keyof UniqueLinkInvitee)[] = [
    "lockSurveySelections", "seniorityCode", "allBuCategories", "buCategoryCodes", "localRegionCodes",
    "roleCodes", "channelCodes", "brPlCode", "subjectRollupCodes", "subjectSingleCodes"
  ];
  remoteTaskIdDependentFields: (keyof UniqueLinkInvitee)[] = [
    "mailoutDatetime", "mailoutFieldSetCode", "mailoutTemplateCode"
  ];
  allBuCategoriesDependentFields: (keyof UniqueLinkInvitee)[] = [
    "buCategoryCodes"
  ];

  protected readonly ResponseStatus = ResponseStatus;
  protected readonly SurveySubject = SurveySubject;

  constructor(private _surveySpecService: SurveySpecService, private _lookupService: LookupService,
              private _panelService: PanelService, private _uniqueLinkInviteesService: UniqueLinkInviteesService) {
    super();
  }

  get inviteeUrl() {
    return this.getFormControl('inviteeUrl');
  }

  override ngOnInit(): void {
    // call displayProgressBar before calling super class.
    this.displayProgressBar(true);
    super.ngOnInit();
  }

  initAddMode() {
    this.title = "Add Row";
    this.initFormGroup();
    this.displayProgressBar(false);
    this.loading = false;
  }

  loadDropdownsBySurveyCode(surveyCode: string) {
    this._surveySpecService.getSurveyRespondentCompanies(surveyCode).then(result => {
      this.invitationCompanyCodeList = result;
    });
    this._surveySpecService.getSurveyRespondentDivisions(surveyCode).then(result => {
      this.invitationDivisionCodesList = result;
    });
    this._surveySpecService.getSurveyLocales(surveyCode).then(result => {
      this.surveyLanguageList = result;
    });
    this._surveySpecService.getSurveyBuCategories(surveyCode).then(result => {
      this.buCategoriesList = result;
    });
    this._surveySpecService.getSurveyLocalRegions(surveyCode).then(result => {
      this.localRegionsList = result;
    });
    this._surveySpecService.getSurveySeniorities(surveyCode).then(result => {
      this.senioritiesList = result;
    });
    this._surveySpecService.getSurveyRoles(surveyCode).then(result => {
      this.rolesList = result;
    });
    this._surveySpecService.getSurveyChannels(surveyCode).then(result => {
      this.channelsList = result;
    });
    this._surveySpecService.getSurveyIndustryFlags(surveyCode).then(result => {
      this.industryFlagsList = result;
    });
    this._surveySpecService.getSurveySubjectRollups(surveyCode).then(result => {
      this.subjectRollupsList = result;
    });
    this._surveySpecService.getSurveySubjectSingles(surveyCode).then(result => {
      this.subjectSinglesList = result.filter(s => s != null);
    });
    this._surveySpecService.getSurveySeniorities(surveyCode).then(result => {
      this.senioritiesList = result;
    });
    this._panelService.getMailoutTemplates(surveyCode).then(result => {
      this.mailoutTemplateCodeList = result;
    });
    this._panelService.getMailoutFieldsets(surveyCode).then(result => {
      this.mailoutFieldsetCodeList = result;
    });
  }

  onSurveyCodeSelected() {
    if (this.getSurveyCodeEditRule()) {
      this.loadDropdownsBySurveyCode(this.formGroup.controls.surveyCode?.value);
    }
    this.applyEditRules();
  }

  onPrefillSurveySelectionsChanged(event: MatSlideToggleChange, formControlName: (keyof UniqueLinkInvitee)) {
    Util.setToggleValue(event.checked, this.getFormControl(formControlName));
    this.applyEditRules();
  }

  onAllBuCategoriesChanged(event: MatSlideToggleChange, formControlName: (keyof UniqueLinkInvitee)) {
    Util.setToggleValue(event.checked, this.getFormControl(formControlName));
    this.applyEditRules();
  }

  onBuCategoriesSelected(event: MatSlideToggleChange, formControlName: (keyof UniqueLinkInvitee)) {
    this.applyEditRules();
  }

  isResponseStatusSelected(responseStatusEnum: ResponseStatus) {
    return this.formGroup.controls.responseStatus?.value == responseStatusEnum.toString();
  }

  isSurveySubjectSelected(surveySubjectEnum: SurveySubject) {
    return this.formGroup.controls.surveySubject?.value == surveySubjectEnum.toString();
  }

  protected override loadDropDownData() {
    this._surveySpecService.getSurveys().then(result => {
      this.surveyCodeList = result;
    });
    this._lookupService.getYearsInRole().then(result => {
      this.yearsInRoleList = result;
    });
  }

  protected getSurveyCodeEditRule() {
    return this.formGroup.controls.surveyCode?.value != undefined && this.formGroup.controls.surveyCode.value != '';
  }

  protected getPrefillEditRule() {
    return this.formGroup.controls.preFillSurveySelections?.value == YesNoValue.YES_VALUE;
  }

  protected getRemoteTaskIdEditRule() {
    return this.formGroup.controls.remoteTaskId?.value != undefined && this.formGroup.controls.remoteTaskId.value != '';
  }

  protected getAllBuCategoriesEditRule() {
    return this.formGroup.controls.allBuCategories?.value == YesNoValue.YES_VALUE;
  }

  protected getBuCategoriesEditRule() {
    return this.formGroup.controls.buCategoryCodes?.value != undefined && this.formGroup.controls.buCategoryCodes.value != '';
  }

  protected override getContentDetails() {
    if (this.pageContext.sc_f && this.pageContext.rc_f) {
      this._uniqueLinkInviteesService.getUniqueLinkInviteesById(this.pageContext.sc_f, this.pageContext.rc_f).then(result => {
        this.selectedItem = result;
        this.initEditMode();
      });
    } else {
      this.initAddMode();
    }
  }

  protected override initFormGroup() {
    this.formGroup = new FormGroup<{ [K in keyof UniqueLinkInvitee]?: FormControl<any> }>(
      {
        period: new FormControl({
          value: this.selectedItem.period,
          disabled: true
        }),
        marketCode: new FormControl({
          value: this.selectedItem.marketCode,
          disabled: true
        }),
        marketName: new FormControl({
          value: this.selectedItem.marketName,
          disabled: true
        }),
        industryCode: new FormControl({
          value: this.selectedItem.industryCode,
          disabled: true
        }),
        industryName: new FormControl({
          value: this.selectedItem.industryName,
          disabled: true
        }),
        surveySubject: new FormControl({
          value: this.selectedItem.surveySubject,
          disabled: true
        }),
        surveyCode: new FormControl({
          value: this.selectedItem.surveyCode,
          disabled: this.isEditMode
        }),
        remoteRespId: new FormControl({
          value: this.selectedItem.remoteRespId,
          disabled: this.isEditMode
        }),
        responseCode: new FormControl({
          value: this.isEditMode ? this.selectedItem.responseCode : -1,
          disabled: this.isEditMode
        }),
        personCode: new FormControl({
          value: this.selectedItem.personCode,
          disabled: this.isEditMode
        }),
        responseStatus: new FormControl({
          value: this.selectedItem.responseStatus,
          disabled: this.isEditMode
        }),
        invalid: new FormControl(
          this.selectedItem.invalid
        ),
        invitationCompanyCode: new FormControl({
          value: this.selectedItem.invitationCompanyCode,
          disabled: !this.isEditMode
        }, Validators.required),
        invitationDivisionCodes: new FormControl({
          value: Util.convertDelimitedStringToArray(this.selectedItem.invitationDivisionCodes),
          disabled: !this.isEditMode
        }),
        firstName: new FormControl({
          value: this.selectedItem.firstName,
          disabled: !this.isEditMode
        }, Validators.required),
        lastName: new FormControl({
          value: this.selectedItem.lastName,
          disabled: !this.isEditMode
        }, Validators.required),
        email: new FormControl({
          value: this.selectedItem.email,
          disabled: !this.isEditMode
        }, Validators.required),
        telephone: new FormControl({
          value: this.selectedItem.telephone,
          disabled: !this.isEditMode
        }),
        cellPhone: new FormControl({
          value: this.selectedItem.cellPhone,
          disabled: !this.isEditMode
        }),
        city: new FormControl({
          value: this.selectedItem.city,
          disabled: !this.isEditMode
        }),
        jobTitle: new FormControl({
          value: this.selectedItem.jobTitle,
          disabled: !this.isEditMode
        }),
        yearsInRole: new FormControl({
          value: this.selectedItem.yearsInRole,
          disabled: !this.isEditMode
        }),
        preFillContactInformation: new FormControl(
          this.selectedItem.preFillContactInformation
        ),
        surveyLanguage: new FormControl({
          value: this.selectedItem.surveyLanguage,
          disabled: !this.isEditMode
        }, Validators.required),
        inviteeUrl: new FormControl({
          value: this.selectedItem.inviteeUrl,
          disabled: !this.isEditMode
        }),
        nominatedByCodes: new FormControl({
          value: Util.convertDelimitedStringToArray(this.selectedItem.nominatedByCodes),
          disabled: !this.isEditMode
        }),
        categoryResponsibility: new FormControl({
          value: this.selectedItem.categoryResponsibility,
          disabled: !this.isEditMode
        }),
        nominationNotes: new FormControl({
          value: this.selectedItem.nominationNotes,
          disabled: !this.isEditMode
        }),
        preFillSurveySelections: new FormControl(
          this.selectedItem.preFillSurveySelections
        ),
        lockSurveySelections: new FormControl(
          this.selectedItem.lockSurveySelections
        ),
        showDivisionsCollapsed: new FormControl(
          this.selectedItem.showDivisionsCollapsed
        ),
        startInReviewMode: new FormControl(
          this.selectedItem.startInReviewMode,
        ),
        localRegionCodes: new FormControl({
          value: Util.convertDelimitedStringToArray(this.selectedItem.localRegionCodes),
          disabled: !this.isEditMode
        }, []),
        seniorityCode: new FormControl({
          value: this.selectedItem.seniorityCode,
          disabled: !this.isEditMode
        }, []),
        roleCodes: new FormControl({
          value: Util.convertDelimitedStringToArray(this.selectedItem.roleCodes),
          disabled: !this.isEditMode
        }, []),
        allBuCategories: new FormControl(
          this.selectedItem.allBuCategories
        ),
        buCategoryCodes: new FormControl({
          value: Util.convertDelimitedStringToArray(this.selectedItem.buCategoryCodes),
          disabled: !this.isEditMode
        }, []),
        channelCodes: new FormControl({
          value: Util.convertDelimitedStringToArray(this.selectedItem.channelCodes),
          disabled: !this.isEditMode
        }, []),
        brPlCode: new FormControl({
          value: this.selectedItem.brPlCode,
          disabled: !this.isEditMode
        }, []),
        subjectRollupCodes: new FormControl({
          value: Util.convertDelimitedStringToArray(this.selectedItem.subjectRollupCodes),
          disabled: !this.isEditMode
        }, []),
        subjectSingleCodes: new FormControl({
          value: Util.convertDelimitedStringToArray(this.selectedItem.subjectSingleCodes),
          disabled: !this.isEditMode
        }, []),
        maxTotalCompaniesToRate: new FormControl({
          value: this.selectedItem.maxTotalCompaniesToRate,
          disabled: !this.isEditMode
        }, Validators.pattern("^[0-9]*$")),
        maxP1CompaniesToRate: new FormControl({
          value: this.selectedItem.maxP1CompaniesToRate,
          disabled: !this.isEditMode
        }, Validators.pattern("^[0-9]*$")),
        maxP2CompaniesToRate: new FormControl({
          value: this.selectedItem.maxP2CompaniesToRate,
          disabled: !this.isEditMode
        }, Validators.pattern("^[0-9]*$")),
        maxP3CompaniesToRate: new FormControl({
          value: this.selectedItem.maxP3CompaniesToRate,
          disabled: !this.isEditMode
        }, Validators.pattern("^[0-9]*$")),
        mailoutTemplateCode: new FormControl({
          value: this.selectedItem.mailoutTemplateCode,
          disabled: !this.isEditMode
        }),
        mailoutFieldSetCode: new FormControl({
          value: this.selectedItem.mailoutFieldSetCode,
          disabled: !this.isEditMode
        }),
        mailoutDatetime: new FormControl({
          value: this.selectedItem.mailoutDatetime,
          disabled: !this.isEditMode
        }),
        remoteTaskId: new FormControl({
          value: this.selectedItem.remoteTaskId,
          disabled: this.isEditMode
        }),
        mailoutHistory: new FormControl({
          value: this.selectedItem.mailoutHistory,
          disabled: this.isEditMode
        }),
        firstMailingDate: new FormControl({
          value: this.selectedItem.firstMailingDate,
          disabled: this.isEditMode
        }),
        lastMailingDate: new FormControl({
          value: this.selectedItem.lastMailingDate,
          disabled: this.isEditMode
        }),
        lastMailingStatus: new FormControl({
          value: this.selectedItem.lastMailingStatus,
          disabled: this.isEditMode
        }),
        timesMailed: new FormControl({
          value: this.selectedItem.timesMailed,
          disabled: this.isEditMode
        }),
        surveyId: new FormControl({
          value: this.selectedItem.surveyId,
          disabled: this.isEditMode
        }),
        surveyInviteeId: new FormControl({
          value: this.selectedItem.surveyInviteeId,
          disabled: false
        }, [])
      });
    this.formGroup.setValidators(maxCompaniesToRateValidator(this.formGroup.controls.maxP1CompaniesToRate, this.formGroup.controls.maxP2CompaniesToRate,
      this.formGroup.controls.maxP3CompaniesToRate, this.formGroup.controls.maxTotalCompaniesToRate));
    this.applyEditRules();
  }

  protected getFormControl(controlName: (keyof UniqueLinkInvitee)) {
    return this.getControl<UniqueLinkInvitee>(controlName);
  }

  protected getFormControlValue(controlName: keyof UniqueLinkInvitee) {
    return this.getControlValue<UniqueLinkInvitee>(controlName);
  }

  protected override saveOrUpdate() {
    if (this.isEditMode) {
      return this._uniqueLinkInviteesService.updateUniqueLinkInvitees(Util.mapFormGroupValuesToObject<UniqueLinkInvitee>(this.formGroup.getRawValue()));
    } else {
      return this._uniqueLinkInviteesService.saveUniqueLinkInvitees(Util.mapFormGroupValuesToObject<UniqueLinkInvitee>(this.formGroup.value));
    }
  }

  private initEditMode() {
    this.isEditMode = this.selectedItem.surveyCode !== undefined;
    if (this.isEditMode) {
      this.title = "Edit Row";
      this.loadDropdownsBySurveyCode(this.selectedItem.surveyCode);
    }
    this.initFormGroup();
    this.displayProgressBar(false);
    this.loading = false;
  }

  private togglePrefillSurveySelectionsControls(enable: boolean) {
    this.preFillSurveySelectionsDependentFields.forEach((controlName) => {
        this.toggleFormControl(this.getFormControl(controlName), enable, [this.formGroup.controls.surveyCode]);
      }
    );
  }

  private toggleRemoteTaskIdControls(enable: boolean) {
    this.remoteTaskIdDependentFields.forEach((controlName) => {
        this.toggleFormControl(this.getFormControl(controlName), enable, [this.formGroup.controls.surveyCode]);
      }
    );
  }

  private toggleAllBuCategoriesControls(enable: boolean) {
    this.allBuCategoriesDependentFields.forEach((controlName) => {
        this.toggleFormControl(this.getFormControl(controlName), enable, [this.formGroup.controls.surveyCode]);
      }
    );
  }

  private applyEditRules() {
    if (!this.isEditMode) {
      this.toggleAllControls(this.getSurveyCodeEditRule(), [this.formGroup.controls.surveyCode]);
    }
    this.togglePrefillSurveySelectionsControls(this.getSurveyCodeEditRule() && this.getPrefillEditRule());
    this.toggleRemoteTaskIdControls(this.getSurveyCodeEditRule() && !this.getRemoteTaskIdEditRule());
    this.toggleAllBuCategoriesControls(this.getSurveyCodeEditRule() && this.getPrefillEditRule() && !this.getAllBuCategoriesEditRule());
    this.refreshFormGroup();
  }
}
