<div class="full-height-container">
  <mat-card class="full-height-container">
    <mat-card-content class="content-panel full-height-container">
      <div class="flex-row">
        <app-component-header (refreshClickEvent)="this.refreshView()" [hideColumnsButton]="true" [startRefreshTimer]="this.refreshComplete"
                              [subtitleSegments]="getUrlSegments()" [hideUploadButton]="true"
                              class="full-width">
        </app-component-header>
      </div>
      <div class="flex-row table-container">
        <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                         (filterChanged)="onFilterChanged($event)"
                         (firstDataRendered)="onFirstDataRendered($event)"
                         (gridReady)="onGridReady($event)"
                         (viewportChanged)="onViewportChanged($event)"
                         [columnDefs]="tableColumns"
                         [gridOptions]="baseAgGridOptions"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         [rowData]="dataSource"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz full-width">
        </ag-grid-angular>

      </div>
    </mat-card-content>
  </mat-card>

</div>
<ag-side-panel #subsystemDetails size="medium" title="Subsystem details">
  <div class="flex-row mg-b-l">
    <ag-datablock [titleBold]="true" data="{{selectedSubsystem?.componentName}}" title="Subsystem name"></ag-datablock>
  </div>
  <div class="flex-row mg-b-l">
    <ag-datablock [titleBold]="true" data="{{selectedSubsystem?.status}}" title="Status"></ag-datablock>
  </div>
  <div class="flex-row mg-b-l">
    <ag-datablock [titleBold]="true" [isJson]="true" data="{{JSON.stringify(selectedSubsystem?.details)}}" title="Details"></ag-datablock>
  </div>
</ag-side-panel>
