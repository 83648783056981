<mat-form-field class="datetimepicker">
  <mat-label>{{label}}</mat-label>
  <input [formControl]="control" [ngxMatDatetimePicker]="pickerCustomIcon" matInput
         placeholder="Choose a date">
  <ngx-mat-datepicker-toggle [for]="pickerCustomIcon" matSuffix></ngx-mat-datepicker-toggle>
  <ngx-mat-datetime-picker #pickerCustomIcon [enableMeridian]="true" [showSpinners]="true"
                           [stepHour]="1" [stepMinute]="1">
    <ngx-mat-datepicker-actions>
      <button mat-button ngxMatDatepickerCancel>Cancel</button>
      <button color="primary" mat-raised-button ngxMatDatepickerApply>Apply</button>
    </ngx-mat-datepicker-actions>
  </ngx-mat-datetime-picker>
</mat-form-field>
