<div [showSkeleton]="this.loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <form [formGroup]="this.formGroup" class="full-height-container">
    <mat-card *ngIf="!this.loading" class="task-component-detail download-request-container backdrop" id="task-component-detail">
      <div class="header-row-info header-container-item flex-row">
        <mat-card-header class="header-title">
          <div>
            <mat-card-title class="primary-font-color">{{ this.title }}</mat-card-title>
            <mat-card-subtitle>Fill in the fields below to create new download request(s)</mat-card-subtitle>
          </div>
        </mat-card-header>
        <p hidden>There are currently 10 items in the queue</p>
      </div>
      <div class="header-row-buttons buttons-row flex-row container-item">
        <button (click)="this.onClearFiltersButtonClick()" class="secondary-button">
          <i class="fa-sharp fa-light fa-eraser"></i>
          <span>Clear Filters</span>
        </button>
      </div>
      <div class="drop-down-row container-item flex-row flex-row-item">
        <mat-form-field>
          <mat-label>Period</mat-label>
          <mat-select formControlName="period">
            <mat-option *ngFor="let period of this.periods"
                        [value]="period.period">
              {{ period.period }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Market</mat-label>
          <mat-select formControlName="market">
            <mat-option *ngFor="let market of this.markets"
                        [value]="market">
              {{ market.marketCode + ' - ' + market.marketName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Industry</mat-label>
          <mat-select formControlName="industry">
            <mat-option *ngFor="let industry of this.industries"
                        [value]="industry">
              {{ industry.industryCode + ' - ' + industry.industryName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Survey Subject</mat-label>
          <mat-select formControlName="surveySubject">
            <mat-option *ngFor="let surveySubject of this.surveySubjects"
                        [value]="surveySubject.surveySubject">
              {{ surveySubject.surveySubject }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Download Type</mat-label>
          <mat-select formControlName="downloadRequestType">
            <mat-option *ngFor="let downloadType of this.downloadRequestTypes"
                        [value]="downloadType">
              {{ downloadType.downloadRequestTypeDisplayValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Language</mat-label>
          <mat-select formControlName="locale">
            <mat-option *ngFor="let locale of this.locales"
                        [value]="locale.language">
              {{ locale.language + ' - ' + locale.languageName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="product-drop-down-row container-item flex-row flex-row-item">
        <ag-search-dropdown (itemsSelected)="this.onItemsSelected($event)" [dataSource]="this.products" [displayValuePropertyName]="'displayValue'" [showAllOption]=false
                            class="flex-grow" label="Product"></ag-search-dropdown>
      </div>
      <div class="select-row selection-product-and-sponsors-row container-item">
        <ag-checkbox-tree (selectionChanged)="this.onCheckBoxTreeSelectionChanged($event)" title="Product & Sponsors"></ag-checkbox-tree>
      </div>
      <div class="select-row selection-respondents-row container-item">
        <ag-checkbox-list (selectionChanged)="this.onCheckBoxListSelectionChanged($event)" title="Respondents"></ag-checkbox-list>
      </div>
      <div class="table-row table-container container-item">
        <ag-grid-angular
          (columnVisible)="this.onColumnVisible($event)"
          (gridReady)="this.onGridReady($event)"
          (modelUpdated)="this.onModelUpdated()"
          (selectionChanged)="this.onRowSelectionChanged($event)"
          [columnDefs]="this.tableColumns"
          [gridOptions]="this.gridOptions"
          [rowData]="this.rowData"
          [suppressPropertyNamesCheck]="true"
          class="ag-theme-quartz full-width record-display-table">
        </ag-grid-angular>
      </div>
      <div class="bottom-row info-row flex-row container-item">
        <p hidden>Estimated time to download: hh:mm</p>
      </div>

      <div class="bottom-row buttons-row flex-row container-item">
        <button (click)="this.requestClick()" [disabled]="!this.canRequestDownloads()" color="primary" mat-raised-button type="submit">
          <mat-icon class="material-symbols-outlined">save</mat-icon>
          Request & Close
        </button>
      </div>
    </mat-card>
  </form>
</div>
