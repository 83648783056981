import {Injectable} from "@angular/core";
import {ApiService} from "@core/api/api.service";
import {
  ViewSurvey,
  ViewSurveyBuCategories,
  ViewSurveyChannels,
  ViewSurveyIndustryFlags, ViewSurveyLocales,
  ViewSurveyLocalRegions,
  ViewSurveyRespondentCompanies,
  ViewSurveyRespondentDivisions,
  ViewSurveyRoles,
  ViewSurveySeniorities,
  ViewSurveySubjectRollups,
  ViewSurveySubjectSingles
} from "@data/interefaces/survey.spec.interfaces";
import {Util} from "@data/util/util";

@Injectable({
  providedIn: 'root'
})

export class SurveySpecService {
  constructor(private _apiService: ApiService) {
  }

  public getSurveys(): Promise<ViewSurvey[]> {
    return this._apiService.makeGetServiceCall<ViewSurvey[]>(`/v2/api/survey-spec/surveys`);
  }

  public getSurveyRespondentCompanies(surveyCode: string): Promise<ViewSurveyRespondentCompanies[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewSurveyRespondentCompanies[]>(`/v2/api/survey-spec/respondent-companies`, queryParam.join('&'));
  }

  public getSurveyRespondentDivisions(surveyCode: string): Promise<ViewSurveyRespondentDivisions[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewSurveyRespondentDivisions[]>(`/v2/api/survey-spec/respondent-divisions`, queryParam.join('&'));
  }

  public getSurveyLocales(surveyCode: string): Promise<ViewSurveyLocales[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewSurveyLocales[]>(`/v2/api/survey-spec/locales`, queryParam.join('&'));
  }

  public getSurveyBuCategories(surveyCode: string): Promise<ViewSurveyBuCategories[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewSurveyBuCategories[]>(`/v2/api/survey-spec/bu-categories`, queryParam.join('&'));
  }

  public getSurveyLocalRegions(surveyCode: string): Promise<ViewSurveyLocalRegions[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewSurveyLocalRegions[]>(`/v2/api/survey-spec/local-regions`, queryParam.join('&'));
  }

  public getSurveySeniorities(surveyCode: string): Promise<ViewSurveySeniorities[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewSurveySeniorities[]>(`/v2/api/survey-spec/seniorities`, queryParam.join('&'));
  }

  public getSurveyRoles(surveyCode: string): Promise<ViewSurveyRoles[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewSurveyRoles[]>(`/v2/api/survey-spec/roles`, queryParam.join('&'));
  }

  public getSurveyChannels(surveyCode: string): Promise<ViewSurveyChannels[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewSurveyChannels[]>(`/v2/api/survey-spec/channels`, queryParam.join('&'));
  }

  public getSurveyIndustryFlags(surveyCode: string): Promise<ViewSurveyIndustryFlags[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewSurveyIndustryFlags[]>(`/v2/api/survey-spec/industry-flags`, queryParam.join('&'));
  }

  public getSurveySubjectRollups(surveyCode: string): Promise<ViewSurveySubjectRollups[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewSurveySubjectRollups[]>(`/v2/api/survey-spec/subject-rollups`, queryParam.join('&'));
  }

  public getSurveySubjectSingles(surveyCode: string): Promise<ViewSurveySubjectSingles[]> {
    let queryParam = Util.pushQueryParam("surveyCode", surveyCode, []);
    return this._apiService.makeGetServiceCall<ViewSurveySubjectSingles[]>(`/v2/api/survey-spec/subject-singles`, queryParam.join('&'));
  }

}
