<div [showSkeleton]="loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <mat-card *ngIf="!loading" class="task-component-detail backdrop" id="event-type-detail">
    <mat-card-header class="mg-b-m">
      <div class="header-title">
        <mat-card-title class="primary-font-color">Event Types</mat-card-title>
        <mat-card-subtitle>Manage Event Type</mat-card-subtitle>
      </div>
    </mat-card-header>
    <mat-card-content>
      <form id="eventTypeForm" class="form-field" (ngSubmit)="this.save()" [formGroup]="formGroup">
        <div class="flex-row view-only-container mg-b-m">
          <mat-form-field>
            <mat-label>Event Type Code</mat-label>
            <input formControlName="eventTypeCode" matInput value="{{eventType?.eventTypeCode}}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Category</mat-label>
            <input formControlName="category" matInput value="{{eventType?.category}}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Event Type Name</mat-label>
            <input formControlName="eventTypeName" matInput value="{{eventType?.eventTypeName}}">
          </mat-form-field>
        </div>
        <div class="flex-row custom-container mg-b-m">
          <div class="category-container">
            <mat-form-field class="mg-b-m">
              <mat-label>Description</mat-label>
              <textarea formControlName="description" matInput rows="5"></textarea>
            </mat-form-field>
          </div>
          <div class="category-container">
            <mat-form-field class="mg-b-m">
              <mat-label>Parameters</mat-label>
              <textarea formControlName="parameters" matInput rows="5"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-row flex-g-s action-buttons mg-b-m">
          <button color="primary" mat-raised-button type="submit">
            <mat-icon class="material-symbols-outlined">save</mat-icon>
            Save
          </button>
          <button (click)="this.close()" mat-button type="button">
            <mat-icon class="material-symbols-outlined">close</mat-icon>
            Close
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
