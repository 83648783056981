export enum LegacyVerbatimFilesEnvironment {
  PROD = "https://a2console.advantagegroup.com/verbatim-files",
  DEV = "https://a2console-dev.advantagegroup.com/verbatim-files",
  QA = "https://a2console-qa.advantagegroup.com/verbatim-files",
  INTEGRATION = "https://a2console-itg.advantagegroup.com/verbatim-files",
  STAGING = "https://a2console-stg.advantagegroup.com/verbatim-files",
}

export enum LegacyVerbatimFileRowsEnvironment {
  PROD = "https://a2console.advantagegroup.com/verbatim-comments",
  DEV = "https://a2console-dev.advantagegroup.com/verbatim-comments",
  QA = "https://a2console-qa.advantagegroup.com/verbatim-comments",
  INTEGRATION = "https://a2console-itg.advantagegroup.com/verbatim-comments",
  STAGING = "https://a2console-stg.advantagegroup.com/verbatim-comments",
}
