import {Directive, inject} from "@angular/core";
import {BaseComponent} from "@pages/base.component";
import {ActivatedRoute} from "@angular/router";
import {NavigationService} from "@core/api/navigation.service";
import {FeatureComponentMode} from "@data/enums/data.enums";
import {Util} from "@data/util/util";
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {DataItem, OperationStatus} from "@data/interefaces/data.interfaces";
import {AgGridService} from "@shared/services/ag-grid.service";

@Directive({
    providers: [AgGridService]
})
export abstract class DetailBaseComponent extends BaseComponent {
  protected abstract pageContext: any;
  protected abstract formGroup: FormGroup;
  protected _activatedRoute: ActivatedRoute;
  protected _navigationService: NavigationService;
  protected featureComponentMode: FeatureComponentMode = FeatureComponentMode.DEFAULT;
  protected loading: boolean = true;
  protected isEditMode: boolean = false;
  protected title: string = 'Details Page';
  protected goBackUrl: string = '/';
  protected saveAndClose = true;
  protected readonly util = Util;

  protected constructor() {
    super();
    this._activatedRoute = inject(ActivatedRoute);
    this._navigationService = inject(NavigationService);

  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.initFeatureComponentMode();
    this.initGoBackUrl();
    this.updateToPageContextUrl();
    this.loadDropDownData();
    this.getContentDetails();
  }

  /**
   * Used to get the initial static dropdown data (if any).
   * @protected
   */
  protected abstract loadDropDownData(): void;

  /**
   * Used to get the initial objects details.  Example, making a request to find the object by the ID or parameters passed into this component.
   * @protected
   */
  protected abstract getContentDetails(): void;

  /**
   * Initializes the formGroup
   * @protected
   */
  protected abstract initFormGroup(): void;

  /**
   * Creates the initial save or update request to be used in the submit form method.
   * @protected
   */
  protected abstract saveOrUpdate(): Promise<OperationStatus> | Promise<DataItem>;

  /**
   * Sets the page's state to this pageContext.
   * @protected
   */
  protected override getPageState(): any {
    return this.pageContext;
  }

  protected close() {
    this._navigationService.back(this.goBackUrl, ["task"]);
  }

  protected resetForm() {
    this.initFormGroup();
  }

  protected hasAnyEdits() {
    if (this.isEditMode) {
      return this.getEditedFields().length > 0;
    } else {
      return this.formGroup.dirty;
    }
  }
  protected onSaveAndCloseClick() {
    this.saveAndClose = true;
  }

  protected getEditedFields() {
    return Object.keys(this.formGroup.controls).filter(control => !this.formGroup.get(control)?.disabled && this.formGroup.get(control)?.dirty)
      .map(editedFieldName => this.formGroup.get(editedFieldName)) as AbstractControl<any, any>[];
  }

  protected refreshFormGroup() {
    Object.keys(this.formGroup.controls).forEach(control => this.formGroup.get(control)?.updateValueAndValidity());
  }

  protected getControl<T>(controlName: (keyof T)) {
    return this.formGroup.get(controlName as string) as FormControl<any>;
  }

  protected getControlValue<T>(controlName: (keyof T)) {
    if (!this.getControl(controlName) || !this.getControl(controlName).value) {
      return "";
    }
    return this.getControl(controlName).value;
  }

  protected toggleAllControls(enable: boolean, alwaysEnabledControls: (FormControl<any> | undefined)[]) {
    Object.values(this.formGroup.controls).forEach((control) => {
      this.toggleFormControl(control as FormControl, enable, alwaysEnabledControls);
    });
  }

  protected toggleFormControl(formControl: FormControl, enable: boolean, alwaysEnabledControls: (FormControl<any> | undefined)[]) {
    return enable || alwaysEnabledControls.includes(formControl) ? formControl.enable() : formControl.disable();
  }

  protected submitForm(closeAfterSave: boolean, successMessage: string) {
    if (this.formGroup.valid) {
      this.displayProgressBar(true);
      this.saveOrUpdate().then(result => {
        if (result) {
          if (closeAfterSave) {
            this.close();
          }
          this.displayNotificationMessage('success', successMessage);
        }
      }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
      ).finally(() => this.displayProgressBar(false));
    }
  }

  private initFeatureComponentMode() {
    const urlSegments = this.getUrlSegments();
    // task URL should be /task/<mode>.  So find tasks, and add 1 to get the feature mode.
    let mode = urlSegments[this.getTaskIndexFromUrlSegments(urlSegments) + 1];
    this.featureComponentMode = <FeatureComponentMode>mode;
  }

  private initGoBackUrl() {
    this.goBackUrl += this.createGoBackUrl();
  }
}
