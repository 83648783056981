import {Component} from '@angular/core';
import {EventProcessor, EventType} from "@data/interefaces/event.type.interface";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EventTypeService} from "@data/event/event-type.service";
import {Util} from "@data/util/util";
import {ApplicationStatus, EventProcessorStatus} from "@data/enums/data.enums";
import {DetailBaseComponent} from "@pages/detail.base.component";
import {DataItem, OperationStatus} from "@data/interefaces/data.interfaces";

@Component({
  selector: 'app-event-processor-detail',
  templateUrl: './event-processor-detail.component.html',
  styleUrls: ['./event-processor-detail.component.scss']
})
export class EventProcessorDetailComponent extends DetailBaseComponent {
  override pageContext: {
    [key: string]: boolean | string | undefined;
    pc_f: string | undefined;
  } = {
    pc_f: undefined,
  };
  eventProcessorCode: string | null | undefined;
  protected eventProcessor: EventProcessor | undefined;
  formGroup: FormGroup<{ [K in keyof EventType]?: FormControl<any> }> = new FormGroup<any>([]);

  constructor(private _eventTypeService: EventTypeService) {
    super();
  }

  override getContentDetails() {
    this.loading = true;
    this.eventProcessorCode = this.pageContext.pc_f;
    this.displayProgressBar(true);
    this._eventTypeService.getEventProcessorsByProcessorCode(this.eventProcessorCode!)
      .then(result => {
        this.eventProcessor = result;
        this.initFormGroup();
      })
      .catch(error => {
          this.displayNotificationMessage('error', JSON.stringify(error));
        }
      ).finally(() => {
      this.displayProgressBar(false);
      this.loading = false;
    });
  }

  displayStartButton(): boolean {
    return this.eventProcessor?.applicationStatus === ApplicationStatus.ONLINE
      && (this.eventProcessor?.processorStatus === EventProcessorStatus.STOPPED || this.eventProcessor?.processorStatus === EventProcessorStatus.PAUSED);
  }

  displayStopButton(): boolean {
    return this.eventProcessor?.applicationStatus === ApplicationStatus.ONLINE
      && (this.eventProcessor?.processorStatus === EventProcessorStatus.STARTED || this.eventProcessor?.processorStatus === EventProcessorStatus.IDLE
        || this.eventProcessor?.processorStatus === EventProcessorStatus.BUSY);
  }

  start() {
    this.displayProgressBar(true);
    this._eventTypeService.startEventProcessor(this.eventProcessorCode!)
      .then(result => {
        if (this.eventProcessor) {
          this.eventProcessor.processorStatus = EventProcessorStatus.STARTING;
        }
      })
      .catch(error => this.displayNotificationMessage('error', JSON.stringify(error)))
      .finally(() => this.displayProgressBar(false));
  }

  stop() {
    this.displayProgressBar(true);
    this._eventTypeService.stopEventProcessor(this.eventProcessorCode!)
      .then(result => {
        if (this.eventProcessor) {
          this.eventProcessor.processorStatus = EventProcessorStatus.STOPPING;
        }
      })
      .catch(error => this.displayNotificationMessage('error', JSON.stringify(error)))
      .finally(() => this.displayProgressBar(false));
  }

  save() {
    this.displayProgressBar(true);
    this._eventTypeService.saveEventProcessor(Util.mapFormGroupValuesToObject<EventProcessor>(this.formGroup.getRawValue()))
      .then(result => this.close())
      .catch(error => this.displayNotificationMessage('error', JSON.stringify(error)))
      .finally(() => this.displayProgressBar(false));
  }

  override close() {
    this._navigationService.back(this.goBackUrl, ["task"]);
  }

  override initFormGroup() {
    this.formGroup = new FormGroup<{ [K in keyof EventProcessor]?: FormControl<any> }>(
      {
        eventType: new FormControl({
          value: this.eventProcessor?.eventType,
          disabled: true
        }),
        eventProcessorCode: new FormControl({
          value: this.eventProcessor?.eventProcessorCode,
          disabled: true
        }),
        processorStatus: new FormControl({
          value: this.eventProcessor?.processorStatus,
          disabled: true
        }),
        applicationStatus: new FormControl({
          value: this.eventProcessor?.applicationStatus,
          disabled: true
        }),
        eventsInQueue: new FormControl({
          value: this.eventProcessor?.eventsInQueue,
          disabled: true
        }),
        queueSize: new FormControl({
          value: this.eventProcessor?.queueSize,
          disabled: true
        }),
        eventsInProgress: new FormControl({
          value: this.eventProcessor?.eventsInProgress,
          disabled: true
        }),
        threadPoolSize: new FormControl({
          value: this.eventProcessor?.threadPoolSize,
          disabled: true
        }),
        eventProcessorName: new FormControl({
          value: this.eventProcessor?.eventProcessorName,
          disabled: false,
        }, Validators.required),
        description: new FormControl({
          value: this.eventProcessor?.description,
          disabled: false
        }),
        parameters: new FormControl({
          value: this.eventProcessor?.parameters,
          disabled: false
        }),
      },
    );
  }

  protected override loadDropDownData(): void {
  }

  protected override saveOrUpdate(): Promise<OperationStatus> | Promise<DataItem> {
    throw new Error('Method not implemented.');
  }
}
