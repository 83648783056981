<div class="context-link flex-row mg-r-m mg-l-m mg-t-m mg-b-m">
  <a (click)="goBack()" *ngIf="enableBackLink" color="primary" mat-button>
    <i class="fa-sharp fa-regular fa-arrow-left fa-lg mg-r-s"></i>
    Back
  </a>
  <div class="flex-row right-align">
    <ng-container *ngFor="let link of auxLinks | keyvalue; let last = last">
      <a color="primary" mat-button routerLink="{{link.key}}">{{link.value}}</a>
      <mat-divider *ngIf="!last" vertical="true"></mat-divider>
    </ng-container>
  </div>
</div>
