import {Component} from '@angular/core';
import {OpenLinkInvitee, ViewMailoutFieldsets, ViewMailoutTemplates} from "src/app/data/interefaces/panel.interfaces";
import {
  ViewSurvey,
  ViewSurveyBuCategories,
  ViewSurveyChannels,
  ViewSurveyIndustryFlags,
  ViewSurveyLocales,
  ViewSurveyLocalRegions,
  ViewSurveyRespondentCompanies,
  ViewSurveyRespondentDivisions,
  ViewSurveyRoles,
  ViewSurveySeniorities,
  ViewSurveySubjectRollups,
  ViewSurveySubjectSingles
} from "src/app/data/interefaces/survey.spec.interfaces";
import {YearsInRole} from "src/app/data/interefaces/lookup.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Util} from "src/app/data/util/util";
import {SurveySpecService} from "src/app/data/survey-spec/survey.spec.service";
import {LookupService} from "src/app/data/lookup/lookup.service";
import {PanelService} from "src/app/data/panel/panel.service";
import {OpenLinkInviteesService} from "src/app/data/panel/open.link.invitees.service";
import {ResponseStatus, SurveySubject} from "@data/enums/data.enums";
import {mailoutValidator} from "@pages/panel/open-link-invitees/open-link-invitees.component.validator";
import {DetailBaseComponent} from "@pages/detail.base.component";

@Component({
  selector: 'open-link-invitees-detail',
  templateUrl: './open-link-invitees-detail.component.html',
  styleUrls: ['./open-link-invitees-detail.component.scss']
})
export class OpenLinkInviteesDetailComponent extends DetailBaseComponent {

  selectedItem: OpenLinkInvitee = {} as OpenLinkInvitee;
  surveyCodeList: ViewSurvey[] | undefined;
  invitationCompanyCodeList: ViewSurveyRespondentCompanies[] | undefined;
  invitationDivisionCodesList: ViewSurveyRespondentDivisions[] | undefined;
  buCategoriesList: ViewSurveyBuCategories[] | undefined;
  localRegionsList: ViewSurveyLocalRegions[] | undefined;
  senioritiesList: ViewSurveySeniorities[] | undefined;
  rolesList: ViewSurveyRoles[] | undefined;
  channelsList: ViewSurveyChannels[] | undefined;
  industryFlagsList: ViewSurveyIndustryFlags[] | undefined;
  subjectRollupsList: ViewSurveySubjectRollups[] | undefined;
  subjectSinglesList: ViewSurveySubjectSingles[] | undefined;
  surveyLanguageList: ViewSurveyLocales[] | undefined;
  yearsInRoleList: YearsInRole[] | undefined;
  mailoutTemplateCodeList: ViewMailoutTemplates[] | undefined;
  mailoutFieldsetCodeList: ViewMailoutFieldsets[] | undefined;
  override formGroup: FormGroup<{ [K in keyof OpenLinkInvitee]?: FormControl<any> }> = new FormGroup<any>([]);
  override pageContext: {
    [key: string]: boolean | string | undefined;
    sii_f: string | undefined;
  } = {
    sii_f: undefined
  };
  protected readonly ResponseStatus = ResponseStatus;
  protected readonly SurveySubject = SurveySubject;

  constructor(private _surveySpecService: SurveySpecService, private _lookupService: LookupService,
              private _panelService: PanelService, private _openLinkInviteesService: OpenLinkInviteesService) {
    super();
  }

  get inviteeUrl() {
    return this.getFormControl('inviteeUrl');
  }

  override ngOnInit(): void {
    this.displayProgressBar(true);
    super.ngOnInit();
  }

  initAddMode() {
    this.title = "Add Row";
    this.initFormGroup();
    this.displayProgressBar(false);
    this.loading = false;
  }

  loadDropdownsBySurveyCode(surveyCode: string) {
    this._surveySpecService.getSurveyRespondentCompanies(surveyCode).then(result => {
      this.invitationCompanyCodeList = result;
    });
    this._surveySpecService.getSurveyRespondentDivisions(surveyCode).then(result => {
      this.invitationDivisionCodesList = result;
    });
    this._surveySpecService.getSurveyLocales(surveyCode).then(result => {
      this.surveyLanguageList = result;
    });
    this._surveySpecService.getSurveyBuCategories(surveyCode).then(result => {
      this.buCategoriesList = result;
    });
    this._surveySpecService.getSurveyLocalRegions(surveyCode).then(result => {
      this.localRegionsList = result;
    });
    this._surveySpecService.getSurveySeniorities(surveyCode).then(result => {
      this.senioritiesList = result;
    });
    this._surveySpecService.getSurveyRoles(surveyCode).then(result => {
      this.rolesList = result;
    });
    this._surveySpecService.getSurveyChannels(surveyCode).then(result => {
      this.channelsList = result;
    });
    this._surveySpecService.getSurveyIndustryFlags(surveyCode).then(result => {
      this.industryFlagsList = result;
    });
    this._surveySpecService.getSurveySubjectRollups(surveyCode).then(result => {
      this.subjectRollupsList = result;
    });
    this._surveySpecService.getSurveySubjectSingles(surveyCode).then(result => {
      this.subjectSinglesList = result.filter(s => s != null);
    });
    this._surveySpecService.getSurveySeniorities(surveyCode).then(result => {
      this.senioritiesList = result;
    });
    this._panelService.getMailoutTemplates(surveyCode).then(result => {
      this.mailoutTemplateCodeList = result;
    });
    this._panelService.getMailoutFieldsets(surveyCode).then(result => {
      this.mailoutFieldsetCodeList = result;
    });
  }

  onSurveyCodeSelected() {
    if (this.getSurveyCodeEditRule()) {
      this.loadDropdownsBySurveyCode(this.formGroup.controls.surveyCode?.value);
    }
    this.applyEditRules();
  }

  isResponseStatusSelected(responseStatusEnum: ResponseStatus) {
    return this.formGroup.controls.responseStatus?.value == responseStatusEnum.toString();
  }

  isSurveySubjectSelected(surveySubjectEnum: SurveySubject) {
    return this.formGroup.controls.surveySubject?.value == surveySubjectEnum.toString();
  }

  getFormControl(controlName: (keyof OpenLinkInvitee)) {
    return this.getControl<OpenLinkInvitee>(controlName);
  }

  getFormControlValue(controlName: (keyof OpenLinkInvitee)) {
    return this.getControlValue<OpenLinkInvitee>(controlName);
  }

  protected override loadDropDownData() {
    this._surveySpecService.getSurveys().then(result => {
      this.surveyCodeList = result;
    });
    this._lookupService.getYearsInRole().then(result => {
      this.yearsInRoleList = result;
    });
  }

  protected override getContentDetails() {
    if (this.pageContext.sii_f) {
      this._openLinkInviteesService.getOpenLinkInviteesById(this.pageContext.sii_f!).then(result => {
        this.selectedItem = result;
        this.initEditMode();
      });
    } else {
      this.initAddMode();
    }
  }

  protected getSurveyCodeEditRule() {
    return this.formGroup.controls.surveyCode?.value != undefined && this.formGroup.controls.surveyCode.value != '';
  }

  protected override saveOrUpdate() {
    if (this.isEditMode) {
      return this._openLinkInviteesService.updateOpenLinkInvitees(Util.mapFormGroupValuesToObject<OpenLinkInvitee>(this.formGroup.getRawValue()));
    } else {
      return this._openLinkInviteesService.saveOpenLinkInvitees(Util.mapFormGroupValuesToObject<OpenLinkInvitee>(this.formGroup.value));
    }
  }

  protected override initFormGroup() {
    this.formGroup = new FormGroup<{ [K in keyof OpenLinkInvitee]?: FormControl<any> }>(
      {
        period: new FormControl({
          value: this.selectedItem.period,
          disabled: true
        }),
        marketCode: new FormControl({
          value: this.selectedItem.marketCode,
          disabled: true
        }),
        marketName: new FormControl({
          value: this.selectedItem.marketName,
          disabled: true
        }),
        industryCode: new FormControl({
          value: this.selectedItem.industryCode,
          disabled: true
        }),
        industryName: new FormControl({
          value: this.selectedItem.industryName,
          disabled: true
        }),
        surveySubject: new FormControl({
          value: this.selectedItem.surveySubject,
          disabled: true
        }),
        surveyCode: new FormControl({
          value: this.selectedItem.surveyCode,
          disabled: this.isEditMode
        }),
        openLinkCode: new FormControl({
          value: this.selectedItem.openLinkCode,
          disabled: false
        }),
        remoteRespId: new FormControl({
          value: this.selectedItem.remoteRespId,
          disabled: this.isEditMode
        }),
        responseCode: new FormControl({
          value: this.isEditMode ? this.selectedItem.responseCode : -1,
          disabled: this.isEditMode
        }),
        personCode: new FormControl({
          value: this.selectedItem.personCode,
          disabled: this.isEditMode
        }),
        responseStatus: new FormControl({
          value: this.selectedItem.responseStatus,
          disabled: this.isEditMode
        }),
        invalid: new FormControl(
          this.selectedItem.invalid
        ),
        invitationCompanyCode: new FormControl({
          value: this.selectedItem.invitationCompanyCode,
          disabled: !this.isEditMode
        }, Validators.required),
        invitationDivisionCodes: new FormControl({
          value: Util.convertDelimitedStringToArray(this.selectedItem.invitationDivisionCodes),
          disabled: !this.isEditMode
        }),
        firstName: new FormControl({
          value: this.selectedItem.firstName,
          disabled: !this.isEditMode
        }, Validators.required),
        lastName: new FormControl({
          value: this.selectedItem.lastName,
          disabled: !this.isEditMode
        }, Validators.required),
        email: new FormControl({
          value: this.selectedItem.email,
          disabled: !this.isEditMode
        }, Validators.required),
        telephone: new FormControl({
          value: this.selectedItem.telephone,
          disabled: !this.isEditMode
        }),
        cellPhone: new FormControl({
          value: this.selectedItem.cellPhone,
          disabled: !this.isEditMode
        }),
        city: new FormControl({
          value: this.selectedItem.city,
          disabled: !this.isEditMode
        }),
        jobTitle: new FormControl({
          value: this.selectedItem.jobTitle,
          disabled: !this.isEditMode
        }),
        surveyLanguage: new FormControl({
          value: this.selectedItem.surveyLanguage,
          disabled: !this.isEditMode
        }, Validators.required),
        inviteeUrl: new FormControl({
          value: this.selectedItem.inviteeUrl,
          disabled: !this.isEditMode
        }),
        nominatedByCodes: new FormControl({
          value: Util.convertDelimitedStringToArray(this.selectedItem.nominatedByCodes),
          disabled: !this.isEditMode
        }),
        nominationNotes: new FormControl({
          value: Util.convertDelimitedStringToArray(this.selectedItem.nominationNotes),
          disabled: !this.isEditMode
        }),

        mailoutTemplateCode: new FormControl({
          value: this.selectedItem.mailoutTemplateCode,
          disabled: !this.isEditMode
        }),
        mailoutFieldSetCode: new FormControl({
          value: this.selectedItem.mailoutFieldSetCode,
          disabled: !this.isEditMode
        }),
        mailoutDatetime: new FormControl({
          value: this.selectedItem.mailoutDatetime,
          disabled: !this.isEditMode
        }),
        remoteTaskId: new FormControl({
          value: this.selectedItem.remoteTaskId,
          disabled: this.isEditMode
        }),
        mailoutHistory: new FormControl({
          value: this.selectedItem.mailoutHistory,
          disabled: this.isEditMode
        }),
        firstMailingDate: new FormControl({
          value: this.selectedItem.firstMailingDate,
          disabled: this.isEditMode
        }),
        lastMailingDate: new FormControl({
          value: this.selectedItem.lastMailingDate,
          disabled: this.isEditMode
        }),
        lastMailingStatus: new FormControl({
          value: this.selectedItem.lastMailingStatus,
          disabled: this.isEditMode
        }),
        timesMailed: new FormControl({
          value: this.selectedItem.timesMailed,
          disabled: this.isEditMode
        }),
        surveyId: new FormControl({
          value: this.selectedItem.surveyId,
          disabled: this.isEditMode
        }),
        surveyInviteeId: new FormControl({
          value: this.selectedItem.surveyInviteeId,
          disabled: this.isEditMode
        })
      });
    this.formGroup.setValidators(mailoutValidator(this.formGroup.controls.mailoutTemplateCode,
      this.formGroup.controls.mailoutDatetime,
      this.formGroup.controls.mailoutFieldSetCode));
    this.applyEditRules();
  }

  private initEditMode() {
    this.isEditMode = this.selectedItem.surveyCode !== undefined;
    if (this.isEditMode) {
      this.title = "Edit Row";
      this.loadDropdownsBySurveyCode(this.selectedItem.surveyCode);
    }
    this.initFormGroup();
    this.displayProgressBar(false);
    this.displayProgressBar(false);
    this.loading = false;
  }

  private applyEditRules() {
    if (!this.isEditMode) {
      this.toggleAllControls(this.getSurveyCodeEditRule(), [this.formGroup.controls.surveyCode]);
    }
    this.refreshFormGroup();
  }
}

