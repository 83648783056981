<div [showSkeleton]="loading" [skeletonType]="'grid-1x4'" agSkeleton class="full-height-container">
  <mat-card *ngIf="!loading" class="task-component-detail backdrop" id="task-component-detail">

    <mat-card-header class="mg-b-m">
      <div class="header-title">
        <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
        <mat-card-subtitle>
            <div class="flex-row view-only-container mg-b-m">
                <div class="view-only-block">
                    <ag-datablock data="{{getFormControlValue('fileName')}}" title="File Name"></ag-datablock>
                </div>
                <div class="view-only-block">
                    <ag-datablock data="{{getFormControlValue('surveyCode')}}" title="Survey Code"></ag-datablock>
                </div>
                <div class="view-only-block">
                    <ag-datablock data="{{getFormControlValue('loadStatus')}}" title="Load Status"></ag-datablock>
                </div>
                <div class="view-only-block">
                    <ag-datablock data="{{getUploadDetails()}}" title="Upload Details"></ag-datablock>
                </div>
            </div>
        </mat-card-subtitle>
      </div>
    </mat-card-header>

      <mat-card-content class="full-height-container">
          <ag-expansion-panel style="position: relative" (expanded)="changeState($event)" [isOpen]="expanded" [supportReset]="false" headerIcon="fa-square-info"
                              title="Additional Details">
              <div expansion-content>
                  <div class="flex-row flex-g-l mg-b-s full-width">
                      <div class="view-only-block">
                          <ag-datablock data="{{getFormControlValue('userName')}}" title="User Name"></ag-datablock>
                      </div>
                      <div class="view-only-block">
                          <ag-datablock data="{{getFormControlValue('description')}}" title="Description"></ag-datablock>
                      </div>
                      <!-- Hide until AFR-10705 Ascribe Verbatims: store uploaded files to SharePoint for reuse and audit purposes is implemented-->
                      <!-- <div class="view-only-block">-->
                      <!--   <ag-datablock data="{{getFormControlValue('sharePointUrl')}}" title="Sharepoint URL"></ag-datablock>-->
                      <!-- </div>-->
                  </div>
                  <div class="flex-row flex-g-l mg-b-s full-width">
                      <div class="view-only-block">
                          <ag-datablock [isDate]="true" data="{{getFormControlValue('startTimestamp')}}" title="Start"></ag-datablock>
                      </div>
                      <div class="view-only-block">
                          <ag-datablock [isDate]="true" data="{{getFormControlValue('endTimestamp')}}" title="End"></ag-datablock>
                      </div>
                      <div class="view-only-block">
                          <ag-datablock data="{{getFormControlValue('recordsInserted')}}" title="Records Inserted"></ag-datablock>
                      </div>
                      <div class="view-only-block">
                          <ag-datablock data="{{getFormControlValue('recordsUpdated')}}" title="Records Updated"></ag-datablock>
                      </div>
                      <div class="view-only-block">
                          <ag-datablock data="{{getFormControlValue('recordsError')}}" title="Records with Errors"></ag-datablock>
                      </div>
                  </div>
                  <div class="flex-row flex-g-l mg-b-s full-width">
                      <div class="view-only-block">
                          <ag-datablock [isDate]="true" data="{{getFormControlValue('id')}}" title="ID"></ag-datablock>
                      </div>
                  </div>
              </div>
          </ag-expansion-panel>
          <app-verbatim-file-detail-rows [ngClass]="expanded ? 'expanded' : 'collapsed'" [verbatimFileId]="pageContext.vfi_f"/>
      </mat-card-content>
  </mat-card>

</div>
