import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType, EventTypeStatus, EventTypeStatusLevel} from "@data/enums/data.enums";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {ResetFilterComponent} from "@pages/segments/grid/filters/reset-filter/reset-filter.component";

export let pageContext: {
  [key: string]: boolean | string | undefined
  etc_f: string | undefined;
  c_f: string | undefined;
  n_f: string | undefined;
  d_f: string | undefined;
  iq_f: string | undefined;
  ip_f: string | undefined;
  pa_f: string | undefined;
  pna_f: string | undefined;
  tp_s: string | undefined;
  s_f: string | undefined;
  p_f: string | undefined;
} = {
  etc_f: undefined,
  c_f: undefined,
  n_f: undefined,
  d_f: undefined,
  iq_f: undefined,
  ip_f: undefined,
  pa_f: undefined,
  pna_f: undefined,
  tp_s: undefined,
  s_f: undefined,
  p_f: undefined,
}

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    field: 'status',
    headerName: 'Alert',
    maxWidth: 75,
    filter: false,
    pinned: "left",
    cellRenderer: (params: any) => {
      if (params.value.level === 'Offline' && params.value.status === 'Busy') {
        return `<span><i class="fa-sharp fa-circle-exclamation error-color"></i></span>`
      }
      if (params.value.level === 'Warning' && params.value.status === 'Busy') {
        return `<span><i class="fa-sharp fa-triangle-exclamation warning-color"></i></span>`;
      }
      return '';
    },
    valueFormatter: params => {
      return `${params.value.level} - ${params.value.status}`;
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    maxWidth: 80,
    filter: false,
    cellRenderer: (params: any) => {
      const {level, status} = params.value;
      const tooltip = `${level} ${status}`;

      if (params.value.level === EventTypeStatusLevel.OK && params.value.status === EventTypeStatus.IDLE) {
        return `<span title="${tooltip}"><i class="fa-sharp fa-signal-bars green-color"></i></span>`;
      }
      if (params.value.level === EventTypeStatusLevel.OK && params.value.status === EventTypeStatus.WORKING) {
        return `<span title="${tooltip}"><i class="fa-sharp fa-signal-bars light-green-color"></i></span>`;
      }
      if (params.value.level === EventTypeStatusLevel.OK && params.value.status === EventTypeStatus.BUSY) {
        return `<span title="${tooltip}"><i class="fa-sharp fa-signal-bars yellow-color"></i></span>`;
      }
      if (params.value.level === EventTypeStatusLevel.WARNING && params.value.status === EventTypeStatus.STANDBY) {
        return `<span title="${tooltip}"><i class="fa-sharp fa-signal-bars-fair green-color"></i></span>`;
      }
      if (params.value.level === EventTypeStatusLevel.WARNING && params.value.status === EventTypeStatus.WORKING) {
        return `<span title="${tooltip}"><i class="fa-sharp fa-signal-bars-fair light-green-color"></i></span>`;
      }
      if (params.value.level === EventTypeStatusLevel.WARNING && params.value.status === EventTypeStatus.BUSY) {
        return `<span title="${tooltip}"><i class="fa-sharp fa-signal-bars-fair yellow-color"></i></span>`;
      }
      if (params.value.level === EventTypeStatusLevel.OFFLINE && params.value.status === EventTypeStatus.STANDBY) {
        return `<span title="${tooltip}"><i class="fa-sharp fa-signal-bars-slash green-color"></i></span>`;
      }
      if (params.value.level === EventTypeStatusLevel.OFFLINE && params.value.status === EventTypeStatus.BUSY) {
        return `<span title="${tooltip}"><i class="fa-sharp fa-signal-bars-slash error-color"></i></span>`;
      }
      return '';
    },
    valueFormatter: params => {
      return `${params.value.level} - ${params.value.status}`;
    }
  },
  {
    field: 'eventTypeCode',
    headerName: 'Event Type Code',
    filterId: 'etc_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'category',
    headerName: 'Category',
    filterId: 'c_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'eventTypeName',
    headerName: 'Name',
    minWidth: 200,
  },
  {
    field: 'eventsInQueue',
    headerName: 'In Queue',
  },
  {
    field: 'eventsInProgress',
    headerName: 'In Progress',
  },
  {
    field: 'totalProcessors',
    headerName: 'Total Processors',
  },
  {
    field: 'processorsAvailable',
    headerName: 'Active Processors',
  },
  {
    field: 'processorsUnavailable',
    headerName: 'InActive Processors',
  },
  {
    field: 'Parameters',
    headerName: 'Parameters',
    maxWidth: 250,
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.parameters,
        type: 'text',
      };
    }
  },
  {
    field: 'description',
    headerName: 'Description',
    maxWidth: 200,
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.description,
        type: 'text',
      };
    }
  },
  {
    field: "actions",
    headerName: "Actions",
    pinned: "right",
    suppressFiltersToolPanel: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: ResetFilterComponent,
    suppressFloatingFilterButton: true,
    suppressHeaderFilterButton: true,
    cellRenderer: ActionColumnComponent,
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRendererParams: function (params: any) {
      return {
        actions: [
          {
            toolTip: "Edit",
            icon: "fa-pen-to-square",
            name: "Edit",
            routerLink: ['task', 'edit'],
            queryParams: {etc_f: params.data.eventTypeCode}
          },
          {
            toolTip: "Processors",
            icon: "fa-file",
            name: "Processors",
            routerLink: ['/system', 'events', 'event-processors'],
            queryParams: {etc_f: params.data.eventTypeCode}
          },
          {
            toolTip: "Event Logs",
            icon: "fa-rectangle-vertical-history",
            name: "Event Logs",
            routerLink: ['/system', 'events', 'event-logs'],
            queryParams: {etc_f: params.data.eventTypeCode}
          },
        ]
      };
    }
  }
];


