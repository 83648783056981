import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FlatTreeControl} from "@angular/cdk/tree";
import {CheckboxNode, DataSourceChangedEvent, ParentCheckboxNode, TreeSelectionChanged, TreeSelectionEvent} from "@shared/ag-checkbox-tree/ag-checkbox-tree.component.ds";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {SelectionChange, SelectionModel} from "@angular/cdk/collections";
import {AgCheckboxTreeService} from "@shared/ag-checkbox-tree/services/ag-checkbox-tree.service";
import {FormControl} from "@angular/forms";
import {map, Observable, startWith, Subscription} from "rxjs";
import {SelectionChangeType} from "@data/enums/data.enums";

@Component({
  selector: 'ag-checkbox-tree',
  templateUrl: './ag-checkbox-tree.component.html',
  styleUrls: ['./ag-checkbox-tree.component.scss']
})
export class AgCheckboxTreeComponent implements OnInit {
  @ViewChild('treeContainer', {static: false}) treeContainer!: ElementRef;
  @Input() title: string = 'Default'
  @Input() disabledTooltipText: string = 'No data available'
  @Input() searchBarLabel: string = 'Search for Items';
  @Output() selectionChanged = new EventEmitter<TreeSelectionChanged>();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  treeControl: FlatTreeControl<ParentCheckboxNode>;
  treeFlattener: MatTreeFlattener<CheckboxNode, ParentCheckboxNode>;
  dataSource: MatTreeFlatDataSource<CheckboxNode, ParentCheckboxNode>;
  // Map of the ParentCheckboxNode to the Checkbox Nodes.  This helps in find the nested nodes.
  flatNodeMap = new Map<ParentCheckboxNode, CheckboxNode>();
  // Map from Checkbox node to ParentCheckboxNode. This helps keep the same object for the selection.
  nestedNodeMap = new Map<CheckboxNode, ParentCheckboxNode>();
  // The selected node check list
  checklistSelection = new SelectionModel<ParentCheckboxNode>(true);
  searchFormControl: FormControl<any> = new FormControl<any>('');
  // the list of checkbox nodes to display in the auto-complete dropdown
  filteredCheckboxNodes$: Observable<CheckboxNode[]>;
  disableCheckListSelectionChanged: boolean = false;
  checkboxTreeServiceDataChange!: Subscription;
  checkboxTreeServiceSetSelection!: Subscription;
  checkListSelectionChange!: Subscription;

  constructor(private _checkboxTreeService: AgCheckboxTreeService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ParentCheckboxNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.initSubscriptions();
    this.filteredCheckboxNodes$ = this.searchFormControl.valueChanges.pipe(startWith(this.searchFormControl.value || ''), map(value => this.filterSearchOptions(value || '')));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.checkboxTreeServiceDataChange.unsubscribe();
    this.checkboxTreeServiceSetSelection.unsubscribe();
    this.checkListSelectionChange.unsubscribe();
  }

  getLevel = (node: ParentCheckboxNode) => node.level;

  isExpandable = (node: ParentCheckboxNode) => node.expandable;

  getChildren = (node: CheckboxNode): CheckboxNode[] => node.children;

  hasChild = (_: number, _nodeData: ParentCheckboxNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: ParentCheckboxNode) => _nodeData.item === '';
  hasChildOrLevelZero = (_: number, _nodeData: ParentCheckboxNode) => this.hasChild(_, _nodeData) || this.getLevel(_nodeData) === 0;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   * @param node
   * @param level
   */
  transformer = (node: CheckboxNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new ParentCheckboxNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    flatNode.id = node.id;
    flatNode.tag = node.tag;
    // for future dev, when new data that is coming in is the same as existing data, do we want to ignore it, or add it?  That
    // logic may have to be done here to help with some of the checkbox filtering data.
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /**
   * Toggle a parent item selection. Check all the parents to see if they changed
   * @param node
   */
  childItemSelectionToggle(node: ParentCheckboxNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /**
   * Check root node checked state and change it accordingly
   * @param node
   */
  checkRootNodeSelection(node: ParentCheckboxNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /**
   * Checks all the parents when a leaf node is selected/unselected
   * @param node
   */
  checkAllParentsSelection(node: ParentCheckboxNode): void {
    let parent: ParentCheckboxNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /**
   * Checks if all the descendants nodes are selected.
   * @param node
   */
  descendantsAllSelected(node: ParentCheckboxNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /**
   * Determines if any of the descendant nodes are partially selected
   * @param node
   */
  descendantsPartiallySelected(node: ParentCheckboxNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /**
   * Toggle the parent item selection. Select/deselect all the descendants node
   * @param node
   */
  parentItemSelectionToggle(node: ParentCheckboxNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  autoCompleteDisplayValue(childItem: CheckboxNode | string): string {
    // If the selected value is an object, display the 'item' property
    if (typeof childItem === 'string') {
      return childItem;
    }
    return childItem ? childItem.item : '';
  }

  /**
   * Get the parent node of a node
   * @param node
   */
  getParentNode(node: ParentCheckboxNode): ParentCheckboxNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  getParentNodeById(id: string) {
    let matchingNode: ParentCheckboxNode | undefined = undefined;
    for (const node of this.flatNodeMap.keys()) {
      if (node.id === id) {
        matchingNode = node;
        break;
      }
    }
    if (matchingNode === undefined) {
      console.warn(`getParentNodeById - Could not find a checkbox node matching id: ${id}`)
      return null;
    }
    return matchingNode;
  }

  clearValue(event: Event) {
    this.searchFormControl.setValue('');
  }

  hasValue() {
    return !!this.searchFormControl.getRawValue()
  }

  isChildNodeSelected(childItem: CheckboxNode) {
    const parentNode = this.getParentNodeById(childItem.id);
    if (parentNode) {
      return this.checklistSelection.hasValue() && this.checklistSelection.isSelected(parentNode);
    }
    return false;
  }

  handleOnClickChange(selectedCheckbox: CheckboxNode) {
    this.selectChildItemById(selectedCheckbox.id);
    this.expandAndScrollToNode(selectedCheckbox.parentId, selectedCheckbox.id);
  }

  expandAndScrollToNode(parentNodeId: string | undefined, nodeId: string) {
    if (parentNodeId) {
      const parentNode = this.getParentNodeById(parentNodeId);
      if (parentNode) {
        this.treeControl.expand(parentNode);
        this.scrollToNode(nodeId);
      }
    }
  }

  onAutoCompleteSelectionChanged(selectedCheckbox: CheckboxNode) {
    this.handleOnClickChange(selectedCheckbox);
  }

  onAutoCompleteCheckboxClicked(event: MouseEvent) {
    // When there is no text in the matInput then this method is called.
    // This means that we need to stopPropagation here in order for the dropdown window stays open
    event.stopPropagation();
  }

  onMatOptionGroupClicked(event: MouseEvent) {
    // This method is required for when we are filtering the data via typing text into the matInput.
    // When there is text in the filter (the list items are filtered) this method is called.
    // This means that we need to stopPropagation here in order for the dropdown window stays open
    event.stopPropagation();
  }

  onAutoCompleteMatOption(event: MouseEvent, selectedCheckbox: CheckboxNode) {
    // this method, with the mat-option-span class allows the dropdown to stay open when there is text in the autocompleted input
    event.stopPropagation();
    this.handleOnClickChange(selectedCheckbox);
  }

  scrollToNode(nodeId: string) {
    // Find the DOM element for the node by ID.  This is the ID generated by the service.
    const nodeElement = document.getElementById(nodeId);
    if (nodeElement && this.treeContainer) {
      const container = this.treeContainer.nativeElement;
      const offsetTop = nodeElement.offsetTop;
      // Scroll the container to the node's position
      container.scrollTop = offsetTop - container.offsetTop;
    }
  }

  validateTreeNodeExpansion(node: ParentCheckboxNode) {
    return node && node.expandable;
  }

  private initSubscriptions() {
    this.checkboxTreeServiceDataChange = this._checkboxTreeService.dataChange.subscribe(data => this.handleCheckboxTreeServiceDataChanged(data));
    this.checkboxTreeServiceSetSelection = this._checkboxTreeService.setSelection.subscribe(event => this.handleCheckBoxTreeServiceSetSelection(event));
    this.checkListSelectionChange = this.checklistSelection.changed.subscribe(change => this.handleChecklistSelectionChanged(change));
  }

  private selectChildItemById(id: string) {
    const selectedNode = this.getParentNodeById(id);
    if (selectedNode) {
      this.childItemSelectionToggle(selectedNode);
    }
    return selectedNode;
  }

  private getAllCurrentlySelectedItems() {
    return this.checklistSelection.selected;
  }

  private getAllCurrentlyExpandedNodes() {
    return this.treeControl?.dataNodes?.filter(node => this.treeControl.isExpanded(node)).map(node => node.item);
  }

  private clearTreeData() {
    this.flatNodeMap.clear();
    this.nestedNodeMap.clear();
    this.checklistSelection.clear(false); // clear to ensure we don't trigger the changed subscription.
  }

  private getUpdatedNodesToSelect(currentlySelectedItems: ParentCheckboxNode[]) {
    /**** Notes for this commented out code -- The old way of adding items was to re-create the grid everytime.
     this meant that all the ids were reset with new ids.  Since now, we are add / deleting items, the previously
     generated ids will no longer change.  Thus we should be able to re-select purely on the id's matching.  However,
     this code will act as a reference incase we need to revert this change.
     return Array.from(this.flatNodeMap.keys()).filter(flatNode =>
     currentlySelectedItems.some(dataItem => Util.deepCompareTransferDataObjects(flatNode.tag, dataItem.tag))
     );
     ****/

    // use the generated id to find the matching items.
    return Array.from(this.flatNodeMap.keys()).filter(flatNode =>
      currentlySelectedItems.some(dataItem => flatNode.id === dataItem.id)
    );
  }

  private setNodesToSelect(currentlySelectedItems: ParentCheckboxNode[]) {
    const nodesToReselect: ParentCheckboxNode[] = this.getUpdatedNodesToSelect(currentlySelectedItems);
    // temporarily disable the selectChange event from firing when we re-set the selection.
    this.disableCheckListSelectionChanged = true;
    this.checklistSelection.setSelection(...nodesToReselect);
    this.disableCheckListSelectionChanged = false;
  }

  private setNodesToExpand(currentlyExpandedNodes: string[]) {
    if (currentlyExpandedNodes === undefined || currentlyExpandedNodes?.length === 0) {
      // no nodes are expanded, so lets expand the first now.
      const firstParentNode = this.treeControl?.dataNodes?.find(node => node.level === 0);
      if (firstParentNode) {
        if (!this.validateTreeNodeExpansion(firstParentNode)) {
          // don't expand the first node if it's not expandable
          return;
        }
        this.treeControl.expand(firstParentNode);
      }
    } else {
      this.treeControl.dataNodes.forEach(node => {
        if (currentlyExpandedNodes?.includes(node.item)) {
          this.treeControl.expand(node);
        }
      });
    }
  }

  private setDataSource(data: CheckboxNode[]) {
    this.dataSource.data = data;
  }

  private triggerSearchFromControlValueChangesEvent(data: CheckboxNode[]) {
    // Manually trigger valueChanges by setting the current value to itself -- This will ensure the
    // search form control has the latest data source.
    // if data.length is 0, it means everything is unselected, to reset the control.
    this.searchFormControl.setValue(data.length === 0 ? '' : this.searchFormControl.value || '', {emitEvent: true});
  }

  private handleCheckboxTreeServiceDataChanged(data: DataSourceChangedEvent) {
    // new data coming in, reset the existing lists
    const currentlySelectedItems = this.getAllCurrentlySelectedItems();
    // get a list of all expanded nodes, so we can re-expand them
    const allExpandedNodes = this.getAllCurrentlyExpandedNodes();
    this.clearTreeData();
    // set the datasource
    this.setDataSource(data.items);
    this.setNodesToSelect(currentlySelectedItems);
    this.setNodesToExpand(allExpandedNodes);
    // Manually trigger the event
    this.triggerSearchFromControlValueChangesEvent(data.items);
  }

  private handleChecklistSelectionChanged(change: SelectionChange<ParentCheckboxNode>) {
    // Only fire if it's enabled.  This will be disabled when re-selecting after the data is reloaded.
    if (!this.disableCheckListSelectionChanged) {
      if (change.added.length > 0) {
        this.handleSelectionChange(change.added, SelectionChangeType.ADD);
      } else if (change.removed.length > 0) {
        this.handleSelectionChange(change.removed, SelectionChangeType.DELETE);
      } else {
        console.warn("ag-checkbox-tree - Invalid selection type");
      }
    }
  }

  private handleSelectionChange(nodes: ParentCheckboxNode[], type: SelectionChangeType): void {
    // Check if the nodes represent a parent selection at level 0
    const isParentSelected = (nodes: ParentCheckboxNode[]) =>
      nodes.length === 1 && nodes[0].level === 0 && nodes[0].expandable;

    // the original event fires twice (once when the parent is select, then when all the children are selected.  We only care about when the children are selected
    if (!isParentSelected(nodes)) {
      // We should validate to make sure that all the children selected do actually belong to the proper parent.
      const allIds = nodes.map(node => node.id);
      const allCheckboxNodes = Array.from(this.nestedNodeMap.keys()).filter(node => allIds.includes(node.id));
      const verifyAllFromSameParent = allCheckboxNodes.every(node => node.parentId === allCheckboxNodes[0].parentId);

      if (verifyAllFromSameParent) {
        // everything is validated, so we can emit properly.
        const parentNodeId = allCheckboxNodes[0]?.parentId;
        if (parentNodeId) {
          const parentNode = this.getParentNodeById(parentNodeId);
          this.selectionChanged.emit({
            parentItem: parentNode?.tag,
            childrenItems: nodes.map(node => node.tag),
            changeType: type
          });
        }
      } else {
        console.error('ag-checkbox-tree - Nodes have different parentIds');
      }
    }
  }

  private getAllParentNodes() {
    const filteredItems: CheckboxNode[] = [];
    this.nestedNodeMap.forEach((childNode, parentNode) => {
      if (childNode.level === 0) {
        filteredItems.push(parentNode);
      }
    });
    return filteredItems;
  }

  private filterSearchOptions(value: string | CheckboxNode | null): CheckboxNode[] {
    if (typeof value === 'string') {
      if (!value) {
        // empty string return full list
        return this.getAllParentNodes();
      }
      let filteredItems: CheckboxNode[];
      filteredItems = this.getAllParentNodes().map(parent => {
        const filteredChildren =
          parent.children.filter(child => child.item?.toLowerCase().includes(value?.toLowerCase()))
        return {...parent, children: filteredChildren};
      }).filter(parent => parent.children.length > 0);
      return filteredItems;
    } else if (value === null) {
      console.warn("value is null");
    } else {
      const selectedNode = this.selectChildItemById(value.id);
      if (selectedNode) {
        return this.filterSearchOptions(selectedNode.item);
      }
    }

    return this.getAllParentNodes();
  }

  /**
   * This is the handler for the AgCheckBoxTreeService setSelection event
   * @param event
   * @private
   */
  private handleCheckBoxTreeServiceSetSelection(event: TreeSelectionEvent) {
    if (event && event.itemsToSelect && event.itemsToSelect.length > 0) {
      let parentNodesToSelect: ParentCheckboxNode[] = [];
      event.itemsToSelect.forEach(item => {
        // We need to get the list of ParentCheckBoxNodes s owe can set the selection properly.
        const parentNode = this.getParentNodeById(item.id);
        if (parentNode) {
          this.disableCheckListSelectionChanged = true;
          this.checklistSelection.toggle(parentNode);
          parentNodesToSelect.push(parentNode);
          this.disableCheckListSelectionChanged = false;
        }
      });
      // trigger selection change for the parent component
      if (event.triggerSelectChangeEvent) {
        this.handleSelectionChange(parentNodesToSelect, SelectionChangeType.ADD);
      }
    }
  }
}
