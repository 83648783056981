<mat-card class="full-height-container">
  <mat-card-content class="full-height-container content-panel">
    <div class="flex-row">
      <app-component-header (refreshClickEvent)="this.refreshView()"
                            [checkAutoRefreshByDefault]="false"
                            [hideColumnsButton]="true"
                            [hideUploadButton]="true"
                            [startRefreshTimer]="this.refreshComplete"
                            [subtitleSegments]="getUrlSegments()"
                            class="full-width">
        <div class="flex-row flex-g-s pd-l-m">
          <button (click)="switchMode('data')" [disabled]="isDataMode()" class="secondary-button">
            <i class="fa-sharp fa-light fa-database"></i>
            <span>Data</span>
          </button>
          <button (click)="switchMode('chart')" [disabled]="!isDataMode()" class="secondary-button">
            <i class="fa-sharp fa-light fa-chart-line"></i>
            <span>Visuals</span>
          </button>
        </div>
      </app-component-header>
    </div>
    <div class="flex-row table-container">
      <ag-kpi-sidebar [sidebar]="kpiSidebar" class="mg-r-s">
        <div class="flex-centered pd-t-s pd-b-s">
          <mat-form-field appearance="fill">
            <mat-label>Scale</mat-label>
            <mat-select (selectionChange)="onScaleChanged($event.value)" [(ngModel)]="scale">
              <mat-option value="Minute">Minute</mat-option>
              <mat-option value="Hour">Hour</mat-option>
              <mat-option value="Day">Day</mat-option>
              <mat-option value="Week">Week</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ag-kpi-sidebar>
      <ag-grid-angular
        (columnVisible)="onColumnVisible($event)"
        (filterChanged)="onFilterChanged($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (gridReady)="onGridReady($event)"
        (viewportChanged)="onViewportChanged($event)"
        [columnDefs]="tableColumns"
        [gridOptions]="baseAgGridOptions"
        [groupHideOpenParents]="false"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [rowData]="rowData"
        [rowGroupPanelShow]="'always'"
        [style.display]="this.isDataMode() ? 'block' : 'none'"
        [suppressPropertyNamesCheck]="true"
        class="ag-theme-quartz ag-grid-flex">
      </ag-grid-angular>
      <div *ngIf="!this.isDataMode()" class="flex-row chart-container">
        <div *ngIf="this.isGridGrouped()" class="data-type-selection">
          <mat-button-toggle-group (change)="onDataTypeChange($event.value)" [(ngModel)]="selectedDataType"
                                   aria-label="Select Data Type">
            <mat-button-toggle *ngFor="let dataType of dataTypeOptions" [value]="dataType.value">
              {{ dataType.label }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <ag-charts [options]="chartOptions" class="chart"></ag-charts>
      </div>
    </div>
  </mat-card-content>
</mat-card>
