import {AfterViewInit, Component, ElementRef, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'ag-drawer',
  templateUrl: './ag-drawer.component.html',
  styleUrls: ['./ag-drawer.component.scss']
})
export class AgDrawerComponent implements AfterViewInit {

  @Input() title: string | undefined;
  @Input() titleIcon: string | undefined = 'fa-sharp fa-light fa-bars accent-color mg-r-s';
  @Input() @Output() hide: boolean = false;
  @ViewChild("leftCardContent") leftContent: ElementRef<HTMLElement> | undefined;


  ngAfterViewInit() {
    this.toggleView();
  }

  onOpenHideClick() {
    this.hide = !this.hide;
    this.toggleView();
  }

  toggleView() {
    if (this.leftContent) {
      let querySelectorAll: NodeListOf<HTMLElement> = this.leftContent.nativeElement.querySelectorAll('[data-decorator]');
      querySelectorAll.forEach(element => {
        if (this.hide) {
          element.classList.add('hide-content');
        } else {
          element.classList.remove('hide-content');
        }
      });
    }
  }

}
