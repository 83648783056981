import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[agClassifier]'
})
export class AgClassifierDirective {

  @Input("agClassifier")
  id: string | undefined;

  constructor(public template: TemplateRef<any>) {
  }

}
