<div class="display-flex" id="auto-refresh-wrapper">
  <div class="auto-refresh flex-row">
    <ag-slide-toggle [isChecked]="checkedByDefault" (toggleUpdate)="startAutoRefresher($event.checked)"></ag-slide-toggle>
    <span class="mg-l-s">Auto refresh in <span class="display-color-{{status}}">{{ refreshIn < 10 ? '0' + refreshIn : refreshIn }} seconds</span></span>
  </div>
  <div class="mdc-form-field">
    <button [matMenuTriggerFor]="menu" class="icon-button-small" mat-icon-button>
      <i class="fa-sharp fa-light fa-gear-complex"></i>
    </button>
  </div>
  <mat-menu #menu="matMenu">
    <button (click)="onRefreshIntervalChange(interval)" *ngFor="let interval of intervalArray" mat-menu-item>
      <!--      <i *ngIf="selectedInternal == interval" class="fa-sharp fa-light fa-check accent-color mg-r-s"></i>-->
      {{ interval }}
    </button>
  </mat-menu>
</div>
