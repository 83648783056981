import {Injectable} from "@angular/core";
import {ApiService} from "@core/api/api.service";
import {UniqueLinkInvitee} from "@data/interefaces/panel.interfaces";
import {DataItem, OperationStatus, ResultsetThresholds, BatchProcessResult} from "@data/interefaces/data.interfaces";
import {Util} from "@data/util/util";

@Injectable({
  providedIn: 'root'
})
export class UniqueLinkInviteesService {
  constructor(private _apiService: ApiService) {
  }

  public getUniqueLinkInvitees(period: string, marketCode: string, surveyCode: string): Promise<UniqueLinkInvitee[]> {
    let queryParams: string[] = [];
    Util.pushQueryParam("period", period, queryParams);
    Util.pushQueryParam("marketCode", marketCode, queryParams);
    Util.pushQueryParam("surveyCode", surveyCode, queryParams);
    return this._apiService.makeGetServiceCall<UniqueLinkInvitee[]>(`/v2/api/panel/unique-link-invitees`, queryParams.join('&'));
  }

  public getUniqueLinkInviteesById(surveyCode: string, responseCode: string): Promise<UniqueLinkInvitee> {
    return this._apiService.makeGetServiceCall<UniqueLinkInvitee>(`/v2/api/panel/unique-link-invitees/${surveyCode}/${responseCode}`);
  }

  public getUniqueLinkInviteesThresholds(period: string, marketCode: string, surveyCode: string): Promise<ResultsetThresholds> {
    let queryParams: string[] = [];
    Util.pushQueryParam("period", period, queryParams);
    Util.pushQueryParam("marketCode", marketCode, queryParams);
    Util.pushQueryParam("surveyCode", surveyCode, queryParams);
    return this._apiService.makeGetServiceCall<ResultsetThresholds>(`/v2/api/panel/unique-link-invitees/thresholds`, queryParams.join('&'));
  }

  public saveUniqueLinkInvitees(uniqueLinkInvitee: any): Promise<DataItem> {
    return this._apiService.makePostServiceCall<DataItem>(`/v2/api/panel/unique-link-invitees`, null, uniqueLinkInvitee);
  }

  public updateUniqueLinkInvitees(uniqueLinkInvitee: any) {
    return this._apiService.makePutServiceCall<OperationStatus>(`/v2/api/panel/unique-link-invitees`, null, uniqueLinkInvitee);
  }

  public getLatestUniqueLinkInviteesBatchProcess() {
    return this._apiService.makeGetServiceCall<BatchProcessResult>(`/v2/api/panel/unique-link-invitees/bulk-process/latest`);
  }

  public startUniqueLinkInviteesBatchProcess(uniqueLinkInvitee: UniqueLinkInvitee[], processType: string) {
    let queryParams: string[] = [];
    Util.pushQueryParam("processType", processType, queryParams);
    return this._apiService.makePutServiceCall<{ id: number }>(`/v2/api/panel/unique-link-invitees/bulk-process/start`, queryParams.join('&'), uniqueLinkInvitee);
  }
}
