<div class="full-width">
  <mat-form-field> <!-- when using flex-column these need to be applied to fix the spacing issues -- appearance="fill" subscriptSizing="dynamic"-->
    <mat-label>{{ label }}</mat-label>
    <input
      [formControl]="this.searchFormControl"
      [matAutocomplete]="auto" matInput/>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="autoCompleteDisplayValue" class="ag-search-dropdown-autocomplete">
      <mat-checkbox (change)="this.toggleSelectAll()" *ngIf="this.showAllOption && this.hasItems()" [checked]="this.allSelected()" [indeterminate]="this.partiallySelected()"
                    class="checkbox-item"
                    color="primary">
        Select All
      </mat-checkbox>
      <mat-option *ngFor="let item of this.filteredItems$ | async" [value]="item">
                  <span (click)="this.onAutoCompleteMatOption($event, item)" class="mat-option-span">
                    <div class="checkbox-wrapper">
                    <mat-checkbox (change)="this.onAutoCompleteSelectionChanged(item)"
                                  (click)="this.onAutoCompleteCheckboxClicked($event)"
                                  [checked]="this.isSelected(item)"
                                  class="checklist-leaf-node"
                                  color="primary">
                      {{ item[this.displayValuePropertyName] }}
                    </mat-checkbox>
                    </div>
                  </span>
      </mat-option>
    </mat-autocomplete>
    <mat-icon (click)="this.clearInputValue($event)" *ngIf="this.hasValue()" matSuffix><i class="fa-sharp fa-regular fa-xmark"></i></mat-icon>
    <mat-icon matSuffix><i class="fa-sharp fa-thin fa-magnifying-glass"></i></mat-icon>
  </mat-form-field>
</div>
