<mat-toolbar-row class="footer">
  <div class="small_font">
    <sub>
      {{timezoneOffset}}
    </sub>
  </div>
  <div class="right-align small_font mg-r-m">
    <sub>© {{currentYear}} Advantage Group International. All Rights Reserved.</sub>
  </div>
  <div class="small_font">
    <sub>version: {{version}}</sub>
  </div>
</mat-toolbar-row>
