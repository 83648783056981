import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {formatDistanceToNow} from 'date-fns';
import {MatMenu, MatMenuTrigger} from "@angular/material/menu";
import {WebSocketService} from "@core/web-socket/web.socket.service";
import {StoreService} from "@core/api/store.service";
import {UserNotification} from "@data/interefaces/notification.interface";
import {NotificationStatus} from "@data/enums/data.enums";
import {NotificationService} from "@data/notification/notification.service";
import {NotificationDecorateService} from "@data/notification/notification-decorate.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'ag-notification',
  templateUrl: './ag-notification.component.html',
  styleUrls: ['./ag-notification.component.scss']
})
export class AgNotificationComponent implements OnInit, OnDestroy {
  @ViewChild('headerMenuTrigger') headerMenuTrigger!: MatMenuTrigger;
  @ViewChild('headerMenu') headerMenu!: MatMenu;
  unreadCount: number = 0;
  unreadOnly: boolean = false;
  private notifications: UserNotification[] = [];
  private notificationLoaded: boolean = false;
  private panelOpened: boolean = false;
  private messageSubscription: Subscription | undefined;

  constructor(private _store: StoreService,
              private _webSocketService: WebSocketService,
              private _notificationService: NotificationService,
              private _notificationDecorateService: NotificationDecorateService) {
  }

  get filteredNotifications(): UserNotification[] {
    return this.unreadOnly ? this.notifications.filter(n => n.notificationStatus === NotificationStatus.PENDING) : this.notifications;
  }

  ngOnInit(): void {
    this._notificationService.countUnreadNotification().then(result => this.unreadCount = result);
    const queue = `/user/${this._store.email}/topic/notifications`;
    this._webSocketService.connect(queue);
    this.messageSubscription = this._webSocketService.getMessages().subscribe(message => {
      this.receivedNewNotification(message);
    });
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    this._webSocketService.disconnect();
  }

  hasUnreadNotifications(): boolean {
    return this.unreadCount > 0;
  }

  onNotificationPanelOpened(): void {
    if (!this.notificationLoaded) {
      this.fetchNotifications();
    }
    this.panelOpened = true;
  }

  onNotificationPanelClosed(): void {
    this.panelOpened = false;
  }

  markAsRead(notification: UserNotification): void {
    if (notification.notificationStatus !== 'read') {
      this._notificationService.markAsRead(notification.notificationRecipientId).then(result => {
        this.unreadCount--
        this.notifications.filter(n => n.notificationRecipientId == notification.notificationRecipientId)
          .find(n => n.notificationStatus = NotificationStatus.READ);
      });
    }
  }

  markAllAsRead(): void {
    if (this.unreadCount > 0) {
      this._notificationService.markAllAsRead().then(result => {
        this.unreadCount = 0;
        this.notifications.filter(n => n.notificationStatus == NotificationStatus.PENDING)
          .forEach(n => n.notificationStatus = NotificationStatus.READ);
      });
    }
  }

  toggleUnreadOnly(isChecked: boolean): void {
    this.unreadOnly = isChecked;
  }

  closeHeaderMenu() {
    if (this.headerMenuTrigger) {
      this.headerMenuTrigger.closeMenu();
    }
  }

  getRelativeTime(timestamp: string | Date): string {
    return formatDistanceToNow(new Date(timestamp), {addSuffix: true});
  }


  private receivedNewNotification(message: string): void {
    this.unreadCount++;
    this.notificationLoaded = false;
    if (this.panelOpened) {
      this.fetchNotifications();
    }
  }

  private fetchNotifications() {
    this._notificationService.recentNotifications().then(result => {
      this.notifications = result.map(n => this._notificationDecorateService.decorateNotification(n));
      this.notificationLoaded = true;
      this.unreadCount = this.notifications.filter(n => n.notificationStatus == NotificationStatus.PENDING).length;
    });
  }
}
