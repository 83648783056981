import {CanActivateFn} from '@angular/router';
import {inject} from "@angular/core";
import {SecurityService} from "@core/secutiry/security.service";

export function canActivate(): CanActivateFn {
  return async () => {
    const securityService = inject(SecurityService);
    return securityService.isAuthenticated().then(result => {
      if (!result) {
        securityService.signIn();
      }
      return result;
    }).catch(() => false);
  };
}
