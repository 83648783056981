<ag-task (goBackEvent)="goBackEvent.emit($event)" [isUrlMode]="isUrlMode" [selectedFeatureMode]="selectedFeatureMode"
         [sidebarEl]="featureComponentSidebarEl" [urlModeGoBackUrl]="urlModeGoBackUrl" class="custom-page-task"
         id="page-task">
  <ng-template agClassifier="{{FeatureComponentMode.EDIT_ROW}}">
    <app-product-period-detail
      class="task-component-detail full-height-container"></app-product-period-detail>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.BULK_EDIT}}">
    <app-product-period-bulk (goBackEvent)="goBackEvent.emit($event)"
                             [featureComponentMode]="FeatureComponentMode.BULK_EDIT"
                             [selectedRows]="selectedRows"
                             class="task-component-detail full-height-container"></app-product-period-bulk>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.UPLOAD}}">
    <app-product-period-bulk (goBackEvent)="goBackEvent.emit($event)"
                             [featureComponentMode]="FeatureComponentMode.UPLOAD"
                             class="task-component-detail full-height-container"></app-product-period-bulk>
  </ng-template>
</ag-task>
