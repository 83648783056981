import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {SelectHeaderComponent} from "@pages/segments/grid/columns/select-header/select-header.component";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";
import {ColumnFilterType, LinkType} from "@data/enums/data.enums";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {Util} from "@data/util/util";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {QuilliupForm} from "@data/enums/quilliup.enum";
import {ResetFilterComponent} from "@pages/segments/grid/filters/reset-filter/reset-filter.component";

export enum ProductStatus {
  APPROVED = 'Approved',
  UNDER_CONSTRUCTION = 'Under Construction',
  NOT_SET = 'Not Set'
}

export let marketCodes: string[] = [];
export let periods: string[] = [];
export let pageContext: {
  [key: string]: boolean | string | undefined
  p_f: string | undefined;
  // m_c: string | undefined;
  mc_f: string | undefined;
  s_s: string | undefined;
  p_c: string | undefined;

  ic_f: string | undefined;
  pc_f: string | undefined;
  mn_f: string | undefined;
  in_f: string | undefined;
  pn_f: string | undefined;
  rgn_f: string | undefined;
  srwsn_f: string | undefined;
  ppta_f: string | undefined;
  ss_f: string | undefined;
  i_f: string | undefined;
  rgc_f: string | undefined;
  l_f: string | undefined;
  arc_f: string | undefined;
  mbd_f: string | undefined;
  srwsc_f: string | undefined;
  mo_f: string | undefined;
  as_f: string | undefined;
  a_r: string | undefined;
  ac_r: string | undefined;
  pc_r: string | undefined;
  psfs_f: string | undefined;
  psfc_r: string | undefined;
  psfu_f: string | undefined;
  psfp_f: string | undefined;
  pi_f: string | undefined;
} = {
  p_f: undefined,
  // m_c: undefined,
  mc_f: undefined,
  s_s: undefined,
  p_c: undefined,
  ic_f: undefined,
  pc_f: undefined,
  mn_f: undefined,
  in_f: undefined,
  pn_f: undefined,
  rgn_f: undefined,
  srwsn_f: undefined,
  ppta_f: undefined,
  ss_f: undefined,
  i_f: undefined,
  rgc_f: undefined,
  l_f: undefined,
  arc_f: undefined,
  mbd_f: undefined,
  srwsc_f: undefined,
  mo_f: undefined,
  as_f: undefined,
  a_r: undefined,
  ac_r: undefined,
  pc_r: undefined,
  psfs_f: undefined,
  psfc_r: undefined,
  psfu_f: undefined,
  psfp_f: undefined,
  pi_f: undefined
};

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    maxWidth: 75,
    field: 'select',
    headerName: '',
    hide: false,
    filter: false,
    headerComponent: SelectHeaderComponent,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    pinned: "left",
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRenderer: SelectColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        showFlag: (params.data.pendingChecksumDatetime),
        // flagIcon: "fa-hourglass-clock",
        flagIcon: "fa-exclamation",
        flagColor: "red",
        toolTip: 'Pending Checksum'
      }
    }
  },
  {
    headerName: 'Product Period', valueFormatter: '', children: [
      {
        field: 'period',
        headerName: 'Period',
        hide: false,
        dbFilter: true,
        filterId: 'p_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: periods,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            suppressSorting: true
          },
      },
      {
        field: 'marketCode',
        headerName: 'Market Code',
        hide: false,
        dbFilter: true,
        filterId: 'mc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: marketCodes,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true
          },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketName,
          };
        }
      },
      {
        field: 'marketName', headerName: 'Market Name', hide: false, filterId: 'mn_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          }
        },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketCode,
          };
        }
      },
      {
        field: 'industryCode',
        headerName: 'Industry Code',
        hide: false,
        filterId: 'ic_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            defaultToNothingSelected: true
          },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryName,
          };
        }
      },
      {
        field: 'industryName', headerName: 'Industry Name', hide: false, filterId: 'in_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          }
        },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryCode,
          };
        }
      },
      {
        field: 'surveySubject', headerName: 'Survey Subject', hide: false, filterId: 'ss_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'productCode',
        headerName: 'Product Code',
        hide: false,
        filterId: 'pc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            defaultToNothingSelected: true
          },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.productName,
            links:
              [
                {
                  text: 'View Products in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.PRODUCTS
                },
                {
                  text: "View Product Report Labels in Quilliup",
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.PRODUCT_REPORT_LABELS
                },
                {
                  text: "View Product Trend Alignments in Quilliup",
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.PRODUCT_TREND_ALIGNMENTS
                }
              ]
          };
        }
      },
      {
        field: 'productName', headerName: 'Product Name', hide: false, filterId: 'pn_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          }
        },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.productCode,
          };
        }
      },
    ]
  },
  {
    headerName: 'Product Configuration', valueFormatter: '', children: [
      {
        field: 'include', headerName: 'Included?', hide: false, filterId: 'i_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, cellRenderer: CircleCheckColumnComponent},
        cellRenderer: CircleCheckColumnComponent
      },
      {
        field: 'respondentGroupCode', headerName: 'Respondent Group Code', hide: false, filterId: 'rgc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.respondentGroupName,
            links:
              [
                {
                  text: 'View Products in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.RESPONDENT_GROUP_PERIODS
                },
                {
                  text: "View Product Periods in Quilliup",
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.RESPONDENT_GROUP_REPORT_LABELS
                }
              ]
          };
        }
      },
      {
        field: 'respondentGroupName', headerName: 'Respondent Group Name', hide: false, filterId: 'rgn_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          }
        },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.respondentGroupCode,
          };
        }
      },
      {
        field: 'languages', headerName: 'Languages', hide: false, filterId: 'l_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
      },
      {
        field: 'allRespondentCompanies', headerName: 'All Respondent Companies?', hide: false, filterId: 'arc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, cellRenderer: CircleCheckColumnComponent},
        cellRenderer: CircleCheckColumnComponent
      },
      {
        field: 'standardRespondentWeightSetCode', headerName: 'Standard Respondent Weight Set Code', hide: false, filterId: 'srwsc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {defaultToNothingSelected: true},
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.standardRespondentWeightSetName,
            links:
              [
                {
                  text: 'View Products in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.STANDARD_COMPANY_WEIGHTS
                },
              ]
          };
        }
      },
      {
        field: 'standardRespondentWeightSetName', headerName: 'Standard Weight Set Name', hide: false, filterId: 'srwsn_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          }
        },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.standardRespondentWeightSetCode,
          };
        }
      },
      {
        field: 'productPeriodTrendAlignments',
        headerName: 'Product Period Trend Alignments',
        hide: false,
        filterId: 'ppta_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            defaultToNothingSelected: true
          }
      },
      {
        field: 'maskedByDefault', headerName: 'Masked By Default', hide: false, filterId: 'mbd_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, cellRenderer: CircleCheckColumnComponent},
        cellRenderer: CircleCheckColumnComponent

      },
      {
        field: 'maskingOverride', headerName: 'Masking Override', hide: false, filterId: 'mo_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
        cellRenderer: StatusColumnComponent
      },
    ]
  },
  {
    headerName: 'Release Management', valueFormatter: '', children: [
      {
        field: 'approvalStatus', headerName: 'Approval Status', hide: false, filterId: 'as_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'approvalDatetime', headerName: 'Approval Datetime', hide: false, filterId: 'a_r',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'approvedChecksumDatetime', headerName: 'Approved Checksum Datetime', hide: false, filterId: 'ac_r',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'pendingChecksumDatetime', headerName: 'Pending Checksum Datetime', hide: false, filterId: 'pc_r',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View Products in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.CHECKSUM_REVIEW
                },
                {
                  text: 'View Products in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.CHECKSUM_DETAILS
                },
              ]
          };
        }
      }
    ]
  },
  {
    headerName: 'Product Summary File', valueFormatter: '', children: [
      {
        field: 'productSummaryFileStatus', headerName: 'Product Summary File Status', hide: false, filterId: 'psfs_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true},
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'productSummaryFileCreatedDatetime',
        headerName: 'Product Summary File Created Datetime',
        hide: true,
        filterId: 'psfc_r',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          inRangeInclusive: true,
          maxNumConditions: 1,
          comparator: Util.dateComparator
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'productSummaryFileUrl', headerName: 'Product Summary File URL', hide: true, filterId: 'psfu_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.productSummaryFileUrl,
            type: 'link',
          };
        }
      },
    ]
  },
  {
    headerName: 'Product Summary File', valueFormatter: '', children: [
      {
        field: 'productId', headerName: 'Product Id', hide: true, filterId: 'pi_f', filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      },
      {
        field: 'productPeriodId', headerName: 'Product Period Id', hide: true, filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      }
    ]
  },
  {
    field: "actions",
    headerName: "Actions",
    pinned: "right",
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: ResetFilterComponent,
    floatingFilterComponentParams: {
      suppressFilterButton: true
    },
    cellRenderer: ActionColumnComponent,
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRendererParams:
      function (params: any) {
        return {
          actions: [
            {
              toolTip: "Edit",
              icon: "fa-pen-to-square",
              name: "Edit",
              routerLink: ['/forms', 'product-spec', 'product-period', 'task', 'edit'],
              queryParams: {p_f: params.data.period, pi_f: params.data.productId}
            }
          ]
        };
      }
  }
]
