import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {EventbusService} from "@core/event-bus/eventbus.service";
import {EventType} from "@core/interfaces/data.interfaces";
import {NgTemplateOutlet} from "@angular/common";

@Component({
  selector: 'ag-side-panel',
  templateUrl: './ag-side-panel.component.html',
  styleUrls: ['./ag-side-panel.component.scss']
})
export class AgSidePanelComponent {

  @Input() title: string | undefined;
  @Input() subTitle: string | undefined;

  @Input() mode: "push" | "over" | "side" = "over";
  @Input() position: "start" | "end" = "end";
  @Input() showProgress: boolean = false;
  @Input() size: "small" | "medium" | "large" | "xlarge" = "medium";

  @ViewChild("sidenavTemplate") templateRef: TemplateRef<NgTemplateOutlet> | null = null;

  constructor(private _eventBus: EventbusService) {
  }

  toggle() {
    this._eventBus.fire({
      type: EventType.SIDE_NAVIGATION, data:
        {
          sideNavTemplateRef: this.templateRef,
          mode: this.mode,
          position: this.position,
          size: this.size
        }
    });
  }
}
