<div class="header" id="header">
  <mat-toolbar>
    <span>ARM Console</span>
    <mat-chip class="mg-l-s">
      <span class="material-symbols-outlined fa-beat primary-color" matChipAvatar>source_environment</span>
      {{ environmentInfo?.get(EnvironmentConfiguration.APPLICATION_ENVIRONMENT_NAME) }}
    </mat-chip>
    <div class="right-align nav-block">
      <ng-container *ngIf="authenticationContext">
        <div class="flex-row navigation-wrapper">
          <ag-menu [navigationMenu]="navigationMenu"/>
          <a [matMenuTriggerFor]="user_menu" mat-button>
            {{ authenticationContext.principleName }}
            <mat-icon class="material-symbols-outlined" iconPositionEnd>arrow_drop_down</mat-icon>
          </a>
        </div>
        <mat-menu #user_menu="matMenu">
          <a (click)=signOut() mat-menu-item>
            <i class="fa-sharp fa-light fa-arrow-right-from-bracket primary-color"></i>
            Sign out
          </a>
          <a [href]="getLegacyUrl()" mat-menu-item target="_blank">
            <i class="fa-sharp fa-light fa-arrow-up-right-from-square primary-color"></i>
            Legacy A2 Console
          </a>
        </mat-menu>
      </ng-container>
    </div>
  </mat-toolbar>
</div>
