import {AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef} from '@angular/core';
import {AgClassifierDirective} from "@shared/ag.classifier.directive";
import {NavigationService} from "@core/api/navigation.service";
import {Router} from "@angular/router";
import {FeatureComponentMode} from "@data/enums/data.enums";
import {TaskSidebarItem} from "@shared/ag-task/ag-task.component.ds";

@Component({
  selector: 'ag-task',
  templateUrl: './ag-task.component.html',
  styleUrls: ['./ag-task.component.scss']
})
export class AgTaskComponent implements AfterContentInit {

  @Input() subtitleSegments: string[] | undefined;
  @Input() sidebarEl: TaskSidebarItem[] = [];
  @Input() selectedFeatureMode: FeatureComponentMode | undefined;
  @Input() isUrlMode!: boolean;
  @Input() urlModeGoBackUrl!: string;
  @Input() goBackTitle: string = 'Back';
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  currentTemplateOutlet: TemplateRef<any> | null = null;
  @ContentChildren(AgClassifierDirective) outletList: QueryList<AgClassifierDirective> | null = null;

  constructor(private _navigation: NavigationService, private _router: Router) {
  }

  ngAfterContentInit(): void {
    this.selectCurrentTaskAndUnselectOthers()
  }

  goBack() {
    if (this.isUrlMode) {
      this._navigation.back(this.urlModeGoBackUrl, ["task"]);
    } else {
      this.goBackEvent.emit();
    }
  }

  taskSidebarItemClicked(task: TaskSidebarItem) {
    if (!task.disabled) {
      if (this.isUrlMode) {
        this._router.navigate([this.getSelectedTaskPath(task)]).then(r => {
          this.selectCurrentTaskAndUnselectOthers(task);
        });
      } else {
        this.selectCurrentTaskAndUnselectOthers(task);
      }
    }
  }

  private selectCurrentTaskAndUnselectOthers(task?: any) {
    let isTaskProvided = task !== undefined;
    this.sidebarEl.forEach(element => {
      if (this.selectedFeatureMode && (isTaskProvided && element === task || !isTaskProvided && element.mode === this.selectedFeatureMode)) {
        element.selected = true;
        this.switchSelectedTaskTemplate(element);
      } else {
        element.selected = false;
      }
    });
  }

  private getSelectedTaskPath(task: TaskSidebarItem) {
    const pathArray = this._router.url.split('/');
    pathArray.pop();
    const resultString = pathArray.join('/');
    return resultString + "/" + task.mode;
  }

  private switchSelectedTaskTemplate(task: TaskSidebarItem) {
    this.outletList?.forEach(template => {
      if (template.id == task.mode) {
        this.currentTemplateOutlet = template.template;
      }
    })
  }

}
