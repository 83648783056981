<ag-task (goBackEvent)="goBackEvent.emit($event)" [isUrlMode]="isUrlMode" [selectedFeatureMode]="selectedFeatureMode"
         [sidebarEl]="featureComponentSidebarEl"
         [urlModeGoBackUrl]="urlModeGoBackUrl" class="full-height-container"
         id="task-component">
  <ng-template agClassifier="{{FeatureComponentMode.ADD_ROW}}">
    <app-ms-excel-survey-invitees-detail
      class="task-component-detail full-height-container"></app-ms-excel-survey-invitees-detail>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.EDIT_ROW}}">
    <app-ms-excel-survey-invitees-detail
      class="task-component-detail full-height-container"></app-ms-excel-survey-invitees-detail>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.BULK_REQUEST_FILES}}">
    <app-ms-excel-survey-invitees-bulk (goBackEvent)="goBackEvent.emit($event)"
                                       [featureComponentMode]="FeatureComponentMode.BULK_REQUEST_FILES"
                                       [selectedRows]="selectedRows"
                                       class="task-component-detail full-height-container"></app-ms-excel-survey-invitees-bulk>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.UPLOAD}}">
    <app-ms-excel-survey-invitees-bulk (goBackEvent)="goBackEvent.emit($event)"
                                       [featureComponentMode]="FeatureComponentMode.UPLOAD"
                                       class="task-component-detail full-height-container"></app-ms-excel-survey-invitees-bulk>
  </ng-template>
</ag-task>
