import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {OperationStatus, TransferConfig} from "@data/interefaces/data.interfaces";
import {ActivatedRoute} from "@angular/router";
import {Util} from "@data/util/util";
import {AgSidePanelComponent} from "@shared/ag-side-panel/ag-side-panel.component";
import {TransferConfigService} from "@data/transfer-config/transfer.config.service";
import {MatDialog} from "@angular/material/dialog";
import {AgConfirmationDialogComponent} from "@shared/ag-confirmation-dialog/ag-confirmation-dialog.component";
import {TransferRunService} from "@data/transfer-run/transfer.run.service";
import {TransferStatus} from "@data/enums/data.enums";
import {editClickAction, pageContext, runClickAction, tableColumns, transferCodeClickAction} from "@pages/transfer-config/transfer-config.component.ds";
import {TableBaseComponent} from "@pages/table.base.component";
import {FilterChangedEvent, RowSelectedEvent, SelectionColumnDef, ViewportChangedEvent} from "@ag-grid-community/core";
import {KpiSidebarItem} from "@shared/ag-kpi-sidebar/ag-kpi-sidebar.component.ds";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'app-transfer-config',
  templateUrl: './transfer-config.component.html',
  styleUrls: ['./transfer-config.component.scss'],
  providers: [AgGridService]
})
export class TransferConfigComponent extends TableBaseComponent implements AfterViewInit {

  public isFilterActive: boolean = false;
  auxLinks: Map<string, string>;
  @ViewChild('runParametersSidePanel') runParametersSidePanel: AgSidePanelComponent | null = null;
  @ViewChild('footerActionButtonPanel') footerActionButtonPanel: TemplateRef<any> | undefined;
  dataSource: TransferConfig[] = [];
  selectedRows: TransferConfig[] = [];
  kpiSidebar: KpiSidebarItem[] = [];
  selectedTransferCode: string | undefined;
  isReadOnly = false;
  refreshComplete: boolean = true;
  protected readonly util = Util;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns

  constructor(private _activatedRoute: ActivatedRoute, private _transferConfigService: TransferConfigService, private _transferRunService: TransferRunService,
              public _dialog: MatDialog) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    /******
     * When we subscribe to the queryParamMap, each time the url changes, the component is re-initialized.
     * in our case, we only want to read the parameters once, when we first go to the page (to support book marking).
     * so we will try to just get the snapshot of the parameters.
     */
    // this._activatedRoute.queryParamMap.subscribe(params => {
    //   this.initiatePageState(params);
    //   this.updatePageContext();
    // });
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.refreshView();
    this.updatePageContext();
  }

  ngAfterViewInit() {
    this.refreshView();
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this));
  }

  refreshView() {
    this.refreshComplete = false;
    this.displayProgressBar(true);
    this._transferConfigService.getTransferConfigurations().then(result => {
      this.dataSource = result;
    }).catch(error => {
      this.displayNotificationMessage('error', JSON.stringify(error));
    }).finally(() => {
      this.refreshComplete = true;
      this.displayProgressBar(false);
    });
  }

  updateScheduleStatus(status: boolean) {
    let promises: Promise<OperationStatus>[] = [];
    Promise.all(promises).then(r => {
      this.refreshView();
      this.displayNotificationMessage('success', 'record saved');
    });
  }

  onTransferCodeClick(transfer: TransferConfig, isReadOnly: boolean) {
    if (this.runParametersSidePanel) {
      this.selectedTransferCode = transfer.code;
      this.isReadOnly = isReadOnly;
      this.runParametersSidePanel?.toggle();
    }
  }

  onRunTransferClick(transfer: TransferConfig) {
    // open confirm dialog
    const dialogRef = this._dialog.open(AgConfirmationDialogComponent, {
      data: {
        title: "Confirm", message: "Are you sure you want to run" +
          " the transfer '" + transfer.code + "' ?"
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.displayProgressBar(true);
        this._transferRunService.runTransfer(transfer.code).then(result => {
          if (result.status) {
            // immediately set the status to disable the icon.
            transfer.status = TransferStatus.IN_PROGRESS;
            this.displayNotificationMessage('info', `Transfer ${transfer.code} started.`);
            // let refresh get the proper status.
            this.refreshView();
          }
          this.displayProgressBar(false);
        }).catch(error => {
            this.displayNotificationMessage('error', JSON.stringify(error));
            this.displayProgressBar(false);
          }
        );
      }
    })
  }

  enableScheduleBulkAction() {
    if (this.selectedRows.length == 0) {
      return false;
    }
    let enableSchedule = true;
    this.selectedRows.forEach(record => {
      enableSchedule = !record.scheduled && enableSchedule;
    });

    return enableSchedule;
  }

  enableUnScheduleBulkAction() {
    if (this.selectedRows.length == 0) {
      return false;
    }
    let enableUnSchedule = true;
    this.selectedRows.forEach(record => {
      enableUnSchedule = record.scheduled && enableUnSchedule;
    });

    return enableUnSchedule;
  }

  onRowSelected(event: RowSelectedEvent) {
    if (event.source == "checkboxSelected") {
      this.gridApi.refreshHeader();
    }
    this.selectedRows = this.getSelectedRows();
    this.enableScheduleBulkAction();
    this.enableUnScheduleBulkAction();
  }

  protected override updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }

  protected override getSelectionColumnDef(): SelectionColumnDef {
    return this.getSelectColumnDef((params: any) => {
      return {
        showFlag: !params.data.scheduled,
        flagIcon: "fa-exclamation",
        flagColor: "red"
      }
    });
  }

  protected override getTableFooterActionButtonPanel(): TemplateRef<any> | undefined {
    return this.footerActionButtonPanel;
  }

  protected override onViewportChanged(event: ViewportChangedEvent<any>) {
    this.populateKpiMetrics();
  }

  protected override initAfterGridReady() {
    this.gridApi.addGlobalListener((eventType: any, event: any) => {
      switch (eventType) {
        case runClickAction: {
          this.onRunTransferClick(event.detail.rowData);
          break;
        }
        case editClickAction: {
          this.onTransferCodeClick(event.detail.rowData, false);
          break;
        }
        case transferCodeClickAction: {
          this.onTransferCodeClick(event.detail.rowData, true);
          break;
        }
      }
    });
  }

  private populateKpiMetrics() {
    let filteredRows = this.getRowsAfterFilter();
    let {scheduled} = this.calculateKpiMetrics(filteredRows);
    this.kpiSidebar = [
      {
        title: "Scheduled",
        value: scheduled
      }];
  }

  private calculateKpiMetrics(rows: TransferConfig[]) {
    let scheduled = rows.filter(element => element.scheduled).length;
    return {scheduled};
  }
}
