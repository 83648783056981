import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDateRangeInput} from "@angular/material/datepicker";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'ag-date-range',
  templateUrl: './ag-date-range.component.html',
  styleUrls: ['./ag-date-range.component.scss']
})
export class AgDateRangeComponent implements OnInit {

  @Input() initIsoDateRange: string | undefined;
  @Output() isoDateRangeChanged: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();
  @Input() dateLabel: string = "Enter Date Range";
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  ngOnInit() {
    this.range.setValue({
      start: this.initIsoDateRange == undefined ? null : new Date(this.initIsoDateRange.split('|')[0]),
      end: this.initIsoDateRange == undefined ? null : new Date(this.initIsoDateRange.split('|')[1])
    });
  }

  onEndDateChange(rangeInput: MatDateRangeInput<any>) {
    rangeInput.value?.end ? this.isoDateRangeChanged.emit(rangeInput.value?.start?.toISOString() + '|' + rangeInput.value?.end?.toISOString())
      : this.isoDateRangeChanged.emit(undefined);
  }

  resetDates() {
    this.range.reset();
    this.isoDateRangeChanged.emit(undefined);
  }
}
