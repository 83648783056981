import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {DataItem, OperationStatus} from "@data/interefaces/data.interfaces";
import {UserNotification} from "@data/interefaces/notification.interface";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private _apiService: ApiService) {
  }

  public countUnreadNotification(): Promise<number> {
    return this._apiService.makeGetServiceCall<DataItem>(`/v2/api/notification/unread-count`).then(result => {
      if (!result?.value) {
        return 0;
      }
      const parsed = parseInt(result.value, 10);
      return isNaN(parsed) ? 0 : parsed;
    });
  }

  public recentNotifications(): Promise<UserNotification[]> {
    return this._apiService.makeGetServiceCall<UserNotification[]>(`/v2/api/notification/recent`);
  }

  public markAsRead(notificationRecipientId: number): Promise<OperationStatus> {
    return this._apiService.makePutServiceCall<OperationStatus>(`/v2/api/notification/mark-read/${notificationRecipientId}`, null, null);
  }

  public markAllAsRead(): Promise<OperationStatus> {
    return this._apiService.makePutServiceCall<OperationStatus>(`/v2/api/notification/mark-all-read`, null, null);
  }
}
