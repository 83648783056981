<div [showSkeleton]="loading" [skeletonType]="'grid-4x1'" agSkeleton class="full-height-container">
    <mat-card *ngIf="!loading" class="task-component-detail backdrop" id="task-component-detail">
        <mat-card-header class="mg-b-m">
            <div class="header-title">
                <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
                <mat-card-subtitle>Verbatim File</mat-card-subtitle>
            </div>
        </mat-card-header>

        <mat-card-content class="form-container">
            <form (ngSubmit)="this.submitForm(this.saveAndClose, 'Verbatim File Uploaded')" [formGroup]="formGroup">

                <ag-file-upload (uploadFinished)="uploadFinished($event)"
                                [maxFileSizeSupported]="maxFileSizeSupported" [supportedFileTypes]="['xlsx', 'xls']"
                                [resetAfterUpload]="false">
                </ag-file-upload>
                <div class="flex-row toggle-block">
                    <div class="mg-b-m flex-row">
                        <ag-slide-toggle (toggleUpdate)="util.setToggleValue($event.checked, this.getFormControl('addSubjectCompany'))"
                                         [isChecked]="util.isToggleChecked(this.getFormControl('addSubjectCompany'))">
                        </ag-slide-toggle>
                        <span class="mg-l-s">Add Subject Company Automatically</span>
                    </div>

                </div>
                <div class="flex-row view-only-container">
                    <mat-form-field>
                        <mat-label>Survey Code</mat-label>
                        <mat-select formControlName="surveyCode">
                            <mat-option
                                    *ngFor="let surveyCode of surveyCodeList"
                                    [value]="surveyCode.surveyCode"
                            >{{ surveyCode.surveyCode }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="flex-row">
                    <mat-form-field>
                        <mat-label>Description</mat-label>
                        <textarea formControlName="description" matInput></textarea>
                    </mat-form-field>
                </div>

                <div class="flex-row flex-g-s action-buttons">
                    <button color="primary" mat-raised-button type="submit"
                            [disabled]="this.selectedFile==null || !getFormControl('surveyCode').value">
                        <mat-icon class="material-symbols-outlined">Save</mat-icon>
                        Upload
                    </button>
                </div>

            </form>
        </mat-card-content>
    </mat-card>
</div>
