import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    field: 'surveyResponseErrorType',
    headerName: 'Error Type',
    hide: false,
    minWidth: 200,
  },
  {
    field: 'message',
    headerName: 'Message',
    hide: false,
    flex: 1,
    wrapText: true,
    autoHeight: true,
    cellStyle: {'line-height': 1.8}
  }
]
