import {GridColumn, GridColumnGroupDef, ProductRespondentCompany} from "@data/interefaces/data.interfaces";
import {ProcessStatus} from "@data/interefaces/extended.data.interfaces";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";

export interface ProductRespondentCompanyWithProcessStatus extends ProductRespondentCompany, ProcessStatus {

}

export let displayTableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    headerName: 'Product Period', valueFormatter: '', children: [
      {field: 'period', headerName: 'Period'},
      {field: 'marketCode', headerName: 'Market Code'},
      {field: 'productCode', headerName: 'Product Code'},
      {field: 'industryCode', headerName: 'Industry Code'}
    ]
  },
  {
    headerName: 'Names', valueFormatter: '', children: [
      {field: 'marketName', headerName: 'Market Name'},
      {field: 'industryName', headerName: 'Industry Name'},
      {field: 'productName', headerName: 'Product Name'},
      {field: 'respondentGroupName', headerName: 'Res Group Name'},
    ]
  },
  {field: 'surveySubject', headerName: 'Survey Subject', cellRenderer: StatusColumnComponent},
  {field: 'include', headerName: 'Included?', cellRenderer: CircleCheckColumnComponent},
  {
    headerName: 'Codes', valueFormatter: '', children: [
      {field: 'respondentGroupCode', headerName: 'Res Group Code'},
      {field: 'respondentCompanyCode', headerName: 'Res Company Code'}
    ]
  },
  {
    headerName: 'Count', valueFormatter: '', children: [
      {field: 'ratingCount', headerName: 'Rating Count'},
      {field: 'personCount', headerName: 'Person Count'},
      {field: 'subjectCompanyCount', headerName: 'Subject Company Count'},
    ]
  },
  {
    headerName: 'Labels', valueFormatter: '', children: [
      {field: 'respondentCompanySurveyLabel', headerName: 'Res Company Survey Label'},
      {field: 'respondentCompanyReportLabel', headerName: 'Res Company Report Label'},
      {field: 'respondentCompanyStandardLabel', headerName: 'Res Company Standard Label'},
    ]
  },
  {field: 'assignedStandardWeight', headerName: 'Assigned Standard Weight'},
  {
    headerName: 'ID\'s', valueFormatter: '', children: [
      {field: 'productId', headerName: 'Product Id'},
      {field: 'productPeriodId', headerName: 'Product Period Id'}
    ]
  }
]
