import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {ApplicationEvent, EventType} from "@core/interfaces/data.interfaces";
import {EventbusService} from "@core/event-bus/eventbus.service";
import {MatSidenav} from "@angular/material/sidenav";

@Component({
  selector: 'app-sidenav-support',
  templateUrl: './sidenav-support.component.html',
  styleUrls: ['./sidenav-support.component.scss']
})
export class SidenavSupportComponent {

  @ViewChild("sidenav") sideNav!: MatSidenav;
  @Input() pendingChanges: boolean = false;
  protected sideNavTemplateRef: TemplateRef<any> | null = null;
  protected size: string = "medium";

  constructor(_eventBus: EventbusService) {
    _eventBus.register(EventType.SIDE_NAVIGATION, (event: ApplicationEvent) => {
      let data = event.data;
      this.sideNavTemplateRef = data.sideNavTemplateRef;
      this.sideNav.mode = data.mode;
      this.sideNav.position = data.position;
      this.size = data.size;
      if (this.sideNav.opened) {
        this.close();
      } else {
        this.sideNav.toggle();
      }
    });
  }

  close() {
    if (this.pendingChanges) {
      if (confirm("There are still pending changes on this form.  By clicking OK all changes will be lost")) {
        this.sideNav.close();
      }
    } else {
      this.sideNav.close();
    }
  }

}
