import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ag-file-upload',
  templateUrl: './ag-file-upload.component.html',
  styleUrls: ['./ag-file-upload.component.scss']
})
export class AgFileUploadComponent {

  fileSelected: boolean = false;
  fileName: string | undefined;
  fileSize: number = 0;
  uploadedSize: number = 0;
  progress: number = 0;
  messageType: 'INFO' | 'ERROR' = 'INFO';
  messageText: string | undefined;
  @Input() maxFileSizeSupported: number = 0;
  @Input() supportedFileTypes: string[] = [];
  @Input() resetAfterUpload: boolean = false;
  @Output() uploadFinished: EventEmitter<{ status: number, message: string, file?: File }> = new EventEmitter<any>()
  @Output() uploadStarted: EventEmitter<{ fileName: string, fileSize: number }> = new EventEmitter<any>()
  @Output() uploadReset: EventEmitter<void> = new EventEmitter<void>()

  protected readonly math: Math = Math;
  private fileToBeUploaded!: File;
  private chunkSize: number = 1024; //in bytes

  constructor() {
  }

  async fileDropped(files: FileList | null) {
    this.reset();
    if (files && files.item(0)) {
      this.fileSelected = true;
      this.fileToBeUploaded = files.item(0)!;
      this.fileName = this.fileToBeUploaded.name;
      this.fileSize = this.fileToBeUploaded.size;
      if (this.validateFile()) {
        this.uploadStarted.emit({fileName: this.fileName, fileSize: this.fileSize});
        this.uploadedSize = 0;
        this.progress = 0;
        this.loadFile().then(result => {
          this.messageText = 'File selected successfully';
          this.uploadFinished.emit({
            status: 0,
            message: this.messageText,
            file: result
          });
          if (this.resetAfterUpload) {
            this.reset();
          }
        });
      }
    }
  }

  getFileSizeDisplayString(fileSize: number) {
    let prefix: string = '';
    if (fileSize < 1024) {
      prefix = 'Bytes'
    } else if (fileSize < 1024 * 1024) {
      fileSize = this.math.round(fileSize / 1024);
      prefix = 'KB';
    } else if (fileSize < 1024 * 1024 * 1024) {
      fileSize = this.math.round(fileSize / (1024 * 1024));
      prefix = 'MB';
    }

    return fileSize + ' ' + prefix
  }

  reset() {
    this.fileSelected = false;
    this.fileName = undefined;
    this.fileSize = 0;
    this.uploadedSize = 0;
    this.progress = 0;
    this.messageText = undefined;
    this.messageType = "INFO";
    this.uploadReset.emit();
  }

  handleFileSelection(event: Event) {
    this.fileDropped((event.target as HTMLInputElement).files).then();
  }

  private validateFile() {
    if (this.fileSize > this.maxFileSizeSupported) {
      this.messageText = 'Upload file is too big, maximum allowed size is ' + this.getFileSizeDisplayString(this.maxFileSizeSupported);
      this.messageType = 'ERROR'
      this.uploadFinished.emit({
        status: -2,
        message: this.messageText
      });
      return false;
    } else if (!this.supportedFileTypes.includes(this.fileName?.split('.').pop() as string)) {
      this.messageText = 'Unsupported file type, only ' + this.supportedFileTypes + ' are supported';
      this.messageType = 'ERROR'
      this.uploadFinished.emit({
        status: -3,
        message: this.messageText
      });
      return false;
    }
    return true;
  }

  private async loadFile() {
    let uploadedChunks: Blob[] = [];
    let percentagePerChunk = this.calculatePercentagePerChunk(this.fileSize);
    let currentProgress = 0;
    for (let offset = 0; offset < this.fileSize; offset += this.chunkSize) {
      const chunk = this.fileToBeUploaded!.slice(offset, offset + this.chunkSize);
      uploadedChunks.push(chunk);
      this.uploadedSize += chunk.size;
      currentProgress += percentagePerChunk;
      this.progress = this.math.floor(currentProgress)
      if (this.fileSize == this.uploadedSize) {
        this.progress = 100;
      }
    }

    return new File(uploadedChunks, this.fileName!);
  }

  private calculatePercentagePerChunk(fileSize: number) {
    return (99 * this.chunkSize) / fileSize;
  }
}
