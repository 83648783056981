<div class="full-height-container">
  <mat-card class="full-height-container">
    <mat-card-content class="content-panel full-height-container">
      <div class="flex-row">
        <app-component-header (refreshClickEvent)="this.refreshView()" [hideColumnsButton]="true" [startRefreshTimer]="this.refreshComplete"
                              [subtitleSegments]="getUrlSegments()"
                              class="full-width">
        </app-component-header>
      </div>
      <div class="flex-row table-container">
        <ag-kpi-sidebar [sidebar]="kpiSidebar" class="mg-r-s"></ag-kpi-sidebar>
        <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                         (filterChanged)="onFilterChanged($event)"
                         (firstDataRendered)="onFirstDataRendered($event)"
                         (gridReady)="onGridReady($event)"
                         (rowSelected)="onRowSelected($event)"
                         (viewportChanged)="onViewportChanged($event)"
                         [columnDefs]="tableColumns"
                         [gridOptions]="baseAgGridOptions"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         [rowData]="dataSource"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz ag-grid-flex">
        </ag-grid-angular>

        <ng-template #footerActionButtonPanel>
          <button (click)="updateScheduleStatus(true)" [disabled]="!enableScheduleBulkAction()" class="mg-r-s" color="primary" mat-raised-button>
            <mat-icon class="material-symbols-outlined">timer</mat-icon>
            Schedule
          </button>
          <button (click)="updateScheduleStatus(false)" [disabled]="!enableUnScheduleBulkAction()" color="primary" mat-raised-button>
            <mat-icon class="material-symbols-outlined">timer_off</mat-icon>
            Un-Schedule
          </button>
        </ng-template>
      </div>
    </mat-card-content>
  </mat-card>

</div>
<ag-side-panel #runParametersSidePanel size="xlarge" subTitle="Apply the default run parameters" title="Default Run Parameters">
  <app-transfer-config-detail (onScheduleDetailChange)="refreshView()" [readonly]="isReadOnly" [transferCode]="selectedTransferCode"></app-transfer-config-detail>
</ag-side-panel>
