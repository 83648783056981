import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {FeatureComponentMode} from "@data/enums/data.enums";
import {ActivatedRoute} from "@angular/router";
import {TaskSidebarItem} from "@shared/ag-task/ag-task.component.ds";
import {BaseComponent} from "@pages/base.component";
import {VerbatimFileInfo} from "@data/interefaces/verbatim.file.interfaces";

@Component({
  selector: 'app-verbatim-file-task',
  templateUrl: './verbatim-file-task.component.html',
  styleUrls: ['./verbatim-file-task.component.scss']
})
export class VerbatimFileTaskComponent extends BaseComponent implements AfterViewInit {
  featureComponentSidebarEl: TaskSidebarItem[] = [
    {
      title: "Upload New File",
      icon: "fa-plus",
      selected: false,
      urlSupport: true,
      disabled: false,
      mode: FeatureComponentMode.UPLOAD
    },
    {
      title: "View File Contents",
      icon: "fa-pen-to-square",
      selected: true,
      urlSupport: true,
      disabled: true,
      mode: FeatureComponentMode.DETAIL
    }
  ];
  @Input() selectedFeatureMode: FeatureComponentMode | undefined;
  @Input() selectedRows!: VerbatimFileInfo[];
  @Input() preSelectedFeatureMode!: FeatureComponentMode;
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  isUrlMode: boolean = false;
  urlModeGoBackUrl: string = "/data-management/verbatim/file";
  protected readonly FeatureComponentMode = FeatureComponentMode;

  constructor(protected _activatedRoute: ActivatedRoute) {
    super();
    let paramMode = this._activatedRoute.snapshot.params['mode'];
    if (paramMode) {
      this.isUrlMode = true;
      this.selectedFeatureMode = paramMode;
    }
  }

  ngAfterViewInit(): void {
  }
}
