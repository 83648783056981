<div class="action-column flex-row flex-g-s">
  <ng-container *ngIf="(this.params.value?.toString()?.trim() === YesNoValue.YES_VALUE || this.params.value === true); else falseCondition">
    <i [ngClass]="'fa-sharp fa-light ' + this.successIcon + ' ' + iconSize + ' ' + this.successColor"></i>
  </ng-container>
  <ng-template #falseCondition>
    <ng-container *ngIf="(this.params.value?.toString()?.trim() === '' || this.params.value === false); else elseCondition">
      <i [ngClass]="'fa-sharp fa-light ' + this.failIcon + ' ' + iconSize + ' ' + this.failColor"></i>
    </ng-container>
  </ng-template>
  <ng-template #elseCondition>
    <ng-container>
      {{ this.params.value }}
    </ng-container>
  </ng-template>
</div>

