import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'ag-skeleton',
  templateUrl: './ag-skeleton.component.html',
  styleUrls: ['./ag-skeleton.component.scss']
})
export class AgSkeletonComponent implements OnChanges {

  @Input("uiType")
  uiType: 'grid' | undefined;
  numberOfRows: number[] | undefined;
  numberOfColumns: number [] | undefined;

  ngOnChanges(changes: SimpleChanges) {
    let currentValue = changes['uiType'].currentValue;
    if (currentValue) {
      if (currentValue.startsWith('grid')) {
        let split = currentValue.split('-')[1].split('x');
        this.uiType = 'grid';
        this.numberOfRows = Array(parseInt(split[0])).fill(1);
        this.numberOfColumns = Array(parseInt(split[1])).fill(2);
      }
    }
  }

}
