import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Util} from "@data/util/util";
import {pageContext, surveyCodes, fileNames, tableColumns} from "@pages/verbatim-file-rows/verbatim-file-rows.component.ds";
import {TableBaseComponent} from "@pages/table.base.component";
import {FilterChangedEvent, FirstDataRenderedEvent} from 'ag-grid-community';
import {GridColumn, GridColumnGroupDef, ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {VerbatimFileRowInfo} from "@data/interefaces/verbatim.file.interfaces";
import {VerbatimRowService} from "@data/verbatim-row/verbatim-row.service";
import {VerbatimService} from "@data/verbatim/verbatim.service";

@Component({
  selector: 'app-verbatim-file-rows',
  templateUrl: './verbatim-file-rows.component.html',
  styleUrls: ['./verbatim-file-rows.component.scss']
})

export class VerbatimFileRowsComponent extends TableBaseComponent implements AfterViewInit {

  rowData: VerbatimFileRowInfo[] = [];
  auxLinks: Map<string, string>;
  showSkeleton: boolean = true;
  refreshComplete: boolean = true;
  isFilterActive: boolean = false;

  protected readonly util = Util;
  protected readonly pageContext = pageContext;

  protected override readonly tableColumns: (GridColumn | GridColumnGroupDef)[];

  constructor(private _activatedRoute: ActivatedRoute,
              private _verbatimRowService: VerbatimRowService,
              private _verbatimService: VerbatimService,
  ) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
    this.tableColumns = this._agGridService.createTableColumnsCopy(tableColumns)
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    this.loadFilterData();
    this.updatePageContext();
  }

  ngAfterViewInit() {
  }

  onLiveDataFilterChange(forceReload: boolean = false): void {
    if (forceReload) {
      this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    }
    this.updateToPageContextUrl();
  }

  updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }

  async loadPageData() {
    const verbatimFileNames = this.pageContext.vf_f || '';
    const minDateRange = this.pageContext.fu_r || '';
    const minDate = minDateRange.split('|')[0] || '';
    const surveyCodes = this.pageContext.sc_f || '';
    let thresholds: ResultsetThresholds | undefined;
    this.refreshComplete = false;
    this.displayProgressBar(true);
    await this._verbatimRowService.getVerbatimFileRowThresholds(verbatimFileNames, minDate, surveyCodes).then(result => {
      thresholds = result;
      this.setRowTemplateMessage(thresholds);
    }).catch(error => {
        this.displayNotificationMessage('error', JSON.stringify(error));
        this.displayProgressBar(false);
        this.refreshComplete = true;
      }
    );

    if (thresholds && !thresholds.hasTooMany) {
      await this._verbatimRowService.getVerbatimFileRowsByFilter(verbatimFileNames, minDate, surveyCodes).then(result => {
        this.rowData = result;
      }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
      ).finally(() => {
          this.displayProgressBar(false);
          this.refreshComplete = true;
        }
      );
    } else {
      this.refreshComplete = true;
      this.rowData = [];
      this.displayProgressBar(false);
    }
  }

  loadFilterData() {
    this._verbatimService.getSurveys().then(result => {
      result.forEach(item => surveyCodes.push(item.surveyCode));
    });
    this._verbatimService.getFileNames().then(result => {
      result.listElements.forEach(item => {
        fileNames.push(item.value)
      });
    });
  }

  async refreshView() {
    await this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
  }

  goBack() {
    this.refreshView();
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    const updateLiveDataFilter = this.isUpdateLiveDataFilterRequired(event.columns);
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this)).then(() => {
      if (updateLiveDataFilter) {
        this.onLiveDataFilterChange(true);
      }
    });
  }

  protected override onFirstDataRendered(event: FirstDataRenderedEvent<any>) {
    super.onFirstDataRendered(event);
    event.api.autoSizeAllColumns();
  }
}
