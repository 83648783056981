import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {SelectHeaderComponent} from "@pages/segments/grid/columns/select-header/select-header.component";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";

let isUserAdmin: boolean = false;
let thresholdCount: number = 0;
let maxDownloadRequestExpediteCount: number = 0;
let currentMarketExpediteCount: number = 0;
export const expediteClickAction = 'expedite_link_click';

export function setIsUserAdmin(isAdmin: boolean) {
  isUserAdmin = isAdmin;
}

export function setThresholdCount(count: number) {
  thresholdCount = count;
}

export function setMaxDownloadRequestExpediteCount(count: number) {
  maxDownloadRequestExpediteCount = count;
}

export function setCurrentMarketExpediteCount(count: number) {
  currentMarketExpediteCount = count;
}

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    maxWidth: 75,
    field: 'select',
    headerName: '',
    hide: false,
    filter: false,
    headerComponent: SelectHeaderComponent,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    pinned: "left",
    suppressColumnsToolPanel: true,
    downloadable: false,
    cellRenderer: SelectColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        showFlag: false,
        flagIcon: "fa-exclamation",
        flagColor: "red",
        toolTip: 'Invalid'
      }
    }
  },
  {
    headerName: 'Your Requests',
    valueFormatter: '',
    headerValueGetter: (params) => {
      const headerText = 'Your Requests';
      const rowCount = params.api.getDisplayedRowCount();
      const selectedItemsCount = params.api.getSelectedRows()?.length;
      const selectedCountText = selectedItemsCount > 0 ? `${headerText} (${selectedItemsCount} of ${rowCount} selected)` : headerText;
      const tooManyRequestsText = `Warning - You've selected more than ${thresholdCount} requests.  Please limit this to under ${thresholdCount}.`
      return selectedItemsCount > thresholdCount ? `${selectedCountText} - ${tooManyRequestsText}` : selectedCountText;
    },
    headerClass: (params) => {
      const selectedItemsCount = params.api.getSelectedRows()?.length;
      return selectedItemsCount > thresholdCount ? 'error-border-solid error-color' : '';
    },
    children: [
      {
        field: "batchCode",
        headerName: "Batch Code",
        hide: false
      },
      {
        field: 'priority',
        headerName: 'Expedite',
        maxWidth: 100,
        hide: false,
        cellRenderer: ActionColumnComponent,
        cellRendererParams: function (params: any) {
          const disableIcon = (params.data?.priority === 5 && currentMarketExpediteCount >= maxDownloadRequestExpediteCount && !isUserAdmin);
          const rowMarketCode = params.data.country;
          const icon = 'fa-toggle-on';
          return {
            actions: [
              {
                toolTip: (params.data?.priority === 5 && currentMarketExpediteCount >= maxDownloadRequestExpediteCount && !isUserAdmin) ?
                  `Current expedite limit of ${maxDownloadRequestExpediteCount} for ${rowMarketCode} reached (${currentMarketExpediteCount}).`
                  : params.data?.priority === 1 ? 'Downgrade Request' : 'Expedite Request',
                icon: params.data?.priority === 1 ? icon : `${icon} fa-flip-horizontal`,
                iconStyle: 'fa-solid',
                actionClass: params.data?.priority === 1 ? 'green-color' : 'grey-color',
                name: 'Expedite Request',
                disabled: disableIcon,
                actionEvent: expediteClickAction
              }
            ]
          }
        }
      },
      {
        field: 'highlightSegments', headerName: 'Highlight Segments?', hide: true, suppressColumnsToolPanel: true
      },
      {
        field: 'period', headerName: 'Period', hide: true
      },
      {
        field: 'marketCode', headerName: 'Market', hide: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.marketLabel
          }
        }
      },
      {
        field: 'industryCode', headerName: 'Industry Code', hide: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.industryLabel
          }
        }
      },
      {
        field: 'surveySubject', headerName: 'Survey Subject', hide: true
      },
      {
        field: 'language', headerName: 'Language', hide: true
      },
      {
        field: 'weightedProductLabel', headerName: 'Product', hide: false
      },
      {field: 'sponsor', headerName: 'Sponsor Code', hide: true, suppressColumnsToolPanel: true, suppressFiltersToolPanel: true},
      {
        field: 'sponsorLabel', headerName: 'Sponsor', hide: false,
        valueFormatter: params => `${params.data.sponsorCode} - ${params.data.sponsorLabel}`
      },
      {
        field: 'respondentCompanyCode', headerName: 'Respondent Company Code', hide: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.respondentCompanyLabel
          }
        }
      },
      {
        field: 'respondentCompanyLabel', headerName: 'Respondent Company Label', hide: false,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.respondentCompanyCode
          }
        }
      },
      {
        field: 'automatedReportName', headerName: 'Download Type Version', hide: false
      },
      {
        field: 'downloadRequestTypeValue', headerName: 'Download Type', hide: true
      }
    ]
  },
  {
    headerName: 'Latest Results', valueFormatter: '', children: [
      {
        field: 'status', headerName: 'Status', hide: false,
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'message', headerName: 'Message', hide: false,
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.message,
            type: 'text',
            title: 'Message'
          };
        }
      },
      {
        field: 'link', headerName: 'Link', hide: false,
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.link,
            type: 'link'
          };
        }
      }
    ]
  }
]
