import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {BaseComponent} from "@pages/base.component";
import {OperationStatus, TransferConfig} from "@data/interefaces/data.interfaces";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TransferConfigService} from "@data/transfer-config/transfer.config.service";
import {environment} from "@environments/environment";
import {debounceTime} from "rxjs";

@Component({
  selector: 'app-transfer-config-detail',
  templateUrl: './transfer-config-detail.component.html',
  styleUrls: ['./transfer-config-detail.component.scss']
})
export class TransferConfigDetailComponent extends BaseComponent implements OnChanges {

  @Input() transferCode: string | undefined;
  @Input() readonly: boolean = false;
  @Output() onScheduleDetailChange: EventEmitter<boolean> = new EventEmitter();
  formGroup: FormGroup;
  scheduleFormControl: FormControl<any>;
  scheduleDetail: TransferConfig | undefined;

  showSkeleton: boolean = true;

  constructor(private _transferConfigService: TransferConfigService) {
    super();
    this.scheduleFormControl = new FormControl([Validators.required]);
    this.formGroup = new FormGroup<any>([this.scheduleFormControl]);
    this.scheduleDetail = undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['transferCode'].currentValue) {
      this.showSkeleton = true;
      this._transferConfigService.getTransferConfiguration(changes['transferCode'].currentValue).then(result => {
        this.scheduleDetail = result;
        this.showSkeleton = false;
      });
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    this.scheduleDetail = undefined;
    this.scheduleFormControl.valueChanges.pipe(debounceTime(environment.debounceTime)).subscribe((value) => {
      this.onScheduleChange(value).then(() => {
      });
    });
  }

  onToggleChanged(event: MatSlideToggleChange) {
    this.updateScheduleStatus(event.checked);
  }

  updateScheduleStatus(status: boolean) {
    if (this.scheduleDetail?.code !== undefined) {
      let promises: Promise<OperationStatus>[] = [];
      this.displayProgressBar(true);
      promises.push(this._transferConfigService.updateStatus(this.scheduleDetail?.code, status));
      Promise.all(promises).then(r => {
        this.displayNotificationMessage('success', 'record saved');
        this.onScheduleDetailChange.emit(true);
      }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)))
        .finally(() => this.displayProgressBar(false));
    }
  }

  async onScheduleChange(cronSchedule: string) {
    if (this.scheduleDetail) {
      let update = false;
      await this._transferConfigService.validateCronSchedule(this.scheduleDetail?.code, cronSchedule).then(result => {
        if (!result.status) {
          this.scheduleFormControl.setErrors({validationError: "Validation failed."});
        } else if (this.scheduleDetail!.schedule.trim() !== cronSchedule.trim()) {
          update = true;
        }
      }).catch(error => this.scheduleFormControl.setErrors({validationError: (error as Error).message}));

      if (update) {
        await this._transferConfigService.updateCronSchedule(this.scheduleDetail?.code, cronSchedule.trim()).then(result => {
          this.displayNotificationMessage('success', 'record saved');
          this.onScheduleDetailChange.emit(true);
        }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
      }
    }
  }

  onDefaultRunConfigurationValueChange(eventData: { field: string, value: any }) {
    if (this.scheduleDetail?.defaultRunConfiguration !== undefined) {
      const runConfigDataObject = JSON.parse(this.scheduleDetail.defaultRunConfiguration);
      const runConfig = runConfigDataObject.find((config: any) => config.fieldId === eventData.field);
      if (!runConfig) {
        throw new Error(`Invalid fieldId ${eventData.field}`);
      }
      if (runConfig.value?.toString() !== eventData.value.toString()) {
        // Value changed, updated
        runConfig.value = eventData.value;
        this.updateRunConfiguration(this.scheduleDetail.code, eventData.field, eventData.value);
      }
    }
  }

  updateRunConfiguration(transferCode: string, fieldId: string, value: string) {
    this._transferConfigService.updateRunConfiguration(transferCode, fieldId, value).then(result => {
      if (!result.status) {
        this.displayNotificationMessage('warning', 'Failed to save run configuration.');
      } else {
        this.displayNotificationMessage('success', 'record saved');
        this.onScheduleDetailChange.emit(true);
      }
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
  }
}
