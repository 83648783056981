<div class="flex-row full-width pd-m">
  <ag-datablock [horizontalAlign]="true" data="{{selectedRespondentGroup?.[0]?.period}}" title="Period"></ag-datablock>
  <ag-datablock [horizontalAlign]="true" data="{{selectedRespondentGroup?.[0]?.marketCode}}" title="Market Code"></ag-datablock>
  <ag-datablock [horizontalAlign]="true" data="{{selectedRespondentGroup?.[0]?.industryCode}}" title="Industry Code"></ag-datablock>
  <ag-datablock [horizontalAlign]="true" data="{{selectedRespondentGroup?.[0]?.surveySubject}}" title="Survey Subject"></ag-datablock>
</div>
<ag-drawer [hide]="true" id="product-assignment">
  <mat-list left>
    <!--    <mat-list-item (click)="selectSubComponent('trend')" [class.selected-sub-menu]="subComponentCode == 'trend'" class="point-cursor">-->
    <!--      <div matListItemIcon>-->
    <!--        <i class="fa-sharp fa-light fa-arrow-trend-up fa-xl accent-color"></i>-->
    <!--        <span class="list-image-label">Trend</span>-->
    <!--      </div>-->
    <!--      <span data-decorator matListItemTitle>Trent Alignments</span>-->
    <!--      <span data-decorator matListItemLine>Second line</span>-->
    <!--    </mat-list-item>-->
    <!--    <mat-divider></mat-divider>-->
    <!--    <mat-list-item (click)="selectSubComponent('res_company')" [class.selected-sub-menu]="subComponentCode == 'res_company'" class="point-cursor">-->
    <!--      <div matListItemIcon>-->
    <!--        <i class="fa-sharp fa-light fa-buildings fa-xl accent-color"></i>-->
    <!--        <span class="list-image-label">Comp</span>-->
    <!--      </div>-->
    <!--      <span data-decorator matListItemTitle>Respondent Companies</span>-->
    <!--      <span data-decorator>Manage Company Respondents</span>-->
    <!--    </mat-list-item>-->
    <!--    <mat-divider></mat-divider>-->
    <!--    <mat-list-item (click)="selectSubComponent('res_rollup')" [class.selected-sub-menu]="subComponentCode == 'res_rollup'" class="point-cursor">-->
    <!--      <div matListItemIcon>-->
    <!--        <i class="fa-sharp fa-light fa-scroll-old fa-xl accent-color"></i>-->
    <!--        <span class="list-image-label">Rollup</span>-->
    <!--      </div>-->
    <!--      <span data-decorator matListItemTitle>Respondent Rollups</span>-->
    <!--      <span data-decorator>Manage Respondent Rollup</span>-->
    <!--    </mat-list-item>-->
    <!--    <mat-divider></mat-divider>-->
    <!--    <mat-list-item (click)="selectSubComponent('comp_weight_set')" [class.selected-sub-menu]="subComponentCode == 'comp_weight_set'" class="point-cursor">-->
    <!--      <div matListItemIcon>-->
    <!--        <i class="fa-sharp fa-light fa-weight-hanging fa-xl accent-color"></i>-->
    <!--        <span class="list-image-label">Weight Set</span>-->
    <!--      </div>-->
    <!--      <span data-decorator matListItemTitle>Company Weight Sets</span>-->
    <!--      <span data-decorator>Second line</span>-->
    <!--    </mat-list-item>-->
    <!--    <mat-divider></mat-divider>-->
    <!--    &lt;!&ndash;    <mat-list-item (click)="selectSubComponent('comp_weight')" [class.selected-sub-menu]="subComponentCode == 'comp_weight'" class="point-cursor">&ndash;&gt;-->
    <!--    &lt;!&ndash;      <div matListItemIcon>&ndash;&gt;-->
    <!--    &lt;!&ndash;        <i class="fa-sharp fa-light fa-dumbbell fa-xl accent-color"></i>&ndash;&gt;-->
    <!--    &lt;!&ndash;        <span class="list-image-label">Com Weight</span>&ndash;&gt;-->
    <!--    &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--    &lt;!&ndash;      <span data-decorator matListItemTitle>Company Weights</span>&ndash;&gt;-->
    <!--    &lt;!&ndash;      <span data-decorator>Second line</span>&ndash;&gt;-->
    <!--    &lt;!&ndash;    </mat-list-item>&ndash;&gt;-->
    <!--    <mat-divider></mat-divider>-->
    <!--    <mat-list-item (click)="selectSubComponent('rollup_weight')" [class.selected-sub-menu]="subComponentCode == 'rollup_weight'" class="point-cursor">-->
    <!--      <div matListItemIcon>-->
    <!--        <i class="fa-sharp fa-light fa-scale-balanced fa-xl accent-color"></i>-->
    <!--        <span class="list-image-label">Rollup Weight</span>-->
    <!--      </div>-->
    <!--      <span data-decorator matListItemTitle>Rollup Weights</span>-->
    <!--      <span data-decorator>Second line</span>-->
    <!--    </mat-list-item>-->
  </mat-list>
  <div [ngSwitch]="subComponentCode" right>
    <!--    <app-trent-alignment *ngSwitchCase="'trend'"></app-trent-alignment>-->
    <!--    <app-respondent-company *ngSwitchCase="'res_company'"></app-respondent-company>-->
    <!--    <app-respondent-rollup *ngSwitchCase="'res_rollup'"></app-respondent-rollup>-->
    <!--    <app-company-weight-set *ngSwitchCase="'comp_weight_set'"></app-company-weight-set>-->
    <!--    &lt;!&ndash;    <app-company-weight *ngSwitchCase="'comp_weight'"></app-company-weight>&ndash;&gt;-->
    <!--    <app-rollup-weight *ngSwitchCase="'rollup_weight'"></app-rollup-weight>-->
  </div>
</ag-drawer>
