import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, inject, OnInit} from "@angular/core";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'ag-dialog',
  templateUrl: 'ag-dialog.component.html',
})
export class AgDialogComponent implements OnInit{
  readonly data = inject(MAT_DIALOG_DATA);

  constructor(private _clipboard: Clipboard) {
  }

  ngOnInit(): void {
    if (this.data && this.data.isJson) {
      this.data.message = JSON.stringify(JSON.parse(this.data.message), null, 2)
    }
  }

  protected onCopyClipboardClick(textToCopy: string | undefined) {
    if (textToCopy !== undefined) {
      this._clipboard.copy(textToCopy);
    }
  }
}

