<div class="{{'full-height-container'}}">
  <mat-card class="full-height-container">
    <mat-card-content class="full-height-container content-panel">
      <div class="flex-row">
        <app-component-header (downloadClickEvent)="this.handleDownloadClickEvent('verbatim-file-rows')"
                              (refreshClickEvent)="this.refreshView()"
                              [redirectTo]="'verbatimFileRows'"
                              [hideColumnsButton]="true"
                              [hideUploadButton]="true"
                              [startRefreshTimer]="this.refreshComplete"
                              [subtitleSegments]="getUrlSegments()"
                              [uploadUrlParam]="{mode: 'upload'}"
                              [uploadUrl]="['task', 'upload']"
                              class="full-width"></app-component-header>
      </div>
      <div class="flex-row table-container">
        <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                         (filterChanged)="onFilterChanged($event)"
                         (firstDataRendered)="onFirstDataRendered($event)"
                         (gridReady)="onGridReady($event)"
                         (viewportChanged)="onViewportChanged($event)"
                         [columnDefs]="tableColumns"
                         [gridOptions]="baseAgGridOptions"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         [rowData]="rowData"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz full-width">
        </ag-grid-angular>
        <ng-template #footerActionButtonPanel>
          <button [routerLink]="['task', 'add']" class="white-font-color mg-r-m" color="primary" mat-raised-button>
            <i class="fa-sharp fa-light fa-add"></i>
            Add Row
          </button>
        </ng-template>
      </div>
    </mat-card-content>
  </mat-card>
</div>
