<div [showSkeleton]="loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <mat-card *ngIf="!loading" class="task-component-detail backdrop" id="task-component-detail">
    <mat-card-header class="mg-b-m">
      <div class="header-title">
        <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
        <mat-card-subtitle>Open Link Invitees</mat-card-subtitle>
      </div>
      <div *ngIf="isEditMode" class="url-container flex-row mg-t-s">
        <label class="url-label mg-r-s">Invitee URL</label>
        <div>
          <a [href]="inviteeUrl.value" class="url-link" target="_blank">{{ inviteeUrl.value }}</a>
        </div>
        <i *ngIf="inviteeUrl.value" [cdkCopyToClipboard]="inviteeUrl.value" class="fa-sharp fa-link-simple url-link-icon mg-l-s"></i>
      </div>
      <div *ngIf="isEditMode" class="flex-row mg-l-s mg-t-s">
        <ag-slide-toggle (toggleUpdate)="util.setToggleValue($event.checked, this.getFormControl('invalid'))"
                         [colorType]="'error'"
                         [isChecked]="util.isToggleChecked(this.getFormControl('invalid'))"
                         [isDisabled]="!getSurveyCodeEditRule()"
                         class="slide-toggle"></ag-slide-toggle>
        <span class="mg-l-s">Invalid?</span>
      </div>
    </mat-card-header>
    <mat-card-content class="form-container">
      <form (ngSubmit)="this.submitForm(this.saveAndClose, 'Open Link Invitees Saved.')" [formGroup]="formGroup">
        <div *ngIf="this.isEditMode" class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('period')}}" title="Period"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('marketCode') + ' ' + getFormControlValue('marketName')}}" title="Market"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('industryCode') + ' ' + getFormControlValue('industryName')}}" title="Industry"></ag-datablock>
          </div>
          <div class="view-only-block">
            <label>Survey Subject</label>
            <mat-chip-listbox aria-label="Status" class="mat-mdc-chip-set-stacked" disabled="true">
              <div class="flex-row">
                <mat-chip-option
                  [selected]="isSurveySubjectSelected(SurveySubject.SUPPLIER)"
                  color="accent"
                  id="ss_s">{{ SurveySubject.SUPPLIER }}
                </mat-chip-option>
                <mat-chip-option
                  [selected]="isSurveySubjectSelected(SurveySubject.RETAILER)"
                  color="accent"
                  id="ss_r">{{ SurveySubject.RETAILER }}
                </mat-chip-option>
              </div>
            </mat-chip-listbox>
          </div>
        </div>
        <div [ngClass]="{'mg-b-m': isEditMode}" class="flex-row view-only-container">
          <mat-form-field *ngIf="!isEditMode">
            <mat-label>Survey Code</mat-label>
            <mat-select formControlName="surveyCode">
              <mat-option (click)="onSurveyCodeSelected()"
                          *ngFor="let surveyCode of surveyCodeList"
                          [value]="surveyCode.surveyCode"
              >{{ surveyCode.surveyCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="isEditMode" class="view-only-block">
            <ag-datablock data="{{formGroup.get('surveyCode')?.value}}" title="Survey Code"></ag-datablock>
          </div>
          <div *ngIf="isEditMode" class="view-only-block">
            <ag-datablock data="{{formGroup.get('openLinkCode')?.value}}" title="Open Link Code"></ag-datablock>
          </div>
          <div *ngIf="isEditMode" class="view-only-block">
            <ag-datablock data="{{formGroup.get('remoteRespId')?.value}}" title="Remote Resp Id"></ag-datablock>
          </div>
          <div *ngIf="isEditMode" class="view-only-block">
            <ag-datablock data="{{formGroup.get('personCode')?.value}}" title="Person Code"></ag-datablock>
          </div>
          <div *ngIf="isEditMode" class="view-only-block">
            <ag-datablock data="{{formGroup.get('responseCode')?.value}}" title="Response Code"></ag-datablock>
          </div>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>Invitation Company</mat-label>
            <mat-select formControlName="invitationCompanyCode">
              <mat-option *ngFor="let invitationCompanyCode of invitationCompanyCodeList"
                          [value]="invitationCompanyCode.respondentCompanyRollupCode"
              >
                {{ invitationCompanyCode.respondentCompanyRollupCode + " " + invitationCompanyCode.respondentCompanyRollupLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Invitation Divisions</mat-label>
            <mat-select [multiple]="true" formControlName="invitationDivisionCodes">
              <mat-option *ngFor="let invitationDivisionCodes of invitationDivisionCodesList"
                          [value]="invitationDivisionCodes.respondentCompanyRollupCode">
                {{ invitationDivisionCodes.respondentCompanyRollupCode + ' ' + invitationDivisionCodes.respondentCompanyDivisionCode + ' ' + invitationDivisionCodes.respondentCompanyDivisionLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="isEditMode" class="status-container mg-b-m">
          <label>Status</label>
          <mat-chip-listbox aria-label="Status" class="mat-mdc-chip-set-stacked" disabled="true">
            <div class="flex-row">
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.NOT_STARTED)"
                color="accent"
                id="rs_ns">{{ ResponseStatus.NOT_STARTED }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.IN_PROGRESS)"
                color="accent"
                id="rs_ip">{{ ResponseStatus.IN_PROGRESS }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.ERROR)"
                color="accent"
                id="rs_e">{{ ResponseStatus.ERROR }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.COMPLETE)"
                color="accent"
                id="rs_c">{{ ResponseStatus.COMPLETE }}
              </mat-chip-option>
              <mat-chip-option
                [selected]="isResponseStatusSelected(ResponseStatus.SCREENED)"
                color="accent"
                id="rs_s">{{ ResponseStatus.SCREENED }}
              </mat-chip-option>
            </div>
          </mat-chip-listbox>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input formControlName="firstName" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input formControlName="lastName" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Telephone</mat-label>
            <input formControlName="telephone" matInput>
          </mat-form-field>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>Cell Phone</mat-label>
            <input formControlName="cellPhone" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>City</mat-label>
            <input formControlName="city" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Job Title</mat-label>
            <input formControlName="jobTitle" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Survey Language</mat-label>
            <mat-select formControlName="surveyLanguage">
              <mat-option *ngFor="let surveyLanguage of surveyLanguageList"
                          [value]="surveyLanguage.language">
                {{ surveyLanguage.language }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex-row custom-container">
          <div class="custom-right-container">
            <mat-form-field>
              <mat-label>Nomination Notes</mat-label>
              <textarea formControlName="nominationNotes" matInput></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-row">
          <mat-form-field>
            <mat-label>Nominated By</mat-label>
            <mat-select [multiple]="true" formControlName="nominatedByCodes">
              <mat-option *ngFor="let buCategories of buCategoriesList"
                          [value]="buCategories.buCategoryCode">
                {{ buCategories.buCategoryCode + ' ' + buCategories.buCategoryLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Mailout Template</mat-label>
            <mat-select formControlName="mailoutTemplateCode">
              <mat-option *ngFor="let mailoutTemplateCode of mailoutTemplateCodeList"
                          [value]="mailoutTemplateCode.mailoutTemplateCode">
                {{ mailoutTemplateCode.mailoutTemplateCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex-row">
          <ng-container *ngIf="getFormControl('mailoutDatetime')">
            <ag-datetimepicker [control]="getFormControl('mailoutDatetime')" [label]="'Mailout Datetime'" class="mat-mdc-form-field"></ag-datetimepicker>
          </ng-container>
          <mat-form-field>
            <mat-label>Mailout Field Set</mat-label>
            <mat-select formControlName="mailoutFieldSetCode">
              <mat-option *ngFor="let mailoutFieldsetCode of mailoutFieldsetCodeList"
                          [value]="mailoutFieldsetCode.mailoutFieldsetCode">
                {{ mailoutFieldsetCode.mailoutFieldsetCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="isEditMode" class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('remoteTaskId')?.value}}" title="Remote Task IDs"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('mailoutHistory')?.value}}" title="Mailout History"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('firstMailingDate')?.value}}" title="First Mailing Date"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('lastMailingDate')?.value}}" title="Last Mailing Date"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('timesMailed')?.value}}" title="Times Mailed"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('surveyId')?.value}}" title="Survey Id"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{formGroup.get('surveyInviteeId')?.value}}" title="Invitation Id"></ag-datablock>
          </div>
        </div>
        <div class="flex-row flex-g-s action-buttons">
          <button (click)="this.onSaveAndCloseClick()" [disabled]="!this.hasAnyEdits()" color="primary" mat-raised-button type="submit">
            <mat-icon class="material-symbols-outlined">save</mat-icon>
            Save and Close
          </button>
          <button (click)="resetForm()" *ngIf="isEditMode" [disabled]="!this.hasAnyEdits()" mat-button type="button">
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
            Reset
          </button>
          <button (click)="resetForm(); close()" *ngIf="hasAnyEdits()" [disabled]="!this.hasAnyEdits()" mat-button type="button">
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
            Cancel
          </button>
          <button (click)="resetForm(); close()" *ngIf="!hasAnyEdits()" [disabled]=" this.hasAnyEdits()" class="primary-font-color" mat-button type="button">
            <i class="fa-sharp fa-light fa-xmark-large"></i> Close
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
