import {GridColumn, GridColumnGroupDef} from "../../../data/interefaces/data.interfaces";
import {ColumnFilterType} from "../../../data/enums/data.enums";
import {MultiLineLinkColumnComponent} from "src/app/pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {StatusColumnComponent} from "src/app/pages/segments/grid/columns/status-column/status-column.component";
import {LongDataColumnComponent} from "src/app/pages/segments/grid/columns/long-data-column/long-data-column.component";

export const subsystemCodeClickAction: string = 'subsystem_code_link_click';

export let pageContext: {
  [key: string]: boolean | string | undefined
  cn_f: string | undefined;
  s_f: string | undefined;
} = {
  cn_f: undefined,
  s_f: undefined,
};

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [

  {
    field: 'componentName',
    headerName: 'Sub-system',
    hide: false,
    filterId: 'cn_f',
    autoHeight: true,
    minWidth: 300,
    filter: ColumnFilterType.TEXT,
    filterParams: {defaultToNothingSelected: true},
    cellRenderer: MultiLineLinkColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        value: params.data.componentName,
        actionEvent: subsystemCodeClickAction
      };
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    hide: false,
    filterId: 's_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true},
    cellRenderer: StatusColumnComponent
  },
  {
    field: 'details',
    headerName: 'Details',
    hide: true,
    minWidth: 400,
    filter: false,
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: JSON.stringify(params.data.details),
        type: 'json'
      };
    }

  }

]
