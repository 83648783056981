import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {YesNoUndeterminedValue} from "@data/enums/data.enums";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'ag-three-state-selector',
  templateUrl: './ag-three-state-selector.html',
  styleUrls: ['./ag-three-state-selector.scss']
})
export class AgThreeStateSelector implements OnChanges {
  @Input() isDisabled: boolean = false;

  // Previously, this variable was named formControl, this is not allowed, as it would cause an NG01203 error
  @Input({ required: true }) formCtrl!: FormControl;

  @Input() label: string | '' = 'Include / Exclude';

  @Input() undeterminedValue: string = YesNoUndeterminedValue.UNDETERMINED_VALUE;
  @Input() undeterminedLabel: string = 'No Change';
  @Input() undeterminedIcon: string = '';
  @Input() showUndeterminedValue: boolean = true;

  @Input() yesValue: string = YesNoUndeterminedValue.YES_VALUE;
  @Input() yesLabel: string = 'Include All Selected';
  @Input() yesIcon: string = 'fa-arrow-right-to-arc primary-color';
  @Input() showYesValue: boolean = true;

  @Input() noValue: string = YesNoUndeterminedValue.NO_VALUE;
  @Input() noLabel: string = 'Exclude All Selected';
  @Input() noIcon: string = 'fa-arrow-left-to-arc error-color';
  @Input() showNoValue: boolean = true;

  @Output() onSelected: EventEmitter<MatSelectChange> = new EventEmitter();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isDisabled'] && changes['isDisabled'].currentValue) {
      this.formCtrl.disable();
    } else if (changes['isDisabled'] && !changes['isDisabled'].currentValue) {
      this.formCtrl.enable();
    }
  }

  onSelectionChange(event: MatSelectChange) {
    this.onSelected.emit(event);
  }

}
