<div class="download-request-container">
  <div
    [ngClass]="{'selection-product-and-sponsors-column': this.selectedDownloadType?.requiresRespondents, 'full-column-span': !this.selectedDownloadType?.requiresRespondents}"
    class="select-row select-row-position">
    <ag-checkbox-tree (selectionChanged)="this.onCheckBoxTreeSelectionChanged($event)" *ngIf="this.canShowSponsorSelection()"
                      [disabledTooltipText]="'No sponsor data is available for this product'"
                      [searchBarLabel]="'Search for Sponsors'"
                      [title]="'Product & Sponsors'"></ag-checkbox-tree>
  </div>
  <div class="select-row selection-respondents-column">
    <ag-checkbox-list (selectionChanged)="this.onCheckBoxListSelectionChanged($event)" *ngIf="this.canShowRespondentSelection()"
                      [searchBarLabel]="'Search for Respondents'"
                      [title]="'Respondents'"></ag-checkbox-list>
  </div>
</div>
