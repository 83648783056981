<div class="table-footer flex-row" id="table-footer">
  <div class="action-section">
    <template [ngTemplateOutlet]="actionButtonPanelDef"></template>
  </div>
  <div class="flex-row mg-r-m page-size-select">
    <label>Items per page:</label>
    <mat-select (selectionChange)="onPageSizeChange()" [(value)]="pageSize">
      <mat-option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</mat-option>
    </mat-select>
  </div>
  <div class="flex-row pagination-section">
    <div class="mg-r-s">
      {{ currentPage * pageSize + 1 }} to {{ (currentPage + 1) * pageSize > rowCount ? rowCount : (currentPage + 1) * pageSize }} of {{ rowCount }}
    </div>
    <button (click)="goToFistPage()" disabled="{{currentPage == 0}}" mat-icon-button>
      <i class="fa-sharp fa-light fa-arrow-left-to-line"></i>
    </button>
    <button (click)="toToPreviousPage()" disabled="{{currentPage == 0}}" mat-icon-button>
      <i class="fa-sharp fa-light fa-chevron-left"></i>
    </button>
    <div>
      page {{ currentPage + 1 }} of {{ totalPages }}
    </div>
    <button (click)="toToNextPage()" disabled="{{currentPage + 1 == totalPages}}" mat-icon-button>
      <i class="fa-sharp fa-light fa-chevron-right"></i>
    </button>
    <button (click)="goToLastPage()" disabled="{{currentPage + 1 == totalPages}}" mat-icon-button>
      <i class="fa-sharp fa-light fa-arrow-right-to-line"></i>
    </button>
  </div>
</div>
