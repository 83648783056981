<ag-task (goBackEvent)="goBackEvent.emit($event)"
         [isUrlMode]="isUrlMode"
         [selectedFeatureMode]="selectedFeatureMode"
         [sidebarEl]="featureComponentSidebarEl"
         [urlModeGoBackUrl]="urlModeGoBackUrl"
         class="full-height-container"
         id="task-component">
  <ng-template agClassifier="{{FeatureComponentMode.BULK_INCLUDE}}">
    <app-product-sponsor-verbatim-bulk (goBackEvent)="goBackEvent.emit($event)"
                                       [featureComponentMode]="FeatureComponentMode.BULK_INCLUDE"
                                       [selectedRows]="selectedRows"
                                   class="task-component-detail full-height-container"></app-product-sponsor-verbatim-bulk>
  </ng-template>
  <ng-template agClassifier="{{FeatureComponentMode.UPLOAD}}">
    <app-product-sponsor-verbatim-bulk (goBackEvent)="goBackEvent.emit($event)"
                                   [featureComponentMode]="FeatureComponentMode.UPLOAD"
                                   class="task-component-detail full-height-container"></app-product-sponsor-verbatim-bulk>
  </ng-template>
</ag-task>
