import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";

@Component({
  selector: 'app-alert-window',
  templateUrl: './alert-window.component.html',
  styleUrls: ['./alert-window.component.scss']
})
export class AlertWindowComponent {
  isCollapsed = true;
  code?: string;
  message: string;

  constructor(public _snackBar: MatSnackBarRef<AlertWindowComponent>, @Inject(MAT_SNACK_BAR_DATA) public _data: any) {
    try {
      const jsonObject = JSON.parse(_data);
      this.code = jsonObject.code;
      this.message = jsonObject.message;
    } catch {
      this.message = _data;
    }
  }

  onCloseClick() {
    this._snackBar.dismiss();
  }

  toggleText() {
    this.isCollapsed = !this.isCollapsed;
  }
}
