import {VerbatimFileInfo} from "@data/interefaces/verbatim.file.interfaces";

export class VerbatimFileUtil {

  /**
   * This method is used to generate a consistent uploadDetails string.
   */
  public static getUploadDetails(verbatimFileInfo: VerbatimFileInfo): string {
    return 'row(s) status: inserted: ' + verbatimFileInfo.recordsInserted +
      ' updated: ' + verbatimFileInfo.recordsUpdated +
      ' error: ' + verbatimFileInfo.recordsError;
  }
}
