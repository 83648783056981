import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType, DownloadRequestProcessStatus} from "@data/enums/data.enums";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {Util} from "@data/util/util";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {ResetFilterComponent} from "@pages/segments/grid/filters/reset-filter/reset-filter.component";

let maxDownloadRequestThreshold: number = 0;
let isUserAdmin: boolean = false;
let marketCodesToLastExpeditedCount: Map<String, number> = new Map();
let currentUser: string = '';

export const expediteClickAction = 'expedite_link_click';
export const cancelClickAction = 'cancel_link_click';
export const retryClickAction = 'retry_link_click';

export function setDownloadRequestThreshold(count: number) {
  maxDownloadRequestThreshold = count;
}

export function setIsUserAdmin(isAdmin: boolean) {
  isUserAdmin = isAdmin;
}

export function setMarketCodesToLastExpeditedMap(map: Map<String, number>) {
  marketCodesToLastExpeditedCount = map;
}

export function setCurrentUser(user?: string) {
  currentUser = user ?? '';
}

export let pageContext: {
  [key: string]: boolean | string | undefined
  per_f: string | undefined;
  m_f: string | undefined;
  dtv_f: string | undefined;
  p_f: string | undefined;
  s_f: string | undefined;
  l_f: string | undefined;
  rb_f: string | undefined;
  rd_f: string | undefined;
  sd_f: string | undefined;
  ed_f: string | undefined;
  para_f: string | undefined;
  ps_f: string | undefined;
  psm_f: string | undefined;
  sfu_f: string | undefined;
  efc_f: string | undefined;
  bc_f: string | undefined;
  obc_f: string | undefined;
  ilr_f: string | undefined;
  dt_f: string | undefined;
  ic_f: string | undefined;
  ss_f: string | undefined;
  rl_f: string | undefined;
  e_f: string | undefined;
  dph_f: string | undefined;
} = {
  per_f: undefined,
  m_f: undefined,
  dtv_f: undefined,
  p_f: undefined,
  s_f: undefined,
  l_f: undefined,
  rb_f: undefined,
  rd_f: undefined,
  sd_f: undefined,
  ed_f: undefined,
  para_f: undefined,
  ps_f: undefined,
  psm_f: undefined,
  sfu_f: undefined,
  efc_f: undefined,
  bc_f: undefined,
  obc_f: undefined,
  ilr_f: undefined,
  dt_f: undefined,
  ic_f: undefined,
  ss_f: undefined,
  rl_f: undefined,
  e_f: undefined,
  dph_f: undefined
}

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    headerName: 'Requests', valueFormatter: '', children: [
      {
        field: 'batchCode',
        headerName: 'Batch Code',
        rowGroup: true,
        hide: false,
        filter: ColumnFilterType.TEXT,
        filterId: 'bc_f',
        filterParams: {
          buttons: ['reset']
        },
      },
      {
        field: 'originalBatchCode',
        headerName: 'Original Batch Code',
        hide: true,
        filter: ColumnFilterType.TEXT,
        filterId: 'obc_f',
        filterParams: {
          buttons: ['reset']
        }
      },
      {
        field: 'period',
        headerName: 'Period',
        hide: false,
        filterId: 'per_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        minWidth: 125,
        sort: 'desc',
        initialSortIndex: 2
      },
      {
        field: 'country',
        headerName: 'Market',
        hide: false,
        filterId: 'm_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        minWidth: 125,
        sort: 'desc',
        initialSortIndex: 3
      },
      {
        field: 'industryCode',
        headerName: 'Industry Code',
        hide: false,
        filterId: 'ic_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        minWidth: 150
      },
      {
        field: 'surveySubject',
        headerName: 'Survey Subject',
        hide: false,
        filterId: 'ss_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        minWidth: 150,
        sort: 'desc',
        initialSortIndex: 4
      },
      {
        field: 'reportTypeLabel',
        headerName: 'Download Type',
        hide: false,
        minWidth: 300,
        filterId: 'dt_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        sort: 'desc',
        initialSortIndex: 5
      },
      {
        field: 'productType',
        headerName: 'Download Type Version',
        hide: false,
        minWidth: 300,
        filterId: 'dtv_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
      },
      {
        field: 'product',
        headerName: 'Product',
        hide: false,
        minWidth: 400,
        filterId: 'p_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        sort: 'desc',
        initialSortIndex: 6
      },
      {
        field: 'sponsor',
        headerName: 'Sponsor',
        hide: false,
        minWidth: 150,
        filterId: 's_f',
        filter: ColumnFilterType.TEXT,
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            const colDefText = data?.sponsorName ? data.sponsorName.toLocaleLowerCase() : colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase()
            return Util.labelCodeComparator(filterOption, value, filterText, colDefText);
          },
          maxNumConditions: 5,
          defaultJoinOperator: 'OR',
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          if (params?.node?.group) {
            return;
          }
          return {
            currentPageContext: pageContext,
            value: params.data.sponsorName ? params.data.sponsorName : params.data.sponsor,
            subText: params.data.sponsorName ? params.data.sponsor : '-'
          };
        },
        sort: 'desc',
        initialSortIndex: 7
      },
      {
        field: 'respondentLabels',
        headerName: 'Respondent Company Label',
        hide: false,
        filterId: 'rl_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        minWidth: 175,
        sort: 'desc',
        initialSortIndex: 8,
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          if (params.value && Array.isArray(params.value)) {
            return params.value.join(', ');
          }
          return params.value || '';
        },
      },

      {
        field: 'language',
        headerName: 'Language',
        hide: false,
        filterId: 'l_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        minWidth: 150,
        sort: 'desc',
        initialSortIndex: 9
      },
      {
        field: 'isLatestRequest',
        headerName: 'Latest Request',
        hide: false,
        filterId: 'ilr_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          cellRendererParams: function (params: any) {
            return {
              iconSize: 'fa-lg'
            };
          },
          buttons: ['reset']
        },
        cellRenderer: CircleCheckColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            iconSize: 'fa-xl'
          };
        },
        minWidth: 150,
        sort: 'desc',
        initialSortIndex: 10
      },
      {
        field: 'requestedBy',
        headerName: 'Requested By',
        hide: false,
        filterId: 'rb_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        sort: 'desc',
        initialSortIndex: 11
      },
      {
        field: 'requestDate',
        headerName: 'Request Date',
        hide: false,
        filterId: 'rd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          maxNumConditions: 1,
          comparator: Util.dateComparator
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        },
        sort: 'desc',
        initialSortIndex: 12
      },
      {
        field: 'priority',
        headerName: 'Is Expedited?',
        hide: false,
        filterId: 'e_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          cellRendererParams: function (params: any) {
            return {
              iconSize: 'fa-lg'
            };
          },
          buttons: ['reset']
        },
        cellRenderer: CircleCheckColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            iconSize: 'fa-xl'
          };
        },
        valueGetter: params => {
          // change value to boolean for CircleCheckColumnComponent
          if (params.node?.group) {
            return;
          }
          return params.data.priority === 1
        },
        sort: 'desc',
        initialSortIndex: 13
      },
      {
        field: 'downloadParameters',
        headerName: 'Parameters',
        hide: false,
        filterId: 'para_f',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          if (params.node.group) {
            return;
          }
          return {
            text: params.data.downloadParameters ?? '',
            type: 'json',
            title: 'Parameters'
          };
        }
      },
      {
        field: 'downloadParametersHash',
        headerName: 'Parameters Hash',
        hide: true,
        filterId: 'dph_f',
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
      },
    ]
  },
  {
    headerName: 'Metrics', valueFormatter: '', children: [
      {
        field: 'metrics.estimatedFileCount',
        headerName: 'Estimated File Count',
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.estimatedSlideCount',
        headerName: 'Estimated Slide Count',
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.currentNumberInQueue',
        headerName: 'Current number in queue',
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.estimatedTimeInQueueInSeconds',
        headerName: 'Estimated time in queue',
        valueFormatter: (params) => Util.formatSecondsToHHMMSS(params.value),
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.estimatedProcessingTimeInSeconds',
        headerName: 'Estimated processing time',
        valueFormatter: (params) => Util.formatSecondsToHHMMSS(params.value),
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.estimatedCompletionDatetime',
        headerName: 'Estimated completion datetime',
        valueFormatter: (params) => Util.getFormattedDate(params.value),
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.timeInQueueInSeconds',
        headerName: 'Time in queue',
        valueFormatter: (params) => Util.formatSecondsToHHMMSS(params.value),
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.timeToProcessInSeconds',
        headerName: 'Time to process',
        valueFormatter: (params) => Util.formatSecondsToHHMMSS(params.value),
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.totalCompletionTimeInSeconds',
        headerName: 'Total completion time',
        valueFormatter: (params) => Util.formatSecondsToHHMMSS(params.value),
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.fileCount',
        headerName: 'File count',
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.fileSize',
        headerName: 'File size',
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.slideCount',
        headerName: 'Slide count',
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.staticSlideCount',
        headerName: '"Static" slide count',
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.templateSlideCount',
        headerName: '"Template" slide count',
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.dataSlideCount',
        headerName: '"Data" slide count',
        hide: true,
        filter: false,
        minWidth: 120
      },
      {
        field: 'metrics.orphanedSlideCount',
        headerName: '"Orphaned" slide count',
        hide: true,
        filter: false,
        minWidth: 120
      }
    ]
  },
  {
    headerName: 'Results', valueFormatter: '', children: [
      {
        field: 'processStatus',
        pinned: 'right',
        headerName: 'Status',
        hide: false,
        minWidth: 180,
        filterId: 'ps_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: StatusColumnComponent,
        sort: 'desc',
        initialSortIndex: 14
      },
      {
        field: 'sharepointFolderUrl',
        pinned: 'right',
        headerName: 'Sharepoint Folder Url',
        hide: false,
        filterId: 'sfu_f',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          if (params.node.group) {
            return;
          }
          return {

            text: params.data.sharepointFolderUrl,
            type: 'link',
          };
        },
        sort: 'desc',
        initialSortIndex: 15
      },
      {
        field: 'processStatusMessage',
        pinned: 'right',
        headerName: 'Details',
        hide: false,
        filterId: 'psm_f',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['reset']
        },
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          if (params.node.group) {
            return;
          }
          return {
            text: params.data.processStatusMessage,
            type: 'text',
            title: 'Message'
          };
        },
      },
      {
        field: 'processStartDate',
        pinned: 'right',
        headerName: 'Start Date',
        hide: true,
        filterId: 'sd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          maxNumConditions: 1,
          comparator: Util.dateComparator
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      },
      {
        field: 'processEndDate',
        pinned: 'right',
        headerName: 'End Date',
        hide: true,
        filterId: 'ed_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          maxNumConditions: 1,
          comparator: Util.dateComparator
        },
        useValueFormatterForExport: false,
        valueFormatter: (params) => {
          return Util.getFormattedDate(params.value);
        }
      }
    ]
  },
  {
    field: "actions",
    headerName: "Actions",
    pinned: "right",
    suppressFiltersToolPanel: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: ResetFilterComponent,
    suppressFloatingFilterButton: true,
    suppressHeaderFilterButton: true,
    cellRenderer: ActionColumnComponent,
    downloadable: false,
    suppressColumnsToolPanel: true,
    minWidth: 200,
    cellRendererParams: function (params: any) {
      if (params.node.group) {
        return;
      }
      const rowMarketCode = params.data.country;
      const currentMarketExpediteCount = marketCodesToLastExpeditedCount.get(rowMarketCode) ?? 0;
      const isValidBatchCode = Util.isValidDownloadRequestBatchCode(params.data.batchCode);
      const isRequestedByOtherAndNotAdmin = params.data.requestedBy !== currentUser && !isUserAdmin;
      const disableIcon = params.data.processStatus != DownloadRequestProcessStatus.PENDING ||
        (params.data?.priority === 5 && currentMarketExpediteCount >= maxDownloadRequestThreshold && !isUserAdmin) ||
        (params.data?.priority === 1 && isRequestedByOtherAndNotAdmin);
      const disableCancelIcon = params.data.processStatus != DownloadRequestProcessStatus.PENDING || isRequestedByOtherAndNotAdmin;
      const disableRetryIcon = (params.data.processStatus == DownloadRequestProcessStatus.PENDING || params.data.processStatus == DownloadRequestProcessStatus.IN_PROGRESS)
        || !isValidBatchCode;
      return {
        actions: [
          {
            toolTip: "Request Detail",
            icon: "fa-pen-to-square",
            name: "Request Detail",
            routerLink: ['task', 'detail'],
            queryParams: {dri_f: params.data.id}
          },
          {
            toolTip: (params.data?.priority === 5 && currentMarketExpediteCount >= maxDownloadRequestThreshold && !isUserAdmin) ?
              `Current expedite limit of ${maxDownloadRequestThreshold} for ${rowMarketCode} reached (${currentMarketExpediteCount}).`
              : params.data?.priority === 1 ?
                (params.data?.priority === 1 && isRequestedByOtherAndNotAdmin) ? 'Cannot downgrade requests you do not own' : 'Downgrade Request'
                : 'Expedite Request',
            icon: params.data?.priority === 1 ? 'fa-gauge-circle-minus' : 'fa-gauge-circle-plus',
            name: 'Expedite Request',
            disabled: disableIcon,
            actionEvent: expediteClickAction
          },
          {
            toolTip: "Retry Request",
            icon: "fa-rotate-left",
            name: "Retry Request",
            disabled: disableRetryIcon,
            actionEvent: retryClickAction
          },
          {
            toolTip: "Cancel Request",
            icon: "fa-ban",
            name: "Cancel Request",
            disabled: disableCancelIcon,
            actionEvent: cancelClickAction
          }
        ]
      };
    }
  }
]
