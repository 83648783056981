import {ApiService} from "@core/api/api.service";
import {Injectable} from "@angular/core";
import {BatchProcessResult} from "@data/interefaces/data.interfaces";

@Injectable({
  providedIn: 'root'
})
export class BatchService {
  constructor(private _apiService: ApiService) {
  }

  public getBatchProcessById(batchProcessId: number) {
    return this._apiService.makeGetServiceCall<BatchProcessResult>(`/v2/api/batch/batch-process/id/${batchProcessId}`);
  }

  public stopBatchProcess(batchProcessId: number) {
    return this._apiService.makePutServiceCall<{ value: string }>(`/v2/api/batch/batch-process/stop/${batchProcessId}`, null, null);
  }

  public markBatchProcessDone(batchProcessId: number) {
    return this._apiService.makePutServiceCall<{ value: string }>(`/v2/api/batch/batch-process/done/${batchProcessId}`, null, null);
  }
}
