import {Component, Input} from '@angular/core';
import {Util} from "@data/util/util";

@Component({
  selector: 'ag-subtitle',
  templateUrl: './ag-subtitle.component.html',
  styleUrls: ['./ag-subtitle.component.scss']
})
export class AgSubtitleComponent {

  @Input() subTitleSegments: string[] | undefined;
  protected readonly util = Util;

  getBreadcrumb() {
    let breadcrumb: string[] = []
    this.subTitleSegments?.forEach(element => {
      if (element.length > 0) {
        breadcrumb.push(this.util.formatDashSeperatedStringToDisplayLabel(element));
      }
    });
    return breadcrumb;
  }

}
