<mat-card class="full-height-container">
  <mat-card-content class="full-height-container content-panel">
    <div class="flex-row">
      <app-component-header [hideColumnsButton]="true"
                            [startRefreshTimer]="this.refreshComplete"
                            (refreshClickEvent)="this.refreshView()"
                            [hideUploadButton]="true"
                            [subtitleSegments]="getUrlSegments()"
                            [checkAutoRefreshByDefault]="false"
                            class="full-width">
        <div class="flex-row flex-g-s pd-l-m">
          <button class="secondary-button" [disabled]="isDataMode()" (click)="switchMode('data')">
            <i class="fa-sharp fa-light fa-database"></i>
            <span>Data</span>
          </button>
          <button class="secondary-button" [disabled]="!isDataMode()" (click)="switchMode('chart')">
            <i class="fa-sharp fa-light fa-chart-line"></i>
            <span>Visuals</span>
          </button>
        </div>
      </app-component-header>
    </div>
    <div class="flex-row table-container">
      <ag-kpi-sidebar [sidebar]="kpiSidebar" class="mg-r-s">
        <div class="flex-centered pd-t-s pd-b-s">
          <mat-form-field appearance="fill">
            <mat-label>Scale</mat-label>
            <mat-select [(ngModel)]="scale" (selectionChange)="onScaleChanged($event.value)">
              <mat-option value="Minute">Minute</mat-option>
              <mat-option value="Hour">Hour</mat-option>
              <mat-option value="Day">Day</mat-option>
              <mat-option value="Week">Week</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ag-kpi-sidebar>
      <ag-grid-angular
        [style.display]="this.isDataMode() ? 'block' : 'none'"
        (columnVisible)="onColumnVisible($event)"
        (filterChanged)="onFilterChanged($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (gridReady)="onGridReady($event)"
        (viewportChanged)="onViewportChanged($event)"
        [columnDefs]="tableColumns"
        [gridOptions]="baseAgGridOptions"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [rowData]="rowData"
        [suppressPropertyNamesCheck]="true"
        [rowGroupPanelShow]="'always'"
        [groupHideOpenParents]="false"
        class="ag-theme-quartz full-width">
      </ag-grid-angular>
      <div *ngIf="!this.isDataMode()" class="flex-row chart-container">
        <div *ngIf="this.isGridGrouped()" class="data-type-selection">
          <mat-button-toggle-group [(ngModel)]="selectedDataType" (change)="onDataTypeChange($event.value)"
                                   aria-label="Select Data Type">
            <mat-button-toggle *ngFor="let dataType of dataTypeOptions" [value]="dataType.value">
              {{ dataType.label }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <ag-charts class="chart" [options]="chartOptions"></ag-charts>
      </div>
    </div>
  </mat-card-content>
</mat-card>
