import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Util} from "@data/util/util";
import {StoreService} from "@core/api/store.service";

/**
 * This component wraps the Refresh, Show Hide Column, and Download button.
 *
 *
 * The default value for tableDomId is 'data_table'.
 */
@Component({
  selector: 'app-component-header',
  templateUrl: './component-header.component.html',
  styleUrls: ['./component-header.component.scss']
})
export class ComponentHeaderComponent {

  @Input() subtitleSegments: string[] | undefined;
  @Input() startRefreshTimer: boolean = true;
  @Input() hideAutoRefresh: boolean = false;
  @Input() hideRefreshButton: boolean = false;
  @Input() hideColumnsButton: boolean = false;
  @Input() hideDownloadButton: boolean = false;
  @Input() hideUploadButton: boolean = false;
  @Input() uploadUrl: string[] = [];
  @Input() uploadUrlParam: any = {};
  @Output() refreshClickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() downloadClickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() hideShowColumnsClickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() quilliupGuid: string | undefined;
  @Input() checkAutoRefreshByDefault: boolean = false;
  @Input() redirectTo: 'quilliup' | '' = '';
  protected readonly util = Util;

  constructor(protected _store: StoreService) {
  }

  getSubtitleSegments() {
    let breadcrumb: string[] = []
    this.subtitleSegments?.forEach(element => {
      breadcrumb.push(this.util.formatDashSeperatedStringToDisplayLabel(element));
    });

    return breadcrumb;
  }

  refreshButtonClick() {
    this.refreshClickEvent.emit(true);
  }

  hideShowColumnsButtonClick() {
    this.hideShowColumnsClickEvent.emit(true);
  }

  downloadButtonClick() {
    this.downloadClickEvent.emit(true);
  }

}
