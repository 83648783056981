export enum QuilliupEnvironment {
  PROD = "https://quilliup.advantagegroup.com/#/",
  DEV = "https://quilliup-dev.advantagegroup.com/#/",
  QA = "https://quilliup-qa.advantagegroup.com/#/",
  INTEGRATION = "https://quilliup-itg.advantagegroup.com/#/",
  TRAINING = "https://quilliup-trn.advantagegroup.com/#/",
  STAGING = "https://quilliup-stg.advantagegroup.com/#/",
}

type QuilliupFormKey =
  "UNIQUE_LINK_INVITEES" |
  "OPEN_LINK_INVITEES" |
  "MAILOUT_TEMPLATES" |
  "MAILOUT_TEMPLATE_LABELS" |
  "MAILOUT_FIELD_SETS" |
  "MAILOUT_FIELD_SET_LABELS" |
  "PRODUCT_PERIODS" |
  "RESPONSES" |
  "RATINGS" |
  "BU_CATEGORIES" |
  "CHANNELS" |
  "SUBJECT_COMPANIES" |
  "RESPONDENT_COMPANY_EXCLUSIONS" |
  "LOCAL_REGION_EXCLUSIONS" |
  "BR_PL_EXCLUSIONS" |
  "CHANNEL_EXCLUSIONS" |
  "BU_CATEGORY_EXCLUSIONS" |
  "VERBATIM" |
  "SURVEY_TRACKER" |
  "PRODUCTS" |
  "PRODUCT_SPONSOR_VERBATIM" |
  "PRODUCT_REPORT_LABELS" |
  "PRODUCT_TREND_ALIGNMENTS" |
  "RESPONDENT_GROUP_PERIODS" |
  "RESPONDENT_GROUP_REPORT_LABELS" |
  "STANDARD_COMPANY_WEIGHTS" |
  "CHECKSUM_REVIEW" |
  "CHECKSUM_DETAILS";

type QuilliupFormDetails = {
  guid: string;
  params: string[];
};

/**
 * This record is designed to map to the actual Quillup page.
 * The param is the required set of parameters from Quilliup that we need to pass in.
 */
export const QuilliupForm: Record<QuilliupFormKey, QuilliupFormDetails> = {
  UNIQUE_LINK_INVITEES: {guid: "44e0f7a3-3556-4450-b300-f1a9ecf04dea", params: []},
  OPEN_LINK_INVITEES: {guid: "f801fd84-a59e-4725-abca-c0fc6f538f30", params: []},
  MAILOUT_TEMPLATES: {guid: "a10147b5-9e50-4733-ab84-1340570db920", params: ["Survey Code"]},
  MAILOUT_TEMPLATE_LABELS: {guid: "0a837aef-658b-413e-a438-5697b8d13f34", params: ["Survey Code"]},
  MAILOUT_FIELD_SETS: {guid: "499b1cd6-e58b-45bb-848f-f914d2152198", params: ["Survey Code"]},
  MAILOUT_FIELD_SET_LABELS: {guid: "7f0902d7-466c-4ce1-b23c-215ce67c5342", params: ["Survey Code"]},
  PRODUCT_PERIODS: {guid: "75e18fba-923d-4f5e-aa32-76477aced2b9", params: []},
  PRODUCTS: {guid: "382d80db-3851-4c4e-9d45-00cd24c6b0bb", params: ["Product Code"]},
  PRODUCT_SPONSOR_VERBATIM: {guid: "14f2cec9-6e15-4aa7-89f0-2c5818741eae", params: ["Product Code"]},
  PRODUCT_REPORT_LABELS: {guid: "1b98e485-32ad-4cd6-ba9b-21c2b69b40dd", params: ["Product Code"]},
  PRODUCT_TREND_ALIGNMENTS: {guid: "3575b8fb-e537-4b95-9db5-7bdb709b7481", params: ["Product Code"]},
  RESPONDENT_GROUP_PERIODS: {guid: "109044d4-b752-4a73-90b1-77bf41db4488", params: ["Respondent Group Code"]},
  RESPONDENT_GROUP_REPORT_LABELS: {guid: "f4d7c2e1-f25a-4cfa-bcb8-28c9fe1ee27a", params: ["Respondent Group Code"]},
  STANDARD_COMPANY_WEIGHTS: {
    guid: "2bb3b3bb-dd9e-493b-962e-e26389b13bb6",
    params: ["Standard Respondent Weight Set Code"]
  },
  CHECKSUM_REVIEW: {guid: "a52f8d3e-f013-4da1-9fc0-e10cd70e5e8d", params: ["Product Code"]},
  CHECKSUM_DETAILS: {guid: "5301ca80-8d29-48e3-93db-89b51902b29a", params: ["Product Code"]},
  BR_PL_EXCLUSIONS: {guid: "60367480-2ea4-4e97-b051-9e10e20444a8", params: ["Survey Code"]},
  BU_CATEGORIES: {guid: "14f2f0b2-d377-41af-a3f1-74b837d7e13e", params: ["Survey Code"]},
  BU_CATEGORY_EXCLUSIONS: {guid: "50abbca7-6499-4708-883b-54bedc1fe2ae", params: ["Survey Code"]},
  CHANNELS: {guid: "4f93127f-85ea-4189-bf24-28998f633671", params: ["Survey Code"]},
  CHANNEL_EXCLUSIONS: {guid: "c705eaff-2edb-4c1e-b3fd-eb8ec455ef59", params: ["Survey Code"]},
  LOCAL_REGION_EXCLUSIONS: {guid: "dad9e5bd-1c1c-41b6-bd2c-d84cd7b8bbd9", params: ["Survey Code"]},
  RATINGS: {guid: "55ec6762-f19b-4d13-9183-d5277afe79b2", params: ["Survey Code"]},
  RESPONDENT_COMPANY_EXCLUSIONS: {guid: "759289bf-5523-4d3e-8e77-1eaa4e7ff6f6", params: ["Survey Code"]},
  RESPONSES: {guid: "8ecdf910-a2fb-4dd3-a69d-bbff57e70f88", params: ["Survey Code"]},
  SUBJECT_COMPANIES: {guid: "e876b4c0-e344-4ba3-b0bd-491bbfc9db0f", params: ["Survey Code"]},
  VERBATIM: {guid: "d1230658-a288-4cc0-a9e2-02a75be4341e", params: ["Survey Code"]},
  SURVEY_TRACKER: {guid: "d7d1e72b-d270-4f6f-9546-c64352a8b3c5", params: []},
};
