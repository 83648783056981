<div class="{{showTaskComponent ? 'hidden' : 'full-height-container'}}">
  <mat-card class="full-height-container">
    <mat-card-content class="full-height-container content-panel">
      <div class="flex-row">
        <app-component-header (downloadClickEvent)="this.handleDownloadClickEvent('respondent-company')"
                              (refreshClickEvent)="this.refreshView()" [hideColumnsButton]="true"
                              [redirectTo]="'quilliup'" [startRefreshTimer]="this.refreshComplete"
                              [subtitleSegments]="getUrlSegments()" [uploadUrlParam]="{mode: 'upload'}"
                              [uploadUrl]="['task', 'upload']"
                              class="full-width"></app-component-header>
      </div>

      <div class="flex-row table-container">
        <!--      <ag-kpi-sidebar [sidebar]="kpiSidebar" class="mg-r-s"></ag-kpi-sidebar>-->
        <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                         (filterChanged)="onFilterChanged($event)"
                         (firstDataRendered)="onFirstDataRendered($event)"
                         (gridReady)="onGridReady($event)"
                         (selectionChanged)="onRowSelectionChanged($event)"
                         (viewportChanged)="onViewportChanged($event)"
                         [columnDefs]="tableColumns"
                         [gridOptions]="baseAgGridOptions"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         [rowData]="this.dataSource"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz ag-grid-flex">
        </ag-grid-angular>
        <ng-template #footerActionButtonPanel>
          <button (click)="onBulkIncludeClicked()" [disabled]="!isBulkOperationAllowed" class="white-font-color mg-r-m" color="accent" mat-raised-button>
            <i class="fa-sharp fa-light fa-arrow-right-to-arc"></i>
            Bulk Include / Exclude
          </button>
        </ng-template>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div class="{{showTaskComponent ? 'full-height-container': 'hidden' }}">
  <app-respondent-company-task (goBackEvent)="goBack()" *ngIf="showTaskComponent" [selectedFeatureMode]="selectedFeatureMode" [selectedRows]="selectedRows"/>
</div>

<ag-side-panel #languageEdit size="medium" subTitle="Edit product Languages" title="Product Language Configuration">
  <div [showSkeleton]="showSkeleton" agSkeleton skeletonType="grid-3x1">
    <div class="flex-row full-width">
      <ag-dynamic-panel [fieldPerRow]="1" [panelConfig]="dynamicPanelConfig"></ag-dynamic-panel>
    </div>
    <div class="flex-row flex-g-s">
      <button color="primary" mat-raised-button>
        <mat-icon class="material-symbols-outlined">save</mat-icon>
        Save
      </button>
      <button class="secondary-button">
        <mat-icon class="material-symbols-outlined">cancel</mat-icon>
        Cancel
      </button>
    </div>
  </div>
</ag-side-panel>

<ag-side-panel #respondentCompanyEdit [showProgress]="showPanelProgress" size="small" subTitle="Edit product company" title="Product Company Configuration">
  <div [showSkeleton]="showSkeleton" agSkeleton skeletonType="grid-3x2">
    <div class="flex-row mg-b-m mg-t-m">
      <ag-slide-toggle
        (toggleUpdate)="onIncludeToggleChange($event)"
        [isChecked]="selectedRespondentCompany?.include === YesNoValue.YES_VALUE"
      ></ag-slide-toggle>
      <span class="mg-l-s">Include?</span>
    </div>
    <div class="flex-row flex-g-s">
      <button (click)="onSaveClick();" color="primary" mat-raised-button>
        <mat-icon class="material-symbols-outlined">save</mat-icon>
        Save
      </button>
      <button class="secondary-button">
        <mat-icon class="material-symbols-outlined">cancel</mat-icon>
        Cancel
      </button>
    </div>
  </div>
</ag-side-panel>
