<div class="ag-checkbox-tree-component">
  <div class="flex-row">
    <div class="header-title">
      <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
    </div>
    <div class="mg-l-l search-box">
      <div>
        <form>
          <mat-form-field class="full-width">
            <mat-label>Search for Sponsors</mat-label>
            <input [formControl]="this.searchFormControl" [matAutocomplete]="auto" matInput/>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="autoCompleteDisplayValue">
              <div (click)="this.onMatOptionGroupClicked($event)"> <!-- This is required here in order to keep the dropdown open when filter via text -->
                <mat-optgroup *ngFor="let searchOption of this.filteredCheckboxNodes$ | async" [label]="searchOption.item">
                  <mat-option *ngFor="let childItem of searchOption.children"
                              [value]="childItem">
                  <span (click)="this.onAutoCompleteMatOption($event, childItem)" class="mat-option-span">
                    <div class="checkbox-wrapper">
                    <mat-checkbox (change)="this.onAutoCompleteSelectionChanged(childItem)"
                                  (click)="this.onAutoCompleteCheckboxClicked($event)"
                                  [checked]="this.isChildNodeSelected(childItem)"
                                  class="checklist-leaf-node"
                                  color="primary">
                      {{ childItem.item }}
                    </mat-checkbox>
                    </div>
                  </span>
                  </mat-option>
                </mat-optgroup>
              </div>
            </mat-autocomplete>
            <mat-icon (click)="clearValue($event)" *ngIf="hasValue()" matSuffix><i class="fa-sharp fa-regular fa-xmark"></i></mat-icon>
            <mat-icon matSuffix><i class="fa-sharp fa-thin fa-magnifying-glass"></i></mat-icon>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <div #treeContainer class="tree-container">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <!-- Child Nodes -->
      <mat-tree-node *matTreeNodeDef="let node" [id]="node.id" class="child-node container-small" matTreeNodePadding matTreeNodeToggle>
        <mat-checkbox (change)="childItemSelectionToggle(node)" [checked]="checklistSelection.isSelected(node)"
                      class="checklist-leaf-node"
                      color="primary">{{ node.item }}
        </mat-checkbox>
      </mat-tree-node>
      <!-- Parent Nodes -->
      <mat-tree-node *matTreeNodeDef="let node; when: hasChildOrLevelZero" [id]="node.id" class="parent-node container-small" matTreeNodePadding>
        <div [matTooltip]="!this.validateTreeNodeExpansion(node) ? disabledTooltipText : ''">
          <button (click)="this.validateTreeNodeExpansion(node)" [attr.aria-label]="'Toggle ' + node.item" [disabled]="!this.validateTreeNodeExpansion(node)"
                  mat-icon-button
                  matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror">
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
        </div>
        <div [matTooltip]="!this.validateTreeNodeExpansion(node) ? disabledTooltipText : ''">
          <mat-checkbox (change)="parentItemSelectionToggle(node)" (click)="this.validateTreeNodeExpansion(node)" [checked]="descendantsAllSelected(node)"
                        [disabled]="!this.validateTreeNodeExpansion(node)"
                        [indeterminate]="descendantsPartiallySelected(node)"
                        color="primary">{{ node.item }}
          </mat-checkbox>
        </div>
      </mat-tree-node>
    </mat-tree>
  </div>
</div>
