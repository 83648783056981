<div class="{{showTaskComponent ? 'hidden' : 'full-height-container'}}">
  <mat-card class="full-height-container">
    <mat-card-content class="full-height-container content-panel">
      <div class="flex-row">
        <app-component-header (refreshClickEvent)="this.refreshView()"
                              [checkAutoRefreshByDefault]="false"
                              [hideColumnsButton]="true"
                              [hideDownloadButton]="true"
                              [redirectTo]="'responseFile'"
                              [startRefreshTimer]="this.refreshComplete"
                              [subtitleSegments]="getUrlSegments()"
                              [uploadUrl]="['task', 'upload']"
                              class="full-width">
        </app-component-header>
      </div>

      <div class="flex-row table-container">
        <ag-kpi-sidebar [sidebar]="kpiSidebar" class="mg-r-s"></ag-kpi-sidebar>

        <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                         (filterChanged)="onFilterChanged($event)"
                         (firstDataRendered)="onFirstDataRendered($event)"
                         (gridReady)="onGridReady($event)"
                         (viewportChanged)="onViewportChanged($event)"
                         [columnDefs]="tableColumns"
                         [gridOptions]="baseAgGridOptions"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         [rowData]="rowData"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz ag-grid-flex">
        </ag-grid-angular>
      </div>
    </mat-card-content>
  </mat-card>
</div>
