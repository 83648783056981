<div>
  <ag-context-link [auxLinks]="auxLinks"></ag-context-link>
</div>
<div class="transfer-manual-run container">
  <div class="mg-b-s">
    <mat-card>
      <mat-card-content>
        <mat-form-field class="select-field-width">
          <mat-label>Choose Transfer Type</mat-label>
          <mat-select [formControl]="formControl">
            <mat-option *ngFor="let job of transferConfigArray" value="{{job.code.toLowerCase()}}">
              {{ job.code }}
              <br/>
              <sub class="mg-l-s">- {{ job.jobName }}</sub>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="flex-row flex-g-s full-width">
          <ag-datablock data="{{selectedTransferConfig?.scheduled}}" title="Scheduled?"></ag-datablock>
          <ag-datablock data="{{selectedTransferConfig?.lastRunDate| date : 'short'}}" title="Last Run"></ag-datablock>
          <ag-datablock data="{{selectedTransferConfig?.nextRunDate| date : 'short'}}" title="Next Run"></ag-datablock>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="mg-t-m mg-b-m">
    <div class="flex-row">
      <ag-slide-toggle (toggleUpdate)="onEnableCustomParameters($event)" [isDisabled]="!selectedTransferConfig?.defaultRunConfiguration"
      ></ag-slide-toggle>
      <span class="mg-l-s">Enable Customer Parameters</span>
    </div>
  </div>
  <div class="mg-b-s expansion-panel-block">
    <ag-expansion-panel (reset)="resetRunParameters()" [isDisabled]="!this.includeRunParameters"
                        [isOpen]="selectedTransferConfig?.defaultRunConfiguration != undefined && this.includeRunParameters"
                        [isPanelActive]="selectedTransferConfig?.defaultRunConfiguration != undefined"
                        [supportReset]="false"
                        title="Run Parameters">
      <ag-dynamic-panel (panelValid)="onValidParameterPanel($event)" (valueChange)="onRunParameterChange($event)" [fieldPerRow]="4"
                        [panelConfig]="selectedTransferConfig?.defaultRunConfiguration" expansion-content></ag-dynamic-panel>
    </ag-expansion-panel>
  </div>
  <div class="mg-b-s actions-section">
    <button (click)="onRunButtonClick()" [disabled]="!runButtonStatus()" class="mg-r-s" color="primary" mat-raised-button>
      <mat-icon class="material-symbols-outlined">start</mat-icon>
      Run
    </button>
    <button mat-button>
      <mat-icon class="material-symbols-outlined">cancel</mat-icon>
      Cancel
    </button>
  </div>
</div>
