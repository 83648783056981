import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {
  BatchProcessResult,
  OperationStatus,
  ProductRespondentCompany,
  ResultsetThresholds
} from "@data/interefaces/data.interfaces";
import {Util} from "@data/util/util";

@Injectable({
  providedIn: 'root'
})
export class RespondentCompanyService {

  constructor(private _apiService: ApiService) {
  }

  public getRespondentCompanyList(period: string, marketCode: string, productCode: string): Promise<ProductRespondentCompany[]> {
    let queryParam: string[] = [];
    Util.pushQueryParam('period', period, queryParam);
    Util.pushQueryParam('marketCode', marketCode, queryParam);
    Util.pushQueryParam('productCode', productCode, queryParam);
    return this._apiService.makeGetServiceCall<ProductRespondentCompany[]>(`/v2/api/product-spec/res-company`, queryParam.join('&'));
  }

  public getRespondentCompanyById(respondentCompanyCode: string, productPeriodId: string): Promise<ProductRespondentCompany> {
    return this._apiService.makeGetServiceCall<ProductRespondentCompany>(`/v2/api/product-spec/res-company/${respondentCompanyCode}/${productPeriodId}`);
  }

  public updateRespondentCompany(productRespondentCompany: ProductRespondentCompany) {
    return this._apiService.makePutServiceCall<OperationStatus>(`/v2/api/product-spec/res-company`, null, productRespondentCompany);
  }

  public getRespondentCompanyThresholds(period: string, marketCode: string, productCode: string): Promise<ResultsetThresholds> {
    let queryParam: string[] = [];
    Util.pushQueryParam('period', period, queryParam);
    Util.pushQueryParam('marketCode', marketCode, queryParam);
    Util.pushQueryParam('productCode', productCode, queryParam);
    return this._apiService.makeGetServiceCall<ResultsetThresholds>(`/v2/api/product-spec/res-company/thresholds`, queryParam.join('&'));
  }

  public bulkProcess(respondentCompanies: ProductRespondentCompany[], mode: "update" | "insert") {
    return this._apiService.makePutServiceCall<{
      successRecordList: Array<{ period: string, marketCode: string; productCode: string }>;
      failedRecordList: Array<{ period: string, marketCode: string; productCode: string; processing_note: string }>
    }>(mode == 'insert' ? `/v2/api/product-spec/res-company/bulk-insert` : `/v2/api/product-spec/res-company/bulk-update`, null, respondentCompanies);
  }

  public startRespCompanyBatchProcess(respondentCompanies: ProductRespondentCompany[], processType: string) {
    let queryParams: string[] = [];
    Util.pushQueryParam("processType", processType, queryParams);
    return this._apiService.makePutServiceCall<{ id: number }>(`/v2/api/product-spec/res-company/bulk-process/start`, queryParams.join('&'), respondentCompanies);
  }

  public getLatestRespondentCompanyBatchProcess() {
    return this._apiService.makeGetServiceCall<BatchProcessResult>(`/v2/api/product-spec/res-company/bulk-process/latest`);
  }
}
