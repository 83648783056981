import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {ICellRendererParams} from "@ag-grid-community/core";
import {YesNoValue} from "@data/enums/data.enums";

@Component({
  selector: 'app-circle-check-column',
  templateUrl: './circle-check-column.component.html',
  styleUrls: ['./circle-check-column.component.scss']
})
export class CircleCheckColumnComponent implements ICellRendererAngularComp {
  params!: any;
  successIcon: string = 'fa-circle-check';
  failIcon: string = 'fa-circle-minus';
  invertIcons: boolean = false;
  successColor: string = 'primary-color';
  failColor: string = 'error-color';
  iconSize: 'fa-xl' | 'fa-lg' = 'fa-xl'; //usually, column cells use bigger size 'fa-xl', filter cells use smaller size 'fa-lg'
  protected readonly YesNoValue = YesNoValue;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    if (this.params.successIcon) {
      this.successIcon = this.params.successIcon;
    }
    if (this.params.failIcon) {
      this.failIcon = this.params.failIcon;
    }
    if (this.params.iconSize) {
      this.iconSize = this.params.iconSize;
    }
    if (this.params.invertIcons) {
      let tempIcon = this.successIcon;
      this.successIcon = this.failIcon;
      this.failIcon = tempIcon;
      this.invertIcons = true;
      let tempColor = this.successColor;
      this.successColor = this.failColor;
      this.failColor = tempColor;
      return;
    }
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
}
