import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {BaseComponent} from "@pages/base.component";
import {ProductPeriod} from "@data/interefaces/data.interfaces";
import {ProductSpecService} from "@data/product-spec/product.spec.service";

@Component({
  selector: 'app-product-assignment',
  templateUrl: './product-assignment.component.html',
  styleUrls: ['./product-assignment.component.scss']
})
export class ProductAssignmentComponent extends BaseComponent implements AfterViewInit {

  auxLinks: Map<string, string>;
  subComponentCode: string = "res_company";
  selectedRespondentGroup: ProductPeriod[] | undefined;

  pageContext: {
    [key: string]: boolean | string | undefined
    p: string | undefined;
    m: string | undefined;
    i: string | undefined;
    s: string | undefined;
    ppi: string | undefined;
    m_c: string | undefined;
    s_s: string | undefined;
  } = {
    p: undefined,
    m: undefined,
    i: undefined,
    s: undefined,
    ppi: undefined,
    m_c: undefined,
    s_s: undefined
  };

  constructor(private _activatedRoute: ActivatedRoute, private _productSpecService: ProductSpecService, private dialog: MatDialog) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
  }

  ngAfterViewInit() {
    this._productSpecService.getProductPeriodByPeriodIdList(this.pageContext.ppi!).then(result => {
      this.selectedRespondentGroup = result;
    });
  }

  selectSubComponent(componentCode: string) {
    this.subComponentCode = componentCode;
  }

  protected override getPageState(): any {
    return this.pageContext;
  }

}
