export enum EventType {
  NOTIFICATION, PROGRESS, SIDE_NAVIGATION
}

export enum ProcessingStatus {
  NONE = "",
  PROGRESS = "in progress",
  COMPLETE_SUCCESS = "completed successfully",
  SYSTEM_ERROR = "failed with system error",
  INFRASTRUCTURE_ERROR = "failed with infrastructure error",
  DATA_ERROR = "failed with validation error"
}

export interface ApplicationEvent {
  type: EventType;
  data: any;
}

export interface UserCache {
  principleName: string;
  pages: PagesCache[];
}

export interface PagesCache {
  pageName: string;
  columns: ColumnCache[];
}

export interface ColumnCache {
  columnDef: string;
  hide: boolean;
}
