<div class="flex-row" id="component-header">
  <ag-subtitle [subTitleSegments]="getSubtitleSegments()" class="subtitle"></ag-subtitle>
  <ng-content></ng-content>
  <div class="flex-row flex-g-s right-align pd-r-m">
    <a *ngIf="redirectTo === 'quilliup'" [href]="util.getQuilliupGlobalPageLink(_store.env, quilliupGuid)" class="primary-link-color quilliup-link" target="_blank">
      Go to Table in Quilliup
      <i class="fa-sharp fa-light fa-arrow-right-long"></i>
    </a>
    <a *ngIf="redirectTo === 'downloadRequest'" [href]="util.getLegacyDownloadRequestPageLink(_store.env)" class="primary-link-color quilliup-link" target="_blank">
      Go to legacy a2 download request page
      <i class="fa-sharp fa-light fa-arrow-right-long"></i>
    </a>
    <a *ngIf="redirectTo === 'verbatimFiles'" [href]="util.getLegacyVerbatimFilesPageLink(_store.env)" class="primary-link-color quilliup-link" target="_blank">
      Go to legacy a2 verbatim files page
      <i class="fa-sharp fa-light fa-arrow-right-long"></i>
    </a>
    <a *ngIf="redirectTo === 'verbatimFileRows'" [href]="util.getLegacyVerbatimFileRowsPageLink(_store.env)" class="primary-link-color quilliup-link" target="_blank">
      Go to legacy a2 verbatim comments page
      <i class="fa-sharp fa-light fa-arrow-right-long"></i>
    </a>
    <a *ngIf="redirectTo === 'responseFile'" [href]="util.getLegacySurveyResponseFilePageLink(_store.env)" class="primary-link-color quilliup-link" target="_blank">
      Go to legacy a2 survey response file page
      <i class="fa-sharp fa-light fa-arrow-right-long"></i>
    </a>
    <ag-auto-refresh [checkedByDefault]="checkAutoRefreshByDefault" (timeout)="this.refreshButtonClick()" *ngIf="!this.hideAutoRefresh" [runCounter]="this.startRefreshTimer" intervals="10,20,30"></ag-auto-refresh>
    <button (click)="this.refreshButtonClick()" *ngIf="!this.hideRefreshButton" class="secondary-button">
      <i class="fa-sharp fa-light fa-arrows-rotate"></i>
      <span>Refresh</span>
    </button>
    <button (click)="this.hideShowColumnsButtonClick()" *ngIf="!this.hideColumnsButton" class="secondary-button">
      <i class="fa-sharp fa-light fa-eye-slash"></i>
      <span>Hide / Show Columns</span>
    </button>
    <button (click)="this.downloadButtonClick()" *ngIf="!this.hideDownloadButton" class="secondary-button">
      <i class="fa-sharp fa-light fa-arrow-down-to-bracket"></i>
      <span>Download</span>
    </button>
    <button *ngIf="!this.hideUploadButton" [queryParams]="uploadUrlParam" [routerLink]="uploadUrl" class="secondary-button">
      <i class="fa-sharp fa-light fa-arrow-up-from-bracket"></i>
      <span>Upload</span>
    </button>
  </div>
</div>
