import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from "@data/interefaces/data.interfaces";
import {map, Observable, startWith} from "rxjs";
import {FormControl} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'ag-menu',
  templateUrl: './ag-menu.component.html',
  styleUrls: ['./ag-menu.component.scss']
})
export class AgMenuComponent implements OnInit {

  @Input()
  navigationMenu!: MenuItem[];

  searchFormControl = new FormControl<string | MenuItem>('');
  searchOptions!: Observable<{ group: string, menuItems: MenuItem[] }[]>;
  private searchMapData: { group: string, menuItems: MenuItem[] }[] = [];

  constructor(private _router: Router) {
  }

  ngOnInit() {
    this.searchMapData = Array.from(this.buildGroupNames(this.navigationMenu, '').values());
    this.searchOptions = this.searchFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterGroup(value))
    );
  }

  getMenuDisplayName(menuItem: MenuItem): string {
    return menuItem ? menuItem.displayName : '';
  }

  clearValue(event: Event) {
    this.searchFormControl.setValue('');
  }

  hasValue() {
    return !!this.searchFormControl.getRawValue()
  }

  clearTypedText(event: KeyboardEvent) {
    if (event.code == 'Backspace' && event.altKey) {
      this.searchFormControl.setValue('');
    }
  }

  private buildGroupNames(navigationMenu: MenuItem[], parentGroup: string,
                          searchMap: Map<string, { group: string, menuItems: MenuItem[] }> = new Map<string, { group: string, menuItems: MenuItem[] }>()) {
    navigationMenu.forEach(menu => {
      if (menu.childMenu != undefined) {
        let groupName = parentGroup == '' ? menu.displayName : parentGroup + ' | ' + menu.displayName;
        this.buildGroupNames(menu.childMenu, groupName, searchMap)
      } else {
        let group = searchMap.get(parentGroup);
        if (group == null) {
          let key = parentGroup == '' ? "Root" : parentGroup;
          searchMap.set(key, {group: key, menuItems: [menu]})
        } else {
          group.menuItems.push(menu);
        }
      }
    });

    return searchMap;
  }

  private filterGroup(value: string | MenuItem | null): { group: string, menuItems: MenuItem[] }[] {
    if (typeof value == 'string') {
      return this.searchMapData.map(element => ({group: element.group, menuItems: this.filterMenu(element.menuItems, value)}))
        .filter(item => item.menuItems.length > 0)
    } else {
      this._router.navigateByUrl((value as MenuItem).fullPath).catch(error => this._router.navigateByUrl('/error'))
    }
    return this.searchMapData;
  }

  private filterMenu(menuItems: MenuItem[], value: string): MenuItem[] {
    return menuItems.filter(item => item.displayName.toLowerCase().includes(value.toLowerCase()));
  }
}
