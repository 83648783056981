import {Injectable} from "@angular/core";
import {
  ColDef,
  ColumnVisibleEvent,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  RowSelectionOptions,
  SelectionColumnDef,
  SideBarDef,
  ToolPanelDef
} from "@ag-grid-community/core";
import {ColumnFilterType} from "@data/enums/data.enums";
import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {LocalStorageService} from "@core/api/local.storage.service";
import {StoreService} from "@core/api/store.service";
import {SelectHeaderComponent} from "@pages/segments/grid/columns/select-header/select-header.component";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";

// Do not add this to the providedIn: root.
// All classes will need to independently add this as a provider in its own component.
@Injectable()
export class AgGridService {

  /**
   * This is the default colId of the ColDef that ag-grid sets for the SelectColumnDef.
   * @private
   */
  private selectColumnId: string = 'ag-Grid-ControlsColumn';

  /**
   * Default value of AG Grid column definition
   * @private
   */
  private defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: ColumnFilterType.TEXT,
    floatingFilter: true,
    filterParams: {defaultOption: 'contains'},
    wrapHeaderText: true,
    autoHeaderHeight: true
  };

  /**
   * Default value of sidebar
   * @private
   */
  private defaultSideBar: SideBarDef = {
    toolPanels: [
      this.getDefaultFilterSideBarPanel(),
      this.getDefaultColumnsSideBarPanel()
    ],
    hiddenByDefault: false,
    position: 'left',
  };

  /**
   * The default values for the MultiRowSelectionOptions
   * @private
   */
  private multiRowSelection: RowSelectionOptions = {
    mode: 'multiRow',
    enableClickSelection: false, // updated for the previous suppressRowClickSelection usage
    hideDisabledCheckboxes: false, // updated for the previous checkboxSelection usage.  This was originally configured on each column def individually
    checkboxes: true,
    headerCheckbox: false
  }

  /**
   * Default value of AG Grid options
   * @private
   */
  private defaultGridOptions: GridOptions = {
    alwaysMultiSort: true,
    defaultColDef: this.defaultColDef,
    paginationPageSize: 50,
    pagination: true,
    sideBar: this.defaultSideBar,
    suppressPaginationPanel: true,
    maintainColumnOrder: true,
    // General note, we can get this onCellClick even in the api.getGridOptions('onCellClicked')
    // It will return this handler, and then we can disable that option and re-set it.
    // This allows us to temporarily disable events in certain rare circumstances.  Note, this only works
    // With events in GridOptions, and not defined in the HTML.
    onCellClicked: (e) => {
      // This method is required for the scenarios of when the user does not click the checkbox directly in the select column cell.
      // this will handle clicking the cell (not the checkbox directly), and properly mark this row as selected.
      if (e.column.getColDef()?.colId === this.selectColumnId) {
        e.node.setSelected(!e.node.isSelected());
      }
    },
  }

  constructor(private _store: StoreService, private _localStorage: LocalStorageService) {
  }

  getDefaultAgiGridOptions() {
    return this.defaultGridOptions;
  }

  getDefaultSideBar() {
    return this.defaultSideBar;
  }

  getDefaultColumnDefinition() {
    return this.defaultColDef;
  }

  getDefaultSelectColumn(cellRendererParamsFn?: (params: any) => any): SelectionColumnDef {
    return {
      sortable: false,
      resizable: false,
      pinned: 'left',
      maxWidth: 75,
      headerComponent: SelectHeaderComponent,
      cellRenderer: SelectColumnComponent,
      lockPinned: true,
      cellRendererParams: function (params: any) {
        return cellRendererParamsFn ? cellRendererParamsFn(params) : {};
      }
    }
  }

  /**
   * This method should be attached to the columnVisible event of the ag-grid.  The main responsibility of this method is to
   * Add the columns that are shown or hidden to the local storage so that their states can be saved
   * @param event
   * @param pageName
   */
  onColumnVisibleHandler(event: ColumnVisibleEvent<any>, pageName: string) {
    if (event?.columns !== undefined && event.columns !== null) {
      for (const column of event.columns) {
        const colDef = column.getColDef();
        colDef.hide = !colDef.hide;
        this.hideShowColumnChangedHandler(colDef, pageName);
      }
    }
  }

  /**
   * This method is responsible for updating the filters with the values we read from the pageContext.
   * @param pageContext the set of values retrieved from our URL
   * @param api the ag-grids api
   */
  updateAgGridFiltersHandler(pageContext: any, api: GridApi) {
    Object.keys(pageContext).forEach(key => {
      if (typeof pageContext[key] === 'boolean') {
        // boolean stuff
      } else {
        if (pageContext[key] !== undefined && pageContext[key] !== null && pageContext[key] !== '') {
          const colDef = this.findColDef(api.getColumnDefs(), key) as GridColumn;
          if (colDef === undefined) {
            throw new Error(`Could not find colDef with filterId: ${key}`);
          }
          if (colDef.filter && colDef.field !== undefined) {
            api.getColumnFilterInstance(colDef.field).then(filterInstance => {
              const model = filterInstance?.getModel();
              if (colDef.field !== undefined) {
                if (model === undefined || model === null) {
                  switch (colDef.filter) {
                    case ColumnFilterType.DROPDOWN:
                      filterInstance?.setModel({
                        filterType: 'set',
                        values: pageContext[key].split(',')
                      });
                      break;
                    case ColumnFilterType.DATE:
                      if (colDef.filterParams?.defaultOption === undefined) {
                        throw new Error(`filterParam defaultOption not set for field: ${colDef.field}`);
                      }
                      const dates = pageContext[key].split('|');
                      filterInstance?.setModel({
                        filterType: 'date',
                        type: colDef.filterParams?.defaultOption,
                        dateFrom: dates[0],
                        dateTo: dates[1]
                      });
                      break;
                    case ColumnFilterType.NUMBER:
                      if (colDef.filterParams?.defaultOption === undefined) {
                        throw new Error(`filterParam defaultOption not set for field: ${colDef.field}`);
                      }
                      filterInstance?.setModel({
                        filterType: 'number',
                        type: colDef.filterParams.defaultOption,
                        filter: pageContext[key]
                      });
                      break;
                    default:
                      if (colDef.filterParams?.defaultOption === undefined) {
                        throw new Error(`filterParam defaultOption not set for field: ${colDef.field}`);
                      }
                      filterInstance?.setModel({
                        filterType: 'text',
                        type: colDef.filterParams.defaultOption,
                        filter: pageContext[key]
                      });
                      break;
                  }
                  api.onFilterChanged();
                }
              }
            });
          }
        }
      }
    });
  }

  /**
   * This method will update the value for the column in the local storage
   * @param pageName
   * @param columns
   */
  updateColumnsFromLocalStorage(pageName: string, columns: (GridColumn | GridColumnGroupDef)[]) {
    const pageCache = this._store.getPageCache(pageName!);
    if (pageCache !== undefined) {
      for (const column of this.getAllGridColumns(columns)) {
        const cachedColumn = pageCache.columns?.find(c => c.columnDef === column.field);
        if (cachedColumn) {
          column.hide = cachedColumn.hide;
        }
      }
    }
  }

  /**
   * This method processes the filter changed event.  It will take the value described from the filtering grid view, and apply it to our pageContext.
   * @param event
   * @param pageContext the object responsible for holding all the filter values for each page.
   * @param updatePageContext
   */
  async processOnFilterChanged(event: FilterChangedEvent<any>, pageContext: any, updatePageContext: (update: boolean) => void) {
    for (const col of event.columns) {
      const colDef = col.getColDef() as GridColumn;
      if (colDef.field && colDef.filter) {
        // await here for the live data filter columns.  If I don't await, it will not properly update the grid with the DB filters.
        await event.api.getColumnFilterInstance(colDef.field).then(filterInstance => {
          if (filterInstance !== undefined && filterInstance !== null) {
            const model = filterInstance.getModel();
            let filterValue = undefined;
            if (model) {
              switch (colDef.filter) {
                case ColumnFilterType.DROPDOWN:
                  filterValue = model.values.join(',');
                  break;
                case ColumnFilterType.DATE:
                  filterValue = new Date(model.dateFrom).toISOString() + '|' + new Date(model.dateTo).toISOString();
                  break;
                default:
                  filterValue = model.filter;
              }
              if (colDef.filterId !== undefined || colDef.filterId === '') {
                pageContext[colDef.filterId] = filterValue;
                updatePageContext(true);
              } else {
                throw new Error(`filterId not set for field ${colDef.field}`)
              }
            } else {
              if (colDef.filterId !== undefined) {
                pageContext[colDef.filterId] = undefined;
                updatePageContext(true);
              }
            }
          }
        });
      }
    }
  }

  getDefaultFilterSideBarPanel(): ToolPanelDef {
    return {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      minWidth: 180,
      maxWidth: 400,
      width: 250,
    };
  }

  getDefaultColumnsSideBarPanel(): ToolPanelDef {
    return {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'filters',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      minWidth: 180,
      maxWidth: 400,
      width: 250,
      toolPanelParams: {
        suppressRowGroups: true,
        suppressPivotMode: true,
        suppressValues: true
      }
    };
  }

  getGridColumns(tableColumns: any): GridColumn[] {
    return (Object.keys(tableColumns) as Array<keyof typeof tableColumns>).map(key => tableColumns[key]);
  }

  getDisplayedColumns(tableColumns: any): string[] {
    return this.getGridColumns(tableColumns).filter(column => column.display!).map(column => column.columnDef!);
  }

  getMultiRowSelectionOptions() {
    return this.multiRowSelection;
  }

  private getAllGridColumns(columns: (GridColumn | GridColumnGroupDef)[]): GridColumn[] {
    const allColumns: GridColumn[] = [];
    for (const column of columns) {
      const gridColDef = column as GridColumnGroupDef;
      if (gridColDef !== undefined) {
        if (gridColDef.children) {
          allColumns.push(...this.getAllGridColumns(gridColDef.children));
          continue;
        }
      }
      const gridCol = column as GridColumn;
      if (gridCol !== undefined) {
        allColumns.push(gridCol);
      }
    }

    return allColumns;
  }

  private findColDef(columns: (GridColumn | GridColumnGroupDef)[] | undefined, filterId: string): GridColumn | GridColumnGroupDef | undefined {
    if (columns !== undefined) {
      for (const col of columns) {
        const gridColumn = col as GridColumn;
        if (gridColumn !== undefined) {
          if (gridColumn.filterId === filterId) {
            return col;
          }
        }
        const gridColumnGroupDef = col as GridColumnGroupDef;
        if (gridColumnGroupDef !== undefined) {
          if (gridColumnGroupDef.children) {
            const column = this.findColDef(gridColumnGroupDef.children, filterId) as GridColumn
            if (column !== undefined) {
              return column;
            }
          }
        }
      }
    }
    return undefined;
  }

  /**
   * Wrapper function for addColumnToLocalStorage()
   * @param column
   * @param pageName
   * @protected
   */
  private hideShowColumnChangedHandler(column: GridColumn, pageName: string) {
    this.addColumnToLocalStorage(pageName, column.field, column.hide);
  }

  private addColumnToLocalStorage(pageName: string, columnDef: string | undefined, hide: boolean | undefined) {
    if (columnDef !== undefined && hide !== undefined) {
      const userCache = this._store.userCache;
      let pageCache = this._store.getPageCache(pageName!);
      if (pageCache === undefined) {
        pageCache = {
          pageName: pageName,
          columns: [{columnDef: columnDef, hide: hide}]
        };
        userCache.pages.push(pageCache);
        this._localStorage.write(this._store.localStorageKey, userCache);
      } else {
        const column = pageCache.columns?.find(column => column.columnDef === columnDef);
        if (column === undefined) {
          pageCache.columns.push({columnDef: columnDef, hide: hide});
        } else {
          column.hide = hide;
        }
        this._localStorage.write(this._store.localStorageKey, userCache);
      }
    }
  }
}
