import {Injectable} from '@angular/core';
import {Client, StompSubscription} from '@stomp/stompjs';
import {Observable, Subject} from 'rxjs';
import {EventbusService} from "@core/event-bus/eventbus.service";
import {EventType} from "@core/interfaces/data.interfaces";
import {NotificationType} from "@data/enums/data.enums";

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private stompClient!: Client;
  private subscription: StompSubscription | null = null;
  private messageSubject: Subject<string> = new Subject();
  private isConnected: boolean = false;

  constructor(private _eventBus: EventbusService) {
  }

  /**
   * Connects to WebSocket endpoint
   *
   * @param queue The queue to subscribe to (e.g., `/user/{username}/queue/notification`)
   */
  public connect(queue: string): void {
    if (this.isConnected) {
      return;
    }
    const url = "/websocket";
    this.stompClient = new Client({
      brokerURL: url,
      reconnectDelay: 5000,
    });

    this.stompClient.onConnect = () => {
      this.isConnected = true;
      this.subscription = this.stompClient.subscribe(queue, (message) => {
        this.messageSubject.next(message.body);
      });
    };

    this.stompClient.onDisconnect = () => {
      this.isConnected = false;
    };

    this.stompClient.onStompError = (frame) => {
      this._eventBus.fire({type: EventType.NOTIFICATION, data: {type: NotificationType.ERROR, message: frame.body}});
    };

    this.stompClient.activate(); // Establish WebSocket connection
  }

  /**
   * Disconnects from the WebSocket and unsubscribes from the topic
   */
  public disconnect(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.stompClient.active) {
      this.stompClient.deactivate().then(r => this.isConnected = false);
    }
  }

  /**
   * Returns an Observable that emits messages from the WebSocket
   * @returns Observable that emits messages
   */
  public getMessages(): Observable<string> {
    return this.messageSubject.asObservable(); // Return the Observable to subscribe to
  }
}
