import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Util} from "@data/util/util";
import {
  excludeClickAction,
  includeClickAction,
  industryCodes,
  marketCodes,
  pageContext,
  periods,
  productCodes,
  surveySubjects,
  tableColumns
} from "@pages/product-spec/product-sponsor-verbatim/product-sponsor-verbatim.component.ds";
import {TableBaseComponent} from "@pages/table.base.component";
import {FilterChangedEvent, FirstDataRenderedEvent, SelectionChangedEvent} from 'ag-grid-community';
import {QuilliupForm} from "@data/enums/quilliup.enum";
import {FeatureComponentMode, ProcessingStatus, ResponseStatus, YesNoValue} from "@data/enums/data.enums";
import {ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {ReferenceService} from "@data/reference/reference.service";
import {LookupService} from "@data/lookup/lookup.service";
import {ProductSponsorVerbatim} from "@data/interefaces/product.sponsor.verbatim.interfaces";
import {ProductSponsorVerbatimService} from "@data/product-spec/product.sponsor.verbatim.service";
import {ProductSpecService} from "@data/product-spec/product.spec.service";
import {ProductSponsorVerbatimComponentUtil} from "@pages/product-spec/product-sponsor-verbatim/product-sponsor-verbatim.component.util";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'app-product-sponsor-verbatim',
  templateUrl: './product-sponsor-verbatim.component.html',
  styleUrls: ['./product-sponsor-verbatim.component.scss'],
  providers: [AgGridService]
})

export class ProductSponsorVerbatimComponent extends TableBaseComponent implements AfterViewInit {

  rowData: ProductSponsorVerbatim[] = [];
  selectedRows: ProductSponsorVerbatim[] = [];
  selectedFeatureMode!: FeatureComponentMode;
  auxLinks: Map<string, string>;
  showSkeleton: boolean = true;
  refreshComplete: boolean = true;
  isFilterActive: boolean = false;
  @ViewChild('footerActionButtonPanel') footerActionButtonPanel: TemplateRef<any> | undefined;

  isBulkOperationAllowed: boolean = false;
  showTaskComponent: boolean = false;

  protected readonly util = Util;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns
  protected readonly responseStatus = ResponseStatus;
  protected readonly QuilliupForm = QuilliupForm;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _productSponsorVerbatimService: ProductSponsorVerbatimService,
    private _referenceService: ReferenceService,
    private _lookupService: LookupService,
    private _productSpecService: ProductSpecService
  ) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    this.loadFilterData();
    this.updatePageContext();
  }

  ngAfterViewInit() {
  }

  async onIncludeExcludeAction(row: ProductSponsorVerbatim, include: boolean) {
    const origInclude = row.include;
    const newInclude = include ? YesNoValue.YES_VALUE : YesNoValue.NO_VALUE;
    this.updateRow(row, origInclude, ProcessingStatus.PROGRESS, '');

    this._productSponsorVerbatimService.includeProductSponsorVerbatim(row.productSaleId, row.verbatimId, include).then(result => {
      if (result.value > 0) {
        // We don't want to refresh the entire grid.  We'll just update the affected records in place.
        // Yes, this is weird, but there is an enhancement logged to clean this up, maybe.
        this.rowData.forEach(productSponsorVerbatim => {
          if (productSponsorVerbatim.productSaleId === row.productSaleId &&
            productSponsorVerbatim.verbatimId === row.verbatimId &&
            productSponsorVerbatim.include !== newInclude
          ) {
            this.updateRow(productSponsorVerbatim, newInclude, ProcessingStatus.COMPLETE_SUCCESS, '');
          }
        });
      } else {
        this.updateRow(row, origInclude, ProcessingStatus.COMPLETE_SUCCESS, '');
      }

      this.displayNotificationMessage('info', `Updated ${result.value} rows to be ${(include ? "included" : "excluded")}.`);
    }).catch(error => {
      // if there is an error, undo the edit!
      const msg = JSON.stringify(error);
      this.updateRow(row, origInclude, ProcessingStatus.DATA_ERROR, msg);
      this.displayNotificationMessage('error', msg);
    }).finally(() => {
    });
  }

  updateRow(row: ProductSponsorVerbatim, include: string, processing_status: ProcessingStatus, processing_note: any) {
    row.include = include;
    row.processing_status = processing_status;
    row.processing_note = processing_note;
    this.gridApi.refreshCells({
      force: true,
      columns: [
        "select",
        "include",
        "processing_status",
        "processing_note",
        "actions"
      ]
    });
  }

  onLiveDataFilterChange(forceReload: boolean = false): void {
    if (forceReload) {
      this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    }
    this.updateToPageContextUrl();
  }

  updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }

  async loadPageData() {
    ProductSponsorVerbatimComponentUtil.setColumns(this.tableColumns, FeatureComponentMode.DEFAULT);
    const periods = this.pageContext.p_f || '';
    const marketCodes = this.pageContext.mc_f || '';
    const industryCodes = this.pageContext.ic_f || '';
    const surveySubjects = this.pageContext.ss_f || '';
    const productCodes = this.pageContext.pc_f || '';
    let thresholds: ResultsetThresholds | undefined;
    this.refreshComplete = false;
    this.displayProgressBar(true);
    let msg = `Checking filter criteria...`;
    this.displayNotificationMessage("info", msg);
    await this._productSponsorVerbatimService.getProductSponsorVerbatimThresholds(
      periods, marketCodes, industryCodes, surveySubjects, productCodes
    ).then(result => {
      thresholds = result;
      this.setRowTemplateMessage(thresholds);
    }).catch(error => {
        this.displayNotificationMessage('error', JSON.stringify(error));
        this.displayProgressBar(false);
        this.refreshComplete = true;
      }
    );

    if (thresholds && !thresholds.hasTooMany && thresholds.count && thresholds.count > 0) {
      const start = new Date();
      const cnt = thresholds.count;
      msg = `Retrieving ${cnt} records, starting at ${Util.getFormattedDate(start)}.  This may take 4-8 minutes...`;
      this.displayNotificationMessage("error", msg);

      await this._productSponsorVerbatimService.getProductSponsorVerbatimByFilter(
        periods, marketCodes, industryCodes, surveySubjects, productCodes
      ).then(result => {
        this.rowData = result;
        const end = new Date();
        msg = `Finished retrieving ${cnt} records in ${Util.calculateTimeDifferent(start, end)} (${Util.getFormattedDate(start)} till ${Util.getFormattedDate(end)}).`;
        this.displayNotificationMessage("error", msg);
      }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
      ).finally(() => {
          this.displayProgressBar(false);
          this.refreshComplete = true;
        }
      );
    } else {
      this.refreshComplete = true;
      this.rowData = [];
      this.displayProgressBar(false);
    }
  }

  loadFilterData() {
    this._lookupService.getPeriods().then(result => {
      result.forEach(item => periods.push(item.period));
    });
    this._referenceService.getAllMarkets().then(result => {
      result.forEach(item => marketCodes.push(item.marketCode));
    });
    this._referenceService.getAllIndustries().then(result => {
      result.forEach(item => industryCodes.push(item.industryCode));
    });
    this._lookupService.getSurveySubjects().then(result => {
      result.forEach(item => surveySubjects.push(item.surveySubject));
    });
    this._productSpecService.getProductCodes().then(result => {
      result.forEach(item => productCodes.push(item.productCode));
    })
  }

  onRowSelectionChanged(event: SelectionChangedEvent) {
    if (event.source == "checkboxSelected") {
      this.gridApi.refreshHeader();
    }
    this.selectedRows = this.getSelectedRows();
    this.isBulkOperationAllowed = (this.selectedRows.length > 0);
  }

  async refreshView() {
    await this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
  }

  goBack() {
    this.showTaskComponent = false;
    this.refreshView();
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    const updateLiveDataFilter = this.isUpdateLiveDataFilterRequired(event.columns);
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this)).then(() => {
      if (updateLiveDataFilter) {
        this.onLiveDataFilterChange(true);
      }
    });
  }

  onBulkIncludeClicked() {
    this.selectedFeatureMode = FeatureComponentMode.BULK_INCLUDE;
    this.showTaskComponent = true;
  }

  protected override initAfterGridReady() {
    this.gridApi.addEventListener(includeClickAction, (event: any) => {
      this.onIncludeExcludeAction(event.detail.rowData, true);
    });
    this.gridApi.addEventListener(excludeClickAction, (event: any) => {
      this.onIncludeExcludeAction(event.detail.rowData, false);
    });
  }

  protected override getTableFooterActionButtonPanel(): TemplateRef<any> | undefined {
    return this.footerActionButtonPanel
  }

  protected override onFirstDataRendered(event: FirstDataRenderedEvent<any>) {
    super.onFirstDataRendered(event);
    event.api.autoSizeAllColumns();
  }
}
