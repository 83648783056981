import { Injectable } from '@angular/core';
import { ApiService} from "@core/api/api.service";
import {ExcelSurveyFileUpload, SurveyFileExists} from "@data/interefaces/survey.response.file.interfaces";
import {Util} from "@data/util/util";

@Injectable({
  providedIn: 'root'
})
export class SurveyResponseFileService {

  constructor(private _apiService: ApiService) {
  }

  public async getAllSurveyResponseFiles(): Promise<ExcelSurveyFileUpload[]> {
    return await this._apiService.makeGetServiceCall<ExcelSurveyFileUpload[]>('/v2/api/survey-response-file');
  }

  public async getSurveyResponseFileById(id: Number): Promise<ExcelSurveyFileUpload> {
    return await this._apiService.makeGetServiceCall<ExcelSurveyFileUpload>(`/v2/api/survey-response-file/${id}`);
  }

  public async checkExistFiles(filenames: string[]): Promise<SurveyFileExists> {
    let queryParams: string[] = [];
    Util.pushQueryParam("fileNames", filenames.join(', '), queryParams);
    return await this._apiService.makeGetServiceCall<SurveyFileExists>(`/v2/api/survey-response-file/exists`, queryParams.join('&'));
  }

  public async uploadFiles(files: File[], description: string): Promise<void> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file, file.name);
    });
    formData.append('description', description);
    return await this._apiService.makeMultipartPostServiceCall<void>(`/v2/api/survey-response-file/upload`, null, formData);
  }

}
