import {Injectable} from '@angular/core';
import {LocalStorageService} from "@core/api/local.storage.service";
import {UserCache} from "@core/interfaces/data.interfaces";

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  env?: string;
  principleName?: string;
  email?: string;
  userGroups?: string[];
  private adminGroups = ["BLUE_CONSOLE_ROLE_ADMIN"];
  private inAdminGroup?: boolean;

  constructor(private _localStoreService: LocalStorageService) {
  }

  private _localStorageKey?: string;

  get localStorageKey(): string {
    if (this._localStorageKey === undefined || this._localStorageKey === null || this._localStorageKey.trim() === '') {
      this._localStorageKey = this.email;
      if (this._localStorageKey === undefined) {
        // default fall back
        this._localStorageKey = this.principleName!;
      }
    }
    return this._localStorageKey;
  }

  private _userCache?: UserCache;

  get userCache(): UserCache {
    let user = this._userCache;
    if (user === undefined) {
      user = {
        principleName: this.principleName!,
        pages: []
      };
    }
    return user;
  }

  /**
   * Returns a boolean if the user is apart of the adminGroups array.
   */
  get isAdmin(): boolean {
    if (this.inAdminGroup === undefined) {
      this.inAdminGroup = this.userGroups?.some(userGroup => this.adminGroups.includes(userGroup));
    }
    return this.inAdminGroup ?? false;
  }

  createLocalStorage() {
    let userCache = this._localStoreService.read(this.localStorageKey);
    if (userCache === undefined || userCache === null || userCache?.trim() === '') {
      // no local storage, create one
      this._userCache = {
        principleName: this.principleName!,
        pages: []
      }
      this._localStoreService.write(this.localStorageKey, this._userCache);
    } else {
      this._userCache = JSON.parse(userCache);
    }
  }

  getPageCache(pageName: string) {
    return this.userCache?.pages.find(page => page.pageName === pageName);
  }

  clearLocalStorage() {
    this._localStoreService.remove(this.localStorageKey);
  }

  // setIsAdmin(roles: string[]) {
  //   this.isAdmin = roles.some(role => this.adminGroups.includes(role));
  // }
}
