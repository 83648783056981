import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {FeatureComponentMode} from "@data/enums/data.enums";
import {TableColumnsUtil} from "@data/util/table-columns-util";

export class ProductSponsorVerbatimComponentUtil extends TableColumnsUtil{

  protected static override setColumn(column: GridColumn, featureComponentMode: FeatureComponentMode) {
    switch (column.field) {
      case 'select':
      case 'actions':
        column.hide = (featureComponentMode !== FeatureComponentMode.DEFAULT);
        break;
      case 'processing_status':
        column.hide = true;
        break;
      case 'processing_note':
        column.hide = (featureComponentMode === FeatureComponentMode.DEFAULT);
        column.downloadable = (featureComponentMode !== FeatureComponentMode.DEFAULT);
        break;
      default:
        // leave as is
        break;
    }
  }

}
