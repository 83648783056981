import {Component, Input} from '@angular/core';

@Component({
  selector: 'ag-datablock',
  templateUrl: './ag-datablock.component.html',
  styleUrls: ['./ag-datablock.component.scss']
})
export class AgDatablockComponent {

  @Input() title: string | undefined;
  @Input() data: any = "-";
  @Input() horizontalAlign = false;
  @Input() url?: string;

  //Usually, either none or one of the following is true at one time
  @Input() isLongData: boolean = false;
  @Input() isDate: boolean = false;
  @Input() isJson: boolean = false;
  @Input() isHtml: boolean = false;
}
