import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AgSlideToggleComponent} from "@shared/ag-slide-toggle/ag-slide-toggle.component";

@Component({
  selector: 'ag-auto-refresh',
  templateUrl: './ag-auto-refresh.component.html',
  styleUrls: ['./ag-auto-refresh.component.scss']
})
export class AgAutoRefreshComponent implements OnInit{

  @Input() intervals: string | undefined;
  @Input() runCounter: boolean = true;
  @Input() checkedByDefault: boolean = false;
  intervalArray: number[] | undefined;
  @Output() protected timeout: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected refreshIn: number;
  protected counter: number | undefined;
  protected status: "enabled" | "disabled" = "disabled";
  private default_refresh_time: number = 30;
  protected selectedInternal: number = this.default_refresh_time;
  @ViewChild('slideToggle') slideToggle?: AgSlideToggleComponent;
  constructor() {
    this.refreshIn = this.default_refresh_time;
  }

  ngOnInit() {
    this.intervalArray = this.intervals == undefined ? [30] : this.intervals.split(',').map(element => parseInt(element))
    this.selectedInternal = this.intervalArray[0];
    this.refreshIn = this.selectedInternal;
    if (this.checkedByDefault) {
      this.startAutoRefresher(true);
    }
  }

  startAutoRefresher(checked: boolean) {
    if (checked) {
      this.status = "enabled";
      this.counter = setInterval(() => {
        if (this.runCounter) {
          this.refreshIn--;
          if (this.refreshIn == 0) {
            this.refreshIn = this.selectedInternal;
            this.runCounter = false;
            this.timeout.emit(true);
          }
        }
      }, 1000);
    } else if (this.counter != undefined) {
      this.refreshIn = this.selectedInternal;
      clearInterval(this.counter);
      this.status = "disabled";
    }
  }

  onRefreshIntervalChange(value: number) {
    this.selectedInternal = value;
    this.refreshIn = this.selectedInternal;
  }

}
