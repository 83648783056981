import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType, LinkType} from "@data/enums/data.enums";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {QuilliupForm} from "@data/enums/quilliup.enum";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {Util} from "@data/util/util";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {ResetFilterComponent} from "@pages/segments/grid/filters/reset-filter/reset-filter.component";

export let pageContext: {
  [key: string]: boolean | string | undefined;
  sc_f: string | undefined;
  olc_f: string | undefined;
  rc_f: string | undefined;
  pc_f: string | undefined;
  ftpc_f: string | undefined;
  rs_f: string | undefined;
  inm_f: string | undefined;
  inv_f: string | undefined;
  icc_f: string | undefined;
  icl_f: string | undefined;
  p_f: string | undefined;
  mc_f: string | undefined;
  mn_f: string | undefined;
  ic_f: string | undefined;
  in_f: string | undefined;
  ss_f: string | undefined;
  fn_f: string | undefined;
  ln_f: string | undefined;
  e_f: string | undefined;
  t_f: string | undefined;
  cp_f: string | undefined;
  c_f: string | undefined;
  jt_f: string | undefined;
  sl_f: string | undefined;
  nbc_f: string | undefined;
  nbl_f: string | undefined;
  cr_f: string | undefined;
  nn_f: string | undefined;
  lrc_f: string | undefined;
  lrl_f: string | undefined;
  scd_f: string | undefined;
  slb_f: string | undefined;
  rlc_f: string | undefined;
  rll_f: string | undefined;
  abc_f: string | undefined;
  bcc_f: string | undefined;
  bcl_f: string | undefined;
  cc_f: string | undefined;
  cl_f: string | undefined;
  brplc_f: string | undefined;
  brpll_f: string | undefined;
  src_f: string | undefined;
  srl_f: string | undefined;
  ssc_f: string | undefined;
  ssl_f: string | undefined;
  sfs_f: string | undefined;
  sfcd_f: string | undefined;
  sf_f: string | undefined;
  sfp_f: string | undefined;
  sfpc_f: string | undefined;
  isfcd_f: string | undefined;
  si_f: string | undefined;
} = {
  sc_f: undefined,
  olc_f: undefined,
  rc_f: undefined,
  pc_f: undefined,
  ftpc_f: undefined,
  rs_f: undefined,
  inm_f: undefined,
  inv_f: undefined,
  icc_f: undefined,
  icl_f: undefined,
  p_f: undefined,
  mc_f: undefined,
  mn_f: undefined,
  ic_f: undefined,
  in_f: undefined,
  ss_f: undefined,
  fn_f: undefined,
  ln_f: undefined,
  e_f: undefined,
  t_f: undefined,
  cp_f: undefined,
  c_f: undefined,
  jt_f: undefined,
  sl_f: undefined,
  nbc_f: undefined,
  nbl_f: undefined,
  cr_f: undefined,
  nn_f: undefined,
  lrc_f: undefined,
  lrl_f: undefined,
  scd_f: undefined,
  slb_f: undefined,
  rlc_f: undefined,
  rll_f: undefined,
  abc_f: undefined,
  bcc_f: undefined,
  bcl_f: undefined,
  cc_f: undefined,
  cl_f: undefined,
  brplc_f: undefined,
  brpll_f: undefined,
  src_f: undefined,
  srl_f: undefined,
  ssc_f: undefined,
  ssl_f: undefined,
  sfs_f: undefined,
  sfcd_f: undefined,
  sf_f: undefined,
  sfp_f: undefined,
  sfpc_f: undefined,
  isfcd_f: undefined,
  si_f: undefined,
};

export let periods: string[] = [];
export let marketCodes: string[] = [];
export let surveyCodes: string[] = [];

export interface NominatedBy {
  code: string;
  label: string;
  surveyIds: string;
  surveyCodes: string;
}

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    headerName: 'Data Filter', children: [
      {
        field: 'period',
        headerName: 'Period',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'p_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: periods,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            closeOnApply: true,
            suppressSorting: true,
            buttons: ['apply', 'reset']
          }
      },
      {
        field: 'marketCode',
        headerName: 'Market Code',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'mc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: marketCodes,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            closeOnApply: true,
            buttons: ['apply', 'reset']
          },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketName
          };
        }
      },
      {
        field: 'surveyCode',
        headerName: 'Survey Code',
        pinned: "left",
        hide: false,
        dbFilter: true,
        filterId: 'sc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: surveyCodes,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            closeOnApply: true,
            buttons: ['apply', 'reset']
          }
      },
    ]
  },
  {
    headerName: 'Invitation', children: [
      {field: 'marketName', headerName: 'Market Name', hide: false, filterId: 'mn_f'},
      {
        field: 'industryCode', headerName: 'Industry Code', hide: true, filterId: 'ic_f',
        filter: ColumnFilterType.DROPDOWN,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryName
          };
        }
      },
      {field: 'industryName', headerName: 'Industry Name', hide: true, filterId: 'in_f'},
      {field: 'surveySubject', headerName: 'Survey Subject', hide: false, filterId: 'ss_f'},
      {
        field: 'responseCode',
        headerName: 'Response Code',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'rc_f',
        filterParams: {buttons: ['reset']},
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View Responses in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.RESPONSES
                },
                {
                  text: "View Ratings in Quilliup",
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.RATINGS
                }
              ]
          };
        }
      },
      {
        field: 'personCode',
        headerName: 'Person Code',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'pc_f',
        filterParams: {buttons: ['reset']},
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.email
          };
        }
      },
      {
        field: 'openLinkCode',
        headerName: 'Open Link Code',
        hide: false,
        filterId: 'olc_f',
        filter: ColumnFilterType.DROPDOWN
      },
      {field: 'forwardedToPersonCodes', headerName: 'Forwarded to Person Codes', hide: false, filterId: 'ftpc_f'},
      {
        field: 'responseStatus',
        headerName: 'Response Status',
        hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'rs_f',
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'invitationName',
        headerName: 'Invitation Name',
        hide: false,
        filterId: 'inm_f',
        filterParams: {buttons: ['reset']}
      },
      {
        field: 'invalid', headerName: 'Invalid?', hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true, cellRenderer: CircleCheckColumnComponent, cellRendererParams: function (params: any) {
            return {
              invertIcons: true,
              iconSize: 'fa-lg'
            }
          }, buttons: ['reset']
        },
        filterId: 'inv_f',
        cellRenderer: CircleCheckColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            invertIcons: true,
            iconSize: 'fa-xl'
          }
        }
      },
      {
        field: 'invitationCompanyCode', headerName: 'Invitation Company Code', hide: false, filterId: 'icc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.invitationCompanyLabel,
            value: params.data.invitationCompanyCode,
            filterId: 'icc_f',
            links:
              [
                {
                  routerLink: '/forms/survey-respondents/survey-tracker',
                  text: 'Survey Tracker',
                  target: '_blank',
                  linkType: LinkType.INTERNAL
                },
              ]
          };
        }
      },
      {
        field: 'invitationCompanyLabel', headerName: 'Invitation Company Label', hide: true, filterId: 'icl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.invitationCompanyCode,
            value: params.data.invitationCompanyLabel,
            filterId: 'icl_f',
            links:
              [
                {
                  routerLink: '/forms/survey-respondents/survey-tracker',
                  text: 'Survey Tracker',
                  target: '_blank',
                  linkType: LinkType.INTERNAL
                },
              ]
          };
        }
      },
    ]
  },
  {
    headerName: 'Demographics', children: [
      {field: 'firstName', headerName: 'First Name', hide: false, filterId: 'fn_f', filterParams: {buttons: ['reset']}},
      {field: 'lastName', headerName: 'Last Name', hide: false, filterId: 'ln_f', filterParams: {buttons: ['reset']}},
      {
        field: 'email', headerName: 'Email', hide: false, filterId: 'e_f', filterParams: {buttons: ['reset']},
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.responseEmail,
            type: 'text',
          };
        }
      },
      {field: 'telephone', headerName: 'Telephone', hide: false, filterId: 't_f', filterParams: {buttons: ['reset']}},
      {field: 'cellPhone', headerName: 'Cell Phone', hide: false, filterId: 'cp_f', filterParams: {buttons: ['reset']}},
      {field: 'city', headerName: 'City', hide: false, filterId: 'c_f', filterParams: {buttons: ['reset']}},
      {field: 'jobTitle', headerName: 'Job Title', hide: false, filterId: 'jt_f', filterParams: {buttons: ['reset']}},
      {
        field: 'surveyLanguage',
        headerName: 'Survey Language',
        hide: false,
        filterId: 'sl_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']}
      }
    ]
  },
  {
    headerName: 'Nominations', children: [
      {
        field: 'nominatedByCodes', headerName: 'Nominated By Codes', hide: true, filterId: 'nbc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.nominatedByLabels
          };
        }
      },
      {
        field: 'nominatedByLabels', headerName: 'Nominated By Labels', hide: true, filterId: 'nbl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        }
      },
      {field: 'categoryResponsibility', headerName: 'Category Responsibility', hide: true, filterId: 'cr_f'},
      {
        field: 'nominationNotes',
        headerName: 'Nomination Notes',
        hide: true,
        filterId: 'nn_f',
        filterParams: {buttons: ['reset']}
      }
    ]
  },
  {
    headerName: 'Pre-selections', children: [
      {
        field: 'localRegionCodes', headerName: 'Local Region Codes', hide: true, filterId: 'lrc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.localRegionLabels
          };
        }
      },
      {
        field: 'localRegionLabels', headerName: 'Local Region Labels', hide: true, filterId: 'lrl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.localRegionCodes
          };
        }
      },
      {
        field: 'seniorityCode', headerName: 'Seniority Code', hide: true, filterId: 'scd_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.seniorityLabel
          };
        }
      },
      {
        field: 'seniorityLabel', headerName: 'Seniority Label', hide: true, filterId: 'slb_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.seniorityCode
          };
        }
      },
      {
        field: 'roleCodes', headerName: 'Role Codes', hide: true, filterId: 'rc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.roleLabels
          };
        }
      },
      {
        field: 'roleLabels', headerName: 'Role Labels', hide: true, filterId: 'rll_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.roleCodes
          };
        }
      },
      {
        field: 'brPlCode', headerName: 'BR/PL Code', hide: true, filterId: 'bpc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.brPlLabel
          };
        }
      },
      {
        field: 'brPlLabel', headerName: 'BR/PL Label', hide: true, filterId: 'bpl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.brPlCode
          };
        }
      },
      {
        field: 'allBuCategories', headerName: 'All BU-Categories?', hide: true, filterId: 'abc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {
          defaultToNothingSelected: true,
          cellRenderer: CircleCheckColumnComponent,
          cellRendererParams: function (params: any) {
            return {
              iconSize: 'fa-lg'
            };
          },
          buttons: ['reset']
        },
        cellRenderer: CircleCheckColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            iconSize: 'fa-xl'
          };
        },
      },
      {
        field: 'buCategoryCodes', headerName: 'BU-Category Codes', hide: true, filterId: 'bcc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.buCategoryLabels,
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.BU_CATEGORIES
                }
              ]
          };
        }
      },
      {
        field: 'buCategoryLabels', headerName: 'BU-Category Labels', hide: true, filterId: 'bcl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.buCategoryCodes
          };
        }
      },
      {
        field: 'channelCodes', headerName: 'Channel Codes', hide: true, filterId: 'cc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.channelLabels,
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.CHANNELS
                },
              ]
          };
        }
      },
      {
        field: 'channelLabels', headerName: 'Channel Labels', hide: true, filterId: 'cl_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.channelCodes
          };
        }
      },
      {
        field: 'subjectRollupCodes', headerName: 'Subject Rollup Codes', hide: true, filterId: 'src_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
      },
      {
        field: 'subjectRollupLabels', headerName: 'Subject Rollup Labels', hide: true, filterId: 'srl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.subjectRollupCodes
          };
        }
      },
      {
        field: 'subjectSingleCodes',
        headerName: 'Subject Single Codes',
        hide: true,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'ssc_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.selectedSubjectSingleLabels,
            currentPageContext: pageContext,
            links:
              [
                {
                  text: 'View in Quilliup',
                  target: '_blank',
                  linkType: LinkType.EXTERNAL,
                  quilliupForm: QuilliupForm.SUBJECT_COMPANIES
                }
              ]
          };
        }
      },
      {
        field: 'subjectSingleLabels', headerName: 'Subject Single Labels', hide: true, filterId: 'ssl_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          },
          buttons: ['reset']
        },
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            subText: params.data.subjectSingleCodes
          };
        }
      },
    ]
  },
  {
    headerName: 'Survey File', children: [
      {
        field: 'surveyFileStatus', headerName: 'Survey File Status', hide: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'sfs_f',
        filterParams: {defaultToNothingSelected: true, buttons: ['reset']},
        cellRenderer: StatusColumnComponent
      },
      {
        field: 'surveyFileCreatedDatetime',
        headerName: 'Survey File Created Datetime', hide: true, filterId: 'sfcd_f',
        filter: ColumnFilterType.DATE,
        filterParams: {
          defaultOption: 'inRange',
          maxNumConditions: 1,
          comparator: Util.dateComparator,
          buttons: ['reset']
        }
      },
      {
        field: 'surveyFile', headerName: 'Survey File', hide: false, filterId: 'sf_f',
        filterParams: {buttons: ['reset']},
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            text: params.data.surveyFile,
            type: 'link',
          };
        }
      },
      {
        field: 'surveyFilePassword',
        headerName: 'Survey File Password',
        hide: true,
        filterId: 'sfp_f',
        filterParams: {buttons: ['reset']}
      },
    ]
  },
  {
    headerName: "ID's", children: [
      {
        field: 'surveyId',
        headerName: 'Survey ID',
        hide: true,
        filterId: 'si_f',
        filter: ColumnFilterType.NUMBER,
        filterParams: {buttons: ['reset']}
      }
    ]
  },
  {
    field: "actions",
    headerName: "Actions",
    pinned: "right",
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: ResetFilterComponent,
    suppressFloatingFilterButton: true,
    suppressHeaderFilterButton: true,
    cellRenderer: ActionColumnComponent,
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRendererParams:
      function (params: any) {
        return {
          actions: [
            {
              toolTip: "Edit",
              icon: "fa-pen-to-square",
              name: "Edit",
              routerLink: ['/forms', 'survey-respondents', 'ms-excel-survey-invitees', 'task', 'edit'],
              queryParams: {sc_f: params.data.surveyCode, rc_f: params.data.responseCode}
            }
          ]
        };
      }
  }
];
