import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType} from "@data/enums/data.enums";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {Util} from "@data/util/util";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";

export let pageContext: {
  [key: string]: boolean | string | undefined
  per_f: string | undefined;
  m_f: string | undefined;
  pt_f: string | undefined;
  p_f: string | undefined;
  s_f: string | undefined;
  l_f: string | undefined;
  rb_f: string | undefined;
  rd_f: string | undefined;
  sd_f: string | undefined;
  ed_f: string | undefined;
  para_f: string | undefined;
  ps_f: string | undefined;
  psm_f: string | undefined;
  sfu_f: string | undefined;
} = {
  per_f: undefined,
  m_f: undefined,
  pt_f: undefined,
  p_f: undefined,
  s_f: undefined,
  l_f: undefined,
  rb_f: undefined,
  rd_f: undefined,
  sd_f: undefined,
  ed_f: undefined,
  para_f: undefined,
  ps_f: undefined,
  psm_f: undefined,
  sfu_f: undefined,
}

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    field: 'period',
    headerName: 'Period',
    hide: false,
    filterId: 'per_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    maxWidth: 100
  },
  {
    field: 'country',
    headerName: 'Market',
    hide: false,
    filterId: 'm_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    maxWidth: 100
  },
  {
    field: 'productType',
    headerName: 'Product Type',
    hide: false,
    minWidth: 300,
    filterId: 'pt_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'product',
    headerName: 'Product',
    hide: false,
    minWidth: 400,
    filterId: 'p_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'sponsor',
    headerName: 'Sponsor',
    hide: false,
    maxWidth: 120,
    filterId: 's_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'language',
    headerName: 'Language',
    hide: false,
    filterId: 'l_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    maxWidth: 120
  },
  {
    field: 'requestedBy',
    headerName: 'Requested By',
    hide: false,
    filterId: 'rb_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'requestDate',
    headerName: 'Request Date',
    hide: false,
    filterId: 'rd_f',
    filter: ColumnFilterType.DATE,
    filterParams: {
      defaultOption: 'inRange',
      maxNumConditions: 1,
      comparator: Util.dateComparator
    },
    useValueFormatterForExport: false,
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },
  {
    field: 'processStartDate',
    headerName: 'Start Date',
    hide: true,
    filterId: 'sd_f',
    filter: ColumnFilterType.DATE,
    filterParams: {
      defaultOption: 'inRange',
      maxNumConditions: 1,
      comparator: Util.dateComparator
    },
    useValueFormatterForExport: false,
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },
  {
    field: 'processEndDate',
    headerName: 'End Date',
    hide: true,
    filterId: 'ed_f',
    filter: ColumnFilterType.DATE,
    filterParams: {
      defaultOption: 'inRange',
      maxNumConditions: 1,
      comparator: Util.dateComparator
    },
    useValueFormatterForExport: false,
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },
  {
    field: 'downloadParameters',
    headerName: 'Parameters',
    hide: false,
    filterId: 'para_f',
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.downloadParameters,
        type: 'json',
        title: 'Parameters'
      };
    }
  },
  {
    field: 'processStatus',
    headerName: 'Status',
    hide: false,
    minWidth: 180,
    filterId: 'ps_f',
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    cellRenderer: StatusColumnComponent
  },
  {
    field: 'processStatusMessage',
    headerName: 'Message',
    hide: false,
    filterId: 'psm_f',
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.processStatusMessage,
        type: 'text',
        title: 'Message'
      };
    }
  },
  {
    field: 'sharepointFolderUrl',
    headerName: 'Sharepoint Folder Url',
    hide: false,
    filterId: 'sfu_f',
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.sharepointFolderUrl,
        type: 'link',
      };
    }
  },
  {
    field: 'action',
    headerName: 'Action',
    hide: false,
    isMandatory: true,
    downloadable: false,
    filter: false,
    pinned: "right",
    maxWidth: 100,
    cellRenderer: ActionColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        actions: [
          {
            toolTip: "Request Detail",
            icon: "fa-pen-to-square",
            name: "Request Detail",
            routerLink: ['task', 'detail'],
            queryParams: {dri_f: params.data.id}
          }
        ]
      };
    }

  }
]
