export enum LegacyDownloadRequestEnvironment {
  PROD = "https://a2console.advantagegroup.com/download-request",
  DEV = "https://a2console-dev.advantagegroup.com/download-request",
  QA = "https://a2console-qa.advantagegroup.com/download-request",
  INTEGRATION = "https://a2console-itg.advantagegroup.com/download-request",
  STAGING = "https://a2console-stg.advantagegroup.com/download-request",
}

export enum DownloadRequestStatus {
  READY_FOR_DOWNLOAD = 'ReadyForDownload',
  In_PROGRESS = 'InProgress',
  PENDING = 'Pending',
  FAILED = 'Failed'
}
