<div class="notification-container">
  <button mat-icon-button [matMenuTriggerFor]="notificationMenu" (menuOpened)="onNotificationPanelOpened()"
          (menuClosed)="onNotificationPanelClosed()">
    <mat-icon><i class="fa-sharp fa-thin fa-bell"></i></mat-icon>
    <div *ngIf="hasUnreadNotifications()" class="red-dot"></div>
  </button>
</div>

<mat-menu #notificationMenu="matMenu" [overlapTrigger]="false">
  <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <div class="notification-popup-content">
      <div class="notification-header">
        <span>Notifications <ng-container *ngIf="unreadCount > 0">({{ unreadCount }})</ng-container></span>
        <span class="clickable-icon" [matMenuTriggerFor]="headerMenu" #headerMenuTrigger="matMenuTrigger">
          <mat-icon>more_horiz</mat-icon>
        </span>
      </div>
      <mat-divider></mat-divider>
      <div *ngFor="let notification of filteredNotifications" class="notification-item">
        <div class="notification-subject" [ngClass]="{'bold-text': notification.notificationStatus === 'pending'}" [innerHTML]="notification.htmlSubject"></div>
        <div class="notification-meta">
          <span class="blue-dot" *ngIf="notification.notificationStatus === 'pending'" matTooltip="Mark as read" (click)="markAsRead(notification)">
          </span>
        </div>
        <div class="notification-body" *ngIf="notification.htmlBody || notification.body" [innerHTML]="notification.htmlBody">
        </div>
        <div class="notification-date" *ngIf="notification.createdTimestamp">
          {{ getRelativeTime(notification.createdTimestamp) }}
        </div>
      </div>
    </div>
  </div>
</mat-menu>

<mat-menu #headerMenu="matMenu">
  <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <button mat-menu-item (click)="markAllAsRead(); closeHeaderMenu();">Mark all as Read</button>
    <mat-divider></mat-divider>
    <button mat-menu-item>
      <mat-slide-toggle [(ngModel)]="unreadOnly" (change)="toggleUnreadOnly($event.checked)">
        Unread only
      </mat-slide-toggle>
    </button>
  </div>
</mat-menu>
