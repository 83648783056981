import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FilterChangedEvent, SelectionColumnDef} from "@ag-grid-community/core";
import {TableBaseComponent} from "@pages/table.base.component";
import {pageContext, subsystemCodeClickAction, tableColumns} from "./status.component.ds";
import {ApplicationStatus} from "src/app/data/interefaces/data.interfaces";
import {AgSidePanelComponent} from "src/app/shared/ag-side-panel/ag-side-panel.component";
import {Util} from "src/app/data/util/util";
import {StatusService} from "@data/system/status/status.service";
import {AgGridService} from "@shared/services/ag-grid.service";

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  providers: [AgGridService]
})
export class StatusComponent extends TableBaseComponent implements AfterViewInit {

  public isFilterActive: boolean = false;
  auxLinks: Map<string, string>;
  @ViewChild('subsystemDetails') subsystemDetailsSidePanel: AgSidePanelComponent | null = null;
  dataSource: ApplicationStatus[] = [];
  isReadOnly = false;
  refreshComplete: boolean = true;
  selectedSubsystem: ApplicationStatus | undefined;
  protected readonly util = Util;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns
  protected readonly JSON = JSON;

  constructor(private _activatedRoute: ActivatedRoute, private _statusService: StatusService) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.refreshView();
    this.updatePageContext();
  }

  ngAfterViewInit() {
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this));
  }

  refreshView() {
    this.refreshComplete = false;
    this.displayProgressBar(true);
    this._statusService.getApplicationStatus().then(result => {
      this.dataSource = result;
    }).catch(error => {
      this.displayNotificationMessage('error', JSON.stringify(error));
    }).finally(() => {
      this.refreshComplete = true;
      this.displayProgressBar(false);
    });
  }

  onSubsystemClick(subsystem: ApplicationStatus) {
    if (this.subsystemDetailsSidePanel) {
      this.selectedSubsystem = subsystem;
      this.subsystemDetailsSidePanel?.toggle();
    }
  }

  protected override updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }

  protected override getSelectionColumnDef(): SelectionColumnDef {
    return {};
  }

  protected override initAfterGridReady() {
    this.gridApi.addGlobalListener((eventType: any, event: any) => {
      switch (eventType) {
        case subsystemCodeClickAction:
          this.onSubsystemClick(event.detail.rowData);
          break;
      }
    });
  }
}
