import {NgModule} from '@angular/core';
import {canActivate} from "@core/auth.guard";
import {RouterModule, Routes} from '@angular/router';

// home pages
import {SigninComponent} from "@pages/signin/signin.component";
import {HomeComponent} from "@pages/home/home.component";

// system pages
import {ArmConfigurationComponent} from "@pages/arm-configuration/arm-configuration.component";

// transfers
import {TransferConfigComponent} from "@pages/transfer-config/transfer-config.component";
import {TransferRunComponent} from "@pages/transfer-run/transfer-run.component";
import {TransferRunDetailComponent} from "@pages/transfer-run-detail/transfer-run-detail.component";
import {TransferManualRunComponent} from "@pages/transfer-manual-run/transfer-manual-run.component";

// verbatim files
import {VerbatimFilesComponent} from "@pages/verbatim-files/verbatim-files.component";
import {VerbatimFileTaskComponent} from "@pages/verbatim-files/verbatim-file-task/verbatim-file-task.component";
//
import {VerbatimFileRowsComponent} from "@pages/verbatim-file-rows/verbatim-file-rows.component";

// PPTX download requests
import {DownloadRequestListComponent} from "@pages/download-request/download-request-list.component";
import {DownloadRequestTaskComponent} from "@pages/download-request/download-request-task/download-request-task.component";

// MS Excel survey response files
import {OfflineSurveyComponent} from "@pages/offline-survey/offline-survey.component";
import {ResponseFileTaskComponent} from "@pages/survey-response-file/response-file-task/response-file-task.component";
import {SurveyResponseFileComponent} from "@pages/survey-response-file/survey-response-file.component";

// product period
import {ProductPeriodComponent} from "@pages/product-spec/product-period/product-period.component";
import {ProductAssignmentComponent} from "@pages/product-spec/product-assignment/product-assignment.component";
import {ProductPeriodTaskComponent} from "@pages/product-spec/product-period/product-period-task/product-period-task.component";

// product respondent company
import {RespondentCompanyComponent} from "@pages/product-spec/product-assignment/respondent-company/respondent-company.component";
import {RespondentCompanyTaskComponent} from "@pages/product-spec/product-assignment/respondent-company/respondent-company-task/respondent-company-task.component";

// product sponsor verbatims
import {ProductSponsorVerbatimComponent} from "@pages/product-spec/product-sponsor-verbatim/product-sponsor-verbatim.component";
import {ProductSponsorVerbatimTaskComponent} from "@pages/product-spec/product-sponsor-verbatim/product-sponsor-verbatim-task/product-sponsor-verbatim-task.component";

// unique link invitations
import {UniqueLinkInviteesComponent} from "@pages/panel/unique-link-invitees/unique-link-invitees.component";
import {UniqueLinkInviteesTaskComponent} from "@pages/panel/unique-link-invitees/unique-link-invitees-task/unique-link-invitees-task.component";

/// open link invitations
import {OpenLinkInviteesComponent} from "@pages/panel/open-link-invitees/open-link-invitees.component";
import {OpenLinkInviteesTaskComponent} from "@pages/panel/open-link-invitees/open-link-invitees-task/open-link-invitees-task.component";

// survey tracker
import {SurveyTrackerComponent} from "@pages/panel/survey-tracker/survey-tracker.component";
import {SurveyTrackerTaskComponent} from "@pages/panel/survey-tracker/survey-tracker-task/survey-tracker-task.component";

// MS Excel invitations
import {MsExcelSurveyInviteesComponent} from "@pages/panel/ms-excel-survey-invitees/ms-excel-survey-invitees.component";
import {MsExcelSurveyInviteesTaskComponent} from "@pages/panel/ms-excel-survey-invitees/ms-excel-survey-invitees-task/ms-excel-survey-invitees-task.component";

// event log
import {EventLogComponent} from "@pages/event-log/event-log.component";
import {EventTypeComponent} from "@pages/event-type/event-type.component";
import {EventTypeTaskComponent} from "@pages/event-type/event-type-task/event-type-task.component";
import {EventProcessorComponent} from "@pages/event-processor/event-processor.component";
import {EventProcessorTaskComponent} from "@pages/event-processor/event-processor-task/event-processor-task.component";
import {StatusComponent} from "@pages/system/status/status.component";

//parent level routes
const homeRoute = 'home';
const formsRoute = 'forms';
const dataManagementRoute = 'data-management';
const systemRoute = 'system';
//second level routes
export const verbatimRoutes = `${dataManagementRoute}/verbatim`
const downloadRoutes = `${dataManagementRoute}/download`
const surveyRoutes = `${dataManagementRoute}/survey`
const adminRoutes = `${dataManagementRoute}/admin`
const panelRoute = `${formsRoute}/panel`;
const productSpecRoute = `${formsRoute}/product-spec`;
const surveyRespondents = `${formsRoute}/survey-respondents`;
const eventsRoute = `${systemRoute}/events`
const transfersRoute = `${systemRoute}/transfers`

const routes: Routes = [
  // home pages
  {path: '', component: SigninComponent},
  {path: `${homeRoute}`, redirectTo: `${homeRoute}/welcome`},
  {path: `${homeRoute}/welcome`, component: HomeComponent, canActivate: [canActivate()]},

  // admin
  {path: `${adminRoutes}/configuration`, component: ArmConfigurationComponent, canActivate: [canActivate()]},

  // transfers
  {path: `${transfersRoute}/schedule`, component: TransferConfigComponent, canActivate: [canActivate()]},
  {path: `${transfersRoute}/run-history`, component: TransferRunComponent, canActivate: [canActivate()]},
  {path: `${transfersRoute}/run-history/:referenceCode/tasks`, component: TransferRunDetailComponent, canActivate: [canActivate()]},
  {path: `${transfersRoute}/run-history/:referenceCode/messages`, component: TransferRunDetailComponent, canActivate: [canActivate()]},
  {path: `${transfersRoute}/start-run-manually`, component: TransferManualRunComponent, canActivate: [canActivate()]},
  {path: `${transfersRoute}/:referenceCode/start-run-manually`, component: TransferManualRunComponent, canActivate: [canActivate()]},

  // verbatim files
  {path: `${verbatimRoutes}/file`, component: VerbatimFilesComponent, canActivate: [canActivate()]},
  {path: `${verbatimRoutes}/file/task/:mode`, component: VerbatimFileTaskComponent, canActivate: [canActivate()]},
  {path: `${verbatimRoutes}/file-rows`, component: VerbatimFileRowsComponent, canActivate: [canActivate()]},

  // PPTX download requests
  {path: `${downloadRoutes}/request`, component: DownloadRequestListComponent, canActivate: [canActivate()]},
  {path: `${downloadRoutes}/request/task/:mode`, component: DownloadRequestTaskComponent, canActivate: [canActivate()]},
  {path: `${downloadRoutes}/new-request`, redirectTo: `${downloadRoutes}/request/task/add`, pathMatch: 'full'},

  // MS Excel survey response files
  {path: `${surveyRoutes}/offline-response`, component: OfflineSurveyComponent, canActivate: [canActivate()]},
  {path: `${surveyRoutes}/response-file`, component: SurveyResponseFileComponent, canActivate: [canActivate()]},
  {path: `${surveyRoutes}/response-file/task/:mode`, component: ResponseFileTaskComponent, canActivate: [canActivate()]},

  // product period
  {path: `${productSpecRoute}/product-period`, component: ProductPeriodComponent, canActivate: [canActivate()]},
  {path: `${productSpecRoute}/product-period/task/:mode`, component: ProductPeriodTaskComponent, canActivate: [canActivate()]},
  {path: `${productSpecRoute}/product-period/assignment`, component: ProductAssignmentComponent, canActivate: [canActivate()]},

  // product respondent company
  {path: `${productSpecRoute}/respondent-company`, component: RespondentCompanyComponent, canActivate: [canActivate()]},
  {path: `${productSpecRoute}/respondent-company/task/:mode`, component: RespondentCompanyTaskComponent, canActivate: [canActivate()]},

  // product sponsor verbatim
  {path: `${productSpecRoute}/product-sponsor-verbatim`, component: ProductSponsorVerbatimComponent, canActivate: [canActivate()]},
  {path: `${productSpecRoute}/product-sponsor-verbatim/task/:mode`, component: ProductSponsorVerbatimTaskComponent, canActivate: [canActivate()]},

  // unique link invitations
  {path: `${surveyRespondents}/unique-link-invitees`, component: UniqueLinkInviteesComponent, canActivate: [canActivate()]},
  {path: `${surveyRespondents}/unique-link-invitees/task/:mode`, component: UniqueLinkInviteesTaskComponent, canActivate: [canActivate()]},

  // open link invitations
  {path: `${surveyRespondents}/open-link-invitees`, component: OpenLinkInviteesComponent, canActivate: [canActivate()]},
  {path: `${surveyRespondents}/open-link-invitees/task/:mode`, component: OpenLinkInviteesTaskComponent, canActivate: [canActivate()]},

  // MS Excel invitations
  {path: `${surveyRespondents}/ms-excel-survey-invitees`, component: MsExcelSurveyInviteesComponent, canActivate: [canActivate()]},
  {path: `${surveyRespondents}/ms-excel-survey-invitees/task/:mode`, component: MsExcelSurveyInviteesTaskComponent, canActivate: [canActivate()]},

  // survey tracker
  {path: `${surveyRespondents}/survey-tracker`, component: SurveyTrackerComponent, canActivate: [canActivate()]},
  {path: `${surveyRespondents}/survey-tracker/task/:mode`, component: SurveyTrackerTaskComponent, canActivate: [canActivate()]},

  // events
  {path: `${eventsRoute}/event-types`, component: EventTypeComponent, canActivate: [canActivate()]},
  {path: `${eventsRoute}/event-types/task/:mode`, component: EventTypeTaskComponent, canActivate: [canActivate()]},
  {path: `${eventsRoute}/event-processors`, component: EventProcessorComponent, canActivate: [canActivate()]},
  {path: `${eventsRoute}/event-processors/task/:mode`, component: EventProcessorTaskComponent, canActivate: [canActivate()]},
  {path: `${eventsRoute}/event-logs`, component: EventLogComponent, canActivate: [canActivate()]},

  // status
  {path: `${systemRoute}/status`, component: StatusComponent, canActivate: [canActivate()]},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
