import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType} from "@data/enums/data.enums";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {Util} from "@data/util/util";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";

export const transferDetailIdClickAction: string = "transfer_detail_id_click_action";

export let pageContext: {
    [key: string]: boolean | string | undefined
    dl_f: string | undefined;
    d_r: string | undefined;
    c_f: string | undefined;
    p_f: string | undefined;
    s_f: string | undefined;
    dm_f: string | undefined;
} = {
    dl_f: undefined,
    d_r: undefined,
    c_f: undefined,
    p_f: undefined,
    s_f: undefined,
    dm_f: undefined,
};

export let tableColumns: (GridColumn | GridColumnGroupDef) [] = [
    {
        field: 'transferDetailId',
        columnHeader: 'Detail Id',
        hide: false,
        isMandatory: false,
        autoHeight: true,
        filter: false,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
            return {
                actionEvent: transferDetailIdClickAction
            };
        }
    },
    {
        field: 'detailLevel',
        columnHeader: 'Level',
        hide: false,
        isMandatory: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'dl_f',
        filterParams: {defaultToNothingSelected: true},
        cellRenderer: StatusColumnComponent
    },
    {
        field: 'detailMessage',
        columnHeader: 'Message',
        hide: false,
        isMandatory: false,
        filter: ColumnFilterType.TEXT,
        filterId: 'dm_f',
        cellRenderer: LongDataColumnComponent,
        cellRendererParams: function (params: any) {
            return {
                text: params.data.detailMessage,
                type: 'text'
            };
        }
    },
    {
        field: 'detailDateTime',
        columnHeader: 'Datetime',
        hide: false,
        isMandatory: false,
        filter: ColumnFilterType.DATE,
        filterId: 'd_f',
        filterParams: {defaultOption: 'inRange', maxNumConditions: 1, comparator: Util.dateComparator},
        valueFormatter: (params) => {
            return Util.getFormattedDate(params.value);
        }
    },
    {
        field: 'confirmitProjectCode',
        columnHeader: 'Project Code',
        hide: false,
        isMandatory: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 'p_f'
    },
    {
        field: 'surveyCode',
        columnHeader: 'Survey Code',
        hide: false,
        isMandatory: false,
        filter: ColumnFilterType.DROPDOWN,
        filterId: 's_f'
    }
]
