<mat-card class="card-content full-height-container backdrop" id="task-component-bulk">
  <mat-card-header class="mg-b-m">
    <div class="header-title">
      <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
      <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
    </div>
  </mat-card-header>
  <mat-card-content class="form-container">
    <div *ngIf="updateStage != 3">
      <div *ngIf="featureComponentMode == FeatureComponentMode.UPLOAD" id="file-processing">
        <h1 class="page-sub-header mg-l-m">
          File Processing | Step {{ updateStage }}/3
        </h1>
        <div class="mg-l-m">
      <span *ngIf="uploadInfo.uploadInitiated">
        <i class="fa-sharp fa-thin fa-xl fa-file mg-r-s"></i> {{ uploadInfo.fileName }} ({{ uploadInfo.fileSize }})
        <span *ngIf="uploadInfo.uploadDataAvailable">
          <i class="fa-sharp fa-thin fa-circle-check fa-xl mg-l-m mg-r-s primary-color"></i>uploaded
        </span>
      </span>
        </div>
        <ag-file-upload (uploadFinished)="uploadFinished($event)" (uploadReset)="resetForm()" (uploadStarted)="uploadStarted($event)"
                        [maxFileSizeSupported]="maxFileSizeSupported" [supportedFileTypes]="this.getSupportedFileTypes()"></ag-file-upload>
      </div>
      <div *ngIf="featureComponentMode != FeatureComponentMode.UPLOAD" class="full-height-container">
        <form [formGroup]="formGroup" id="bulkUpdateForm">
          <div *ngIf="featureComponentMode != FeatureComponentMode.BULK_MAILOUT">
            <div class="flex-row mg-b-m">
              <mat-form-field>
                <mat-label>Invitation Company</mat-label>
                <mat-select formControlName="invitationCompanyCode">
                  <mat-option *ngFor="let invitationCompanyCode of invitationCompanyCodeList"
                              [value]="invitationCompanyCode.respondentCompanyRollupCode"
                  >
                    {{ invitationCompanyCode.respondentCompanyRollupCode + " " + invitationCompanyCode.respondentCompanyRollupLabel }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Invitation Divisions</mat-label>
                <mat-select [multiple]="true" formControlName="invitationDivisionCodes">
                  <mat-option *ngFor="let invitationDivisionCodes of invitationDivisionCodesList"
                              [value]="invitationDivisionCodes.respondentCompanyRollupCode">
                    {{ invitationDivisionCodes.respondentCompanyRollupCode + ' ' + invitationDivisionCodes.respondentCompanyDivisionCode + ' ' + invitationDivisionCodes.respondentCompanyDivisionLabel }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Survey Language</mat-label>
                <mat-select formControlName="surveyLanguage">
                  <mat-option *ngFor="let surveyLanguage of surveyLanguageList"
                              [value]="surveyLanguage.language">
                    {{ surveyLanguage.language }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Nominated By</mat-label>
                <mat-select [multiple]="true" formControlName="nominatedByCodes">
                  <mat-option *ngFor="let buCategories of buCategoriesList"
                              [value]="buCategories.buCategoryCode">
                    {{ buCategories.buCategoryCode + ' ' + buCategories.buCategoryLabel }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex-row mg-b-m">
              <mat-form-field>
                <mat-label>Local Regions</mat-label>
                <mat-select [multiple]="true" formControlName="localRegionCodes">
                  <mat-option *ngFor="let localRegions of localRegionsList"
                              [value]="localRegions.localRegionCode">
                    {{ localRegions.localRegionCode + ' ' + localRegions.localRegionLabel }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex-row mg-b-m toggle-block custom-container">
              <div class="custom-left-container mg-b-m">
                <ag-three-state-selector
                  [formCtrl]="this.getFormControl('invalid')"
                  label="Invalid?"
                  yesLabel="Mark Invalid for All Selected"
                  noLabel="Mark Valid for All Selected"
                ></ag-three-state-selector>
              </div>
              <div class="custom-left-container mg-b-m">
                <ag-three-state-selector
                  [formCtrl]="this.getFormControl('preFillContactInformation')"
                  label="Pre-fill Contact Information?"
                  yesLabel="Pre-fill Contact Information for All Selected"
                  noLabel="Do Not Pre-fill Contact Information for All Selected"
                ></ag-three-state-selector>
              </div>
              <div class="custom-left-container mg-b-m">
                <ag-three-state-selector
                  [formCtrl]="this.getFormControl('preFillSurveySelections')"
                  label="Pre-fill Survey Selections?"
                  yesLabel="Pre-fill Survey Selections for All Selected"
                  noLabel="Do Not Pre-fill Survey Selections for All Selected"
                ></ag-three-state-selector>
              </div>
              <div class="custom-left-container mg-b-m">
                <ag-three-state-selector
                  [formCtrl]="this.getFormControl('lockSurveySelections')"
                  label="Lock Survey Selections?"
                  yesLabel="Lock Survey Selections for All Selected"
                  noLabel="Do Not Lock Survey Selections for All Selected"
                ></ag-three-state-selector>
              </div>
              <div class="custom-left-container mg-b-m">
                <ag-three-state-selector
                  [formCtrl]="this.getFormControl('showDivisionsCollapsed')"
                  label="Show Divisions Collapsed?"
                  yesLabel="Show Divisions Collapsed for All Selected"
                  noLabel="Do Not Show Divisions Collapsed for All Selected"
                ></ag-three-state-selector>
              </div>
              <div class="custom-left-container mg-b-m">
                <ag-three-state-selector
                  [formCtrl]="this.getFormControl('startInReviewMode')"
                  label="Start in Review Mode?"
                  yesLabel="Start in Review Mode for All Selected"
                  noLabel="Do Not Start in Review Mode for All Selected"
                ></ag-three-state-selector>
              </div>
            </div>
            <div class="flex-row custom-container mg-b-m">
              <div class="category-container">
                <mat-form-field class="mg-b-m">
                  <mat-label>Category Responsibility</mat-label>
                  <textarea formControlName="categoryResponsibility" matInput></textarea>
                </mat-form-field>

                <div class="left-bottom-block flex-row">
                  <div class="custom-left-container">
                    <mat-form-field>
                      <mat-label>Seniority</mat-label>
                      <mat-select formControlName="seniorityCode">
                        <mat-option *ngFor="let seniority of senioritiesList"
                                    [value]="seniority.seniorityCode">
                          {{ seniority.seniorityCode + ' ' + seniority.seniorityLabel }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="custom-right-container">
                    <ag-three-state-selector
                    [formCtrl]="this.getFormControl('allBuCategories')"
                      label="All BU-Categories?"
                      yesLabel="Yes BU-Categories for All Selected"
                      noLabel="No BU-Categories for All Selected"
                      class="all-bu-categories"
                    ></ag-three-state-selector>
                  </div>
                </div>
              </div>
              <div class="custom-right-container">
                <mat-form-field>
                  <mat-label>Nomination Notes</mat-label>
                  <textarea formControlName="nominationNotes" matInput></textarea>
                </mat-form-field>
              </div>
            </div>
            <div class="flex-row mg-b-m">
              <mat-form-field>
                <mat-label>Roles</mat-label>
                <mat-select [multiple]="true" formControlName="roleCodes">
                  <mat-option *ngFor="let roles of rolesList"
                              [value]="roles.roleCode">
                    {{ roles.roleCode + ' ' + roles.roleLabel }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>BU-Categories</mat-label>
                <mat-select [multiple]="true" formControlName="buCategoryCodes">
                  <mat-option *ngFor="let buCategories of buCategoriesList"
                              [value]="buCategories.buCategoryCode">
                    {{ buCategories.buCategoryCode + ' ' + buCategories.buCategoryLabel }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Channels</mat-label>
                <mat-select [multiple]="true" formControlName="channelCodes">
                  <mat-option *ngFor="let channels of channelsList"
                              [value]="channels.channelCode">
                    {{ channels.channelCode + ' ' + channels.channelLabel }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>BR/PL</mat-label>
                <mat-select formControlName="brPlCode">
                  <mat-option *ngFor="let industryFlags of industryFlagsList"
                              [value]="industryFlags.brPlCode">
                    {{ industryFlags.brPlCode + ' ' + industryFlags.brPlLabel }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex-row mg-b-m">
              <mat-form-field>
                <mat-label>Subject Rollups</mat-label>
                <mat-select [multiple]="true" formControlName="subjectRollupCodes">
                  <mat-option *ngFor="let subjectRollups of subjectRollupsList"
                              [value]="subjectRollups.subjectCompanyRollupCode">
                    {{ subjectRollups.subjectCompanyRollupCode + ' ' + subjectRollups.subjectCompanyRollupLabel }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Subject Singles</mat-label>
                <mat-select [multiple]="true" formControlName="subjectSingleCodes">
                  <mat-option *ngFor="let subjectSingles of subjectSinglesList"
                              [value]="subjectSingles.subjectCompanySingleCode">
                    {{ subjectSingles.subjectCompanySingleCode + ' ' + subjectSingles.subjectCompanySingleLabel }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Max Total Companies to Rate</mat-label>
                <input formControlName="maxTotalCompaniesToRate" matInput>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Max P1 Companies to Rate</mat-label>
                <input formControlName="maxP1CompaniesToRate" matInput>
              </mat-form-field>
            </div>
            <div class="flex-row mg-b-m">
              <mat-form-field>
                <mat-label>Max P2 Companies to Rate</mat-label>
                <input formControlName="maxP2CompaniesToRate" matInput>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Max P3 Companies to Rate</mat-label>
                <input formControlName="maxP3CompaniesToRate" matInput>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="featureComponentMode == FeatureComponentMode.BULK_MAILOUT" class="flex-row mg-b-m">
            <mat-form-field>
              <mat-label>Mailout Template</mat-label>
              <mat-select formControlName="mailoutTemplateCode">
                <mat-option *ngFor="let mailoutTemplateCode of mailoutTemplateCodeList"
                            [value]="mailoutTemplateCode.mailoutTemplateCode">
                  {{ mailoutTemplateCode.mailoutTemplateCode }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Mailout Field Set</mat-label>
              <mat-select formControlName="mailoutFieldSetCode">
                <mat-option *ngFor="let mailoutFieldsetCode of mailoutFieldsetCodeList"
                            [value]="mailoutFieldsetCode.mailoutFieldsetCode">
                  {{ mailoutFieldsetCode.mailoutFieldsetCode }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <ng-container *ngIf="getFormControl('mailoutDatetime')">
              <ag-datetimepicker [control]="getFormControl('mailoutDatetime')" [label]="'Mailout Datetime'" class="mat-mdc-form-field"></ag-datetimepicker>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="updateStage == 3" class="mg-b-m">
      <div class="bulk-update-progress-panel">
        <div class="bulk-update-progress-top-icon">
          <i class="fa-sharp fa-light fa-table fa-5x"></i>
        </div>
        <div class="flex-row flex-g-m">
          <div>
            <span>Processing Rows ({{ updateStatus.numberOfProcessedRows }} of {{ updateStatus.totalNumberOfRows }})</span>
          </div>
          <div class="right-align">
            <h3>{{ processedPercentage() }}%</h3>
          </div>
        </div>
        <div class="progress-bar-container">
          <mat-progress-bar color="primary" mode="determinate" value="{{processedPercentage()}}"></mat-progress-bar>
        </div>
        <div class="flex-row flex-g-m">
          <div>
            <span>Successful Rows ({{ updateStatus.numberOfSuccessRows }} of {{ updateStatus.numberOfProcessedRows }}) </span>
          </div>
          <div class="right-align">
            <h3>{{ successPercentage() }}%</h3>
          </div>
        </div>
        <div class="progress-bar-container">
          <mat-progress-bar color="accent" mode="determinate" value="{{successPercentage()}}"></mat-progress-bar>
        </div>
        <div class="flex-row flex-g-m">
          <div>
            <span>Unsuccessful Rows ({{ updateStatus.numberOfErrorRows }} of {{ updateStatus.numberOfProcessedRows }}) </span>
          </div>
          <div class="right-align">
            <h3>{{ errorPercentage() }}%</h3>
          </div>
        </div>
        <div class="progress-bar-container">
          <mat-progress-bar color="warn" mode="determinate" value="{{errorPercentage()}}"></mat-progress-bar>
        </div>
      </div>
    </div>
    <div>
      <div class="flex-row flex-g-s action-buttons">
        <button (click)="startProcessing()" *ngIf="updateStage == 2" [disabled]="this.featureComponentMode != FeatureComponentMode.UPLOAD && !this.hasAnyEdits()"
                color="primary" form="bulkUpdateForm" mat-raised-button type="submit">
          <mat-icon class="material-symbols-outlined">save</mat-icon>
          {{ submitButtonText }}
        </button>
        <button (click)="resetForm()" *ngIf="updateStage == 2 && featureComponentMode != FeatureComponentMode.UPLOAD" [disabled]="!this.hasAnyEdits()" mat-button
                type="button">
          <mat-icon class="material-symbols-outlined">cancel</mat-icon>
          Reset
        </button>
        <button (click)="abort()" *ngIf="updateStage == 3" [disabled]="!saveInProgress" mat-button>
          <mat-icon class="material-symbols-outlined">cancel</mat-icon>
          Abort
        </button>
        <button (click)="retry()" *ngIf="updateStage == 3" [disabled]="saveInProgress" mat-button>
          <mat-icon class="material-symbols-outlined">rotate_left</mat-icon>
          Retry
        </button>
        <!--        <button (click)="resetForm(); close()" *ngIf="hasAnyEdits()" [disabled]="!this.hasAnyEdits()" mat-button type="button">-->
        <!--          <mat-icon class="material-symbols-outlined">cancel</mat-icon>-->
        <!--          Cancel-->
        <!--        </button>-->
        <button (click)="resetForm(); close()" *ngIf="updateStage == 2 || updateStage == 3" mat-button type="button">
          <i class="fa-sharp fa-light fa-xmark-large"></i> Close
        </button>
        <button (click)="this.downloadFailedRows('unique-link-invitees-failed')" *ngIf="updateStage == 3" [disabled]="saveInProgress"
                class="right-align failed-rows-download-button" color="primary"
                mat-raised-button>
          <i class="fa-sharp fa-light fa-arrow-down-to-bracket"></i>
          <span class="button-span-margin-left">Download</span>
        </button>
      </div>
      <div class="flex-row full-width mg-t-m">
        <ag-grid-angular (gridReady)="onGridReady($event)"
                         [columnDefs]="tableColumns"
                         [rowData]="selectedRows"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz record-display-table"></ag-grid-angular>
      </div>
    </div>
  </mat-card-content>
</mat-card>
