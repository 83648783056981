import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType} from "@data/enums/data.enums";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";
import {Util} from "@data/util/util";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {VerbatimFileUtil} from "@data/util/verbatim.file.util";


export let pageContext: {
  [key: string]: boolean | string | undefined
  st_s: boolean;
  st_e: boolean;
  sd_r: string | undefined;
  ed_r: string | undefined;
  sc_f: string | undefined;
  ls_f: string | undefined;
  fn_f: string | undefined;
  un_f: string | undefined;
  su_f: string | undefined;
  ud_f: string | undefined;
  ri_f: string | undefined;
  ru_f: string | undefined;
  re_f: string | undefined;
  d_f: string | undefined;
} = {
  st_s: false,
  st_e: false,
  sd_r: undefined,
  ed_r: undefined,
  sc_f: undefined,
  ls_f: undefined,
  fn_f: undefined,
  un_f: undefined,
  su_f: undefined,
  ud_f: undefined,
  ri_f: undefined,
  ru_f: undefined,
  re_f: undefined,
  d_f: undefined,
};

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    maxWidth: 40,
    hide: false,
    filter: false,
    pinned: "left",
    suppressColumnsToolPanel: true,
    downloadable: false,
    cellRenderer: SelectColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        showFlag: params.data.loadStatus == 'Error',
        flagIcon: "fa-exclamation",
        flagColor: "red",
        toolTip: 'Error'
      }
    }
  },
  {

    field: 'fileName',
    headerName: 'File Name',
    hide: false,
    isMandatory: false,
    minWidth: 200,
    filterId: "fn_f"
  },
  {
    field: 'userName',
    headerName: 'User Name',
    hide: false,
    isMandatory: false,
    minWidth: 200,
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true},
    filterId: "un_f"
  },
  {
    field: 'surveyCode',
    headerName: 'Survey Code',
    hide: false,
    isMandatory: false,
    filterId: 'sc_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true},
  },
  // Hide until AFR-10705 Ascribe Verbatims: store uploaded files to SharePoint for reuse and audit purposes is implemented
  // {
  //   field: 'sharePointUrl',
  //   headerName: 'Sharepoint URL',
  //   hide: false,
  //   isMandatory: false,
  //   filter: false,
  //   filterId: 'su_f'
  // },
  {
    field: 'loadStatus',
    headerName: 'Load Status',
    hide: false,
    isMandatory: false,
    filterId: 'ls_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true},
    cellRenderer: StatusColumnComponent
  },
  {
    field: 'uploadDetails',
    headerName: 'Upload Details',
    minWidth: 250,
    hide: false,
    isMandatory: false,
    filterId: "ud_f",
    filter: false,
    valueGetter: function (params: any) {
      return VerbatimFileUtil.getUploadDetails(params.data);
    }
  },
  {
    field: 'recordsInserted',
    headerName: 'Rec. Inserted',
    hide: true,
    isMandatory: false,
    filter: ColumnFilterType.NUMBER,
    filterParams: {
      defaultOption: 'inRange',
      inRangeInclusive: true,
      maxNumConditions: 1,
      buttons: ['reset']
    },
    filterId: 'ri_f'
  },
  {
    field: 'recordsUpdated',
    headerName: 'Rec. Updated',
    hide: true,
    isMandatory: false,
    filter: ColumnFilterType.NUMBER,
    filterParams: {
      defaultOption: 'inRange',
      inRangeInclusive: true,
      maxNumConditions: 1,
      buttons: ['reset']
    },
    filterId: 'ru_f'
  },
  {
    field: 'recordsError',
    headerName: 'Rec. Error',
    hide: true,
    isMandatory: false,
    filter: ColumnFilterType.NUMBER,
    filterParams: {
      defaultOption: 'inRange',
      inRangeInclusive: true,
      maxNumConditions: 1,
      buttons: ['reset']
    },
    filterId: 're_f'
  },
  {
    field: 'startTimestamp',
    headerName: 'Start',
    hide: false,
    isMandatory: false,
    filterId: 'sd_r',
    filter: ColumnFilterType.DATE,
    filterParams: {defaultOption: 'inRange', inRangeInclusive: true, maxNumConditions: 1, comparator: Util.dateComparator},
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },
  {
    field: 'endTimestamp',
    headerName: 'End',
    hide: false,
    isMandatory: false,
    filterId: 'ed_r',
    filter: ColumnFilterType.DATE,
    filterParams: {defaultOption: 'inRange', inRangeInclusive: true, maxNumConditions: 1, comparator: Util.dateComparator},
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },
  {
    field: 'description',
    headerName: 'Description',
    hide: false,
    isMandatory: false,
    filterId: 'd_f'
  },
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    isMandatory: false,
    filter: ColumnFilterType.NUMBER,
    filterParams: {
      defaultOption: 'equals',
      maxNumConditions: 1,
      buttons: ['reset']
    }
  },
  {
    field: 'action',
    headerName: 'Action',
    hide: false,
    isMandatory: true,
    suppressColumnsToolPanel: true,
    downloadable: false,
    filter: false,
    pinned: "right",
    maxWidth: 100,
    cellRenderer: ActionColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        actions: [
          {
            toolTip: "File Detail",
            icon: "fa-square-info",
            name: "File Detail",
            routerLink: ['task', 'detail'],
            queryParams: {vfi_f: params.data.id}
          }
        ]
      };
    }
  }
]


