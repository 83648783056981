import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {CheckboxItem} from "../ag-checkbox-list.component.ds";
import {v4 as uuidv4} from 'uuid';

@Injectable()
export class AgCheckboxListService {
  dataChange = new BehaviorSubject<CheckboxItem[]>([]);

  constructor() {
  }

  get data(): CheckboxItem[] {
    return this.dataChange.value;
  }

  createAgCheckboxList(listData: any[], labelPropertyKey: string) {
    const data = this.buildCheckboxList(listData, labelPropertyKey);
    this.dataChange.next(data);
  }


  private buildCheckboxList(listData: any[], labelPropertyKey: string): CheckboxItem[] {
    const checkboxItem: CheckboxItem[] = [];
    listData.forEach(item => {
      const labelValue = this.extractPropertyByPath(item, labelPropertyKey);
      const node: CheckboxItem = {
        tag: item,
        id: uuidv4(),
        label: labelValue
      }
      checkboxItem.push(node);
    });

    return checkboxItem;
  }

  /**
   * Extract a nested property value from an object using a dot-separated key path.
   * @param obj - The object to extract the property from.
   * @param keyPath - The dot-separated path to the property.
   * @private
   */
  private extractPropertyByPath(obj: any, keyPath: string): any {
    return keyPath.split('.').reduce((acc, key) => acc && acc[key], obj);
  }
}
