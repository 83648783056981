import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {ColumnFilterType} from "@data/enums/data.enums";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {Util} from "@data/util/util";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {ResetFilterComponent} from "@pages/segments/grid/filters/reset-filter/reset-filter.component";

export const runClickAction: string = 'run_now';
export const editClickAction: string = 'edit_transfer';
export const transferCodeClickAction: string = 'transfer_code_link_click';

export let pageContext: {
  [key: string]: boolean | string | undefined
  lr_r: string | undefined;
  nr_r: string | undefined;
  c_f: string | undefined;
  ct_f: string | undefined;
  j_f: string | undefined;
  sc_f: string | undefined;
  s_f: string | undefined;
  d_f: string | undefined;
  sr_f: string | undefined;
  t_f: string | undefined;
  dr_f: string | undefined;
  sd_f: string | undefined;
} = {
  lr_r: undefined,
  nr_r: undefined,
  c_f: undefined,
  ct_f: undefined,
  j_f: undefined,
  sc_f: undefined,
  s_f: undefined,
  d_f: undefined,
  sr_f: undefined,
  t_f: undefined,
  dr_f: undefined,
  sd_f: undefined
};

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    field: 'code', headerName: 'Transfer Code', hide: false, filterId: 'c_f', autoHeight: true,
    minWidth: 300,
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true},
    cellRenderer: MultiLineLinkColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        subText: params.data.jobName,
        actionEvent: transferCodeClickAction
      };
    }
  },
  {
    field: 'category', headerName: 'Category', hide: false, filterId: 'ct_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true}
  },
  {field: 'jobName', headerName: 'Name', hide: true, filterId: 'j_f'},
  {
    field: 'scheduled', headerName: 'Is Scheduled', hide: false, filterId: 'sd_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      cellRenderer: CircleCheckColumnComponent,
      cellRendererParams: function (params: any) {
        return {
          iconSize: 'fa-lg'
        };
      },
    },
    cellRenderer: CircleCheckColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        successIcon: 'fa-kit fa-sharp-light-timer-circle-check', // custom icon requires fa-kit
        failIcon: 'fa-kit fa-sharp-light-timer-circle-xmark',
        iconSize: 'fa-xl'
      }
    }
  },
  {field: 'schedule', headerName: 'Schedule', hide: false, filterId: 'sc_f'},
  {
    field: 'status', headerName: 'Status', hide: false, filterId: 's_f', filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true}, cellRenderer: StatusColumnComponent
  },
  {
    field: 'lastRunDate', headerName: 'Last Run', hide: false, filterId: 'lr_r', filter: ColumnFilterType.DATE,
    filterParams: {
      defaultOption: 'inRange',
      inRangeInclusive: true,
      maxNumConditions: 1,
      comparator: Util.dateComparator
    },
    useValueFormatterForExport: false,
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },
  {
    field: 'nextRunDate', headerName: 'Next Run', hide: false, filterId: 'nr_r', filter: ColumnFilterType.DATE,
    filterParams: {defaultOption: 'inRange', inRangeInclusive: true, maxNumConditions: 1, comparator: Util.dateComparator},
    useValueFormatterForExport: false,
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },
  {
    field: 'description', headerName: 'Description', hide: true, filterId: 'd_f',
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.description,
        type: 'text',
      };
    }
  },
  {
    field: 'source', headerName: 'Source', hide: true, filterId: 'sr_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true}
  },
  {
    field: 'target', headerName: 'Target', hide: true, filterId: 't_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true}
  },
  {
    field: 'defaultRunConfiguration', headerName: 'Parameters', hide: true, filterId: 'dr_f',
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.defaultRunConfiguration,
        type: 'text',
      };
    }
  },
  {
    field: "actions",
    headerName: "Actions",
    pinned: "right",
    suppressFiltersToolPanel: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: ResetFilterComponent,
    suppressFloatingFilterButton: true,
    suppressHeaderFilterButton: true,
    cellRenderer: ActionColumnComponent,
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRendererParams: function (params: any) {
      return {
        actions: [
          {
            toolTip: "Run Now",
            icon: "fa-play",
            name: "Run Now",
            actionEvent: runClickAction
          },
          {
            toolTip: "Edit",
            icon: "fa-pen-to-square",
            name: "Edit",
            actionEvent: editClickAction
          },
          {
            toolTip: "Run History",
            icon: "fa-rectangle-vertical-history",
            name: "Run History",
            routerLink: ['/system', 'transfers', 'run-history'],
            queryParams: {t_f: params.data.code}
          },
          {
            toolTip: "Manual Run",
            icon: "fa-user-gear",
            name: "Manual Run",
            routerLink: ['/system', 'transfers', params.data.code.toLowerCase(), 'start-run-manually']
          },
        ]
      };
    }
  }
]
