import {Component} from '@angular/core';

@Component({
  selector: 'app-arm-configuration',
  templateUrl: './arm-configuration.component.html',
  styleUrls: ['./arm-configuration.component.scss']
})
export class ArmConfigurationComponent {

}
