import {BaseComponent} from "@pages/base.component";
import {Directive, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {DownloadRequestType, LookupLocale, Period, StandardReportType, SurveySubject} from "@data/interefaces/lookup.interfaces";
import {ViewIndustry, ViewMarket} from "@data/interefaces/reference.interfaces";
import {ReportType, WeightedProductLabel} from "@data/interefaces/product.interfaces";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {DownloadRequestData, ViewDownloadRequest} from "@data/interefaces/download.request.interfaces";
import {SelectionChangeType} from "@data/enums/data.enums";
import {PptDownloadsService} from "@data/ppt-downloads/ppt-downloads.service";
import {AgCheckboxTreeService} from "@shared/ag-checkbox-tree/services/ag-checkbox-tree.service";
import {AdvantageReportService} from "@pages/download-request/download-request-add/components/services/advantage-report.service";

@Directive({
  providers: [AgCheckboxTreeService]
})
export abstract class AdvantageReportBaseComponent extends BaseComponent implements OnChanges, OnInit {
  @Input({required: true}) selectedPeriod?: Period;
  @Input({required: true}) selectedMarket?: ViewMarket;
  @Input({required: true}) selectedIndustry?: ViewIndustry;
  @Input({required: true}) selectedSurveySubject?: SurveySubject;
  @Input({required: true}) selectedDownloadType?: DownloadRequestType;
  @Input({required: true}) selectedLocale?: LookupLocale;
  @Input({required: true}) selectedProducts: WeightedProductLabel[] = [];
  @Input({required: true}) standardReportTypes: StandardReportType[] = [];
  @Input({required: true}) reportTypes: ReportType[] = [];
  @Input({required: true}) currentBatchCode: string = '';
  @Input({required: true}) originalBatchCodeFromRetry?: string = '';
  /**
   * The listener for the event emitted from the parent.  This is converted to an Observable (from subject) because we do not want the
   * child to have the ability to call .next().
   */
  @Input({required: true}) resetEvent?: Observable<void>;
  @Input({required: true}) retryEvent?: Observable<ViewDownloadRequest[]>;
  @Output() downloadRequests: EventEmitter<{ items: DownloadRequestData[], changeType: SelectionChangeType }> = new EventEmitter<{
    items: DownloadRequestData[],
    changeType: SelectionChangeType
  }>();
  @Output() isValidSelectionData: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * This event is used currently used for the retry mechanism only.  I need to signal to the parent that the selected products
   * have finished processing, in order for me to accurately continue setting up the screen for retry.  This will avoid any
   * race conditions happening during the time of rendering.
   */
  @Output() selectedProductsProcessed: EventEmitter<void> = new EventEmitter<void>();
  pendingDownloadRequests = new BehaviorSubject<any>({});
  protected _pptDownloadService: PptDownloadsService;
  protected _checkboxTreeService: AgCheckboxTreeService;
  protected _advantageReportService: AdvantageReportService;
  protected parametersSubscription?: Subscription;
  protected resetEventSubscription?: Subscription;
  protected retryEventSubscription?: Subscription;

  protected constructor() {
    super();
    this._pptDownloadService = inject(PptDownloadsService);
    this._checkboxTreeService = inject(AgCheckboxTreeService);
    this._advantageReportService = inject(AdvantageReportService);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedProducts']) {
      this.handleSelectedProductsChanged(changes['selectedProducts'].currentValue);
    }
  }

  ngOnDestroy() {
    this.resetAllData();
    this.unsubscribeFromSubscriptions();
  }

  protected abstract handleSelectedProductsChanged(value: WeightedProductLabel[]): Promise<void>;

  protected abstract addOrUpdateDownloadRequest(changeType: SelectionChangeType): void;

  protected abstract resetAllData(): void;

  protected abstract onRetryEvent(downloadRequestsToRetry: ViewDownloadRequest[]): Promise<void>;

  protected initSubscriptions() {
    this.parametersSubscription = this.pendingDownloadRequests.subscribe(params => this.handleDownloadRequestDataChanged(params.items, params.changeType));
    this.resetEventSubscription = this.resetEvent?.subscribe(() => this.resetAllData());
    this.retryEventSubscription = this.retryEvent?.subscribe((downloadRequestsToRetry) => this.onRetryEvent(downloadRequestsToRetry));
  }

  private unsubscribeFromSubscriptions() {
    this.parametersSubscription?.unsubscribe();
    this.resetEventSubscription?.unsubscribe();
    this.retryEventSubscription?.unsubscribe();
  }

  private async handleDownloadRequestDataChanged(downloadRequests: DownloadRequestData[], changeType: SelectionChangeType) {
    this.downloadRequests.emit({items: downloadRequests, changeType: changeType});
  }
}

