import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {debounceTime} from "rxjs";
import {environment} from "@environments/environment";
import {FieldConfiguration} from "@data/interefaces/data.interfaces";

@Component({
  selector: 'ag-dynamic-panel',
  templateUrl: './ag-dynamic-panel.component.html',
  styleUrls: ['./ag-dynamic-panel.component.scss']
})
export class AgDynamicPanelComponent implements OnChanges {

  @Input() panelConfig: string | undefined;
  @Input() fieldPerRow: number = 3;
  @Input() isReadOnly: boolean = false;
  @Output() valueChange: EventEmitter<{ field: string, value: any }> = new EventEmitter<{ field: string; value: any }>();
  @Output() panelValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  fieldConfigList: FieldConfiguration[] = [];

  fieldGrid: Map<string, FormField[]> | undefined;
  formGroup: FormGroup;

  constructor() {
    this.formGroup = new FormGroup<any>([])
  }

  // This method fires everytime an @Input value changes.
  // We may need to take advantage of SimpleChanges parameter to ensure
  // only work gets done when certain inputs change.
  ngOnChanges(changes: SimpleChanges) {
    for (const inputName in changes) {
      if (changes.hasOwnProperty(inputName)) {
        switch (inputName) {
          case 'panelConfig': {
            if (changes['panelConfig'].currentValue !== changes['panelConfig'].previousValue) {
              this.configurePanel();
            }
          }
        }
      }
    }
  }

  configurePanel() {
    if (this.panelConfig) {
      let formFieldList: FormField [] = [];
      this.fieldConfigList = JSON.parse(this.panelConfig);
      this.fieldConfigList.forEach(config => {
        let formControl = this.buildFormControl(config);
        this.formGroup.addControl(config.fieldId, formControl);
        formFieldList.push({
          config: config,
          formControl: formControl
        });
      });
      this.fieldGrid = new Map<string, FormField[]>();
      for (let i = 0; i < formFieldList.length; i += this.fieldPerRow) {
        this.fieldGrid.set("row" + i, formFieldList.slice(i, i + this.fieldPerRow));
      }
      this.formGroup.statusChanges.subscribe(() => this.panelValid.emit(this.formGroup.valid))
    } else {
      // clean up screen from previous render if there are no run configurations.
      if (this.fieldConfigList.length > 0) {
        this.fieldGrid = undefined;
        this.fieldConfigList = [];
      }
    }
  }

  onChange(fieldName: string, value: any) {
    this.valueChange.emit({field: fieldName, value: value});
  }

  getRowList() {
    return this.fieldGrid ? [...this.fieldGrid.keys()] : [];
  }

  getRowFields(rowId: string) {
    return this.fieldGrid?.get(rowId);
  }

  getSplitArray(stringToParse: string | undefined) {
    if (stringToParse) {
      return stringToParse.split(',');
    }
    return [];
  }

  private buildFormControl(fieldConfiguration: FieldConfiguration) {
    let formControl = new FormControl();
    formControl.valueChanges.pipe(debounceTime(environment.debounceTime)).subscribe(value => this.onChange(fieldConfiguration.fieldId, value));
    if ("string" == fieldConfiguration.type || "number" == fieldConfiguration.type) {
      formControl.setValue(fieldConfiguration.value);
    } else if ("select" == fieldConfiguration.type && fieldConfiguration.value !== undefined) {
      fieldConfiguration.mode == "multiple" ? formControl.setValue(fieldConfiguration.value.split(',')) : formControl.setValue(fieldConfiguration.value.toString());
    }
    formControl.addValidators(Validators.required)
    if (fieldConfiguration.validationRegEx) {
      formControl.addValidators(Validators.pattern(fieldConfiguration.validationRegEx));
    }
    if (this.isReadOnly) {
      formControl.disable({onlySelf: true});
    }
    return formControl;
  }

}

export interface FormField {
  config: FieldConfiguration,
  formControl: FormControl
}
