import {Component, OnInit} from '@angular/core';
import {SecurityService} from "@core/secutiry/security.service";
import {NavigationEnd, Router} from "@angular/router";
import {NavigationService} from "@core/api/navigation.service";
import {EnvironmentConfiguration} from "@data/enums/data.enums";
import {AuthenticationContext, MenuItem} from "@data/interefaces/data.interfaces";
import {StoreService} from "@core/api/store.service";
import {Util} from "@data/util/util";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  authenticationContext: AuthenticationContext | undefined;
  environmentInfo: Map<string, string> | undefined;
  public mainMenu: string[] = [];
  navigationMenu: MenuItem[] = [];
  protected readonly EnvironmentConfiguration = EnvironmentConfiguration;
  private menuStructure: any = {};

  constructor(private _router: Router, private _navigationService: NavigationService, private _securityService: SecurityService, private _store: StoreService) {
    _router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        _navigationService.push(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit() {
    this._securityService.getAuthenticationContext().then(context => {
      this._store.principleName = context.principleName;
      this._store.email = context.authenticationAttributes['email'];
      this._store.userGroups = context.authenticationAttributes['user-groups'];
      this.authenticationContext = context;
      this.navigationMenu = context.authenticationAttributes['navigation'];
      this.buildMainMenu(this.navigationMenu)
      this.buildMenuStructure(this.navigationMenu);
      this.updateNavigationMenuLabel(this.navigationMenu);
      this._store.createLocalStorage();
    }).catch(() => {
      this.authenticationContext = undefined;
      this._store.principleName = undefined;
      this._store.email = undefined;
    });

    this._securityService.getEnvironmentInfo().then(result => {
        this.environmentInfo = new Map(Object.entries(result.env));
        this._store.env = this.environmentInfo.get(EnvironmentConfiguration.APPLICATION_ENVIRONMENT_NAME);
      }
    ).catch(() => {
      this.environmentInfo = undefined;
      this._store.env = undefined;
    });
  }

  public signOut() {
    this._securityService.signOut()
  }

  //Note: These are tem methods to handle how the current UI works, when new UI changes coming in, this will become obsolete
  private buildMainMenu(menuItems: MenuItem[]) {
    menuItems.forEach(element => this.mainMenu.push(element.pathSegment))
  }

  //Note: These are tem methods to handle how the current UI works, when new UI changes coming in, this will become obsolete
  private buildMenuStructure(menuItems: MenuItem[]) {
    menuItems.forEach(item => {
      this.menuStructure[item.pathSegment] = this.getChildMenuList(item.childMenu)
    });
  }

  private updateNavigationMenuLabel(menuItems: MenuItem[]) {
    menuItems.forEach(item => {
      item.displayName = Util.formatDashSeperatedStringToDisplayLabel(item.pathSegment);
      if (item.childMenu != undefined) {
        this.updateNavigationMenuLabel(item.childMenu);
      }
    })
  }

  private getChildMenuList(menuItems: MenuItem[] | undefined) {
    let returnArray: string[] = [];
    menuItems?.forEach(item => {
      returnArray.push(item.pathSegment);
    })

    return returnArray;
  }

}
