import {Component} from '@angular/core';
import {StoreService} from "@core/api/store.service";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {ICellRendererParams} from "@ag-grid-community/core";

@Component({
  selector: 'app-long-data-column',
  templateUrl: './long-data-column.component.html',
  styleUrls: ['./long-data-column.component.scss']
})
export class LongDataColumnComponent implements ICellRendererAngularComp {
  params!: any
  text?: string;
  type?: 'text' | 'link' | 'json' | 'html';
  title?: string; //if type is json or html, title must not be null

  constructor(private _store: StoreService) {
  }

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.text = this.params.text;
    this.type = this.params.type;
    this.title = this.params.title;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
}
