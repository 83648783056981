<mat-form-field class="date-range full-width max-width">
  <mat-label>{{ dateLabel }}</mat-label>
  <mat-date-range-input #dateRange [formGroup]="range" [rangePicker]="picker">
    <input formControlName="start" matStartDate placeholder="Start date">
    <input (dateChange)="onEndDateChange(dateRange)" formControlName="end" matEndDate placeholder="End date">
  </mat-date-range-input>
  <mat-datepicker-toggle [for]="picker" matIconSuffix>
    <mat-icon class="material-symbols-outlined icon-size" matDatepickerToggleIcon>calendar_month</mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker-toggle (click)="resetDates()" matIconSuffix>
    <mat-icon class="material-symbols-outlined icon-size" matDatepickerToggleIcon>clear</mat-icon>
  </mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
