import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCardModule} from "@angular/material/card";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatListModule} from "@angular/material/list";
import {MatGridListModule} from "@angular/material/grid-list";
import {AgDatepickerComponent} from '@shared/ag-datepicker/ag-datepicker.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatNativeDateModule} from "@angular/material/core";
import {AgDateRangeComponent} from './ag-date-range/ag-date-range.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTableModule} from "@angular/material/table";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatRadioModule} from "@angular/material/radio";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatPaginatorModule} from "@angular/material/paginator";
import {AgContextLinkComponent} from './ag-context-link/ag-context-link.component';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {MatSortModule} from "@angular/material/sort";
import {AgExpansionPanelComponent} from '@shared/ag-expansion-panel/ag-expansion-panel.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatBadgeModule} from "@angular/material/badge";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {AgStatusChipComponent} from '@shared/ag-status-chip/ag-status-chip.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {AgSidePanelComponent} from '@shared/ag-side-panel/ag-side-panel.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatMenuModule} from "@angular/material/menu";
import {AgAutoRefreshComponent} from '@shared/ag-auto-refresh/ag-auto-refresh.component';
import {AgDatablockComponent} from '@shared/ag-datablock/ag-datablock.component';
import {MatTabsModule} from "@angular/material/tabs";
import {AgDynamicPanelComponent} from '@shared/ag-dymanic-panel/ag-dynamic-panel.component';
import {AgSkeletonDirective} from '@shared/ag.skeleton.directive';
import {AgSkeletonComponent} from '@shared/ag-skeleton/ag-skeleton.component';
import {AgConfirmationDialogComponent} from '@shared/ag-confirmation-dialog/ag-confirmation-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {AgDrawerComponent} from '@shared/ag-drawer/ag-drawer.component';
import {AgGridAngular} from '@ag-grid-community/angular';
import {ModuleRegistry} from '@ag-grid-community/core';
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ClipboardModule as AgGridClipboardModule} from "@ag-grid-enterprise/clipboard";
import {CsvExportModule} from "@ag-grid-community/csv-export";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {FiltersToolPanelModule} from "@ag-grid-enterprise/filter-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {MultiFilterModule} from '@ag-grid-enterprise/multi-filter';
import {RowGroupingModule} from "@ag-grid-enterprise/row-grouping";
import {SideBarModule} from "@ag-grid-enterprise/side-bar";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {StatusBarModule} from "@ag-grid-enterprise/status-bar";

import {AgSlideToggleComponent} from '@shared/ag-slide-toggle/ag-slide-toggle.component';
import {AgThreeStateSelector} from '@shared/ag-three-state-selector/ag-three-state-selector';
import {AgTaskComponent} from '@shared/ag-task/ag-task.component';
import {AgKpiSidebarComponent} from '@shared/ag-kpi-sidebar/ag-kpi-sidebar.component';
import {AgClassifierDirective} from "@shared/ag.classifier.directive";
import {AgTableFooterComponent} from "@shared/ag-table-footer/ag-table-footer.component";
import {AgFileUploadComponent} from './ag-file-upload/ag-file-upload.component';
import {AgDndDirective} from './ag.dnd.directive';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {AgMenuComponent} from './ag-menu/ag-menu.component';
import {AgSubtitleComponent} from './ag-subtitle/ag-subtitle.component';
import {AgSubMenuComponent} from './ag-menu/ag-sub-menu/ag-sub-menu.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule} from "@angular-material-components/datetime-picker";
import {BrowserModule} from "@angular/platform-browser";
import {AgDatetimepickerComponent} from './ag-datetimepicker/ag-datetimepicker.component';
import {AgDialogComponent} from "@shared/ag-dialog/ag-dialog.component";
import {AgExpandDialogButtonComponent} from "@shared/ag-button/ag-expand-dialog-button/ag-expand-dialog-button.component";
import {AgCopyLinkButtonComponent} from "@shared/ag-button/ag-copy-link-button/ag-copy-link-button.component";
import {AgCopyTextButtonComponent} from "@shared/ag-button/ag-copy-text-button/ag-copy-text-button.component";
import {AgCheckboxTreeComponent} from './ag-checkbox-tree/ag-checkbox-tree.component';
import {MatTreeModule} from "@angular/material/tree";
import {AgCheckboxListComponent} from './ag-checkbox-list/ag-checkbox-list.component';

import {AgCharts} from "ag-charts-angular";
import {AgSearchDropdownComponent} from './ag-search-dropdown/ag-search-dropdown.component';
import {AgNotFoundComponent} from './ag-not-found/ag-not-found.component';
import {AgNotificationComponent} from '@shared/ag-notification/ag-notification.component';

@NgModule({
  declarations: [
    AgDatepickerComponent,
    AgDateRangeComponent,
    AgContextLinkComponent,
    AgExpansionPanelComponent,
    AgStatusChipComponent,
    AgSidePanelComponent,
    AgAutoRefreshComponent,
    AgDatablockComponent,
    AgDynamicPanelComponent,
    AgSkeletonComponent,
    AgConfirmationDialogComponent,
    AgSkeletonComponent,
    AgDrawerComponent,
    AgSkeletonDirective,
    AgSlideToggleComponent,
    AgThreeStateSelector,
    AgTaskComponent,
    AgKpiSidebarComponent,
    AgTableFooterComponent,
    AgClassifierDirective,
    AgFileUploadComponent,
    AgDndDirective,
    AgMenuComponent,
    AgSubtitleComponent,
    AgSubMenuComponent,
    AgDatetimepickerComponent,
    AgDialogComponent,
    AgExpandDialogButtonComponent,
    AgCopyLinkButtonComponent,
    AgCopyTextButtonComponent,
    AgCheckboxTreeComponent,
    AgCheckboxListComponent,
    AgSearchDropdownComponent,
    AgNotFoundComponent,
    AgNotificationComponent
  ],
  imports: [
    CommonModule,
    MatGridListModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatCardModule,
    MatSnackBarModule,
    NgOptimizedImage,
    MatListModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatTableModule,
    MatInputModule,
    MatTooltipModule,
    MatPaginatorModule,
    RouterLink,
    RouterLinkActive,
    MatSortModule,
    MatChipsModule,
    MatBadgeModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatDialogModule,
    ClipboardModule,
    AgGridAngular,
    BrowserModule,
    BrowserAnimationsModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    AgCharts,
    MatTreeModule,
    AgCharts,
  ],
  exports: [
    CommonModule,
    MatGridListModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatCardModule,
    MatSnackBarModule,
    NgOptimizedImage,
    MatListModule,
    MatTableModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatInputModule,
    MatTooltipModule,
    MatPaginatorModule,
    RouterLink,
    MatSortModule,
    MatChipsModule,
    MatBadgeModule,
    MatProgressBarModule,
    MatSidenavModule,
    ClipboardModule,
    AgDatepickerComponent,
    AgDateRangeComponent,
    AgContextLinkComponent,
    AgSidePanelComponent,
    AgStatusChipComponent,
    AgExpansionPanelComponent,
    AgAutoRefreshComponent,
    AgDatablockComponent,
    AgDynamicPanelComponent,
    AgSkeletonComponent,
    AgDrawerComponent,
    AgSlideToggleComponent,
    AgThreeStateSelector,
    AgSkeletonDirective,
    MatSlideToggleModule,
    MatMenuModule,
    MatTabsModule,
    MatDialogModule,
    AgTaskComponent,
    AgGridAngular,
    AgKpiSidebarComponent,
    AgTableFooterComponent,
    AgClassifierDirective,
    AgFileUploadComponent,
    AgDndDirective,
    AgMenuComponent,
    AgSubtitleComponent,
    BrowserModule,
    BrowserAnimationsModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    AgDatetimepickerComponent,
    AgExpandDialogButtonComponent,
    AgCopyLinkButtonComponent,
    AgCopyTextButtonComponent,
    AgCharts,
    AgCheckboxTreeComponent,
    AgCheckboxListComponent,
    AgCharts,
    AgSearchDropdownComponent,
  ]
})
export class SharedModule {
  constructor() {
    ModuleRegistry.registerModules([
      AgGridClipboardModule,
      ClientSideRowModelModule,
      ColumnsToolPanelModule,
      CsvExportModule,
      ExcelExportModule,
      FiltersToolPanelModule,
      MultiFilterModule,
      RowGroupingModule,
      SetFilterModule,
      SideBarModule,
      StatusBarModule,
      MenuModule
    ]);
  }


}
