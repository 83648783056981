import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {ColumnFilterType, TransferRunStatus} from "@data/enums/data.enums";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {Util} from "@data/util/util";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";
import {DurationColumnComponent} from "@pages/segments/grid/columns/duration-column/duration-column.component";

export const stopClickAction: string = 'stop_button_link_click';
export const transferCodeClickAction: string = 'transfer_code_link_click';

export let pageContext: {
  [key: string]: boolean | string | undefined
  st_ip: boolean;
  st_a: boolean;
  st_c: boolean;
  st_ce: boolean;
  sd_r: string | undefined;
  ed_r: string | undefined;
  c_f: string | undefined;
  kw_f: string | undefined;
  t_f: string | undefined;
  d_s: string | undefined;
  d_e: string | undefined;
  il_f: boolean;
} = {
  st_ip: false,
  st_a: false,
  st_c: false,
  st_ce: false,
  sd_r: undefined,
  ed_r: undefined,
  c_f: undefined,
  kw_f: undefined,
  t_f: undefined,
  d_s: undefined,
  d_e: undefined,
  il_f: false
};


export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [

  {
    field: 'transferId',
    headerName: 'Run Code',
    filter: false,
    downloadable: false,
    cellRenderer: MultiLineLinkColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        routerLink: ['/data-management', 'transfer', 'run-detail', params.data.transferId, 'tasks']
      }
    }

  },

  {
    field: 'transferTypeCode',
    headerName: 'Transfer Code',
    hide: false,
    isMandatory: true,
    autoHeight: true,
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true},
    cellRenderer: MultiLineLinkColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        subText: params.data.transferTypeName,
        actionEvent: transferCodeClickAction
      };
    },
    minWidth: 300

  },
  {
    field: 'transferMode',
    headerName: 'Run Mode',
    hide: false,
    isMandatory: false
  },
  {
    field: 'transferState',
    headerName: 'Status',
    hide: false,
    isMandatory: false,
    filterId: 's_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true},
    cellRenderer: StatusColumnComponent
  },
  {
    field: 'startDatetime',
    headerName: 'Start',
    hide: false,
    isMandatory: false,
    filterId: 'sd_r',
    filter: ColumnFilterType.DATE,
    filterParams: {defaultOption: 'inRange', inRangeInclusive: true, maxNumConditions: 1, comparator: Util.dateComparator},
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }

  },
  {
    field: 'endDatetime',
    headerName: 'End',
    hide: false,
    isMandatory: false,
    filterId: 'ed_r',
    filter: ColumnFilterType.DATE,
    filterParams: {defaultOption: 'inRange', inRangeInclusive: true, maxNumConditions: 1, comparator: Util.dateComparator},
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },
  {
    field: 'duration',
    headerName: 'Duration',
    hide: false,
    isMandatory: false,
    filter: ColumnFilterType.NUMBER,
    filterId: 'd_s',
    filterParams: {
      filterOptions: ['greaterThanOrEqual', 'lessThanOrEqual', 'inRange'],
      inRangeInclusive: true,
      defaultOption: 'inRange',
      maxNumConditions: 1,
      //allow only number and colon,
      //this pattern doesn't validate target string but only input chars one by one so we can't add something like: \d{2}:[0-50-9]{2}:[0-50-9]{2}
      allowedCharPattern: '\\d:',
      buttons: ['reset'],
      filterPlaceholder: 'hh:mm:ss',
      numberParser: (text: string) => {
        return text == null ? null : Util.parseDuration(text);
      },
      numberFormatter: (value: number) => {
        return value == null ? null : Util.formatDuration(value);
      }
    },
    cellRenderer: DurationColumnComponent,
  },

  {
    field: 'transferSummaryMessage',
    headerName: 'Last Message',
    hide: false,
    isMandatory: false,
    filter: false,
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.transferSummaryMessage,
        type: 'text',
      };
    }

  },
  {
    field: 'isLatest',
    headerName: 'Latest',
    hide: true,
    isMandatory: false,
    filter: true,
    filterId: 'il_f'
  },
  {
    field: 'runConfiguration',
    headerName: 'Run Parameters',
    hide: true,
    isMandatory: false
  },
  {
    field: 'action',
    headerName: 'Action',
    hide: false,
    isMandatory: true,
    downloadable: false,
    filter: false,
    cellRenderer: ActionColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        actions: [
          {
            toolTip: "Stop",
            icon: "fa-stop",
            name: "Stop transfer",
            disabled: params.data.transferState != TransferRunStatus.IN_PROGRESS,
            actionEvent: stopClickAction
          },
          {
            toolTip: "Task List",
            icon: "fa-pen-to-square",
            name: "Task List",
            routerLink: ['/system', 'transfers', 'run-history', params.data.transferId, 'tasks']
          },
          {
            toolTip: "log entries",
            icon: "fa-rectangle-vertical-history",
            name: "Log Entries",
            routerLink: ['/system', 'transfers', 'run-history', params.data.transferId, 'messages']
          }

        ]
      };
    }

  }
]
