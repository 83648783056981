import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {OperationStatus, TransferConfig} from "@data/interefaces/data.interfaces";

@Injectable({
  providedIn: 'root'
})
export class TransferConfigService {

  constructor(private _apiService: ApiService) {
  }

  public getTransferConfigurations(): Promise<TransferConfig[]> {
    return this._apiService.makeGetServiceCall<TransferConfig[]>(`/v2/api/transfer-config`);
  }

  public getTransferConfiguration(transferCode: string): Promise<TransferConfig> {
    return this._apiService.makeGetServiceCall<TransferConfig>(`/v2/api/transfer-config/${transferCode}`);
  }

  public updateStatus(transferCode: string, status: boolean): Promise<OperationStatus> {
    return this._apiService.makePostServiceCall<OperationStatus>(`/v2/api/transfer-config/${transferCode}/schedule`, `enable=${status}`);
  }

  public updateCronSchedule(transferCode: string, cronSchedule: string): Promise<OperationStatus> {
    return this._apiService.makePostServiceCall<OperationStatus>(`/v2/api/transfer-config/${transferCode}/cron-schedule`, `cronSchedule=${cronSchedule}`);
  }

  public validateCronSchedule(transferCode: string, cronSchedule: string): Promise<OperationStatus> {
    return this._apiService.makePostServiceCall(`/v2/api/transfer-config/${transferCode}/cron-validation`, `cronSchedule=${cronSchedule}`);
  }

  public updateRunConfiguration(transferCode: string, fieldId: string, value: string): Promise<OperationStatus> {
    return this._apiService.makePostServiceCall(`/v2/api/transfer-config/${transferCode}/run-configuration`, null, {key: fieldId, value: value});
  }

}
