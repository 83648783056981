import {Component, inject, Input} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {AgDialogComponent} from "../../ag-dialog/ag-dialog.component";

@Component({
  selector: 'ag-expand-dialog-button',
  templateUrl: './ag-expand-dialog-button.component.html',
  styleUrls: ['./ag-expand-dialog-button.component.scss']
})
export class AgExpandDialogButtonComponent {
  @Input() isJson: boolean = false;
  @Input() title!: string;
  @Input() message!: string;

  readonly dialog = inject(MatDialog);
  protected openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(AgDialogComponent, {
      width: '1500px',
      enterAnimationDuration,
      exitAnimationDuration,
      maxHeight: '1700px',
      data: {title: this.title, message: this.message, isJson: this.isJson }
    });
  }
}
