import {Directive, inject, OnInit} from "@angular/core";
import {ParamMap, Router} from "@angular/router";
import {EventbusService} from "@core/event-bus/eventbus.service";
import {StoreService} from "@core/api/store.service";
import {LocalStorageService} from "@core/api/local.storage.service";
import {EventType} from "@core/interfaces/data.interfaces";
import {Util} from "@data/util/util";
import {NotificationType} from "@data/enums/data.enums";
import {AgConfirmationDialogComponent} from "@shared/ag-confirmation-dialog/ag-confirmation-dialog.component";
import {firstValueFrom} from "rxjs";
import {MatDialog} from "@angular/material/dialog";


@Directive()
export abstract class BaseComponent implements OnInit {

  protected _router: Router;
  protected _localStorage: LocalStorageService;
  protected _store: StoreService;
  protected pageName: string;
  private _matDialog: MatDialog;
  private currentUrl!: string;
  private _eventBus: EventbusService;

  protected constructor() {
    this._router = inject(Router);
    this._eventBus = inject(EventbusService);
    this._localStorage = inject(LocalStorageService);
    this._store = inject(StoreService);
    this._matDialog = inject(MatDialog);
    this.pageName = this.constructor.name;
  }

  ngOnInit(): void {
    this.currentUrl = this._router.url;
  }

  protected getPageState(): any | undefined {
  }

  protected getFilterContext(): any | undefined {
  }

  protected filterPageState(): { [p: string]: any } | undefined {
    if (this.getPageState()) {
      return Object.fromEntries(Object.entries(this.getPageState()).filter(([k, v]) => {
        if (typeof v == "boolean") {
          return v;
        } else {
          return v != null;
        }
      }));
    }

    return undefined;
  }

  protected getUrlSegments(): string[] {
    return Util.getUrlSegments(this.currentUrl);
  }

  protected getTaskIndexFromUrlSegments(urlSegments: string[]) {
    return urlSegments.indexOf('task');
  }

  protected createGoBackUrl() {
    const urlSegments = this.getUrlSegments();
    const goBackArraySegments = urlSegments.slice(0, this.getTaskIndexFromUrlSegments(urlSegments));
    return goBackArraySegments.join('/');
  }

  protected initiatePageState(paramMap: ParamMap) {
    let currentPageState = this.getPageState();
    let currentFilterContext = this.getFilterContext();
    Object.keys(currentPageState).forEach(key => {
      if (typeof currentPageState[key] === "boolean") {
        currentPageState[key] = "true" === paramMap.get(key)
        if (currentFilterContext && currentFilterContext.hasOwnProperty(key)) {
          currentFilterContext[key].filterValue = currentPageState[key] === false ? undefined : currentPageState[key];
        }
      } else {
        currentPageState[key] = paramMap.get(key)
        if (currentFilterContext && currentFilterContext.hasOwnProperty(key)) {
          if (currentPageState[key] === undefined || currentPageState[key] === null || currentPageState[key] === '') {
            currentFilterContext[key].filterValue = undefined;
          } else {
            currentFilterContext[key].filterValue = currentPageState[key];
          }
        }
      }
    });
  }

  protected updateToPageContextUrl() {
    let queryParameters = this.filterPageState();
    if (queryParameters) {
      let urlTree = this._router.parseUrl(this._router.url);
      urlTree.queryParams = {};
      this.navigateToUrl([urlTree.toString()], queryParameters);
    }
  }

  protected navigateToUrl(urlTree: string[], queryParameters?: { [p: string]: any } | undefined) {
    this._router.navigate(urlTree, {queryParams: queryParameters, replaceUrl: true})
      .catch(error => this.displayNotificationMessage("error", error));
  }

  protected displayNotificationMessage(notificationType: "error" | "info" | "warning" | "success", message: string) {
    this._eventBus.fire({type: EventType.NOTIFICATION, data: {type: notificationType, message: message}});
  }

  protected displayProgressBar(show: boolean) {
    this._eventBus.fire({type: EventType.PROGRESS, data: show});
  }

  protected async displayDialogMessage(notificationType: NotificationType, message: string, title?: string, hideCancelButton?: boolean) {
    let dialogResult = false;
    const dialogTitle = (title) ? title : notificationType.toString();
    const hideCancel = !hideCancelButton;
    const dialogRef = this._matDialog.open(AgConfirmationDialogComponent, {
      data: {
        title: dialogTitle,
        message: message,
        hideCancel: hideCancel
      }
    });
    dialogResult = await firstValueFrom(dialogRef.afterClosed());
    return dialogResult;
  }

}
