import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {Util} from "@data/util/util";
import {EventLog, EventLogLineChartData} from "@data/interefaces/eventlog.interfaces";
import {KpiSidebarItem} from "@shared/ag-kpi-sidebar/ag-kpi-sidebar.component.ds";
import {TimeUnit} from "@data/enums/data.enums";

@Injectable({
  providedIn: 'root'
})
export class EventLogService {
  private readonly TITLE_IN_QUEUE = "Currently in Queue";
  private readonly TITLE_IN_PROGRESS = "Currently in Progress";
  private readonly TITLE_ADDED_IN = "Added in Last";
  private readonly TITLE_PROCESSED_SUCCESSFULLY = "Processed Successfully in Last";
  private readonly TITLE_PROCESSED_WITH_ERRORS = "Processed With Errors in Last";
  private readonly TIME_AMOUNTS = 10;

  constructor(private _apiService: ApiService) {
  }

  public searchEventLog(eventType: string | null | undefined,
                        eventStatus: string | null | undefined,
                        processedBy: string | null | undefined,
                        dateAfter: string | null,
                        dateBefore: string | null): Promise<EventLog[]> {
    let queryParams: string[] = [];
    if (eventType) {
      Util.pushQueryParam("eventType", eventType, queryParams);
    }
    if (eventStatus) {
      Util.pushQueryParam("eventStatus", eventStatus, queryParams);
    }
    if (processedBy) {
      Util.pushQueryParam("processedBy", processedBy, queryParams);
    }
    if (dateAfter !== null) {
      Util.pushQueryParam("dateAfter", dateAfter, queryParams);
    }
    if (dateBefore !== null) {
      Util.pushQueryParam("dateBefore", dateBefore, queryParams);
    }
    return this._apiService.makeGetServiceCall<EventLog[]>(`/v2/api/event/event-log/search`, queryParams.join('&'));
  }

  public cancelEvent(eventLogId: number) : Promise<EventLog> {
    let queryParams: string[] = [];
    Util.pushQueryParam("eventLogId", eventLogId.toString(), queryParams);
    return this._apiService.makePutServiceCall<EventLog>(`/v2/api/event/event-log/cancel`, queryParams.join('&'));
  }

  public abortEvent(eventLogId: number) : Promise<EventLog> {
    let queryParams: string[] = [];
    Util.pushQueryParam("eventLogId", eventLogId.toString(), queryParams);
    return this._apiService.makePutServiceCall<EventLog>(`/v2/api/event/event-log/abort`, queryParams.join('&'));
  }

  public getEventLogKpi(eventLogs: EventLog[], timeUnit: string | null): KpiSidebarItem[] {
    if (!timeUnit) {
      timeUnit = TimeUnit.HOUR;
    }
    const kpiItems: KpiSidebarItem[] = [];
    kpiItems.push(this.getCurrentInQueueKpiItem(eventLogs));
    kpiItems.push(this.getCurrentInProgressKpiItem(eventLogs));
    kpiItems.push(...this.getAddedInKpiItems(eventLogs, timeUnit));
    kpiItems.push(...this.getProcessedSuccessfullyKpiItems(eventLogs, timeUnit));
    kpiItems.push(...this.getProcessedWithErrorsKpiItems(eventLogs, timeUnit));
    return kpiItems;
  }

  private getCurrentInQueueKpiItem(eventLogs: EventLog[]): KpiSidebarItem {
    return {
      title: this.TITLE_IN_QUEUE,
      value: eventLogs.filter(log => log.eventStatus === "pending" && log.receivedDateTime !== null).length
    };
  }

  private getCurrentInProgressKpiItem(eventLogs: EventLog[]): KpiSidebarItem {
    return {
      title: this.TITLE_IN_PROGRESS,
      value: eventLogs.filter(log => log.eventStatus === "in progress").length
    };
  }

  private getAddedInKpiItems(eventLogs: EventLog[], timeUnit: string,): KpiSidebarItem[] {
    const countFunc = (timeUintStart: Date): number => eventLogs.filter(log => log.createdDateTime >= timeUintStart).length;
    return this.getKpiItems(timeUnit, this.TITLE_ADDED_IN, countFunc);
  }

  private getProcessedSuccessfullyKpiItems(eventLogs: EventLog[], timeUnit: string,): KpiSidebarItem[] {
    const countFunc = (timeUintStart: Date): number =>
      eventLogs.filter(log => log.eventStatus === "completed" && log.endDatetime >= timeUintStart).length;
    return this.getKpiItems(timeUnit, this.TITLE_PROCESSED_SUCCESSFULLY, countFunc);
  }

  private getProcessedWithErrorsKpiItems(eventLogs: EventLog[], timeUnit: string,): KpiSidebarItem[] {
    const statuses = ["failed", "rejected", "stopped", "cancelled"];
    const countFunc = (timeUintStart: Date): number =>
      eventLogs.filter(log => statuses.includes(log.eventStatus) && ((log.endDatetime ?? log.startDatetime) >= timeUintStart)).length;
    return this.getKpiItems(timeUnit, this.TITLE_PROCESSED_WITH_ERRORS, countFunc);
  }

  private getKpiItems(timeUnit: string, baseTitle: string, countFunc: (startTime: Date) => number): KpiSidebarItem[] {
    const kpiItems: KpiSidebarItem[] = [];

    let timeUintStart = this.getTimeUnitStart(timeUnit, 1);
    kpiItems.push({
      title: this.getKpiTitle(baseTitle, timeUnit, 1),
      value: countFunc(timeUintStart)
    });

    timeUintStart = this.getTimeUnitStart(timeUnit, this.TIME_AMOUNTS);
    kpiItems.push({
      title: this.getKpiTitle(baseTitle, timeUnit, this.TIME_AMOUNTS),
      value: countFunc(timeUintStart)
    });

    return kpiItems;
  }

  private getTimeUnitStart(timeUnit: string, amount: number): Date {
    const currentTime = new Date();
    return new Date(currentTime.getTime() - amount * Util.getTimeUnitMultiplier(timeUnit));
  }

  private getKpiTitle(baseTitle: string, timeUnit: string, amount: number): string {
    const pluralizedUnit = amount > 1 ? `${timeUnit}s` : timeUnit;
    return `${baseTitle} ${amount} ${pluralizedUnit}`;
  }

  public getGroupedEventLogLineChartData(groupedMap: Map<string, EventLog[]>, timeUnit: string, dataAmount: number): { [key: string]: EventLogLineChartData[] } {
    const result: { [key: string]: EventLogLineChartData[] } = {};
    groupedMap.forEach((eventLogs, group) => {
      result[group] = this.getEventLogLineChartData(eventLogs, timeUnit, dataAmount);
    });
    return result;
  }

  public getEventLogLineChartData(eventLogs: EventLog[], timeUnit: string, dataAmount: number): EventLogLineChartData[] {
    const chartData: EventLogLineChartData[] = [];
    const timeUnitMultiplier = Util.getTimeUnitMultiplier(timeUnit);
    for (let i = dataAmount; i >= 0; i--) {
      const periodStart = this.getPeriodStart(i + 1, timeUnitMultiplier);
      const periodEnd = this.getPeriodStart(i, timeUnitMultiplier);
      const newEvents = eventLogs.filter(log => log.createdDateTime > periodStart && log.createdDateTime <= periodEnd).length;
      const completedEvents = eventLogs.filter(log => log.endDatetime > periodStart && log.endDatetime <= periodEnd).length;

      const queueSize = eventLogs.filter(log =>
        log.receivedDateTime > periodStart && (log.startDatetime === null || log.startDatetime <= periodEnd)
      ).length;

      const queueLogs = eventLogs.filter(log =>
        log.receivedDateTime > periodStart && log.receivedDateTime <= periodEnd
      );
      const avgQueueTime = this.calculateAverageTime(queueLogs, 'queue');

      const processingLogs = eventLogs.filter(log =>
        log.endDatetime && log.startDatetime && log.endDatetime > periodStart && log.endDatetime <= periodEnd
      );
      const avgProcessingTime = this.calculateAverageTime(processingLogs, 'processing');

      chartData.push({
        time: i,
        newEvents,
        completedEvents,
        queueSize,
        avgQueueTime,
        avgProcessingTime
      });
    }
    return chartData;
  }

  private getPeriodStart(period: number, timeUnitMultiplier: number): Date {
    const now = new Date();
    return new Date(now.getTime() - period * timeUnitMultiplier);
  }

  private calculateAverageTime(eventLogs: EventLog[], type: 'queue' | 'processing'): number {
    if (eventLogs.length === 0) {
      return 0;
    }

    const total = eventLogs.reduce((sum, log) => {
      if (type === 'queue') {
        const start = log.startDatetime || new Date();
        return sum + (start.getTime() - log.receivedDateTime.getTime()) / 60000;
      } else if (type === 'processing') {
        return sum + (log.endDatetime.getTime() - log.startDatetime.getTime()) / 60000;
      }
      return sum;
    }, 0);

    return eventLogs.length > 0 ? Math.round((total / eventLogs.length) * 100) / 100 : 0;
  }
}
