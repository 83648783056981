<mat-selection-list class="hide-show-column">
  <ng-container *ngFor="let task of checkboxTasks">
    <div *ngIf="task.name !== 'Rest'; else singleSubtask">
      <mat-checkbox
        (change)="setAll($event.checked, task)"
        [checked]="task.allChecked"
        [disabled]="someDisabled(task)"
        [indeterminate]="someChecked(task)"
        color="primary">
        {{ task.name }}
      </mat-checkbox>
      <ul>
        <li *ngFor="let subtask of task.subtasks">
          <mat-checkbox (ngModelChange)="updateAllChecked(task)"
                        *ngIf="subtask.columnHeader !== '' && !subtask.isMandatory"
                        [(ngModel)]="subtask.display"
                        color="primary">
            {{ subtask.columnHeader }}
          </mat-checkbox>
        </li>
      </ul>
    </div>

    <ng-template #singleSubtask>
      <ng-container *ngFor="let subtask of getRestSubtasks(task)">
        <div>
          <mat-checkbox (ngModelChange)="toggleCheckEvent(subtask)"
                        *ngIf="subtask.columnHeader !== '' && !subtask.isMandatory"
                        [(ngModel)]="subtask.display"
                        color="primary">
            {{ subtask.columnHeader }}
          </mat-checkbox>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
</mat-selection-list>
