<div [showSkeleton]="loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <mat-card *ngIf="!loading" class="task-component-detail backdrop" id="response-file-detail">
    <mat-card-header class="mg-b-m">
      <div class="header-title">
        <mat-card-title class="primary-font-color">Survey Response File Detail</mat-card-title>
        <mat-card-subtitle>File ID: {{ this.responseFileId }}</mat-card-subtitle>
      </div>
    </mat-card-header>
    <mat-card-content class="form-container">
      <div class="flex-row view-only-container">
        <div class="view-only-block">
          <ag-datablock data="{{this.excelSurveyFileUpload?.fileName}}" title="File Name"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.excelSurveyFileUpload?.description}}" title="Description"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.excelSurveyFileUpload?.survey?.surveyCode}}"
                        title="Survey Code"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.excelSurveyFileUpload?.respondentEmail}}"
                        title="Respondent Email"></ag-datablock>
        </div>
      </div>
      <div class="flex-row view-only-container mg-t-m mg-b-m">
        <div class="view-only-block">
          <ag-datablock [isDate]="true" data="{{this.excelSurveyFileUpload?.processedDatetime}}"
                        title="Uploaded Date"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock class="view-only-block" data="{{this.excelSurveyFileUpload?.processingStatus}}"
                        title="Upload Status"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.excelSurveyFileUpload?.overwritten}}" title="File Status"></ag-datablock>
        </div>
        <div class="view-only-block">
          <ag-datablock data="{{this.excelSurveyFileUpload?.sharepointUrl}}"
                        title="SharePoint URL" url="{{this.excelSurveyFileUpload?.sharepointUrl}}"></ag-datablock>
        </div>
      </div>
      <div class="flex-row view-only-container mg-t-m mg-b-m">
        <mat-label *ngIf="rowData.length == 0">No upload error</mat-label>
        <mat-label *ngIf="rowData.length != 0">Upload errors</mat-label>
      </div>
      <ng-container *ngIf="rowData.length != 0">
        <div *ngIf="rowData.length != 0" style="height: 50vh;">
          <ag-grid-angular
            (columnVisible)="onColumnVisible($event)"
            [columnDefs]="tableColumns"
            [rowData]="rowData"
            [suppressPropertyNamesCheck]="true"
            class="ag-theme-quartz ag-grid-flex"
            style=" height: 100%;">
          </ag-grid-angular>
        </div>
      </ng-container>

    </mat-card-content>
  </mat-card>
</div>
