import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {SelectHeaderComponent} from "@pages/segments/grid/columns/select-header/select-header.component";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";
import {ColumnFilterType} from "@data/enums/data.enums";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {Util} from "@data/util/util";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {CircleCheckColumnComponent} from "@pages/segments/grid/columns/circle-check-column/circle-check-column.component";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";

export let marketCodes: string[] = [];
export let productCodes: string[] = [];
export let periods: string[] = [];

export let pageContext: {
  [key: string]: boolean | string | undefined
  p_f: string | undefined;
  mc_f: string | undefined;
  pc_f: string | undefined;
  ic_f: string | undefined;
  mn_f: string | undefined;
  in_f: string | undefined;
  pn_f: string | undefined;
  rgn_f: string | undefined;
  ss_f: string | undefined;
  i_f: string | undefined;
  rgc_f: string | undefined;
  rcc_f: string | undefined;
  rc_f: string | undefined;
  pct_f: string | undefined;
  scc_f: string | undefined;
  rcsl_f: string | undefined;
  rcrl_f: string | undefined;
  rcstl_f: string | undefined;
  rcsll_f: string | undefined;
  rcrll_f: string | undefined;
  rcstll_f: string | undefined;
  asw_f: string | undefined;
  cui_f: string | undefined;
  ppi_f: string | undefined;
} = {
  p_f: undefined,
  mc_f: undefined,
  pc_f: undefined,
  ic_f: undefined,
  mn_f: undefined,
  in_f: undefined,
  pn_f: undefined,
  rgn_f: undefined,
  ss_f: undefined,
  i_f: undefined,
  rgc_f: undefined,
  rcc_f: undefined,
  rc_f: undefined,
  pct_f: undefined,
  scc_f: undefined,
  rcsl_f: undefined,
  rcrl_f: undefined,
  rcstl_f: undefined,
  rcsll_f: undefined,
  rcrll_f: undefined,
  rcstll_f: undefined,
  asw_f: undefined,
  cui_f: undefined,
  ppi_f: undefined
};

export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    maxWidth: 75,
    field: 'select',
    headerName: '',
    hide: false,
    filter: false,
    headerComponent: SelectHeaderComponent,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    pinned: "left",
    suppressColumnsToolPanel: true,
    downloadable: false,
    cellRenderer: SelectColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        showFlag: false,
        flagIcon: "fa-exclamation",
        flagColor: "red",
        toolTip: params.data.note
      }
    }
  },
  {
    headerName: 'Product Period', valueFormatter: '', children: [
      {
        field: 'period', headerName: 'Period', hide: false, dbFilter: true, filterId: 'p_f', filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: periods,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            suppressSorting: true,
            buttons: ['apply', 'reset'],
            closeOnApply: true

          },
      },
      {
        field: 'marketCode', headerName: 'Market Code', hide: false, dbFilter: true, filterId: 'mc_f', filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: marketCodes,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            buttons: ['apply', 'reset'],
            closeOnApply: true
          },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketName,
          };
        }
      },
      {
        field: 'productCode', headerName: 'Product Code', hide: false, dbFilter: true, filterId: 'pc_f', filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            values: productCodes,
            refreshValuesOnOpen: true,
            defaultToNothingSelected: true,
            buttons: ['apply', 'reset'],
            closeOnApply: true
          },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.productName,
          };
        }
      },
      {
        field: 'industryCode', headerName: 'Industry Code', hide: false, filterId: 'ic_f', filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            defaultToNothingSelected: true,
            buttons: ['reset']
          },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryName,
          };
        }
      },
    ]
  },
  {
    headerName: 'Names', valueFormatter: '', children: [
      {
        field: 'marketName', headerName: 'Market Name', hide: true, filterId: 'mn_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          }
        },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.marketCode,
          };
        }
      },
      {
        field: 'industryName', headerName: 'Industry Name', hide: true, filterId: 'in_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          }
        },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.industryCode,
          };
        }
      },
      {
        field: 'productName', headerName: 'Product Name', hide: true, filterId: 'pn_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          }
        },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.productCode,
          };
        }
      },
      {
        field: 'respondentGroupName', headerName: 'Res Group Name', hide: true, filterId: 'rgn_f',
        filterParams: {
          textMatcher: ({filterOption, value, filterText, data, colDef}: any) => {
            return Util.labelCodeComparator(filterOption, value, filterText, colDef.cellRendererParams({data: data})?.subText?.toLocaleLowerCase());
          }
        },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.respondentGroupCode,
          };
        }
      },
    ]
  },
  {
    field: 'surveySubject', headerName: 'Survey Subject', hide: false, filterId: 'ss_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true},
    cellRenderer: StatusColumnComponent
  },
  {
    field: 'include', headerName: 'Included?', hide: false, filterId: 'i_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      cellRenderer: CircleCheckColumnComponent,
      buttons: ['reset']
    },
    cellRenderer: CircleCheckColumnComponent
  },
  {
    headerName: 'Codes', valueFormatter: '', children: [
      {
        field: 'respondentGroupCode', headerName: 'Res Group Code', hide: true, filterId: 'rgc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            defaultToNothingSelected: true,
            buttons: ['reset']
          },
        autoHeight: true,
        cellRenderer: MultiLineLinkColumnComponent,
        cellRendererParams: function (params: any) {
          return {
            currentPageContext: pageContext,
            subText: params.data.respondentGroupName,
          };
        }
      },
      {
        field: 'respondentCompanyCode', headerName: 'Res Company Code', hide: false, filterId: 'rcc_f',
        filter: ColumnFilterType.DROPDOWN,
        filterParams:
          {
            defaultToNothingSelected: true,
            buttons: ['reset']
          },
        autoHeight: true
      }
    ]
  },
  {
    headerName: 'Count', valueFormatter: '', children: [
      {
        field: 'ratingCount', headerName: 'Rating Count', hide: false, filterId: 'rc_f', filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      },
      {
        field: 'personCount', headerName: 'Person Count', hide: false, filterId: 'pct_f', filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      },
      {
        field: 'subjectCompanyCount', headerName: 'Subject Company Count', hide: false, filterId: 'scc_f', filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      },
    ]
  },
  {
    headerName: 'Labels', valueFormatter: '', children: [
      {
        field: 'respondentCompanySurveyLabelDefaultLocale', headerName: 'Res Company Survey Label (Default Locale)', hide: false, filterId: 'rcsl_f',
        filterParams: {
          buttons: ['reset']
        }
      },
      {
        field: 'respondentCompanyReportLabelDefaultLocale', headerName: 'Res Company Report Label (Default Locale)', hide: false, filterId: 'rcrl_f',
        filterParams: {
          buttons: ['reset']
        }
      },
      {
        field: 'respondentCompanyStandardLabelDefaultLocale', headerName: 'Res Company Standard Label (Default Locale)', hide: true, filterId: 'rcstl_f',
        filterParams: {
          buttons: ['reset']
        }
      },
    ]
  },
  {
    headerName: 'Locales', valueFormatter: '', children: [
      {
        field: 'respondentCompanySurveyLabelLocale', headerName: 'Res Company Survey Label (Locale)', hide: true, filterId: 'rcsll_f',
        filterParams: {
          buttons: ['reset']
        }
      },
      {
        field: 'respondentCompanyReportLabelLocale', headerName: 'Res Company Report Label (Locale)', hide: true, filterId: 'rcrll_f',
        filterParams: {
          buttons: ['reset']
        }
      },
      {
        field: 'respondentCompanyStandardLabelLocale', headerName: 'Res Company Standard Label (Locale)', hide: true, filterId: 'rcstll_f',
        filterParams: {
          buttons: ['reset']
        }
      },
    ]
  },
  {
    field: 'assignedStandardWeight', headerName: 'Assigned Standard Weight', hide: false, filterId: 'asw_f'
  },
  {
    headerName: 'ID\'s', valueFormatter: '', children: [
      {
        field: 'companyUseId', headerName: 'Company Use Id', hide: true, filterId: 'cui_f',
        filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      },
      {
        field: 'productPeriodId',
        headerName: 'Product Period Id',
        hide: true,
        filterId: 'ppi_f',
        filter: ColumnFilterType.NUMBER,
        filterParams: {defaultOption: 'equals', maxNumConditions: 1, buttons: ['reset']}
      }]
  },
  {
    field: "actions",
    headerName: "Actions",
    pinned: "right",
    filter: false,
    cellRenderer: ActionColumnComponent,
    downloadable: false,
    suppressColumnsToolPanel: true,
    cellRendererParams:
      function (params: any) {
        return {
          actions: [
            {
              toolTip: "Edit",
              icon: "fa-pen-to-square",
              name: "Edit",
              routerLink: ['/forms', 'product-spec', 'respondent-company', 'task', 'edit'],
              queryParams: {rcc_f: params.data.respondentCompanyCode, ppi_f: params.data.productPeriodId}
            }
          ]
        };
      }
  }
]
