<div [showSkeleton]="loading" [skeletonType]="'grid-10x4'" agSkeleton class="full-height-container">
  <mat-card *ngIf="!loading" class="task-component-detail full-height-container backdrop" id="task-component-detail">
    <mat-card-header class="mg-b-m">
      <div class="header-title">
        <mat-card-title class="primary-font-color">{{ title }}</mat-card-title>
        <mat-card-subtitle>Product Period</mat-card-subtitle>
      </div>
      <div class="flex-row view-only-block mg-t-s" style="margin-left: auto">
        <ag-slide-toggle (toggleUpdate)="onIncludeChanged($event.checked)"
                         [colorType]="'error'"
                         [isChecked]="util.isToggleChecked(this.getFormControl('include'))"
                         class="slide-toggle"></ag-slide-toggle>
        <span class="mg-l-s">Include?</span>
      </div>
    </mat-card-header>
    <mat-card-content class="form-container">
      <form (ngSubmit)="this.submitForm(this.saveAndClose, 'Product Period Saved.')"
            [formGroup]="formGroup">
        <div class="flex-row view-only-container">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('period')}}"
                          title="Period"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('marketCode') + ' ' + getFormControlValue('marketName')}}"
                          title="Market"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('industryCode') + ' ' + getFormControlValue('industryName')}}"
                          title="Industry"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('productCode') + ' ' + getFormControlValue('productName')}}"
                          title="Product"></ag-datablock>
          </div>
        </div>
        <div class="flex-row view-only-container mg-t-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('ratingCount')}}"
                          title="Rating Count"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('personCount')}}"
                          title="Person Count"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('subjectCompanyCount')}}"
                          title="Subject Company Count"></ag-datablock>
          </div>
          <div class="view-only-block">
            <label>Survey Subject</label>
            <mat-chip-listbox aria-label="Status" class="mat-mdc-chip-set-stacked" disabled="true">
              <div class="flex-row">
                <mat-chip-option
                  [selected]="isSurveySubjectSelected(SurveySubject.SUPPLIER)"
                  color="accent"
                  id="ss_s">{{ SurveySubject.SUPPLIER }}
                </mat-chip-option>
                <mat-chip-option
                  [selected]="isSurveySubjectSelected(SurveySubject.RETAILER)"
                  color="accent"
                  id="ss_r">{{ SurveySubject.RETAILER }}
                </mat-chip-option>
              </div>
            </mat-chip-listbox>
          </div>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-t-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('respondentCompanyCode')}}"
                          title="Respondent Company"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('respondentCompanySurveyLabelDefaultLocale')}}"
                          title="Respondent Company Survey Label (Default Locale)"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('assignedStandardWeight')}}"
                          title="Assigned Standard Weight"></ag-datablock>
          </div>
        </div>
        <div *ngIf="!isEditMode" class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('respondentCompanyReportLabelDefaultLocale')}}"
                          title="Respondent Company Report Label (Default Locale)"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row view-only-container mg-t-m mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('respondentCompanyCode')}}"
                          title="Respondent Company"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('respondentCompanySurveyLabelDefaultLocale')}}"
                          title="Respondent Company Survey Label (Default Locale)"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('assignedStandardWeight')}}"
                          title="Assigned Standard Weight"></ag-datablock>
          </div>
        </div>
        <div *ngIf="isEditMode" class="flex-row view-only-container">
          <mat-form-field>
            <mat-label>Respondent Company Report Label (Default Locale)</mat-label>
            <input [formControl]="getFormControl('respondentCompanyReportLabelDefaultLocale')" matInput>
          </mat-form-field>
        </div>
        <div class="flex-row view-only-container mg-b-m">
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('productPeriodId')}}"
                          title="Product Period ID"></ag-datablock>
          </div>
          <div class="view-only-block">
            <ag-datablock data="{{getFormControlValue('productRespondentCompanyId')}}"
                          title="Product Respondent Company ID"></ag-datablock>
          </div>
        </div>
        <div class="flex-row flex-g-s action-buttons">
          <button (click)="onSaveAndCloseClick()" [disabled]="!this.hasAnyEdits()" color="primary" mat-raised-button
                  type="submit">
            <mat-icon class="material-symbols-outlined">save</mat-icon>
            Save and Close
          </button>
          <button (click)="resetForm()" *ngIf="isEditMode" [disabled]="!this.hasAnyEdits()" mat-button type="button">
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
            Reset
          </button>
          <button (click)="resetForm(); close()" *ngIf="hasAnyEdits()" [disabled]="!this.hasAnyEdits()" mat-button
                  type="button">
            <mat-icon class="material-symbols-outlined">cancel</mat-icon>
            Cancel
          </button>
          <button (click)="resetForm(); close()" *ngIf="!hasAnyEdits()" [disabled]=" this.hasAnyEdits()"
                  class="primary-font-color" mat-button type="button">
            <i class="fa-sharp fa-light fa-xmark-large"></i> Close
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
